import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Chunk, Lock, LockAction } from '../data'
import { NavPathItem } from '../gears'
import { LockAccess, OrganizationAccess, OrganizationProgramAccess, OrganizationProgramCardAccess } from '../parts/data/access'
import { EMPTY_ID, OrganizationContractName, OrganizationProgram, OrganizationProgramCard, OrganizationProgramCardCreateModel, OrganizationProgramCardStatistics, OrganizationProgramCreateModel, OrganizationProgramName, OrganizationProgramReference, OrganizationProgramType, OrganizationProgramUpdateModel, OrganizationReference, OrganizationType, ResolvedOrganizationProgram } from '../parts/data/models'
import { Div, Loader, Span, TXT } from '../parts/gears'
import { useFormatMessage } from '../parts/hooks'
import { CorporateContractGrid, CorporateContractItem, CorporateProgramGrid, CorporateProgramItem } from '../parts/views'
import { PageProps, withPage } from './withPage'

function MembershipPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props

  return (
    <Switch>
      <Route exact={true}
             path={`${match.path}`}
             render={props => <MembershipListPage {...props}
                                                  onLoaded={onLoaded}
                                                  onNavigate={onNavigate}
                                                  onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/programs/:id`}
             render={props => <MembershipProgramPage {...props}
                                                     onLoaded={onLoaded}
                                                     onNavigate={onNavigate}
                                                     onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/cards/:id`}
             render={props => <MembershipCardPage {...props}
                                                  onLoaded={onLoaded}
                                                  onNavigate={onNavigate}
                                                  onError={onError} />} />
    </Switch>
  )
}

interface MembershipProgramPageProps extends RouteComponentProps<{ id: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function MembershipProgramPage(props: MembershipProgramPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const itemId = match.params.id || ''
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')
  const family = options.get('family') === 'individual' ? OrganizationProgramType.Individual : OrganizationProgramType.Corporate

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new OrganizationProgram(OrganizationReference))
  const [ lock, setLock ] = useState(new Lock())
  const [ canArchive, setCanArchive ] = useState(false)

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const item = await OrganizationProgramAccess.getOne(itemId)
            setItem(item)
            if (auth.profile.isSys || auth.profile.isOwner) {
              const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
              setLock(lock)
            }
            const canArchive = (await OrganizationProgramCardAccess.getAll(undefined, undefined, 0, 1, undefined, undefined, undefined, undefined, true)).data.length === 0
            setCanArchive(canArchive)
          } else {
            const provider = await OrganizationAccess.getOne(auth.profile.belongsTo)
            setItem(prev => ({
              ...prev,
              organization: provider.type !== OrganizationType.System ? provider : new OrganizationReference(),
              type: family,
            }))
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, auth.profile.isSys, auth.profile.isOwner, auth.profile.belongsTo,
    itemId, family, setItem, setLock, setCanArchive,
    setLoading, setLoaded, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([ item ])
    }
  }, [ loaded, item, onLoaded ])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`programs/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [ setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: OrganizationProgramCreateModel): Promise<void> => {
    try {
      if (itemId !== 'new') {
        const updated = await OrganizationProgramAccess.update(itemId, item).then(() => OrganizationProgramAccess.getOne(itemId))
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await OrganizationProgramAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [ itemId, handleNavigate ])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      if (canArchive) {
        await OrganizationProgramAccess.delete(itemId)
        await handleNavigate()
      } else {
        const model = new OrganizationProgramUpdateModel()
        model.name = item.name
        model.description = item.description
        model.activated = false
        await OrganizationProgramAccess.update(itemId, model)
        await handleNavigate(itemId, false, true)
      }
    } catch (error) {
      onError(error)
    }
  }, [ item, itemId, canArchive, handleNavigate, onError ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [ itemId, handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [ itemId, handleNavigate ])

  return (
    <Loader loading={loading}>
      <CorporateProgramItem item={item}
                            lock={lock}
                            readonly={readonly}
                            showProviders={auth.profile.isSys || auth.profile.isAgent}
                            showArchive={canArchive && (auth.profile.isSys || auth.profile.isOwner)}
                            onSubmit={auth.profile.isSys || auth.profile.isOwner ? handleSubmit : undefined}
                            onCancel={auth.profile.isSys || auth.profile.isOwner ? handleCancel : undefined}
                            onDelete={!isNew && (auth.profile.isSys || auth.profile.isOwner) ? handleDelete : undefined}
                            onEdit={auth.profile.isSys || auth.profile.isOwner ? handleEdit : undefined}
                            onFree={auth.profile.isSys || auth.profile.isOwner ? handleFree : undefined} />
    </Loader>
  )
}

interface MembershipCardPageProps extends RouteComponentProps<{ id: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function MembershipCardPage(props: MembershipCardPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const itemId = match.params.id
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new OrganizationProgramCard(OrganizationProgramReference))
  const [ lock, setLock ] = useState(new Lock())
  const [ statistics, setStatistics ] = useState(new OrganizationProgramCardStatistics())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const item = await OrganizationProgramCardAccess.getOne(itemId)
            setItem(item)
            const statistics = await OrganizationProgramCardAccess.getOneStatistics(itemId)
            setStatistics(statistics)
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly,
    itemId, setItem, setStatistics, setLock,
    setLoading, setLoaded, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([ item ])
    }
  }, [ loaded, item, onLoaded ])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`cards/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [ setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: OrganizationProgramCardCreateModel): Promise<void> => {
    try {
      if (itemId !== 'new') {
        await OrganizationProgramCardAccess.update(itemId, item)
        await handleNavigate(itemId, false, true)
      } else {
        const created = await OrganizationProgramCardAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [ itemId, handleNavigate ])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await OrganizationProgramCardAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [ itemId, handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [ itemId, handleNavigate ])

  return (
    <Loader loading={loading}>
      <CorporateContractItem item={item}
                             lock={lock}
                             readonly={readonly}
                             showProviders={auth.profile.isSys || auth.profile.isAgent}
                             showComment={auth.profile.isAgent}
                             statistics={statistics}
                             onSubmit={handleSubmit}
                             onCancel={handleCancel}
                             onDelete={!isNew && !auth.profile.isAgent ? handleDelete : undefined}
                             onEdit={handleEdit}
                             onFree={handleFree} />
    </Loader>
  )
}

interface MembershipListPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function MembershipListPage(props: MembershipListPageProps): ReactElement {
  const { onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const formatMessage = useFormatMessage()

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const [ individualData, setIndividualData ] = useState(new Chunk<ResolvedOrganizationProgram>())
  const [ individualSort, setIndividualSort ] = useState('')
  const [ individualSkip, setIndividualSkip ] = useState(0)
  const [ individualTake, setIndividualTake ] = useState(15)
  const [ individualPattern, setIndividualPattern ] = useState('')

  const [ corporateData, setCorporateData ] = useState(new Chunk<ResolvedOrganizationProgram>())
  const [ corporateSort, setCorporateSort ] = useState('')
  const [ corporateSkip, setCorporateSkip ] = useState(0)
  const [ corporateTake, setCorporateTake ] = useState(15)
  const [ corporatePattern, setCorporatePattern ] = useState('')

  const [ cardData, setCardData ] = useState(new Chunk<OrganizationProgramCard<OrganizationProgramName, OrganizationContractName>>())
  const [ cardSort, setCardSort ] = useState('')
  const [ cardSkip, setCardSkip ] = useState(0)
  const [ cardTake, setCardTake ] = useState(15)
  const [ cardPattern, setCardPattern ] = useState('')

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const belongsTo = await OrganizationAccess.getOne(auth.profile.belongsTo)
          const providerId = belongsTo.type === OrganizationType.Provider ? belongsTo.id : undefined
          const agencyId = belongsTo.type === OrganizationType.Agency ? belongsTo.id : undefined

          const individualData = await OrganizationProgramAccess.getAll(individualPattern, individualSort,
            individualSkip, individualTake, { byProviderId: providerId, byAgencyId: agencyId, byType: OrganizationProgramType.Individual })
          setIndividualData(individualData)

          const corporateData = await OrganizationProgramAccess.getAll(corporatePattern, corporateSort,
            corporateSkip, corporateTake, { byProviderId: providerId, byAgencyId: agencyId, byType: OrganizationProgramType.Corporate })
          setCorporateData(corporateData)

          const org = await OrganizationAccess.getOne(auth.profile.belongsTo)

          const cardData = await OrganizationProgramCardAccess.getAll(cardPattern, cardSort,
            cardSkip, cardTake, 'Resolved', undefined,
            org.type === OrganizationType.Provider ? org.id : undefined,
            org.type === OrganizationType.Agency ? org.id : undefined)
          setCardData(cardData as Chunk<OrganizationProgramCard<OrganizationProgramName, OrganizationContractName>>)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, auth.profile.belongsTo,
    individualPattern, individualSort, individualSkip, individualTake, setIndividualData,
    corporatePattern, corporateSort, corporateSkip, corporateTake, setCorporateData,
    cardPattern, cardSort, cardSkip, cardTake, setCardData,
    setLoading, setLoaded, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [ loaded, onLoaded ])

  const handleIndividualProgramItemCreate = useCallback((): void => {
    onNavigate(`programs/new?family=individual`)
  }, [ onNavigate ])

  const handleCorporateProgramItemCreate = useCallback((): void => {
    onNavigate(`programs/new?family=corporate`)
  }, [ onNavigate ])

  const handleProgramItemSelect = useCallback((record: OrganizationProgram): void => {
    onNavigate(`programs/${record.id}`)
  }, [ onNavigate ])

  const handleIndividualProgramItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(individualPattern, newPattern)) {
      setIndividualPattern(newPattern)
      setLoading(true)
    }
  }, [ individualPattern, setIndividualPattern, setLoading ])

  const handleCorporateProgramItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(corporatePattern, newPattern)) {
      setCorporatePattern(newPattern)
      setLoading(true)
    }
  }, [ corporatePattern, setCorporatePattern, setLoading ])

  const handleIndividualProgramPageChange = useCallback((skip: number, take: number): void => {
    setIndividualSkip(skip)
    setIndividualTake(take)
    setLoading(true)
  }, [ setIndividualSkip, setIndividualTake, setLoading ])

  const handleCorporateProgramPageChange = useCallback((skip: number, take: number): void => {
    setCorporateSkip(skip)
    setCorporateTake(take)
    setLoading(true)
  }, [ setCorporateSkip, setCorporateTake, setLoading ])

  const handleIndividualProgramSortChange = useCallback((sort: string): void => {
    setIndividualSort(sort)
    setLoading(true)
  }, [ setIndividualSort, setLoading ])

  const handleCorporateProgramSortChange = useCallback((sort: string): void => {
    setCorporateSort(sort)
    setLoading(true)
  }, [ setCorporateSort, setLoading ])

  const handleCardItemCreate = useCallback((): void => {
    onNavigate(`cards/new`)
  }, [ onNavigate ])

  const handleCardItemSelect = useCallback((record: OrganizationProgramCard): void => {
    onNavigate(`cards/${record.id}`)
  }, [ onNavigate ])

  const handleCardItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(cardPattern, newPattern)) {
      setCardPattern(newPattern)
      setLoading(true)
    }
  }, [ cardPattern, setCardPattern, setLoading ])

  const handleCardPageChange = useCallback((skip: number, take: number): void => {
    setCardSkip(skip)
    setCardTake(take)
    setLoading(true)
  }, [ setCardSkip, setCardTake, setLoading ])

  const handleCardSortChange = useCallback((sort: string): void => {
    setCardSort(sort)
    setLoading(true)
  }, [ setCardSort, setLoading ])

  return (
    <Loader loading={!loaded && loading}>
      <section>
        <Div layout="grid 12">
          <Div>
            <Span className="header">{formatMessage(TXT('label.individualPrograms'))}</Span>
          </Div>
          <Div>
            <CorporateProgramGrid data={individualData}
                                  showProviders={auth.profile.isSys || auth.profile.isAgent}
                                  onItemCreate={auth.profile.isSys || auth.profile.isOwner ? handleIndividualProgramItemCreate : undefined}
                                  onItemSelect={handleProgramItemSelect}
                                  onItemSearch={handleIndividualProgramItemSearch}
                                  onPageChange={handleIndividualProgramPageChange}
                                  onSortChange={handleIndividualProgramSortChange} />
          </Div>
        </Div>
      </section>
      <section>
        <Div layout="grid 12">
          <Div>
            <Span className="header">{formatMessage(TXT('label.corporatePrograms'))}</Span>
          </Div>
          <Div>
            <CorporateProgramGrid data={corporateData}
                                  showProviders={auth.profile.isSys || auth.profile.isAgent}
                                  onItemCreate={auth.profile.isSys || auth.profile.isOwner ? handleCorporateProgramItemCreate : undefined}
                                  onItemSelect={handleProgramItemSelect}
                                  onItemSearch={handleCorporateProgramItemSearch}
                                  onPageChange={handleCorporateProgramPageChange}
                                  onSortChange={handleCorporateProgramSortChange} />
          </Div>
        </Div>
      </section>
      <section>
        <Div layout="grid 12">
          <Div>
            <Span className="header">{formatMessage(TXT('label.cards'))}</Span>
          </Div>
          <Div>
            <CorporateContractGrid data={cardData}
                                   showProviders={auth.profile.isSys || auth.profile.isAgent}
                                   onItemCreate={auth.profile.isSys || auth.profile.isOwner ? handleCardItemCreate : undefined}
                                   onItemSelect={handleCardItemSelect}
                                   onItemSearch={handleCardItemSearch}
                                   onPageChange={handleCardPageChange}
                                   onSortChange={handleCardSortChange} />
          </Div>
        </Div>
      </section>
    </Loader>
  )
}

function isProgram(item: OrganizationProgram | OrganizationProgramCard): item is OrganizationProgram {
  return (item as OrganizationProgram).name !== undefined
}

function isCard(item: OrganizationProgram | OrganizationProgramCard): item is OrganizationProgramCard {
  return (item as OrganizationProgramCard).number !== undefined
}

function pathBuilder(items: (OrganizationProgram | OrganizationProgramCard)[]): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: TXT('page.membership'),
  })
  for (const item of items) {
    if (isProgram(item)) {
      pathItems.push({
        path: item.id !== EMPTY_ID ? `programs/${item.id}` : 'programs/new',
        text: item.id !== EMPTY_ID ? `${item.code.toString().padStart(4, '0').toUpperCase()} — ${item.name}` : TXT('page.membership.program.new'),
      })
    }
    if (isCard(item)) {
      pathItems.push({
        path: item.id !== EMPTY_ID ? `cards/${item.id}` : 'contracts/new',
        text: item.id !== EMPTY_ID ? `${item.number}` : TXT('page.membership.card.new'),
      })
    }
  }

  return pathItems
}

export const Membership = withPage(MembershipPage, pathBuilder)
Membership.displayName = 'MembershipPage'
