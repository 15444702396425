import { AirportTerminalName, TransferPointType } from './AirportTerminal'
import { CurrencyBase } from './Currency'
import { PassengerReference } from "./Passenger";
import { RateCost } from "./Rate";
import { SeatStatus } from "./SeatStatus";
import { ServiceBase } from "./Service";
import { ServiceField } from "./ServiceField";

export class RatedService {
  public id: number;
  public service: ServiceBase;
  public passenger: PassengerReference | null;
  public fields: ServiceField[] | null;
  public count: number | null;
  public from: string | null;
  public till: string | null;
  public hours: number | null;
  public departureTime: string | null;
  public destinationPoint: AirportTerminalName | null;
  public destinationPointType: TransferPointType | null;
  public cost: RateCost | null;
  public price: number | null;
  public penalty: number | null;
  public status: SeatStatus;
  public referenceCurrency: CurrencyBase;
  public effectiveCurrency: CurrencyBase;
  public confirmedAt: string | null;

  public constructor() {
    this.id = 0;
    this.passenger = null;
    this.service = new ServiceBase();
    this.fields = null;
    this.count = null;
    this.from = null;
    this.till = null;
    this.hours = null;
    this.departureTime = null
    this.destinationPoint = null
    this.destinationPointType = null
    this.cost = null;
    this.price = null;
    this.penalty = null;
    this.status = SeatStatus.Requested;
    this.referenceCurrency = new CurrencyBase();
    this.effectiveCurrency = new CurrencyBase();
    this.confirmedAt = null;
  }
}
