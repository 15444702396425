import React, { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { Identity } from "../auth";

interface AuthProps extends RouteComponentProps {
}

interface AuthState {
}

export class Auth extends Component<AuthProps, AuthState> {
  public constructor(props: AuthProps) {
    super(props);

    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    const identity = await Identity.instance();
    if (await identity.isLoggedIn()) {
      await identity.signOut();
    } else {
      await identity.signIn();
    }
  }

  public render(): ReactNode {
    return (
      <div>...</div>
    );
  }
}
