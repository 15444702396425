import { UploadedFile } from "./UploadedFile";

export class UpdatedPassenger {
  public id: number;
  public return: boolean | null;
  public forced: boolean | null;
  public reason: string | null;
  public attachments: UploadedFile[] | null;

  public constructor() {
    this.id = 0;
    this.return = null;
    this.forced = null;
    this.reason = null;
    this.attachments = null;
  }
}
