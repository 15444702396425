import moment from "moment";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import Measure, { ContentRect } from "react-measure";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth";
import { Chunk, Lock, Pair } from "../../data";
import {
  Button,
  ButtonGroup,
  ButtonItem,
  FieldSet,
  Form,
  FormContext,
} from "../../gears";
import {
  ComboBox,
  DateBox,
  NumberBox,
  SwitchBox,
  TextArea,
  TextBox,
} from "../../gears/inputs";
import { email, flight, length, phone, required } from "../../gears/validators";
import { ServiceAccess } from "../data/access";
import {
  AccessProgram,
  Airline,
  Flight,
  FlightUpdateModel,
  FreeAccessType,
  LoyaltyLevel,
  LoyaltyProgram,
  OrderBlockModel,
  OrderFilter,
  FreeOrderPaymentInfo,
  OrderType,
  OrganizationType,
  Passenger,
  PassengerCategory,
  PassengerKlass,
  RatedPassenger,
  Resource,
  Seat,
  SeatUpdateModel,
  ServiceKind,
  ServiceType,
} from "../data/models";
import {
  ActionHandler,
  Div,
  formatOrderBy,
  Grid,
  GridColumn,
  ItemActionHandler,
  ItemFilterHandler,
  ItemSearchHandler,
  ItemSelectHandler,
  ItemSubmitHandler,
  parseOrderBy,
  replaceSortOrder,
  Span,
  TXT,
} from "../gears";
import {
  useDelay,
  useField,
  useFormatCode,
  useFormatDate,
  useFormatMessage,
  useFormatNumber,
  useFormatPassenger,
  useFormatTime,
  useWindowSize,
  useResource,
  useOrderStatuses,
  useFlightTypes,
  useFreeAccessTypes,
  useOrganizationFreeAccessSettings,
  usePassengerKlasses,
  useAirline,
  useAirlines,
  useAirport,
  useOrganization,
  useOrderTypes,
  useService,
  useLoyaltyPrograms,
  useResources,
} from "../hooks";
import {
  CapacityTimeline,
  CapacityTimelineItem,
  CapacityTimelinePlace,
} from "../spare";
import { PassengerView } from "./Passengers";
import { GridViewProps, ItemViewProps, ViewProps } from "./types";

export interface OrderControlPanelProps extends ViewProps<null> {
  capacity?: number;
  quota?: number;
  filter: OrderFilter;
  onItemCreate?: (
    type: OrderType.Program | OrderType.Block,
    freeAccess?: FreeAccessType
  ) => void;
  onItemFilter?: ItemFilterHandler<OrderFilter>;
  onShowAssist?: ActionHandler;
}

export function OrderControlPanel(props: OrderControlPanelProps): ReactElement {
  const { capacity, quota, filter, onItemCreate, onItemFilter, onShowAssist } =
    props;

  const pageSize = 100;

  const auth = useContext(AuthContext);

  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();

  const [belongsTo] = useOrganization(auth.profile.belongsTo);
  const providerCode =
    belongsTo !== null
      ? belongsTo.type !== OrganizationType.System
        ? belongsTo.code
        : undefined
      : "UNKNOWN";

  const [cookies, setCookie] = useCookies(["controlled-resource"]);

  const [resources, getResources] = useResources(
    pageSize,
    providerCode !== "UNKNOWN",
    useMemo(() => ({ byCode: providerCode }), [providerCode])
  );
  const [resource, setResource] = useState<Resource | null>(null);
  const controlledResourceId = resource
    ? resource.id
    : filter.resourceId
    ? filter.resourceId
    : cookies["controlled-resource"];
  const [controlledResource] = useResource(
    controlledResourceId ? controlledResourceId : null
  );

  const [flightDate, setFlightDate] = useState<string | null>(
    moment(filter.flightDate ? new Date(filter.flightDate) : new Date())
      .startOf("day")
      .toISOString()
  );

  const [types] = useFreeAccessTypes();
  const [items] = useState<ButtonItem[]>(
    types.map((pair) => ({ key: pair.code, text: pair.name }))
  );

  useEffect(() => {
    setResource(controlledResource);
  }, [controlledResource, setResource]);

  useEffect(() => {
    if (resource) {
      setCookie("controlled-resource", resource.id);
    }
  }, [resource, setCookie]);

  useEffect(() => {
    if (onItemFilter) {
      const orderFilter = {
        flightDate: flightDate ? flightDate : null,
        resourceId: controlledResourceId ? controlledResourceId : null,
      };

      onItemFilter(orderFilter);
    }
  }, [flightDate, controlledResourceId, onItemFilter]);

  const handleFlightDateDecrease = useCallback((): void => {
    setFlightDate((prev) =>
      prev ? moment(prev).subtract(1, "day").toISOString() : prev
    );
  }, [setFlightDate]);

  const handleFlightDateIncrease = useCallback((): void => {
    setFlightDate((prev) =>
      prev ? moment(prev).add(1, "day").toISOString() : prev
    );
  }, [setFlightDate]);

  const handleBizCreate = useCallback(
    (item?: ButtonItem): void => {
      if (onItemCreate) {
        onItemCreate(OrderType.Program, item!.key as FreeAccessType);
      }
    },
    [onItemCreate]
  );

  const handleResCreate = useCallback((): void => {
    if (onItemCreate) {
      onItemCreate(OrderType.Block);
    }
  }, [onItemCreate]);

  return (
    <Form loaded={true} readonly={false}>
      <Div layout="grid 12">
        {onItemFilter && (
          <Div>
            <Div layout="grid 12 3@lg">
              <Div>
                <ComboBox
                  placeholder={TXT("label.resource")}
                  searchable={true}
                  pageSize={pageSize}
                  fill={true}
                  name="filter-resource"
                  data={resources}
                  value={resource}
                  valueKey="id"
                  valueLabel="name"
                  onChange={setResource}
                  onFetch={getResources}
                />
              </Div>
              <Div>
                <Div layout="flex">
                  <Div layout="fit">
                    <Button
                      look="bare"
                      icon="arrow-chevron-left"
                      onClick={handleFlightDateDecrease}
                    />
                  </Div>
                  <Div layout="fill">
                    <DateBox
                      fill={true}
                      name="flightDate"
                      value={flightDate}
                      onChange={setFlightDate}
                    />
                  </Div>
                  <Div layout="fit">
                    <Button
                      look="bare"
                      icon="arrow-chevron-right"
                      onClick={handleFlightDateIncrease}
                    />
                  </Div>
                </Div>
              </Div>
              {resource && onItemCreate && (
                <Div>
                  {resource!.kind !== ServiceKind.BusinessLounge && (
                    <ButtonGroup>
                      <Button
                        look="bare"
                        text={TXT("label.reservation")}
                        onClick={handleResCreate}
                      />
                      {onShowAssist && (
                        <Button
                          look="bare"
                          text="Fast Track"
                          onClick={onShowAssist}
                        />
                      )}
                    </ButtonGroup>
                  )}
                  {resource!.kind === ServiceKind.BusinessLounge && (
                    <ButtonGroup>
                      <Button
                        look="bare"
                        text={TXT("label.freeAccess")}
                        items={items}
                        onClick={handleBizCreate}
                      />
                      <Button
                        look="bare"
                        text={TXT("label.reservation")}
                        onClick={handleResCreate}
                      />
                      {onShowAssist && (
                        <Button
                          look="bare"
                          text="Fast Track"
                          onClick={onShowAssist}
                        />
                      )}
                    </ButtonGroup>
                  )}
                </Div>
              )}
              <Div layout="padding-top--xs">
                {capacity && (
                  <Span>{`${formatMessage(
                    TXT("label.capacity")
                  )}: ${formatNumber(capacity)}`}</Span>
                )}
                {capacity && quota && <Span> &#9702; </Span>}
                {quota && (
                  <Span>{`${formatMessage(TXT("label.quota"))}: ${formatNumber(
                    quota
                  )}`}</Span>
                )}
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </Form>
  );
}

interface OrderedFlightSeatProps extends ViewProps<null> {
  name: string;
  item: SeatUpdateModel & { expectedFrom: string; expectedTill: string };
  index: number;
  orderType: OrderType;
  onChange?: (item: SeatUpdateModel, index: number) => void;
}

function OrderedFlightSeat(props: OrderedFlightSeatProps): ReactElement {
  const { name, item, index, orderType, onChange } = props;

  const [passenger, setPassenger] = useState(new Passenger());
  useEffect(() => {
    setPassenger((prev) => ({
      ...prev,
      familyName: item.familyName || "",
      givenName: item.givenName || "",
      middleName: item.middleName,
      dateOfBirth: item.dateOfBirth || "",
      ticket: item.ticket,
    }));
  }, [item, setPassenger]);

  const allowedFrom = item.visitedFrom !== null;
  const allowedTill = item.visitedTill !== null;
  const visitedFrom = item.visitedFrom || item.expectedFrom;
  const visitedTill = item.visitedTill || item.expectedTill;

  const handleAllowedFromChange = useCallback((): void => {
    if (onChange) {
      onChange({ ...item, visitedFrom: item.expectedFrom }, index);
    }
  }, [item, index, onChange]);

  const handleAllowedTillChange = useCallback((): void => {
    if (onChange) {
      onChange({ ...item, visitedTill: item.expectedTill }, index);
    }
  }, [item, index, onChange]);

  const handleVisitedFromChange = useCallback(
    (value: string | null): void => {
      if (onChange) {
        onChange({ ...item, visitedFrom: value || "" }, index);
      }
    },
    [item, index, onChange]
  );

  const handleVisitedTillChange = useCallback(
    (value: string | null): void => {
      if (onChange) {
        onChange({ ...item, visitedTill: value || "" }, index);
      }
    },
    [item, index, onChange]
  );

  const handlePassengerChanger = useCallback(
    (value: Passenger): void => {
      if (onChange) {
        onChange(
          {
            ...item,
            familyName: value.familyName,
            givenName: value.givenName,
            middleName: value.middleName,
            dateOfBirth: value.dateOfBirth,
            ticket: value.ticket,
          },
          index
        );
      }
    },
    [item, index, onChange]
  );

  return (
    <Div>
      <Div layout="grid 12">
        <Div>
          <PassengerView
            name={`${name}.passenger`}
            item={passenger}
            index={index}
            showName={true}
            requireName={true}
            showTicket={orderType === OrderType.Business}
            requireTicket={orderType === OrderType.Business}
            showBirthDate={true}
            requireBirthDate={false}
            onChange={handlePassengerChanger}
          />
        </Div>
        <Div>
          <Div layout="grid 12 6@lg">
            <Div>
              <Div>
                <DateBox
                  disabled={!allowedFrom}
                  fill={true}
                  time={true}
                  name="from"
                  value={visitedFrom}
                  validators={allowedFrom ? [required] : undefined}
                  onChange={handleVisitedFromChange}
                />
              </Div>
              <Div>
                <SwitchBox
                  label={TXT("action.markEntranceDate")}
                  disabled={item.visitedFrom !== null}
                  name="entered"
                  mode="check"
                  value={allowedFrom}
                  onChange={handleAllowedFromChange}
                />
              </Div>
            </Div>
            <Div>
              <Div>
                <DateBox
                  disabled={!allowedTill}
                  fill={true}
                  time={true}
                  name="till"
                  value={visitedTill}
                  validators={allowedTill ? [required] : undefined}
                  onChange={handleVisitedTillChange}
                />
              </Div>
              <Div>
                <SwitchBox
                  label={TXT("action.markExitDate")}
                  disabled={item.visitedTill !== null}
                  name="leaved"
                  mode="check"
                  value={allowedTill}
                  onChange={handleAllowedTillChange}
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

export interface OrderedFlightItemProps
  extends ItemViewProps<Flight, FlightUpdateModel> {
  seat?: number;
}

export function OrderedFlightItem(props: OrderedFlightItemProps): ReactElement {
  const { item, seat, onSubmit, onCancel, onDelete } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const [, formatFlightType] = useFlightTypes();
  const [, formatOrderType] = useOrderTypes();
  const [, formatFreeAccessType] = useFreeAccessTypes();
  const [, formatPassengerKlass] = usePassengerKlasses();

  const [seats, setSeats] = useState(
    item.seats
      .filter((item) => seat === undefined || item.id === seat)
      .map((seat) => ({
        id: seat.id,
        familyName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .familyName,
        givenName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .givenName,
        middleName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .middleName,
        dateOfBirth: item.passengers.filter(
          (x) => x.id === seat.passenger.id
        )[0].dateOfBirth,
        ticket: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .ticket,
        expectedFrom: seat.expectedFrom,
        expectedTill: seat.expectedTill,
        visitedFrom: seat.visitedFrom,
        visitedTill: seat.visitedTill,
      }))
  );
  useEffect(() => {
    item.seats
      .filter((item) => seat === undefined || item.id === seat)
      .map((seat) => ({
        id: seat.id,
        familyName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .familyName,
        givenName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .givenName,
        middleName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .middleName,
        dateOfBirth: item.passengers.filter(
          (x) => x.id === seat.passenger.id
        )[0].dateOfBirth,
        ticket: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .ticket,
        expectedFrom: seat.expectedFrom,
        expectedTill: seat.expectedTill,
        visitedFrom: seat.visitedFrom,
        visitedTill: seat.visitedTill,
      }));
  }, [seat, item, item.seats]);

  const handleSeatChange = useCallback(
    (item: SeatUpdateModel, index: number): void => {
      setSeats((prev) => {
        const changedSeats = [...prev];
        changedSeats.splice(index, 1, { ...changedSeats[index], ...item });

        return changedSeats;
      });
    },
    [setSeats]
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const model = new FlightUpdateModel();
      model.id = item.id;
      model.seats = seats;

      await onSubmit(model);
    }
  }, [item.id, seats, onSubmit]);

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" icon="close" onClick={onCancel} />
          </Div>
        </Div>
      </Div>
      <Div>
        <Form
          loaded={true}
          readonly={false}
          confirm={
            seat
              ? formatMessage(TXT("prompt.delete.message"))
              : formatMessage(TXT("prompt.revert.message", { fine: 0 }))
          }
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={
            item.order.type === OrderType.Business ||
            item.order.type === OrderType.Block
              ? onDelete
              : undefined
          }
        >
          <Div layout="grid 12">
            <Div>
              <Div layout="flex">
                <Div layout="fill">
                  <Span className="header">
                    {formatMessage(
                      TXT("label.orderWithId", {
                        id: formatCode(item.order.id, 6),
                      })
                    )}
                  </Span>
                </Div>
              </Div>
            </Div>
            <Div>
              <Span>{`${formatMessage(TXT("label.type"))}: ${
                item.order.type === OrderType.Business &&
                item.order.paymentInfo !== null &&
                item.order.paymentInfo.type !== null
                  ? formatFreeAccessType(item.order.paymentInfo.type)
                  : formatOrderType(item.order.type)
              }`}</Span>
              {item.order.paymentInfo !== null && (
                <React.Fragment>
                  {item.order.paymentInfo.passengerKlass && (
                    <Span>
                      {" "}
                      /{" "}
                      {formatPassengerKlass(
                        item.order.paymentInfo.passengerKlass
                      )}
                    </Span>
                  )}
                  {item.order.paymentInfo.loyaltyProgram && (
                    <Span> / {item.order.paymentInfo.loyaltyProgram}</Span>
                  )}
                  {item.order.paymentInfo.loyaltyLevel && (
                    <Span> / {item.order.paymentInfo.loyaltyLevel}</Span>
                  )}
                  {item.order.paymentInfo.accessProgram && (
                    <Span> / {item.order.paymentInfo.accessProgram}</Span>
                  )}
                  {item.order.paymentInfo.cardNumber && (
                    <Span> / {item.order.paymentInfo.cardNumber}</Span>
                  )}
                </React.Fragment>
              )}
            </Div>
            <Div>
              <Span>{`${formatMessage(TXT("label.flight"))}: ${
                item.flightNumber
              } / ${formatDate(
                item.flightDate,
                true,
                item.flightZone
              )} / ${formatFlightType(item.flightType)}`}</Span>
            </Div>
            <Div>
              <Div layout="grid 12">
                {seats.map((seatItem, seatIndex) => (
                  <Div key={seatIndex}>
                    <FieldSet
                      label={TXT("label.passengerWithId", {
                        id: seatIndex + 1,
                      })}
                    >
                      <OrderedFlightSeat
                        name={`seat[${seatIndex}].`}
                        item={seatItem}
                        index={seatIndex}
                        orderType={item.order.type}
                        onChange={handleSeatChange}
                      />
                    </FieldSet>
                  </Div>
                ))}
              </Div>
            </Div>
          </Div>
        </Form>
      </Div>
    </Div>
  );
}

export interface OrderedFlightGridProps extends GridViewProps<Flight> {
  onItemSearch?: ItemSearchHandler;
  onReload?: ItemActionHandler;
}

export function OrderedFlightGrid(props: OrderedFlightGridProps): ReactElement {
  const {
    data,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
    onReload,
  } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const [, formatOrderStatus] = useOrderStatuses();
  const [, formatFlightTypes] = useFlightTypes();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      const adultCount = item.seats.filter(
        (seat) => seat.category === PassengerCategory.Adult
      ).length;
      const childCount = item.seats.filter(
        (seat) => seat.category === PassengerCategory.Child
      ).length;
      const infantCount = item.seats.filter(
        (seat) => seat.category === PassengerCategory.Infant
      ).length;
      let passengerCount = `${adultCount}`;
      if (childCount + infantCount > 0) {
        passengerCount += ` + ${childCount}`;
        if (infantCount > 0) {
          passengerCount += ` + ${infantCount}`;
        }
      }
      const passengerNames: string[] = [];
      const seat = item.seats[0];
      const passenger = item.passengers.filter(
        (x) => x.id === seat.passenger.id
      )[0];
      if (passenger) {
        if (passenger.familyName) passengerNames.push(passenger.familyName);
        if (passenger.givenName) passengerNames.push(passenger.givenName);
        if (passenger.middleName) passengerNames.push(passenger.middleName);
      }
      processedData.data.push({
        id: `${item.order.id}-${item.id}`,
        code: formatCode(item.order.id, 6),
        orderStatus: formatOrderStatus(item.order.status),
        flightType: formatFlightTypes(item.flightType),
        flightNumber: item.flightNumber,
        flightDate: formatDate(item.flightDate, true, item.flightZone),
        passengerCount: `${passengerCount}${
          infantCount > 0 ? ` + ${infantCount}` : ""
        }`,
        passenger: passengerNames.join(" "),
      });
    }

    setProcessedData(processedData);
  }, [
    data,
    formatCode,
    formatDate,
    formatOrderStatus,
    formatFlightTypes,
    setProcessedData,
  ]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: string }): void => {
      const originalItem = data.data.filter(
        (i) => `${i.order.id}-${i.id}` === item.id
      )[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  const handleSortChange = useCallback(
    (orderBy: string): void => {
      if (onSortChange) {
        const sortOrder = parseOrderBy(orderBy);
        replaceSortOrder(sortOrder, "code", "id");

        onSortChange(formatOrderBy(sortOrder));
      }
    },
    [onSortChange]
  );

  const linkRenderer = useCallback(
    (dataItem: any): ReactElement => {
      const item = data.data.filter(
        (i) => `${i.order.id}-${i.id}` === dataItem.id
      )[0];
      if (!item) return <span />;

      return (
        <Link to={`/orders/${item.order.id}`}>
          {formatCode(item.order.id, 6)}
        </Link>
      );
    },
    [data, formatCode]
  );

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          {onItemSearch && (
            <Div layout="fill">
              <TextBox
                placeholder={TXT("label.search")}
                fill={true}
                name="search-pattern"
                value={currentPattern}
                onChange={setPattern}
              />
            </Div>
          )}
          <Div layout="fit">
            <Button look="bare" icon="refresh" onClick={onReload} />
          </Div>
        </Div>
      </Div>
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}
            >
              <GridColumn
                title={TXT("label.id")}
                field="code"
                width={92}
                locked={true}
                itemRenderer={linkRenderer}
              />
              <GridColumn
                title={TXT("label.status")}
                field="orderStatus"
                sortable={false}
                width={128}
              />
              <GridColumn
                title={TXT("label.flightType")}
                field="flightType"
                sortable={false}
                width={128}
              />
              <GridColumn
                title={TXT("label.flightNumber")}
                field="flightNumber"
                sortable={false}
                width={128}
              />
              <GridColumn
                title={TXT("label.flightDate")}
                field="flightDate"
                sortable={false}
                width={192}
              />
              <GridColumn
                title={TXT("label.passengerCount")}
                field="passengerCount"
                sortable={false}
                width={128}
              />
              <GridColumn
                title={TXT("label.passengers")}
                field="passenger"
                sortable={false}
                fill={true}
                width={256}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}

export interface OrderedAssistanceItemProps
  extends ItemViewProps<Flight, FlightUpdateModel> {}

export function OrderedAssistanceItem(
  props: OrderedAssistanceItemProps
): ReactElement {
  const { item, onSubmit, onCancel } = props;

  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();
  const [, formatFlightType] = useFlightTypes();

  const [seats, setSeats] = useState(
    item.seats.map((seat) => ({
      id: seat.id,
      familyName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
        .familyName,
      givenName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
        .givenName,
      middleName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
        .middleName,
      dateOfBirth: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
        .dateOfBirth,
      ticket: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
        .ticket,
      expectedFrom: seat.expectedFrom,
      expectedTill: seat.expectedTill,
      visitedFrom: seat.visitedFrom,
      visitedTill: seat.visitedTill,
    }))
  );
  useEffect(() => {
    setSeats(
      item.seats.map((seat) => ({
        id: seat.id,
        familyName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .familyName,
        givenName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .givenName,
        middleName: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .middleName,
        dateOfBirth: item.passengers.filter(
          (x) => x.id === seat.passenger.id
        )[0].dateOfBirth,
        ticket: item.passengers.filter((x) => x.id === seat.passenger.id)[0]
          .ticket,
        expectedFrom: seat.expectedFrom,
        expectedTill: seat.expectedTill,
        visitedFrom: seat.visitedFrom,
        visitedTill: seat.visitedTill,
      }))
    );
  }, [item.passengers, item.seats]);

  const handleSeatChange = useCallback(
    (confirmed: boolean, index: number): void => {
      setSeats((prev) => {
        const changedSeats = [...prev];
        const changedSeat = changedSeats[index];
        changedSeat.visitedFrom = confirmed ? new Date().toISOString() : null;
        changedSeats.splice(index, 1, changedSeat);

        return changedSeats;
      });
    },
    [setSeats]
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const model = new FlightUpdateModel();
      model.id = item.id;
      model.seats = seats;

      await onSubmit(model, item.order.id, item.id);
    }
  }, [item.id, item.order.id, seats, onSubmit]);

  return (
    <Form
      loaded={true}
      readonly={false}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
    >
      <Div layout="grid 12">
        <Div>
          <Span>{`${formatMessage(TXT("label.flight"))}: ${
            item.flightNumber
          } / ${formatDate(
            item.flightDate,
            true,
            item.flightZone
          )} / ${formatFlightType(item.flightType)}`}</Span>
        </Div>
        <Div>
          <Div layout="grid 12">
            {seats.map((seatItem, seatIndex) => (
              <Div key={seatIndex}>
                <SwitchBox
                  label={formatPassenger(seatItem)}
                  mode="check"
                  name={`passenger-${seatItem.id}`}
                  value={seatItem.visitedFrom !== null}
                  onChange={(v) => handleSeatChange(v, seatIndex)}
                />
              </Div>
            ))}
          </Div>
        </Div>
      </Div>
    </Form>
  );
}

export interface OrderedAssistanceGridProps extends GridViewProps<Flight> {
  onItemSearch?: ItemSearchHandler;
  onItemSubmit?: ItemSubmitHandler<FlightUpdateModel>;
  onReload?: ItemActionHandler;
}

export function OrderedAssistanceGrid(
  props: OrderedAssistanceGridProps
): ReactElement {
  const {
    data,
    onItemSearch,
    onItemSelect,
    onItemSubmit,
    onPageChange,
    onSortChange,
    onReload,
  } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const formatTime = useFormatTime();
  const [, formatOrderStatus] = useOrderStatuses();
  const [, formatFlightTypes] = useFlightTypes();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      const infantCount = item.seats.filter(
        (seat) => seat.category === PassengerCategory.Infant
      ).length;
      const passengerCount = item.seats.length - infantCount;
      const meetingTime = item.seats[0].expectedFrom;
      const passenger = item.passengers[0];
      processedData.data.push({
        id: item.id,
        code: formatCode(item.order.id, 6),
        flightType: formatFlightTypes(item.flightType),
        flightNumber: item.flightNumber,
        flightDate: formatDate(item.flightDate, true, item.flightZone),
        meetingTime: formatTime(meetingTime, item.flightZone),
        passengerCount: `${passengerCount}${
          infantCount > 0 ? ` + ${infantCount}` : ""
        }`,
        passengerFamily: passenger.familyName,
      });
    }

    setProcessedData(processedData);
  }, [
    data,
    formatCode,
    formatDate,
    formatTime,
    formatOrderStatus,
    formatFlightTypes,
    setProcessedData,
  ]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: number }): void => {
      const originalItem = data.data.filter((i) => i.id === item.id)[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  const handleSortChange = useCallback(
    (orderBy: string): void => {
      if (onSortChange) {
        const sortOrder = parseOrderBy(orderBy);
        replaceSortOrder(sortOrder, "code", "id");

        onSortChange(formatOrderBy(sortOrder));
      }
    },
    [onSortChange]
  );

  const itemRenderer = useCallback(
    (dataItem: any): ReactElement => {
      const item = data.data.filter((i) => i.id === dataItem.id)[0];
      if (!item) return <span />;

      return (
        <Div style={{ width: width - 64, padding: "8px 0" }}>
          <OrderedAssistanceItem
            item={item}
            lock={new Lock()}
            readonly={false}
            onSubmit={onItemSubmit}
          />
        </Div>
      );
    },
    [data, width, onItemSubmit]
  );

  const linkRenderer = useCallback(
    (dataItem: any): ReactElement => {
      const item = data.data.filter((i) => i.id === dataItem.id)[0];

      return (
        <Link to={`/orders/${item.order.id}`}>
          {formatCode(item.order.id, 6)}
        </Link>
      );
    },
    [data, formatCode]
  );

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          {onItemSearch && (
            <Div layout="fill">
              <TextBox
                placeholder={TXT("label.search")}
                fill={true}
                name="search-pattern"
                value={currentPattern}
                onChange={setPattern}
              />
            </Div>
          )}
          <Div layout="fit">
            <Button look="bare" icon="refresh" onClick={onReload} />
          </Div>
        </Div>
      </Div>
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              itemRenderer={itemRenderer}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}
            >
              <GridColumn
                title={TXT("label.id")}
                field="code"
                width={92}
                locked={true}
                itemRenderer={linkRenderer}
              />
              <GridColumn
                title={TXT("label.flightDate")}
                field="flightDate"
                width={160}
              />
              <GridColumn
                title={TXT("label.meetingTime")}
                field="meetingTime"
                width={96}
              />
              <GridColumn
                title={TXT("label.familyName")}
                field="passengerFamily"
                width={96}
                fill={true}
              />
              <GridColumn
                title={TXT("label.passengerCount")}
                field="passengerCount"
                locked={true}
                sortable={false}
                width={96}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}

export interface OrderedRoomItemProps
  extends ItemViewProps<Seat, SeatUpdateModel> {}

export function OrderedRoomItem(props: OrderedRoomItemProps): ReactElement {
  const { item, onSubmit, onCancel } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const [, formatFlightType] = useFlightTypes();

  const [service] = useService(item.service ? item.service.id : null);

  const [visitedFrom, setVisitedFrom] = useField(item.visitedFrom);
  const [visitedTill, setVisitedTill] = useField(item.visitedTill);
  const allowedFrom = visitedFrom !== null;
  const allowedTill = visitedTill !== null;

  const handleAllowedFromChange = useCallback((): void => {
    setVisitedFrom(item.expectedFrom);
  }, [item, setVisitedFrom]);

  const handleAllowedTillChange = useCallback((): void => {
    setVisitedTill(item.expectedTill);
  }, [item, setVisitedTill]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const model = new SeatUpdateModel();
      model.id = item.id;
      model.visitedFrom = visitedFrom;
      model.visitedTill = visitedTill;

      await onSubmit(model);
    }
  }, [item.id, visitedFrom, visitedTill, onSubmit]);

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" icon="close" onClick={onCancel} />
          </Div>
        </Div>
      </Div>
      <Div>
        <Form
          label={TXT("label.orderWithId", { id: formatCode(item.order.id, 6) })}
          loaded={true}
          readonly={false}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
        >
          <Div layout="grid 12">
            <Div>
              <Span>{`${formatMessage(TXT("label.flight"))}: ${
                item.flight.flightNumber
              } / ${formatDate(
                item.flight.flightDate,
                true,
                item.flight.flightZone
              )} / ${formatFlightType(item.flight.flightType)}`}</Span>
            </Div>
            <Div>
              <FieldSet label={service ? service.name : undefined}>
                <Div layout="grid 12 6@lg">
                  <Div>
                    <Div>
                      <DateBox
                        disabled={!allowedFrom}
                        fill={true}
                        time={true}
                        name="from"
                        value={visitedFrom || item.expectedFrom}
                        validators={allowedFrom ? [required] : undefined}
                        onChange={setVisitedFrom}
                      />
                    </Div>
                    <Div>
                      <SwitchBox
                        label={TXT("action.markEntranceDate")}
                        disabled={item.visitedFrom !== null}
                        name="entered"
                        mode="check"
                        value={allowedFrom}
                        onChange={handleAllowedFromChange}
                      />
                    </Div>
                  </Div>
                  <Div>
                    <Div>
                      <DateBox
                        disabled={!allowedTill}
                        fill={true}
                        time={true}
                        name="till"
                        value={visitedTill || item.expectedTill}
                        validators={allowedTill ? [required] : undefined}
                        onChange={setVisitedTill}
                      />
                    </Div>
                    <Div>
                      <SwitchBox
                        label={TXT("action.markExitDate")}
                        disabled={item.visitedTill !== null}
                        name="leaved"
                        mode="check"
                        value={allowedTill}
                        onChange={handleAllowedTillChange}
                      />
                    </Div>
                  </Div>
                </Div>
              </FieldSet>
            </Div>
          </Div>
        </Form>
      </Div>
    </Div>
  );
}

export interface OrderedFlightTimelineProps extends ViewProps<null> {
  data: Chunk<Flight>;
  date: string;
  capacity?: number;
  quota?: number;
  onItemSelect?: ItemSelectHandler<{ flight: Flight; seat: number }>;
}

export function OrderedFlightTimeline(
  props: OrderedFlightTimelineProps
): ReactElement {
  const { data, date, capacity, quota, onItemSelect } = props;

  const formatCode = useFormatCode();

  const [timelineItems, setTimelineItems] = useState<CapacityTimelineItem[]>(
    []
  );
  const [from, setFrom] = useState(new Date());
  const [till, setTill] = useState(new Date());

  useEffect(() => {
    const timelineItems: CapacityTimelineItem[] = [];
    const groupedSeats = new Map<
      number,
      { flight: Flight; seat: RatedPassenger }[]
    >();
    for (const flight of data.data) {
      for (const seat of flight.seats) {
        if (seat.seat) {
          const groupSeats = groupedSeats.get(seat.seat) || [];
          groupedSeats.set(seat.seat, [...groupSeats, { flight, seat }]);
        }
      }
    }
    for (const seatNumber of Array.from(groupedSeats.keys())) {
      const groupSeats = groupedSeats.get(seatNumber);
      if (seatNumber && groupSeats) {
        let intent = "";
        if (quota && seatNumber > quota) {
          intent = "danger";
        }
        if (capacity && seatNumber > capacity) {
          intent = "danger";
        }
        for (const groupSeat of groupSeats) {
          timelineItems.push({
            id: `${groupSeat.flight.order.id}-${groupSeat.flight.id}-${groupSeat.seat.id}`,
            item: { flight: groupSeat.flight, seat: groupSeat.seat, intent },
            place: seatNumber,
            from: moment(
              groupSeat.seat.visitedFrom || groupSeat.seat.expectedFrom
            ).toDate(),
            till: moment(
              groupSeat.seat.visitedTill || groupSeat.seat.expectedTill
            ).toDate(),
          });
        }
      }
    }

    setTimelineItems(timelineItems);
  }, [data, from, till, capacity, quota, setTimelineItems]);

  useEffect(() => {
    setFrom(moment(date).toDate());
    setTill(moment(date).add(1, "day").toDate());
  }, [date, setFrom, setTill]);

  const [width, setWidth] = useState<number>(0);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(0);
  }, [innerWidth, setWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleSelect = useCallback(
    (data: { flight: Flight; seat: RatedPassenger }): void => {
      if (onItemSelect) {
        onItemSelect({ flight: data.flight, seat: data.seat.id });
      }
    },
    [onItemSelect]
  );

  const renderPassenger = useCallback(
    (item: CapacityTimelineItem): string => {
      const data = item.item as { flight: Flight; seat: RatedPassenger };
      const seat = data.seat;
      const pass = data.flight.passengers.filter(
        (x) => x.id === seat.passenger.id
      )[0];
      const name = `${pass.familyName || ""} ${pass.givenName || ""} ${
        pass.middleName || ""
      }`.trim();
      return `№ ${formatCode(data.flight.order.id, 6)}: ${name}`;
    },
    [formatCode]
  );

  return (
    <Div layout="grid 12">
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            {width && (
              <Div style={{ width }}>
                <CapacityTimeline
                  items={timelineItems}
                  itemTitleRenderer={renderPassenger}
                  from={from}
                  till={till}
                  onSelect={onItemSelect ? handleSelect : undefined}
                />
              </Div>
            )}
          </Div>
        )}
      </Measure>
    </Div>
  );
}

export interface OrderedRoomTimelineProps extends ViewProps<null> {
  data: Chunk<Seat>;
  date: string;
  onItemSelect?: ItemSelectHandler<Seat>;
}

export function OrderedRoomTimeline(
  props: OrderedRoomTimelineProps
): ReactElement {
  const { data, date, onItemSelect } = props;

  const formatCode = useFormatCode();

  const [timelineItems, setTimelineItems] = useState<CapacityTimelineItem[]>(
    []
  );
  const [timelinePlaces, setTimelinePlaces] = useState<CapacityTimelinePlace[]>(
    []
  );
  const [from, setFrom] = useState(new Date());
  const [till, setTill] = useState(new Date());

  useEffect(() => {
    (async (): Promise<void> => {
      const timelineItems: CapacityTimelineItem[] = [];
      const timelinePlaces = new Map<string, CapacityTimelinePlace>();
      let roomIndex = 1;
      for (const room of data.data) {
        if (room.service) {
          if (!timelinePlaces.has(room.service.id)) {
            const service = await ServiceAccess.getOne(room.service.id);
            timelinePlaces.set(room.service.id, {
              id: roomIndex,
              name: service.name,
            });
            roomIndex++;
          }

          timelineItems.push({
            id: `${room.order.id}-${room.flight.id}-${room.id}`,
            item: room,
            place: timelinePlaces.get(room.service.id)!.id,
            from: new Date(room.visitedFrom || room.expectedFrom),
            till: new Date(room.visitedTill || room.expectedTill),
          });
        }
      }

      setTimelineItems(timelineItems);
      setTimelinePlaces(Array.from(timelinePlaces.values()));
    })();
  }, [data, setTimelineItems, setTimelinePlaces]);

  useEffect(() => {
    setFrom(moment(date).toDate());
    setTill(moment(date).add(1, "day").toDate());
  }, [date, setFrom, setTill]);

  const [width, setWidth] = useState<number>(0);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(0);
  }, [innerWidth, setWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleSelect = useCallback(
    (data: Seat): void => {
      if (onItemSelect) {
        onItemSelect(data);
      }
    },
    [onItemSelect]
  );

  const renderRoom = useCallback(
    (item: CapacityTimelineItem): string => {
      const data = item.item as Seat;
      return `№ ${formatCode(data.order.id, 6)}`;
    },
    [formatCode]
  );

  return (
    <Measure client={true} bounds={true} onResize={handleResize}>
      {({ measureRef }) => (
        <Div ref={measureRef}>
          {width && (
            <Div style={{ width }}>
              <CapacityTimeline
                places={timelinePlaces}
                items={timelineItems}
                itemTitleRenderer={renderRoom}
                sidebarWidth={128}
                from={from}
                till={till}
                onSelect={handleSelect}
              />
            </Div>
          )}
        </Div>
      )}
    </Measure>
  );
}

export interface OrderBlockFormProps extends ViewProps<null> {
  type: OrderType.Business | OrderType.Block;
  resource: Resource;
  freeAccess?: FreeAccessType;
  onSubmit?: ItemSubmitHandler<OrderBlockModel>;
  onCancel?: ItemActionHandler;
}

export function OrderBlockForm(props: OrderBlockFormProps): ReactElement {
  const { type, resource, freeAccess, onSubmit, onCancel } = props;

  const auth = useContext(AuthContext);
  const form = useContext(FormContext);

  const [airport] = useAirport(resource.airport.id);

  const [flightTypes] = useFlightTypes();
  const [flightType, setFlightType] = useField<Pair<ServiceType> | null>(
    resource.type !== ServiceType.Any
      ? flightTypes.filter((pair) => pair.code === resource.type)[0] || null
      : null
  );
  const [flightNumber, delayedFlightNumber, setFlightNumber] = useDelay<
    string | null
  >(null);
  const [flightDate, setFlightDate] = useState<string | null>(
    new Date().toISOString()
  );
  const [passengerCount, setPassengerCount] = useState<number | null>(1);
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [contactName, setContactName] = useState<string | null>(
    auth.profile.fullName
  );
  const [contactEmail, setContactEmail] = useState<string | null>(
    auth.profile.email
  );
  const [contactPhone, setContactPhone] = useState<string | null>(
    auth.profile.phone
  );
  const [description, setDescription] = useState<string | null>(null);

  const [, formatFreeAccessType] = useFreeAccessTypes();
  const [freeAccessSettings] = useOrganizationFreeAccessSettings(
    resource.organization.id
  );

  const [passengerKlasses] = usePassengerKlasses();
  const [passengerKlass, setPassengerKlass] =
    useState<Pair<PassengerKlass> | null>(null);

  const [resolvedAirline] = useAirline(
    delayedFlightNumber ? delayedFlightNumber.substr(0, 2) : null
  );
  const [airlines, getAirlines] = useAirlines(
    100,
    freeAccess === FreeAccessType.LoyaltyProgram &&
      freeAccessSettings !== null &&
      freeAccessSettings.loyaltyProgramSettings.airlineRequired
  );
  const [airline, setAirline] = useState<Airline | null>(null);
  useEffect(() => {
    setAirline(resolvedAirline);
  }, [resolvedAirline, setAirline]);

  const [allLoyaltyPrograms] = useLoyaltyPrograms(
    undefined,
    freeAccess === FreeAccessType.LoyaltyProgram &&
      freeAccessSettings !== null &&
      freeAccessSettings.loyaltyProgramSettings.programRequired,
    airline ? airline.code : null
  );
  const loyaltyPrograms = allLoyaltyPrograms.data.filter((x) =>
    (freeAccessSettings
      ? freeAccessSettings.loyaltyProgramSettings.programs
      : []
    ).some((y) => y.name === x.name)
  );
  const [loyaltyProgram, setLoyaltyProgram] = useState<LoyaltyProgram | null>(
    null
  );
  useEffect(() => {
    setLoyaltyProgram(null);
  }, [airline, setLoyaltyProgram]);

  const loyaltyLevels = loyaltyProgram ? loyaltyProgram.levels : [];
  const [loyaltyLevel, setLoyaltyLevel] = useState<LoyaltyLevel | null>(null);
  useEffect(() => {
    setLoyaltyLevel(null);
  }, [loyaltyProgram, setLoyaltyLevel]);

  const accessPrograms = freeAccessSettings
    ? freeAccessSettings.accessProgramSettings.programs
    : [];
  const [accessProgram, setAccessProgram] = useState<AccessProgram | null>(
    null
  );

  const [cardNumber, setCardNumber] = useState<string | null>(null);

  useEffect(() => {
    let restCount = (passengerCount || 0) - passengers.length;
    if (restCount > 0) {
      const restPassengers: Passenger[] = [];
      for (let i = 0; i < restCount; i++) {
        restPassengers.push(new Passenger());
      }
      setPassengers((prev) => [...prev, ...restPassengers]);
    }
    if (restCount < 0) {
      const restPassengers = [...passengers];
      restPassengers.splice(passengers.length + restCount, -restCount);
      setPassengers(restPassengers);

      form.onFieldRemove("passengers", false);
    }
  }, [form, passengerCount, passengers, setPassengers]);

  const handlePassengerChange = useCallback(
    (item: Passenger, index: number): void => {
      setPassengers((prev) => {
        const changedPassengers = [...prev];
        changedPassengers.splice(index, 1, item);

        return changedPassengers;
      });
    },
    [setPassengers]
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const model = new OrderBlockModel();
      model.resource = resource;
      model.type = type;
      model.flightType = flightType ? flightType.code : ServiceType.Any;
      model.flightNumber = flightNumber || "";
      model.flightDate = flightDate || "";
      if (freeAccess) {
        const paymentInfo = new FreeOrderPaymentInfo();
        paymentInfo.type = freeAccess;
        paymentInfo.passengerKlass =
          freeAccess === FreeAccessType.BusinessPassenger
            ? passengerKlass
              ? passengerKlass.code
              : null
            : null;
        paymentInfo.airline =
          freeAccess === FreeAccessType.LoyaltyProgram
            ? airline
              ? airline.code
              : null
            : null;
        paymentInfo.loyaltyProgram =
          freeAccess === FreeAccessType.LoyaltyProgram
            ? loyaltyProgram
              ? loyaltyProgram.name
              : null
            : null;
        paymentInfo.loyaltyLevel =
          freeAccess === FreeAccessType.LoyaltyProgram
            ? loyaltyLevel
              ? loyaltyLevel.name
              : null
            : null;
        paymentInfo.accessProgram =
          freeAccess === FreeAccessType.AccessProgram
            ? accessProgram
              ? accessProgram.name
              : null
            : null;
        paymentInfo.cardNumber =
          freeAccess === FreeAccessType.LoyaltyProgram ||
          freeAccess === FreeAccessType.AccessProgram
            ? cardNumber
            : null;
        model.paymentInfo = paymentInfo;
      }
      model.passengerCount = passengerCount || 0;
      model.passengers = passengers;
      model.contactName = contactName || "";
      model.contactEmail = contactEmail || "";
      model.contactPhone = contactPhone || "";
      model.description = description;

      await onSubmit(model);
    }
  }, [
    resource,
    type,
    flightType,
    flightNumber,
    flightDate,
    freeAccess,
    passengerKlass,
    airline,
    loyaltyProgram,
    loyaltyLevel,
    accessProgram,
    cardNumber,
    passengerCount,
    passengers,
    contactName,
    contactEmail,
    contactPhone,
    description,
    onSubmit,
  ]);

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" icon="close" onClick={onCancel} />
          </Div>
        </Div>
      </Div>
      <Div>
        <Form
          label={
            type === OrderType.Business
              ? formatFreeAccessType(
                  freeAccess ? freeAccess : FreeAccessType.BusinessPassenger
                )
              : TXT("label.reservation")
          }
          loaded={true}
          readonly={false}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
        >
          <Div layout="grid 12">
            <Div>
              <FieldSet label={TXT("label.parameters")}>
                <Div layout="grid 12 6@lg">
                  <Div>
                    <NumberBox
                      label={TXT("label.placeCount")}
                      fill={true}
                      name="passengerCount"
                      value={passengerCount}
                      min={1}
                      validators={[required]}
                      onChange={setPassengerCount}
                    />
                  </Div>
                  <Div>
                    <ComboBox
                      label={TXT("label.flightType")}
                      fill={true}
                      name="flightType"
                      data={flightTypes}
                      value={flightType}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setFlightType}
                    />
                  </Div>
                  <Div>
                    <TextBox
                      label={TXT("label.flightNumber")}
                      fill={true}
                      mask="AB00000"
                      placeholder="AB00000"
                      rules={{
                        A: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                        B: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                      }}
                      name="flightNumber"
                      value={flightNumber}
                      validators={[required, flight]}
                      onChange={setFlightNumber}
                    />
                  </Div>
                  <Div>
                    <DateBox
                      label={TXT(
                        flightType && flightType.code === ServiceType.Arrival
                          ? "label.flightDate.arrival"
                          : flightType &&
                            flightType.code === ServiceType.Departure
                          ? "label.flightDate.departure"
                          : "label.flightDate"
                      )}
                      fill={true}
                      time={true}
                      zone={airport ? airport.zone : undefined}
                      name="flightDate"
                      value={flightDate}
                      validators={[required]}
                      onChange={setFlightDate}
                    />
                  </Div>
                  {freeAccess === FreeAccessType.BusinessPassenger &&
                    freeAccessSettings &&
                    freeAccessSettings.businessPassengerSettings
                      .klassRequired && (
                      <Div>
                        <ComboBox
                          label={TXT("label.passengerKlass")}
                          fill={true}
                          name="passengerKlass"
                          data={passengerKlasses}
                          value={passengerKlass}
                          valueKey="code"
                          valueLabel="name"
                          validators={[required]}
                          onChange={setPassengerKlass}
                        />
                      </Div>
                    )}
                  {freeAccess === FreeAccessType.LoyaltyProgram &&
                    freeAccessSettings &&
                    freeAccessSettings.loyaltyProgramSettings
                      .airlineRequired && (
                      <Div>
                        <ComboBox
                          label={TXT("label.airline")}
                          fill={true}
                          searchable={true}
                          name={"airline"}
                          data={airlines}
                          value={airline}
                          valueKey="code"
                          valueLabel="name"
                          validators={[required]}
                          onChange={setAirline}
                          onFetch={getAirlines}
                        />
                      </Div>
                    )}
                  {freeAccess === FreeAccessType.LoyaltyProgram &&
                    freeAccessSettings &&
                    freeAccessSettings.loyaltyProgramSettings
                      .programRequired && (
                      <Div>
                        <ComboBox
                          label={TXT("label.loyaltyProgram")}
                          fill={true}
                          name={"loyaltyProgram"}
                          data={loyaltyPrograms}
                          value={loyaltyProgram}
                          valueKey="name"
                          valueLabel="name"
                          validators={[required]}
                          onChange={setLoyaltyProgram}
                        />
                      </Div>
                    )}
                  {freeAccess === FreeAccessType.LoyaltyProgram &&
                    freeAccessSettings &&
                    freeAccessSettings.loyaltyProgramSettings.levelRequired && (
                      <Div>
                        <ComboBox
                          label={TXT("label.loyaltyLevel")}
                          fill={true}
                          name={"loyaltyLevel"}
                          data={loyaltyLevels}
                          value={loyaltyLevel}
                          valueKey="name"
                          valueLabel="name"
                          validators={
                            loyaltyLevels.length > 0 ? [required] : undefined
                          }
                          onChange={setLoyaltyLevel}
                        />
                      </Div>
                    )}
                  {freeAccess === FreeAccessType.AccessProgram &&
                    freeAccessSettings &&
                    freeAccessSettings.accessProgramSettings
                      .programRequired && (
                      <Div>
                        <ComboBox
                          label={TXT("label.accessProgram")}
                          fill={true}
                          name={"accessProgram"}
                          data={accessPrograms}
                          value={accessProgram}
                          valueKey="name"
                          valueLabel="name"
                          validators={[required]}
                          onChange={setAccessProgram}
                        />
                      </Div>
                    )}
                  {((freeAccess === FreeAccessType.LoyaltyProgram &&
                    freeAccessSettings &&
                    freeAccessSettings.loyaltyProgramSettings.numberRequired) ||
                    (freeAccess === FreeAccessType.AccessProgram &&
                      freeAccessSettings &&
                      freeAccessSettings.accessProgramSettings
                        .numberRequired)) && (
                    <Div>
                      <TextBox
                        label={TXT("label.cardNumber")}
                        fill={true}
                        name={"cardNumber"}
                        value={cardNumber}
                        validators={[required]}
                        onChange={setCardNumber}
                      />
                    </Div>
                  )}
                </Div>
              </FieldSet>
            </Div>
            {type === OrderType.Business && (
              <Div>
                <FieldSet label={TXT("label.passengers")}>
                  <Div layout="grid 12">
                    {passengers.map((item, index) => (
                      <Div key={index}>
                        <PassengerView
                          name={`passengers[${index}]`}
                          item={item}
                          index={index}
                          showName={true}
                          showTicket={true}
                          showBirthDate={true}
                          requireTicket={
                            freeAccess === FreeAccessType.BusinessPassenger &&
                            freeAccessSettings !== null &&
                            freeAccessSettings.businessPassengerSettings
                              .ticketRequired
                          }
                          onChange={handlePassengerChange}
                        />
                      </Div>
                    ))}
                  </Div>
                </FieldSet>
              </Div>
            )}
            {type === OrderType.Business && (
              <Div>
                <FieldSet label={TXT("label.contactInformation")}>
                  <Div layout="grid 12 4@lg">
                    <Div>
                      <TextBox
                        placeholder={TXT("label.fullName")}
                        fill={true}
                        name="contactName"
                        value={contactName}
                        validators={[
                          required,
                          (value) => length(value, 3, 256),
                        ]}
                        onChange={setContactName}
                      />
                    </Div>
                    <Div>
                      <TextBox
                        placeholder={TXT("label.email")}
                        fill={true}
                        name="contactEmail"
                        value={contactEmail}
                        validators={[
                          required,
                          email,
                          (value) => length(value, 3, 100),
                        ]}
                        onChange={setContactEmail}
                      />
                    </Div>
                    <Div>
                      <TextBox
                        placeholder={TXT("label.phone")}
                        fill={true}
                        mask="(999) 000-00-00"
                        name="contactPhone"
                        value={contactPhone}
                        validators={[required, phone]}
                        onChange={setContactPhone}
                      />
                    </Div>
                  </Div>
                </FieldSet>
              </Div>
            )}
            <Div>
              <FieldSet label={TXT("label.comments")}>
                <TextArea
                  fill={true}
                  style={{ height: 46 }}
                  name="description"
                  value={description}
                  onChange={setDescription}
                />
              </FieldSet>
            </Div>
          </Div>
        </Form>
      </Div>
    </Div>
  );
}
