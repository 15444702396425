import { classNames } from "@progress/kendo-react-common";
import * as KendoLayout from "@progress/kendo-react-layout";
import React, { CSSProperties, ReactElement } from "react";
import { GearProps } from "./types";

export interface CardItemProps extends GearProps {
  style?: CSSProperties;
}

export function CardItem(props: CardItemProps): ReactElement {
  const { children, className, style } = props;
  return (
    <KendoLayout.Card className={classNames(className)} style={style}>
      {children}
    </KendoLayout.Card>
  );
}

export interface CardItemHeaderProps extends GearProps {
  style?: CSSProperties;
}

export function CardItemHeader(props: CardItemHeaderProps): ReactElement {
  const { children, className, style } = props;

  return (
    <KendoLayout.CardHeader className={className} style={style}>
      {children}
    </KendoLayout.CardHeader>
  );
}

export interface CardItemTitleProps extends GearProps {
  style?: CSSProperties;
}

export function CardItemTitle(props: CardItemTitleProps): ReactElement {
  const { children, className, style } = props;

  return (
    <KendoLayout.CardTitle className={className} style={style}>
      {children}
    </KendoLayout.CardTitle>
  );
}

export interface CardItemActionsProps extends GearProps {
  style?: CSSProperties;
}

export function CardItemActions(props: CardItemActionsProps): ReactElement {
  const { children, className, style } = props;

  return (
    <KendoLayout.CardActions className={className} style={style}>
      {children}
    </KendoLayout.CardActions>
  );
}

export interface CardItemBodyProps extends GearProps {
  style?: CSSProperties;
}

export function CardItemBody(props: CardItemBodyProps): ReactElement {
  const { children, className, style } = props;

  return (
    <KendoLayout.CardBody className={className} style={style}>
      {children}
    </KendoLayout.CardBody>
  );
}

export interface CardItemFooterProps extends GearProps {
  style?: CSSProperties;
}

export function CardItemFooter(props: CardItemFooterProps): ReactElement {
  const { children, className, style } = props;

  return (
    <KendoLayout.CardFooter className={className} style={style}>
      {children}
    </KendoLayout.CardFooter>
  );
}
