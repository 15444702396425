import * as KendoButtons from "@progress/kendo-react-buttons";
import { classNames } from "@progress/kendo-react-common";
import React, { CSSProperties, MouseEvent, ReactElement, useCallback } from "react";
import { useFormatMessage } from '../hooks'
import { ActionHandler, GearProps, Intent, IntlText } from './types'
import { intentToClassName } from "./utils";

export interface ButtonProps extends GearProps {
  disabled?: boolean;
  primary?: boolean;
  intent?: Intent;
  icon?: string;
  look?: "bare" | "flat" | "outline";
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  title?: IntlText | string;
  onClick?: ActionHandler;
}

export function Button(props: ButtonProps): ReactElement {
  const { children, className, disabled, primary, intent, icon, look, type, style, title, onClick } = props;

  const formatMessage = useFormatMessage();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>): void => {
    if (onClick) {
      onClick();
    }
    event.preventDefault();
  }, [ onClick ]);

  return (
    <KendoButtons.Button className={classNames("g-button", intentToClassName(intent, className))}
                         disabled={disabled}
                         primary={primary}
                         icon={icon}
                         look={look}
                         type={type || "button"}
                         style={style}
                         title={title ? formatMessage(title) : undefined}
                         onClick={onClick ? handleClick : undefined}>
      {children}
    </KendoButtons.Button>
  );
}
