import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Measure, { ContentRect } from "react-measure";
import { Chunk, ClientError, Pair } from "../../data";
import { RateFamilyV1 } from "../../data/models";
import { FieldSet, Form, FormContext, Validator } from "../../gears";
import {
  ComboBox,
  DateBox,
  NumberBox,
  SwitchBox,
  TextBox,
} from "../../gears/inputs";
import { length, range, required } from "../../gears/validators";
import { RateAccess, ResourceAccess } from "../data/access";
import {
  Airport,
  AirportTerminalName,
  EMPTY_ID,
  FlightDirection,
  GroupRateRule,
  OrganizationContractType,
  OrganizationType,
  PassengerCategory,
  PoolReference,
  RateCost,
  RateCreateModel,
  RateFilter,
  ResolvedRate,
  Resource,
  ServiceKind,
  ServiceType,
  Transfer,
  TransferPointType,
} from "../data/models";
import {
  Button,
  Div,
  Grid,
  GridColumn,
  ItemCreateHandler,
  ItemFilterHandler,
  ItemSearchHandler,
  Span,
  TXT,
} from "../gears";
import {
  useAirport,
  useAirports,
  useAirportTerminals,
  useDelay,
  useField,
  useFlightDirections,
  useFormatBoolean,
  useFormatCode,
  useFormatCostRange,
  useFormatDateRange,
  useFormatDays,
  useFormatMessage,
  useFormatTimeRange,
  useOrganization,
  useOrganizationContract,
  useOrganizationContracts,
  useOrganizationProgram,
  useOrganizationPrograms,
  useOrganizations,
  usePassengerCategories,
  usePool,
  usePrimaryServiceKinds,
  useRateFamiliesV1,
  useResource,
  useResources,
  useResourceServices,
  useService,
  useServiceTypes,
  useServiceUnits,
  useWindowSize,
} from "../hooks";
import { firstOrNull } from "../utils";
import { GridViewProps, ItemViewProps } from "./types";

interface VehicleCostItemProps {
  name: string;
  index: number;
  resource: Resource;
  terminals: AirportTerminalName[];
  value: RateCost;
  onChange?: (index: number, value: RateCost) => void;
}

function VehicleCostItem(props: VehicleCostItemProps): ReactElement {
  const { name, index, resource, terminals, value, onChange } = props;

  const formatMessage = useFormatMessage();

  const terminalA = terminals.filter((x) => x.code === resource.terminal)[0];
  const [terminalB, setTerminalB] = useState<AirportTerminalName | null>(null);
  const [terminalBCommon, setTerminalBCommon] = useState(false);
  const [terminalBLounge, setTerminalBLounge] = useState(false);
  const [price, setPrice] = useState<number | null>(null);

  useEffect(() => {
    if (value.transfers && value.transfers.length > 0) {
      const transfer = value.transfers[0];
      const terminalB = terminals.filter(
        (x) => x.id === transfer.destinationPoint.id
      )[0];
      const terminalBCommon =
        value.transfers.filter(
          (t) =>
            t.departurePoint.id === terminalA.id &&
            t.departurePointType === TransferPointType.Lounge &&
            t.destinationPoint.id === terminalB.id &&
            t.destinationPointType === TransferPointType.Common
        ).length > 0;
      const terminalBLounge =
        value.transfers.filter(
          (t) =>
            t.departurePoint.id === terminalA.id &&
            t.departurePointType === TransferPointType.Lounge &&
            t.destinationPoint.id === terminalB.id &&
            t.destinationPointType === TransferPointType.Lounge
        ).length > 0;
      setPrice(value.value);
      setTerminalB(terminalB);
      setTerminalBCommon(terminalBCommon);
      setTerminalBLounge(terminalBLounge);
      setPrice(value.value);
    } else {
      setPrice(null);
      setTerminalB(null);
      setTerminalBCommon(false);
      setTerminalBLounge(false);
    }
  }, [
    value.value,
    value.transfers,
    terminals,
    terminalA,
    setTerminalB,
    setTerminalBCommon,
    setTerminalBLounge,
  ]);

  const getChangedValue = useCallback(
    (
      price: number | null,
      terminalB: AirportTerminalName | null,
      terminalBCommon: boolean,
      terminalBLounge: boolean
    ): RateCost | null => {
      if (
        price !== null &&
        terminalB &&
        (terminalBCommon || terminalBLounge) &&
        terminalA.id !== terminalB.id
      ) {
        const changedValue = new RateCost();
        changedValue.value = price;
        changedValue.transfers = [];
        if (terminalBCommon) {
          const t = new Transfer();
          t.departurePoint = terminalA;
          t.departurePointType = TransferPointType.Lounge;
          t.destinationPoint = terminalB;
          t.destinationPointType = TransferPointType.Common;
          changedValue.transfers.push(t);
        }
        if (terminalBLounge) {
          const t = new Transfer();
          t.departurePoint = terminalA;
          t.departurePointType = TransferPointType.Lounge;
          t.destinationPoint = terminalB;
          t.destinationPointType = TransferPointType.Lounge;
          changedValue.transfers.push(t);
        }
        return changedValue;
      } else {
        return null;
      }
    },
    [terminalA]
  );

  const handleTerminalBChange = useCallback(
    (terminalB: AirportTerminalName | null): void => {
      setTerminalB(terminalB);

      const value = getChangedValue(
        price,
        terminalB,
        terminalBCommon,
        terminalBLounge
      );
      if (onChange && value) {
        onChange(index, value);
      }
    },
    [
      index,
      price,
      terminalBCommon,
      terminalBLounge,
      setTerminalB,
      getChangedValue,
      onChange,
    ]
  );

  const handleTerminalBCommonChange = useCallback(
    (terminalBCommon: boolean): void => {
      setTerminalBCommon(terminalBCommon);

      const value = getChangedValue(
        price,
        terminalB,
        terminalBCommon,
        terminalBLounge
      );
      if (onChange && value) {
        onChange(index, value);
      }
    },
    [
      index,
      price,
      terminalB,
      terminalBLounge,
      setTerminalBCommon,
      getChangedValue,
      onChange,
    ]
  );

  const handleTerminalBLoungeChange = useCallback(
    (terminalBLounge: boolean): void => {
      setTerminalBLounge(terminalBLounge);

      const value = getChangedValue(
        price,
        terminalB,
        terminalBCommon,
        terminalBLounge
      );
      if (onChange && value) {
        onChange(index, value);
      }
    },
    [
      index,
      price,
      terminalB,
      terminalBCommon,
      setTerminalBLounge,
      getChangedValue,
      onChange,
    ]
  );

  const handlePriceChange = useCallback(
    (price: number | null): void => {
      setPrice(price);

      const value = getChangedValue(
        price,
        terminalB,
        terminalBCommon,
        terminalBLounge
      );
      if (onChange && value) {
        onChange(index, value);
      }
    },
    [
      index,
      terminalB,
      terminalBCommon,
      terminalBLounge,
      setPrice,
      getChangedValue,
      onChange,
    ]
  );

  const terminalRequired = useCallback(
    (selected: boolean): string | undefined => {
      return !selected
        ? formatMessage(TXT("error.terminalRequired"))
        : undefined;
    },
    [formatMessage]
  );

  const terminalTypeRequired = useCallback(
    (selected: boolean): string | undefined => {
      return !selected
        ? formatMessage(TXT("error.terminalTypeRequired"))
        : undefined;
    },
    [formatMessage]
  );

  return (
    <Div layout="grid 12 3@lg">
      <Div>
        <Div>
          <ComboBox
            placeholder={TXT("label.terminal")}
            fill={true}
            searchable={true}
            name={`${name}-terminalB`}
            data={terminals.filter(
              (x) => terminalA === null || x.id !== terminalA.id
            )}
            value={terminalB}
            valueKey="id"
            valueLabel="code"
            onChange={handleTerminalBChange}
          />
        </Div>
        <Div>
          <Div layout="flex">
            <Div layout="fill">
              <Div layout="flex">
                <Div layout="fit">
                  <SwitchBox
                    mode="check"
                    name={`${name}-terminalBCommon`}
                    value={terminalBCommon}
                    onChange={handleTerminalBCommonChange}
                  />
                </Div>
                <Div layout="fill">{formatMessage(TXT("label.common"))}</Div>
              </Div>
            </Div>
            <Div layout="fill">
              <Div layout="flex">
                <Div layout="fit">
                  <SwitchBox
                    mode="check"
                    name={`${name}-terminalBLounge`}
                    value={terminalBLounge}
                    onChange={handleTerminalBLoungeChange}
                  />
                </Div>
                <Div layout="fill">{formatMessage(TXT("label.vip"))}</Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Validator
          name={`${name}-terminalBSelected`}
          value={terminalB !== null}
          validators={[terminalRequired]}
        />
        <Validator
          name={`${name}-terminalBTypeSelected`}
          value={terminalBCommon || terminalBLounge}
          validators={[terminalTypeRequired]}
        />
      </Div>

      <Div>
        <NumberBox
          placeholder={TXT("label.cost")}
          fill={true}
          name={`${name}-price`}
          value={price}
          onChange={handlePriceChange}
          validators={[required, (value) => range(value, 0)]}
        />
      </Div>
    </Div>
  );
}

export interface RateItemProps
  extends ItemViewProps<ResolvedRate, RateCreateModel> {
  showResources?: boolean;
  showServices?: boolean;
  showKinds?: boolean;
  showTypes?: boolean;
}

export function RateItem(props: RateItemProps): ReactElement {
  const {
    item,
    lock,
    readonly,
    showResources,
    showServices,
    onSubmit,
    onCancel,
    onDelete,
    onEdit,
    onFree,
  } = props;

  const pageSize = 100;
  const id = item.id;
  const isNew = id === EMPTY_ID;

  const form = useContext(FormContext);
  const formatMessage = useFormatMessage();
  const [, formatUnit] = useServiceUnits();

  const [pool] = usePool(item.pool.id);

  const [name, setName] = useField<string | null>(item.name);

  const [rateFamilies] = useRateFamiliesV1();
  const [rateFamily, setRateFamily] = useField<Pair<RateFamilyV1> | null>(
    !isNew
      ? rateFamilies.filter(
          (pair) =>
            (pair.code === RateFamilyV1.Standard &&
              item.program == null &&
              item.contract === null) ||
            (pair.code === RateFamilyV1.Program && item.program !== null) ||
            (pair.code === RateFamilyV1.Contract && item.contract !== null)
        )[0] || null
      : rateFamilies.filter((pair) => pair.code === RateFamilyV1.Standard)[0]
  );

  const [contract, setContract] = useOrganizationContract(
    item.contract ? item.contract.id : null
  );
  const [provider] = useOrganization(pool ? pool.organization.id : null);
  const [agency, setAgency] = useOrganization(
    contract ? contract.agency.id : null
  );
  const [program, setProgram] = useOrganizationProgram(
    item.program ? item.program.id : null
  );

  const organizationFilter = useMemo(
    () => ({
      byProviderId: pool ? pool.organization.id : EMPTY_ID,
      isConcluded: true,
      isAuthorized: true,
    }),
    [pool]
  );
  const [agencies, getAgencies] = useOrganizations(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Contract &&
      pool !== null,
    organizationFilter
  );

  const organizationContractFilter = useMemo(
    () => ({
      byProviderId: pool ? pool.organization.id : EMPTY_ID,
      byAgencyId: agency ? agency.id : EMPTY_ID,
      byType: OrganizationContractType.Deposit,
    }),
    [pool, agency]
  );
  const [contracts, getContracts] = useOrganizationContracts(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Contract &&
      agency !== null,
    organizationContractFilter
  );

  const organizationProgramFilter = useMemo(
    () => ({ byOrganizationId: provider ? provider.id : EMPTY_ID }),
    [provider]
  );
  const [programs, getPrograms] = useOrganizationPrograms(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Program,
    organizationProgramFilter
  );

  const [resource, setResource] = useResource(
    item.resource ? item.resource.id : null
  );
  const [airport, setAirport] = useAirport(resource?.airport.id ?? null);

  const resourceFilter = useMemo(
    () => ({
      byOwnerId:
        pool && pool.organization.type === OrganizationType.Provider
          ? pool.organization.id
          : undefined,
      byPoolId:
        pool && pool.organization.type !== OrganizationType.Provider
          ? pool.id
          : undefined,
      byAirportId: airport?.id,
    }),
    [pool, airport]
  );
  const [resources, getResources] = useResources(
    pageSize,
    !readonly && isNew && showResources && pool !== null,
    resourceFilter
  );
  const [airports, getAirports] = useAirports(
    pageSize,
    pool !== null,
    undefined,
    pool?.id
  );

  const [services, getServices] = useResourceServices(
    resource ? resource.id : null,
    pageSize,
    !readonly && isNew && resource !== null && showServices
  );
  const [service, setService] = useService(
    item.service ? item.service.id : null
  );

  const [terminals] = useAirportTerminals(
    resource?.airport.id || null,
    !readonly
  );

  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (
      isNew &&
      (rateFamily === null || rateFamily.code !== RateFamilyV1.Program)
    ) {
      setProgram(null);
    }
    if (
      isNew &&
      (rateFamily === null || rateFamily.code !== RateFamilyV1.Contract)
    ) {
      setContract(null);
    }
  }, [isNew, rateFamily, setProgram, setContract]);

  useEffect(() => {
    if (
      isNew &&
      (agency === null || (contract && contract.agency.id !== agency.id))
    ) {
      setContract(null);
    }
  }, [isNew, agency, contract, setContract]);

  const [value, setValue] = useField<number | null>(
    item.costs.length > 0 ? item.costs[item.costs.length - 1].value : null
  );
  const [value1, setValue1] = useField<number | null>(
    item.costs.length > 0 ? item.costs[0].value : null
  );
  const [value2, setValue2] = useField<number | null>(
    item.costs.length > 1 ? item.costs[1].value : null
  );
  const [value3, setValue3] = useField<number | null>(
    item.costs.length > 2 ? item.costs[2].value : null
  );

  const [baseCostValue, setBaseCostValue] = useState<number | null>(null);
  useEffect(() => {
    setBaseCostValue(
      item.costs
        .filter(
          (cost) => cost.transfers === null || cost.transfers.length === 0
        )
        .map((cost) => cost.value)[0] || null
    );
  }, [item.costs, setBaseCostValue]);

  const [baseCost, setBaseCost] = useState<RateCost | null>(null);
  useEffect(() => {
    setBaseCost((prev) => {
      let cost = prev;
      if (cost === null) {
        cost = new RateCost();
      }

      if (baseCostValue !== null) {
        cost.value = baseCostValue;
      }

      return cost;
    });
  }, [baseCostValue, setBaseCost]);

  const [routeCosts, setRouteCosts] = useState<RateCost[]>([]);
  useEffect(() => {
    setRouteCosts(item.costs.filter((cost) => cost.transfers !== null));
  }, [item.costs, setRouteCosts]);

  const allTransfers = routeCosts
    .filter((x) => x.transfers !== null)
    .flatMap((x) => x.transfers)
    .map((x) => x!);

  const getItem = useCallback((): RateCreateModel => {
    const item = new RateCreateModel();
    item.name = name || "";
    item.pool = pool || new PoolReference();
    item.kind = service ? service.kind : ServiceKind.Any;
    item.type = service ? service.type : ServiceType.Any;
    item.flightDirection = FlightDirection.Any;
    item.resource = resource;
    item.service = service;
    item.contract = contract;
    item.program = program;
    if (service && service.kind === ServiceKind.Apartments) {
      const cost1 = new RateCost();
      cost1.appliedFrom = "00:00:00";
      cost1.appliedTill = "11:59:59";
      cost1.value = value1 || 0;
      const cost2 = new RateCost();
      cost2.appliedFrom = "12:00:00";
      cost2.appliedTill = "23:59:59";
      cost2.value = value2 || 0;
      const cost3 = new RateCost();
      cost3.appliedFrom = "1.00:00:00";
      cost3.appliedTill = null;
      cost3.value = value3 || 0;
      item.costs = [cost1, cost2, cost3];
      return item;
    }
    if (service && service.kind === ServiceKind.Urgency) {
      const cost = new RateCost();
      cost.value = value || 0;
      item.costs = [cost];
      return item;
    }
    if (service && service.kind === ServiceKind.Transfer) {
      item.costs = [];
      if (baseCost) {
        item.costs.push(baseCost);
      }
      item.costs.push(...routeCosts);
      return item;
    } else {
      const cost = new RateCost();
      cost.value = value || 0;
      item.costs = [cost];
      return item;
    }

    return item;
  }, [
    pool,
    name,
    contract,
    program,
    resource,
    service,
    value,
    value1,
    value2,
    value3,
    baseCost,
    routeCosts,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const item = getItem();
      try {
        if (isNew) {
          await RateAccess.create(item, true);
        } else {
          await RateAccess.update(id, item, true);
        }
        await onSubmit(item);
      } catch (error) {
        if (error instanceof ClientError && error.data && error.data.errors) {
          const firstError =
            error.data.errors[Object.keys(error.data.errors)[0]];
          setValidationMessage(
            formatMessage(
              TXT("error.rateScheduleIntersectedWith", {
                code: firstError[0],
                name: firstError[1],
              })
            )
          );
        }
      }
    }
  }, [formatMessage, getItem, id, isNew, setValidationMessage, onSubmit]);

  const handleRouteCostAdd = useCallback((): void => {
    setRouteCosts((prev) => {
      const curr = [...prev];
      curr.push(new RateCost());
      return curr;
    });
  }, [setRouteCosts]);

  const handleRouteCostChange = useCallback(
    (index: number, item: RateCost | null): void => {
      setRouteCosts((prev) => {
        const curr = [...prev];
        form.onFieldRemove(`routeCost-`, false);
        if (item) {
          curr.splice(index, 1, item);
        } else {
          curr.splice(index, 1);
        }

        return curr;
      });
    },
    [form, setRouteCosts]
  );

  const uniqueness = useCallback(
    (value: {
      currentTransfers: Transfer[];
      existedTransfers: Transfer[];
    }): string | undefined => {
      let count = 0;
      value.currentTransfers.forEach((tested) => {
        count += value.existedTransfers.filter(
          (existed) =>
            existed !== tested &&
            existed.destinationPoint.id === tested.destinationPoint.id &&
            existed.destinationPointType === tested.destinationPointType &&
            existed.departurePoint.id === tested.departurePoint.id &&
            existed.departurePointType === tested.departurePointType
        ).length;
      });

      return count > 0
        ? formatMessage(TXT("error.transferMustBeUnique"))
        : undefined;
    },
    [formatMessage]
  );

  return (
    <Form
      loaded={true}
      lock={lock}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onDelete={onDelete}
      onEdit={onEdit}
      onFree={onFree}
    >
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12 6@md 3@lg">
              <Div>
                <TextBox
                  label={TXT("label.name")}
                  fill={true}
                  name="name"
                  value={name}
                  validators={[required, (value) => length(value, 3, 256)]}
                  onChange={setName}
                />
              </Div>
              {showResources && airports.total > 1 && (
                <Div>
                  <ComboBox
                    label={TXT("label.airports")}
                    fill={true}
                    disabled={!readonly && !isNew}
                    searchable={true}
                    pageSize={pageSize}
                    name="airport"
                    data={airports}
                    value={airport}
                    valueKey="id"
                    valueLabel="name"
                    onChange={setAirport}
                    onFetch={getAirports}
                  />
                </Div>
              )}
              {showResources && (
                <Div>
                  <ComboBox
                    label={TXT("label.resource")}
                    fill={true}
                    disabled={!readonly && !isNew}
                    searchable={true}
                    pageSize={pageSize}
                    name="provider"
                    data={resources}
                    value={resource}
                    valueKey="id"
                    valueLabel="name"
                    onChange={setResource}
                    onFetch={getResources}
                  />
                </Div>
              )}
              {showServices && (
                <Div>
                  <ComboBox
                    label={TXT("label.services")}
                    fill={true}
                    disabled={!readonly && !isNew}
                    searchable={true}
                    pageSize={pageSize}
                    name="service"
                    data={services}
                    value={service}
                    valueKey="id"
                    valueLabel="name"
                    onChange={setService}
                    onFetch={getServices}
                  />
                </Div>
              )}
              <Div>
                <ComboBox
                  label={TXT("label.rateFamily")}
                  fill={true}
                  disabled={!readonly && !isNew}
                  name="rateKind"
                  data={rateFamilies.filter(
                    (x) =>
                      provider &&
                      (provider.type === OrganizationType.Provider ||
                        x.code !== RateFamilyV1.Program)
                  )}
                  value={rateFamily}
                  valueKey="code"
                  valueLabel="name"
                  validators={[required]}
                  onChange={setRateFamily}
                />
              </Div>
              {rateFamily && rateFamily.code === RateFamilyV1.Contract && (
                <Div>
                  <ComboBox
                    label={TXT("label.agency")}
                    fill={true}
                    disabled={!readonly && !isNew}
                    searchable={true}
                    pageSize={pageSize}
                    name="agency"
                    data={agencies}
                    value={agency}
                    valueKey="id"
                    valueLabel="name"
                    validators={[required]}
                    onChange={setAgency}
                    onFetch={getAgencies}
                  />
                </Div>
              )}
              {rateFamily &&
                rateFamily.code === RateFamilyV1.Contract &&
                !showServices && (
                  <Div>
                    <ComboBox
                      label={TXT("label.contract")}
                      fill={true}
                      disabled={!readonly && !isNew}
                      searchable={true}
                      pageSize={pageSize}
                      name="contract"
                      data={contracts}
                      value={contract}
                      valueKey="id"
                      valueLabel="number"
                      validators={[required]}
                      onChange={setContract}
                      onFetch={getContracts}
                    />
                  </Div>
                )}
              {rateFamily &&
                rateFamily.code === RateFamilyV1.Program &&
                !showServices && (
                  <Div>
                    <ComboBox
                      label={TXT("label.corporateProgram")}
                      fill={true}
                      disabled={!readonly && !isNew}
                      searchable={true}
                      pageSize={pageSize}
                      name="corporateProgram"
                      data={programs}
                      value={program}
                      valueKey="id"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setProgram}
                      onFetch={getPrograms}
                    />
                  </Div>
                )}
              {validationMessage && (
                <Div>
                  <Span intent="danger">{validationMessage}</Span>
                </Div>
              )}
            </Div>
          </FieldSet>
        </Div>
        <Div>
          {resource && service && service.kind !== ServiceKind.Transfer && (
            <FieldSet label={TXT("label.cost")}>
              <Div layout="grid 12">
                <Div>
                  {(service.kind === ServiceKind.Other ||
                    service.kind === ServiceKind.ConferenceRoom ||
                    service.kind === ServiceKind.Luggage ||
                    service.kind === ServiceKind.Photography ||
                    service.kind === ServiceKind.LoungeTime ||
                    service.kind === ServiceKind.Urgency) && (
                    <Div layout="grid 12 3@lg">
                      <Div>
                        <NumberBox
                          fill={true}
                          hint={formatUnit(service.unit)}
                          name="cost"
                          value={value}
                          min={0}
                          validators={[required]}
                          onChange={setValue}
                        />
                      </Div>
                    </Div>
                  )}
                  {service.kind === ServiceKind.Apartments && (
                    <Div layout="grid 12 3@lg">
                      <Div>
                        <NumberBox
                          label={TXT("label.period.halfDay")}
                          fill={true}
                          hint={formatUnit(service.unit)}
                          name="cost1"
                          value={value1}
                          min={0}
                          validators={[required]}
                          onChange={setValue1}
                        />
                      </Div>
                      <Div>
                        <NumberBox
                          label={TXT("label.period.fullDay")}
                          fill={true}
                          hint={formatUnit(service.unit)}
                          name="cost2"
                          value={value2}
                          min={0}
                          validators={[required]}
                          onChange={setValue2}
                        />
                      </Div>
                      <Div>
                        <NumberBox
                          label={TXT("label.period.moreDay")}
                          fill={true}
                          hint={formatUnit(service.unit)}
                          name="cost3"
                          value={value3}
                          min={0}
                          validators={[required]}
                          onChange={setValue3}
                        />
                      </Div>
                    </Div>
                  )}
                </Div>
                <Div>{formatMessage(TXT("message.rateRules"))}</Div>
                {pool && (
                  <Div>
                    {formatMessage(TXT("label.currency"))}: {pool.currency.code}
                  </Div>
                )}
              </Div>
            </FieldSet>
          )}
          {resource && service && service.kind === ServiceKind.Transfer && (
            <Div layout="grid 12">
              <Div>
                <FieldSet label={TXT("label.basePrice")}>
                  <Div layout="grid 12">
                    <Div>
                      <NumberBox
                        name="baseCostValue"
                        hint={TXT("hint.basePrice")}
                        value={baseCostValue}
                        validators={[required, (value) => range(value, 0)]}
                        onChange={setBaseCostValue}
                      />
                    </Div>
                    <Div>{formatMessage(TXT("message.rateRules"))}</Div>
                    {pool && (
                      <Div>
                        {formatMessage(TXT("label.currency"))}:{" "}
                        {pool.currency.code}
                      </Div>
                    )}
                  </Div>
                </FieldSet>
              </Div>
              <Div>
                <FieldSet label={TXT("label.routePrice")}>
                  <Div layout="grid 12">
                    {routeCosts.map((item, index) => (
                      <Div key={index}>
                        <Div layout="flex vertical-start">
                          {!readonly && (
                            <Div layout="fit">
                              <Button
                                disabled={form.submitting}
                                look="bare"
                                icon="minus-outline"
                                onClick={() =>
                                  handleRouteCostChange(index, null)
                                }
                              />
                            </Div>
                          )}
                          <Div layout="fill">
                            <VehicleCostItem
                              name={`routeCost-${index}`}
                              index={index}
                              resource={resource}
                              terminals={terminals.data}
                              value={item}
                              onChange={handleRouteCostChange}
                            />
                            <Validator
                              name={`routeCost-${index}-transfers-uniqueness`}
                              value={{
                                currentTransfers: item.transfers || [],
                                existedTransfers: allTransfers,
                              }}
                              validators={[uniqueness]}
                            />
                          </Div>
                        </Div>
                      </Div>
                    ))}
                    {!readonly && (
                      <Div>
                        <Button
                          disabled={form.submitting}
                          look="bare"
                          icon="plus-outline"
                          onClick={handleRouteCostAdd}
                        />
                      </Div>
                    )}
                  </Div>
                </FieldSet>
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    </Form>
  );
}

export interface RateGridProps extends GridViewProps<ResolvedRate> {
  pools?: PoolReference[];
  filter?: RateFilter;
  showCategories?: boolean;
  showKinds?: boolean;
  showTypes?: boolean;
  showTimes?: boolean;
  showGroup?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemFilter?: ItemFilterHandler<RateFilter>;
  onItemSearch?: ItemSearchHandler;
}

export function RateGrid(props: RateGridProps): ReactElement {
  const {
    data,
    pools,
    filter,
    showCategories,
    showKinds,
    showTypes,
    showTimes,
    showGroup,
    onItemCreate,
    onItemFilter,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
  } = props;

  const formatMessage = useFormatMessage();
  const formatCode = useFormatCode();
  const formatCostRange = useFormatCostRange();
  const formatDateRange = useFormatDateRange();
  const formatTimeRange = useFormatTimeRange();
  const formatDays = useFormatDays();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());
  const [passengerCategories] = usePassengerCategories();
  const [passengerCategory, setPassengerCategory] =
    useField<Pair<PassengerCategory> | null>(
      firstOrNull(
        passengerCategories.filter((x) => x.code === filter?.byCategory)
      )
    );
  const [, formatRateFamily] = useRateFamiliesV1();
  const [rateKinds, formatRateKind] = usePrimaryServiceKinds();
  const [rateKind, setRateKind] = useField<Pair<ServiceKind> | null>(
    firstOrNull(rateKinds.filter((x) => x.code === filter?.byKind))
  );
  const [rateTypes, formatRateType] = useServiceTypes();
  const [, formatFlightDirection] = useFlightDirections();
  const [rateType, setRateType] = useField<Pair<ServiceType> | null>(
    firstOrNull(rateTypes.filter((x) => x.code === filter?.byType))
  );
  const [flightDate, setFlightDate] = useField<string | null>(
    filter?.byFlightDate ?? null
  );
  const [airports, getAirports] = useAirports(
    100,
    pools && pools.length > 0,
    undefined,
    useMemo(() => pools?.map((pool) => pool.id), [pools])
  );
  const [airport, setAirport] = useAirport(filter?.byAirportId ?? null);
  const formatBoolean = useFormatBoolean();

  const handlePassengerCategory = useCallback(
    (value: Pair<PassengerCategory> | null): void => {
      setPassengerCategory(value);
      if (onItemFilter) onItemFilter({ ...filter, byCategory: value?.code });
    },
    [filter, onItemFilter, setPassengerCategory]
  );

  const handleKind = useCallback(
    (value: Pair<ServiceKind> | null): void => {
      setRateKind(value);
      if (onItemFilter) onItemFilter({ ...filter, byKind: value?.code });
    },
    [filter, onItemFilter, setRateKind]
  );

  const handleType = useCallback(
    (value: Pair<ServiceType> | null): void => {
      setRateType(value);
      if (onItemFilter) onItemFilter({ ...filter, byType: value?.code });
    },
    [filter, onItemFilter, setRateType]
  );

  const handleAirport = useCallback(
    (value: Airport | null): void => {
      setAirport(value);
      if (onItemFilter) onItemFilter({ ...filter, byAirportId: value?.id });
    },
    [filter, onItemFilter, setAirport]
  );

  const handleDate = useCallback(
    (value: string | null): void => {
      setFlightDate(value);
      if (onItemFilter)
        onItemFilter({ ...filter, byFlightDate: value ? value : undefined });
    },
    [filter, onItemFilter, setFlightDate]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      const processedData = new Chunk<any>(
        [],
        data.skip,
        data.take,
        data.total
      );
      const resources = new Map<string, Resource>();
      for (const item of data.data) {
        const resource =
          item.resource !== null
            ? resources.get(item.resource.id) ||
              resources
                .set(
                  item.resource.id,
                  await ResourceAccess.getOne(item.resource.id)
                )
                .get(item.resource.id) ||
              null
            : null;
        processedData.data.push({
          id: item.id,
          code: item.code,
          name: item.name,
          categories: passengerCategories
            .filter((x) => item.costs.some((y) => y.category === x.code))
            .map((x) => x.name[0])
            .join(", "),
          family:
            item.program !== null
              ? `${formatRateFamily(RateFamilyV1.Program)} / ${
                  item.program.name
                }`
              : item.contract != null
              ? `${formatRateFamily(RateFamilyV1.Contract)} / ${
                  item.contract.number
                }`
              : formatRateFamily(RateFamilyV1.Standard),
          kind: `${formatRateKind(item.kind)}`,
          type: `${formatRateType(item.type)}`,
          group: formatBoolean(
            item.rules.some(
              (rule) =>
                rule.name === "GroupRateRule" &&
                (rule as GroupRateRule).minSize > 1
            )
          ),
          resource: resource !== null ? resource.name : "",
          flightDirection: `${formatFlightDirection(item.flightDirection)}`,
          bookablePeriod: formatDateRange(item.bookableFrom, item.bookableTill),
          effectivePeriod: formatDateRange(item.validFrom, item.validTill),
          effectiveTime: formatTimeRange(
            item.validFromTime,
            item.validTillTime
          ),
          effectiveDays: formatDays(item.validDays),
          value: formatCostRange(
            Math.min(...item.costs.map((x) => x.value)),
            Math.max(...item.costs.map((x) => x.value)),
            item.currency.code
          ),
        });
      }
      setProcessedData(processedData);
    })();
  }, [
    data.data,
    data.total,
    data.skip,
    data.take,
    setProcessedData,
    passengerCategories,
    formatCode,
    formatCostRange,
    formatRateKind,
    formatRateType,
    formatRateFamily,
    formatDateRange,
    formatTimeRange,
    formatDays,
    formatFlightDirection,
    formatBoolean,
  ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: string }): void => {
      const originalItem = data.data.filter((i) => i.id === item.id)[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) && (
        <Div>
          <Div layout="flex">
            {onItemSearch && (
              <Div layout="fill">
                <TextBox
                  placeholder={TXT("label.search")}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern}
                />
              </Div>
            )}
            {onItemCreate && (
              <Div layout="fit">
                <Button
                  primary={true}
                  className="action"
                  onClick={onItemCreate}
                >
                  {formatMessage(TXT("action.create"))}
                </Button>
              </Div>
            )}
          </Div>
        </Div>
      )}
      {onItemFilter && (
        <Div>
          <Div layout="grid 12 6@md 3@lg">
            {showCategories && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.passengerCategory")}
                  searchable={true}
                  fill={true}
                  name="filter-passengerCategory"
                  data={passengerCategories}
                  value={passengerCategory}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handlePassengerCategory}
                />
              </Div>
            )}
            {showKinds && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.serviceKind")}
                  searchable={true}
                  fill={true}
                  name="filter-rateKind"
                  data={rateKinds}
                  value={rateKind}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handleKind}
                />
              </Div>
            )}
            {showTypes && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.serviceType")}
                  searchable={true}
                  fill={true}
                  name="filter-rateType"
                  data={rateTypes}
                  value={rateType}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handleType}
                />
              </Div>
            )}
            <Div>
              <ComboBox
                placeholder={TXT("label.airports")}
                searchable={true}
                fill={true}
                name={"filter-airport"}
                data={airports}
                value={airport}
                valueKey={"id"}
                valueLabel={"name"}
                onChange={handleAirport}
                onFetch={getAirports}
              />
            </Div>
            {showTimes && (
              <Div>
                <DateBox
                  fill={true}
                  name="filter-flightDate"
                  zone="UTC"
                  value={flightDate}
                  onChange={handleDate}
                />
              </Div>
            )}
          </Div>
        </Div>
      )}
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
            >
              <GridColumn
                title={TXT("label.code")}
                field="code"
                width={92}
                sortable={false}
                locked={true}
              />
              {showCategories && (
                <GridColumn
                  title={TXT("label.passengerCategory")}
                  field="categories"
                  width={128}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.rateFamily")}
                field="family"
                width={320}
                sortable={false}
              />
              {showGroup && (
                <GridColumn
                  title={TXT("label.groupRate")}
                  field="group"
                  width={128}
                  sortable={false}
                />
              )}
              {showKinds && (
                <GridColumn
                  title={TXT("label.serviceKind")}
                  field="kind"
                  width={128}
                  sortable={false}
                />
              )}
              {showTypes && (
                <GridColumn
                  title={TXT("label.serviceType")}
                  field="type"
                  width={128}
                  sortable={false}
                />
              )}
              {showTimes && (
                <GridColumn
                  title={TXT("label.flightDirection")}
                  field="flightDirection"
                  width={128}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.resource")}
                field="resource"
                width={256}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.name")}
                field="name"
                width={256}
                fill={true}
              />
              {showTimes && (
                <GridColumn
                  title={TXT("label.salesPeriod")}
                  field="bookablePeriod"
                  width={192}
                  sortable={false}
                />
              )}
              {showTimes && (
                <GridColumn
                  title={TXT("label.effectivePeriod")}
                  field="effectivePeriod"
                  width={192}
                  sortable={false}
                />
              )}
              {showTimes && (
                <GridColumn
                  title={TXT("label.effectiveTime")}
                  field="effectiveTime"
                  width={192}
                  sortable={false}
                />
              )}
              {showTimes && (
                <GridColumn
                  title={TXT("label.effectiveDays")}
                  field="effectiveDays"
                  width={192}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.cost")}
                field="value"
                width={128}
                sortable={false}
                locked={true}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}
