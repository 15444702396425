import axios, { AxiosInstance } from "axios";
import { Identity } from "../auth";

export class Client {
  public static instance(baseURL: string, responseType: "json" | "blob" = "json" ): Client {
    return new Client(baseURL, responseType);
  }

  private static handleError<TResult>(error: any): Promise<TResult> {
    if (error.response) {
      // The request was made and the server responded with a status code
      throw new ClientError(error.message, error.response.status, error.response.data);
    } else {
      throw error.request
        // The request was made but no response was received
        ? new ClientError(error.message)
        // Something happened in setting up the request that triggered an Error
        : new ClientError(error.message);
    }
  }

  private readonly baseURL: string;
  private readonly responseType: "json" | "blob";

  private constructor(baseURL: string, responseType: "json" | "blob" = "json") {
    this.baseURL = baseURL;
    this.responseType = responseType;
  }

  public async get<TResult>(url: string, urlParams?: any, queryParams?: any): Promise<TResult> {
    try {
      const pathPart = this.processUrlParameters(urlParams);
      const queryPart = this.processQueryParameters(queryParams);
      const instance = await this.ensureInstance();
      const response = await instance.get(`${url}${pathPart}${queryPart}`);
      return response.data;
    } catch (error) {
      return await Client.handleError(error);
    }
  }

  public async create<TRequest, TResult = TRequest>(url: string, data: TRequest, urlParams?: any, queryParams?: any): Promise<TResult> {
    try {
      const pathPart = this.processUrlParameters(urlParams);
      const queryPart = this.processQueryParameters(queryParams);
      const instance = await this.ensureInstance();
      const response = await instance.post(`${url}${pathPart}${queryPart}`, data);
      return response.data;
    } catch (error) {
      return await Client.handleError(error);
    }
  }

  public async update<TRequest, TResult = TRequest>(url: string, data: TRequest, urlParams?: any, queryParams?: any): Promise<TResult> {
    try {
      const pathPart = this.processUrlParameters(urlParams);
      const queryPart = this.processQueryParameters(queryParams);
      const instance = await this.ensureInstance();
      const response = await instance.put(`${url}${pathPart}${queryPart}`, data);
      return response.data;
    } catch (error) {
      return await Client.handleError(error);
    }
  }

  public async delete<TResult = any>(url: string, urlParams?: any): Promise<TResult> {
    try {
      const pathPart = this.processUrlParameters(urlParams);
      const instance = await this.ensureInstance();
      const response = await instance.delete(`${url}${pathPart}`);
      return response.data;
    } catch (error) {
      return await Client.handleError(error);
    }
  }

  public async modify<TRequest, TResult = TRequest>(url: string, data: TRequest, urlParams?: any, queryParams?: any): Promise<TResult> {
    try {
      const pathPart = this.processUrlParameters(urlParams);
      const queryPart = this.processQueryParameters(queryParams);
      const instance = await this.ensureInstance();
      const response = await instance.patch(`${url}${pathPart}${queryPart}`, data);
      return response.data;
    } catch (error) {
      return await Client.handleError(error);
    }
  }

  private async ensureInstance(): Promise<AxiosInstance> {
    const identity = await Identity.instance();
    const loggedIn = await identity.isLoggedIn();
    if (!loggedIn) {
      await identity.signIn();
    }
    const token = await identity.getToken();
    const headers = token.length > 0 ? { "Authorization": `Bearer ${token}` } : undefined;
    return axios.create({ baseURL: this.baseURL, headers, responseType: this.responseType, withCredentials: true, timeout: 15 * 60 * 1000 });
  }

  private processUrlParameters(urlParams: any): string {
    const urlParamValues: string[] = [];
    if (urlParams) {
      Object.keys(urlParams).forEach(key => {
        const value = urlParams[key];
        if (value) {
          urlParamValues.push(encodeURIComponent(value));
        }
      });
    }

    return urlParamValues.length > 0 ? `/${urlParamValues.join("/")}` : "";
  }

  private processQueryParameters(queryParams: any): string {
    const queryParamNameValues: string[] = [];
    if (queryParams) {
      Object.keys(queryParams).forEach(key => {
        const value = queryParams[key];
        if (value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach(v => {
              queryParamNameValues.push(`${key}=${encodeURIComponent(v)}`);
            })
          } else {
            queryParamNameValues.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
      })
    }

    return queryParamNameValues.length > 0 ? `?${queryParamNameValues.join("&")}` : "";
  }
}

export class ClientError extends Error {
  public readonly status?: number;
  public readonly data?: any;

  public constructor(message: string, status?: number, data?: any) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = ClientError.name;
    this.status = status;
    this.data = data;
  }
}
