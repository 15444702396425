import { AirportBaseV1 } from "./Airport";
import { CityBaseV1 } from "./City";
import { IReference } from "./IReference";

export enum OrganizationTypeV1 {
  System = "system",
  Provider = "provider",
  Agency = "agency",
}

export class OrganizationReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class OrganizationRecord extends OrganizationReferenceV1{
  code: string;
  name: string;
  type: OrganizationTypeV1;
  city: string;
  hasActiveContracts: boolean;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
    this.type = OrganizationTypeV1.System;
    this.city = "";
    this.hasActiveContracts = false;
  }
}

export class OrganizationBaseV1 extends OrganizationReferenceV1 {
  public code: string;
  public name: string;
  public type: OrganizationTypeV1;
  public currency: string;

  public constructor(id?: number, code?: string, name?: string, type?: OrganizationTypeV1) {
    super(id);
    this.code = code || "";
    this.name = name || "";
    this.type = type || OrganizationTypeV1.System;
    this.currency = "RUB";
  }
}

export class OrganizationV1 extends OrganizationBaseV1 {
  public city: CityBaseV1;
  public airports: AirportBaseV1[] | null;
  public legalRequisites: LegalRequisitesV1 | null;
  public paymentRequisites: PaymentRequisitesV1[] | null;

  public constructor() {
    super();
    this.city = new CityBaseV1();
    this.airports = null;
    this.legalRequisites = null;
    this.paymentRequisites = null;
  }
}

export class LegalRequisitesV1 {
  public legalAddress: string | null;
  public postalAddress: string | null;
  public taxIdentificationNumber: string | null;
  public taxRegistrationReasonCode: string | null;
  public primaryStateRegistrationNumber: string | null;
  public generalPhone: string | null;
  public supportPhone: string | null;
  public fax: string | null;
  public email: string | null;
  public chiefExecutiveOfficer: string | null;
  public chiefAccountant: string | null;

  public constructor() {
    this.legalAddress = null;
    this.postalAddress = null;
    this.taxIdentificationNumber = null;
    this.taxRegistrationReasonCode = null;
    this.primaryStateRegistrationNumber = null;
    this.generalPhone = null;
    this.supportPhone = null;
    this.fax = null;
    this.email = null;
    this.chiefExecutiveOfficer = null;
    this.chiefAccountant = null;
  }
}

export class PaymentRequisitesV1 {
  public id: string;
  public paymentAccount: string | null;
  public correspondentAccount: string | null;
  public bankName: string | null;
  public bankIdentificationCode: string | null;

  public constructor() {
    this.id = "";
    this.paymentAccount = null;
    this.correspondentAccount = null;
    this.bankName = null;
    this.bankIdentificationCode = null;
  }
}
