import { classNames } from "@progress/kendo-react-common";
import * as KendoDialogs from "@progress/kendo-react-dialogs";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "../hooks";
import { Button } from "./Button";
import { ActionHandler, GearProps, IntlText } from "./types";
import { TXT } from "./utils";

export interface DialogProps extends GearProps {
  title: IntlText;
  labelAccept?: IntlText;
  labelReject?: IntlText;
  labelCancel?: IntlText;
  canAccept?: boolean;
  canReject?: boolean;
  canCancel?: boolean;
  onAccept?: ActionHandler;
  onReject?: ActionHandler;
  onCancel?: ActionHandler;
}

export function Dialog(props: DialogProps): ReactElement {
  const { children, className, title, labelAccept, labelReject, labelCancel, canAccept, canReject, canCancel, onAccept, onReject, onCancel } = props;

  const formatMessage = useFormatMessage();

  return (
    <KendoDialogs.Dialog className={classNames("g-dialog", className)}
                         title={formatMessage(title)}
                         onClose={onCancel}>
      <React.Fragment>
        {children}
      </React.Fragment>
      <KendoDialogs.DialogActionsBar>
        {onCancel &&
        <Button disabled={canCancel !== undefined && !canCancel} onClick={onCancel}>
          {labelCancel ? <FormattedMessage {...labelCancel} /> : <FormattedMessage {...TXT("action.cancel")} />}
        </Button>
        }
        {onReject &&
        <Button disabled={canReject !== undefined && !canReject} intent="danger" onClick={onReject}>
          {labelReject ? <FormattedMessage {...labelReject} /> : <FormattedMessage {...TXT("action.reject")} />}
        </Button>
        }
        {onAccept &&
        <Button disabled={canAccept !== undefined && !canAccept} primary={true} onClick={onAccept}>
          {labelAccept ? <FormattedMessage {...labelAccept} /> : <FormattedMessage {...TXT("action.accept")} />}
        </Button>
        }
      </KendoDialogs.DialogActionsBar>
    </KendoDialogs.Dialog>
  );
}
