export enum PassengerCategory
{
  Adult = "Adult",
  Child = "Child",
  Infant = "Infant",
  Group = "Group",
}

export enum PassengerType
{
  Primary = "Primary",
  Secondary = "Secondary",
  Guest = "Guest",
  Assistant = "Assistant",
  Service = "Service",
}

export enum PassengerKlass
{
  Business = "Business",
  First = "First",
}

export class PassengerReference {
  public id: number;

  public constructor() {
    this.id = 0;
  }
}

export class PassengerBase extends PassengerReference {
  public type: PassengerType;
  public givenName: string | null;
  public middleName: string | null;
  public familyName: string | null;
  public dateOfBirth: string | null;
  public email: string | null;
  public phone: string | null;

  public constructor() {
    super();
    this.type = PassengerType.Primary;
    this.givenName = null;
    this.middleName = null;
    this.familyName = null;
    this.dateOfBirth = null;
    this.email = null;
    this.phone = null;
  }
}

export class Passenger extends PassengerBase {
  public ticket: string | null;

  public constructor() {
    super();
    this.ticket = null;
  }
}
