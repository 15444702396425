import { IReference } from "./IReference";
import { OrganizationBaseV1, PaymentRequisitesV1 } from "./Organization";

export enum PaymentModeV1 {
  Online = "online",
  Offline = "offline",
}

export enum ContractPaymentTypeV1 {
  None = "none",
  Deposit = "deposit",
}

export class ContractReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class ContractRecord extends ContractReferenceV1 {
  public number: string;
  public provider: string;
  public agency: string;
  public concludedAt: string;
  public isAuthorized: boolean;

  public constructor() {
    super();
    this.number = "";
    this.provider = "";
    this.agency = "";
    this.concludedAt = "";
    this.isAuthorized = false;
  }
}

export class ContractBaseV1 extends ContractReferenceV1 {
  public number: string;
  public concludedAt: string;
  public provider: OrganizationBaseV1;
  public agency: OrganizationBaseV1;
  public paymentType: ContractPaymentTypeV1;

  public constructor() {
    super();
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    this.number = "";
    this.concludedAt = `${year}-${month}-01T00:00:00.000Z`;
    this.provider = new OrganizationBaseV1();
    this.agency = new OrganizationBaseV1();
    this.paymentType = ContractPaymentTypeV1.None;
  }
}

export class ContractV1 extends ContractBaseV1 {
  public overdraft: number | null;
  public isAuthorized: boolean;
  public providerRequisites: PaymentRequisitesV1 | null;
  public agencyRequisites: PaymentRequisitesV1 | null;

  public constructor() {
    super();
    this.overdraft = null;
    this.isAuthorized = false;
    this.providerRequisites = null;
    this.agencyRequisites = null;
  }
}

export enum ContractDepositOperationTypeV1 {
  Replenish = "replenish",
  Withdraw = "withdraw",
  Return = "return",
  Fine = "fine",
}

export class ContractDepositOperationV1 {
  public id: number;
  public operationType: ContractDepositOperationTypeV1;
  public value: number;
  public performedAt: string;
  public performedBy: string;

  public constructor() {
    this.id = 0;
    this.operationType = ContractDepositOperationTypeV1.Replenish;
    this.value = 0;
    this.performedAt = "";
    this.performedBy = "";
  }
}

export class ContractDepositUpdateModelV1 {
  public value: number;

  public constructor() {
    this.value = 0;
  }
}
