import React, { PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface TooltipProps {
  title: IntlMessage;
}

interface TooltipProps {
  children?: null;
}

interface TooltipState {
}

export class Tooltip extends PureComponent<TooltipProps, TooltipState> {
  public constructor(props: TooltipProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { title } = this.props;

    return (
      <Intl render={intl => <span className="k-icon k-i-question g-tooltip" title={getIntlMessage(intl, title)} />} />
    );
  }
}
