import React, { ReactElement, useCallback, useEffect } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { NavPathItem } from "../gears";
import { ReportAccess } from "../parts/data/access/ReportAccess";
import { ReportParameters } from "../parts/data/models";
import { Loader, TXT } from "../parts/gears";
import { ReportBuilder } from "../parts/views";
import { PageProps, withPage } from "./withPage";

function ReportsPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props;

  return (
    <Switch>
      <Route exact={true}
             path={`${match.path}`}
             render={props => <ReportBuilderPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
    </Switch>
  );
}

interface ReportBuilderPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ReportBuilderPage(props: ReportBuilderPageProps): ReactElement {
  const { onLoaded, onError } = props;

  useEffect(() => {
    onLoaded([]);
  }, [ onLoaded ]);

  const handleSubmit = useCallback(async (item: ReportParameters): Promise<void> => {
    try {
      const id = await ReportAccess.create(item);
      const url = await ReportAccess.getUrl(id);
      const link = document.createElement("a");
      link.href = url;
      link.download = url;
      link.click();
    } catch (error) {
      onError(error);
    }
  }, [ onError ]);

  return (
    <React.Fragment>
      <Loader loading={false}>
        <ReportBuilder onSubmit={handleSubmit} />
      </Loader>
    </React.Fragment>
  );
}

function pathBuilder(): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: TXT("page.reports"),
  });

  return pathItems;
}

export const Reports = withPage(ReportsPage, pathBuilder);
Reports.displayName = "Reports";
