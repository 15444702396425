import { CurrencyBase, CurrencyReference, EMPTY_ID, FlightDirection, OrganizationContractBase, OrganizationContractReference, OrganizationProgramBase, OrganizationProgramReference, PassengerCategory, PoolBase, PoolReference, RateRule, ResourceBase, ResourceReference, ServiceBase, ServiceKind, ServiceReference, ServiceType, Transfer } from './index'

export interface RateFilter {
  byPoolId?: string | string[];
  byContractId?: string;
  byResourceId?: string;
  byServiceId?: string;
  byAirportId?: string;
  byCategory?: PassengerCategory;
  byKind?: ServiceKind;
  byType?: ServiceType;
  byFlightDirection?: FlightDirection;
  byFlightDate?: string;
  isShared?: boolean;
  isPrimary?: boolean;
}

export class RateReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class RateBase extends RateReference {
  public code: string
  public name: string
  public kind: ServiceKind
  public type: ServiceType
  public flightDirection: FlightDirection
  public bookableFrom: string
  public bookableTill: string
  public validFrom: string
  public validTill: string
  public validFromTime: string
  public validTillTime: string
  public validDays: string[]
  public validRenewal: boolean

  public constructor() {
    super()
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0')
    this.code = ''
    this.name = ''
    this.kind = ServiceKind.Any
    this.type = ServiceType.Any
    this.flightDirection = FlightDirection.Any
    this.bookableFrom = `${year}-${month}-01T00:00:00.000Z`
    this.bookableTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFrom = `${year}-${month}-01T00:00:00.000Z`
    this.validTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFromTime = '00:00'
    this.validTillTime = '23:59'
    this.validDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    this.validRenewal = false
  }
}

export class RateCost {
  public id: string
  public category: PassengerCategory
  public type: ServiceType
  public appliedFrom: string | null
  public appliedTill: string | null
  public transfers: Transfer[] | null
  public value: number

  public constructor(category?: PassengerCategory, type?: ServiceType, value?: number) {
    this.id = EMPTY_ID
    this.category = category || PassengerCategory.Adult
    this.type = type || ServiceType.Any
    this.appliedFrom = null
    this.appliedTill = null
    this.transfers = null
    this.value = value || 0
  }
}

export class RateFine {
  public id: string
  public type: ServiceType
  public flightDelta: string
  public valueAfter: number
  public valueBefore: number

  public constructor(type?: ServiceType, flightDelta?: string, valueAfter?: number, valueBefore?: number) {
    this.id = EMPTY_ID
    this.type = type || ServiceType.Any
    this.flightDelta = flightDelta || ''
    this.valueAfter = valueAfter || 0
    this.valueBefore = valueBefore || 0
  }
}

export class Rate<TPool extends PoolReference = PoolReference,
  TCurrency extends CurrencyReference = CurrencyReference,
  TResource extends ResourceReference = ResourceReference,
  TService extends  ServiceReference = ServiceReference,
  TOrganizationProgram extends OrganizationProgramReference = OrganizationProgramReference,
  TOrganizationContract extends OrganizationContractReference = OrganizationContractReference> extends RateBase {
  public pool: TPool
  public resource: TResource | null
  public service: TService | null
  public program: TOrganizationProgram | null
  public contract: TOrganizationContract | null
  public costs: RateCost[]
  public fines: RateFine[]
  public rules: RateRule[]
  public currency: TCurrency

  public constructor(TPool: new() => TPool, TCurrency: new() => TCurrency) {
    super()
    this.pool = new TPool()
    this.resource = null
    this.service = null
    this.program = null
    this.contract = null
    this.costs = []
    this.fines = []
    this.rules = []
    this.currency = new TCurrency()
  }
}

export class ResolvedRate extends Rate<PoolBase, CurrencyBase, ResourceBase, ServiceBase, OrganizationProgramBase, OrganizationContractBase> {
  public constructor() {
    super(PoolBase, CurrencyBase)
  }
}

export class RateCostUpdateModel {
  public category: PassengerCategory
  public type: ServiceType
  public appliedFrom: string | null
  public appliedTill: string | null
  public value: number

  public constructor() {
    this.category = PassengerCategory.Adult
    this.type = ServiceType.Any
    this.appliedFrom = null
    this.appliedTill = null
    this.value = 0
  }
}

export class RateFineUpdateModel {
  public type: ServiceType
  public flightDelta: string
  public valueAfter: number
  public valueBefore: number

  public constructor() {
    this.type = ServiceType.Any
    this.flightDelta = ''
    this.valueAfter = 0
    this.valueBefore = 0
  }
}

export class RateUpdateModel {
  public name: string
  public bookableFrom: string
  public bookableTill: string
  public validFrom: string
  public validTill: string
  public validFromTime: string
  public validTillTime: string
  public validDays: string[]
  public costs: RateCostUpdateModel[]
  public fines: RateFineUpdateModel[]

  public constructor() {
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0')
    this.name = ''
    this.bookableFrom = `${year}-${month}-01T00:00:00.000Z`
    this.bookableTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFrom = `${year}-${month}-01T00:00:00.000Z`
    this.validTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFromTime = '00:00'
    this.validTillTime = '23:59'
    this.validDays = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ]
    this.costs = []
    this.fines = []
  }
}

export class RateCreateModel extends RateUpdateModel {
  public pool: PoolReference
  public resource: ResourceReference | null
  public service: ServiceReference | null
  public kind: ServiceKind
  public type: ServiceType
  public flightDirection: FlightDirection
  public program: OrganizationProgramReference | null
  public contract: OrganizationContractReference | null

  public constructor() {
    super()
    this.pool = new PoolReference()
    this.resource = null
    this.service = null
    this.kind = ServiceKind.Any
    this.type = ServiceType.Any
    this.flightDirection = FlightDirection.Any
    this.program = null
    this.contract = null
  }
}
