import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { SwitchBox } from "../../gears/inputs";
import {
  ConfirmedResource,
  ConfirmedService, FlightSpan,
  Order,
  OrderConfirmModel,
  RatedResource,
  RatedService,
  SeatStatus,
} from '../data/models'
import { Button, Div, TXT } from "../gears";
import { useFormatDate, useFormatDateRange, useFormatMessage } from "../hooks";
import { fieldOrValue } from "../utils";
import { ViewProps } from "./types";

export interface OrderConfirmViewProps extends ViewProps<null> {
  calc: Order;
  item: OrderConfirmModel;
  onChange: (item: OrderConfirmModel) => void;
}

export function OrderConfirmView(props: OrderConfirmViewProps): ReactElement {
  const { calc, item, onChange } = props;

  const formatDate = useFormatDate();
  const formatDateRange = useFormatDateRange();
  const formatMessage = useFormatMessage();

  const [ canResourceBeConfirmed, setCanResourceBeConfirmed ] = useState<Map<string, boolean>>(new Map());
  const [ canServiceBeConfirmed, setCanServiceBeConfirmed ] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    const canResourceBeConfirmed = new Map<string, boolean>();
    const canServiceBeConfirmed = new Map<string, boolean>();

    const confirmedResources: ConfirmedResource[] = [];
    for (const ratedResource of calc.spans.flatMap(span => span.resources).filter(x => x.passengers.every(y => y.status !== SeatStatus.Returned))) {
      const confirmedResource = new ConfirmedResource();
      confirmedResource.id = ratedResource.id;
      confirmedResource.confirmed = !ratedResource.passengers.some(x => x.confirmedAt === null);
      canResourceBeConfirmed.set(confirmedResource.id.toString(), !confirmedResource.confirmed);

      confirmedResource.services = [];
      for (const ratedService of (ratedResource.services || []).filter(x => x.status !== SeatStatus.Returned)) {
        const confirmedService = new ConfirmedService();
        confirmedService.id = ratedService.id;
        confirmedService.confirmed = ratedService.confirmedAt !== null
        canServiceBeConfirmed.set(`${confirmedResource.id.toString()}-${confirmedService.id.toString()}`, !confirmedService.confirmed);

        confirmedResource.services.push(confirmedService);
      }

      confirmedResources.push(confirmedResource);
    }

    setCanResourceBeConfirmed(canResourceBeConfirmed);
    setCanServiceBeConfirmed(canServiceBeConfirmed);

    onChange({ resources: confirmedResources });
  }, [ calc, onChange ]);
  
  useEffect(
    () => {
      let changed = false
      const changedItem = { ...item }
      
      const getSpan = (confirmedResource: ConfirmedResource): FlightSpan => {
        return calc.spans.filter(span => span.resources.flatMap(ratedResource => ratedResource.id).some(id => id === confirmedResource.id))[0]
      }
      
      for (const confirmedResource of item.resources.filter(x => canResourceBeConfirmed.get(x.id.toString()))) {
        const span = getSpan(confirmedResource)
        for (const c of item.resources) {
          if (span === getSpan(c) && canResourceBeConfirmed.get(c.id.toString()) && c.confirmed !== confirmedResource.confirmed) {
            c.confirmed = confirmedResource.confirmed
            changed = true
          }
        }
      }
      
      if (changed) {
        onChange(changedItem)
      }
    },
    [ item, calc, canResourceBeConfirmed, onChange ]
  )

  const handleAll = useCallback((selected: boolean): void => {
    const changedItem = { ...item };
    for (const confirmedResource of item.resources) {
      if (canResourceBeConfirmed.get(confirmedResource.id.toString())) {
        confirmedResource.confirmed = selected;
      }
      for (const confirmedService of confirmedResource.services) {
        if (canServiceBeConfirmed.get(`${confirmedResource.id.toString()}-${confirmedService.id.toString()}`)) {
          confirmedService.confirmed = selected;
        }
      }
    }

    onChange(changedItem);
  }, [ item, onChange, canResourceBeConfirmed, canServiceBeConfirmed ]);

  const handleSelectAll = useCallback((): void => {
    handleAll(true);
  }, [ handleAll ]);

  const handleDeselectAll = useCallback((): void => {
    handleAll(false);
  }, [ handleAll ]);

  const renderResource = useCallback((item: OrderConfirmModel, ratedResource: RatedResource, ratedResourceIndex: number): ReactElement => {
    const getSpan = (confirmedResource: ConfirmedResource): FlightSpan => {
      return calc.spans.filter(span => span.resources.flatMap(ratedResource => ratedResource.id).some(id => id === confirmedResource.id))[0]
    }
    
    const handleChange = (confirmed: boolean): void => {
      const confirmedResources = [ ...item.resources ];
      const confirmedResource = { ...confirmedResources[ratedResourceIndex], confirmed };
      confirmedResources.splice(ratedResourceIndex, 1, confirmedResource);
      
      const span = getSpan(confirmedResource)
      for (const spannedResource of confirmedResources.filter(x => canResourceBeConfirmed.get(x.id.toString()) && x.id !== confirmedResource.id && span.resources.some(y => y.id === x.id))) {
        const spannedResourceIndex = confirmedResources.indexOf(spannedResource)
        confirmedResources.splice(spannedResourceIndex, 1, { ...spannedResource, confirmed })
      }

      onChange({ resources: confirmedResources });
    };

    return (
      <SwitchBox label={`${ratedResource.resource.name} / ${ratedResource.flights[0].number} / ${formatDate(ratedResource.flights[0].date, true)}`}
                 disabled={!canResourceBeConfirmed.get(ratedResource.id.toString())}
                 mode="check"
                 name={`resource-${ratedResourceIndex}-confirmed`}
                 value={fieldOrValue(item.resources, ratedResourceIndex, "confirmed", ratedResource.passengers.every(x => x.status === SeatStatus.Rated))}
                 onChange={handleChange} />
    );
  }, [ calc.spans, formatDate, canResourceBeConfirmed, onChange ]);

  const renderService = useCallback((item: OrderConfirmModel, ratedResource: RatedResource, ratedResourceIndex: number, ratedService: RatedService, ratedServiceIndex): ReactElement => {
    const handleChange = (confirmed: boolean): void => {
      const confirmedResources = [ ...item.resources ];
      const confirmedResource = { ...confirmedResources[ratedResourceIndex] };
      const confirmedServices = [ ...confirmedResource.services ];
      const confirmedService = { ...confirmedServices[ratedServiceIndex], confirmed };
      if (confirmed) {
        confirmedResource.confirmed = true;
      }
      confirmedServices.splice(ratedServiceIndex, 1, confirmedService);
      confirmedResource.services = confirmedServices;
      confirmedResources.splice(ratedResourceIndex, 1, confirmedResource);

      onChange({ resources: confirmedResources });
    };

    let label = ratedService.service.name;
    if (ratedService.from && ratedService.till) {
      label = `${label} / ${formatDateRange(ratedService.from, ratedService.till, true)}`;
    }

    return (
      <SwitchBox label={label}
                 disabled={!canServiceBeConfirmed.get(`${ratedResource.id.toString()}-${ratedService.id.toString()}`)}
                 mode="check"
                 name={`resource-${ratedResourceIndex}-service-${ratedServiceIndex}-confirmed`}
                 value={fieldOrValue(fieldOrValue(item.resources, ratedResourceIndex, "services", []), ratedServiceIndex, "confirmed", ratedService.status === SeatStatus.Rated)}
                 onChange={handleChange} />
    );
  }, [ formatDateRange, canServiceBeConfirmed, onChange ]);

  return (
    <Div layout="grid 12">
      <Div>
        <ul>
          {calc.spans.flatMap(ratedSegment => ratedSegment.resources).map((ratedResource, ratedResourceIndex) => (
            <li key={ratedResourceIndex}>
              {renderResource(item, ratedResource, ratedResourceIndex)}
              {ratedResource.services &&
              <ul>
                {ratedResource.services.map((ratedService, ratedServiceIndex) => (
                  <li key={ratedServiceIndex}>
                    {renderService(item, ratedResource, ratedResourceIndex, ratedService, ratedServiceIndex)}
                  </li>
                ))}
              </ul>
              }
            </li>
          ))}
        </ul>
      </Div>
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" onClick={handleSelectAll}>{formatMessage(TXT("action.selectAll"))}</Button>
            <Button look="bare" onClick={handleDeselectAll}>{formatMessage(TXT("action.deselectAll"))}</Button>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
