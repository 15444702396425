import * as KendoPopups from "@progress/kendo-react-popup";
import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { IntlProvider } from "../assets";

interface DrawerProps {
  onClose?: () => void;
}

export function Drawer(props: Readonly<PropsWithChildren<DrawerProps>>): ReactElement {
  const { children } = props;

  const onClose = useCallback(props.onClose ? props.onClose : () => undefined, []);

  const [ offset, setOffset ] = useState<{ left: number; top: number; }>({ left: 0, top: 0 });
  const divRef = useRef<HTMLDivElement>(null);

  const update = useCallback((): void => {
    setOffset({ left: document.documentElement.clientWidth, top: document.documentElement.scrollTop });
  }, []);

  const mouseupOutside = useCallback((event: MouseEvent): void => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      onClose();
    }
  }, [ onClose ]);

  useEffect(() => {
    update();
    document.addEventListener("resize", update);
    document.addEventListener("scroll", update);

    return () => {
      document.removeEventListener("resize", update);
      document.removeEventListener("scroll", update);
    };
  }, [ update ]);

  useEffect(() => {
    document.addEventListener("mouseup", mouseupOutside);

    return () => {
      document.removeEventListener("mouseup", mouseupOutside);
    };
  }, [ mouseupOutside ]);

  return (
    <KendoPopups.Popup animate={false} offset={offset} show={true} popupClass="g-drawer">
      <div ref={divRef} className="g-drawer-inner">
        <IntlProvider>
          <div className="g-drawer-inner-content">
            {children}
          </div>
        </IntlProvider>
      </div>
    </KendoPopups.Popup>
  );
}
