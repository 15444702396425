import React, { ReactElement, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import ReactDOM from 'react-dom'
import { useHistory, useLocation } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { IntlProvider } from './assets'
import { AuthContext, AuthContextValue, Identity, IdentityProfile } from './auth'
import * as serviceWorker from './serviceWorker'

import './index.scss'

export function Authenticated(): ReactElement {
  const [authenticated, setAuthenticated] = useState(false)
  const [context, setContext] = useState<AuthContextValue>({ authenticated: false, profile: new IdentityProfile() })

  const [cookies, setCookie, removeCookie] = useCookies()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const init = async (): Promise<void> => {
      const response = await fetch('configuration/version')
      const expectedVersion = await response.text()
      const currentVersion = process.env.REACT_APP_VERSION
      if (expectedVersion !== currentVersion) window.location.reload()
    }
    init().finally();
  }, [])

  const pathname = cookies["pathname"]

  useEffect(() => {
    const init = async (): Promise<void> => {
      const identity = await Identity.instance()
      const authenticated = await identity.isLoggedIn()
      if (authenticated) {
        const profile = await identity.getProfile()
        const context = { authenticated, profile }
        setContext(context)

        removeCookie("pathname")
        if (pathname) {
          history.push(pathname)
        }
      } else {
        removeCookie("pathname")
        setCookie("pathname", location.pathname, { sameSite: 'strict', path: '/' })
        await identity.signIn()
      }
      setAuthenticated(authenticated)
    }

    init()
      .catch(reason => console.error(reason))
      .finally()
  }, [
    //pathname, history, location, setCookie, removeCookie,
    setAuthenticated, setContext,
  ])

  if (authenticated) {
    return (
      <AuthContext.Provider value={context}>
        <App />
      </AuthContext.Provider>
    )
  } else {
    return (
      <div />
    )
  }
}

ReactDOM.render(
  <BrowserRouter>
    <IntlProvider>
      <Authenticated />
    </IntlProvider>
  </BrowserRouter>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
