import { RatedFlight } from './RatedFlight'
import { RatedPassenger } from './RatedPassenger'
import { RatedService } from './RatedService'
import { ResourceBase } from './Resource'

export class RatedResource {
  public id: number
  public resource: ResourceBase
  public flights: RatedFlight[]
  public passengers: RatedPassenger[]
  public services: RatedService[] | null

  public constructor() {
    this.id = 0
    this.resource = new ResourceBase()
    this.flights = []
    this.passengers = []
    this.services = null
  }
}
