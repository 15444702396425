import { Apis, Chunk, Client } from "../../../data";
import { AccessProgram } from "../../../data/models";

export class AccessProgramAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<AccessProgram>> {
    return await Client.instance(await Apis.getGatewayV0())
      .get<Chunk<AccessProgram>>("access-programs", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }
}
