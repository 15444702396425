import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import {
  MediaBaseV1,
  MediaKindV1,
  ResourceV1,
  ResourceBaseV1,
  ResourceDefaultsV1,
  ResourceRecord,
  User,
  UserBase,
} from "../../../data/models";

export class ResourceAccessV1 {
  public static async fetchItem(id: number): Promise<ResourceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<ResourceV1>("Resource", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number, belongsTo?: string, airportId?: string, withContracts?: boolean, lounges?: boolean, includeTransfers?: boolean): Promise<Chunk<ResourceBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ResourceBaseV1>>("Resource", {}, {
        pattern,
        orderBy,
        skip,
        take,
        belongsTo,
        airportId,
        withContracts,
        lounges,
        includeTransfers,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number, belongsTo?: string, airportId?: string, withContracts?: boolean, includeTransfers?: boolean): Promise<Chunk<ResourceRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ResourceRecord>>("Resource/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        belongsTo,
        airportId,
        withContracts,
        includeTransfers,
      });
  }

  public static async fetchSuggestions(top?: number): Promise<Chunk<ResourceBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ResourceBaseV1>>("Resource/suggestions", {}, {
        top,
      });
  }

  public static async fetchSubscribers(id: number): Promise<Chunk<User>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<User>>("Resource", { id, subscribers: "subscribers" });
  }

  public static async create(item: ResourceV1): Promise<ResourceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Resource", item);
  }

  public static async update(id: number, item: ResourceV1): Promise<ResourceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update("Resource", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    return await Client
      .instance(await Apis.getDataApi())
      .delete("Resource", { id });
  }

  public static async fetchDefaults(provider: number | string): Promise<ResourceDefaultsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<ResourceDefaultsV1>("Resource/defaults", { provider });
  }

  public static async updateDefaults(provider: number | string, model: ResourceDefaultsV1): Promise<ResourceDefaultsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<ResourceDefaultsV1>("Resource/defaults", model, { provider });
  }

  public static async fetchMedia(id: number, kind: MediaKindV1): Promise<MediaBaseV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<MediaBaseV1[]>("Media", {}, { resourceId: id, kind });
  }

  public static async updateMedia(id: number, updated: MediaBaseV1[], kind: MediaKindV1): Promise<MediaBaseV1[]> {
    const result: MediaBaseV1[] = [];

    const existed = await this.fetchMedia(id, kind);
    const removed = existed.filter(item => !updated.map(image => image.id).some(id => item.id === id));

    for (const image of updated) {
      if (image.id !== 0) {
        const updatedImage = await Client.instance(await Apis.getDataApi()).update("Media", image, { id: image.id });
        result.push(updatedImage);
      } else {
        const createdImage = await Client.instance(await Apis.getDataApi()).create("Media/resource", image, { resourceId: id });
        result.push(createdImage);
      }
    }

    for (const image of removed) {
      if (image.id !== 0) {
        await Client.instance(await Apis.getDataApi()).delete("Media", { id: image.id });
      }
    }

    return result;
  }

  public static async updateSubscribers(id: number, updated: UserBase[]): Promise<void> {
    const existed = (await this.fetchSubscribers(id)).data;
    const removed = existed.filter(existedItem => !updated.map(updatedItem => updatedItem.id).some(id => existedItem.id === id));

    for (const item of updated) {
      await Client
        .instance(await Apis.getDataApi())
        .update(`Resource/${id}/subscribers/${item.id}`, {});
    }

    for (const item of removed) {
      await Client
        .instance(await Apis.getDataApi())
        .delete(`Resource/${id}/subscribers/${item.id}`, {});
    }
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Resource/lock", { id }, { action })
  }
}
