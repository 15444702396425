import { Apis, Client } from "../../../data";
import { ReportParameters } from "../models";

export class ReportAccess {
  public static async getUrl(id: string): Promise<string> {
    const base = await Apis.getGatewayV0();
    return `${base}/reports/${id}`;
  }

  public static async create(item: ReportParameters): Promise<string> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`reports`, item);
  }
}
