import { CorporateContractBaseV1, CorporateContractReferenceV1 } from "./CorporateContract";
import { IReference } from "./IReference";
import { OrganizationBaseV1, OrganizationReferenceV1 } from "./Organization";

export enum ReportTypeV1 {
  Sale = "sale",
  Load = "load",
  Free = "free",
}

export enum ReportStatusV1 {
  Waiting = "waiting",
  Processing = "processing",
  Finished = "finished",
  Aborted = "aborted",
}

export class ReportQueryV1 {
  public type: ReportTypeV1;
  public from: string;
  public till: string;
  public card: CorporateContractReferenceV1 | null;
  public organization: OrganizationReferenceV1;

  public constructor() {
    this.type = ReportTypeV1.Sale;
    this.from = "";
    this.till = "";
    this.card = null;
    this.organization = new OrganizationReferenceV1();
  }
}

export class ReportReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class ReportV1 extends ReportReferenceV1 {
  public type: ReportTypeV1;
  public from: string;
  public till: string;
  public card: CorporateContractBaseV1 | null;
  public organization: OrganizationBaseV1;
  public status: ReportStatusV1;
  public downloadId: string;

  public constructor() {
    super();
    this.type = ReportTypeV1.Sale;
    this.status = ReportStatusV1.Waiting;
    this.from = "";
    this.till = "";
    this.card = null;
    this.organization = new OrganizationBaseV1();
    this.downloadId = "";
  }
}

export class ReportRecord {
  public orderId: number;

  public constructor() {
    this.orderId = 0;
  }
}
