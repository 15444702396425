import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chunk, Pair } from '../../data'
import { RateFamilyV1, RateV1 } from '../../data/models'
import { RateAccess, RateAccessV1 } from '../data/access'
import { RateFilter, ResolvedRate } from '../data/models'
import { FetchList, PairConverter, PairData } from './types'

export function useRates(pageSize?: number, prefetch?: boolean, filter?: RateFilter): [ Chunk<ResolvedRate>, FetchList ] {
  const [ data, setData ] = useState<Chunk<ResolvedRate>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await RateAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter)
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, filter, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useRate(id: string | null): [ ResolvedRate | null, Dispatch<SetStateAction<ResolvedRate | null>> ] {
  const [ item, setItem ] = useState<ResolvedRate | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await RateAccess.getOne(id))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ id, setItem ])

  return [ item, setItem ]
}

export function useRateFamiliesV1(): [ PairData<RateFamilyV1>, PairConverter<RateFamilyV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<RateFamilyV1>>([
    new Pair(RateFamilyV1.Standard, intl.formatMessage({ id: "enum.rateFamily.standard" })),
    // new Pair(RateFamilyV1.Contract, intl.formatMessage({ id: "enum.rateFamily.contract" })),
    // new Pair(RateFamilyV1.Program, intl.formatMessage({ id: "enum.rateFamily.program" })),
  ]);

  const converter = useCallback((value: RateFamilyV1): string => {
    switch (value) {
      case RateFamilyV1.Standard:
        return intl.formatMessage({ id: "enum.rateFamily.standard" });
      case RateFamilyV1.Contract:
        return intl.formatMessage({ id: "enum.rateFamily.contract" });
      case RateFamilyV1.Program:
        return intl.formatMessage({ id: "enum.rateFamily.program" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useRateScheduleValidationV1(): (item: RateV1) => Promise<RateV1[]> {
  return useCallback(async (item: RateV1): Promise<RateV1[]> => {
    try {
      return await RateAccessV1.check(item);
    } catch {
      return [];
    }
  }, []);
}
