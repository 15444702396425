import React, { PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface NavHeadProps {
  text: IntlMessage;
}

interface NavHeadProps {
  children?: null;
}

interface NavHeadState {
}

export class NavHead extends PureComponent<NavHeadProps, NavHeadState> {
  public constructor(props: NavHeadProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { text } = this.props;

    return (
      <Intl render={intl =>
        <span>{getIntlMessage(intl, text)}</span>
      } />
    );
  }
}
