import React, { ReactElement, useContext } from 'react'
import { Icons } from '../../assets'
import { Button, ButtonItem, Div, FormContext, ID, IntlFormat, Validator } from '../../gears'
import { DateBox, TextBox } from '../../gears/inputs'
import { email, length, phone, required } from '../../gears/validators'
import { Passenger, PassengerType } from '../data/models'

class LabeledPassenger {
  public key: number
  public text: string
  public passenger: Passenger

  public constructor(id: number, passenger: Passenger) {
    this.key = id
    this.text = `${passenger.familyName} ${passenger.givenName} ${passenger.middleName ? passenger.middleName : ''}`.trim()
    this.passenger = passenger
  }
}

export function PassengerList(props: Readonly<{
  disabled?: boolean;
  name: string;
  data?: Passenger[];
  value: Passenger[] | null;
  limit?: number | null;
  type?: PassengerType;
  hasTicket?: boolean;
  required?: boolean;
  infantAge?: number;
  childAge?: number;
  emailRequired?: boolean;
  phoneRequired?: boolean;
  onChange: (items: Passenger[]) => void;
}>): ReactElement {
  const context = useContext(FormContext)

  const {
    disabled,
    name,
    data,
    value,
    limit,
    type,
    hasTicket,
    infantAge,
    childAge,
    emailRequired,
    phoneRequired,
    onChange,
  } = props
  const enteredPassengers = value ? [ ...value ] : []
  const count = enteredPassengers.filter(passenger => passenger.type === (type || PassengerType.Primary)).length

  const now = new Date()

  const labeledPassengers = data
    ? data.filter(x => !enteredPassengers.some(y => x.type === y.type
      && x.familyName === y.familyName
      && x.middleName === y.middleName
      && x.dateOfBirth === y.dateOfBirth))
      .map((passenger, id) => new LabeledPassenger(id, passenger))
    : []

  const add = (item?: ButtonItem, index?: number): void => {
    if (item && index !== undefined) {
      enteredPassengers.push(labeledPassengers[index].passenger)
    } else {
      const passenger = new Passenger()
      passenger.type = type || PassengerType.Primary
      enteredPassengers.push(passenger)
    }
    onChange(enteredPassengers)
  }

  const remove = (index: number): void => {
    context.onFieldRemove(`${name}-${index}`, false)
    enteredPassengers.splice(index, 1)
    onChange(enteredPassengers)
  }

  const change = (index: number, fields: {
    givenName?: string | null;
    middleName?: string | null;
    familyName?: string | null;
    birthDate?: string | null;
    email?: string | null;
    phone?: string | null;
    ticket?: string | null;
  }): void => {
    const passenger = { ...enteredPassengers[index] }
    if (fields.givenName !== undefined) {
      passenger.givenName = fields.givenName || ''
    }
    if (fields.middleName !== undefined) {
      passenger.middleName = fields.middleName
    }
    if (fields.familyName !== undefined) {
      passenger.familyName = fields.familyName || ''
    }
    if (fields.birthDate !== undefined) {
      passenger.dateOfBirth = fields.birthDate || ''
    }
    if (fields.email !== undefined) {
      passenger.email = fields.email || ''
    }
    if (fields.phone !== undefined) {
      passenger.phone = fields.phone || ''
    }
    if (fields.ticket !== undefined) {
      passenger.ticket = fields.ticket
    }
    enteredPassengers.splice(index, 1, passenger)
    onChange(enteredPassengers)
  }

  const getDayOfYear = (value: Date): number => {
    const start = new Date(value.getFullYear(), 0, 0)
    const diff = value.valueOf() - start.valueOf()
    const oneDay = 1000 * 60 * 60 * 24
    return Math.floor(diff / oneDay)
  }

  const getAge = (value: Date): number => {
    const now = new Date()

    let age = now.getFullYear() - value.getFullYear()
    if (getDayOfYear(now) < getDayOfYear(value)) {
      age -= 1
    }

    return age
  }

  const renderAge = (passenger: Passenger): ReactElement | null => {
    const birthDate = new Date(passenger.dateOfBirth || '')
    if (isNaN(birthDate.valueOf())) {
      return <span className="icon">{Icons.Passengers.Unknown}</span>
    } else {
      const age = getAge(birthDate)
      let icon = Icons.Passengers.Adult
      if (age < (infantAge || 2)) {
        icon = Icons.Passengers.Infant
      } else if (age < (childAge || 12)) {
        icon = Icons.Passengers.Child
      }

      return <span className="icon">{icon}</span>
    }
  }

  return (
    <Div className="passenger-list">
      {value && value.map((passenger, index) => {
        if (passenger.type === (type || PassengerType.Primary)) {
          return (
            <Div key={index}>
              <Div layout="flex">
                {!context.readonly &&
                <Div layout="fit">
                  <Button disabled={disabled || context.submitting}
                          look="bare"
                          icon="minus-outline"
                          onClick={() => remove(index)} />
                </Div>
                }
                {(!type || type !== PassengerType.Service) &&
                <Div layout="fit">
                  {renderAge(passenger)}
                </Div>
                }
                <Div layout="fill">
                  <Div layout="flex">
                    <Div layout="fit" className="passenger-list-group">
                      <Div layout="flex">
                        <Div layout="fit" className="passenger-list-group-item">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.familyName')}
                                   name={`${name}-${index}-familyName`}
                                   value={passenger.familyName}
                                   validators={[ required, familyName => length(familyName, 1, 256) ]}
                                   onChange={familyName => change(index, { familyName })} />
                        </Div>
                        <Div layout="fit" className="passenger-list-group-item">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.givenName')}
                                   name={`${name}-${index}-givenName`}
                                   value={passenger.givenName}
                                   validators={[ required, givenName => length(givenName, 1, 256) ]}
                                   onChange={givenName => change(index, { givenName })} />
                        </Div>
                        <Div layout="fit" className="passenger-list-group-item">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.middleName')}
                                   name={`${name}-${index}-middleName`}
                                   value={passenger.middleName}
                                   validators={[ middleName => length(middleName, 1, 256) ]}
                                   onChange={middleName => change(index, { middleName })} />
                        </Div>
                        {(!type || type !== PassengerType.Service) &&
                        <Div layout="fit" className="passenger-list-group-item">
                          <DateBox disabled={disabled}
                                   readonly={!!data}
                                   name={`${name}-${index}-birthDate`}
                                   zone="UTC"
                                   max={now.toISOString()}
                                   value={passenger.dateOfBirth}
                                   validators={[ required ]}
                                   onChange={birthDate => change(index, { birthDate: birthDate })} />
                        </Div>
                        }
                        {hasTicket &&
                        <Div layout="fit">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.ticket')}
                                   name={`${name}-${index}-ticket`}
                                   value={passenger.ticket}
                                   validators={[ required, ticket => length(ticket, 1, 50) ]}
                                   onChange={ticket => change(index, { ticket })} />
                        </Div>
                        }
                      </Div>
                    </Div>
                    <Div layout="fit" className="passenger-list-group">
                      <Div layout="flex">
                        {(!type || type !== PassengerType.Service) &&
                        <Div layout="fit" className="passenger-list-group-item">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.email')}
                                   name={`${name}-${index}-email`}
                                   value={passenger.email}
                                   validators={[ ...(emailRequired ? [ required ] : []), email, value => length(value, 1, 100) ]}
                                   onChange={email => change(index, { email })} />
                        </Div>
                        }
                        {(!type || type !== PassengerType.Service) &&
                        <Div layout="fit" className="passenger-list-group-item">
                          <TextBox disabled={disabled}
                                   readonly={!!data}
                                   placeholder={ID('passengerList.phone')}
                                   mask="(999) 000-00-00"
                                   name={`${name}-${index}-phone`}
                                   value={passenger.phone}
                                   validators={[ ...(phoneRequired ? [ required ] : []), phone ]}
                                   onChange={phone => change(index, { phone })} />
                        </Div>
                        }
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          )
        } else {
          return null
        }
      })}
      {!context.readonly &&
      <Validator name={name} value={count}
                 validators={props.required ? [ value => value < 1 ? ID('passengerList.required') : undefined ] : undefined}>
        <Div layout="flex vertical-center">
          <Div layout="fit">
            <Button
              disabled={disabled || context.submitting || (limit && count >= limit) || (data && labeledPassengers.length === 0)}
              look="bare"
              icon="plus-outline"
              items={labeledPassengers.length > 0 ? labeledPassengers : undefined}
              onClick={add} />
          </Div>
          {limit &&
          <Div layout="fit">
            <small>
              <IntlFormat text={ID('passengerList.limit', { count, limit })} />
            </small>
          </Div>
          }
        </Div>
      </Validator>
      }
    </Div>
  )
}
