import * as KendoInputs from "@progress/kendo-react-inputs";
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface TextBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  fill?: boolean;
  type?: "text" | "email" | "password"
  mask?: string;
  rules?: { [key: string]: RegExp };
  name: string;
  value: string | null;
  error?: IntlMessage;
  validators?: ((value: string | null) => IntlMessage | undefined)[],
  onChange?: (value: string | null) => void;
}

interface TextBoxProps {
  children?: null;
}

interface TextBoxState {
}

export class TextBox extends PureComponent<TextBoxProps, TextBoxState> {
  public constructor(props: TextBoxProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { hint, label, placeholder, disabled, readonly, autoFocus, fill, type, mask, rules, name, value, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={false}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange, onFieldEnter, onFieldLeave) => {
                 if (readonly || fieldReadonly) {
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          autoFocus={autoFocus}
                                          value={value ? value : ""} />
                     </span>
                   );
                 } else {
                   if (mask) {
                     return (
                       <span className={className}>
                         <KendoInputs.MaskedTextBox label={label && getIntlMessage(intl, label)}
                                                    placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                                    disabled={disabled || fieldDisabled || !onFieldChange}
                                                    mask={mask}
                                                    rules={rules}
                                                    valid={true}
                                                    value={value ? value : ""}
                                                    onChange={e => onFieldChange(e.target.value ? e.target.value.replace(/_+/, "") : null)}
                                                    onFocus={onFieldEnter}
                                                    onBlur={onFieldLeave} />
                       </span>
                     );
                   } else {
                     return (
                       <span className={className}>
                         <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                            placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                            disabled={disabled || fieldDisabled || !onFieldChange}
                                            autoFocus={autoFocus}
                                            type={type}
                                            valid={true}
                                            value={value ? value : ""}
                                            onChange={e => onFieldChange(e.target.value ? e.target.value : null)}
                                            onFocus={onFieldEnter}
                                            onBlur={onFieldLeave} />
                       </span>
                     );
                   }
                 }
               }}
               onChange={onChange} />
      } />
    );
  }
}
