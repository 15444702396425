import { Apis, Chunk, Client } from '../../../data'
import { ResolvedSchedule, Schedule, ScheduleCreateModel, ScheduleFilter, ScheduleUpdateModel } from '../models'

export class ScheduleAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: ScheduleFilter): Promise<Chunk<ResolvedSchedule>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`schedules`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        as: 'Resolved',
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<ResolvedSchedule> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`schedules/${id}`, {}, { as: 'Resolved' })
  }

  public static async create(model: ScheduleCreateModel): Promise<Schedule> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`schedules`, model)
  }

  public static async update(id: string, model: ScheduleUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`schedules/${id}`, model)
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`schedules/${id}`)
  }
}
