import moment from "moment";

export function parseTimeSpan(value: string): { hours: number, minutes: number } {
  let dd = 0;
  let hh = 0;
  let mm = 0;

  if (value) {
    const daysSplit = value.split(".")
    if (daysSplit.length > 1) {
      dd = Number(daysSplit[0])
    }

    if (daysSplit.length > 0) {
      const restSplit = daysSplit[daysSplit.length - 1].split(":")
      if (restSplit.length > 0) {
        hh = Number(restSplit[0])
      }
      if (restSplit.length > 1) {
        mm = Number(restSplit[1])
      }
    }
  }

  return { hours: dd * 24 + hh , minutes: mm };
}

export function addTimeSpan(to: string, value: string, sign: -1 | 1 = 1): string {
  const date = moment(to);
  const timeSpan = parseTimeSpan(value);
  if (sign > 0) {
    return date.add(timeSpan.hours, 'hour').add(timeSpan.minutes, 'minute').toISOString();
  } else {
    return date.subtract(timeSpan.hours, 'hour').subtract(timeSpan.minutes, 'minute').toISOString();
  }
}

export function getEnterDateTime(flightType: "arrival" | "departure" | "Arrival" | "Departure", flightDate: string, arrivalDelta: string, departureDelta: string): string {
  const flightMoment = moment(flightDate);
  const arrival = parseTimeSpan(arrivalDelta);
  const departure = parseTimeSpan(departureDelta);

  if (flightType === "arrival" || flightType === "Arrival") {
    return flightMoment.add(arrival.hours, 'hour').add(arrival.minutes, 'minute').toISOString();
  } else {
    return flightMoment.subtract(departure.hours, 'hour').subtract(departure.minutes, 'minute').toISOString();
  }
}

export function isInAgeRange(birthDate: string | null, min: number | null, max: number | null): boolean {
  const getDayOfYear = (value: Date): number => {
    const start = new Date(value.getFullYear(), 0, 0);
    const diff = value.valueOf() - start.valueOf();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  };

  const getAge = (value: Date): number => {
    const now = new Date();

    let age = now.getFullYear() - value.getFullYear();
    if (getDayOfYear(now) < getDayOfYear(value)) {
      age -= 1;
    }

    return age;
  };

  if (birthDate) {
    const age = getAge(new Date(birthDate));
    return (min === null || age >= min) && (max === null || age < max);
  } else {
    return false;
  }
}

export function getLeaverDateTime(flightType: "arrival" | "departure" | "Arrival" | "Departure", flightDate: string, arrivalDelta: string, departureDelta: string): string {
  const flightMoment = moment(flightDate);
  const arrival = parseTimeSpan(arrivalDelta);
  const departure = parseTimeSpan(departureDelta);

  if (flightType === "arrival" || flightType === "Arrival") {
    return flightMoment.add(arrival.hours, 'hour').add(arrival.minutes, 'minute').toISOString();
  } else {
    return flightMoment.subtract(departure.hours, 'hour').subtract(departure.minutes, 'minute').toISOString();
  }
}
