import { OrganizationReference } from './Organization'
import { OrganizationProgramCardReference } from './OrganizationProgramCard'
import { ResourceReference } from './Resource'
import { ServiceKind } from './Service'

export enum ReportType {
  Sale = 'Sale',
  Load = 'Load',
  Free = 'Free',
  Operations = 'Operations',
}

export class ReportParameters {
  public type: ReportType
  public from: string
  public till: string
  public card: OrganizationProgramCardReference | null
  public organization: OrganizationReference
  public counterparty: OrganizationReference | null
  public kind: ServiceKind | null
  public resource: ResourceReference | null

  public constructor() {
    this.type = ReportType.Sale
    this.from = ''
    this.till = ''
    this.card = null
    this.organization = new OrganizationReference()
    this.counterparty = null
    this.kind = null
    this.resource = null
  }
}
