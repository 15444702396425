import moment from "moment";
import * as PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Timeline, { DateHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from "react-calendar-timeline";


// noinspection JSUnusedGlobalSymbols
export class CapacityTimeline extends PureComponent {
  static propTypes = {
    places: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    })),
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string]).isRequired,
      item: PropTypes.any.isRequired,
      place: PropTypes.oneOfType([ PropTypes.number, PropTypes.string]).isRequired,
      from: PropTypes.instanceOf(Date).isRequired,
      till: PropTypes.instanceOf(Date).isRequired,
    })).isRequired,
    itemTitleRenderer: PropTypes.func.isRequired,
    sidebarWidth: PropTypes.number,
    capacity: PropTypes.number,
    from: PropTypes.instanceOf(Date),
    till: PropTypes.instanceOf(Date),
    onSelect: PropTypes.func,
  };

  render() {
    const items = this.props.items.map(item => ({
      id: item.id,
      group: item.place,
      title: this.props.itemTitleRenderer(item),
      start_time: moment(item.from),
      end_time: moment(item.till),
      data: item.item,
    }));

    const maxPlace = Math.max(...this.props.items.map(item => item.place),
      this.props.capacity !== undefined ? this.props.capacity : 0);
    const groups = [];
    if (this.props.places) {
      this.props.places.forEach(v => {
        groups.push({
          id: v.id,
          title: v.name,
        });
      });
    } else {
      for (let i = 1; i <= maxPlace; i++) {
        groups.push({
          id: i,
          title: `${i}`,
        });
      }
    }

    const from = this.props.from !== undefined ? moment(this.props.from) : moment().startOf("day");
    const till = this.props.till !== undefined ? moment(this.props.till) : from.add(1, "day");

    return (
      <Timeline
        canMove={false}
        canResize={false}
        canChangeGroup={false}
        groups={groups}
        items={items}
        lineHeight={25}
        itemHeightRatio={0.75}
        itemRenderer={this.itemRenderer}
        sidebarWidth={this.props.sidebarWidth ? this.props.sidebarWidth : 64}
        selected={[]}
        visibleTimeStart={from.toDate().valueOf()}
        visibleTimeEnd={till.toDate().valueOf()}
        onTimeChange={this.handleTimeChange}>
        <TimelineHeaders>
          <DateHeader unit="day" labelFormat="DD.MM.YYYY" />
          <DateHeader unit="hour" labelFormat="HH" />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) =>
              <div style={{ ...styles, backgroundColor: "#ea9626" }} />
            }
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    );
  }

  itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    let intent = "";
    if (item.data.intent && typeof item.data.intent === "string") {
      intent = item.data.intent;
    }

    let backgroundColor;
    let borderColor;
    switch (intent) {
      case "warning":
        backgroundColor = "#fdce3e";
        borderColor = "#fdce3e";
        break;
      case "danger":
        backgroundColor = "#d51923";
        borderColor = "#d51923";
        break;
      case "success":
        backgroundColor = "#5ec232";
        borderColor = "#5ec232";
        break;
      default:
        backgroundColor = "#ffb345";
        borderColor = "#ea9626";
        break;
    }

    // noinspection JSUnusedGlobalSymbols
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 0,
          },
          onMouseDown: () => {
            if (this.props.onSelect) {
              this.props.onSelect(item.data);
            }
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {itemContext.title}
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  };
}
