import { Dispatch, ReactNodeArray, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Pair } from "../../data";
import { ActionHandler, IntlText, TXT } from "../gears";
import {
  BooleanFormatter,
  CodeFormatter,
  CostFormatter,
  CostRangeFormatter,
  DateFormatter,
  DateRangeFormatter,
  DaysFormatter,
  MessageFormatter,
  NumberFormatter,
  PairConverter,
  PairData,
  TimeFormatter,
  TimeRangeFormatter,
} from "./types";

export function useDelay<TValue>(initialState: TValue | (() => TValue), delay: number = 1000): [ TValue, TValue, Dispatch<SetStateAction<TValue>> ] {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [ currentState, setCurrentState ] = useState<TValue>(initialState);
  const [ delayedState, setDelayedState ] = useState<TValue>(initialState);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setDelayedState(currentState);
    }, delay);

    return () => {
      if (timeout.current !== null) clearTimeout(timeout.current);
    };
  }, [ currentState, delay ]);

  return [ currentState, delayedState, setCurrentState ];
}

export function useField<TField>(initialField: TField | (() => TField)): [ TField, Dispatch<SetStateAction<TField>> ] {
  const [ field, setField ] = useState(initialField);

  useEffect(() => {
    setField(initialField);
  }, [ initialField, setField ]);

  return [ field, setField ];
}

export function useTimer(handler: () => void, enabled: boolean = true, immediately: boolean = false, timeout: number = 60000) {
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (enabled) {
      if (timeoutRef.current !== null) clearInterval(timeoutRef.current);
      timeoutRef.current = setInterval(handler, timeout);
      if (immediately) handler();
    }

    return () => {
      if (timeoutRef.current !== null) clearInterval(timeoutRef.current);
    };
  }, [ handler, enabled, immediately, timeout ]);
}

export function useCounter(): [ number, ActionHandler ] {
  const [ state, setState ] = useState(0);

  const increase = useCallback((): void => {
    setState(prev => prev + 1);
  }, [ setState ]);

  return [ state, increase ];
}

export function useFormatCode(): CodeFormatter {
  return useCallback((code: number, pad: number = 4): string => {
    return code.toString().padStart(pad, "0").toUpperCase();
  }, []);
}

export function useFormatCost(): CostFormatter {
  const intl = useIntl();

  return useCallback((value: number, currency?: string): string => {
    return `${intl.formatNumber(value)}${currency ? ` ${currency}` : ""}`;
  }, [ intl ]);
}

export function useFormatCostRange(): CostRangeFormatter {
  const intl = useIntl();

  return useCallback((from: number, till: number, currency?: string): string => {
    const fromFormat = intl.formatNumber(from);
    const tillFormat = from !== till ? ` – ${intl.formatNumber(till)}` : "";
    const currencyFormat = currency ? ` ${currency}` : "";
    return `${fromFormat}${tillFormat}${currencyFormat}`;
  }, [ intl ]);
}

export function useFormatDate(): DateFormatter {
  const intl = useIntl();

  return  useCallback((value: string, time?: boolean, zone?: string): string => {
    return `${intl.formatDate(value, { timeZone: zone })}${time ? ` ${intl.formatTime(value, { timeZone: zone })}` : ""}`;
  }, [ intl ]);
}

export function useFormatDateRange(): DateRangeFormatter {
  const formatDate = useFormatDate();

  return useCallback((value1: string, value2: string, time?: boolean, zone?: string): string => {
    return `${formatDate(value1, time, zone)} – ${formatDate(value2, time, zone)}`;
  }, [ formatDate ]);
}

export function useFormatTime(): TimeFormatter {
  const intl = useIntl();

  return useCallback((value: string, zone?: string): string => {
    const dateParts = value.split("T");
    let date: Date | null = null;
    if (dateParts.length === 1) {
      const timePart = dateParts[dateParts.length - 1];
      const parts = timePart.split(":");
      let hh = 0;
      let mm = 0;
      if (parts.length > 0) {
        hh = Number(parts[0]);
      }
      if (parts.length > 1) {
        mm = Number(parts[1]);
      }
      date = new Date(2000, 0, 1, hh, mm);
    }

    return intl.formatTime(date || value, { timeZone: zone })
  }, [ intl ]);
}

export function useFormatTimeRange(): TimeRangeFormatter {
  const intl = useIntl();
  const formatTime = useFormatTime();

  return useCallback((value1: string, value2: string, zone?: string): string => {
    const time1 = formatTime(value1, zone);
    const time2 = formatTime(value2, zone);

    if (time1 === "00:00" && time2 === "23:59") {
      return intl.formatMessage(TXT("time.allDay"));
    } else {
      return `${time1} – ${time2}`;
    }
  }, [ formatTime, intl ]);
}

export function useFormatDays(): DaysFormatter {
  const intl = useIntl();

  return useCallback((value: string[]): string => {
    const days = [
      { code: "mon", name: intl.formatMessage(TXT("week.days.mon")), isSelected: value.some(day => day === "mon") },
      { code: "tue", name: intl.formatMessage(TXT("week.days.tue")), isSelected: value.some(day => day === "tue") },
      { code: "wed", name: intl.formatMessage(TXT("week.days.wed")), isSelected: value.some(day => day === "wed") },
      { code: "thu", name: intl.formatMessage(TXT("week.days.thu")), isSelected: value.some(day => day === "thu") },
      { code: "fri", name: intl.formatMessage(TXT("week.days.fri")), isSelected: value.some(day => day === "fri") },
      { code: "sat", name: intl.formatMessage(TXT("week.days.sat")), isSelected: value.some(day => day === "sat") },
      { code: "sun", name: intl.formatMessage(TXT("week.days.sun")), isSelected: value.some(day => day === "sun") },
    ];


    const ranges = [new Array<string>()];
    days.forEach((day) => {
      if (day.isSelected) {
        ranges[ranges.length - 1].push(day.name);
      } else {
        ranges.push(new Array<string>());
      }
    });
    return ranges.map((range) => {
      if (range.length === 1) {
        return range[0];
      } else if (range.length > 1) {
        const delim = range.length > 2 ? " – " : ", ";
        return `${range[0]}${delim}${range[range.length - 1]}`;
      } else {
        return "";
      }
    }).filter((range) => range.length > 0).join(", ");
  }, [ intl ]);
}

export function useFormatBoolean(): BooleanFormatter {
  const intl = useIntl();

  return useCallback((value: boolean): string => {
    return value ? intl.formatMessage({ id: "label.yes" }) : intl.formatMessage({ id: "label.no" });
  }, [ intl ]);
}

export function useFormatMessage(): MessageFormatter {
  const intl = useIntl();

  return useCallback((value: string | IntlText): string => {
    if (typeof value === "string") {
      return value;
    } else {
      return intl.formatMessage({ id: value.id }, value.values).toString();
    }
  }, [ intl ]);
}

export function useFormatMessageEx(): MessageFormatter<string | ReactNodeArray> {
  const intl = useIntl();

  return useCallback((value: string | IntlText): string | ReactNodeArray => {
    if (typeof value === "string") {
      return value;
    } else {
      return intl.formatMessage({ id: value.id }, value.values);
    }
  }, [ intl ]);
}

export function useFormatNumber(): NumberFormatter {
  const intl = useIntl();

  return useCallback((value: number): string => {
    return intl.formatNumber(value);
  }, [ intl ]);
}

export function useWindowSize(): [ number, number ] {
  const [ height, setHeight ] = useState(window.innerHeight);
  const [ width, setWidth ] = useState(window.innerWidth);

  const resize = useCallback((): void => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }, [ setHeight, setWidth ]);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [ resize ]);

  return [ height, width ];
}

export function useRequired(): [ PairData<boolean>, PairConverter<boolean> ] {
  const intl = useIntl();

  const [ data, ] = useState([
    new Pair(true, intl.formatMessage({ id: "enum.required.true" })),
    new Pair(false, intl.formatMessage({ id: "enum.required.false" })),
  ]);

  const converter = useCallback((value: boolean): string => {
    if (value) {
      return intl.formatMessage({ id: "enum.required.true" });
    } else {
      return intl.formatMessage({ id: "enum.required.false" });
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useForceUpdate(): () => void {
  const [ , setValue ] = useState(0);
  return () => setValue(value => ++value);
}
