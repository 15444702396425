import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Pair } from '../../data'
import { CurrencyAccess } from '../data/access'
import { Currency } from '../data/models'
import { PairConverter, PairData } from './types'

export function useCurrency(id: string | null): [ Currency | null, Dispatch<SetStateAction<Currency | null>> ] {
  const [ item, setItem ] = useState<Currency | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await CurrencyAccess.getOne(id));
        } else {
          setItem(null);
        }
      } catch (e) {
        setItem(null);
      }
    })();
  }, [ id, setItem ]);

  return [ item, setItem ];
}

export function useCurrencies(): [ PairData, PairConverter ] {
  const [ data, ] = useState<PairData>([
    new Pair('RUB', 'Russian Ruble'),
    new Pair('USD', 'US Dollar'),
    new Pair('EUR', 'Euro'),
  ]);

  const converter = useCallback((value: string): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}
