import { IReference } from "./IReference";
import { OrganizationBaseV1 } from "./Organization";

export enum CorporateProgramFamilyV1 {
  Individual = "individual",
  Corporate = "corporate",
}

export class CorporateProgramReferenceV1 implements IReference {
  public id: number;

  public constructor() {
    this.id = 0;
  }
}

export class CorporateProgramRecord extends CorporateProgramReferenceV1 {
  public name: string;
  public family: CorporateProgramFamilyV1;
  public provider: string;
  public isActive: boolean;
  public cardCount: number;
  public description: string | null;

  public constructor() {
    super();
    this.name = "";
    this.family = CorporateProgramFamilyV1.Individual;
    this.provider = "";
    this.isActive = true;
    this.cardCount = 0;
    this.description = null;
  }
}

export class CorporateProgramBaseV1 extends CorporateProgramReferenceV1 {
  public name: string;
  public family: CorporateProgramFamilyV1;
  public provider: OrganizationBaseV1;
  public isActive: boolean;
  public description: string | null;

  public constructor() {
    super();
    this.name = "";
    this.family = CorporateProgramFamilyV1.Individual;
    this.provider = new OrganizationBaseV1();
    this.isActive = true;
    this.description = null;
  }
}

export class CorporateProgramV1 extends CorporateProgramBaseV1 {
  public availablePrimaryPersonCount: number | null;
  public availableSecondaryPersonCount: number | null;
  public availableServicePersonCount: number | null;
  public availableFlightCount: number | null;
  public availableFlightCountForDomestic: number | null;
  public availableFlightCountForInternational: number | null;
  public availableFlightCountPerMonth: number | null;
  public availableFlightCountPerMonthForDomestic: number | null;
  public availableFlightCountPerMonthForInternational: number | null;
  public availablePassengerCountPerFlight: number | null;
  public availableGuestCount: number | null;
  public availableGuestCountPerMonth: number | null;
  public availableGuestCountPerMonthForArrival: number | null;
  public availableGuestCountPerMonthForDeparture: number | null;
  public availableGuestCountPerFlight: number | null;
  public needsSaveUnusedFlightCount: boolean;

  public constructor() {
    super();
    this.availablePrimaryPersonCount = null;
    this.availableSecondaryPersonCount = null;
    this.availableServicePersonCount = null;
    this.availableFlightCount = null;
    this.availableFlightCountForDomestic = null;
    this.availableFlightCountForInternational = null;
    this.availableFlightCountPerMonth = null;
    this.availableFlightCountPerMonthForDomestic = null;
    this.availableFlightCountPerMonthForInternational = null;
    this.availablePassengerCountPerFlight = null;
    this.availableGuestCount = null;
    this.availableGuestCountPerMonth = null;
    this.availableGuestCountPerMonthForArrival = null;
    this.availableGuestCountPerMonthForDeparture = null;
    this.availableGuestCountPerFlight = null;
    this.needsSaveUnusedFlightCount = false;
  }
}
