import { CityReference, CountryReference, EMPTY_ID } from "./index";

export class AirportReference {
  public id: string;

  public constructor() {
    this.id = EMPTY_ID;
  }
}

export class AirportName extends AirportReference {
  public code: string;
  public name: string;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
  }
}

export class AirportBase extends AirportName {
  public zone: string;

  public constructor() {
    super();
    this.zone = "UTC";
  }
}

export class Airport<TCountry extends CountryReference = CountryReference, TCity extends CityReference = CityReference> extends AirportBase {
  public country: TCountry;
  public city: TCity;

  public constructor(TCountry: new() => TCountry, TCity: new() => TCity) {
    super();
    this.country = new TCountry();
    this.city = new TCity();
  }
}
