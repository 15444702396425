import { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AuthContext } from "../../auth";
import { Chunk, Pair } from "../../data";
import { EventAccess } from "../data/access";
import { EventBase, EventKind, EventPriority, EventType } from "../data/models";
import { useTimer } from "./Common";
import { PairConverter, PairData } from "./types";

export function useEventTypes(): [ PairData<EventType>, PairConverter<EventType> ] {
  const intl = useIntl();

  const [ data, ] = useState<Pair<EventType>[]>([
    new Pair(EventType.Order, intl.formatMessage({ id: "enum.queueType.order" })),
    new Pair(EventType.Time, intl.formatMessage({ id: "enum.queueType.seat" })),
  ]);

  const converter = useCallback((value: EventType): string => {
    const item = data.filter(pair => pair.code === value)[0];
    return item ? item.name : "";
  }, [ data ]);

  return [ data, converter ];
}

export function useEventKind(): [ PairData<EventKind>, PairConverter<EventKind> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<EventKind>>([
    new Pair(EventKind.OrderCreated, intl.formatMessage({ id: "enum.queueEvent.orderCreated" })),
    new Pair(EventKind.OrderConfirmed, intl.formatMessage({ id: "enum.queueEvent.orderConfirmed" })),
    new Pair(EventKind.OrderRejected, intl.formatMessage({ id: "enum.queueEvent.orderRejected" })),
    new Pair(EventKind.OrderPending, intl.formatMessage({ id: "enum.queueEvent.orderPending" })),
    new Pair(EventKind.OrderPaid, intl.formatMessage({ id: "enum.queueEvent.orderPaid" })),
    new Pair(EventKind.OrderCancelled, intl.formatMessage({ id: "enum.queueEvent.orderCancelled" })),
    new Pair(EventKind.OrderCompleted, intl.formatMessage({ id: "enum.queueEvent.orderCompleted" })),
    new Pair(EventKind.OrderChanged, intl.formatMessage({ id: "enum.queueEvent.orderChanged" })),
    new Pair(EventKind.OrderForced, intl.formatMessage({ id: "enum.queueEvent.orderForced" })),
    new Pair(EventKind.OrderRefund, intl.formatMessage({ id: "enum.queueEvent.orderRefund" })),
    new Pair(EventKind.TimeExpired, intl.formatMessage({ id: "enum.queueEvent.timeExpired" })),
  ]);

  const converter = useCallback((value: EventKind): string => {
    const item = data.filter(pair => pair.code === value)[0];
    return item ? item.name : "";
  }, [ data ]);

  return [ data, converter ];
}

export function useEventPriorities(): [ PairData<EventPriority>, PairConverter<EventPriority> ] {
  const intl = useIntl();
  const [ data, setData ] = useState<PairData<EventPriority>>([]);

  useEffect(() => {
    const data = [
      new Pair(EventPriority.Low, intl.formatMessage({ id: "enum.queuePriority.low" })),
      new Pair(EventPriority.Normal, intl.formatMessage({ id: "enum.queuePriority.normal" })),
      new Pair(EventPriority.High, intl.formatMessage({ id: "enum.queuePriority.high" })),
    ];
    setData(data);
  }, [ intl, setData ]);

  const converter = useCallback((value: EventPriority): string => {
    switch (value) {
      case EventPriority.Low:
        return intl.formatMessage({ id: "enum.queuePriority.low" });
      case EventPriority.Normal:
        return intl.formatMessage({ id: "enum.queuePriority.normal" });
      case EventPriority.High:
        return intl.formatMessage({ id: "enum.queuePriority.high" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useEventCount(max?: number, type?: EventType): number {
  const auth = useContext(AuthContext);
  const [ count, setCount ] = useState(0);

  const load = useCallback(() => {
    if (auth.profile.isInRole("system.admin", "owner.super", "owner.manager.order", "agent.super", "agent.manager")) {
      EventAccess.getCount(type || EventType.Order)
        .then(setCount)
        .catch(() => undefined);
    }
  }, [ auth, type, setCount ]);

  useTimer(load, true);

  if (max !== undefined) {
    return Math.min(max, count)
  } else {
    return count;
  }
}

export function useEvents(max?: number, type?: EventType): [ Chunk<EventBase>, (id: string) => Promise<void> ] {
  const [ data, setData ] = useState<Chunk<EventBase>>(new Chunk());

  const reload = useCallback(async (): Promise<void> => {
    try {
      setData(await EventAccess.getAll(undefined, undefined, 0, max, type));
    } catch (e) {
      console.error(e);
    }
  }, [ max, type, setData ]);

  const resolve = useCallback(async (id: string): Promise<void> => {
    try {
      await EventAccess.resolve(id);
      await reload();
    } catch (e) {
      console.error(e);
    }
  }, [ reload ]);

  useTimer(reload, true);

  return [ data, resolve ];
}
