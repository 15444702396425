import { IReference } from "./IReference";
import { OrganizationBaseV1 } from "./Organization";
import { PassengerCategoryV1 } from './Passenger'
import { ServiceModeV1, ServiceTypeV1 } from "./Service";
import { TerminalBaseV1 } from "./Terminal";

export enum ServiceKindV1 {
  Additional = "additional",
  VipLounge = "vipLounge",
  BusinessLounge = "businessLounge",
  ComfortLounge = "comfortLounge",
  OfficialPersonsAndDelegationsLounge = "officialPersonsAndDelegationsLounge",
  FastTrackService = "fastTrackService",
  MeetAndAssistService = "meetAndAssistService",
  MeetAndGreetService = "meetAndGreetService",
  ParkingService = "parkingService",
  TransferService = "transferService",
  TransitService = "transitService",
  Meal = "mealService",
  Info = "infoService",
}

export enum FlightDirectionV1 {
  Any = "any",
  Domestic = "domestic",
  International = "international",
}

export class ResourceReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class ResourceRecord extends ResourceReferenceV1 {
  public name: string;
  public kind: ServiceKindV1;
  public type: ServiceTypeV1;
  public flightDirection: FlightDirectionV1;
  public provider: string;
  public airport: string;

  public constructor() {
    super();
    this.name = "";
    this.kind = ServiceKindV1.VipLounge;
    this.type = ServiceTypeV1.All;
    this.flightDirection = FlightDirectionV1.Any;
    this.provider = "";
    this.airport = "";
  }
}

export class ResourceBaseV1 extends ResourceReferenceV1 {
  public name: string;
  public kind: ServiceKindV1;
  public type: ServiceTypeV1;
  public direction: FlightDirectionV1;
  public provider: OrganizationBaseV1;
  public terminal: TerminalBaseV1;
  public available: boolean;
  public description: string | null;

  public constructor() {
    super();
    this.name = "";
    this.kind = ServiceKindV1.VipLounge;
    this.type = ServiceTypeV1.All;
    this.direction = FlightDirectionV1.Any;
    this.provider = new OrganizationBaseV1();
    this.terminal = new TerminalBaseV1();
    this.available = false;
    this.description = null;
  }
}

export class ResourceV1 extends ResourceBaseV1 {
  public mode: ServiceModeV1;
  public capacity: number | null;
  public quota: number | null;
  public groupAge: number | null;
  public groupSize: number | null;
  public maxStayDuration: string | null;
  public arrivalEnterDelta: string | null;
  public arrivalLeaveDelta: string | null;
  public departureEnterDelta: string | null;
  public departureLeaveDelta: string | null;
  public minChildAge: number | null;
  public minAdultAge: number | null;
  public servicedCategories: PassengerCategoryV1[] | null;
  public location: string | null;
  public schedule: string | null;
  public phone: string | null;
  public features: string[] | null;
  public compatibilities: ServiceKindV1[] | null;

  public constructor() {
    super();
    this.mode = ServiceModeV1.Automatic;
    this.capacity = null;
    this.quota = null;
    this.groupAge = null;
    this.groupSize = null;
    this.maxStayDuration = null;
    this.arrivalEnterDelta = null;
    this.arrivalLeaveDelta = null;
    this.departureEnterDelta = null;
    this.departureLeaveDelta = null;
    this.minChildAge = null;
    this.minAdultAge = null;
    this.servicedCategories = null;
    this.location = null;
    this.schedule = null;
    this.phone = null;
    this.features = null;
    this.compatibilities = null;
  }
}

export function isLoungeV1(type: ServiceKindV1): boolean {
  switch (type) {
    case ServiceKindV1.VipLounge:
    case ServiceKindV1.BusinessLounge:
    case ServiceKindV1.ComfortLounge:
    case ServiceKindV1.OfficialPersonsAndDelegationsLounge:
    case ServiceKindV1.Info:
    case ServiceKindV1.Meal:
      return true;
    default:
      return false;
  }
}

export function isAssistanceV1(type: ServiceKindV1): boolean {
  switch (type) {
    case ServiceKindV1.FastTrackService:
    case ServiceKindV1.MeetAndAssistService:
    case ServiceKindV1.MeetAndGreetService:
    case ServiceKindV1.ParkingService:
    case ServiceKindV1.TransitService:
      return true;
    default:
      return false;
  }
}

export function isTransferV1(type: ServiceKindV1): boolean {
  return type === ServiceKindV1.TransferService;
}
