import { Apis, Chunk, Client } from "../../../data";
import { Airport, AirportTerminalName } from '../models'

export class AirportAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, byContractId?: string, byPoolId?: string | string[]): Promise<Chunk<Airport>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`airports`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        byContractId,
        byPoolId,
      });
  }

  public static async getOne(id: string): Promise<Airport> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`airports/${id}`);
  }

  public static async getOneTerminals(id: string): Promise<Chunk<AirportTerminalName>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`airports/${id}/terminals`);
  }
}
