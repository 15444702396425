import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { Chunk } from '../../data'
import { FieldSet, Form } from '../../gears'
import { NumberBox, TextArea, TextBox } from '../../gears/inputs'
import { length, range, required } from '../../gears/validators'
import { Media, MediaKind, ResolvedService, ResourceReference, Service, ServiceCreateModel, ServiceKind, ServiceMode, ServiceType, ServiceUnit } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, TXT } from '../gears'
import { useDelay, useField, useFormatCode, useFormatCostRange, useFormatMessage, useResource, useWindowSize } from '../hooks'
import { MediaView } from './Medias'
import { GridViewProps, ItemViewProps } from './types'

export interface VehicleItemProps extends ItemViewProps<Service & { images: Media[] }, ServiceCreateModel & { images: Media[] }> {
}

export function VehicleItem(props: VehicleItemProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onDelete, onEdit, onFree } = props;

  const [ resource ] = useResource(item.resource.id);

  const [ name, setName ] = useField<string | null>(item.name);

  const [ capacity, setCapacity ] = useField<number | null>(item.capacity);
  
  const [ description, setDescription ] = useField(item.description);

  const [ images, seImages ] = useField(item.images);

  const getItem = useCallback((): ServiceCreateModel & { images: Media[] } => {
    const model: ServiceCreateModel & { images: Media[] } = { ...new ServiceCreateModel(), images: [] };
    model.name = name || "";
    model.type = ServiceType.Any;
    model.resource = resource || new ResourceReference();
    model.mode = ServiceMode.Manual;
    model.unit = ServiceUnit.Whole;
    model.kind = ServiceKind.Transfer;
    model.capacity = capacity;
    model.description = description;
    model.images = images;

    return model;
  }, [
    name, resource, capacity,
    description, images,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem());
    }
  }, [ getItem, onSubmit ]);

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12 3@lg">
              <Div>
                <TextBox label={TXT("label.name")}
                         fill={true}
                         name="name"
                         value={name}
                         validators={[ required, value => length(value, 3, 256) ]}
                         onChange={setName} />
              </Div>
              <Div>
                <NumberBox label={TXT("label.vehicleCapacity")}
                           fill={true}
                           name="capacity"
                           value={capacity}
                           validators={[ required, value => range(value, 0) ]}
                           onChange={setCapacity} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT("label.description")}>
            <TextArea fill={true}
                      name="description"
                      value={description}
                      onChange={setDescription} />
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT("label.media")}>
            <MediaView data={images} kind={MediaKind.Image} onChange={!readonly ? seImages : undefined} />
          </FieldSet>
        </Div>
      </Div>
    </Form>
  );
}

export interface VehicleGridProps extends GridViewProps<ResolvedService> {
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function VehicleGrid(props: VehicleGridProps): ReactElement {
  const { data, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props;

  const formatCode = useFormatCode();
  const formatCostRange = useFormatCostRange();
  const formatMessage = useFormatMessage();

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null);
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        code: item.code,
        name: item.name,
      });
      setProcessedData(processedData);
    }
  }, [
    data.data, data.skip, data.take, data.total,
    formatCode, formatCostRange,
    setProcessedData,
  ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [ delayedPattern, onItemSearch ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [ data.data, onItemSelect ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);
      replaceSortOrder(sortOrder, "code", "code");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [ onSortChange ]);

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT("label.search")}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              {formatMessage(TXT("action.create"))}
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={`${width}px`}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.code")} field="code" width={92} locked={true} />
              <GridColumn title={TXT("label.name")} field="name" fill={true} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}
