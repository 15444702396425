import { AirportReference, CityBase, CityReference, EMPTY_ID, LegalRequisites, PaymentRequisites } from './index'

export enum OrganizationType {
  System = 'System',
  Provider = 'Provider',
  Agency = 'Agency',
}

export interface OrganizationFilter {
  byType?: OrganizationType
  byProviderId?: string
  byAgencyId?: string
  byPoolId?: string
  isConcluded?: boolean
  isAuthorized?: boolean
}

export class OrganizationReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class OrganizationName extends OrganizationReference {
  public code: string
  public name: string
  
  public constructor() {
    super()
    this.code = ''
    this.name = ''
  }

}

export class OrganizationBase extends OrganizationName {
  public type: OrganizationType

  public constructor() {
    super()
    this.type = OrganizationType.System
  }
}

export class Organization<TCity extends CityReference = CityReference> extends OrganizationBase {
  public city: TCity

  public constructor(TCity: new() => TCity) {
    super()
    this.city = new TCity()
  }
}

export class ResolvedOrganization extends Organization<CityBase> {
  public constructor() {
    super(CityBase)
  }
}

export class OrganizationToken {
  public value: string

  public constructor() {
    this.value = ''
  }
}

export class OrganizationUpdateModel {
  public name: string
  public legalRequisites: LegalRequisites | null
  public paymentRequisites: PaymentRequisites[] | null
  public airports: AirportReference[] | null
  
  public constructor() {
    this.name = ''
    this.legalRequisites = null
    this.paymentRequisites = null
    this.airports = null
  }
}

export class OrganizationCreateModel extends OrganizationUpdateModel {
  public type: OrganizationType
  public city: CityReference
  public currency: string
  
  public constructor() {
    super()
    this.type = OrganizationType.System
    this.city = new CityReference()
    this.currency = 'RUB'
  }

}
