import { ButtonGroup } from "@progress/kendo-react-buttons";
import fileDownload from "js-file-download";
import * as R from "ramda";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { AuthContext } from "../auth";
import { Chunk, Lock, LockAction } from "../data";
import { Confirm, ID, NavPathItem, Tabs } from "../gears";
import { ToggleBox } from "../gears/inputs";
import {
  AirportAccess,
  LockAccess,
  OrderAccess,
  OrganizationContractAccess,
  ResourceAccess,
} from "../parts/data/access";
import {
  Airport,
  Change,
  ConfirmedResource,
  ConfirmedService,
  CurrencyReference,
  DepositOperation,
  FlightUpdateModel,
  FreeOrderPaymentInfo,
  Order,
  OrderChangeModel,
  OrderConfirmModel,
  OrderCreateModel,
  OrderPaymentMode,
  OrderPaymentModel,
  OrderPaymentType,
  OrderReturn,
  OrderStatus,
  OrderUpdateModel,
  OrganizationContract,
  OrganizationContractType,
  OrganizationReference,
  PoolReference,
  RatedFlight,
  RatedResource,
  ResolvedOrganizationContract,
  Resource,
  ServiceKind,
  ServiceType,
} from "../parts/data/models";
import { Div, Loader, Span, TXT } from "../parts/gears";
import {
  useFormatMessage,
  useOrganizationContract,
  useTimer,
} from "../parts/hooks";
import {
  DepositGrid,
  OrderChangesView,
  OrderConfirmView,
  OrderGrid,
  OrderItem,
  OrderPaymentView,
  OrderReturnConfirmView,
  OrderReturnRequestView,
  OrderVisitView,
} from "../parts/views";
import { PageProps, withPage } from "./withPage";

function OrdersPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props;

  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={(props) => (
          <OrderListPage
            {...props}
            onLoaded={onLoaded}
            onNavigate={onNavigate}
            onError={onError}
          />
        )}
      />
      <Route
        exact={true}
        path={`${match.path}/:orderId`}
        render={(props) => (
          <OrderItemPage
            {...props}
            onLoaded={onLoaded}
            onNavigate={onNavigate}
            onError={onError}
          />
        )}
      />
      <Route
        exact={true}
        path={`${match.path}/:orderId/return`}
        render={(props) => (
          <OrderReturnRequestPage
            {...props}
            onLoaded={onLoaded}
            onNavigate={onNavigate}
            onError={onError}
          />
        )}
      />
      <Route
        exact={true}
        path={`${match.path}/:orderId/confirm`}
        render={(props) => (
          <OrderReturnConfirmPage
            {...props}
            onLoaded={onLoaded}
            onNavigate={onNavigate}
            onError={onError}
          />
        )}
      />
    </Switch>
  );
}

export interface OrderItemPageProps
  extends RouteComponentProps<{ orderId: string }, {}, any> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any, processData?: (data: any) => string) => void;
}

export function OrderItemPage(props: OrderItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props;

  const auth = useContext(AuthContext);

  const formatMessage = useFormatMessage();

  const itemId = Number(match.params.orderId) || 0;
  const isNew = itemId === 0;
  const options = new URLSearchParams(location.search);
  const readonly = !isNew && !options.has("edit");
  const resource = location.state
    ? (location.state.resource as Resource)
    : undefined;
  const returnUrl = location.state
    ? (location.state.returnUrl as string)
    : undefined;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(new Order());
  const [lock, setLock] = useState<Lock | undefined>(new Lock());
  const [contract, setContract] = useState(
    new OrganizationContract(
      OrganizationReference,
      PoolReference,
      CurrencyReference
    )
  );
  const [changes, setChanges] = useState<Chunk<Change>>(new Chunk());
  const [deposit, setDeposit] = useState<
    Map<ResolvedOrganizationContract, Chunk<DepositOperation>>
  >(new Map());
  const [performer, setPerformer] = useState("");
  const [pay, setPay] = useState(false);

  const [rejectToggled, setRejectToggled] = useState(false);

  const [confirmToggled, setConfirmToggled] = useState(false);
  const [confirmModel, setConfirmModel] = useState(new OrderConfirmModel());

  const [withdrawToggled, setWithdrawToggled] = useState(false);
  const [withdrawModel, setWithdrawModel] = useState(new OrderPaymentModel());

  const [discardToggled, setDiscardToggled] = useState(false);

  const [revertToggled, setRevertToggled] = useState(false);

  const [voucherToggled, setVoucherToggled] = useState(false);
  const [voucherModel, setVoucherModel] = useState<"download" | "resend">(
    "download"
  );

  const [visitToggled, setVisitToggled] = useState(false);
  const [visitModel, setVisitModel] = useState<FlightUpdateModel[]>([]);

  const [locked, setLocked] = useState(false);

  useEffect(() => {
    setLoading(true);
    setLoaded(false);
  }, [itemId]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const [item, changes /*, deposit*/] = await Promise.all([
              OrderAccess.getOne(itemId),
              OrderAccess.getOneChanges(itemId),
              //OrderAccess.getOneOperations(itemId),
            ]);

            const lock = await OrderAccess.GetOneId(item.id).then((id) =>
              LockAccess.lock(id, readonly ? LockAction.info : LockAction.lock)
            );
            setItem(item);
            setLock(lock);
            setChanges(changes);
            setPerformer(
              changes.data.map(
                (x) =>
                  x.performedBy.fullName ||
                  x.performedBy.userName ||
                  x.performedBy.channel ||
                  x.performedBy.id ||
                  x.performedBy.clientId ||
                  ""
              )[changes.data.length - 1] || ""
            );
            //setDeposit(deposit);

            const deposit = new Map<
              ResolvedOrganizationContract,
              Chunk<DepositOperation>
            >();

            const contract = await OrganizationContractAccess.getOne(
              item.contract.id
            );
            setContract(contract);

            deposit.set(
              contract,
              await OrderAccess.getOneOperations(itemId, contract.id)
            );

            const parentContracts =
              await OrganizationContractAccess.getOneParents(contract.id);
            for (const parentContract of parentContracts.data) {
              deposit.set(
                parentContract,
                await OrderAccess.getOneOperations(itemId, parentContract.id)
              );
            }

            setDeposit(deposit);
          } else if (resource) {
            const orderedFlight1 = new RatedFlight();
            orderedFlight1.type =
              resource.type !== ServiceType.Any
                ? resource.type
                : ServiceType.Any;
            const orderedFlight2 =
              resource.kind === ServiceKind.Transit
                ? new RatedFlight()
                : undefined;
            if (orderedFlight2) {
              orderedFlight2.type = ServiceType.Departure;
            }

            const orderedResource = new RatedResource();
            orderedResource.resource = resource;
            orderedResource.flights = [orderedFlight1];
            if (orderedFlight2) {
              orderedResource.flights.push(orderedFlight2);
            }

            setItem((prev) => ({
              ...prev,
              spans: [{ resources: [orderedResource], passengers: [] }],
            }));
          }
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    isNew,
    readonly,
    itemId,
    resource,
    setLoaded,
    setLoading,
    setItem,
    setLock,
    setContract,
    setChanges,
    setPerformer,
    setDeposit,
    onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([item]);
    }
  }, [loaded, item, onLoaded]);

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    timer.current = setInterval(() => {
      if (readonly) setLoading(true);
    }, 60000);

    return () => {
      if (timer.current !== null) clearInterval(timer.current);
    };
  }, [readonly, setLoading]);

  const toggleReject = useCallback((): void => {
    setRejectToggled((prev) => !prev);
  }, [setRejectToggled]);

  const toggleConfirm = useCallback((): void => {
    setConfirmModel(new OrderConfirmModel());
    setConfirmToggled((prev) => !prev);
  }, [setConfirmToggled, setConfirmModel]);

  const toggleWithdraw = useCallback(
    (value?: boolean): void => {
      const model = new OrderPaymentModel();
      if (contract.type === OrganizationContractType.Deposit) {
        model.mode = OrderPaymentMode.Offline;
        model.type = OrderPaymentType.Deposit;
      } else {
        model.mode = OrderPaymentMode.Offline;
        model.type = OrderPaymentType.Card;
      }
      model.info = new FreeOrderPaymentInfo();
      setWithdrawModel(model);
      setWithdrawToggled((prev) => (value !== undefined ? value : !prev));
    },
    [contract, setWithdrawModel, setWithdrawToggled]
  );

  const toggleVisit = useCallback((): void => {
    setVisitModel([]);
    setVisitToggled((prev) => !prev);
  }, [setVisitToggled, setVisitModel]);

  useEffect(() => {
    if (pay) {
      toggleWithdraw(pay);
    }
  }, [pay, toggleWithdraw]);

  const handleNavigate = useCallback(
    async (
      item?: Order,
      edit?: boolean,
      free?: boolean,
      pay?: boolean
    ): Promise<void> => {
      if (item && item.id) {
        try {
          if (free) {
            try {
              await OrderAccess.GetOneId(item.id).then((id) =>
                LockAccess.lock(id, LockAction.free)
              );
            } catch {}
          }
          if (returnUrl) {
            onNavigate(returnUrl, {
              success: true,
              id: item.id,
              status: item.status,
            });
          } else {
            setPay(pay || false);
            onNavigate(`${item.id}${edit ? "?edit" : ""}`, { pay });
          }
          setLoaded(false);
          setLoading(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        if (returnUrl) {
          onNavigate(returnUrl, { success: false });
        } else {
          onNavigate(``);
        }
      }
    },
    [returnUrl, setLoaded, setLoading, setPay, onNavigate]
  );

  const handleCalc = useCallback(
    async (
      model: OrderCreateModel | OrderChangeModel | OrderUpdateModel
    ): Promise<Order | null> => {
      try {
        const calc = isNew
          ? await OrderAccess.create(model as OrderCreateModel, true)
          : item.paidAt === null
          ? await OrderAccess.change(itemId, model as OrderChangeModel, true)
          : await OrderAccess.update(itemId, model as OrderUpdateModel, true);
        setItem(calc);
        return calc;
      } catch (error) {
        onError(error);
        return null;
      }
    },
    [isNew, itemId, item, setItem, onError]
  );

  const handleSubmit = useCallback(
    async (
      model: OrderCreateModel | OrderChangeModel | OrderUpdateModel
    ): Promise<void> => {
      try {
        if (itemId) {
          const updated =
            item.paidAt === null
              ? await OrderAccess.change(itemId, model as OrderChangeModel)
              : await OrderAccess.update(itemId, model as OrderUpdateModel);
          setItem(updated);
          await handleNavigate(
            updated,
            false,
            true,
            updated.status === OrderStatus.Created
          );
        } else {
          const created = await OrderAccess.create(model as OrderCreateModel);
          setItem(created);
          await handleNavigate(
            created,
            false,
            true,
            created.status === OrderStatus.Created
          );
        }
      } catch (error) {
        onError(error);
      }
    },
    [itemId, item, handleNavigate, onError]
  );

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(item, false, true);
  }, [item, handleNavigate]);

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(item, true, false);
  }, [item, handleNavigate]);

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(item, false, true);
  }, [item, handleNavigate]);

  const handleReject = useCallback(
    async (confirmed: boolean): Promise<void> => {
      const processError = (): string => {
        return formatMessage(TXT("error.deposit.insufficientFunds"));
      };

      if (confirmed) {
        try {
          setLocked(true);

          const model = new OrderConfirmModel();
          for (const ratedResource of item.spans.flatMap(
            (span) => span.resources
          )) {
            const confirmedResource = new ConfirmedResource();
            confirmedResource.id = ratedResource.id;
            confirmedResource.confirmed = false;
            confirmedResource.services = [];
            for (const ratedService of ratedResource.services || []) {
              const confirmedService = new ConfirmedService();
              confirmedService.id = ratedService.id;
              confirmedService.confirmed = false;

              confirmedResource.services.push(confirmedService);
            }

            model.resources.push(confirmedResource);
          }

          await OrderAccess.confirm(item.id, model);

          await handleNavigate(item, false, false);
        } catch (error) {
          if ((error as any).status === 402) {
            onError(error, processError);
            setLoading(true);
          } else {
            onError(error);
          }
        }
      }
      toggleReject();
      setLocked(false);
    },
    [item, toggleReject, handleNavigate, setLocked, onError, formatMessage]
  );

  const handleConfirm = useCallback(
    async (confirmed: boolean): Promise<void> => {
      const processError = (): string => {
        return formatMessage(TXT("error.deposit.insufficientFunds"));
      };

      if (confirmed) {
        try {
          setLocked(true);
          await OrderAccess.confirm(item.id, confirmModel);
          await handleNavigate(item, false, false);
        } catch (error) {
          if ((error as any).status === 402) {
            onError(error, processError);
            setLoading(true);
          } else {
            onError(error);
          }
        }
      }
      toggleConfirm();
      setLocked(false);
    },
    [
      item,
      confirmModel,
      toggleConfirm,
      handleNavigate,
      setLocked,
      setLoading,
      onError,
      formatMessage,
    ]
  );

  const handleWithdraw = useCallback(
    async (confirmed: boolean): Promise<void> => {
      const processError = (): string => {
        return formatMessage(TXT("error.deposit.insufficientFunds"));
      };

      if (confirmed) {
        try {
          setLocked(true);
          await OrderAccess.pay(item.id, withdrawModel);
          await handleNavigate(item, false, false, false);
        } catch (error) {
          if ((error as any).status === 402) {
            onError(error, processError);
            setLoading(true);
          } else {
            onError(error);
          }
        }
      }
      toggleWithdraw();
      setLocked(false);
      setPay(false);
    },
    [
      item,
      withdrawModel,
      toggleWithdraw,
      handleNavigate,
      formatMessage,
      setLocked,
      setLoading,
      setPay,
      onError,
    ]
  );

  const handleDownload = useCallback(async (): Promise<void> => {
    try {
      const file = await OrderAccess.getOneVoucher(itemId);
      fileDownload(file, "EVoucher.pdf");
    } catch (error) {
      onError(error);
    }
  }, [itemId, onError]);

  const toggleVoucher = useCallback((): void => {
    setVoucherToggled((prev) => !prev);
  }, [setVoucherToggled]);

  const handleVoucher = useCallback(
    async (confirmed: boolean): Promise<void> => {
      if (confirmed) {
        if (voucherModel === "download") {
          await handleDownload();
        } else {
          try {
            await OrderAccess.resend(item.id);
          } catch (error) {
            onError(error);
          }
        }
      }
      toggleVoucher();
    },
    [item, voucherModel, toggleVoucher, handleDownload, onError]
  );

  const toggleDiscard = useCallback((): void => {
    setDiscardToggled(true);
  }, [setDiscardToggled]);

  const handleDiscard = useCallback(
    async (confirmed: boolean): Promise<void> => {
      if (confirmed) {
        try {
          setLocked(true);
          await OrderAccess.delete(itemId);
          await handleNavigate();
        } catch (error) {
          onError(error);
        }
      }
      setDiscardToggled(false);
      setLocked(false);
    },
    [itemId, handleNavigate, setDiscardToggled, setLocked, onError]
  );

  const toggleRevert = useCallback((): void => {
    setRevertToggled(true);
  }, [setRevertToggled]);

  const handleRevert = useCallback(async (): Promise<void> => {
    try {
      setLocked(true);
      await OrderAccess.revert(item.id);
      await handleNavigate(item, false, false);
    } catch (error) {
      onError(error);
    }
    setRevertToggled(false);
    setLocked(false);
  }, [item, handleNavigate, setRevertToggled, setLocked, onError]);

  const handleReturn = useCallback((): void => {
    if (item.status === OrderStatus.Forced) {
      onNavigate(`${item.id}/confirm?edit`);
    } else {
      onNavigate(`${item.id}/return?edit`);
    }
  }, [item, onNavigate]);

  const handleVisit = useCallback(
    async (confirmed: boolean): Promise<void> => {
      if (confirmed) {
        try {
          setLocked(true);

          for (const flight of visitModel) {
            await OrderAccess.updateFlight(item.id, flight.id, flight)
          }

          await handleNavigate(item, false, false);
        } catch (error) {
          onError(error);
        }
      }
      toggleVisit();
      setLocked(false);
    },
    [item, visitModel, toggleVisit, handleNavigate, setLocked, onError]
  );

  const [depositContract] = useOrganizationContract(
    contract.type === OrganizationContractType.Deposit
      ? contract.id
      : contract.parent
      ? contract.parent.id
      : null
  );

  const [depositTab, setDepositTab] = useState(0);

  return (
    <Loader loading={loading && !loaded}>
      <section>
        <OrderItem
          item={item}
          lock={lock}
          readonly={readonly}
          performer={performer}
          showProviders={!auth.profile.isOwner}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onEdit={
            item.status !== OrderStatus.Cancelled &&
            item.status !== OrderStatus.Completed &&
            item.status !== OrderStatus.Rejected &&
            item.status !== OrderStatus.Forced &&
            item.status !== OrderStatus.Pending &&
            item.status !== OrderStatus.Visited &&
            item.status !== OrderStatus.Paid &&
            contract.type === OrganizationContractType.Direct
              ? handleEdit
              : undefined
          }
          onFree={
            item.status !== OrderStatus.Cancelled &&
            item.status !== OrderStatus.Completed &&
            item.status !== OrderStatus.Rejected &&
            item.status !== OrderStatus.Forced &&
            item.status !== OrderStatus.Pending &&
            item.status !== OrderStatus.Visited &&
            item.status !== OrderStatus.Paid &&
            contract.type === OrganizationContractType.Direct
              ? handleFree
              : undefined
          }
          onCalc={handleCalc}
          onDiscard={
            readonly && contract.type === OrganizationContractType.Direct
              ? toggleDiscard
              : undefined
          }
          onReject={
            !auth.profile.isAgent && readonly ? toggleReject : undefined
          }
          onConfirm={
            !auth.profile.isAgent && readonly ? toggleConfirm : undefined
          }
          onWithdraw={
            readonly && contract.type === OrganizationContractType.Direct
              ? toggleWithdraw
              : undefined
          }
          onDownload={
            readonly
              ? auth.profile.isOwner
                ? handleDownload
                : toggleVoucher
              : undefined
          }
          onRevert={
            !auth.profile.isAgent && readonly ? toggleRevert : undefined
          }
          onReturn={
            readonly &&
            (item.status === OrderStatus.Confirmed ||
              (item.status === OrderStatus.Forced && !auth.profile.isAgent))
              ? handleReturn
              : undefined
          }
          onVisit={
            readonly &&
            (item.status === OrderStatus.Confirmed || item.status === OrderStatus.Visited) &&
            !auth.profile.isAgent
              ? toggleVisit
              : undefined
          }
        />
      </section>
      {readonly && (
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">
                {formatMessage(TXT("label.changes"))}
              </Span>
            </Div>
            <Div>
              <OrderChangesView data={changes} />
            </Div>
          </Div>
        </section>
      )}
      {readonly && depositContract && (
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">
                {formatMessage(TXT("label.deposit"))}
              </Span>
            </Div>
            <Div>
              <Tabs
                items={Array.from(deposit.entries())
                  .filter((item) => item[1].total > 0)
                  .map((item) => ({
                    label: `${item[0].number}`,
                    data: item,
                  }))}
                render={(tabItem, tabIndex) => (
                  <DepositGrid
                    currency={tabItem.data[0].currency.code}
                    data={tabItem.data[1]}
                  />
                )}
                selected={depositTab}
                onSelect={setDepositTab}
              />
            </Div>
          </Div>
        </section>
      )}
      {rejectToggled && (
        <Confirm
          title={TXT("prompt.reject.title")}
          locked={locked}
          onConfirm={handleReject}
        >
          <Span>{formatMessage(TXT("prompt.reject.message"))}</Span>
        </Confirm>
      )}
      {confirmToggled && (
        <Confirm
          title={TXT("prompt.confirm.title")}
          locked={locked}
          canConfirm={confirmModel.resources.some(
            (confirmedResource) => confirmedResource.confirmed
          )}
          onConfirm={handleConfirm}
        >
          <OrderConfirmView
            calc={item}
            item={confirmModel}
            onChange={setConfirmModel}
          />
        </Confirm>
      )}
      {withdrawToggled && (
        <Confirm
          title={TXT("prompt.withdraw.title")}
          locked={locked}
          onConfirm={handleWithdraw}
        >
          <OrderPaymentView
            calc={item}
            item={withdrawModel}
            onChange={setWithdrawModel}
          />
        </Confirm>
      )}
      {voucherToggled && (
        <Confirm
          title={TXT("prompt.voucher.title")}
          locked={locked}
          labelConfirm={ID("action.apply")}
          onConfirm={handleVoucher}
        >
          <ButtonGroup>
            <ToggleBox
              name={"download"}
              primary={true}
              label={ID("action.download")}
              value={voucherModel === "download"}
              onChange={() => setVoucherModel("download")}
            />
            <ToggleBox
              name={"resend"}
              primary={true}
              label={ID("action.resend")}
              value={voucherModel === "resend"}
              onChange={() => setVoucherModel("resend")}
            />
          </ButtonGroup>
        </Confirm>
      )}
      {discardToggled && (
        <Confirm
          title={TXT("prompt.discard.title")}
          locked={locked}
          onConfirm={handleDiscard}
        >
          <Span>
            {formatMessage(TXT("prompt.discard.message", { fine: 0 }))}
          </Span>
        </Confirm>
      )}
      {revertToggled && (
        <Confirm
          title={TXT("prompt.revert.title")}
          locked={locked}
          onConfirm={handleRevert}
        >
          <Span>{formatMessage(TXT("prompt.revert.message"))}</Span>
        </Confirm>
      )}
      {visitToggled && (
        <Confirm
          title={TXT("prompt.visit.title")}
          locked={locked}
          onConfirm={handleVisit}
        >
          <OrderVisitView
            calc={item}
            data={visitModel}
            onChange={setVisitModel}
          />
        </Confirm>
      )}
    </Loader>
  );
}

export interface OrderListPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

export function OrderListPage(props: OrderListPageProps): ReactElement {
  const { onLoaded, onNavigate, onError } = props;

  const auth = useContext(AuthContext);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    Chunk<Order & { airport: string; seller: string }>
  >(new Chunk());
  const [sort, setSort] = useState("");
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(15);
  const [pattern, setPattern] = useState("");

  const reload = useCallback(
    (pattern?: string, sort?: string, skip?: number, take?: number): void => {
      if (pattern !== undefined) setPattern(pattern);
      if (sort !== undefined) setSort(sort);
      if (skip !== undefined) setSkip(skip);
      if (take !== undefined) setTake(take);
      setLoading(true);
    },
    [setLoading, setPattern, setSort, setSkip, setTake]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await OrderAccess.getAll(pattern, sort, skip, take);
          const flightAirports = new Map<string, Airport>();
          const contracts = new Map<string, ResolvedOrganizationContract>();
          const resoledData: (Order & { airport: string; seller: string })[] =
            [];
          for (const item of data.data) {
            const flight = item.spans.flatMap((span) => span.resources)[0];
            const flightAirport =
              flightAirports.get(flight.resource.id) ||
              flightAirports
                .set(
                  flight.resource.id,
                  await AirportAccess.getOne(
                    (
                      await ResourceAccess.getOne(flight.resource.id)
                    ).airport.id
                  )
                )
                .get(flight.resource.id);
            const contract =
              contracts.get(item.contract.id) ||
              contracts
                .set(
                  item.contract.id,
                  await OrganizationContractAccess.getOne(item.contract.id)
                )
                .get(item.contract.id);

            resoledData.push({
              ...item,
              airport: flightAirport?.code ?? "",
              seller:
                contract?.agency.code.toLowerCase() ===
                auth.profile.belongsTo.toLowerCase()
                  ? "Прямая продажа"
                  : contract?.agency.name ?? "",
            });
          }
          setData(new Chunk(resoledData, data.skip, data.take, data.total));
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    auth.profile.belongsTo,
    pattern,
    sort,
    skip,
    take,
    setData,
    setLoaded,
    setLoading,
    onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [loaded, onLoaded]);

  useTimer(() => setLoading(true));

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`);
  }, [onNavigate]);

  const handleItemSelect = useCallback(
    (record: Order): void => {
      onNavigate(`${record.id}`);
    },
    [onNavigate]
  );

  const handleItemSearch = useCallback(
    (newPattern: string): void => {
      if (!R.equals(pattern, newPattern)) {
        reload(newPattern);
      }
    },
    [pattern, reload]
  );

  const handlePageChange = useCallback(
    (skip: number, take: number): void => {
      reload(undefined, undefined, skip, take);
    },
    [reload]
  );

  const handleSortChange = useCallback(
    (sort: string): void => {
      reload(undefined, sort, undefined, undefined);
    },
    [reload]
  );

  return (
    <Loader loading={!loaded && loading}>
      <OrderGrid
        data={data}
        showProviders={auth.profile.isSys}
        showResources={!auth.profile.isOwner}
        showAirports={!auth.profile.isOwner}
        showTypes={!auth.profile.isAgent}
        onItemCreate={handleItemCreate}
        onItemSelect={handleItemSelect}
        onItemSearch={handleItemSearch}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
      />
    </Loader>
  );
}

export interface OrderReturnRequestPageProps
  extends RouteComponentProps<{ orderId: string }, {}, any> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any, processData?: (data: any) => string) => void;
}

export function OrderReturnRequestPage(
  props: OrderReturnRequestPageProps
): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props;

  const itemId = Number(match.params.orderId) || 0;
  const options = new URLSearchParams(location.search);
  const readonly = !options.has("edit");
  const returnUrl = location.state
    ? (location.state.returnUrl as string)
    : undefined;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(new Order());
  const [lock] = useState(new Lock());

  useEffect(() => {
    setLoading(true);
    setLoaded(false);
  }, [itemId]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrderAccess.getOne(itemId);
          setItem(item);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [loading, readonly, itemId, setLoaded, setLoading, setItem, onError]);

  useEffect(() => {
    if (loaded) {
      onLoaded([item]);
    }
  }, [loaded, item, onLoaded]);

  const handleNavigate = useCallback(
    async (item?: Order, edit?: boolean): Promise<void> => {
      if (item && item.id) {
        try {
          if (returnUrl) {
            onNavigate(returnUrl, {
              success: true,
              id: item.id,
              status: item.status,
            });
          } else {
            onNavigate(`${item.id}${edit ? "?edit" : ""}`);
          }
          setLoaded(false);
          setLoading(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        if (returnUrl) {
          onNavigate(returnUrl, { success: false });
        } else {
          onNavigate(``);
        }
      }
    },
    [returnUrl, setLoaded, setLoading, onNavigate]
  );

  const handleCalc = useCallback(
    async (item: OrderUpdateModel): Promise<Order | null> => {
      try {
        const calc = await OrderAccess.update(itemId, item, true);
        setItem(calc);
        return calc;
      } catch (error) {
        onError(error);
        return null;
      }
    },
    [itemId, setItem, onError]
  );

  const handleSubmit = useCallback(
    async (item: OrderUpdateModel): Promise<void> => {
      try {
        const updated = await OrderAccess.update(itemId, item);
        if (updated.status === OrderStatus.Created) {
          await OrderAccess.pay(itemId, {
            mode: updated.paymentMode || OrderPaymentMode.Offline,
            type: updated.paymentType || OrderPaymentType.Cash,
            info: null,
            voucherSendRequired: null,
            voucherRateRequired: null,
          });
        }
        await handleNavigate(updated, false);
      } catch (error) {
        onError(error);
      }
    },
    [itemId, handleNavigate, onError]
  );

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(item, false);
  }, [item, handleNavigate]);

  return (
    <Loader loading={loading}>
      <OrderReturnRequestView
        item={item}
        lock={lock}
        readonly={readonly}
        onCalc={handleCalc}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Loader>
  );
}

export interface OrderReturnConfirmPageProps
  extends RouteComponentProps<{ orderId: string }, {}, any> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any, processData?: (data: any) => string) => void;
}

export function OrderReturnConfirmPage(
  props: OrderReturnConfirmPageProps
): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props;

  const itemId = Number(match.params.orderId) || 0;
  const options = new URLSearchParams(location.search);
  const readonly = !options.has("edit");

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(new Order());
  const [reasons, setReasons] = useState(new OrderReturn());
  const [lock] = useState(new Lock());
  const returnUrl = location.state
    ? (location.state.returnUrl as string)
    : undefined;

  useEffect(() => {
    setLoading(true);
    setLoaded(false);
  }, [itemId]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrderAccess.getOne(itemId);
          setItem(item);
          const reasons = await OrderAccess.getOneReturn(itemId);
          setReasons(reasons);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    readonly,
    itemId,
    setLoaded,
    setLoading,
    setItem,
    setReasons,
    onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([item]);
    }
  }, [loaded, item, onLoaded]);

  const handleNavigate = useCallback(
    async (item?: Order, edit?: boolean): Promise<void> => {
      if (item && item.id) {
        try {
          if (returnUrl) {
            onNavigate(returnUrl, {
              success: true,
              id: item.id,
              status: item.status,
            });
          } else {
            onNavigate(`${item.id}${edit ? "?edit" : ""}`);
          }
          setLoaded(false);
          setLoading(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        if (returnUrl) {
          onNavigate(returnUrl, { success: false });
        } else {
          onNavigate(``);
        }
      }
    },
    [returnUrl, setLoaded, setLoading, onNavigate]
  );

  const handleReject = useCallback(async (): Promise<void> => {
    try {
      await OrderAccess.force(item.id, false);
      await handleNavigate(item, false);
    } catch (error) {
      onError(error);
    }
  }, [item, handleNavigate, onError]);

  const handleConfirm = useCallback(async (): Promise<void> => {
    try {
      await OrderAccess.force(item.id, true);
      await handleNavigate(item, false);
    } catch (error) {
      onError(error);
    }
  }, [item, handleNavigate, onError]);

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(item, false);
  }, [item, handleNavigate]);

  return (
    <Loader loading={loading}>
      <OrderReturnConfirmView
        item={item}
        reasons={reasons}
        lock={lock}
        readonly={readonly}
        onConfirm={handleConfirm}
        onReject={handleReject}
        onCancel={handleCancel}
      />
    </Loader>
  );
}

function pathBuilder(items: Order[]): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: TXT("page.orders"),
  });
  for (const item of items) {
    pathItems.push({
      path: item.id > 0 ? `${item.id}` : "new",
      text:
        item.id > 0
          ? `№ ${item.id.toString().padStart(6, "0").toUpperCase()}`
          : TXT("page.orders.new"),
    });
  }

  return pathItems;
}

export const Orders = withPage(OrdersPage, pathBuilder);
Orders.displayName = "OrdersPage";
