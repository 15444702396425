import { EMPTY_ID } from "./index";

export enum ServiceFieldType {
  Text = "Text",
  Date = "Date",
  Time = "Time",
}

export class ServiceFieldDescription {
  public id: string;
  public name: string;
  public type: ServiceFieldType;
  public required: boolean;

  public constructor() {
    this.id = "";
    this.name = "";
    this.type = ServiceFieldType.Text;
    this.required = true;
  }
}

export class ServiceField {
  public id: string;
  public value: string;

  public constructor() {
    this.id = EMPTY_ID;
    this.value = "";
  }
}
