import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Chunk } from "../../data";
import { CityBaseV1 } from "../../data/models";
import { CityAccess, CityAccessV1 } from "../data/access";
import { City, CityQuery } from '../data/models'
import { FetchList } from "./types";

export function useCities(pageSize?: number, prefetch?: boolean, query?: CityQuery): [ Chunk<City>, FetchList ] {
  const [ data, setData ] = useState<Chunk<City>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await CityAccess.getAll(pattern, undefined, skip || 0, take || pageSize, query);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, query, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, query, fetch ]);

  return [ data, fetch ];
}

export function useCity(cityId: string | null): [ City | null, Dispatch<SetStateAction<City | null>> ] {
  const [ item, setItem ] = useState<City | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (cityId) {
          setItem(await CityAccess.getOne(cityId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ cityId, setItem ]);

  return [ item, setItem ];
}

export function useCitiesV1(pageSize?: number, prefetch?: boolean): [ Chunk<CityBaseV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<CityBaseV1>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await CityAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}
