import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Measure, { ContentRect } from "react-measure";
import { AuthContext } from "../../auth";
import { Chunk } from "../../data";
import { PaymentRequisitesV1 } from "../../data/models";
import { ButtonGroup, Confirm, FieldSet, Form } from "../../gears";
import {
  ComboBox,
  DateBox,
  NumberBox,
  SwitchBox,
  TagsBox,
  TextBox,
  ToggleBox,
} from "../../gears/inputs";
import { email, length, required, phone } from "../../gears/validators";
import {
  CurrencyBase,
  DepositOperation,
  DepositOperationType,
  EMPTY_ID,
  OrganizationBase,
  OrganizationContract,
  OrganizationContractCreateModel,
  OrganizationContractType,
  OrganizationReference,
  OrganizationType,
  PaymentRequisites,
  PoolBase,
} from "../data/models";
import {
  Button,
  Div,
  formatOrderBy,
  Grid,
  GridColumn,
  IntlText,
  ItemCreateHandler,
  ItemSearchHandler,
  parseOrderBy,
  replaceSortOrder,
  Span,
  TXT,
} from "../gears";
import {
  useCurrencies,
  useDelay,
  useDepositOperationTypes,
  useField,
  useFormatBoolean,
  useFormatCode,
  useFormatCost,
  useFormatDate,
  useFormatMessage,
  useOrganization,
  useOrganizationContract,
  useOrganizationPaymentRequisites,
  useOrganizations,
  useOrganizationTypes,
  usePools,
  useWindowSize,
} from "../hooks";
import { firstOrNull } from "../utils";
import { GridViewProps, ItemViewProps } from "./types";

interface ContractRequisitesViewProps {
  label?: IntlText;
  readonly: boolean;
  disabled: boolean;
  name: string;
  data: PaymentRequisites[] | null;
  item: PaymentRequisites;
  onChange: (value: PaymentRequisites) => void;
}

function ContractRequisitesView(
  props: ContractRequisitesViewProps
): ReactElement {
  const { label, readonly, disabled, name, data, item, onChange } = props;

  const auth = useContext(AuthContext);

  const formatMessage = useFormatMessage();

  const handleChange = useCallback(
    (fields: {
      paymentAccount?: string | null;
      correspondentAccount?: string | null;
      bankName?: string | null;
      bankIdentificationCode?: string | null;
    }): void => {
      const value = { ...item, ...fields };
      onChange(value);
    },
    [item, onChange]
  );

  const handleSelect = useCallback(
    (value: PaymentRequisitesV1 | null): void => {
      if (value) {
        handleChange(value);
      }
    },
    [handleChange]
  );

  const selectedItem = data
    ? data.filter(
        (dataItem) =>
          dataItem.paymentAccount === item.paymentAccount &&
          dataItem.correspondentAccount === item.correspondentAccount &&
          dataItem.bankName === item.bankName &&
          dataItem.bankIdentificationCode === item.bankIdentificationCode
      )[0] || null
    : null;

  return (
    <FieldSet label={label}>
      <Div layout="grid 12">
        {!readonly && data && data.length > 0 && (
          <Div>
            <Div layout="grid 12 3@lg">
              <Div>
                <ComboBox
                  fill={true}
                  name={`${name}-choose`}
                  data={data}
                  value={selectedItem}
                  valueKey="id"
                  valueLabel="id"
                  onChange={handleSelect}
                />
              </Div>
            </Div>
          </Div>
        )}
        <Div>
          <Div layout="grid 12 3@lg">
            <Div>
              <TextBox
                label={TXT("label.paymentAccount")}
                disabled={disabled && !readonly}
                fill={true}
                name={`${name}-paymentAccount`}
                value={item.paymentAccount}
                validators={[(value) => length(value, 1, 256)]}
                onChange={(paymentAccount) => handleChange({ paymentAccount })}
              />
            </Div>
            <Div>
              <TextBox
                label={TXT("label.correspondentAccount")}
                disabled={disabled && !readonly}
                fill={true}
                name={`${name}-correspondentAccount`}
                value={item.correspondentAccount}
                validators={[(value) => length(value, 1, 256)]}
                onChange={(correspondentAccount) =>
                  handleChange({ correspondentAccount })
                }
              />
            </Div>
            <Div>
              <TextBox
                label={TXT("label.bankName")}
                disabled={disabled && !readonly}
                fill={true}
                name={`${name}-bankName`}
                value={item.bankName}
                validators={[(value) => length(value, 1, 256)]}
                onChange={(bankName) => handleChange({ bankName })}
              />
            </Div>
            <Div>
              <TextBox
                label={TXT("label.bankIdentificationCode")}
                disabled={disabled && !readonly}
                fill={true}
                name={`${name}-bankIdentificationCode`}
                value={item.bankIdentificationCode}
                validators={[(value) => length(value, 1, 256)]}
                onChange={(bankIdentificationCode) =>
                  handleChange({ bankIdentificationCode })
                }
              />
            </Div>
          </Div>
        </Div>
      </Div>
      {!readonly && !data && auth.profile.isAgent && (
        <Div>
          <Span intent="info">{formatMessage(TXT("hint.filledByOwner"))}</Span>
        </Div>
      )}
      {!readonly && !data && auth.profile.isOwner && (
        <Div>
          <Span intent="info">{formatMessage(TXT("hint.filledByAgent"))}</Span>
        </Div>
      )}
    </FieldSet>
  );
}

export interface ContractItemProps
  extends ItemViewProps<
    OrganizationContract<OrganizationBase, PoolBase, CurrencyBase>,
    OrganizationContractCreateModel
  > {}

export function ContractItem(props: ContractItemProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onDelete, onEdit, onFree } =
    props;

  const intl = useIntl();
  const auth = useContext(AuthContext);
  const profile = auth.profile;

  const pageSize = 100;
  const id = item.id;
  const isNew = id === EMPTY_ID;

  const formatMessage = useFormatMessage();

  const [belongsTo] = useOrganization(profile.belongsTo);
  const [provider] = useOrganization(item.provider.id);

  const [pools, getPools] = usePools(
    pageSize,
    provider !== null,
    useMemo(
      () => ({
        byOrganizationId: provider?.id,
      }),
      [provider]
    )
  );
  const [selPools, setPools] = useField(
    useMemo(
      () =>
        item.rules
          .filter((rule) => rule.pool !== null)
          .map((rule) => rule.pool!),
      [item.rules]
    )
  );

  const agencyFilter = useMemo(() => ({ byType: OrganizationType.Agency }), []);
  const [agencies, getAgencies] = useOrganizations(
    pageSize,
    !readonly && isNew,
    agencyFilter
  );
  const [agency, setAgency] = useOrganization(
    item.agency.id !== EMPTY_ID ? item.agency.id : null
  );

  const [number, setNumber] = useField<string | null>(item.number);
  const [concludedAt, setConcludedAt] = useField<string | null>(
    item.concludedAt
  );
  const [authorized, setAuthorized] = useField(item.authorized);

  const [providerRequisitesData] = useOrganizationPaymentRequisites(
    provider !== null ? provider.id : null
  );
  const [providerRequisitesItem, setProviderRequisitesItem] = useField(
    item.providerRequisites
  );

  const [agencyRequisitesData] = useOrganizationPaymentRequisites(
    agency !== null ? agency.id : null
  );
  const [agencyRequisitesItem, setAgencyRequisitesItem] = useField(
    item.agencyRequisites
  );

  const [currencies] = useCurrencies();
  const [currency, setCurrency] = useField(
    firstOrNull(currencies.filter((pair) => pair.code === item.currency.code))
  );

  const [contactName, setContactName] = useField<string | null>(
    item.contactInformation.fullName
  );
  const [contactEmail, setContactEmail] = useField<string | null>(
    item.contactInformation.email
  );
  const [contactPhone, setContactPhone] = useField<string | null>(
    item.contactInformation.phone
  );

  const [source, setSource] = useField<string | null>(item.source);

  const [existed] = useOrganizationContract(
    provider !== null && agency !== null && number
      ? `${provider.id}/${agency.id}/${encodeURIComponent(number)}`
      : null
  );

  const getItem = useCallback((): OrganizationContractCreateModel => {
    const item = new OrganizationContractCreateModel();
    item.number = number || "";
    item.type = OrganizationContractType.Deposit;
    item.provider = provider || new OrganizationReference();
    item.agency = agency || new OrganizationReference();
    item.concludedAt = concludedAt || "";
    item.providerRequisites = providerRequisitesItem;
    item.agencyRequisites = agencyRequisitesItem;
    item.authorized = authorized;
    item.rules = selPools.map((pool) => ({ pool }));
    item.currency = currency !== null ? currency.code : "RUB";
    item.contactInformation.fullName = contactName;
    item.contactInformation.email = contactEmail;
    item.contactInformation.phone = contactPhone;
    item.source = source;

    return item;
  }, [
    number,
    provider,
    agency,
    concludedAt,
    authorized,
    providerRequisitesItem,
    agencyRequisitesItem,
    selPools,
    currency,
    contactName,
    contactEmail,
    contactPhone,
    source,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem());
    }
  }, [getItem, onSubmit]);

  const renamedPools = useMemo(() => {
    return new Chunk(
      pools.data.map((pool) => ({
        ...pool,
        name:
          pool.name === "(DEFAULT)"
            ? intl.formatMessage({ id: "label.defaultPool" })
            : pool.name,
      })),
      pools.skip,
      pools.take,
      pools.total
    );
  }, [pools, intl]);

  return (
    <Form
      lock={lock}
      loaded={true}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onDelete={onDelete}
      onEdit={onEdit}
      onFree={onFree}
    >
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12 3@lg">
              {auth.profile.isSys && (
                <Div>
                  <ComboBox
                    label={TXT("label.agency")}
                    disabled={!isNew && !readonly}
                    fill={true}
                    searchable={true}
                    pageSize={pageSize}
                    name="agency"
                    data={agencies}
                    value={agency}
                    valueKey="id"
                    valueLabel="name"
                    validators={[required]}
                    onChange={setAgency}
                    onFetch={getAgencies}
                  />
                </Div>
              )}
              {belongsTo &&
                (item.provider.id === belongsTo.id ||
                  belongsTo.type === OrganizationType.System) && (
                  <Div>
                    <TagsBox
                      label={TXT("label.pools")}
                      fill={true}
                      pageSize={pageSize}
                      name="pools"
                      data={renamedPools}
                      value={selPools.map((pool) => ({
                        ...pool,
                        name:
                          pool.name === "(DEFAULT)"
                            ? intl.formatMessage({ id: "label.defaultPool" })
                            : pool.name,
                      }))}
                      valueKey="id"
                      valueLabel="name"
                      validators={[required]}
                      onFetch={getPools}
                      onChange={setPools}
                    />
                  </Div>
                )}
              <Div>
                <ComboBox
                  label={TXT("label.currency")}
                  fill={true}
                  disabled={!isNew && !readonly}
                  name="currency"
                  data={currencies}
                  value={currency}
                  valueKey="code"
                  valueLabel="name"
                  validators={[required]}
                  onChange={setCurrency}
                />
              </Div>
              <Div>
                <TextBox
                  label={TXT("label.contractNumber")}
                  disabled={!isNew && !readonly}
                  fill={true}
                  name="number"
                  value={number}
                  error={
                    isNew && existed !== null
                      ? TXT("hint.contractAlreadyExists")
                      : undefined
                  }
                  validators={[required, (value) => length(value, 3, 128)]}
                  onChange={setNumber}
                />
              </Div>
              {belongsTo &&
                (item.provider.id === belongsTo.id ||
                  belongsTo.type === OrganizationType.System) && (
                  <Div>
                    <TextBox
                      label={TXT("label.source")}
                      fill={true}
                      name="source"
                      value={source}
                      validators={[(value) => length(value, 3, 128)]}
                      onChange={setSource}
                    />
                  </Div>
                )}
              <Div>
                <DateBox
                  label={TXT("label.effectiveFrom")}
                  disabled={
                    !auth.profile.isSys &&
                    item.provider.code !==
                      auth.profile.belongsTo.toUpperCase() &&
                    !readonly
                  }
                  fill={true}
                  zone="UTC"
                  name="concludedAt"
                  value={concludedAt}
                  validators={[required]}
                  onChange={setConcludedAt}
                />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <ContractRequisitesView
            label={TXT("label.providerRequisites")}
            readonly={readonly}
            disabled={
              !auth.profile.isSys &&
              item.provider.code !== auth.profile.belongsTo.toUpperCase()
            }
            name="providerRequisites"
            data={
              providerRequisitesData !== null ? providerRequisitesData.data : []
            }
            item={providerRequisitesItem || new PaymentRequisitesV1()}
            onChange={setProviderRequisitesItem}
          />
        </Div>
        <Div>
          <ContractRequisitesView
            label={TXT("label.agencyRequisites")}
            readonly={readonly}
            disabled={
              !auth.profile.isSys &&
              item.agency.code !== auth.profile.belongsTo.toUpperCase()
            }
            name="agencyRequisites"
            data={
              agencyRequisitesData !== null ? agencyRequisitesData.data : []
            }
            item={agencyRequisitesItem || new PaymentRequisitesV1()}
            onChange={setAgencyRequisitesItem}
          />
        </Div>
        {(auth.profile.isSys ||
          item.agency.code === auth.profile.belongsTo.toUpperCase()) && (
          <Div>
            <FieldSet label={TXT("label.contactInformation")}>
              <Div layout="grid 12 4@lg">
                <Div>
                  <TextBox
                    label={TXT("label.fullName")}
                    fill={true}
                    name="contactName"
                    value={contactName}
                    validators={[(value) => length(value, 3, 256)]}
                    onChange={setContactName}
                  />
                </Div>
                <Div>
                  <TextBox
                    label={TXT("label.email")}
                    fill={true}
                    name="contactEmail"
                    value={contactEmail}
                    validators={[email, (value) => length(value, 3, 100)]}
                    onChange={setContactEmail}
                  />
                </Div>
                <Div>
                  <TextBox
                    label={TXT("label.phone")}
                    fill={true}
                    mask="(999) 000-00-00"
                    name="contactPhone"
                    value={contactPhone}
                    validators={[phone]}
                    onChange={setContactPhone}
                  />
                </Div>
              </Div>
            </FieldSet>
          </Div>
        )}
        <Div>
          {(auth.profile.isSys ||
            item.provider.code === auth.profile.belongsTo.toUpperCase()) &&
            !readonly && (
              <FieldSet label={TXT("label.authorization")}>
                <Div layout="grid 12">
                  <Div>
                    {formatMessage(TXT("action.authorize"))}
                    {"? "}
                    <SwitchBox
                      disabled={
                        !auth.profile.isSys &&
                        item.provider.code !==
                          auth.profile.belongsTo.toUpperCase() &&
                        !readonly
                      }
                      name="authorized"
                      value={authorized}
                      onChange={setAuthorized}
                    />
                  </Div>
                </Div>
              </FieldSet>
            )}
          {(auth.profile.isAgent || readonly) && (
            <Div>
              {authorized ? (
                <Span intent="success">
                  {formatMessage(TXT("hint.contractIsAuthorized"))}
                </Span>
              ) : (
                <Span intent="info">
                  {formatMessage(TXT("hint.contractIsNotAuthorized"))}
                </Span>
              )}
            </Div>
          )}
        </Div>
      </Div>
    </Form>
  );
}

export interface ContractGridProps
  extends GridViewProps<OrganizationContract<OrganizationBase>> {
  showProviders?: boolean;
  showAgencies?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function ContractGrid(props: ContractGridProps): ReactElement {
  const {
    data,
    showProviders,
    showAgencies,
    onItemCreate,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
  } = props;

  const formatCode = useFormatCode();
  const formatBoolean = useFormatBoolean();
  const formatDate = useFormatDate();
  const [, formatType] = useOrganizationTypes();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        number: item.number,
        provider: item.provider.name,
        agency: item.agency.name,
        validFrom: formatDate(item.concludedAt),
        authorized: formatBoolean(item.authorized),
      });
    }
    setProcessedData(processedData);
  }, [
    data.data,
    data.skip,
    data.take,
    data.total,
    formatCode,
    formatBoolean,
    formatDate,
    formatType,
    setProcessedData,
  ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: string }): void => {
      const originalItem = data.data.filter((i) => i.id === item.id)[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  const handleSortChange = useCallback(
    (orderBy: string): void => {
      if (onSortChange) {
        const sortOrder = parseOrderBy(orderBy);
        replaceSortOrder(sortOrder, "code", "id");

        onSortChange(formatOrderBy(sortOrder));
      }
    },
    [onSortChange]
  );

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) && (
        <Div>
          <Div layout="flex">
            {onItemSearch && (
              <Div layout="fill">
                <TextBox
                  placeholder={TXT("label.search")}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern}
                />
              </Div>
            )}
            {onItemCreate && (
              <Div layout="fit">
                <Button
                  primary={true}
                  className="action"
                  onClick={onItemCreate}
                >
                  <FormattedMessage {...TXT("action.create")} />
                </Button>
              </Div>
            )}
          </Div>
        </Div>
      )}
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}
            >
              <GridColumn
                title={TXT("label.number")}
                field="number"
                width={128}
                locked={true}
              />
              {showProviders && (
                <GridColumn
                  title={TXT("label.provider")}
                  field="provider"
                  fill={true}
                  width={256}
                  sortable={false}
                />
              )}
              {showAgencies && (
                <GridColumn
                  title={TXT("label.agency")}
                  field="agency"
                  fill={true}
                  width={256}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.concludedAt")}
                field="validFrom"
                width={128}
              />
              <GridColumn
                title={TXT("label.authorization")}
                field="authorized"
                width={128}
                locked={true}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}

interface OperationSignProps {
  value: number;
  onChange: (value: number) => void;
}

function OperationSign(props: OperationSignProps) {
  const { value, onChange } = props;

  return (
    <ButtonGroup>
      <ToggleBox
        primary={true}
        label={TXT("action.increase")}
        name={"increase"}
        value={value > 0}
        onChange={(value) => value && onChange(1)}
      />
      <ToggleBox
        primary={true}
        label={TXT("action.decrease")}
        name={"decrease"}
        value={value < 0}
        onChange={(value) => value && onChange(-1)}
      />
    </ButtonGroup>
  );
}

export interface DepositViewProps {
  currency: string;
  balance: number;
  overdraft: number;
  autoreplenish: boolean;
  onBalanceChange?: (value: number) => void;
  onOverdraftChange?: (value: number) => void;
  onAutoreplenishChange?: (value: boolean) => void;
}

export function DepositView(props: DepositViewProps): ReactElement {
  const {
    currency,
    balance,
    overdraft,
    autoreplenish,
    onBalanceChange,
    onOverdraftChange,
    onAutoreplenishChange,
  } = props;

  const formatCost = useFormatCost();
  const formatMessage = useFormatMessage();

  const balanceView = Math.max(balance || 0, 0);
  const overdraftView =
    (balance || 0) > 0 ? overdraft || 0 : (overdraft || 0) + (balance || 0);

  const [balanceChangeToggled, setBalanceChangeToggled] = useState(false);
  const [balanceChangeValue, setBalanceChangeValue] = useState<number | null>(
    null
  );
  const [balanceChangeSign, setBalanceChangeSign] = useState(1);

  const [overdraftChangeToggled, setOverdraftChangeToggled] = useState(false);
  const [overdraftChangeValue, setOverdraftChangeValue] = useState<
    number | null
  >(null);
  const [overdraftChangeSign, setOverdraftChangeSign] = useState(1);

  const toggleBalanceChange = useCallback((): void => {
    setBalanceChangeToggled((prev) => !prev);
  }, [setBalanceChangeToggled]);

  const toggleOverdraftChange = useCallback((): void => {
    setOverdraftChangeToggled((prev) => !prev);
  }, [setOverdraftChangeToggled]);

  const handleBalanceChange = useCallback(
    (confirmed: boolean): void => {
      if (onBalanceChange && confirmed) {
        setBalanceChangeValue(null);
        onBalanceChange(balanceChangeSign * (balanceChangeValue || 0));
      }
      setBalanceChangeToggled(false);
    },
    [
      balanceChangeSign,
      balanceChangeValue,
      setBalanceChangeValue,
      setBalanceChangeToggled,
      onBalanceChange,
    ]
  );

  const handleOverdraftChange = useCallback(
    (confirmed: boolean): void => {
      if (onOverdraftChange && confirmed) {
        setOverdraftChangeValue(null);
        onOverdraftChange(overdraftChangeSign * (overdraftChangeValue || 0));
      }
      setOverdraftChangeToggled(false);
    },
    [
      overdraftChangeSign,
      overdraftChangeValue,
      setOverdraftChangeValue,
      setOverdraftChangeToggled,
      onOverdraftChange,
    ]
  );

  return (
    <Div className="deposit">
      <Div layout="grid 12 6@lg">
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="label">
                {formatMessage(TXT("label.balance"))}
              </Span>
            </Div>
            <Div>
              <Div layout="flex vertical-center">
                <Div layout="fill">
                  <Span className="value">
                    {formatCost(balanceView, currency)}
                  </Span>
                </Div>
                {onBalanceChange && (
                  <Div layout="fit">
                    <Button
                      look="outline"
                      primary={true}
                      className="action"
                      onClick={toggleBalanceChange}
                    >
                      {formatMessage(TXT("action.change"))}
                    </Button>
                  </Div>
                )}
              </Div>
            </Div>
            <Div>
              {onAutoreplenishChange && (
                <ToggleBox
                  name="autoreplenish"
                  label={TXT("label.autoReplenish")}
                  value={autoreplenish}
                  onChange={onAutoreplenishChange}
                  primary={true}
                />
              )}
            </Div>
          </Div>
        </Div>
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="label">
                {formatMessage(TXT("label.overdraft"))}
              </Span>
            </Div>
            <Div>
              <Div layout="flex vertical-center">
                <Div layout="fill">
                  {overdraft !== overdraftView && (
                    <Span className="value">
                      {" "}
                      {formatMessage(TXT("label.available"))}{" "}
                      {formatCost(overdraftView)}{" "}
                      {formatMessage(TXT("label.of"))}{" "}
                    </Span>
                  )}
                  <Span className="value">
                    {formatCost(overdraft, currency)}
                  </Span>
                </Div>
                {onOverdraftChange && (
                  <Div layout="fit">
                    <Button
                      look="outline"
                      primary={true}
                      className="action"
                      onClick={toggleOverdraftChange}
                    >
                      {formatMessage(TXT("action.change"))}
                    </Button>
                  </Div>
                )}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {balanceChangeToggled && (
        <Confirm
          title={TXT("action.change")}
          canConfirm={balanceChangeValue !== null && balanceChangeValue > 0}
          onConfirm={handleBalanceChange}
        >
          <Div style={{ width: "256px" }}>
            <Div layout="grid 12">
              <Div>
                <NumberBox
                  fill={true}
                  name="depositValue"
                  value={balanceChangeValue}
                  min={0}
                  onChange={setBalanceChangeValue}
                />
              </Div>
              <Div>
                <OperationSign
                  value={balanceChangeSign}
                  onChange={setBalanceChangeSign}
                />
              </Div>
            </Div>
          </Div>
        </Confirm>
      )}
      {overdraftChangeToggled && (
        <Confirm
          title={TXT("action.change")}
          canConfirm={
            overdraftChangeValue !== null && overdraftChangeValue >= 0
          }
          onConfirm={handleOverdraftChange}
        >
          <Div style={{ width: "256px" }}>
            <Div layout="grid 12">
              <Div>
                <NumberBox
                  fill={true}
                  name="overdraftValue"
                  value={overdraftChangeValue}
                  min={0}
                  onChange={setOverdraftChangeValue}
                />
              </Div>
              <Div>
                <OperationSign
                  value={overdraftChangeSign}
                  onChange={setOverdraftChangeSign}
                />
              </Div>
            </Div>
          </Div>
        </Confirm>
      )}
    </Div>
  );
}

export interface DepositGridProps extends GridViewProps<DepositOperation> {
  currency: string;
  onItemSearch?: ItemSearchHandler;
}

export function DepositGrid(props: DepositGridProps): ReactElement {
  const {
    currency,
    data,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
  } = props;

  const formatCode = useFormatCode();
  const formatCost = useFormatCost();
  const formatDate = useFormatDate();
  const [, formatType] = useDepositOperationTypes();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      const sign =
        item.type === DepositOperationType.Replenish ||
        item.type === DepositOperationType.Refund
          ? "+"
          : item.type === DepositOperationType.Withdraw ||
            item.type === DepositOperationType.Reduce
          ? "-"
          : " ";
      processedData.data.push({
        id: item.id,
        operationType: formatType(item.type),
        value: `${sign}${formatCost(Math.abs(item.value), currency)}`,
        performedAt: formatDate(item.performedAt, true),
        performedBy:
          item.performedBy?.fullName ||
          item.performedBy?.userName ||
          item.performedBy?.clientId ||
          "",
      });
    }
    setProcessedData(processedData);
  }, [
    data.data,
    data.skip,
    data.take,
    data.total,
    currency,
    formatCode,
    formatCost,
    formatDate,
    formatType,
    setProcessedData,
  ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: string }): void => {
      const originalItem = data.data.filter((i) => i.id === item.id)[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  const handleSortChange = useCallback(
    (orderBy: string): void => {
      if (onSortChange) {
        const sortOrder = parseOrderBy(orderBy);
        replaceSortOrder(sortOrder, "code", "id");

        onSortChange(formatOrderBy(sortOrder));
      }
    },
    [onSortChange]
  );

  return (
    <Div layout="grid 12">
      {onItemSearch && (
        <Div>
          <Div layout="flex">
            <Div layout="fill">
              <TextBox
                placeholder={TXT("label.search")}
                fill={true}
                name="search-pattern"
                value={currentPattern}
                onChange={setPattern}
              />
            </Div>
          </Div>
        </Div>
      )}
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}
            >
              <GridColumn
                title={TXT("label.operationType")}
                field="operationType"
                width={256}
                fill={true}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.dateTime")}
                field="performedAt"
                width={192}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.value")}
                field="value"
                width={256}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.user")}
                field="performedBy"
                width={256}
                sortable={false}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}
