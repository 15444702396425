import { useCallback, useEffect, useState } from "react";
import { Apis } from "../../data";

export function useFileUrl(): [ string, (id: string) => string ] {
  const [ baseUrl, setBaseUrl ] = useState("");

  useEffect(() => {
    (async (): Promise<void> => {
      setBaseUrl(`${await Apis.getGatewayV1()}/files`);
    })();
  }, [ setBaseUrl ]);

  const formatFileUrl = useCallback((id: string): string => {
    if (baseUrl && id) {
      return `${baseUrl}/${id}`;
    } else {
      return "";
    }
  }, [ baseUrl ]);

  return [ baseUrl, formatFileUrl ];
}
