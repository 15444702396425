export class LegalRequisites {
  public legalAddress: string | null
  public postalAddress: string | null
  public taxIdentificationNumber: string | null
  public taxRegistrationReasonCode: string | null
  public primaryStateRegistrationNumber: string | null
  public generalPhone: string | null
  public supportPhone: string | null
  public fax: string | null
  public email: string | null
  public chiefExecutiveOfficer: string | null
  public chiefAccountant: string | null

  public constructor() {
    this.legalAddress = null
    this.postalAddress = null
    this.taxIdentificationNumber = null
    this.taxRegistrationReasonCode = null
    this.primaryStateRegistrationNumber = null
    this.generalPhone = null
    this.supportPhone = null
    this.fax = null
    this.email = null
    this.chiefExecutiveOfficer = null
    this.chiefAccountant = null
  }
}
