import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import {
  ContractDepositOperationV1,
  OrderV1,
  OrderBaseV1,
  OrderBlockModelV1,
  OrderChangeV1,
  OrderConfirmModelV1,
  OrderCreateModelV1,
  OrderedFlightPatchModelV1,
  OrderedFlightRecordV1,
  OrderedRoomRecordV1,
  OrderedRoomPatchModelV1,
  OrderModifyModelV1,
  OrderPaymentModelV1,
  OrderRecord,
  OrderUpdateModelV1,
} from "../../../data/models";

export class OrderAccessV1 {
  public static async fetchItem(id: number): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<OrderV1>("Order", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<OrderBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrderBaseV1>>("Order", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<OrderRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrderRecord>>("Order/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }

  public static async fetchFlights(pattern?: string, orderBy?: string, skip?: number, take?: number, date?: string, lounges?: boolean, assistance?: boolean, resourceId?: number): Promise<Chunk<OrderedFlightRecordV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrderedFlightRecordV1>>("Order/flights", {}, {
        pattern,
        orderBy,
        skip,
        take,
        date,
        lounges,
        assistance,
        resourceId,
      });
  }

  public static async fetchRooms(pattern?: string, orderBy?: string, skip?: number, take?: number, resourceId?: number, date?: string): Promise<Chunk<OrderedRoomRecordV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrderedRoomRecordV1>>("Order/rooms", {}, {
        pattern,
        orderBy,
        skip,
        take,
        resourceId,
        date,
      });
  }

  public static async fetchChanges(id: number): Promise<Chunk<OrderChangeV1>> {
    const data = await Client
      .instance(await Apis.getDataApi())
      .get<OrderChangeV1[]>("Order/changes", { id });

    return new Chunk<OrderChangeV1>(data, 0, data.length, data.length);
  }

  public static async fetchDeposit(id: number): Promise<Chunk<ContractDepositOperationV1>> {
    const data = await Client
      .instance(await Apis.getDataApi())
      .get<ContractDepositOperationV1[]>("Order/deposit", { id });

    return new Chunk<ContractDepositOperationV1>(data, 0, data.length, data.length);
  }

  public static async create(item: OrderCreateModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Order", item);
  }

  public static async update(id: number, item: OrderUpdateModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update("Order", item, { id });
  }

  public static async modify(id: number, item: OrderModifyModelV1, forced: boolean): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .modify("Order", item, { id }, { forced });
  }

  public static async delete(id: number, seatId?: number): Promise<void> {
    return await Client
      .instance(await Apis.getDataApi())
      .delete(`Order/${id}${seatId ? `/seat/${seatId}` : ""}`);
  }

  public static async block(item: OrderBlockModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Order/block", item);
  }

  public static async patchFlight(item: OrderedFlightPatchModelV1): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .modify("Order/flight", item);
  }

  public static async patchRoom(item: OrderedRoomPatchModelV1): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .modify("Order/room", item);
  }

  public static async rateNew(item: OrderCreateModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create<OrderCreateModelV1, OrderV1>(`Order/new/rate`, item);
  }

  public static async rateUpdated(id: number, item: OrderUpdateModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<OrderUpdateModelV1, OrderV1>(`Order/${id}/rate`, item);
  }

  public static async rateModified(id: number, item: OrderModifyModelV1, forced: boolean): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .modify<OrderModifyModelV1, OrderV1>(`Order/${id}/rate`, item, {}, { forced });
  }

  public static async rateCancelled(id: number): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .delete<OrderV1>(`Order/${id}/rate`);
  }

  public static async confirmCreated(id: number, item: OrderConfirmModelV1): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .modify<OrderConfirmModelV1, OrderV1>(`Order/${id}/confirm`, item);
  }

  public static async confirmForced(id: number): Promise<OrderV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<any, OrderV1>(`Order/${id}/confirm`, {});
  }

  public static async rejectCreated(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete(`Order/${id}/confirm`, {});
  }

  public static async rejectForced(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete(`Order/${id}/confirm`, {});
  }

  public static async pay(id: number, item?: OrderPaymentModelV1): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .modify<any, OrderV1>(`Order/${id}/pay`, item ? item : {});
  }

  public static async download(id: number): Promise<Blob> {
    return await Client
      .instance(await Apis.getDataApi(), "blob")
      .get<Blob>(`Order/${id}/voucher`);
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Order/lock", { id }, { action })
  }
}
