import { Apis, Chunk, Client } from '../../../data'
import { EMPTY_ID, Media, MediaKind, Rate, RateFilter, ResolvedResource, Resource, ResourceCreateModel, ResourceFilter, ResourceReference, ResourceSettings, ResourceTranslateModel, ResourceTranslation, ResourceUpdateModel, Service, ServiceKind, ServiceType } from '../models'

export class ResourceAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: ResourceFilter): Promise<Chunk<ResolvedResource>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        as: 'Resolved',
        language: 'default',
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<ResolvedResource> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}`, {}, { as: 'Resolved', language: 'default' })
  }

  public static async getOneTranslations(id: string): Promise<ResourceTranslation[]> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/translations`)
  }

  public static async getOneSettings(id: string): Promise<ResourceSettings> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/settings`)
  }

  public static async getOneRates(id: string, filter?: RateFilter, as?: string, currency?: string): Promise<Chunk<Rate>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/rates`, {}, {
        as,
        inCurrency: currency,
        ...filter,
      })
  }

  public static async getOneBaseRates(id: string, filter?: RateFilter, as?: string, currency?: string): Promise<Chunk<Rate>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/rates/base`, {}, {
        as,
        inCurrency: currency,
        ...filter,
      })
  }

  public static async getOneServices(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number, byKind?: ServiceKind | ServiceKind[], byType?: ServiceType | ServiceType[]): Promise<Chunk<Service>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/services`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        byKind,
        byType,
      })
  }

  public static async getOneMedias(id: string, byKind?: MediaKind): Promise<Chunk<Media>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/medias`, {}, { byKind })
  }
  
  public static async getOneSubscribers(id: string): Promise<string[]> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/subscribers`)
  }

  public static async getOneCompatibilities(id: string): Promise<ServiceKind[]> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`resources/${id}/compatibilities`)
  }
  
  public static async create(model: ResourceCreateModel): Promise<Resource> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`resources`, model)
  }
  
  public static async update(id: string, model: ResourceUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`resources/${id}`, model)
  }

  public static async updateTranslations(id: string, updated: ResourceTranslation[]): Promise<void> {
    const existed = await this.getOneTranslations(id)
    const removed = existed.filter(item => !updated.map(translation => translation.language).some(language => item.language === language))

    for (const translation of updated) {
      await Client
        .instance(await Apis.getGatewayV0())
        .modify(`resources/${id}/translations?language=${translation.language}`, translation)
    }

    for (const translation of removed) {
      await Client
        .instance(await Apis.getGatewayV0())
        .delete(`resources/${id}/translations?language=${translation.language}`)
    }
  }

  public static async updateMedia(id: string, updated: Media[], kind: MediaKind): Promise<void> {
    const existed = await this.getOneMedias(id, kind).then((chunk) => chunk.data);
    const removed = existed.filter(item => !updated.map(image => image.id).some(id => item.id === id));

    for (const image of updated) {
      if (image.id !== EMPTY_ID) {
        await Client.instance(await Apis.getGatewayV0()).update(`medias/${image.id}`, image);
      } else {
        await Client.instance(await Apis.getGatewayV0()).create("medias/resource", { ...image, resource: new ResourceReference(id) });
      }
    }

    for (const image of removed) {
      if (image.id !== EMPTY_ID) {
        await Client.instance(await Apis.getGatewayV0()).delete(`medias/${image.id}`);
      }
    }
  }
  
  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`resources/${id}`)
  }
}
