import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Chunk } from '../../data'
import { PoolAccess } from '../data/access'
import { PoolFilter, ResolvedPool } from '../data/models'
import { FetchList } from './types'

export function usePools(pageSize?: number, prefetch?: boolean, filter?: PoolFilter): [Chunk<ResolvedPool>, FetchList] {
  const [data, setData] = useState<Chunk<ResolvedPool>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await PoolAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter)
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [pageSize, filter, setData])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [prefetch, fetch])

  return [data, fetch]
}

export function usePool(id: string | null): [ResolvedPool | null, Dispatch<SetStateAction<ResolvedPool | null>>] {
  const [item, setItem] = useState<ResolvedPool | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await PoolAccess.getOne(id))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [id, setItem])

  return [item, setItem]
}

export function usePoolParent(id: string | null): [Chunk<ResolvedPool> | null, Dispatch<SetStateAction<Chunk<ResolvedPool> | null>>] {
  const [item, setItem] = useState<Chunk<ResolvedPool> | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await PoolAccess.getOneParent(id))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [id, setItem])

  return [item, setItem]
}
