import React, { DetailedHTMLProps, HTMLAttributes, Ref } from "react";
import { Intent } from "./types";
import { intentToClassName } from "./utils";

export type SpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { intent?: Intent };
export type SpanRef = Ref<HTMLSpanElement>;

export const Span = React.forwardRef((props: SpanProps, ref?: SpanRef) => {
  const { intent, className, ...rest } = props;

  return (
    <span ref={ref} className={intentToClassName(intent, className)} {...rest} />
  );
});

Span.displayName = "Span";
