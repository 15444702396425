import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chunk, Pair } from '../../data'
import { OrderChangeActionV1, OrderedFlightRecordV1, OrderPaymentTypeV1, OrderStatusV1, OrderTypeV1, SeatStatusV1, ServiceTypeV1 } from '../../data/models'
import { OrderAccess, OrderAccessV1 } from '../data/access'
import { Flight, Order, OrderPaymentType, OrderStatus, OrderType, SeatStatus, ServiceType } from '../data/models'
import { FetchList, PairConverter, PairData } from './types'

export function useSeatStatuses(): [ PairData<SeatStatus>, PairConverter<SeatStatus> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<SeatStatus>>([
    new Pair(SeatStatus.Paid, intl.formatMessage({ id: 'enum.seatStatus.paid' })),
    new Pair(SeatStatus.Rated, intl.formatMessage({ id: 'enum.seatStatus.rated' })),
    new Pair(SeatStatus.Requested, intl.formatMessage({ id: 'enum.seatStatus.requested' })),
    new Pair(SeatStatus.Rejected, intl.formatMessage({ id: 'enum.seatStatus.rejected' })),
    new Pair(SeatStatus.NoSchedule, intl.formatMessage({ id: 'enum.seatStatus.noSchedule' })),
    new Pair(SeatStatus.NoRate, intl.formatMessage({ id: 'enum.seatStatus.noRate' })),
    new Pair(SeatStatus.NoLimit, intl.formatMessage({ id: 'enum.seatStatus.noLimit' })),
    new Pair(SeatStatus.NoSeat, intl.formatMessage({ id: 'enum.seatStatus.noSeat' })),
    new Pair(SeatStatus.Fined, intl.formatMessage({ id: 'enum.seatStatus.fined' })),
    new Pair(SeatStatus.Forced, intl.formatMessage({ id: 'enum.seatStatus.forced' })),
    new Pair(SeatStatus.Cancelled, intl.formatMessage({ id: 'enum.seatStatus.cancelled' })),
    new Pair(SeatStatus.Returned, intl.formatMessage({ id: 'enum.seatStatus.returned' })),
  ])

  const converter = useCallback((value: SeatStatus): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useFlightTypes(predicate: (code: ServiceType) => boolean = () => true): [ PairData<ServiceType>, PairConverter<ServiceType> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<ServiceType>>([
    new Pair(ServiceType.Arrival, intl.formatMessage({ id: 'enum.serviceType.arrival' })),
    new Pair(ServiceType.Departure, intl.formatMessage({ id: 'enum.serviceType.departure' })),
  ].filter(x => predicate(x.code)))

  const converter = useCallback((value: ServiceType): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderPaymentTypes(): [ PairData<OrderPaymentType>, PairConverter<OrderPaymentType> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<OrderPaymentType>>([
    new Pair(OrderPaymentType.Card, intl.formatMessage({ id: 'enum.orderPaymentType.card' })),
    new Pair(OrderPaymentType.Cash, intl.formatMessage({ id: 'enum.orderPaymentType.cash' })),
    new Pair(OrderPaymentType.Free, intl.formatMessage({ id: 'enum.orderPaymentType.free' })),
    new Pair(OrderPaymentType.Deposit, intl.formatMessage({ id: 'enum.orderPaymentType.deposit' })),
  ])

  const converter = useCallback((value: OrderPaymentType): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderChangeActions(): [ PairData, PairConverter ] {
  const intl = useIntl()
  const [ data ] = useState<PairData>([
    new Pair('Create', intl.formatMessage({ id: 'enum.orderChangeAction.create' })),
    new Pair('Update', intl.formatMessage({ id: 'enum.orderChangeAction.update' })),
    new Pair('Delete', intl.formatMessage({ id: 'enum.orderChangeAction.delete' })),
    new Pair('Confirm', intl.formatMessage({ id: 'enum.orderChangeAction.confirm' })),
    new Pair('Reject', intl.formatMessage({ id: 'enum.orderChangeAction.reject' })),
    new Pair('Pay', intl.formatMessage({ id: 'enum.orderChangeAction.withdraw' })),
    new Pair('Force', intl.formatMessage({ id: 'enum.orderChangeAction.force' })),
    new Pair('Revert', intl.formatMessage({ id: 'enum.orderChangeAction.force' })),
  ])

  const converter = useCallback((code: string): string => {
    const pair = data.filter(pair => pair.code === code)[0]
    return pair ? pair.name : ''
  }, [ data ])

  return [ data, converter ]
}

export function useFlightSearch(): [ Chunk<Flight>, FetchList ] {
  const [ data, setData ] = useState<Chunk<Flight>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (pattern) {
        setData(await OrderAccess.getFlights(pattern, undefined, skip, take))
      } else {
        setData(new Chunk())
      }
    } catch (e) {
      console.error(e)
    }
  }, [ setData ])

  return [ data, fetch ]
}

export function useSeatStatusesV1(): [ PairData<SeatStatusV1>, PairConverter<SeatStatusV1> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<SeatStatusV1>>([
    new Pair(SeatStatusV1.Paid, intl.formatMessage({ id: 'enum.seatStatus.paid' })),
    new Pair(SeatStatusV1.Rated, intl.formatMessage({ id: 'enum.seatStatus.rated' })),
    new Pair(SeatStatusV1.Requested, intl.formatMessage({ id: 'enum.seatStatus.requested' })),
    new Pair(SeatStatusV1.Rejected, intl.formatMessage({ id: 'enum.seatStatus.rejected' })),
    new Pair(SeatStatusV1.NoSchedule, intl.formatMessage({ id: 'enum.seatStatus.noSchedule' })),
    new Pair(SeatStatusV1.NoRate, intl.formatMessage({ id: 'enum.seatStatus.noRate' })),
    new Pair(SeatStatusV1.NoLimit, intl.formatMessage({ id: 'enum.seatStatus.noLimit' })),
    new Pair(SeatStatusV1.NoSeat, intl.formatMessage({ id: 'enum.seatStatus.noSeat' })),
    new Pair(SeatStatusV1.Fined, intl.formatMessage({ id: 'enum.seatStatus.fined' })),
    new Pair(SeatStatusV1.Forced, intl.formatMessage({ id: 'enum.seatStatus.forced' })),
    new Pair(SeatStatusV1.Cancelled, intl.formatMessage({ id: 'enum.seatStatus.cancelled' })),
    new Pair(SeatStatusV1.Returned, intl.formatMessage({ id: 'enum.seatStatus.returned' })),
  ])

  const converter = useCallback((code: SeatStatusV1): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderChangeActionsV1(): [ PairData<OrderChangeActionV1>, PairConverter<OrderChangeActionV1> ] {
  const intl = useIntl()
  const [ data ] = useState<PairData<OrderChangeActionV1>>([
    new Pair(OrderChangeActionV1.Create, intl.formatMessage({ id: 'enum.orderChangeAction.create' })),
    new Pair(OrderChangeActionV1.Update, intl.formatMessage({ id: 'enum.orderChangeAction.update' })),
    new Pair(OrderChangeActionV1.Delete, intl.formatMessage({ id: 'enum.orderChangeAction.delete' })),
    new Pair(OrderChangeActionV1.Confirm, intl.formatMessage({ id: 'enum.orderChangeAction.confirm' })),
    new Pair(OrderChangeActionV1.Reject, intl.formatMessage({ id: 'enum.orderChangeAction.reject' })),
    new Pair(OrderChangeActionV1.Withdraw, intl.formatMessage({ id: 'enum.orderChangeAction.withdraw' })),
    new Pair(OrderChangeActionV1.Block, intl.formatMessage({ id: 'enum.orderChangeAction.create' })),
    new Pair(OrderChangeActionV1.Patch, intl.formatMessage({ id: 'enum.orderChangeAction.update' })),
    new Pair(OrderChangeActionV1.Finalize, intl.formatMessage({ id: 'enum.orderChangeAction.finalize' })),
  ])

  const converter = useCallback((code: OrderChangeActionV1): string => {
    const pair = data.filter(pair => pair.code === code)[0]
    return pair ? pair.name : ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderTypes(): [ PairData<OrderType>, PairConverter<OrderType> ] {
  const intl = useIntl()
  const [ data ] = useState<PairData<OrderType>>([
    new Pair(OrderType.Standard, intl.formatMessage({ id: 'enum.orderType.standard' })),
    new Pair(OrderType.Program, intl.formatMessage({ id: 'enum.orderType.corporate' })),
    new Pair(OrderType.Business, intl.formatMessage({ id: 'enum.orderType.business' })),
    new Pair(OrderType.Block, intl.formatMessage({ id: 'enum.orderType.reservation' })),
  ])

  const converter = useCallback((code: OrderType): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderTypesV1(): [ PairData<OrderTypeV1>, PairConverter<OrderTypeV1> ] {
  const intl = useIntl()
  const [ data ] = useState<PairData<OrderTypeV1>>([
    new Pair(OrderTypeV1.Standard, intl.formatMessage({ id: 'enum.orderType.standard' })),
    new Pair(OrderTypeV1.Corporate, intl.formatMessage({ id: 'enum.orderType.corporate' })),
    new Pair(OrderTypeV1.Business, intl.formatMessage({ id: 'enum.orderType.business' })),
    new Pair(OrderTypeV1.Reservation, intl.formatMessage({ id: 'enum.orderType.reservation' })),
  ])

  const converter = useCallback((code: OrderTypeV1): string => {
    const pair = data.filter(pair => pair.code === code)[0]
    return pair ? pair.name : ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderStatuses(): [ PairData<OrderStatus>, PairConverter<OrderStatus> ] {
  const intl = useIntl()
  const [ data ] = useState<PairData<OrderStatus>>([
    new Pair(OrderStatus.Rated, intl.formatMessage({ id: 'enum.orderStatus.rated' })),
    new Pair(OrderStatus.Created, intl.formatMessage({ id: 'enum.orderStatus.created' })),
    new Pair(OrderStatus.Confirmed, intl.formatMessage({ id: 'enum.orderStatus.confirmed' })),
    new Pair(OrderStatus.Rejected, intl.formatMessage({ id: 'enum.orderStatus.rejected' })),
    new Pair(OrderStatus.Cancelled, intl.formatMessage({ id: 'enum.orderStatus.canceled' })),
    new Pair(OrderStatus.Paid, intl.formatMessage({ id: 'enum.orderStatus.payed' })),
    new Pair(OrderStatus.Pending, intl.formatMessage({ id: 'enum.orderStatus.pending' })),
    new Pair(OrderStatus.Completed, intl.formatMessage({ id: 'enum.orderStatus.completed' })),
    new Pair(OrderStatus.Forced, intl.formatMessage({ id: 'enum.orderStatus.forced' })),
    new Pair(OrderStatus.Visited, intl.formatMessage({ id: 'enum.orderStatus.visited' })),
  ])

  const converter = useCallback((code: OrderStatus): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useCalcOrderStatus(): PairConverter<Order> {
  const intl = useIntl()
  const [ , formatStatus ] = useOrderStatuses()

  return useCallback((item: Order): string => {
    const statuses: SeatStatus[] = []
    const confirmed: (string | null)[] = []
    if (item.status === OrderStatus.Created || item.status === OrderStatus.Paid) {
      const ratedResources = item.spans.flatMap(span => span.resources)
      statuses.push(...ratedResources.flatMap(ratedResource => ratedResource.passengers).map(ratedPassenger => ratedPassenger.status).filter(x => x !== SeatStatus.Rejected))
      statuses.push(...ratedResources.flatMap(ratedResource => ratedResource.services || []).map(ratedService => ratedService.status).filter(x => x !== SeatStatus.Rejected))
      confirmed.push(...ratedResources.flatMap(ratedResource => ratedResource.passengers).filter(ratedPassenger => ratedPassenger.status === SeatStatus.Rated || ratedPassenger.status === SeatStatus.Paid).map(ratedPassenger => ratedPassenger.confirmedAt))
      confirmed.push(...ratedResources.flatMap(ratedResource => ratedResource.services || []).filter(ratedService => ratedService.status === SeatStatus.Rated || ratedService.status === SeatStatus.Paid).map(ratedService => ratedService.confirmedAt))


      if (item.status === OrderStatus.Paid && confirmed.some(status => status === null) && confirmed.some(status => status !== null)) {
        return intl.formatMessage({ id: 'enum.orderStatus.confirmedPartially' })
      }

      if (item.status === OrderStatus.Created && statuses.some(status => status === SeatStatus.Paid) && statuses.some(status => status === SeatStatus.Rated)) {
        return intl.formatMessage({ id: 'enum.orderStatus.payedPartially' })
      }
    }

    return formatStatus(item.status)
  }, [ intl, formatStatus ])
}

export function useOrderStatusesV1(): [ PairData<OrderStatusV1>, PairConverter<OrderStatusV1> ] {
  const intl = useIntl()
  const [ data ] = useState<PairData<OrderStatusV1>>([
    new Pair(OrderStatusV1.Rated, intl.formatMessage({ id: 'enum.orderStatus.rated' })),
    new Pair(OrderStatusV1.Created, intl.formatMessage({ id: 'enum.orderStatus.created' })),
    new Pair(OrderStatusV1.Confirmed, intl.formatMessage({ id: 'enum.orderStatus.confirmed' })),
    new Pair(OrderStatusV1.Rejected, intl.formatMessage({ id: 'enum.orderStatus.rejected' })),
    new Pair(OrderStatusV1.Cancelled, intl.formatMessage({ id: 'enum.orderStatus.canceled' })),
    new Pair(OrderStatusV1.Paid, intl.formatMessage({ id: 'enum.orderStatus.payed' })),
    new Pair(OrderStatusV1.Completed, intl.formatMessage({ id: 'enum.orderStatus.completed' })),
    new Pair(OrderStatusV1.Forced, intl.formatMessage({ id: 'enum.orderStatus.forced' })),
  ])

  const converter = useCallback((code: OrderStatusV1): string => {
    const pair = data.filter(pair => pair.code === code)[0]
    return pair ? pair.name : ''
  }, [ data ])

  return [ data, converter ]
}

export function useFlightTypesV1(predicate: (code: ServiceTypeV1) => boolean = () => true): [ PairData<ServiceTypeV1>, PairConverter<ServiceTypeV1> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<ServiceTypeV1>>([
    new Pair(ServiceTypeV1.Arrival, intl.formatMessage({ id: 'enum.serviceType.arrival' })),
    new Pair(ServiceTypeV1.Departure, intl.formatMessage({ id: 'enum.serviceType.departure' })),
  ].filter(x => predicate(x.code)))

  const converter = useCallback((value: ServiceTypeV1): string => {
    switch (value) {
      case ServiceTypeV1.Arrival:
        return intl.formatMessage({ id: 'enum.serviceType.arrival' })
      case ServiceTypeV1.Departure:
        return intl.formatMessage({ id: 'enum.serviceType.departure' })
      default:
        return ''
    }
  }, [ intl ])

  return [ data, converter ]
}

export function useOrderPaymentTypesV1(): [ PairData<OrderPaymentTypeV1>, PairConverter<OrderPaymentTypeV1> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<OrderPaymentTypeV1>>([
    new Pair<OrderPaymentTypeV1>(OrderPaymentTypeV1.Card, intl.formatMessage({ id: 'enum.orderPaymentType.card' })),
    new Pair<OrderPaymentTypeV1>(OrderPaymentTypeV1.Cash, intl.formatMessage({ id: 'enum.orderPaymentType.cash' })),
  ])

  const converter = useCallback((value: OrderPaymentTypeV1): string => {
    const item = data.filter(pair => pair.code === value)[0]
    return item ? item.name : ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrderSearchV1(): [ Chunk<OrderedFlightRecordV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<OrderedFlightRecordV1>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (pattern) {
        setData(await OrderAccessV1.fetchFlights(pattern, undefined, skip, take))
      } else {
        setData(new Chunk())
      }
    } catch (e) {
      console.error(e)
    }
  }, [ setData ])

  return [ data, fetch ]
}
