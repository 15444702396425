import { PassengerKlassV1 } from "./Passenger";
import { LoyaltyProgramV1 } from "./LoyaltyProgram";
import { AccessProgram } from "./AccessProgram";

export enum OrganizationFreeAccessTypeV1 {
  BusinessPassenger = "businessPassenger",
  LoyaltyProgram = "loyaltyProgram",
  AccessProgram = "accessProgram",
}

export class OrganizationBusinessPassengerSettingsV1 {
  public klasses: PassengerKlassV1[];
  public klassRequired: boolean;
  public ticketRequired: boolean;

  public constructor() {
    this.klasses = [];
    this.klassRequired = true;
    this.ticketRequired = true;
  }
}

export class OrganizationLoyaltyProgramSettingsV1 {
  public programs: LoyaltyProgramV1[];
  public airlineRequired: boolean;
  public programRequired: boolean;
  public levelRequired: boolean;
  public numberRequired: boolean;


  public constructor() {
    this.programs = [];
    this.airlineRequired = true;
    this.programRequired = true;
    this.levelRequired = true;
    this.numberRequired = true;
  }
}

export class OrganizationAccessProgramSettingsV1 {
  public programs: AccessProgram[];
  public programRequired: boolean;
  public numberRequired: boolean;

  public constructor() {
    this.programs = [];
    this.programRequired = true;
    this.numberRequired = true;
  }
}

export class OrganizationFreeAccessSettingsV1 {
  public types: OrganizationFreeAccessTypeV1[];
  public businessPassengerSettings: OrganizationBusinessPassengerSettingsV1;
  public loyaltyProgramSettings: OrganizationLoyaltyProgramSettingsV1;
  public accessProgramSettings: OrganizationAccessProgramSettingsV1;

  public constructor() {
    this.types = [];
    this.businessPassengerSettings = new OrganizationBusinessPassengerSettingsV1();
    this.loyaltyProgramSettings = new OrganizationLoyaltyProgramSettingsV1();
    this.accessProgramSettings = new OrganizationAccessProgramSettingsV1();
  }
}
