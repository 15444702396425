import * as KendoData from "@progress/kendo-data-query";
import * as KendoGrid from "@progress/kendo-react-grid";
import * as Ramda from "ramda";
import React, { PureComponent, ReactNode } from "react";
import { Chunk, isChunk } from "../data";
import { Button } from "./Button";
import { Div } from "./Div";
import { TextBox } from "./inputs";
import { getIntlMessage, ID, Intl, IntlMessage } from "./Intl";
import { LoadingIndicator } from "./LoadingIndicator";

interface TableProps<TValue> {
  label?: IntlMessage;
  pageSize?: number;
  loaded: boolean;
  data: Chunk<TValue> | TValue[];
  scheme: TableScheme,
  canSearch?: boolean;
  canSort?: boolean;
  onPatternChange?: (value: string) => void;
  onCreate?: () => void;
  onSelect?: (value: TValue) => void;
  onFetch?: (pattern?: string, orderBy?: string, skip?: number, take?: number) => void;
}

interface TableProps<TValue> {
  children?: null;
}

interface TableState {
  pattern: string;
  orderBy: KendoData.SortDescriptor[];
  originalData: any[];
  processedData: any[];
}

export function asDate(value: any): any {
  return new Date(value);
}

export interface TableField {
  path: string[],
  title: IntlMessage;
  width?: string;
  format?: string;
  converter?: (value: any, item: any) => any;
  sortable?: boolean;
}

export type TableScheme = TableField[];

export class Table<TValue> extends PureComponent<TableProps<TValue>, TableState> {
  public constructor(props: TableProps<TValue>) {
    super(props);

    this.state = {
      pattern: "",
      orderBy: [],
      originalData: [],
      processedData: [],
    };
  }

  public componentDidMount(): void {
    const data = isChunk(this.props.data) ? this.props.data.data : this.props.data;
    this.setState({
      originalData: data,
      processedData: this.process(data),
    });
  }

  public componentDidUpdate(prevProps: Readonly<TableProps<TValue>>, prevState: Readonly<TableState>, snapshot?: any): void {
    if (prevProps.data !== this.props.data) {
      const data = isChunk(this.props.data) ? this.props.data.data : this.props.data;
      this.setState({
        originalData: data,
        processedData: this.process(data),
      });
    }
  }

  public render(): ReactNode {
    const { label, pageSize, loaded, data, scheme, canSearch, canSort, onPatternChange, onCreate, onSelect, onFetch } = this.props;
    const { pattern, orderBy, processedData } = this.state;

    const handlePageChange = onFetch && pageSize
      ? async (event: KendoGrid.GridPageChangeEvent): Promise<void> => {
        await onFetch(pattern, this.orderByToString(orderBy), event.page.skip, event.page.take);
      }
      : undefined;

    const handleSortChange = onFetch && (canSort === undefined || canSort)
      ? async (event: KendoGrid.GridSortChangeEvent): Promise<void> => {
        this.setState({ orderBy: event.sort });
        await onFetch(pattern, this.orderByToString(event.sort));
      }
      : undefined;

    const handleSearch = onFetch && (canSearch === undefined || canSearch)
      ? async (value: string | null): Promise<void> => {
        this.setState({ pattern: value || "" });
        if (onPatternChange) {
          onPatternChange(value || "")
        }
        await onFetch(value ? value : undefined, this.orderByToString(orderBy));
      }
      : undefined;

    const handleSelect = onSelect
      ? (event: KendoGrid.GridRowClickEvent) => onSelect(this.state.originalData[event.dataItem["__index"]])
      : undefined;

    if (!loaded) {
      return (
        <LoadingIndicator />
      );
    }

    return (
      <Intl render={intl =>
        <Div className="g-table">
          {label &&
          <Div layout="grid 12" className="g-table-header">
            <Div className="g-table-label">
              <span>{getIntlMessage(intl, label)}</span>
            </Div>
          </Div>
          }

          {(handleSearch || onCreate) &&
          <Div layout="flex" className="g-table-header">
            <Div layout="fill" className="g-table-search">
              {handleSearch &&
              <TextBox fill={true}
                       placeholder={ID("table.search")}
                       name="pattern"
                       value={pattern}
                       onChange={handleSearch} />
              }
            </Div>
            <Div layout="fit" className="g-table-actions">
              {onCreate &&
              <Button primary={true}
                      text={ID("action.create")}
                      type="button"
                      onClick={onCreate} />
              }
            </Div>
          </Div>
          }

          <KendoGrid.Grid data={processedData}
                          pageable={!!handlePageChange}
                          pageSize={pageSize ? pageSize : isChunk(data) ? data.total : data.length}
                          skip={isChunk(data) ? data.skip : 0}
                          total={isChunk(data) ? data.total : data.length}
                          sortable={!!handleSortChange}
                          sort={orderBy}
                          onPageChange={handlePageChange}
                          onSortChange={handleSortChange}
                          onRowClick={handleSelect}>
            {scheme.map((item, index) => (
              <KendoGrid.GridColumn key={index}
                                    field={item.path.join(".")}
                                    title={getIntlMessage(intl, item.title)}
                                    width={item.width}
                                    format={item.format} sortable={item.sortable} />
            ))}
          </KendoGrid.Grid>
        </Div>
      } />
    );
  }

  private readonly process = (data: any[]): any[] => {
    const processedData = [];
    let dataIndex = 0;
    for (const item of data) {
      let processedItem: any = Ramda.clone(item);
      processedItem["__index"] = dataIndex;
      dataIndex++;
      for (const field of this.props.scheme) {
        const value = field.converter ? field.converter(Ramda.path(field.path, item), item) : Ramda.path(field.path, item);
        processedItem = Ramda.assocPath(field.path, value, processedItem);
      }
      processedData.push(processedItem);
    }

    return processedData;
  };

  private readonly orderByToString = (sort: KendoData.SortDescriptor[]): string =>
    sort.map(value => `${value.field}:${value.dir}`).join("|");
}
