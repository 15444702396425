import React, { ReactElement } from "react";

export function Dash(): ReactElement {
  return (
    <span className="k-textbox-container" style={{ width: "16px" }}>
      <span className="k-textbox" style={{ padding: "4px 5px", border: "none" }}>
        <span>–</span>
      </span>
    </span>
  );
}
