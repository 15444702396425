export enum LockAction {
  info = "info",
  lock = "lock",
  free = "free",
}

export class Lock {
  public uri: string;
  public lockedBy: string | null;
  public lockedAt: string | null;
  public belongsTo: string | null;
  public canEdit: boolean;
  public canFree: boolean;

  public constructor(canEdit: boolean = true, canFree: boolean = false) {
    this.uri = "";
    this.lockedBy = null;
    this.lockedAt = null;
    this.belongsTo = null;
    this.canEdit = canEdit;
    this.canFree = canFree;
  }
}
