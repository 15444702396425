import { IReference } from "./IReference";
import { TerminalBaseV1 } from "./Terminal";

export enum TransferPointTypeV1 {
  Common = "common",
  Lounge = "lounge",
}

export class TransferReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class TransferBaseV1 extends TransferReferenceV1 {
  public departurePoint: TerminalBaseV1;
  public departurePointType: TransferPointTypeV1;
  public destinationPoint: TerminalBaseV1;
  public destinationPointType: TransferPointTypeV1;

  public constructor() {
    super();
    this.departurePoint = new TerminalBaseV1();
    this.departurePointType = TransferPointTypeV1.Common;
    this.destinationPoint = new TerminalBaseV1();
    this.destinationPointType = TransferPointTypeV1.Common;
  }
}
