import React from "react";
import { ReactComponent as NavigationAuthSignIn } from "./icons/navigation-auth-signin.svg";
import { ReactComponent as NavigationAuthSignOut } from "./icons/navigation-auth-signout.svg";
import { ReactComponent as NavigationItemHome } from "./icons/navigation-item-home.svg";
import { ReactComponent as NavigationItemUsers } from "./icons/navigation-item-users.svg";
import { ReactComponent as NavigationItemOrganizations } from "./icons/navigation-item-organizations.svg";
import { ReactComponent as NavigationItemContracts } from "./icons/navigation-item-contracts.svg";
import { ReactComponent as NavigationItemCorporate } from "./icons/navigation-item-corporate.svg";
import { ReactComponent as NavigationItemResources } from "./icons/navigation-item-resources.svg";
import { ReactComponent as NavigationItemOrders } from "./icons/navigation-item-orders.svg";
import { ReactComponent as NavigationItemControl } from "./icons/navigation-item-control.svg";
import { ReactComponent as NavigationItemSearch } from "./icons/navigation-item-search.svg";
import { ReactComponent as NavigationItemSettings } from "./icons/navigation-item-settings.svg";
import { ReactComponent as NavigationItemQueues } from "./icons/navigation-item-queues.svg";
import { ReactComponent as NavigationItemRates } from "./icons/navigation-item-rates.svg";
import { ReactComponent as NavigationItemReports } from "./icons/navigation-item-reports.svg";
import { ReactComponent as NavigationItemHistory } from "./icons/navigation-item-history.svg";
import { ReactComponent as NavigationDoubleChevronLeft } from "./icons/navigation-double-chevron-left.svg";
import { ReactComponent as NavigationDoubleChevronRight } from "./icons/navigation-double-chevron-right.svg";
import { ReactComponent as NavigationPathSeparator } from "./icons/navigation-path-separator.svg";
import { ReactComponent as PassengerAdult } from "./icons/passenger-adult.svg";
import { ReactComponent as PassengerChild } from "./icons/passenger-child.svg";
import { ReactComponent as PassengerInfant } from "./icons/passenger-infant.svg";
import { ReactComponent as PassengerUnknown } from "./icons/passenger-unknown.svg";
import { ReactComponent as DescriptionLocation } from "./icons/description-location.svg";
import { ReactComponent as DescriptionSchedule } from "./icons/description-schedule.svg";
import { ReactComponent as DescriptionDirection } from "./icons/description-direction.svg";
import { ReactComponent as DescriptionPhone } from "./icons/description-phone.svg";
import { ReactComponent as DescriptionEmail } from "./icons/description-email.svg";
import { ReactComponent as ResourceLoungeVip } from "./icons/resource-lounge-vip.svg";
import { ReactComponent as ResourceLoungeBusiness } from "./icons/resource-lounge-business.svg";
import { ReactComponent as ResourceLoungeComfort } from "./icons/resource-lounge-comfort.svg";
import { ReactComponent as ResourceLoungeDelegation } from "./icons/resource-lounge-delegation.svg";
import { ReactComponent as ResourceServiceFastTrack } from "./icons/resource-service-fast-track.svg";
import { ReactComponent as ResourceServiceMeetAndAssist } from "./icons/resource-service-meet-and-assist.svg";
import { ReactComponent as ResourceServiceMeetAndGreet } from "./icons/resource-service-meet-and-greet.svg";
import { ReactComponent as ResourceServiceParking } from "./icons/resource-service-parking.svg";
import { ReactComponent as CommonNoImage } from "./icons/common-no-image.svg";

export const Icons = {
  Navigation: {
    Auth: {
      SignIn: <NavigationAuthSignIn />,
      SignOut: <NavigationAuthSignOut />,
    },
    Items: {
      Home: <NavigationItemHome />,
      Users: <NavigationItemUsers />,
      Organizations: <NavigationItemOrganizations />,
      Contracts: <NavigationItemContracts />,
      Membership: <NavigationItemCorporate />,
      Resources: <NavigationItemResources />,
      Orders: <NavigationItemOrders />,
      Control: <NavigationItemControl />,
      Search: <NavigationItemSearch />,
      Settings: <NavigationItemSettings />,
      Queues: <NavigationItemQueues />,
      Rates: <NavigationItemRates />,
      Reports: <NavigationItemReports />,
      History: <NavigationItemHistory />,
    },
    Chevrons: {
      Left: <NavigationDoubleChevronLeft />,
      Right: <NavigationDoubleChevronRight />,
    },
    Path: {
      Separator: <NavigationPathSeparator />,
    },
  },
  Common: {
    NoImage: <CommonNoImage />,
  },
  Passengers: {
    Adult: <PassengerAdult />,
    Child: <PassengerChild />,
    Infant: <PassengerInfant />,
    Unknown: <PassengerUnknown />,
  },
  Description: {
    Location: <DescriptionLocation />,
    Schedule: <DescriptionSchedule />,
    Direction: <DescriptionDirection />,
    Phone: <DescriptionPhone />,
    Email: <DescriptionEmail />,
  },
  Resources: {
    Lounges: {
      Vip: <ResourceLoungeVip />,
      Business: <ResourceLoungeBusiness />,
      Comfort: <ResourceLoungeComfort />,
      Delegation: <ResourceLoungeDelegation />,
    },
    Services: {
      FastTrack: <ResourceServiceFastTrack />,
      MeetAndAssist: <ResourceServiceMeetAndAssist />,
      MeetAndGreet: <ResourceServiceMeetAndGreet />,
      Parking: <ResourceServiceParking />,
    },
  },
  Features: {
    AirCondition: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <rect x="52" y="20.5" width="2" height="64.2" />
          <polygon points="53 35.3 42.7 25.1 44.1 23.7 53 32.6 61.9 23.7 63.3 25.1" />
          <polygon points="61.9 81.5 53 72.6 44.1 81.5 42.7 80.1 53 69.9 63.3 80.1" />
          <rect transform="matrix(.8753 -.4836 .4836 .8753 -18.824 32.188)" x="20.9" y="51.6" width="64.2" height="2" />
          <polygon points="82.1 48.3 68.1 44.2 72.1 30.3 74 30.8 70.5 42.9 82.6 46.4" />
          <polygon points="33.9 74.9 32 74.4 35.5 62.3 23.4 58.8 23.9 56.9 37.9 60.9" />
          <rect transform="matrix(.4836 -.8753 .8753 .4836 -18.666 73.549)" x="52" y="20.5" width="2" height="64.2" />
          <polygon points="23.9 48.3 23.4 46.4 35.5 42.9 32 30.8 33.9 30.3 37.9 44.2" />
          <polygon points="72.1 74.9 68.1 60.9 82.1 56.9 82.6 58.8 70.5 62.3 74 74.4" />
        </g>
      </switch>
    </svg>,
    AlcoholDrink: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <polygon points="52 50.3 22.5 20.7 23.9 19.4 52 47.5 80.1 19.4 81.5 20.7" />
          <rect x="29.2" y="25.1" width="45.6" height="2" />
          <rect x="36.2" y="82.9" width="31.6" height="2" />
          <rect x="51" y="48.9" width="2" height="35" />
          <rect transform="matrix(.5841 -.8117 .8117 .5841 -4.6079 58.15)" x="48.7" y="32.6" width="11.4" height="2" />
          <rect transform="matrix(.5841 -.8117 .8117 .5841 14.358 61.028)" x="62.3" y="15.5" width="8.9" height="2" />
          <path
            d="m60.9 30.9c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4c1.4 0 2.7 0.4 3.8 1.2 1.7 1.2 2.7 3.2 2.7 5.2 0 3.6-2.9 6.4-6.5 6.4zm0-10.9c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5c0-1.4-0.7-2.8-1.8-3.6-0.8-0.6-1.7-0.9-2.7-0.9z" />
        </g>
      </switch>
    </svg>,
    BizZone: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m34.8 84.6h-13.1c-1.8 0-3.2-1.5-3.2-3.2v-7.2c0-1.8 1.5-3.2 3.2-3.2h13.1c1.8 0 3.2 1.5 3.2 3.2v7.2c0 1.7-1.5 3.2-3.2 3.2zm-13.1-11.8c-0.7 0-1.3 0.6-1.3 1.3v7.2c0 0.7 0.6 1.3 1.3 1.3h13.1c0.7 0 1.3-0.6 1.3-1.3v-7.2c0-0.7-0.6-1.3-1.3-1.3h-13.1z" />
          <path d="m32.2 72.8h-7.8v-5.1h7.8v5.1zm-5.9-1.9h3.9v-1.2h-3.9v1.2z" />
          <path
            d="m51.2 25.4c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11zm0-20c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1 9.1-4.1 9.1-9.1-4.1-9.1-9.1-9.1z" />
          <polygon points="58.4 95.8 44.1 95.8 40.2 50.3 42.2 50.1 45.9 93.8 56.6 93.8 60.3 50.1 62.2 50.3" />
          <path
            d="m71.6 65.4l-4.4-22.3c-0.9-4.4-4.7-7.6-9.2-7.6h-0.4l-6.4 11.9-6.4-11.9h-0.4c-4.5 0-8.4 3.2-9.2 7.6l-4.4 22.3-1.9-0.4 4.4-22.3c1-5.3 5.7-9.2 11.1-9.2h1.6l5.2 9.7 5.2-9.7h1.6c5.4 0 10.1 3.9 11.1 9.2l4.5 22.3-2 0.4z" />
        </g>
      </switch>
    </svg>,
    Cafe: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m54.1 75.6h-6.2c-10.1 0-18.3-8.2-18.3-18.3v-24.5h42.8v24.5c0 10.1-8.2 18.3-18.3 18.3zm-22.6-40.8v22.6c0 9 7.3 16.4 16.4 16.4h6.2c9 0 16.4-7.3 16.4-16.4v-22.6h-39z" />
          <rect x="15.8" y="81.1" width="70.4" height="2" />
          <path
            d="m76.1 57.1h-4.6v-2h4.6c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1h-4.6v-2h4.6c3.9 0 7.1 3.2 7.1 7.1s-3.2 7.1-7.1 7.1z" />
          <path
            d="m47.5 21.3l-0.4-1.9c0.7-0.1 1.3-0.6 1.7-1.2s0.5-1.3 0.4-2c-0.2-0.7-0.6-1.3-1.2-1.7s-1.3-0.5-2-0.4l-0.4-1.9c1.2-0.3 2.4 0 3.5 0.6 1 0.7 1.7 1.7 2 2.9s0 2.4-0.6 3.5c-0.7 1.1-1.8 1.8-3 2.1z" />
          <path
            d="m48.1 28.5c-2.1 0-4-1.5-4.5-3.6-0.5-2.5 1.1-4.9 3.5-5.5l0.4 1.9c-1.4 0.3-2.4 1.7-2 3.2 0.3 1.4 1.7 2.4 3.2 2l0.4 1.9c-0.4 0.1-0.7 0.1-1 0.1z" />
          <path
            d="m54.9 21.3l-0.4-1.9c0.7-0.1 1.3-0.6 1.7-1.2s0.5-1.3 0.4-2-0.6-1.3-1.2-1.7-1.3-0.5-2-0.4l-0.4-1.9c1.2-0.3 2.4 0 3.5 0.6 1 0.7 1.7 1.7 2 2.9s0 2.4-0.6 3.5c-0.8 1.1-1.8 1.8-3 2.1z" />
          <path
            d="m55.4 28.5c-2.1 0-4-1.5-4.5-3.6-0.5-2.5 1.1-4.9 3.5-5.5l0.4 1.9c-1.4 0.3-2.4 1.7-2 3.2 0.3 1.4 1.7 2.3 3.2 2l0.4 1.9c-0.3 0.1-0.6 0.1-1 0.1z" />
        </g>
      </switch>
    </svg>,
    ElectricalSocket: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m58.2 74.2h-22.4l-8.4-7.6c-4.9-4.4-7.7-10.7-7.7-17.3v-4.5h54.6v4.5c0 6.6-2.8 12.9-7.7 17.3l-8.4 7.6zm-21.7-1.9h20.9l7.9-7.1c4.5-4 7-9.8 7-15.8v-2.6h-50.6v2.6c0 6 2.6 11.8 7 15.8l7.8 7.1z" />
          <path d="M58.8,87.4H35.2V72.3h23.7V87.4z M37.1,85.4h19.7V74.2H37.1V85.4z" />
          <path
            d="m36.1 39.4c-2.3 0-4.3-1.9-4.3-4.3v-13c0-2.3 1.9-4.3 4.3-4.3 2.3 0 4.3 1.9 4.3 4.3v13.1c0 2.3-1.9 4.2-4.3 4.2zm0-19.6c-1.3 0-2.3 1-2.3 2.3v13.1c0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-13.1c0-1.3-1-2.3-2.3-2.3z" />
          <path
            d="m57.9 39.4c-2.3 0-4.3-1.9-4.3-4.3v-13c0-2.3 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3v13.1c-0.1 2.3-2 4.2-4.3 4.2zm0-19.6c-1.3 0-2.3 1-2.3 2.3v13.1c0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-13.1c0-1.3-1.1-2.3-2.3-2.3z" />
        </g>
      </switch>
    </svg>,
    Internet: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="M50,82.9c-16.7,0-30.3-13.6-30.3-30.3S33.3,22.3,50,22.3s30.3,13.6,30.3,30.3S66.7,82.9,50,82.9z M50,24.2     c-15.6,0-28.4,12.7-28.4,28.4S34.4,81,50,81s28.4-12.7,28.4-28.4S65.6,24.2,50,24.2z" />
          <path
            d="m50 43.4c-8.5 0-16.7-3.6-22.4-9.9l1.4-1.3c5.4 5.9 13 9.2 20.9 9.2s15.6-3.4 20.9-9.2l1.4 1.3c-5.5 6.3-13.7 9.9-22.2 9.9z" />
          <path
            d="m29.1 73l-1.4-1.3c5.7-6.3 13.9-9.9 22.4-9.9s16.7 3.6 22.4 9.9l-1.6 1.3c-5.4-5.9-13-9.2-20.9-9.2s-15.6 3.4-20.9 9.2z" />
          <rect x="20.7" y="51.6" width="58.7" height="2" />
          <rect x="49" y="23.3" width="2" height="58.7" />
        </g>
      </switch>
    </svg>,
    LimitedPeople: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m47.5 29.6c-6 0-11-4.9-11-11 0-6 4.9-11 11-11s11 4.9 11 11-4.9 11-11 11zm0-19.9c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
          <path
            d="m44.2 91.5h-0.3c-7.3-0.1-14.1-3-19.2-8.2s-7.8-12.1-7.8-19.4c0.1-11 6.8-20.8 16.9-24.9l0.7 1.8c-9.4 3.9-15.6 12.9-15.7 23.1-0.1 6.8 2.5 13.1 7.2 18 4.7 4.8 11 7.5 17.8 7.6 9 0.1 17.3-4.6 22-12.2l1.7 1c-4.8 8.1-13.8 13.2-23.3 13.2z" />
          <path
            d="m80.2 88.1l-11.3-18h-18.4c-6 0-10.8-4.9-10.8-10.8v-26.9h6c3.7 0 6.8 2.4 7.9 5.9l3.4 11.4h16v2h-17.4l-3.9-12.8c-0.8-2.7-3.2-4.5-6-4.5h-4.1v24.9c0 4.9 4 8.9 8.9 8.9h19.4l11.9 18.9-1.6 1z" />
          <polygon points="83.4 79.1 70.7 60.8 57.5 60.8 57.5 58.9 71.7 58.9 85 78" />
        </g>
      </switch>
    </svg>,
    LuggagePacking: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m68.1 83.9h-36.2c-3.2 0-5.9-2.6-5.9-5.9v-47.5c0-3.2 2.6-5.9 5.9-5.9h36.2c3.2 0 5.9 2.6 5.9 5.9v47.5c0 3.2-2.7 5.9-5.9 5.9zm-36.2-57.3c-2.2 0-3.9 1.8-3.9 3.9v47.5c0 2.2 1.8 3.9 3.9 3.9h36.2c2.2 0 3.9-1.8 3.9-3.9v-47.5c0-2.2-1.8-3.9-3.9-3.9h-36.2z" />
          <path
            d="m62.3 26.6h-24.6v-7.1c0-1.6 1.3-2.8 2.8-2.8h18.9c1.6 0 2.8 1.3 2.8 2.8v7.1zm-22.6-2h20.7v-5.1c0-0.5-0.4-0.9-0.9-0.9h-19c-0.5 0-0.9 0.4-0.9 0.9v5.1z" />
          <path d="m47.7 55.4l-2.7-8.5 20.9-6.8 2.8 8.5-21 6.8zm-0.3-7.3l1.6 4.8 17.1-5.6-1.6-4.8-17.1 5.6z" />
          <path
            d="m40.2 47.3c-3.3 0-5.9-2.7-5.9-5.9s2.7-5.9 5.9-5.9 5.9 2.7 5.9 5.9-2.6 5.9-5.9 5.9zm0-9.9c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z" />
          <path d="m43.5 69.1l-10.4-5.3 5.3-10.4 10.4 5.3-5.3 10.4zm-7.8-6.2l7 3.5 3.5-7-7-3.5-3.5 7z" />
          <path
            d="m36.4 88.5h-1c-1.8 0-3.2-1.4-3.2-3.2v-3.4h7.5v3.4c-0.1 1.8-1.5 3.2-3.3 3.2zm-2.3-4.6v1.4c0 0.7 0.6 1.3 1.3 1.3h1c0.7 0 1.3-0.6 1.3-1.3v-1.4h-3.6z" />
          <path
            d="m64.6 88.5h-1c-1.8 0-3.2-1.4-3.2-3.2v-3.4h7.5v3.4c-0.1 1.8-1.5 3.2-3.3 3.2zm-2.3-4.6v1.4c0 0.7 0.6 1.3 1.3 1.3h1c0.7 0 1.3-0.6 1.3-1.3v-1.4h-3.6z" />
          <rect transform="matrix(.4841 -.875 .875 .4841 -19.255 85.003)" x="61.5" y="46.8" width="2" height="24.1" />
          <rect transform="matrix(.4841 -.875 .875 .4841 -19.859 62.948)" x="42.5" y="44.1" width="2" height="8.4" />
          <rect transform="matrix(.4841 -.875 .875 .4841 -20.238 49.15)" x="30.6" y="36.5" width="2" height="10.5" />
          <rect transform="matrix(.4841 -.875 .875 .4841 -34.581 88.877)" x="57.1" y="57.7" width="2" height="32.2" />
          <rect transform="matrix(.4841 -.875 .875 .4841 -35.436 57.707)" x="30.2" y="54.1" width="2" height="9.7" />
          <rect transform="matrix(.9108 -.4129 .4129 .9108 -22.528 17.694)" x="26.7" y="60" width="5.9" height="2" />
          <rect transform="matrix(.9108 -.4129 .4129 .9108 -18.436 23.212)" x="40.7" y="53.3" width="7.5" height="2" />
          <rect transform="matrix(.9108 -.4129 .4129 .9108 -11.497 32.568)" x="65.9" y="41.9" width="7.5" height="2" />
        </g>
      </switch>
    </svg>,
    Meal: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m69.3 87.7h-8.6v-27.3c-18.3-18.4 5-43.3 6-44.3 0.5-0.6 1.1-0.6 1.4-0.5 0.6 0.1 1 0.5 1.2 0.9l0.1 0.4v70.8zm-6.6-2h4.7v-67.5c-3.9 4.5-20.9 25.6-5 41.1l0.3 0.3v26.1z" />
          <path
            d="m40.3 87.7h-8.6v-36.1c-4.2-2.8-7-9.7-7-17.3h2c0 7.2 2.6 13.6 6.5 15.9l0.5 0.3v35.2h4.7v-35.2l0.5-0.3c3.9-2.3 6.5-8.7 6.5-15.9h2c0 7.6-2.8 14.4-7 17.3l-0.1 36.1z" />
          <rect x="24.7" y="16.7" width="2" height="17.7" />
          <rect x="45.3" y="16.7" width="2" height="17.7" />
          <rect x="38.3" y="16.7" width="2" height="17.7" />
          <rect x="31.7" y="16.7" width="2" height="17.7" />
        </g>
      </switch>
    </svg>,
    Phone: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m67.8 85.5h-35.6c-3.3 0-5.9-2.6-5.9-5.9v-60c0-3.3 2.6-5.9 5.9-5.9h35.5c3.3 0 5.9 2.6 5.9 5.9v60c0 3.3-2.6 5.9-5.8 5.9zm-35.6-69.9c-2.2 0-3.9 1.8-3.9 3.9v60c0 2.2 1.8 3.9 3.9 3.9h35.5c2.2 0 3.9-1.8 3.9-3.9v-60c0-2.2-1.8-3.9-3.9-3.9h-35.5z" />
          <path d="m68.5 72.3h-37v-53.3h36.9v53.3zm-35-1.9h33v-49.5h-33v49.5z" />
          <path
            d="m50 81.6c-2.1 0-3.7-1.7-3.7-3.7 0-2.1 1.7-3.7 3.7-3.7 2.1 0 3.7 1.7 3.7 3.7s-1.6 3.7-3.7 3.7zm0-5.5c-1 0-1.8 0.8-1.8 1.8s0.8 1.8 1.8 1.8 1.8-0.8 1.8-1.8-0.8-1.8-1.8-1.8z" />
        </g>
      </switch>
    </svg>,
    PlayRoom: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m21.4 47.6c-7.1-1.3-12.2-7.4-12.2-14.6 0-8.2 6.7-14.9 14.9-14.9 5.7 0 11 3.4 13.5 8.6l-1.8 0.8c-2.2-4.5-6.8-7.5-11.8-7.5-7.1 0-12.9 5.8-12.9 12.9 0 6.2 4.4 11.6 10.6 12.7l-0.3 2z" />
          <path
            d="m81.1 47.5l-0.4-1.9c5.9-1.3 10.2-6.6 10.2-12.6 0-7.2-5.8-13-12.9-13-5 0-9.6 2.9-11.7 7.4l-1.8-0.8c2.4-5.2 7.7-8.6 13.5-8.6 8.2 0 14.9 6.7 14.9 14.9-0.1 7-5 13.1-11.8 14.6z" />
          <path
            d="m63.2 87.1h-24.4c-10.2 0-18.5-8.3-18.5-18.5v-24.5c0-10.2 8.3-18.5 18.5-18.5h24.5c10.2 0 18.5 8.3 18.5 18.5v24.5c-0.1 10.2-8.4 18.5-18.6 18.5zm-24.4-59.5c-9.1 0-16.5 7.4-16.5 16.5v24.5c0 9.1 7.4 16.5 16.5 16.5h24.5c9.1 0 16.5-7.4 16.5-16.5v-24.5c0-9.1-7.4-16.5-16.5-16.5h-24.5z" />
          <path
            d="M51,81c-8.7,0-15.8-7.1-15.8-15.8S42.3,49.5,51,49.5s15.8,7.1,15.8,15.8S59.7,81,51,81z M51,51.5     c-7.6,0-13.8,6.2-13.8,13.8S43.4,79.1,51,79.1s13.8-6.2,13.8-13.8S58.6,51.5,51,51.5z" />
          <path
            d="m51 65.4c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3c0 3-2.4 5.3-5.3 5.3zm0-8.5c-1.8 0-3.3 1.5-3.3 3.3s1.5 3.3 3.3 3.3 3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3z" />
        </g>
      </switch>
    </svg>,
    Press: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path d="M84.8,83.7H19.2V11.5h65.7V83.7z M21.1,81.8h61.8V13.4H21.1V81.8z" />
          <rect x="27.2" y="60.7" width="24.8" height="2" />
          <rect x="27.2" y="69.8" width="24.8" height="2" />
          <rect x="52" y="23.4" width="24.8" height="2" />
          <rect x="52" y="32.6" width="24.8" height="2" />
          <rect x="27.2" y="42" width="49.6" height="2" />
          <rect x="27.2" y="51.1" width="49.6" height="2" />
          <path d="M76.8,74.8H59.6V57.6h17.2V74.8z M61.5,72.9h13.3V59.6H61.5V72.9z" />
          <path d="M44.5,37.6H27.3V20.4h17.2V37.6z M29.2,35.6h13.3V22.3H29.2V35.6z" />
        </g>
      </switch>
    </svg>,
    Registration: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m50.1 40.6c-6.1 0-11-4.9-11-11 0-1.9 0.5-3.7 1.4-5.3h-4.5v-2h22.4l0.5 0.6c1.5 1.9 2.3 4.2 2.3 6.7-0.1 6-5 11-11.1 11zm-7.4-16.3c-1.1 1.5-1.7 3.4-1.7 5.3 0 5 4.1 9.1 9.1 9.1s9.1-4.1 9.1-9.1c0-1.9-0.6-3.7-1.7-5.3h-14.8z" />
          <path
            d="m59.3 20.6h-20.2l-5.2-6.4h21.1c2.4 0 4.3 1.9 4.3 4.3v2.1zm-19.3-2h17.3v-0.2c0-1.3-1.1-2.3-2.3-2.3h-17l2 2.5z" />
          <rect transform="matrix(.4942 -.8693 .8693 .4942 -21.314 72.4)" x="40.7" y="53.5" width="21.7" height="2" />
          <rect transform="matrix(.4961 -.8682 .8682 .4961 -19.417 76.589)" x="46" y="54" width="20.6" height="2" />
          <path className="st0" d="m40 49.2" />
          <path
            d="m68 64.1l-2-10c-0.9-4.4-4.7-7.6-9.2-7.6h-13.6c-4.5 0-8.4 3.2-9.2 7.6l-2 10-1.9-0.4 2-10c1-5.3 5.7-9.2 11.1-9.2h13.7c5.4 0 10.1 3.9 11.1 9.2l2 10-2 0.4z" />
          <rect x="14.8" y="63.2" width="70.4" height="2" />
          <rect x="17" y="83.1" width="66" height="2" />
          <rect x="31" y="73.3" width="37.9" height="2" />
          <rect x="23.3" y="64.4" width="2" height="19.7" />
          <rect x="74.7" y="64.4" width="2" height="19.7" />
        </g>
      </switch>
    </svg>,
    Shower: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path d="m81.6 27.3h-71.2v-12.9h71.1v12.9zm-69.2-1.9h67.2v-9h-67.2v9z" />
          <rect x="24.8" y="62.7" width="2" height="11" />
          <rect x="24.8" y="40.8" width="2" height="11" />
          <rect x="30.5" y="30.2" width="2" height="11" />
          <rect x="30.5" y="52.1" width="2" height="11" />
          <rect x="36.3" y="62.7" width="2" height="11" />
          <rect x="36.3" y="40.8" width="2" height="11" />
          <rect x="42.1" y="30.2" width="2" height="11" />
          <rect x="42.1" y="52.1" width="2" height="11" />
          <rect x="47.9" y="62.7" width="2" height="11" />
          <rect x="47.9" y="40.8" width="2" height="11" />
          <rect x="53.7" y="30.2" width="2" height="11" />
          <rect x="53.7" y="52.1" width="2" height="11" />
          <rect x="59.5" y="62.7" width="2" height="11" />
          <rect x="59.5" y="40.8" width="2" height="11" />
          <rect x="65.3" y="30.2" width="2" height="11" />
          <rect x="65.3" y="52.1" width="2" height="11" />
          <rect x="30.5" y="73.7" width="2" height="11" />
          <rect x="42.1" y="73.7" width="2" height="11" />
          <rect x="53.7" y="73.7" width="2" height="11" />
          <rect x="65.3" y="73.7" width="2" height="11" />
        </g>
      </switch>
    </svg>,
    Transfer: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <rect x="34.6" y="66.1" width="30.5" height="2" />
          <path
            d="m87.2 68h-9.7v-2h7.7v-25.8c0-4.7-3.8-8.5-8.5-8.5h-51.5c-4.7 0-8.5 3.8-8.5 8.5v25.9h5v2h-7v-27.9c0-5.7 4.7-10.4 10.4-10.4h51.5c5.7 0 10.4 4.7 10.4 10.4v27.8z" />
          <path d="m34.8 51.6h-13.4v-15h13.4v15zm-11.5-2h9.5v-11.1h-9.5v11.1z" />
          <path d="m46.3 51.6h-13.4v-15h13.4v15zm-11.5-2h9.5v-11.1h-9.5v11.1z" />
          <path d="m57.9 51.6h-13.4v-15h13.4v15zm-11.5-2h9.6v-11.1h-9.5v11.1z" />
          <path d="m69.4 51.6h-13.4v-15h13.4v15zm-11.5-2h9.5v-11.1h-9.5v11.1z" />
          <polygon
            points="87.2 55.1 73.7 55.1 73.7 36.5 85.6 36.5 85.6 38.5 75.7 38.5 75.7 53.2 85.2 53.2 85.2 40.2 87.2 40.2" />
          <path
            d="m28.5 73.4c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zm0-12.4c-2.9 0-5.2 2.4-5.2 5.2 0 2.9 2.4 5.2 5.2 5.2s5.2-2.4 5.2-5.2-2.3-5.2-5.2-5.2z" />
          <path
            d="m71.4 73.4c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zm0-12.4c-2.9 0-5.2 2.4-5.2 5.2 0 2.9 2.4 5.2 5.2 5.2s5.2-2.4 5.2-5.2-2.4-5.2-5.2-5.2z" />
        </g>
      </switch>
    </svg>,
    TV: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path d="m88.7 62.8h-71.4v-41h71.4v41zm-69.4-2h67.4v-37.1h-67.4v37.1z" />
          <rect x="26.3" y="69.4" width="53.4" height="2" />
          <rect x="47.6" y="61.8" width="2" height="8.6" />
          <rect x="56.4" y="61.8" width="2" height="8.6" />
          <path d="m67.6 29.4h-3.4v-1.2h8.3v1.2h-3.4v10h-1.5v-10z" />
          <path
            d="m76.8 39.4l-3.7-11.2h1.6l1.7 5.5c0.5 1.5 0.9 2.9 1.2 4.2 0.3-1.3 0.8-2.7 1.3-4.2l1.9-5.5h1.5l-4 11.2h-1.5z" />
        </g>
      </switch>
    </svg>,
    Wardrobe: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m87.2 64h-72.4v-1c0-3.6 2.1-6.8 5.4-8.3l30.8-11.8 30.8 11.9c3.3 1.5 5.4 4.7 5.4 8.3v0.9zm-70.4-1.9h68.3c-0.3-2.4-1.9-4.5-4.1-5.5l-30-11.6-30.1 11.6c-2.2 1-3.7 3.1-4.1 5.5z" />
          <path
            d="m52 44h-2v-6.4h1c1.8 0 3.2-1.4 3.2-3.2s-1.4-3.2-3.2-3.2-3.2 1.4-3.2 3.2h-2c0-2.8 2.3-5.2 5.2-5.2s5.2 2.3 5.2 5.2c0 2.5-1.8 4.6-4.2 5.1v4.5z" />
        </g>
      </switch>
    </svg>,
    Wifi: <svg viewBox="0 0 100 100">
      <switch>
        <g>
          <path
            d="m51 73.9c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1-3.2 7.1-7.1 7.1zm0-12.2c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1z" />
          <path
            d="M36.8,58.3l-1.7-0.9c3.1-6,9.2-9.7,15.9-9.7s12.8,3.7,15.9,9.7l-1.7,0.9C62.4,53,57,49.7,51,49.7S39.6,53,36.8,58.3z" />
          <path
            d="m73 49.1c-4.5-8-12.9-12.9-22-12.9s-17.5 4.9-22 12.9l-1.7-1c4.8-8.6 13.9-13.9 23.7-13.9s18.9 5.3 23.7 13.9l-1.7 1z" />
          <path
            d="m85.2 41.3c-6.9-12.4-20-20.1-34.2-20.1s-27.3 7.7-34.2 20.1l-1.7-1c7.3-13 21-21.1 35.9-21.1s28.6 8.1 35.9 21.1l-1.7 1z" />
        </g>
      </switch>
    </svg>,
  },
};
