import * as KendoData from "@progress/kendo-data-query";
import { classNames } from "@progress/kendo-react-common";
import * as KendoGrid from "@progress/kendo-react-grid";
import React, { ReactElement, useCallback, useState } from "react";
import { Chunk, isChunk } from "../../data";
import { useForceUpdate, useFormatMessage } from "../hooks";
import { PageChangeHandler, ItemSelectHandler, SortChangeHandler, GearProps, IntlText } from "./types";
import { formatOrderBy } from "./utils";

export interface GridColumnProps<TItem> {
  title: string | IntlText;
  field: string,
  fill?: boolean;
  width?: number | string;
  format?: string;
  locked?: boolean;
  sortable?: boolean;
  itemRenderer?: (item: TItem) => ReactElement;
}

// noinspection JSUnusedLocalSymbols
export function GridColumn<TItem>(props: GridColumnProps<TItem>): null {
  return null;
}

export interface GridProps<TItem> extends GearProps<(ReactElement<GridColumnProps<TItem>> | null | undefined | false)[] | ReactElement<GridColumnProps<TItem>> | null | undefined | false> {
  data: Chunk<TItem> | TItem[];
  width?: number | string;
  showSelector?: boolean;
  itemRenderer?: (item: TItem) => ReactElement;
  onItemSelect?: ItemSelectHandler<TItem>;
  onPageChange?: PageChangeHandler;
  onSortChange?: SortChangeHandler;
}

export function Grid<TItem>(props: GridProps<TItem>): ReactElement {
  const { children, className, data, width, showSelector, itemRenderer, onItemSelect, onPageChange, onSortChange } = props;

  const forceUpdate = useForceUpdate();
  const formatMessage = useFormatMessage();

  const take = isChunk(data) ? data.take : data.length;
  const skip = isChunk(data) ? data.skip : 0;
  const total = isChunk(data) ? data.total : data.length;
  const pageable = !!onPageChange && take < total;

  const sortable = !!onSortChange;
  const [ sort, setSort ] = useState<KendoData.SortDescriptor[] | undefined>(undefined);

  const handleRowClick = useCallback((event: KendoGrid.GridRowClickEvent): void => {
    if (onItemSelect) {
      onItemSelect(event.dataItem);
    }
  }, [ onItemSelect]);

  const handlePageChange = useCallback((event: KendoGrid.GridPageChangeEvent): void => {
    if (onPageChange) {
      onPageChange(event.page.skip, event.page.take);
    }
  }, [ onPageChange ]);

  const handleSortChange = useCallback((event: KendoGrid.GridSortChangeEvent): void => {
    if (onSortChange) {
      onSortChange(formatOrderBy(event.sort));
    }
    setSort(event.sort);
  }, [ onSortChange, setSort ]);

  const handleItemExpanded = useCallback((event: KendoGrid.GridExpandChangeEvent): void => {
    event.dataItem.__expanded = !event.dataItem.__expanded;
    forceUpdate();
  }, [ forceUpdate ]);

  const handleItemSelected = useCallback((event: KendoGrid.GridSelectionChangeEvent): void => {
    event.dataItem.__selected = !event.dataItem.__selected;
    if (onItemSelect) {
      onItemSelect(event.dataItem, event.dataItem.__selected === true);
    }
    forceUpdate();
  }, [ forceUpdate, onItemSelect ]);

  const handleHeadSelected = useCallback((event: KendoGrid.GridHeaderSelectionChangeEvent): void => {
    const checked = (event.syntheticEvent.target as any).checked === true;
    for (const item of (isChunk(data) ? data.data : data)) {
      (item as any).__selected = checked;
      if (onItemSelect) {
        onItemSelect(item, checked);
      }
    }
    forceUpdate()
  }, [ data, forceUpdate, onItemSelect ]);

  const customCell = useCallback((props: KendoGrid.GridCellProps, renderer: (item: TItem) => ReactElement): ReactElement => {
    const { className, style, colSpan, dataItem } = props;
    return (
      <td className={className} style={style} colSpan={colSpan}>
        {renderer(dataItem)}
      </td>
    );
  }, []);

  const customView = useCallback((props: KendoGrid.GridDetailRowProps): ReactElement | null => {
    if (itemRenderer) {
      return itemRenderer(props.dataItem);
    } else {
      return null;
    }
  }, [ itemRenderer ]);

  const selWidth = showSelector ? 50 : 0;
  let auto = typeof width === "number" ? width - selWidth : undefined;
  let autoCount = 0;
  React.Children.forEach(children, child => {
    if (!child) return;
    if (child.props.fill) {
      autoCount++;
    }
    if (typeof child.props.width === "number" && auto && !child.props.fill) {
      auto -= child.props.width;
    }
  });
  if (auto && autoCount) {
    auto = auto / autoCount;
  } else {
    auto = undefined;
  }

  const headerSelected = showSelector
    ? (isChunk(data) ? data.data : data).findIndex((item: any) => item.__selected === undefined || item.__selected === false) === -1
    : false;

  return (
    <KendoGrid.Grid className={classNames("g-grid", className)} data={isChunk(data) ? data.data : data}
                    pageable={pageable}
                    take={take}
                    skip={skip}
                    total={total}
                    sortable={sortable}
                    sort={sort}
                    style={{ width }}
                    columnVirtualization={true}
                    detail={itemRenderer ? customView : undefined}
                    expandField={itemRenderer ? "__expanded" : undefined}
                    selectedField="__selected"
                    onRowClick={handleRowClick}
                    onPageChange={handlePageChange}
                    onSortChange={handleSortChange}
                    onExpandChange={itemRenderer ? handleItemExpanded : undefined}
                    onSelectionChange={showSelector ? handleItemSelected : undefined}
                    onHeaderSelectionChange={showSelector ? handleHeadSelected : undefined} >
      {showSelector &&
        <KendoGrid.GridColumn field="__selected"
                              width={selWidth}
                              headerSelectionValue={headerSelected} />
      }
      {children && React.Children.map(children, (child, index) => {
        if (!child) return;
        return (
          <KendoGrid.GridColumn key={index}
                                cell={child.props.itemRenderer ? (props) => customCell(props, child.props.itemRenderer!) : undefined}
                                title={formatMessage(child.props.title)}
                                field={child.props.field}
                                width={child.props.fill && auto && typeof child.props.width === "number" ? Math.max(auto, child.props.width)  : child.props.width}
                                format={child.props.format}
                                locked={child.props.locked}
                                sortable={child.props.sortable}  />
        );
      })}
    </KendoGrid.Grid>
  );
}
