export class EVoucherSettings {
  public logoId: string;
  public description: string | null;
  public voucherSendRequired: boolean | null;
  public voucherRateRequired: boolean | null;

  public constructor() {
    this.logoId = "";
    this.description = null;
    this.voucherSendRequired = null;
    this.voucherRateRequired = null;
  }
}
