import { Apis, Chunk, Client } from '../../../data'
import { FlightDirection, Rate, RateCreateModel, RateFilter, RateUpdateModel, ResolvedRate, ServiceType } from '../models'

export class RateAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: RateFilter): Promise<Chunk<ResolvedRate>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`rates`, {}, {
          pattern,
          orderBy,
          skip,
          take,
          ...filter,
        })
  }

  public static async getOne(id: string): Promise<ResolvedRate> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`rates/${id}`)
  }

  public static async find(id: string, flightDate: string, flightDirection: FlightDirection, flightType: ServiceType,
                           poolId: string | undefined = undefined,
                           contractId: string | undefined = undefined,
                           cardId: string | undefined = undefined,
                           currency: string | undefined = undefined): Promise<Chunk<Rate>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`rates`, { id, flightDate, flightDirection, flightType }, {
        poolId,
        contractId,
        cardId,
        currency,
      })
  }

  public static async create(model: RateCreateModel, test?: boolean): Promise<ResolvedRate> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create<RateCreateModel, ResolvedRate>(`rates?test=${test || false}`, model)
  }

  public static async update(id: string, model: RateUpdateModel, test?: boolean): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`rates/${id}?test=${test || false}`, model)
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`rates/${id}`)
  }
}
