import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import { RateV1, RateBaseV1, RateRecord, RateFilterV1 } from "../../../data/models";

export class RateAccessV1 {
  public static async fetchItem(id: number): Promise<RateV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<RateV1>("Rate", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                providerId?: number, resourceId?: number, isShared?: boolean, filter?: RateFilterV1): Promise<Chunk<RateBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<RateBaseV1>>("Rate", {}, {
        pattern,
        orderBy,
        skip,
        take,
        providerId,
        resourceId,
        isShared,
        category: filter ? filter.category : undefined,
        kind: filter ? filter.kind : undefined,
        type: filter ? filter.type : undefined,
        isByProgram: filter ? filter.isByProgram : undefined,
        isByContract: filter ? filter.isByContract : undefined,
        flightDate: filter ? filter.flightDate : undefined,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                providerId?: number, resourceId?: number, isShared?: boolean, filter?: RateFilterV1): Promise<Chunk<RateRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<RateRecord>>("Rate/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        providerId,
        resourceId,
        isShared,
        category: filter ? filter.category : undefined,
        kind: filter ? filter.kind : undefined,
        type: filter ? filter.type : undefined,
        isByProgram: filter ? filter.isByProgram : undefined,
        isByContract: filter ? filter.isByContract : undefined,
        flightDate: filter ? filter.flightDate : undefined,
      });
  }

  public static async create(item: RateV1): Promise<RateV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create<RateV1>("Rate", item);
  }

  public static async update(id: number, item: RateV1): Promise<RateV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<RateV1>("Rate", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete("Rate", { id });
  }

  public static async check(item: RateV1): Promise<RateV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Rate/check", item);
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Rate/lock", { id }, { action });
  }
}
