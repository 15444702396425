import * as KendoButtons from "@progress/kendo-react-buttons";
import React, { PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface ButtonGroupProps {
  label?: IntlMessage;
  fill?: boolean;
}

interface ButtonGroupState {
}

export class ButtonGroup extends PureComponent<ButtonGroupProps, ButtonGroupState> {
  public constructor(props: ButtonGroupProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { label, fill, children } = this.props;

    const className = fill ? "fill" : undefined;

    if (label) {
      return (
        <Intl render={intl =>
          <span className={className}>
            <span className="k-textbox-container">
              <span>
                <KendoButtons.ButtonGroup>
                  {children}
                </KendoButtons.ButtonGroup>
              </span>
              <label className="k-label">{getIntlMessage(intl, label)}</label>
            </span>
          </span>
        } />
      );
    } else {
      return (
        <KendoButtons.ButtonGroup>
          {children}
        </KendoButtons.ButtonGroup>
      );
    }
  }
}
