import { Apis, Chunk, Client } from '../../../data'
import { User, UserCreateModel, UserUpdateModel } from '../../../data/models'

export class UserAccess {
  public static async getOne(id: string): Promise<User> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<User>("users", { id });
  }

  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, belongsTo?: string): Promise<Chunk<User>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<Chunk<User>>("users", {}, {
        pattern,
        orderBy,
        skip,
        take,
        belongsTo,
      });
  }

  public static async create(item: UserCreateModel): Promise<User> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create("users", item);
  }

  public static async update(id: string, item: UserUpdateModel): Promise<User> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .update(`users/${id}`, item, {});
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`users/${id}`, {});
  }

  public static async reset(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .get(`users/${id}/reset`, {});
  }

  public static async check(belongsTo: string, name: string): Promise<boolean> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`users/${belongsTo}/${name}/check`, {});
  }
}
