import { OrganizationBase } from '../../parts/data/models'
import { IReference } from "./IReference";

export class UserReference implements IReference<string> {
  public id: string;

  public constructor(id?: string) {
    this.id = id || "";
  }
}

export class UserBase extends UserReference {
  public name: string;
  public role: string;
  public email: string;
  public phone: string;
  public familyName: string;
  public givenName: string;
  public middleName: string | null;
  public confirmed: boolean;

  public constructor() {
    super();
    this.name = "";
    this.role = "";
    this.email = "";
    this.phone = "";
    this.familyName = "";
    this.givenName = "";
    this.middleName = "";
    this.confirmed = false;
  }
}

export class User extends UserBase {
  public belongsTo: OrganizationBase;

  public constructor() {
    super();
    this.belongsTo = new OrganizationBase();
  }
}

export class UserUpdateModel {
  public role: string;
  public email: string;
  public phone: string;
  public familyName: string;
  public givenName: string;
  public middleName: string | null;

  public constructor() {
    this.role = "";
    this.email = "";
    this.phone = "";
    this.familyName = "";
    this.givenName = "";
    this.middleName = "";
  }
}

export class UserCreateModel extends UserUpdateModel {
  public name: string;
  public belongsTo: string;

  public constructor() {
    super();
    this.name = "";
    this.belongsTo = "";
  }
}
