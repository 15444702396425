import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Chunk } from "../../data";
import { OrganizationProgramCardAccess } from "../data/access";
import { OrganizationProgramCard } from "../data/models";
import { FetchList } from "./types";

export function useOrganizationProgramCard(cardId: string | null): [ OrganizationProgramCard | null, Dispatch<SetStateAction<OrganizationProgramCard | null>> ] {
  const [ item, setItem ] = useState<OrganizationProgramCard | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (cardId) {
          setItem(await OrganizationProgramCardAccess.getOne(cardId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ cardId, setItem ]);

  return [ item, setItem ];
}

export function useOrganizationProgramCards(pageSize?: number, prefetch?: boolean, byContractId?: string, byProviderId?: string, byAgencyId?: string, isAuthorized?: boolean, isIndividual?: boolean): [ Chunk<OrganizationProgramCard>, FetchList ] {
  const [ data, setData ] = useState<Chunk<OrganizationProgramCard>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await OrganizationProgramCardAccess.getAll(pattern, undefined, skip || 0, take || pageSize, undefined, byContractId, byProviderId, byAgencyId, isAuthorized, isIndividual);
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }, [ pageSize, byContractId, byProviderId, byAgencyId, isAuthorized, isIndividual, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ]
}


