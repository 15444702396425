import { Apis, Chunk, Client } from "../../../data";
import { Change } from '../models'

export class HistoryAccess {
  public static async getOne(id: number): Promise<Change> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<Change>(`changes/${id}`);
  }

  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<Change>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<Chunk<Change>>("changes", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }
}
