import { classNames } from "@progress/kendo-react-common";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import { ReactElement } from "react";
import { IntlText, Intent, SortOrder } from "./types";

export function TXT(id: string, values?: Record<string, PrimitiveType | ReactElement | FormatXMLElementFn>): IntlText {
  return { id, values };
}

export function VAL<TValue>(value: TValue): { value: TValue } {
  return { value };
}

export function formatOrderBy(sortOrder: SortOrder): string {
  return sortOrder.filter(value => value.field && value.dir).map(value => `${value.field}:${value.dir}`).join("|");
}

export function parseOrderBy(orderBy: string): SortOrder {
  const sortOrder: SortOrder = [];
  const values = orderBy.split("|");
  for (const value of values) {
    const [ field, dir ] = value.split(":");
    sortOrder.push({ field, dir: dir === "desc" ? "desc" : "asc" });
  }

  return sortOrder;
}

export function replaceSortOrder(sortOrder: SortOrder, field: string, ...withFields: string[]) {
  const fieldIndex = sortOrder.findIndex(value => value.field === field);
  if (fieldIndex > -1) {
    const sortDesc = sortOrder[fieldIndex];
    sortOrder.splice(fieldIndex, 1, ...withFields.map(name => ({ field: name, dir: sortDesc.dir })));
  }
}

export function intentToClassName(value?: Intent, className?: string): string {
  let intentClassName = "";
  switch (value) {
    case "info":
      intentClassName = "k-text-info";
      break;
    case "success":
      intentClassName = "k-text-success";
      break;
    case "warning":
      intentClassName = "k-text-warning";
      break;
    case "danger":
      intentClassName = "k-text-error";
      break;
    case "primary":
      intentClassName = "k-text-primary";
      break;
  }

  return classNames(className, intentClassName);
}
