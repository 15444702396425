import { CityReference } from './City'
import { ServiceType } from './Service'

export class OrderedFlight {
  public city: CityReference | null
  public type: ServiceType
  public number: string
  public date: string

  public constructor() {
    this.city = null
    this.type = ServiceType.Any
    this.number = ''
    this.date = ''
  }
}
