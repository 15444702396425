import moment from 'moment'
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../auth'
import { Chunk, Pair } from '../../data'
import { Div, FieldSet, Form } from '../../gears'
import { ComboBox, DateBox } from '../../gears/inputs'
import { required } from '../../gears/validators'
import { Organization, OrganizationProgramCard, OrganizationReference, OrganizationType, ReportParameters, ReportType, Resource, ServiceKind } from '../data/models'
import { ItemSubmitHandler, TXT } from '../gears'
import { useField, useOrganization, useOrganizationProgramCards, useOrganizations, useReportTypes, useResources, usePrimaryServiceKinds } from '../hooks'

export interface ReportBuilderProps {
  onSubmit?: ItemSubmitHandler<ReportParameters>;
}

export function ReportBuilder(props: ReportBuilderProps): ReactElement {
  const { onSubmit } = props;

  const auth = useContext(AuthContext);

  const pageSize = 100;

  const [ belongsTo ] = useOrganization(auth.profile.belongsTo);

  const [ organizations, getOrganizations ] = useOrganizations(pageSize, true);
  const [ organization, setOrganization ] = useField<Organization | null>(belongsTo && belongsTo.type === OrganizationType.System ? null : belongsTo);

  const [ types, ] = useReportTypes();
  const [ type, setType ] = useState<Pair<ReportType> | null>(types.filter(pair => pair.code === ReportType.Sale)[0] || null);

  const [ cards, getCards ] = useOrganizationProgramCards(pageSize, organization !== null, undefined, organization && organization.type === OrganizationType.Provider ? organization.id : undefined, organization && organization.type === OrganizationType.Agency ? organization.id : undefined);
  const [ card, setCard ] = useState<OrganizationProgramCard | null>(null);

  const [ from, setFrom ] = useState<string | null>(moment().utc().startOf('month').toISOString());
  const [ till, setTill ] = useState<string | null>(moment().utc().add(1, 'month').startOf('month').toISOString());

  const [ counterparties, getCounterparties ] = useOrganizations(pageSize, true, useMemo(() => ({
    byType: OrganizationType.Agency,
    isConcluded: true,
  }), []))
  const counterpartiesWithSelf = useMemo(() => {
    if (organization && counterparties) {
      return new Chunk<Organization>([ organization, ...counterparties.data ], counterparties.skip, counterparties.take, counterparties.total)
    } else {
      return  new Chunk<Organization>()
    }
  }, [ organization, counterparties ])
  const [ counterparty, setCounterparty ] = useState<Organization | null>(null)

  const [ kinds ] = usePrimaryServiceKinds()
  const [ kind, setKind ] = useState<Pair<ServiceKind> | null>(null)

  const [ resources, getResources ] = useResources(pageSize, organization !== null, useMemo(() => ({
    byProviderId: organization && organization.type === OrganizationType.Provider ? organization.id : undefined,
    byAgencyId: organization && organization.type === OrganizationType.Agency ? organization.id : undefined,
    byKind: kind ? kind.code : undefined,
  }), [ organization, kind ]))
  const [ resource, setResource ] = useState<Resource | null>(null)

  useEffect(() => {
    setCard(null);
  }, [ organization, type, setCard ]);

  useEffect(() => {
    if (organization && organization.type === OrganizationType.Agency) {
      setType(types.filter(pair => pair.code === ReportType.Sale)[0] || null);
    }
  }, [ organization, type, types, from, till, card, setType ]);

  useEffect(() => {
    setResource((prev) => {
      if (prev && kind && prev.kind === kind.code) return prev
      else return null
    })
  }, [ kind ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const item = new ReportParameters();
      item.organization = organization || new OrganizationReference();
      item.card = card;
      item.from = from || "";
      item.till = till || "";
      item.type = type ? type.code : ReportType.Sale;
      item.counterparty = counterparty
      item.kind = kind ? kind.code : null
      item.resource = resource

      await onSubmit(item);
    }
  }, [ organization, card, from, till, type, counterparty, kind, resource, onSubmit ]);

  return (
    <Div layout="grid 12">
      <Div>
        <Form loaded={true}
              readonly={false}
              labelSubmit={TXT("action.download")}
              onSubmit={onSubmit ? handleSubmit : undefined}>
          <Div layout="grid 12">
            <Div>
              <FieldSet label={TXT("label.parameters")}>
                <Div layout="grid 12 3@lg">
                  {belongsTo && belongsTo.type === OrganizationType.System &&
                  <Div>
                    <ComboBox label={TXT("label.organization")}
                              name="organization"
                              fill={true}
                              searchable={true}
                              data={organizations}
                              value={organization}
                              valueKey={"id"}
                              valueLabel={"name"}
                              validators={[ required ]}
                              onChange={setOrganization}
                              onFetch={getOrganizations} />
                  </Div>
                  }
                  <Div>
                    <DateBox label={TXT("label.from")}
                             fill={true}
                             name="from"
                             value={from}
                             validators={[ required ]}
                             onChange={setFrom} />
                  </Div>
                  <Div>
                    <DateBox label={TXT("label.till")}
                             fill={true}
                             name="till"
                             value={till}
                             validators={[ required ]}
                             onChange={setTill} />
                  </Div>
                  {organization && organization.type === OrganizationType.Provider &&
                  <Div>
                    <ComboBox label={TXT("label.type")}
                              fill={true}
                              name="type"
                              data={types}
                              value={type}
                              valueKey="code"
                              valueLabel="name"
                              validators={[ required ]}
                              onChange={setType} />
                  </Div>
                  }
                  {type && (type.code === ReportType.Sale || type.code === ReportType.Operations) &&
                  <Div>
                    <ComboBox label={TXT("label.card")}
                              fill={true}
                              searchable={true}
                              pageSize={pageSize}
                              name="card"
                              data={cards}
                              value={card}
                              valueKey="id"
                              valueLabel="number"
                              onChange={setCard}
                              onFetch={getCards} />
                  </Div>
                  }
                  {type && (type.code === ReportType.Sale || type.code === ReportType.Load || type.code === ReportType.Operations) &&
                  <Div>
                    <ComboBox label={TXT("label.counterparty")}
                              name="counterparty"
                              fill={true}
                              searchable={true}
                              data={counterpartiesWithSelf}
                              value={counterparty}
                              valueKey={"id"}
                              valueLabel={"name"}
                              onChange={setCounterparty}
                              onFetch={getCounterparties} />
                  </Div>
                  }
                  <Div>
                    <ComboBox label={TXT("label.serviceKind")}
                              name="kind"
                              fill={true}
                              searchable={true}
                              data={kinds.filter((pair) => pair.code === ServiceKind.VipLounge || pair.code === ServiceKind.BusinessLounge || pair.code === ServiceKind.OfficialPersonsAndDelegationsLounge || pair.code === ServiceKind.ComfortLounge)}
                              value={kind}
                              valueKey={"code"}
                              valueLabel={"name"}
                              onChange={setKind} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT("label.resource")}
                              name="resource"
                              fill={true}
                              searchable={true}
                              data={resources}
                              value={resource}
                              valueKey={"id"}
                              valueLabel={"name"}
                              onChange={setResource}
                              onFetch={getResources} />
                  </Div>
                </Div>
              </FieldSet>
            </Div>
          </Div>
        </Form>
      </Div>
    </Div>
  );
}
