import { EMPTY_ID, Performer } from './index'

export enum DepositOperationType {
  Replenish = "Replenish",
  Withdraw = "Withdraw",
  Refund = "Refund",
  Penalty = "Penalty",
  Reduce = "Reduce",
}

export class DepositOperation {
  public id: string;
  public type: DepositOperationType;
  public value: number;
  public performedAt: string;
  public performedBy?: Performer;

  public constructor() {
    this.id = EMPTY_ID;
    this.type = DepositOperationType.Replenish;
    this.value = 0;
    this.performedAt = "";
  }
}
