import { Apis, Chunk, Client } from '../../../data'
import { Airport, CityBase, CurrencySettings, EVoucherSettings, FreeAccessSettings, LegalRequisites, Organization, OrganizationBase, OrganizationContract, OrganizationContractType, OrganizationCreateModel, OrganizationFilter, OrganizationToken, OrganizationUpdateModel, PaymentRequisites, ResolvedOrganization, Resource, ResourceDefaults } from '../models'

export class OrganizationAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: OrganizationFilter): Promise<Chunk<ResolvedOrganization>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get('organizations', {}, {
        as: 'Detailed',
        pattern,
        orderBy,
        skip,
        take,
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<Organization<CityBase>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}?as=Detailed`)
  }

  public static async getOneContracts(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number,
                                      byType?: OrganizationContractType, byProviderId?: string, byAgencyId?: string,
                                      isAuthorized?: boolean, isActive?: boolean,
  ): Promise<Chunk<OrganizationContract<OrganizationBase>>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/contracts`, {}, {
        as: 'Detailed',
        pattern,
        orderBy,
        skip,
        take,
        byType,
        byProviderId,
        byAgencyId,
        isAuthorized,
        isActive,
      })
  }

  public static async getOneDefaults(id: string): Promise<ResourceDefaults> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/defaults`)
  }

  public static async getOneFreeAccessSettings(id: string): Promise<FreeAccessSettings> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/access`)
  }

  public static async getOneVoucherSettings(id: string): Promise<EVoucherSettings> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/voucher`)
  }

  public static async getOneCurrencySettings(id: string): Promise<CurrencySettings> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/currency`)
  }

  public static async getOneLegalRequisites(id: string): Promise<LegalRequisites> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/legal`)
  }

  public static async getOnePaymentRequisites(id: string): Promise<Chunk<PaymentRequisites>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/payment`)
  }

  public static async getOneResources(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<Resource>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/resources`, {}, {
        as: 'Detailed',
        pattern,
        orderBy,
        skip,
        take,
      })
  }
  
  public static async getOneAirports(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<Airport>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/airports`, {}, { pattern, orderBy, skip, take })
  }
  
  public static async create(model: OrganizationCreateModel): Promise<Organization> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`organizations`, model);
  }
  
  public static async update(id: string, model: OrganizationUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`organizations/${id}`, model);
  }
  
  public static async updateSettings(id: string, model: { resourceDefaults?: ResourceDefaults; eVoucherSettings?: EVoucherSettings; freeAccessSettings?: FreeAccessSettings }): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .modify(`organizations/${id}/settings`, model);
  }

  public static async prepareToken(id: string): Promise<OrganizationToken> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`organizations/${id}/token`)
  }

  public static async confirmToken(id: string, model: OrganizationToken): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`organizations/${id}/token`, model)
  }
}
