import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import {
  PaymentRequisitesV1,
  OrganizationV1,
  OrganizationBaseV1,
  OrganizationRecord,
  OrganizationTypeV1,
  OrganizationVoucherSettingsV1,
  OrganizationFreeAccessSettingsV1,
} from "../../../data/models";
import { CurrencySettings } from "../models";

export class OrganizationAccessV1 {
  public static async fetchItem(id: number | string): Promise<OrganizationV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<OrganizationV1>("Organization", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                type?: OrganizationTypeV1, withContract?: boolean,
                                forProviderId?: number, forAgencyId?: number): Promise<Chunk<OrganizationBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrganizationBaseV1>>("Organization", {}, {
        pattern,
        orderBy,
        skip,
        take,
        type,
        withContract,
        forProviderId,
        forAgencyId,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                type?: OrganizationTypeV1, withContract?: boolean,
                                forProviderId?: number, forAgencyId?: number): Promise<Chunk<OrganizationRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<OrganizationRecord>>("Organization/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        type,
        withContract,
        forProviderId,
        forAgencyId,
      });
  }

  public static async create(item: OrganizationV1): Promise<OrganizationV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Organization", item);
  }

  public static async update(id: number, item: OrganizationV1): Promise<OrganizationV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update("Organization", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    return await Client
      .instance(await Apis.getDataApi())
      .delete("Organization", { id });
  }

  public static async fetchPaymentRequisites(organization: number | string): Promise<PaymentRequisitesV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<PaymentRequisitesV1[]>("Organization/requisites", { organization });
  }

  public static async updatePaymentRequisites(organizationId: number | string, data: PaymentRequisitesV1[]): Promise<PaymentRequisitesV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<PaymentRequisitesV1[]>("Organization/requisites", data, { organizationId });
  }

  public static async fetchEVoucherSettings(provider: number | string): Promise<OrganizationVoucherSettingsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<OrganizationVoucherSettingsV1>("Organization/eVoucher", { provider });
  }

  public static async updateEVoucherSettings(provider: number | string, item: OrganizationVoucherSettingsV1): Promise<OrganizationVoucherSettingsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<OrganizationVoucherSettingsV1>("Organization/eVoucher", item, { provider });
  }

  public static async fetchFreeAccessSettings(provider: number | string): Promise<OrganizationFreeAccessSettingsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<OrganizationFreeAccessSettingsV1>("Organization/freeAccess", { provider });
  }

  public static async updateFreeAccessSettings(provider: number | string, item: OrganizationFreeAccessSettingsV1): Promise<OrganizationFreeAccessSettingsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<OrganizationFreeAccessSettingsV1>("Organization/freeAccess", item, { provider });
  }

  public static async fetchCurrency(provider: number | string): Promise<CurrencySettings> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<CurrencySettings>("Organization/currency", { provider });
  }

  public static async updateCurrency(provider: number | string, model: CurrencySettings): Promise<CurrencySettings> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<CurrencySettings>("Organization/currency", model, { provider });
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Organization/lock", { id }, { action })
  }
}
