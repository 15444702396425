import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import {
  CorporateProgramV1,
  CorporateProgramFamilyV1,
  CorporateProgramBaseV1,
  CorporateProgramRecord,
} from "../../../data/models";

export class CorporateProgramAccessV1 {
  public static async fetchItem(id: number): Promise<CorporateProgramV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<CorporateProgramV1>("CorporateProgram", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                providerId?: number, agencyId?: number, family?: CorporateProgramFamilyV1): Promise<Chunk<CorporateProgramBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<CorporateProgramBaseV1>>("CorporateProgram", {}, {
        pattern,
        orderBy,
        skip,
        take,
        providerId,
        agencyId,
        family,
        isActive: true,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                providerId?: number, agencyId?: number, family?: CorporateProgramFamilyV1, isActive?: boolean): Promise<Chunk<CorporateProgramRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<CorporateProgramRecord>>("CorporateProgram/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        providerId,
        agencyId,
        family,
        isActive,
      });
  }

  public static async create(item: CorporateProgramV1): Promise<CorporateProgramV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create<CorporateProgramV1>("CorporateProgram", item);
  }

  public static async update(id: number, item: CorporateProgramV1): Promise<CorporateProgramV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<CorporateProgramV1>("CorporateProgram", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete("CorporateProgram", { id });
  }

  public static async deactivate(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .get("CorporateProgram/deactivate", { id });
  }

  public static async hasActiveCards(id: number): Promise<boolean> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<boolean>("CorporateProgram/hasActiveCards", { id });
  }

  public static async lock(id: number, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("CorporateProgram/lock", { id }, { action })
  }
}
