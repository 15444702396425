import React, { ContextType, PureComponent, ReactNode } from "react";
import { FormContext } from "./FormContext";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface FieldRenderer<TValue> {
  (value: TValue, error: IntlMessage | undefined, disabled: boolean, readonly: boolean,
   onChange: (value: TValue) => void,
   onEnter: () => void,
   onLeave: () => void,
  ): ReactNode;
}

interface FieldProps<TValue> {
  hint?: IntlMessage;
  name: string;
  value: TValue;
  error?: IntlMessage;
  validators?: ((value: TValue) => IntlMessage | undefined)[],
  validateOnChange: boolean;
  render: FieldRenderer<TValue>,
  onChange?: (value: TValue) => void;
}

interface FieldProps<TValue> {
  children?: null;
}

interface FieldState<TValue> {
  value?: TValue;
  error?: IntlMessage;
}

export class Field<TValue> extends PureComponent<FieldProps<TValue>, FieldState<TValue>> {
  // noinspection JSUnusedGlobalSymbols
  public static contextType = FormContext;
  public context!: ContextType<typeof FormContext>;

  public constructor(props: FieldProps<TValue>) {
    super(props);

    this.state = {};
  }

  public componentDidUpdate(prevProps: Readonly<FieldProps<TValue>>, prevState: Readonly<FieldState<TValue>>, snapshot?: any): void {
    if (this.context.loaded && this.state.value === undefined) {
      this.setState({ value: this.props.value });
    }

    if (!this.context.loaded && this.state.value !== undefined) {
      this.setState({ value: undefined, error: undefined });
      this.context.onFieldChange(this.props.name, true, false);
    }

    if ((this.context.submitting && !this.state.error) || (this.props.validateOnChange && prevProps.value !== this.props.value)) {
      this.check();
    }

    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
      this.context.onFieldChange(this.props.name, !this.props.error);
    }

    if (this.context.cancelling && this.state.value !== undefined && this.state.value !== this.props.value) {
      this.handleChange(this.state.value);
    }
  }

  public componentWillUnmount(): void {
    this.context.onFieldRemove(this.props.name, true);
  }

  public render(): ReactNode {
    const { readonly, submitting, cancelling, deleting } = this.context;
    const { hint, value, render } = this.props;
    const { error } = this.state;

    return (
      <Intl render={intl =>
        <React.Fragment>
          {render(value, error, submitting || cancelling || deleting, readonly, this.handleChange, this.handleEnter, this.handleLeave)}
          {error
            ?
            <div className="k-text-error">
              <small>{error && getIntlMessage(intl, error)}</small>
            </div>
            :
            <div>
              <small>{hint && getIntlMessage(intl, hint)}</small>
            </div>
          }
        </React.Fragment>
      } />
    );
  }

  private readonly clean = (): void => {
    this.setState({ error: undefined });
  };

  private readonly check = (): void => {
    let error = this.props.error;
    if (!error && this.props.validators) {
      for (const validator of this.props.validators) {
        error = validator(this.props.value);
        if (error) {
          break;
        }
      }
    }

    const initialValue = this.state.value;
    const isValid = !error;
    const changed = initialValue !== undefined && this.props.value !== initialValue;
    this.context.onFieldChange(this.props.name, isValid, changed);
    this.setState({ error });
  };

  private readonly handleChange = (value: TValue): void => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  private readonly handleEnter = (): void => {
    this.clean();
  };

  private readonly handleLeave = (): void => {
    this.check();
  };
}
