import { CityReference } from "./City";
import { FlightDirection } from "./FlightSpan";
import { Order } from "./Order";
import { Passenger } from "./Passenger";
import { RatedPassenger } from "./RatedPassenger";
import { ResourceReference } from "./Resource";
import { SeatUpdateModel } from "./Seat";
import { ServiceType } from "./Service";

export class FlightReference {
  public id: number;

  public constructor() {
    this.id = 0;
  }
}

export class FlightBase extends FlightReference {
  public flightCity: CityReference | null;
  public flightType: ServiceType;
  public flightNumber: string;
  public flightDate: string;
  public flightZone: string;
  public flightDirection: FlightDirection;

  public constructor() {
    super();
    this.flightCity = null;
    this.flightType = ServiceType.Any;
    this.flightNumber = "";
    this.flightDate = "";
    this.flightZone = "UTC";
    this.flightDirection = FlightDirection.Any;
  }
}

export class Flight extends FlightBase {
  public order: Order;
  public resource: ResourceReference;
  public passengers: Passenger[];
  public seats: RatedPassenger[];

  public constructor() {
    super();
    this.order = new Order();
    this.resource = new ResourceReference();
    this.passengers = [];
    this.seats = [];
  }
}

export class FlightUpdateModel {
  public id: number;
  public seats: SeatUpdateModel[];

  public constructor() {
    this.id = 0;
    this.seats = [];
  }
}
