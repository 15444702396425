import { FlightDirection } from './FlightSpan'
import { AirportName, AirportReference, EMPTY_ID, OrganizationName, OrganizationReference, PassengerCategory, ServiceKind, ServiceMode, ServiceType } from './index'

export enum ResourceFeature {
  AirCondition = 'air-condition',
  AlcoholDrink = 'alcohol-drink',
  BizZone = 'biz-zone',
  ElectricalSocket = 'electrical-socket',
  Internet = 'internet',
  LimitedPeople = 'limited-people',
  LuggagePacking = 'luggage-packing',
  Meal = 'meal',
  Phone = 'phone',
  PlayRoom = 'playroom',
  Press = 'press',
  Registration = 'registration',
  Shower = 'shower',
  Transfer = 'transfer',
  TV = 'tv',
  Wardrobe = 'wardrobe',
  Wifi = 'wifi',
  Vegan = 'vegan',
  HotDishes = 'hot-dishes',
  Snacks = 'snacks',
  Cocktails = 'cocktails',
  Menu = 'menu',
  Fruits = 'fruits',
  Salad = 'salad',
  Champagne = 'champagne',
  SPA = 'spa',
  RunwayView = 'runway-view',
  Sofa = 'sofa',
  SmokingArea = 'smoking-area',
  EntertainmentArea = 'entertainment-area',
  LuggageStorage = 'luggage-storage',
  BabyChanging = 'baby-changing',
  NailPolish = 'nail-polish',
  MassageChair = 'massage-chair',
  PrayerRoom = 'prayer-room',
  SleepingPlaces = 'sleeping-places',
  WC = 'wc',
  Laptop = 'laptop',
  ConferenceHall = 'conference-hall',
  FlightTime = 'flight-time',
  Announcements = 'announcements',
  SecurityCheck = 'security-check',
  Printer = 'printer',
  Fax = 'fax',
}

export interface ResourceFilter {
  byCode?: string;
  byKind?: ServiceKind;
  byType?: ServiceType;
  byPoolId?: string;
  byAirportId?: string;
  byProviderId?: string;
  byAgencyId?: string;
  isLounge?: boolean;
  isAssistance?: boolean;
  includeDeactivated?: boolean;
}

export class ResourceReference {
  public id: string

  public constructor(id?: string) {
    this.id = id || EMPTY_ID
  }
}

export class ResourceName extends ResourceReference {
  public code: string
  public name: string

  public constructor() {
    super()
    this.code = ''
    this.name = ''
  }
}

export class ResourceBase extends ResourceName {
  public kind: ServiceKind
  public type: ServiceType
  public mode: ServiceMode
  public isTransit: boolean
  public flightDirection: FlightDirection
  public location: string | null
  public schedule: string | null
  public description: string | null
  public phone: string | null
  public features: ResourceFeature[] | null
  public amenities: ResourceFeature[] | null
  public deactivated: Boolean

  public constructor() {
    super()
    this.kind = ServiceKind.Any
    this.type = ServiceType.Any
    this.mode = ServiceMode.Automatic
    this.isTransit = false
    this.flightDirection = FlightDirection.Any
    this.location = null
    this.schedule = null
    this.description = null
    this.features = null
    this.amenities = null
    this.phone = null
    this.deactivated = false
  }
}

export class Resource<TOrganization extends OrganizationReference = OrganizationReference, TAirport extends AirportReference = AirportReference> extends ResourceBase {
  public organization: TOrganization
  public airport: TAirport
  public terminal: string | null

  public constructor(TOrganization: new() => TOrganization, TAirport: new() => TAirport) {
    super()
    this.organization = new TOrganization()
    this.airport = new TAirport()
    this.terminal = null
  }
}

export class ResourceTranslation extends ResourceReference {
  public language: string
  public name: string | null
  public description: string | null
  public location: string | null
  public schedule: string | null

  public constructor(init: Partial<ResourceTranslation>) {
    super(init.id)
    this.language = init.language ?? ''
    this.name = init.name ?? null
    this.description = init.description ?? null
    this.location = init.location ?? null
    this.schedule = init.schedule ?? null
  }
}

export class ResolvedResource extends Resource<OrganizationName, AirportName> {
  public constructor() {
    super(OrganizationName, AirportName)
  }
}

export function isLounge(kind: ServiceKind): boolean {
  switch (kind) {
    case ServiceKind.VipLounge:
    case ServiceKind.BusinessLounge:
    case ServiceKind.ComfortLounge:
    case ServiceKind.OfficialPersonsAndDelegationsLounge:
    case ServiceKind.Info:
      return true
    default:
      return false
  }
}

export function isAssistance(kind: ServiceKind): boolean {
  switch (kind) {
    case ServiceKind.FastTrack:
    case ServiceKind.MeetAndAssist:
    case ServiceKind.MeetAndGreet:
    case ServiceKind.Transit:
    case ServiceKind.Parking:
      return true
    default:
      return false
  }
}

export function isTransfer(kind: ServiceKind): boolean {
  return kind === ServiceKind.Transfer
}

export function isSecondary(kind: ServiceKind): boolean {
  switch (kind) {
    case ServiceKind.Other:
    case ServiceKind.Apartments:
    case ServiceKind.ConferenceRoom:
    case ServiceKind.LoungeTime:
    case ServiceKind.Luggage:
    case ServiceKind.Photography:
    case ServiceKind.Urgency:
      return true
    default:
      return false
  }
}

export class ResourceUpdateModel {
  public name: string
  public mode: ServiceMode
  public type: ServiceType
  public description: string | null
  public capacity: number | null
  public quota: number | null
  public groupAge: number | null
  public groupSize: number | null
  public maxStayDuration: string | null
  public arrivalEnterDelta: string | null
  public arrivalLeaveDelta: string | null
  public departureEnterDelta: string | null
  public departureLeaveDelta: string | null
  public minChildAge: number | null
  public minAdultAge: number | null
  public servicedCategories: PassengerCategory[] | null
  public location: string | null
  public schedule: string | null
  public phone: string | null
  public features: string[] | null
  public amenities: string[] | null
  public subscribers: string[] | null
  public compatibilities: ServiceKind[] | null
  public isTransit: boolean | null
  public maxFlightConnection: string | null
  public deactivated: boolean | null

  public constructor() {
    this.name = ''
    this.mode = ServiceMode.Automatic
    this.type = ServiceType.Any
    this.description = null
    this.capacity = null
    this.quota = null
    this.groupAge = null
    this.groupSize = null
    this.maxStayDuration = null
    this.arrivalEnterDelta = null
    this.arrivalLeaveDelta = null
    this.departureEnterDelta = null
    this.departureLeaveDelta = null
    this.minChildAge = null
    this.minAdultAge = null
    this.servicedCategories = null
    this.location = null
    this.schedule = null
    this.phone = null
    this.features = null
    this.amenities = null
    this.subscribers = null
    this.compatibilities = null
    this.isTransit = null
    this.maxFlightConnection = null
    this.deactivated = null
  }
}

export class ResourceCreateModel extends ResourceUpdateModel {
  public kind: ServiceKind
  public flightDirection: FlightDirection
  public organization: OrganizationReference
  public airport: AirportReference
  public terminal: string | null

  public constructor() {
    super()
    this.kind = ServiceKind.VipLounge
    this.flightDirection = FlightDirection.Any
    this.organization = new OrganizationReference()
    this.airport = new AirportReference()
    this.terminal = null
  }
}

export class ResourceTranslateModel {
  public name: string | null
  public description: string | null
  public location: string | null
  public schedule: string | null

  public constructor() {
    this.name = null
    this.description = null
    this.location = null
    this.schedule = null
  }
}
