import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Pair } from '../../data'
import { ID } from '../../gears'
import { DepositOperationType } from '../data/models'
import { PairConverter, PairData } from './types'

export function useDepositOperationTypes(): [ PairData<DepositOperationType>, PairConverter<DepositOperationType> ] {
  const intl = useIntl();

  const [ data, ] = useState<Pair<DepositOperationType>[]>([
    new Pair(DepositOperationType.Replenish, intl.formatMessage(ID("enum.depositOperationType.replenish"))),
    new Pair(DepositOperationType.Withdraw, intl.formatMessage(ID("enum.depositOperationType.withdraw"))),
    new Pair(DepositOperationType.Refund, intl.formatMessage(ID("enum.depositOperationType.refund"))),
    new Pair(DepositOperationType.Reduce, intl.formatMessage(ID("enum.depositOperationType.reduce"))),
    new Pair(DepositOperationType.Penalty, intl.formatMessage(ID("enum.depositOperationType.fine"))),
  ]);

  const converter = useCallback((code: DepositOperationType): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ]
}
