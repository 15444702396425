export enum SeatStatus {
  Requested = "Requested",
  Rated = "Rated",
  Paid = "Paid",
  NoSchedule = "NoSchedule",
  NoRate = "NoRate",
  NoSeat = "NoSeat",
  NoLimit = "NoLimit",
  Fined = "Fined",
  Forced = "Forced",
  Cancelled = "Cancelled",
  Returned = "Returned",
  Rejected = "Rejected",
}
