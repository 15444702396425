import React, { ComponentType, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AuthContext, IdentityProfile } from "../auth";
import { ClientError } from "../data";
import { Div, ID, IntlMessage, NavHead, NavPath, NavPathItem, Toaster, ToasterItem } from "../gears";

export interface PageProps<TParams = {}, TState = any> extends RouteComponentProps<TParams, {}, TState> {
  onNavigate: (path: string, state?: any) => void;
  onLoaded: (items: any[]) => void;
  onError: (error: any, processData?: (data: any) => string) => void;
}

export interface PageProps<TParams, TState> {
  children?: null;
}

export interface PathBuilder {
  (items: any[], profile: IdentityProfile): NavPathItem[];
}

export function withPage<TParams, TState, TComponentProps extends PageProps<TParams, TState>>(Component: ComponentType<TComponentProps>, pathBuilder: PathBuilder): ComponentType<TComponentProps> {
  return (props: Readonly<TComponentProps>) => {

    const context = useContext(AuthContext);
    const [ pathItems, setPathItems ] = useState<NavPathItem[]>([]);
    const [ toasterItems, setToasterItems ] = useState<ToasterItem[]>([]);

    const title = pathItems.length > 0 ? pathItems[pathItems.length - 1].text : "";

    useEffect(() => {
      setToasterItems([]);
    }, [ props.location.pathname, setToasterItems ]);

    const buildPath = useCallback((items: any[]) => {
      const pathItems = pathBuilder(items, context.profile)
        .map(pathItem => ({
          path: `${props.match.path}${pathItem.path.length > 0 ? `/${pathItem.path}` : ""}`,
          text: pathItem.text,
          state: pathItem.state,
        }));
      setPathItems(pathItems);
    }, [ context, props.match.path ]);

    const handleNavigate = useCallback((path: string, state?: any) => {
      if (path.startsWith("/")) {
        props.history.push(path, state);
      } else {
        props.history.push(`${props.match.path}${path.length > 0 ? path.startsWith("?") ? `${path}` : `/${path}` : ""}`, state);
      }
    }, [ props.history, props.match.path ]);

    const handleLoaded = useCallback((items: any[]) => {
      buildPath(items);
    }, [ buildPath ]);

    const handleError = useCallback((error: any, processData?: (data: any) => string) => {
      let text: IntlMessage = error.toString();
      if (error instanceof Error) {
        if (error instanceof ClientError) {
          switch (error.status) {
            case 401:
            case 403:
              text = ID("error.forbid");
              break;
            case 404:
              text = ID("error.notFound");
              break;
            default:
              text = ""
              if (processData) {
                text = processData(error.data);
              } else {
                text = error.status
                  ? ID("error.status", { status: error.status })
                  : ID("error.network");
              }
              break;
          }
        } else {
          console.error(error);
        }
      }
      setToasterItems(prev => [ ...prev, { text, type: "error", icon: true } ]);
    }, [ setToasterItems ]);

    useEffect(
      () => {
        const init = async (): Promise<void> => {
          const response = await fetch('configuration/version')
          const expectedVersion = await response.text()
          const currentVersion = process.env.REACT_APP_VERSION
          if (expectedVersion !== currentVersion) window.location.reload()
        }

        init().finally();
      },
      [],
    )

    return (
      <React.Fragment>
        <Div className="path">
          <NavPath items={pathItems} />
        </Div>
        <Div className="head">
          <NavHead text={title} />
        </Div>
        <Div className="content">
          <Component {...props as TComponentProps}
                     onNavigate={handleNavigate}
                     onLoaded={handleLoaded}
                     onError={handleError} />
        </Div>
        <Toaster items={toasterItems}
                 onClose={index => setToasterItems(prev => prev.filter((t, i) => i !== index))} />
      </React.Fragment>
    );
  };
}
