import { ContactInformation } from './ContactInformation'
import { CurrencyBase, CurrencyReference, EMPTY_ID, OrganizationBase, OrganizationReference, PaymentRequisites, PoolBase, PoolReference } from './index'

export enum OrganizationContractType {
  Direct = 'Direct',
  Deposit = 'Deposit',
}

export interface OrganizationContractFilter {
  byType?: OrganizationContractType;
  byProviderId?: string;
  byAgencyId?: string;
  byResourceId?: string;
  isAuthorized?: boolean;
  isActive?: boolean;
}

export class OrganizationContractReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class OrganizationContractName extends OrganizationContractReference {
  public number: string

  public constructor() {
    super()
    this.number = ''
  }
}

export class OrganizationContractBase extends OrganizationContractName {
  public type: OrganizationContractType
  public concludedAt: string
  public authorized: boolean

  public constructor() {
    super()
    this.type = OrganizationContractType.Direct
    this.concludedAt = ''
    this.authorized = true
  }
}

export class OrganizationContractRule<TPool extends PoolReference = PoolReference> {
  public pool: TPool | null

  public constructor() {
    this.pool = null
  }
}

export class OrganizationContract<TOrganization extends OrganizationReference = OrganizationReference, TPool extends PoolReference = PoolReference, TCurrency extends CurrencyReference = CurrencyReference> extends OrganizationContractBase {
  public provider: TOrganization
  public agency: TOrganization
  public rules: OrganizationContractRule<TPool>[]
  public providerRequisites: PaymentRequisites
  public agencyRequisites: PaymentRequisites
  public contactInformation: ContactInformation
  public parent: OrganizationContractReference | null
  public currency: TCurrency
  public source: string | null

  public constructor(TOrganization: new() => TOrganization, TPool: new () => TPool, TCurrency: new () => TCurrency) {
    super()
    this.provider = new TOrganization()
    this.agency = new TOrganization()
    this.rules = []
    this.providerRequisites = new PaymentRequisites()
    this.agencyRequisites = new PaymentRequisites()
    this.contactInformation = new ContactInformation()
    this.parent = null
    this.currency = new TCurrency()
    this.source = null
  }
}

export class ResolvedOrganizationContract extends OrganizationContract<OrganizationBase, PoolBase, CurrencyBase> {
  public constructor() {
    super(OrganizationBase, PoolBase, CurrencyBase);
  }
}

export class OrganizationContractUpdateModel {
  public authorized: boolean
  public providerRequisites: PaymentRequisites
  public agencyRequisites: PaymentRequisites
  public contactInformation: ContactInformation
  public source: string | null

  public constructor() {
    this.authorized = false
    this.providerRequisites = new PaymentRequisites()
    this.agencyRequisites = new PaymentRequisites()
    this.contactInformation = new ContactInformation()
    this.source = null
  }
}

export class OrganizationContractCreateModel extends OrganizationContractUpdateModel {
  public number: string
  public type: OrganizationContractType
  public concludedAt: string
  public provider: OrganizationReference
  public agency: OrganizationReference
  public rules: OrganizationContractRule[]
  public parent: OrganizationContractReference | null
  public currency: string

  public constructor() {
    super()
    this.number = ''
    this.type = OrganizationContractType.Deposit
    this.concludedAt = ''
    this.provider = new OrganizationReference()
    this.agency = new OrganizationReference()
    this.rules = []
    this.parent = null
    this.currency = 'RUB'
  }
}
