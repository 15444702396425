import { AirportTerminalName, TransferPointType } from './AirportTerminal'
import { PassengerReference } from './Passenger'
import { ServiceReference } from './Service'
import { ServiceField } from './ServiceField'

export class UpdatedService {
  public id: number
  public passenger: PassengerReference | null
  public service: ServiceReference
  public fields: ServiceField[] | null
  public count: number | null
  public from: string | null
  public till: string | null
  public departureTime: string | null
  public destinationPoint: AirportTerminalName | null
  public destinationPointType: TransferPointType | null
  public return: boolean | null

  public constructor() {
    this.id = 0
    this.passenger = null
    this.service = new ServiceReference()
    this.fields = null
    this.count = null
    this.from = null
    this.till = null
    this.departureTime = null
    this.destinationPoint = null
    this.destinationPointType = null
    this.return = null
  }
}
