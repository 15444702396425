import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { Chunk } from '../../data'
import { FieldSet, Form } from '../../gears'
import { DateBox, TextArea, TextBox } from '../../gears/inputs'
import { Change } from '../data/models'
import { Div, Grid, GridColumn, ItemSearchHandler, TXT } from '../gears'
import { useDelay, useField, useFormatDate, useWindowSize } from '../hooks'
import { GridViewProps, ItemViewProps } from './types'

export interface HistoryItemProps extends ItemViewProps<Change> {
}

export function HistoryItem(props: HistoryItemProps): ReactElement {
  const { item, lock, readonly } = props;

  const [ uri, setUri ] = useField<string | null>(item.id);
  const [ action, setAction ] = useField<string | null>(item.action);
  const [ modelId, setModelId ] = useField<string | null>(item.model.id);
  const [ modelName, setModelName ] = useField<string | null>(item.name);
  const [ inputModel, setInputModel ] = useField<string | null>(item.input ? JSON.stringify(JSON.parse(item.input), null, 2) : null);
  const [ outputModel, setOutputModel ] = useField<string | null>(item.output ? JSON.stringify(JSON.parse(item.output), null, 2) : null);
  const [ performedAt, setPerformedAt ] = useField<string | null>(item.performedAt);
  const [ performedBy, setPerformedBy ] = useField<string | null>(item.performedBy.userName ?? item.performedBy.id ?? item.performedBy.clientId ?? 'UNKNOWN',);

  return (
    <Form lock={lock}
          loaded={true}
          readonly={readonly}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label="Attributes">
            <Div layout="grid 12">
              <Div>
                <Div layout="grid 12 6@lg">
                  <Div>
                    <TextBox label="URI" fill={true} name="uri" value={uri} onChange={setUri} />
                  </Div>
                  <Div>
                    <TextBox label="Action" fill={true} name="action" value={action} onChange={setAction} />
                  </Div>
                  <Div>
                    <TextBox label="Model ID" fill={true} name="modelId" value={modelId} onChange={setModelId} />
                  </Div>
                  <Div>
                    <TextBox label="Model Name" fill={true} name="modelName" value={modelName} onChange={setModelName} />
                  </Div>
                  <Div>
                    <DateBox label="At" fill={true} time={true} name="performedAt" value={performedAt} onChange={setPerformedAt} />
                  </Div>
                  <Div>
                    <TextBox label="By" fill={true} name="performedBy" value={performedBy} onChange={setPerformedBy} />
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
          <FieldSet label="Input">
            <Div>
              <TextArea fill={true} name="inputModel" value={inputModel} onChange={setInputModel} />
            </Div>
          </FieldSet>
          <FieldSet label="Output">
            <Div>
              <TextArea fill={true} name="outputModel" value={outputModel} onChange={setOutputModel} />
            </Div>
          </FieldSet>
        </Div>
      </Div>
    </Form>
  );
}

export interface HistoryGridProps extends GridViewProps<Change> {
  onItemSearch?: ItemSearchHandler;
}

export function HistoryGrid(props: HistoryGridProps): ReactElement {
  const { data, onItemSearch, onItemSelect, onPageChange, onSortChange } = props;

  const formatDate = useFormatDate();

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null);
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        action: item.action,
        modelId: item.model.id,
        modelName: item.name,
        performedAt: formatDate(item.performedAt, true),
        performedBy: item.performedBy.userName ?? item.performedBy.id ?? item.performedBy.clientId ?? 'UNKNOWN',
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatDate, setProcessedData ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [ delayedPattern, onItemSearch ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [ data.data, onItemSelect ]);

  return (
    <Div layout="grid 12">
      {onItemSearch &&
      <Div>
        <TextBox placeholder={TXT("label.search")}
                 fill={true}
                 name="search-pattern"
                 value={currentPattern}
                 onChange={setPattern} />
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange}>
              <GridColumn title="Model ID" field="modelId" width={384} sortable={false} locked={true} />
              <GridColumn title="Model Name" field="modelName" width={128} sortable={false} />
              <GridColumn title="Action" field="action" fill={true} sortable={false} />
              <GridColumn title="At" field="performedAt" width={192} sortable={false} />
              <GridColumn title="By" field="performedBy" width={192} sortable={false} locked={true} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}
