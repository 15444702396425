import * as KendoInputs from "@progress/kendo-react-inputs";
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, ID, Intl, IntlMessage } from "../Intl";

interface SwitchBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  labelOn?: IntlMessage;
  labelOff?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  name: string;
  mode?: "switch" | "check"
  value: boolean;
  error?: IntlMessage;
  validators?: ((value: boolean) => IntlMessage | undefined)[],
  onChange?: (value: boolean) => void;
}

interface SwitchBoxProps {
  children?: null;
}

interface SwitchBoxState {
}

export class SwitchBox extends PureComponent<SwitchBoxProps, SwitchBoxState> {
  public constructor(props: SwitchBoxProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { hint, label, labelOn, labelOff, disabled, readonly, name, mode, value, error, validators, onChange } = this.props;

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={true}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) => {
                 if (mode === "check") {
                   return (
                     <KendoInputs.Checkbox disabled={disabled || fieldDisabled || !onFieldChange}
                                           label={label && getIntlMessage(intl, label)}
                                           checked={value}
                                           onChange={!readonly && !fieldReadonly ? e => onFieldChange(e.value) : undefined} />
                   );
                 } else {
                   return (
                     <KendoInputs.Switch disabled={disabled || fieldDisabled || !onFieldChange}
                                         onLabel={getIntlMessage(intl, labelOn || ID("switch.on"))}
                                         offLabel={getIntlMessage(intl, labelOff || ID("switch.off"))}
                                         checked={value}
                                         onChange={!readonly && !fieldReadonly ? e => onFieldChange(e.target.value) : undefined} />
                   );
                 }
               }
               }
               onChange={onChange} />
      } />
    );
  }
}
