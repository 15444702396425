import * as R from 'ramda'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Chunk, Lock } from '../data'
import { Drawer, NavPathItem } from '../gears'
import { HistoryAccess } from '../parts/data/access'
import { Change } from '../parts/data/models'
import { Button, Div, Loader, TXT } from '../parts/gears'
import { useFormatMessage } from '../parts/hooks'
import { HistoryGrid, HistoryItem } from '../parts/views'
import { PageProps, withPage } from './withPage'

function HistoryPage(props: PageProps): ReactElement {
  const { onLoaded, onError } = props;

  const formatMessage = useFormatMessage();

  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState<Chunk<Change>>(new Chunk());
  const [ sort, setSort ] = useState("");
  const [ skip, setSkip ] = useState(0);
  const [ take, setTake ] = useState(15);
  const [ pattern, setPattern ] = useState("");
  const [ item, setItem] = useState<Change | null>();

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern);
    if (sort !== undefined) setSort(sort);
    if (skip !== undefined) setSkip(skip);
    if (take !== undefined) setTake(take);
    setLoading(true);
  }, [ setLoading, setPattern, setSort, setSkip, setTake ]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await HistoryAccess.getAll(pattern, sort, skip, take);
          setData(data);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [ loading, pattern, sort, skip, take, setData, setLoaded, setLoading, onError ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [ loaded, onLoaded ]);

  const handleItemCancel = useCallback((): void => {
    setItem(null);
  }, [ setItem ]);

  const handleItemSelect = useCallback((model: Change): void => {
    setItem(model);
  }, [ setItem ]);

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern);
    }
  }, [ pattern, reload ]);

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take);
  }, [ reload ]);

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined);
  }, [ reload ]);

  return (
    <Loader loading={!loaded && loading}>
      <HistoryGrid data={data}
                   onItemSelect={handleItemSelect}
                   onItemSearch={handleItemSearch}
                   onPageChange={handlePageChange}
                   onSortChange={handleSortChange} />
      {item &&
      <Drawer>
        <Div layout="grid 12">
          <Div>
            <HistoryItem item={item}
                         lock={new Lock()}
                         readonly={true} />

          </Div>
          <Div>
            <Div layout="flex">
              <Div layout="fill" />
              <Div layout="fir">
                <Button primary={true} look="outline" className="action" onClick={handleItemCancel}>
                  {formatMessage(TXT("action.close"))}
                </Button>
              </Div>
            </Div>
          </Div>
        </Div>
      </Drawer>
      }
    </Loader>
  );
}

function pathBuilder(): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: TXT("page.history"),
  });

  return pathItems;
}

export const History = withPage(HistoryPage, pathBuilder);
History.displayName = "HistoryPage";
