import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Measure, { ContentRect } from 'react-measure'
import { Chunk } from '../../data'
import { ButtonGroup, FieldSet, Form } from '../../gears'
import { DateBox, TextBox, TimeBox, ToggleBox } from '../../gears/inputs'
import { length, required } from '../../gears/validators'
import { ResolvedSchedule, Schedule, ScheduleCreateModel } from '../data/models'
import { Button, Dash, Div, formatOrderBy, Grid, GridColumn, IntlText, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, TXT } from '../gears'
import { useDelay, useField, useFormatCode, useFormatDateRange, useFormatDays, useFormatTimeRange, useWindowSize } from '../hooks'
import { GridViewProps, ItemViewProps } from './types'

export interface ScheduleItemProps extends ItemViewProps<Schedule, ScheduleCreateModel> {
}

export function ScheduleItem(props: ScheduleItemProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onDelete, onEdit, onFree } = props;

  const resource = item.resource;

  const [ name, setName ] = useField<string | null>(item.name);

  const [ effectiveFromDate, setEffectiveFromDate ] = useField<string | null>(item.validFrom);
  const [ effectiveTillDate, setEffectiveTillDate ] = useField<string | null>(item.validTill);

  const [ effectiveFromTime, setEffectiveFromTime ] = useField<string | null>(item.validFromTime);
  const [ effectiveTillTime, setEffectiveTillTime ] = useField<string | null>(item.validTillTime);

  const [ days, setDays] = useField(item.validDays);

  const getItem = useCallback((): ScheduleCreateModel => {
    const item = new ScheduleCreateModel();
    item.name = name || "";
    item.validFrom = effectiveFromDate || "";
    item.validTill = effectiveTillDate || "";
    item.validFromTime = effectiveFromTime || "";
    item.validTillTime = effectiveTillTime || "";
    item.validDays = days;
    item.resource = resource;

    return item;
  }, [
    name, effectiveFromDate, effectiveTillDate, effectiveFromTime, effectiveTillTime, days, resource,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const item = getItem();
      await onSubmit(item);
    }
  }, [ getItem, onSubmit ]);

  return (
    <Form lock={lock}
          loaded={true}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12 6@lg">
              <Div>
                <Div layout="grid 12 6@md">
                  <Div>
                    <TextBox label={TXT("label.name")}
                             fill={true}
                             name="name"
                             value={name}
                             validators={[ required, value => length(value, 3, 256) ]}
                             onChange={setName} />
                  </Div>
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12">
                  <Div>
                    <Div layout="flex">
                      <Div layout="fill">
                        <DateBox label={TXT("label.effectivePeriod")}
                                 fill={true}
                                 zone="UTC"
                                 name="effectiveFromDate"
                                 value={effectiveFromDate}
                                 validators={[ required ]}
                                 onChange={setEffectiveFromDate} />
                      </Div>
                      <Div layout="fit"><Dash /></Div>
                      <Div layout="fill">
                        <DateBox label=" "
                                 fill={true}
                                 zone="UTC"
                                 name="effectiveTillDate"
                                 value={effectiveTillDate}
                                 validators={[ required ]}
                                 onChange={setEffectiveTillDate} />
                      </Div>
                    </Div>
                  </Div>
                  <Div>
                    <Div layout="flex">
                      <Div layout="fill">
                        <TimeBox label={TXT("label.effectiveTime")}
                                 fill={true}
                                 name="effectiveFromTime"
                                 value={effectiveFromTime}
                                 validators={[ required ]}
                                 onChange={setEffectiveFromTime} />
                      </Div>
                      <Div layout="fit"><Dash /></Div>
                      <Div layout="fill">
                        <TimeBox label=" "
                                 fill={true}
                                 name="effectiveTillTime"
                                 validators={[ required ]}
                                 value={effectiveTillTime}
                                 onChange={setEffectiveTillTime} />
                      </Div>
                    </Div>
                  </Div>
                  <Div>
                    <WeekDays label={TXT("label.effectiveDays")} name="days" days={days} onChange={setDays} />
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
      </Div>
    </Form>
  );
}

export interface ScheduleGridProps extends GridViewProps<ResolvedSchedule> {
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function ScheduleGrid(props: ScheduleGridProps): ReactElement {
  const { data, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props;

  const formatCode = useFormatCode();
  const formatDateRange = useFormatDateRange();
  const formatTimeRange = useFormatTimeRange();
  const formatDays = useFormatDays();

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null);
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        code: item.code,
        name: item.name,
        effectivePeriod: formatDateRange(item.validFrom, item.validTill),
        effectiveTime: formatTimeRange(item.validFromTime, item.validTillTime),
        effectiveDays: formatDays(item.validDays),
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatCode, formatDateRange, formatTimeRange, formatDays, setProcessedData ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [ delayedPattern, onItemSearch ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [ data.data, onItemSelect ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);
      replaceSortOrder(sortOrder, "code", "code");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [ onSortChange ]);

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT("label.search")}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              <FormattedMessage {...TXT("action.create")} />
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={`${width}px`}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.code")} field="code" width="92px" locked={true} />
              <GridColumn title={TXT("label.name")} field="name" width={`${Math.max(width - 92 - 192 * 3, 256)}px`} />
              <GridColumn title={TXT("label.effectivePeriod")} field="effectivePeriod" width="192px" sortable={false} />
              <GridColumn title={TXT("label.effectiveTime")} field="effectiveTime" width="192px" sortable={false} />
              <GridColumn title={TXT("label.effectiveDays")} field="effectiveDays" width="192px" sortable={false} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}

export interface WeekDaysProps {
  label: IntlText;
  name: string;
  days: string[];
  onChange: (value: string[]) => void;
}

export function WeekDays(props: WeekDaysProps): ReactElement {
  const { label, name, days, onChange } = props;

  const [ dayList, setDayList ] = useState([
    { code: "mon", label: TXT("week.days.mon"), selected: false },
    { code: "tue", label: TXT("week.days.tue"), selected: false },
    { code: "wed", label: TXT("week.days.wed"), selected: false },
    { code: "thu", label: TXT("week.days.thu"), selected: false },
    { code: "fri", label: TXT("week.days.fri"), selected: false },
    { code: "sat", label: TXT("week.days.sat"), selected: false },
    { code: "sun", label: TXT("week.days.sun"), selected: false },
  ]);

  useEffect(() => {
    setDayList(prev => {
      const selectedDayList = [ ...prev ];
      for (const code of days) {
        const selectedDayItem = selectedDayList.filter(day => day.code === code)[0];
        if (selectedDayItem) {
          selectedDayItem.selected = true;
        }
      }
      return selectedDayList;
    });
  }, [ days, setDayList ]);

  const handleChange = useCallback((index: number, value: boolean): void => {
    dayList[index].selected = value;
    onChange(dayList.filter(dayItem => dayItem.selected).map(dayItem => dayItem.code));
  }, [ dayList, onChange ]);

  return (
    <ButtonGroup label={label}>
      {dayList.map((day, index) =>
        <ToggleBox key={index}
                   primary={true}
                   label={day.label}
                   name={`${name}-${day.code}`}
                   value={day.selected}
                   onChange={value => handleChange(index, value)} />)}
    </ButtonGroup>
  );
}
