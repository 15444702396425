import React, { PureComponent, ReactNode } from "react";

interface NavMenuProps {
}

interface NavMenuState {
}

export class NavMenu extends PureComponent<NavMenuProps, NavMenuState> {
  public constructor(props: NavMenuProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { children } = this.props;

    return (
      <ul>{children}</ul>
    );
  }
}
