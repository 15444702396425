import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { Chunk } from '../../data'
import { FieldSet, Form, FormContext, Tooltip } from '../../gears'
import { ComboBox, NumberBox, RichBox, SwitchBox, TextBox } from '../../gears/inputs'
import { length, required } from '../../gears/validators'
import { EMPTY_ID, OrganizationProgram, OrganizationProgramCreateModel, OrganizationProgramSettings, OrganizationProgramType, OrganizationReference, OrganizationType, ResolvedOrganizationProgram } from '../data/models'
import { Button, Div, Grid, GridColumn, IntlText, ItemCreateHandler, ItemSearchHandler, Span, TXT } from '../gears'
import { useDelay, useField, useFormatBoolean, useFormatMessage, useOrganization, useOrganizationProgramSettings, useOrganizations, useWindowSize } from '../hooks'
import { GridViewProps, ItemViewProps } from './types'

interface LimitBoxProps {
  label: IntlText;
  hint?: IntlText;
  help?: IntlText;
  disabled?: boolean;
  name: string;
  value: number | null;
  min?: number | null;
  max?: number | null;
  onChange: (value: number | null) => void;
}

function LimitBox(props: LimitBoxProps): ReactElement {
  const { label, hint, help, disabled, name, value, min, max, onChange } = props

  const form = useContext(FormContext)

  const getDescription = (value: number | null): IntlText | undefined => {
    if (value === null) {
      return TXT('limit.unlimited')
    } else {
      if (value === 0) {
        return TXT('limit.prohibited')
      } else {
        return TXT('limit.limited')
      }
    }
  }

  useEffect(() => {
    if (min !== null && min !== undefined && value !== null && value < min) {
      onChange(min)
    }
    if (max !== null && max !== undefined && value !== null && value > max) {
      onChange(max)
    }
  }, [ value, min, max, onChange ])

  return (
    <Div layout="flex">
      <Div layout="fill">
        <NumberBox label={label}
                   fill={true}
                   hint={getDescription(value) || hint}
                   placeholder={value === null ? '∞' : undefined}
                   disabled={!form.readonly && disabled}
                   name={name}
                   value={value}
                   min={-1}
                   onChange={value => value === null || value < 0 ? onChange(null) : onChange(value)} />
      </Div>
      {help &&
      <Div layout="fit" style={{ paddingTop: '19px', marginLeft: '8px' }}>
        <Tooltip title={help} />
      </Div>
      }
    </Div>
  )
}

export interface CorporateProgramItemProps extends ItemViewProps<OrganizationProgram, OrganizationProgramCreateModel> {
  showProviders?: boolean;
  showArchive?: boolean;
}

export function CorporateProgramItem(props: CorporateProgramItemProps): ReactElement {
  const { item, lock, readonly, showProviders, showArchive, onSubmit, onCancel, onDelete, onEdit, onFree } = props

  const pageSize = 100
  const id = item.id
  const isNew = id === EMPTY_ID

  const formatMessage = useFormatMessage()

  const [ itemSettings ] = useOrganizationProgramSettings(id !== EMPTY_ID ? id : null)
  const [ settings, setSettings ] = useState(new OrganizationProgramSettings())
  useEffect(
    () => {
      if (itemSettings !== null) {
        setSettings(itemSettings)
      }
    },
    [ itemSettings, setSettings ],
  )
  
  const [ providers, getProviders ] = useOrganizations(pageSize, !readonly && isNew && showProviders, useMemo(() => ({ byType: OrganizationType.Provider }), []))
  const [ provider, setProvider ] = useOrganization(item.organization.id !== EMPTY_ID ? item.organization.id : null)

  const [ name, setName ] = useField<string | null>(item.name)
  const [ description, setDescription ] = useField<string | null>(item.description)

  const isActive = item.activated
  const family = item.type

  const [ availablePrimaryPersonCount, setAvailablePrimaryPersonCount ] = useField(settings.availablePrimaryPersonCount)
  const [ availableSecondaryPersonCount, setAvailableSecondaryPersonCount ] = useField(settings.availableSecondaryPersonCount)
  const [ availablePassengerCountPerFlight, setAvailablePassengerCountPerFlight ] = useField(settings.availablePassengerCountPerFlight)

  const [ availableFlightCount, setAvailableFlightCount ] = useField(settings.availableFlightCount)
  const [ availableFlightCountForDomestic, setAvailableFlightCountForDomestic ] = useField(settings.availableFlightCountForDomestic)
  const [ availableFlightCountForInternational, setAvailableFlightCountForInternational ] = useField(settings.availableFlightCountForInternational)

  const [ availableFlightCountPerMonth, setAvailableFlightCountPerMonth ] = useField(settings.availableFlightCountPerMonth)
  const [ availableFlightCountPerMonthForDomestic, setAvailableFlightCountPerMonthForDomestic ] = useField(settings.availableFlightCountPerMonthForDomestic)
  const [ availableFlightCountPerMonthForInternational, setAvailableFlightCountPerMonthForInternational ] = useField(settings.availableFlightCountPerMonthForInternational)
  const [ needsSaveUnusedFlightCount, setNeedsSaveUnusedFlightCount ] = useField(settings.needsSaveUnusedFlightCount)
  const getAvailableFlightCountPerMonthForDomesticMax = (): number | null => {
    return availableFlightCountForDomestic !== null
      ? availableFlightCountForDomestic
      : availableFlightCount
  }
  const getAvailableFlightCountPerMonthForInternationalMax = (): number | null => {
    return availableFlightCountForInternational !== null
      ? availableFlightCountForInternational
      : availableFlightCount
  }
  const getAvailableFlightCountPerMonthMax = (): number | null => {
    return availableFlightCount !== null
      ? availableFlightCount
      : availableFlightCountForDomestic !== null && availableFlightCountForInternational !== null
        ? availableFlightCountForDomestic + availableFlightCountForInternational
        : null
  }

  const [ availableGuestCount, setAvailableGuestCount ] = useField(settings.availableGuestCount)
  const [ availableGuestCountPerFlight, setAvailableGuestCountPerFlight ] = useField(settings.availableGuestCountPerFlight)
  const getAvailableGuestCountMax = (): number | null => {
    return availableFlightCount !== null
      ? availableFlightCount
      : availableFlightCountForDomestic !== null && availableFlightCountForInternational !== null
        ? availableFlightCountForDomestic + availableFlightCountForInternational
        : null
  }
  const getAvailableGuestCountPerFlightMax = (): number | null => {
    return availableGuestCount !== null
      ? availableGuestCount
      : availableFlightCount !== null
        ? availableFlightCount
        : availableFlightCountForDomestic !== null || availableFlightCountForInternational !== null
          ? (availableFlightCountForDomestic || 0) + (availableFlightCountForInternational || 0)
          : null
  }

  const [ availableGuestCountPerMonth, setAvailableGuestCountPerMonth ] = useField(settings.availableGuestCountPerMonth)
  const [ availableGuestCountPerMonthForArrival, setAvailableGuestCountPerMonthForArrival ] = useField(settings.availableGuestCountPerMonthForArrival)
  const [ availableGuestCountPerMonthForDeparture, setAvailableGuestCountPerMonthForDeparture ] = useField(settings.availableGuestCountPerMonthForDeparture)
  const getAvailableGuestCountPerMonthMax = (): number | null => {
    return availableGuestCount !== null
      ? availableGuestCount
      : availableFlightCountPerMonthForDomestic !== null && availableFlightCountPerMonthForInternational !== null
        ? availableFlightCountPerMonthForDomestic + availableFlightCountPerMonthForInternational
        : availableFlightCountPerMonth !== null
          ? availableFlightCountPerMonth
          : availableFlightCountForDomestic !== null && availableFlightCountForInternational !== null
            ? availableFlightCountForDomestic + availableFlightCountForInternational
            : availableFlightCount
  }

  const [ availableServicePersonCount, setAvailableServicePersonCount ] = useField(settings.availableServicePersonCount)

  useEffect(() => {
    if (isNew) {
      setAvailableSecondaryPersonCount(prev => availablePrimaryPersonCount === 0 ? 0 : prev)
    }
  }, [ isNew, availablePrimaryPersonCount, setAvailableSecondaryPersonCount ])

  useEffect(() => {
    if (isNew) {
      setAvailableFlightCountForDomestic(null)
      setAvailableFlightCountForInternational(null)
    }
  }, [ isNew, availableFlightCount, setAvailableFlightCountForDomestic, setAvailableFlightCountForInternational ])

  const getItem = useCallback((): OrganizationProgramCreateModel => {
    const model = new OrganizationProgramCreateModel()
    model.name = name || ''
    model.type = family
    model.organization = provider || new OrganizationReference()
    model.description = description
    model.availablePrimaryPersonCount = availablePrimaryPersonCount
    model.availableSecondaryPersonCount = availableSecondaryPersonCount
    model.availableServicePersonCount = availableServicePersonCount
    model.availableFlightCount = availableFlightCount
    model.availableFlightCountForDomestic = availableFlightCountForDomestic
    model.availableFlightCountForInternational = availableFlightCountForInternational
    model.availableFlightCountPerMonth = availableFlightCountPerMonth
    model.availableFlightCountPerMonthForDomestic = availableFlightCountPerMonthForDomestic
    model.availableFlightCountPerMonthForInternational = availableFlightCountPerMonthForInternational
    model.availablePassengerCountPerFlight = availablePassengerCountPerFlight
    model.availableGuestCount = availableGuestCount
    model.availableGuestCountPerMonth = availableGuestCountPerMonth
    model.availableGuestCountPerMonthForArrival = availableGuestCountPerMonthForArrival
    model.availableGuestCountPerMonthForDeparture = availableGuestCountPerMonthForDeparture
    model.availableGuestCountPerFlight = availableGuestCountPerFlight
    model.needsSaveUnusedFlightCount = needsSaveUnusedFlightCount

    return model
  }, [
    name, family, provider, description,
    availablePrimaryPersonCount, availableSecondaryPersonCount, availableServicePersonCount,
    availableFlightCount, availableFlightCountForDomestic, availableFlightCountForInternational,
    availableFlightCountPerMonth, availableFlightCountPerMonthForDomestic, availableFlightCountPerMonthForInternational,
    availablePassengerCountPerFlight, availableGuestCount,
    availableGuestCountPerMonth, availableGuestCountPerMonthForArrival, availableGuestCountPerMonthForDeparture,
    availableGuestCountPerFlight, needsSaveUnusedFlightCount,
  ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem())
    }
  }, [ getItem, onSubmit ])

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          allowDelete={showArchive || item.activated}
          labelDelete={showArchive ? TXT('action.archive') : TXT('action.deactivate')}
          confirm={showArchive ? formatMessage(TXT('prompt.archiveProgram.message')) : formatMessage(TXT('prompt.deactivateProgram.message'))}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12 3@lg">
              {showProviders &&
              <Div>
                <ComboBox label={TXT('label.provider')}
                          disabled={!isNew && !readonly}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="provider"
                          data={providers}
                          value={provider}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setProvider}
                          onFetch={getProviders} />
              </Div>
              }
              <Div>
                <TextBox label={TXT('label.name')}
                         fill={true}
                         name="name"
                         value={name}
                         validators={[ required, value => length(value, 3, 256) ]}
                         onChange={setName} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.description')}>
            <Div layout="grid 12">
              <Div>
                <RichBox fill={true}
                         name="description"
                         value={description}
                         onChange={setDescription} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {item.type === OrganizationProgramType.Individual &&
        <Div>
          <FieldSet label={TXT('label.programPersons')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <LimitBox label={TXT('label.availablePrimaryPersonCount')}
                          help={TXT('help.availablePrimaryPersonCount')}
                          disabled={!isNew && !readonly}
                          name="availablePrimaryPersonCount"
                          value={availablePrimaryPersonCount}
                          onChange={setAvailablePrimaryPersonCount} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availableSecondaryPersonCount')}
                          help={TXT('help.availableSecondaryPersonCount')}
                          disabled={(availablePrimaryPersonCount !== null && availablePrimaryPersonCount === 0) || (!isNew && !readonly)}
                          name={'availableSecondaryPersonCount'}
                          value={availableSecondaryPersonCount}
                          onChange={setAvailableSecondaryPersonCount} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availablePassengerCountPerFlight')}
                          name={'availablePassengerCountPerFlight'}
                          disabled={!isNew && !readonly}
                          value={availablePassengerCountPerFlight}
                          onChange={setAvailablePassengerCountPerFlight} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        <Div>
          <FieldSet label={TXT('label.programFlights')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <LimitBox label={TXT('label.availableFlightCountForDomestic')}
                          disabled={availableFlightCount !== null || (!isNew && !readonly)}
                          name="availableFlightCountForDomestic"
                          value={availableFlightCountForDomestic}
                          onChange={setAvailableFlightCountForDomestic} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availableFlightCountForInternational')}
                          disabled={availableFlightCount !== null || (!isNew && !readonly)}
                          name="availableFlightCountForInternational"
                          value={availableFlightCountForInternational}
                          onChange={setAvailableFlightCountForInternational} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availableFlightCount')}
                          name="availableFlightCount"
                          disabled={!isNew && !readonly}
                          value={availableFlightCount}
                          onChange={setAvailableFlightCount} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.flightsPerMonth')}>
            <Div layout="grid 12">
              <Div>
                <Div layout="grid 12 3@lg">
                  <Div>
                    <LimitBox label={TXT('label.availableFlightCountPerMonthForDomestic')}
                              disabled={availableFlightCountPerMonth !== null || (!isNew && !readonly)}
                              name="availableFlightCountPerMonthForDomestic"
                              value={availableFlightCountPerMonthForDomestic}
                              max={getAvailableFlightCountPerMonthForDomesticMax()}
                              onChange={setAvailableFlightCountPerMonthForDomestic} />
                  </Div>
                  <Div>
                    <LimitBox label={TXT('label.availableFlightCountPerMonthForInternational')}
                              disabled={availableFlightCountPerMonth !== null || (!isNew && !readonly)}
                              name="availableFlightCountPerMonthForInternational"
                              value={availableFlightCountPerMonthForInternational}
                              max={getAvailableFlightCountPerMonthForInternationalMax()}
                              onChange={setAvailableFlightCountPerMonthForInternational} />
                  </Div>
                  <Div>
                    <LimitBox label={TXT('label.availableFlightCountPerMonth')}
                              name="availableFlightCountPerMonth"
                              disabled={!isNew && !readonly}
                              value={availableFlightCountPerMonth}
                              max={getAvailableFlightCountPerMonthMax()}
                              onChange={setAvailableFlightCountPerMonth} />
                  </Div>
                </Div>
              </Div>
              <Div>
                {formatMessage(TXT('label.needsSaveUnusedFlightCount'))}
                {' '}
                <SwitchBox disabled={!isNew && !readonly}
                           name="needsSaveUnusedFlightCount"
                           value={needsSaveUnusedFlightCount}
                           onChange={setNeedsSaveUnusedFlightCount} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.programGuests')}>
            <Div layout="grid 12 3@lg">
              {item.type === OrganizationProgramType.Individual &&
              <Div>
                <LimitBox label={TXT('label.availableGuestCount')}
                          help={TXT('help.availableGuestCount')}
                          disabled={!isNew && !readonly}
                          name="availableGuestCount"
                          value={availableGuestCount}
                          max={getAvailableGuestCountMax()}
                          onChange={setAvailableGuestCount} />
              </Div>
              }
              <Div>
                <LimitBox label={TXT('label.availableGuestCountPerFlight')}
                          disabled={!isNew && !readonly}
                          name={'availableGuestCountPerFlight'}
                          value={availableGuestCountPerFlight}
                          max={getAvailableGuestCountPerFlightMax()}
                          onChange={setAvailableGuestCountPerFlight} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {item.type === OrganizationProgramType.Individual &&
        <Div>
          <FieldSet label={TXT('label.guestsPerMonth')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <LimitBox label={TXT('label.availableGuestCountPerMonthForArrival')}
                          disabled={availableGuestCountPerMonth !== null || (!isNew && !readonly)}
                          name="availableGuestCountPerMonthForArrival"
                          value={availableGuestCountPerMonthForArrival}
                          max={getAvailableGuestCountPerMonthMax()}
                          onChange={setAvailableGuestCountPerMonthForArrival} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availableGuestCountPerMonthForDeparture')}
                          disabled={availableGuestCountPerMonth !== null || (!isNew && !readonly)}
                          name="availableGuestCountPerMonthForDeparture"
                          value={availableGuestCountPerMonthForDeparture}
                          max={getAvailableGuestCountPerMonthMax()}
                          onChange={setAvailableGuestCountPerMonthForDeparture} />
              </Div>
              <Div>
                <LimitBox label={TXT('label.availableGuestCountPerMonth')}
                          disabled={!isNew && !readonly}
                          name="availableGuestCountPerMonth"
                          value={availableGuestCountPerMonth}
                          max={getAvailableGuestCountPerMonthMax()}
                          onChange={setAvailableGuestCountPerMonth} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        {item.type === OrganizationProgramType.Individual &&
        <Div>
          <FieldSet label={TXT('label.servicePersons')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <LimitBox label={TXT('label.availableServicePersonCount')}
                          help={TXT('label.availableServicePersonCount')}
                          disabled={!isNew && !readonly}
                          name="availableServicePersonCount"
                          value={availableServicePersonCount}
                          onChange={setAvailableServicePersonCount} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        {!isNew && !isActive &&
        <Div>
          <Span intent="warning">{formatMessage(TXT('hint.programIsNoActive'))}</Span>
        </Div>
        }
      </Div>
    </Form>
  )
}

export interface CorporateProgramGridProps extends GridViewProps<ResolvedOrganizationProgram> {
  showProviders?: boolean;
  showDescription?: boolean;
  showActive?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function CorporateProgramGrid(props: CorporateProgramGridProps): ReactElement {
  const {
    data,
    showProviders,
    showDescription,
    showActive,
    onItemCreate,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
  } = props

  const formatBoolean = useFormatBoolean()
  const formatMessage = useFormatMessage()

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null)
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk())

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total)
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        name: item.name,
        provider: item.organization.name,
        activated: formatBoolean(item.activated),
        description: item.description,
      })
      setProcessedData(processedData)
    }
  }, [
    data.data, data.skip, data.take, data.total,
    formatBoolean,
    setProcessedData,
  ])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [ delayedPattern, onItemSearch ])

  const [ width, setWidth ] = useState<number>(1)
  const [ , innerWidth ] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [ innerWidth ])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [ setWidth ])

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0]
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem)
    }
  }, [ data.data, onItemSelect ])

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT('label.search')}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              {formatMessage(TXT('action.create'))}
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange}>
              <GridColumn title={TXT('label.name')} field="name" width={256} locked={true} />
              {showProviders &&
              <GridColumn title={TXT('label.provider')} field="provider" fill={true} sortable={false} width={256} />
              }
              {showDescription &&
              <GridColumn title={TXT('label.description')} field="description" fill={true} width={256} />
              }
              {(showActive !== undefined ? showActive : true) &&
              <GridColumn title={TXT('label.active')} field="activated" width={128} locked={true} />
              }
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  )
}
