import { CityBaseV1 } from "./City";
import { CountryBaseV1 } from "./Country";
import { IReference } from "./IReference";

export class AirportReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class AirportBaseV1 extends AirportReferenceV1 {
  public code: string;
  public name: string;
  public zone: string;
  public city: CityBaseV1;
  public country: CountryBaseV1;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
    this.zone = "";
    this.city = new CityBaseV1();
    this.country = new CountryBaseV1();
  }
}

export class AirportV1 extends AirportBaseV1 {
  public constructor() {
    super();
  }
}
