import React, { PureComponent, ReactElement, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface NavMenuItemProps {
  exact?: boolean;
  path: string;
  icon: ReactElement;
  text: IntlMessage;
}

interface NavMenuItemProps {
  children?: null;
}

interface NavMenuItemState {
}

export class NavMenuItem extends PureComponent<NavMenuItemProps, NavMenuItemState> {
  public constructor(props: NavMenuItemProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { exact, path, icon, text } = this.props;

    return (
      <Intl render={intl =>
        <li>
          <NavLink exact={exact} to={path}>
            <span className="icon">{icon}</span>
            <span className="text">{getIntlMessage(intl, text)}</span>
          </NavLink>
        </li>
      } />
    );
  }
}
