import { DateTime } from "luxon";
import * as R from "ramda";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Chunk, Lock } from "../data";
import { Drawer, ID, NavPathItem, Tabs } from "../gears";
import { OrderAccess, OrganizationContractAccess } from "../parts/data/access";
import {
  Flight,
  FlightUpdateModel,
  FreeAccessType,
  isLounge,
  OrderBlockModel,
  OrderCreateModel,
  OrderedFlight,
  OrderFilter,
  OrderPaymentMode,
  OrderPaymentType,
  OrderType,
  OrderUpdateModel,
  Seat,
  SeatUpdateModel,
  ServiceKind,
} from "../parts/data/models";
import { Button, Dialog, Div, Loader, TXT } from "../parts/gears";
import {
  useFormatMessage,
  useResource,
  useResourceSettings,
  useTimer,
} from "../parts/hooks";
import {
  OrderBlockForm,
  OrderControlPanel,
  OrderedAssistanceGrid,
  OrderedFlightGrid,
  OrderedFlightItem,
  OrderedFlightTimeline,
  OrderedRoomItem,
  OrderedRoomTimeline,
} from "../parts/views";
import { PageProps, withPage } from "./withPage";

function ControlPage(props: PageProps): ReactElement {
  const { onLoaded, onError } = props;

  const formatMessage = useFormatMessage();

  const query = new URLSearchParams(useLocation().search);
  const resourceId = query.get("resource");
  const flightDate = query.get("flightDate");
  const orderId = Number(query.get("order")) || undefined;
  const flightId = Number(query.get("flight")) || undefined;
  const seatId = Number(query.get("seat")) || undefined;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [flights, setFlights] = useState<Chunk<Flight>>(new Chunk());
  const [rooms, setRooms] = useState<Chunk<Seat>>(new Chunk());
  const [sort, setSort] = useState("");
  const [pattern, setPattern] = useState("");
  const [filter, setFilter] = useState<OrderFilter>({ resourceId, flightDate });
  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      resourceId: resourceId || null,
      flightDate: flightDate || null,
    }));
  }, [resourceId, flightDate, setFilter]);

  const [resource] = useResource(filter.resourceId);
  const [settings] = useResourceSettings(filter.resourceId);

  const [tabIndex, setTabIndex] = useState(0);
  const [bizToggled, setBizToggled] = useState(false);
  const [resToggled, setResToggled] = useState(false);
  const [freeAccessType, setFreeAccessType] = useState<
    FreeAccessType | undefined
  >(undefined);
  const [showAssistToggled, setShowAssistToggled] = useState(false);
  const [flight, setFlight] = useState<Flight | null>(null);
  const [seat, setSeat] = useState<number | undefined>();
  const [room, setRoom] = useState<Seat | null>(null);
  const [blockModel, setBlockModel] = useState<OrderBlockModel | null>(null);
  const [blockModelSubmitting, setBlockModelSubmitting] = useState(false);

  const reload = useCallback(
    (pattern?: string, sort?: string, filter?: OrderFilter): void => {
      if (pattern !== undefined) setPattern(pattern);
      if (sort !== undefined) setSort(sort);
      if (filter !== undefined) setFilter(filter);
      setLoading(true);
    },
    [setLoading, setPattern, setSort, setFilter]
  );

  useEffect(() => {
    setSeat(seatId);
    reload();
  }, [seatId, setSeat, reload]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (filter.resourceId && filter.flightDate) {
            const from = DateTime.fromISO(filter.flightDate);
            const till = from.plus({ days: 1 });
            const flights = await OrderAccess.getFlights(
              pattern,
              sort,
              undefined,
              undefined,
              filter.resourceId,
              undefined,
              undefined,
              undefined,
              undefined,
              from.toISO(),
              till.toISO(),
              undefined
            );
            setFlights(flights);
            const rooms = await OrderAccess.getSeats(
              pattern,
              sort,
              undefined,
              undefined,
              filter.resourceId,
              ServiceKind.ConferenceRoom,
              undefined,
              undefined,
              undefined,
              from.toISO(),
              till.toISO()
            );
            setRooms(rooms);
            if (seat) {
              setFlight(
                flights.data.filter(
                  (x) =>
                    x.order.id === orderId &&
                    x.id === flightId &&
                    x.seats.some((y) => y.id === seat)
                )[0] || null
              );
            }
          } else {
            setFlights(new Chunk());
            setRooms(new Chunk());
          }
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    pattern,
    sort,
    filter.resourceId,
    filter.flightDate,
    seat,
    orderId,
    flightId,
    setFlight,
    setFlights,
    setRooms,
    setLoaded,
    setLoading,
    onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [loaded, onLoaded]);

  useTimer(reload);

  const handleItemCreate = useCallback(
    (
      type: OrderType.Program | OrderType.Block,
      freeAccess?: FreeAccessType
    ): void => {
      if (type === OrderType.Program) {
        setBizToggled(true);
        setFreeAccessType(freeAccess);
      } else {
        setResToggled(true);
      }
    },
    [setBizToggled, setResToggled, setFreeAccessType]
  );

  const handleItemSelect = useCallback(
    (record: Flight): void => {
      setFlight(record);
    },
    [setFlight]
  );

  const handleSeatSelect = useCallback(
    (record: { flight: Flight; seat: number }): void => {
      setFlight(record.flight);
      if (resource && isLounge(resource.kind)) {
        setSeat(record.seat);
      }
    },
    [resource, setFlight, setSeat]
  );

  const handleRoomSelect = useCallback(
    (record: Seat): void => {
      setRoom(record);
    },
    [setRoom]
  );

  const handleItemFilter = useCallback(
    (newFilter: OrderFilter): void => {
      if (
        !R.equals(
          { resourceId: filter.resourceId, flightDate: filter.flightDate },
          newFilter
        )
      ) {
        reload(undefined, undefined, newFilter);
      }
    },
    [filter.resourceId, filter.flightDate, reload]
  );

  const handleItemSearch = useCallback(
    (newPattern: string): void => {
      if (!R.equals(pattern, newPattern)) {
        reload(newPattern);
      }
    },
    [pattern, reload]
  );

  const handleSortChange = useCallback(
    (sort: string): void => {
      reload(undefined, sort, undefined);
    },
    [reload]
  );

  const handleBlockSubmit = useCallback(
    async (model: OrderBlockModel): Promise<void> => {
      setBlockModel(model);
    },
    [setBlockModel]
  );

  const processBlockSubmit = useCallback(async (): Promise<void> => {
    setBlockModelSubmitting(true);
    try {
      const contract = await OrganizationContractAccess.getOne(
        `${resource!.organization.id}/${resource!.organization.id}/(DEFAULT)`
      );
      const created = await OrderAccess.create(
        new OrderCreateModel({ ...blockModel!, contract })
      );
      await OrderAccess.pay(created.id, {
        mode: OrderPaymentMode.Online,
        type: OrderPaymentType.Free,
        info: blockModel!.paymentInfo,
        voucherSendRequired: false,
        voucherRateRequired: true,
      });
      setBlockModelSubmitting(false);
      setBizToggled(false);
      setResToggled(false);
      setFreeAccessType(undefined);
      setBlockModel(null);
      reload();
    } catch (error) {
      setBlockModelSubmitting(false);
      onError(error);
    }
  }, [
    resource,
    blockModel,
    reload,
    setBizToggled,
    setResToggled,
    setFreeAccessType,
    setBlockModel,
    setBlockModelSubmitting,
    onError,
  ]);

  const dismissBlockSubmit = useCallback(async (): Promise<void> => {
    setBlockModel(null);
  }, [setBlockModel]);

  const handleReload = useCallback(async (): Promise<void> => {
    reload();
  }, [reload]);

  const handleBlockCancel = useCallback(async (): Promise<void> => {
    setBizToggled(false);
    setResToggled(false);
    setFreeAccessType(undefined);
  }, [setBizToggled, setResToggled, setFreeAccessType]);

  const handlePatchFlightSubmit = useCallback(
    async (model: FlightUpdateModel): Promise<void> => {
      try {
        await OrderAccess.updateFlight(flight!.order.id, flight!.id, model);
        setFlight(null);
        setSeat(undefined);
        reload();
      } catch (error) {
        onError(error);
      }
    },
    [flight, reload, setFlight, setSeat, onError]
  );

  const handlePatchFlightCancel = useCallback(async (): Promise<void> => {
    setFlight(null);
    setSeat(undefined);
  }, [setFlight, setSeat]);

  const handleDelete = useCallback(async (): Promise<void> => {
    if (flight) {
      try {
        const updatedFlight = new OrderedFlight();
        updatedFlight.city = flight.flightCity;
        updatedFlight.type = flight.flightType;
        updatedFlight.number = flight.flightNumber;
        updatedFlight.date = flight.flightDate;
        const updateModel = new OrderUpdateModel();
        updateModel.contactName = flight.order.contactName;
        updateModel.contactEmail = flight.order.contactEmail;
        updateModel.contactPhone = flight.order.contactPhone;
        updateModel.description = flight.order.description;
        updateModel.resources = [];
        updateModel.resources.push({
          id: flight.id,
          passengers: flight.seats
            .filter((x) => seat === undefined || x.id === seat)
            .map((x) => ({
              id: x.id,
              return: true,
              forced: null,
              reason: null,
              attachments: null,
            })),
          resource: flight.resource,
          flights: [updatedFlight],
          services: null,
        });
        await OrderAccess.update(flight.order.id, updateModel);
        await OrderAccess.pay(flight.order.id, {
          mode: flight.order.paymentMode || OrderPaymentMode.Online,
          type: flight.order.paymentType || OrderPaymentType.Free,
          info: flight.order.paymentInfo,
          voucherSendRequired: false,
          voucherRateRequired: true,
        });
        setFlight(null);
        setSeat(undefined);
        reload();
      } catch (error) {
        onError(error);
      }
    }
  }, [flight, seat, reload, setFlight, setSeat, onError]);

  const handlePatchRoomSubmit = useCallback(
    async (model: SeatUpdateModel): Promise<void> => {
      try {
        await OrderAccess.updateSeat(
          room!.order.id,
          room!.flight.id,
          room!.id,
          model
        );
        setRoom(null);
        reload();
      } catch (error) {
        onError(error);
      }
    },
    [room, reload, setRoom, onError]
  );

  const handlePatchRoomCancel = useCallback(async (): Promise<void> => {
    setRoom(null);
  }, [setRoom]);

  const toggleShowAssist = useCallback((): void => {
    setShowAssistToggled((prev) => !prev);
  }, [setShowAssistToggled]);

  return (
    <Loader loading={!loaded && loading}>
      <Div layout="grid 12">
        <Div>
          <OrderControlPanel
            capacity={settings ? settings.capacity || undefined : undefined}
            quota={settings ? settings.quota || undefined : undefined}
            filter={filter}
            onItemCreate={handleItemCreate}
            onItemFilter={handleItemFilter}
          />
        </Div>
        {flights.data.length > 0 && rooms.data.length > 0 && (
          <Tabs
            items={[
              { label: TXT("label.resource"), data: "flights" },
              { label: TXT("label.conferenceRooms"), data: "rooms" },
            ]}
            render={(tabItem) => {
              if (tabItem.data === "flights") {
                return (
                  <OrderedFlightTimeline
                    data={flights}
                    date={filter.flightDate || ""}
                    capacity={
                      settings ? settings.capacity || undefined : undefined
                    }
                    quota={settings ? settings.quota || undefined : undefined}
                    onItemSelect={handleSeatSelect}
                  />
                );
              } else {
                return (
                  <OrderedRoomTimeline
                    data={rooms}
                    date={filter.flightDate || ""}
                    onItemSelect={handleRoomSelect}
                  />
                );
              }
            }}
            selected={tabIndex}
            onSelect={setTabIndex}
          />
        )}
        {flights.data.length > 0 && rooms.data.length === 0 && (
          <Div>
            <OrderedFlightTimeline
              data={flights}
              date={filter.flightDate || ""}
              capacity={settings ? settings.capacity || undefined : undefined}
              quota={settings ? settings.quota || undefined : undefined}
              onItemSelect={handleSeatSelect}
            />
          </Div>
        )}
        {flights.data.length === 0 && rooms.data.length > 0 && (
          <Div>
            <OrderedRoomTimeline
              data={rooms}
              date={filter.flightDate || ""}
              onItemSelect={handleRoomSelect}
            />
          </Div>
        )}
        <Div>
          <OrderedFlightGrid
            data={flights}
            onReload={handleReload}
            onItemSearch={handleItemSearch}
            onItemSelect={handleItemSelect}
            onSortChange={handleSortChange}
          />
        </Div>
      </Div>
      {resource && bizToggled && (
        <Drawer>
          <OrderBlockForm
            type={OrderType.Business}
            resource={resource}
            freeAccess={freeAccessType}
            onSubmit={handleBlockSubmit}
            onCancel={handleBlockCancel}
          />
        </Drawer>
      )}
      {resource && resToggled && (
        <Drawer>
          <OrderBlockForm
            type={OrderType.Block}
            resource={resource}
            onSubmit={handleBlockSubmit}
            onCancel={handleBlockCancel}
          />
        </Drawer>
      )}
      {flight && (
        <Drawer>
          <OrderedFlightItem
            item={flight}
            seat={seat}
            lock={new Lock()}
            readonly={false}
            onSubmit={handlePatchFlightSubmit}
            onCancel={handlePatchFlightCancel}
            onDelete={handleDelete}
          />
        </Drawer>
      )}
      {room && (
        <Drawer>
          <OrderedRoomItem
            item={room}
            lock={new Lock()}
            readonly={false}
            onSubmit={handlePatchRoomSubmit}
            onCancel={handlePatchRoomCancel}
          />
        </Drawer>
      )}
      {showAssistToggled && (
        <Drawer>
          <AssistView
            flightDate={filter.flightDate || ""}
            onClose={toggleShowAssist}
            onError={onError}
          />
        </Drawer>
      )}
      {blockModel !== null && (
        <Dialog
          title={TXT("prompt.businessOrder.title")}
          onAccept={processBlockSubmit}
          onCancel={dismissBlockSubmit}
          canAccept={!blockModelSubmitting}
          canCancel={!blockModelSubmitting}
        >
          <p>
            {formatMessage(
              TXT("prompt.businessOrder.message", {
                count: blockModel.passengerCount,
              })
            )}
          </p>
        </Dialog>
      )}
    </Loader>
  );
}

interface AssistViewProps {
  flightDate: string;
  onClose: () => void;
  onError: (error: any) => void;
}

function AssistView(props: AssistViewProps): ReactElement {
  const { flightDate, onClose, onError } = props;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Chunk<Flight>>(new Chunk());
  const [sort, setSort] = useState("");
  const [pattern, setPattern] = useState("");

  const reload = useCallback(
    (pattern?: string, sort?: string): void => {
      if (pattern !== undefined) setPattern(pattern);
      if (sort !== undefined) setSort(sort);
      setLoading(true);
    },
    [setLoading, setPattern, setSort]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (flightDate) {
            const from = DateTime.fromISO(flightDate);
            const till = from.plus({ days: 1 });
            const data = await OrderAccess.getFlights(
              pattern,
              sort,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              from.toISO(),
              till.toISO(),
              false,
              true
            );
            setData(data);
          } else {
            setData(new Chunk());
          }
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    pattern,
    sort,
    flightDate,
    setData,
    setLoaded,
    setLoading,
    onError,
  ]);

  const handleReload = useCallback(async (): Promise<void> => {
    reload();
  }, [reload]);

  const handleItemSearch = useCallback(
    (newPattern: string): void => {
      if (!R.equals(pattern, newPattern)) {
        reload(newPattern);
      }
    },
    [pattern, reload]
  );

  const handleItemSubmit = useCallback(
    async (
      model: FlightUpdateModel,
      orderId: number,
      flightId: number
    ): Promise<void> => {
      try {
        await OrderAccess.updateFlight(orderId, flightId, model);
        reload();
      } catch (error) {
        onError(error);
      }
    },
    [reload, onError]
  );

  const handleSortChange = useCallback(
    (sort: string): void => {
      reload(undefined, sort);
    },
    [reload]
  );

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" icon="close" onClick={onClose} />
          </Div>
        </Div>
      </Div>
      <Div>
        <Loader loading={!loaded && loading}>
          <OrderedAssistanceGrid
            data={data}
            onReload={handleReload}
            onItemSearch={handleItemSearch}
            onItemSubmit={handleItemSubmit}
            onSortChange={handleSortChange}
          />
        </Loader>
      </Div>
    </Div>
  );
}

function pathBuilder(): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: ID("page.control"),
  });

  return pathItems;
}

export const Control = withPage(ControlPage, pathBuilder);
Control.displayName = "ControlPage";
