import { RatedResource } from "./RatedResource";

export enum FlightDirection {
  Any = "Any",
  Domestic = "Domestic",
  International = "International",
}

export enum FlightType {
  Arrival = "Arrival",
  Departure = "Departure",
}

export class FlightSpan {
  public resources: RatedResource[];

  public constructor() {
    this.resources = [];
  }
}
