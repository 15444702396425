import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import {
  CorporateContractV1,
  CorporateContractBaseV1,
  CorporateContractRecord,
  CorporateContractStatisticsV1,
} from "../../../data/models";

export class CorporateContractAccessV1 {
  public static async fetchItem(id: number): Promise<CorporateContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<CorporateContractV1>("CorporateContract", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                programId?: number, providerId?: number, agencyId?: number, contractId?: number, active?: boolean): Promise<Chunk<CorporateContractBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<CorporateContractBaseV1>>("CorporateContract", {}, {
        pattern,
        orderBy,
        skip,
        take,
        programId,
        providerId,
        agencyId,
        contractId,
        active,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                programId?: number, providerId?: number, agencyId?: number, contractId?: number): Promise<Chunk<CorporateContractRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<CorporateContractRecord>>("CorporateContract/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        programId,
        providerId,
        agencyId,
        contractId,
      });
  }

  public static async fetchStatistics(id: number): Promise<CorporateContractStatisticsV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<CorporateContractStatisticsV1>("CorporateContract/statistics", { id });
  }

  public static async create(item: CorporateContractV1): Promise<CorporateContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create<CorporateContractV1>("CorporateContract", item);
  }

  public static async update(id: number, item: CorporateContractV1): Promise<CorporateContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<CorporateContractV1>("CorporateContract", item, { id });
  }

  public static async comment(id: number, item: CorporateContractV1): Promise<CorporateContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .modify<CorporateContractV1>("CorporateContract", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete("CorporateContract", { id });
  }

  public static async isExist(programId: number, cardNumber: string): Promise<boolean> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<boolean>("CorporateContract/exists", { programId, cardNumber });
  }

  public static async lock(id: string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("CorporateContract/lock", { id }, { action })
  }
}
