import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import { MediaBaseV1, MediaKindV1, ServiceV1, ServiceBaseV1, ServiceRecord } from "../../../data/models";

export class ServiceAccessV1 {
  public static async fetchItem(id: number): Promise<ServiceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<ServiceV1>("Service", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                resourceId?: number, includeDefault?: boolean, includeTransfers?: boolean): Promise<Chunk<ServiceBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ServiceBaseV1>>("Service", {}, {
        pattern,
        orderBy,
        skip,
        take,
        resourceId,
        includeDefault,
        includeTransfers,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                resourceId?: number, includeDefault?: boolean, includeTransfers?: boolean): Promise<Chunk<ServiceRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ServiceRecord>>("Service/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        resourceId,
        includeDefault,
        includeTransfers,
      });
  }

  public static async create(item: ServiceV1): Promise<ServiceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create<ServiceV1>("Service", item);
  }

  public static async update(id: number, item: ServiceV1): Promise<ServiceV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update<ServiceV1>("Service", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete("Service", { id });
  }

  public static async fetchMedia(id: number, kind: MediaKindV1): Promise<MediaBaseV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<MediaBaseV1[]>("Media", {}, { serviceId: id, kind });
  }

  public static async updateMedia(id: number, updated: MediaBaseV1[], kind: MediaKindV1): Promise<MediaBaseV1[]> {
    const result: MediaBaseV1[] = [];

    const existed = await this.fetchMedia(id, kind);
    const removed = existed.filter(item => !updated.map(image => image.id).some(id => item.id === id));

    for (const image of updated) {
      if (image.id !== 0) {
        const updatedImage = await Client.instance(await Apis.getDataApi()).update("Media", image, { id: image.id });
        result.push(updatedImage);
      } else {
        const createdImage = await Client.instance(await Apis.getDataApi()).create("Media/service", image, { serviceId: id });
        result.push(createdImage);
      }
    }

    for (const image of removed) {
      if (image.id !== 0) {
        await Client.instance(await Apis.getDataApi()).delete("Media", { id: image.id });
      }
    }

    return result;
  }

  public static async lock(id: number, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Service/lock", { id }, { action })
  }
}
