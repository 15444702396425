import React, { PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface FieldSetProps {
  label?: IntlMessage;
}

interface FieldSetProps {
  children?: ReactNode;
}

interface FieldSetState {
}

export class FieldSet extends PureComponent<FieldSetProps, FieldSetState> {
  public constructor(props: FieldSetProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { label, children } = this.props;

    return (
      <Intl render={intl =>
        <fieldset className="g-form-fieldset">
          <legend>{label && getIntlMessage(intl, label)}</legend>
          <div>
            {children}
          </div>
        </fieldset>
      } />
    );
  }
}
