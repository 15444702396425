import { CurrencyBase } from './Currency'
import { PassengerCategory, PassengerReference } from './Passenger'
import { RateBase, RateCost, RateFine } from './Rate'
import { SeatStatus } from './SeatStatus'

export class RatedPassenger {
  public id: number;
  public passenger: PassengerReference;
  public category: PassengerCategory;
  public rate: RateBase | null;
  public base: RateCost | null;
  public cost: RateCost | null;
  public fine: RateFine | null;
  public seat: number | null;
  public price: number | null;
  public penalty: number | null;
  public discountRatio: number | null;
  public confirmedAt: string | null;
  public expectedFrom: string;
  public expectedTill: string;
  public visitedFrom: string | null;
  public visitedTill: string | null;
  public status: SeatStatus;
  public statusText: string | null;
  public referenceCurrency: CurrencyBase;
  public effectiveCurrency: CurrencyBase;

  public constructor() {
    this.id = 0;
    this.passenger = new PassengerReference();
    this.category = PassengerCategory.Adult;
    this.rate = null;
    this.base = null;
    this.cost = null;
    this.fine = null;
    this.seat = null;
    this.price = null;
    this.penalty = null;
    this.discountRatio = null;
    this.confirmedAt = null;
    this.expectedFrom = "";
    this.expectedTill = "";
    this.visitedFrom = null;
    this.visitedTill = null;
    this.status = SeatStatus.Requested;
    this.statusText = null;
    this.referenceCurrency = new CurrencyBase();
    this.effectiveCurrency = new CurrencyBase();
  }
}
