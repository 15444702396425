import { Apis, Chunk, Client } from "../../../data";
import { AirportV1, AirportBaseV1, TerminalBaseV1 } from "../../../data/models";

export class AirportAccessV1 {
  public static async fetchItem(id: number): Promise<AirportV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<AirportV1>("Airport", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<AirportBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<AirportBaseV1>>("Airport", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }

  public static async fetchTerminals(code: number | string): Promise<TerminalBaseV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<TerminalBaseV1[]>("Airport", { code, terminals: "terminals" });
  }
}
