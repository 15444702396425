import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chunk } from '../../data'
import { OrganizationContractAccess } from '../data/access'
import { OrganizationContractFilter, OrganizationProgramCard, ResolvedOrganizationContract, Resource, ServiceKind } from '../data/models'
import { FetchList } from './types'

export function useOrganizationContracts(pageSize?: number, prefetch?: boolean, filter?: OrganizationContractFilter): [ Chunk<ResolvedOrganizationContract>, FetchList ] {
  const intl = useIntl()
  const [ data, setData ] = useState<Chunk<ResolvedOrganizationContract>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await OrganizationContractAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter)
      for (const item of data.data) {
        if (item.number === '(DEFAULT)') {
          item.number = `${intl.formatMessage({ id: 'label.defaultContract' })}`
        }
      }
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, filter, intl, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganizationContract(contractId: string | null): [ ResolvedOrganizationContract | null, Dispatch<SetStateAction<ResolvedOrganizationContract | null>> ] {
  const intl = useIntl()
  const [ item, setItem ] = useState<ResolvedOrganizationContract | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (contractId) {
          const item = await OrganizationContractAccess.getOne(contractId)
          if (item.number === '(DEFAULT)') {
            item.number = `${intl.formatMessage({ id: 'label.defaultContract' })}`
          }
          setItem(item)
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ intl, contractId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationContractBalance(contractId: string | null): [ number | null, Dispatch<SetStateAction<number | null>> ] {
  const [ item, setItem ] = useState<number | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (contractId) {
          setItem(await OrganizationContractAccess.getOneBalance(contractId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ contractId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationContractOverdraft(contractId: string | null): [ number | null, Dispatch<SetStateAction<number | null>> ] {
  const [ item, setItem ] = useState<number | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (contractId) {
          setItem(await OrganizationContractAccess.getOneOverdraft(contractId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ contractId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationContractCards(contractId: string | null, pageSize?: number, prefetch?: boolean,
                                             isAuthorized?: boolean,
): [ Chunk<OrganizationProgramCard>, FetchList ] {
  const [ data, setData ] = useState<Chunk<OrganizationProgramCard>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (contractId) {
        const data = await OrganizationContractAccess.getOneCards(contractId, pattern, undefined, skip || 0, take || pageSize, isAuthorized)
        setData(data)
      } else {
        setData(new Chunk())
      }
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, contractId, isAuthorized, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganizationContractResources(contractId: string | null, pageSize?: number, prefetch?: boolean,
                                                 byKind?: ServiceKind | ServiceKind[], byAirportId?: string,
): [ Chunk<Resource>, FetchList ] {
  const [ data, setData ] = useState<Chunk<Resource>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (contractId) {
        const data = await OrganizationContractAccess.getOneResources(contractId, pattern, undefined, skip || 0, take || pageSize, byKind, byAirportId)
        setData(data)
      } else {
        setData(new Chunk())
      }
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, contractId, byKind, byAirportId, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}
