import * as KendoDialogs from "@progress/kendo-react-dialogs"
import React, { PureComponent, ReactNode } from "react";
import { Button } from "./Button";
import { getIntlMessage, ID, Intl, IntlDescriptor, IntlMessage } from './Intl'

interface ConfirmProps {
  title: IntlMessage
  message?: IntlMessage;
  locked?: boolean;
  labelConfirm?: string | IntlDescriptor;
  canConfirm?: boolean;
  onConfirm: (confirmed: boolean) => void;
}

interface ConfirmState {
}

export class Confirm extends PureComponent<ConfirmProps, ConfirmState> {
  public constructor(props: ConfirmProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { title, message, locked, labelConfirm, canConfirm, children, onConfirm } = this.props;

    return (
      <Intl render={intl =>
        <KendoDialogs.Dialog className="g-confirm" title={getIntlMessage(intl, title)} onClose={() => onConfirm(false)}>
          {message ? <p>{getIntlMessage(intl, message)}</p> : <React.Fragment>{children}</React.Fragment>}
          <KendoDialogs.DialogActionsBar>
            <Button disabled={locked} look="outline" text={ID("action.cancel")} type="button" onClick={() => onConfirm(false)} />
            <Button disabled={locked || (canConfirm !== undefined && !canConfirm)} primary={true} text={labelConfirm || ID("action.confirm")} type="button" onClick={() => onConfirm(true)} />
          </KendoDialogs.DialogActionsBar>
        </KendoDialogs.Dialog>
      } />
    );
  }
}
