import { Apis, Chunk, Client } from "../../../data";
import { EventDeleteModel, EventBase, EventType } from "../models";

export class EventAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, byType?: EventType): Promise<Chunk<EventBase>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<Chunk<EventBase>>(`events`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        byType,
      });
  }

  public static async getCount(byType?: EventType): Promise<number> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get<number>(`events/count`, {}, { byType });
  }

  public static async resolve(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`events/${id}`);
  }

  public static async clear(model: EventDeleteModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .modify(`events`, model);
  }
}
