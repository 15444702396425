import * as KendoButtons from "@progress/kendo-react-buttons";
import { classNames } from "@progress/kendo-react-common";
import React, { MouseEvent, PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface ButtonProps {
  disabled?: boolean;
  primary?: boolean;
  className?: string;
  intent?: "info" | "success" | "warning" | "error";
  look?: "bare" | "flat" | "outline";
  icon?: string;
  text?: IntlMessage
  items?: ButtonItem[];
  type?: "button" | "submit" | "reset";
  onClick?: (item?: ButtonItem, index?: number) => void;
}

interface ButtonProps {
  children?: null;
}

interface ButtonState {
}

export interface ButtonItem  {
  key?: string | number;
  icon?: string;
  text?: IntlMessage;
}

export class Button extends PureComponent<ButtonProps, ButtonState> {
  public constructor(props: ButtonProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { disabled, className, primary, intent, look, icon, type, text, items, onClick } = this.props;

    return (
      <Intl render={intl => {
        if (items) {
          return (
            <KendoButtons.DropDownButton disabled={disabled}
                                         ref={this.setupDropDownButton}
                                         className={classNames(className, intent ? `k-text-${intent}` : undefined)}
                                         popupSettings={{ popupClass: "g-button-popup" }}
                                         primary={primary}
                                         look={look}
                                         icon={icon}
                                         text={text && getIntlMessage(intl, text)}
                                         items={items && items.map(item => ({ key: item.key, icon: item.icon, text: item.text ? getIntlMessage(intl, item.text) : undefined }))} onItemClick={onClick && this.handelItemClick} />
          );
        } else {
          return (
            <KendoButtons.Button disabled={disabled}
                                 className={classNames(className, intent ? `k-text-${intent}` : undefined)}
                                 primary={primary}
                                 look={look}
                                 icon={icon}
                                 type={type || "button"}
                                 onClick={onClick && this.handleClick}>{text && getIntlMessage(intl, text)}</KendoButtons.Button>
          );
        }
      }} />
    );
  }

  private readonly handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    event.preventDefault();
  };

  private readonly handelItemClick = (event: KendoButtons.DropDownButtonItemClickEvent): void => {
    if (this.props.onClick) {
      this.props.onClick(event.item, event.itemIndex);
    }
  };

  private readonly setupDropDownButton = (button: KendoButtons.DropDownButton | null): void => {
    if (button && button.element) {
      button.element.type = "button";
    }
  }
}
