import { OrderedFlight } from './OrderedFlight'
import { OrderedService } from './OrderedService'
import { ResourceReference } from './Resource'

export class OrderedResource {
  public resource: ResourceReference
  public flights: OrderedFlight[]
  public services: OrderedService[] | null

  public constructor() {
    this.resource = new ResourceReference()
    this.flights = []
    this.services = null
  }
}
