import CryptoJS from 'crypto-js'
import { Identity } from '../../../auth'
import { Apis, Chunk, Client } from '../../../data'
import { Change, DepositOperation, Flight, FlightType, FlightUpdateModel, Order, OrderChangeModel, OrderConfirmModel, OrderCreateModel, OrderPaymentModel, OrderReturn, OrderUpdateModel, Seat, SeatUpdateModel, ServiceKind } from '../models'

export class OrderAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<Order>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get('orders', {}, {
        pattern,
        orderBy,
        skip,
        take,
      })
  }

  public static async getFlights(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                 byResourceId?: string, byKind?: ServiceKind, byType?: FlightType,
                                 byFlightFrom?: string, byFlightTill?: string,
                                 bySeatFrom?: string, bySeatTill?: string,
                                 isLounge?: boolean, isAssistance?: boolean): Promise<Chunk<Flight>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get('orders/flights', {}, {
        pattern,
        orderBy,
        skip,
        take,
        byResourceId,
        byKind,
        byType,
        byFlightFrom,
        byFlightTill,
        bySeatFrom,
        bySeatTill,
        isLounge,
        isAssistance,
      })
  }

  public static async getSeats(pattern?: string, orderBy?: string, skip?: number, take?: number,
                               byResourceId?: string, byKind?: ServiceKind, byType?: FlightType,
                               byFlightFrom?: string, byFlightTill?: string,
                               bySeatFrom?: string, bySeatTill?: string,
                               isLounge?: boolean, isAssistance?: boolean): Promise<Chunk<Seat>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get('orders/seats', {}, {
        pattern,
        orderBy,
        skip,
        take,
        byResourceId,
        byKind,
        byType,
        byFlightFrom,
        byFlightTill,
        bySeatFrom,
        bySeatTill,
        isLounge,
        isAssistance,
      })
  }

  public static async getOne(id: number): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${id}`)
  }
  
  public static async GetOneNo(id: string): Promise<number> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${id}/no`)
      .then((value) => (value as { no: number }).no)
  }

  public static async GetOneId(no: number): Promise<string> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${no}/id`)
      .then((value) => (value as { id: string }).id)
  }

  public static async getOneReturn(id: number): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${id}/return`)
  }

  public static async getOneVoucher(id: number): Promise<Blob> {
    return await Client
      .instance(await Apis.getGatewayV0(), 'blob')
      .get(`orders/${id}/voucher`)
  }

  public static async getOneChanges(id: number): Promise<Chunk<Change>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${id}/changes`)
  }

  public static async getOneOperations(id: number, contractId: string): Promise<Chunk<DepositOperation>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`orders/${id}/operations?contractId=${contractId}`)
  }

  public static async create(model: OrderCreateModel, calc?: boolean): Promise<Order> {
    let id = await Identity.instance()
    let data = { url: `${await Apis.getGatewayV0()}/orders?calc=${calc || false}`, method: 'POST', payload: model }
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), await id.getToken())
    return await Client
      .instance('')
      .create(`query`, {
        message: encrypted.toString(CryptoJS.format.Hex),
        iv: encrypted.iv.toString(CryptoJS.enc.Hex),
        key: encrypted.key.toString(CryptoJS.enc.Hex),
      }, {}, {})
  }

  public static async change(id: number, model: OrderChangeModel, calc?: boolean): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .update(`orders/${id}`, model, {}, { calc })
  }

  public static async update(id: number, model: OrderUpdateModel, calc?: boolean): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${id}`, model, {}, { calc })
  }

  public static async updateFlight(orderId: number, flightId: number, model: FlightUpdateModel): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${orderId}/${flightId}`, model)
  }

  public static async updateSeat(orderId: number, flightId: number, seatId: number, model: SeatUpdateModel): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${orderId}/${flightId}/${seatId}`, model)
  }

  public static async confirm(id: number, model: OrderConfirmModel): Promise<Order> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${id}/confirm`, model)
  }

  public static async force(id: number, confirmed: boolean): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${id}/force`, { confirmed })
  }

  public static async pay(id: number, model: OrderPaymentModel): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${id}/pay`, model)
  }

  public static async delete(id: number): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .delete(`orders/${id}`)
  }

  public static async revert(id: number): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`orders/${id}/revert`, {})
  }

  public static async resend(id: number): Promise<OrderReturn> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .delete(`orders/${id}/voucher`)
  }
}
