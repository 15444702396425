import { classNames } from "@progress/kendo-react-common";
import React, { ReactElement } from "react";
import { CardItemProps } from "./CardItem";
import { GearProps } from "./types";

export interface CardDeckProps extends GearProps<(ReactElement<CardItemProps> | null | undefined | false)[] | ReactElement<CardItemProps> | null | undefined | false> {
}

export function CardDeck(props: CardDeckProps): ReactElement {
  const { className, children } = props;

  return (
    <div className={classNames(className)}>
      {children}
    </div>
  );
}
