import { Apis, Chunk, Client } from '../../../data'
import { OrganizationProgramCard, OrganizationProgramCardCreateModel, OrganizationProgramCardStatistics, OrganizationProgramCardUpdateModel } from '../models'

export class OrganizationProgramCardAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, as?: string, byContractId?: string, byProviderId?: string, byAgencyId?: string, isAuthorized?: boolean, isIndividual?: boolean): Promise<Chunk<OrganizationProgramCard>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get('cards', {}, {
        pattern,
        orderBy,
        skip,
        take,
        as,
        byContractId,
        byProviderId,
        byAgencyId,
        isAuthorized,
        isIndividual,
      })
  }

  public static async getOne(id: string): Promise<OrganizationProgramCard> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`cards/${id}`)
  }

  public static async getOneStatistics(id: string): Promise<OrganizationProgramCardStatistics> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`cards/${id}/statistics`)
  }

  public static async create(model: OrganizationProgramCardCreateModel): Promise<OrganizationProgramCard> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`cards`, model)
  }

  public static async update(id: string, model: OrganizationProgramCardUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`cards/${id}`, model)
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`cards/${id}`)
  }
}
