export class Apis {
  private static dataApiUri?: string;
  private static gatewayV0?: string;
  private static gatewayV1?: string;
  private static storageApiUri?: string;

  public static async getDataApi(): Promise<string> {
    let dataApiUri = "";
    if (Apis.dataApiUri) {
      dataApiUri = Apis.dataApiUri;
    } else {
      const dataApi = await fetch("Configuration/apis")
        .then(response => response.json())
        .then(data => data as Array<{ id: string; uri: string; }>)
        .then(apis => apis.filter(api => api.id === "ResourceServerApi")[0]);
      if (dataApi) {
        dataApiUri = dataApi.uri;
        Apis.dataApiUri = dataApiUri;
      } else {
        throw new ApisError("Configuration error");
      }
    }

    return `${dataApiUri}`;
  }

  public static async getFileApi(): Promise<string> {
    return await Apis.getDataApi();
  }

  public static async getGatewayV0(): Promise<string> {
    let gatewayApiUri = "";
    if (Apis.gatewayV0) {
      gatewayApiUri = Apis.gatewayV0;
    } else {
      const gatewayApi = await fetch("Configuration/apis")
        .then(response => response.json())
        .then(data => data as Array<{ id: string; uri: string; }>)
        .then(apis => apis.filter(api => api.id === "GatewayV0")[0]);
      if (gatewayApi) {
        gatewayApiUri = gatewayApi.uri;
        Apis.gatewayV0 = gatewayApiUri;
      } else {
        throw new ApisError("Configuration error");
      }
    }

    return `${gatewayApiUri}`;
  }

  public static async getGatewayV1(): Promise<string> {
    let gatewayApiUri = "";
    if (Apis.gatewayV1) {
      gatewayApiUri = Apis.gatewayV1;
    } else {
      const gatewayApi = await fetch("Configuration/apis")
        .then(response => response.json())
        .then(data => data as Array<{ id: string; uri: string; }>)
        .then(apis => apis.filter(api => api.id === "GatewayV1")[0]);
      if (gatewayApi) {
        gatewayApiUri = gatewayApi.uri;
        Apis.gatewayV1 = gatewayApiUri;
      } else {
        throw new ApisError("Configuration error");
      }
    }

    return `${gatewayApiUri}`;
  }

  public static async getStorageApi(): Promise<string> {
    let storageApiUri = "";
    if (Apis.storageApiUri) {
      storageApiUri = Apis.storageApiUri;
    } else {
      const storageApi = await fetch("Configuration/apis")
        .then(response => response.json())
        .then(data => data as Array<{ id: string; uri: string; }>)
        .then(apis => apis.filter(api => api.id === "Storage")[0]);
      if (storageApi) {
        storageApiUri = storageApi.uri;
        Apis.storageApiUri = storageApiUri;
      } else {
        throw new ApisError("Configuration error");
      }
    }

    return `${storageApiUri}`;
  }
}

export class ApisError extends Error {
  public constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = ApisError.name;
  }
}
