import { useCallback, useEffect, useState } from "react";
import { Chunk } from "../../data";
import { AccessProgramAccess } from '../data/access'
import { AccessProgram } from '../data/models'
import { FetchList } from "./types";

export function useAccessPrograms(pageSize?: number, prefetch?: boolean): [ Chunk<AccessProgram>, FetchList ] {
  const [ data, setData ] = useState<Chunk<AccessProgram>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await AccessProgramAccess.getAll(pattern, undefined, skip || 0, take || pageSize);
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }, [ pageSize, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ]
}
