import React, { ReactElement } from 'react'
import { Div, getIntlMessage, ID, Intl, IntlMessage } from '../../gears'
import { TextBox } from '../../gears/inputs'
import { OrganizationProgramCardStatistics } from '../data/models'

interface CorporateContractStatisticsProps {
  name: string;
  item: OrganizationProgramCardStatistics;
}

export function CorporateContractStatisticsView(props: Readonly<CorporateContractStatisticsProps>): ReactElement {
  const { item } = props

  const renderLimit = (name: string, label: IntlMessage, count: number, ...limits: Array<number | null>): ReactElement => {

    let limit: number | null = limits[0]
    if (limits.length > 1) {
      if (limits[0] !== null) {
        limit = limits[0]
      } else {
        limit = limits.slice(1).filter(l => l).reduce((p, c) => p ? p + c! : c!, null)
      }
    }

    const text = limit !== null ? limit.toString() : '∞'

    return (
      <Intl render={intl =>
        <TextBox label={label}
                 fill={true}
                 name={`${props.name}-${name}`}
                 value={getIntlMessage(intl, text)} />
      } />
    )
  }

  return (
    <Div layout={'grid 12'}>
      <Div>
        <Div layout={'grid 12 3@lg'}>
          <Div>
            {renderLimit('availableFlightCountForDomestic',
              ID('statistics.availableFlightCountForDomestic'),
              item.committedFlightCountForDomestic,
              item.availableFlightCountForDomestic)}
          </Div>
          <Div>
            {renderLimit('availableFlightCountForInternational',
              ID('statistics.availableFlightCountForInternational'),
              item.committedFlightCountForInternational,
              item.availableFlightCountForInternational)}
          </Div>
          <Div>
            {renderLimit('availableFlightCount',
              ID('statistics.availableFlightCount'),
              item.committedFlightCount,
              item.availableFlightCount,
              item.availableFlightCountForDomestic,
              item.availableFlightCountForInternational)}
          </Div>
        </Div>
      </Div>
      <Div>
        <Div layout={'grid 12 3@lg'}>
          <Div>
            {renderLimit('availableFlightCountInCurrentMonthForDomestic',
              ID('statistics.availableFlightCountInCurrentMonthForDomestic'),
              item.committedFlightCountInCurrentMonthForDomestic,
              item.availableFlightCountInCurrentMonthForDomestic)}
          </Div>
          <Div>
            {renderLimit('availableFlightCountInCurrentMonthForInternational',
              ID('statistics.availableFlightCountInCurrentMonthForInternational'),
              item.committedFlightCountInCurrentMonthForInternational,
              item.availableFlightCountInCurrentMonthForInternational)}
          </Div>
          <Div>
            {renderLimit('availableFlightCountInCurrentMonth',
              ID('statistics.availableFlightCountInCurrentMonth'),
              item.committedFlightCountInCurrentMonth,
              item.availableFlightCountInCurrentMonth,
              item.availableFlightCountInCurrentMonthForDomestic,
              item.availableFlightCountInCurrentMonthForInternational)}
          </Div>
        </Div>
      </Div>
      <Div>
        <Div layout={'grid 12 3@lg'}>
          <Div>
            {renderLimit('availableGuestCountInCurrentMonthForArrival',
              ID('statistics.availableGuestCountInCurrentMonthForArrival'),
              item.committedGuestCountInCurrentMonthForArrival,
              item.availableGuestCountInCurrentMonthForArrival)}
          </Div>
          <Div>
            {renderLimit('availableGuestCountInCurrentMonthForDeparture',
              ID('statistics.availableGuestCountInCurrentMonthForDeparture'),
              item.committedGuestCountInCurrentMonthForDeparture,
              item.availableGuestCountInCurrentMonthForDeparture)}
          </Div>
          <Div>
            {renderLimit('availableGuestCountInCurrentMonth',
              ID('statistics.availableGuestCountInCurrentMonth'),
              item.committedGuestCountInCurrentMonth,
              item.availableGuestCountInCurrentMonth,
              item.availableGuestCountInCurrentMonthForArrival,
              item.availableGuestCountInCurrentMonthForDeparture)}
          </Div>
          <Div>
            {renderLimit('availableGuestCount',
              ID('statistics.availableGuestCount'),
              item.committedGuestCount,
              item.availableGuestCount)}
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
