export class IdentityProfile {
  public name: string;
  public roles: string[];
  public belongsTo: string;
  public givenName: string;
  public familyName: string;
  public middleName: string;
  public email: string;
  public phone: string;

  public constructor(profile?: any) {
    this.name = profile && profile.name ? profile.name : "";
    this.roles = profile && profile.role ? (profile.role instanceof Array ? [ ...profile.role ] : [ profile.role ]) : [];
    this.belongsTo = profile && profile.belongs_to ? profile.belongs_to : "";
    this.givenName = profile && profile.given_name ? profile.given_name : "";
    this.familyName = profile && profile.family_name ? profile.family_name : "";
    this.middleName = profile && profile.middle_name ? profile.middle_name : "";
    this.email = profile && profile.email ? profile.email : "";
    this.phone = profile && profile.phone_number ? profile.phone_number : "";
  }

  public isInRole(...args: string[]): boolean {
    return this.roles.some(role => args.find(arg => arg === role) !== undefined);
  }

  public get fullName(): string {
    const names: string[] = [];
    if (this.familyName) {
      names.push(this.familyName);
    }
    if (this.givenName) {
      names.push(this.givenName);
    }
    if (this.middleName) {
      names.push(this.middleName);
    }

    return names.join(" ");
  }

  public get isSys(): boolean {
    return this.belongsTo === "system";
  }

  public get isAgent(): boolean {
    return this.roles.some(role => role.startsWith("agent."));
  }

  public get isOwner(): boolean {
    return this.roles.some(role => role.startsWith("owner."));
  }
}
