import { classNames } from "@progress/kendo-react-common";
import React, { ReactElement } from "react";
import { GearProps } from "./types";

export interface LoaderProps extends GearProps {
  loading: boolean;
}

export function Loader(props: LoaderProps): ReactElement {
  const { children, className, loading } = props;

  if (loading) {
    return (
      <div className={classNames("g-loader", "k-loading-mask", className)}>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}
