import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Measure, { ContentRect } from 'react-measure'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../auth'
import { Chunk } from '../../data'
import { TextBox } from '../../gears/inputs'
import { Order, OrderStatus, PassengerCategory, SeatStatus, ServiceKind, ServiceType } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, Span, TXT } from '../gears'
import { useCalcOrderStatus, useDelay, useFormatCode, useFormatCost, useFormatDate, useFormatPassenger, useOrderTypes, useServiceTypes, useWindowSize } from '../hooks'
import { GridViewProps } from './types'

export interface OrderGridProps extends GridViewProps<Order & { airport: string; seller: string}> {
  showProviders?: boolean;
  showResources?: boolean;
  showAirports?: boolean;
  showTypes?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function OrderGrid(props: OrderGridProps): ReactElement {
  const { data, showResources, showAirports, showTypes, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props;

  const formatCode = useFormatCode();
  const formatCost = useFormatCost();
  const formatDate = useFormatDate();
  const formatPassenger = useFormatPassenger();
  const formatStatus = useCalcOrderStatus();
  const [, formatServiceType] = useServiceTypes()
  const [, formatType] = useOrderTypes();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(null);
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());

  const auth = useContext(AuthContext);

  useEffect(() => {
    (async (): Promise<void> => {
      const processedData = new Chunk<any>([], data.skip, data.take, data.total);

      for (const item of data.data) {
        const flight = item.spans.flatMap(span => span.resources)[0];
        const ratedPassengers = flight.passengers.filter((ratedPassenger) => ratedPassenger.status !== SeatStatus.Cancelled && ratedPassenger.status !== SeatStatus.Returned && ratedPassenger.status !== SeatStatus.Rejected)
        const adultCount = ratedPassengers.filter((ratedPassenger) => ratedPassenger.category === PassengerCategory.Adult).length
        const childCount = ratedPassengers.filter((ratedPassenger) => ratedPassenger.category === PassengerCategory.Child).length
        const infantCount = ratedPassengers.filter((ratedPassenger) => ratedPassenger.category === PassengerCategory.Infant).length
        let passengerCount = `${adultCount}`
        if (childCount + infantCount > 0) {
          passengerCount += ` + ${childCount}`
          if (infantCount > 0) {
            passengerCount += ` + ${infantCount}`
          }
        }

        let serviceType = flight.flights[0].type
        if (serviceType === ServiceType.Arrival && flight.flights[1]?.type === ServiceType.Departure) {
          serviceType = ServiceType.Transit
        }

        processedData.data.push({
          id: item.id,
          code: formatCode(item.id, 6),
          status: formatStatus(item),
          type: formatType(item.type),
          serviceType: formatServiceType(serviceType),
          flightDate: flight ? formatDate(flight.flights[0].date, true, flight.flights[0].zone) : '',
          passenger: formatPassenger(item.passengers[0]),
          passengerCount: passengerCount,
          value: formatCost(item.total, item.currency.code),
          resource: flight.resource.kind !== ServiceKind.VirtualLounge || flight.services === null || flight.services.length === 0
            ? flight.resource.name
            : flight.services[0].service.name,
          airport: item.airport,
          seller: item.seller,
        });
      }
      setProcessedData(processedData);
    })()
  }, [auth, data.data, data.skip, data.take, data.total, formatCode, formatStatus, formatType, formatDate, formatCost, formatPassenger, formatServiceType, setProcessedData]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [setWidth]);

  const handleItemSelect = useCallback((item: { id: number }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [data.data, onItemSelect]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);
      replaceSortOrder(sortOrder, "code", "id");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [onSortChange]);

  const statusRenderer = useCallback((dataItem: any): ReactElement => {
    const item = data.data.filter(i => i.id === dataItem.id)[0];
    if (!item) return (<span />);

    let intent: 'success' | 'warning' | 'danger' | undefined = undefined
    switch (item.status) {
      case OrderStatus.Rated:
        break;
      case OrderStatus.Created:
        intent = 'warning'
        break;
      case OrderStatus.Confirmed:
        intent = 'success'
        break;
      case OrderStatus.Paid:
        intent = 'warning'
        break;
      case OrderStatus.Forced:
        intent = 'warning'
        break;
      case OrderStatus.Rejected:
        intent = 'danger'
        break;
      case OrderStatus.Completed:
        intent = 'success'
        break;
      case OrderStatus.Cancelled:
        intent = 'danger'
        break;
    }

    return (
      <Link to={`/orders/${item.id}`} style={{ cursor: 'text' }}>
        <Span className="k-icon k-i-circle" intent={intent} />
        <span className="">{formatStatus(item)}</span>
      </Link>
    );
  }, [data, formatStatus]);

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
        <Div>
          <Div layout="flex">
            {onItemSearch &&
              <Div layout="fill">
                <TextBox placeholder={TXT("label.search")}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern} />
              </Div>
            }
            {onItemCreate &&
              <Div layout="fit">
                <Button primary={true} className="action" onClick={onItemCreate}>
                  <FormattedMessage {...TXT("action.create")} />
                </Button>
              </Div>
            }
          </Div>
        </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.id")} field="code" width={92} locked={true} />
              <GridColumn title={TXT("label.status")} field="status" width={144} itemRenderer={statusRenderer} />
              <GridColumn title={TXT("label.seller")} field="seller" width={256} sortable={false} />
              <GridColumn title={TXT("label.serviceType")} field="serviceType" sortable={false} width={128} />
              {showResources &&
                <GridColumn title={TXT("label.serviceName")} field="resource" sortable={false} width={256} />
              }
              {showAirports &&
                <GridColumn title={TXT("label.airport")} field="airport" sortable={false} width={192} />
              }
              {showTypes &&
                <GridColumn title={TXT("label.orderType")} field="type" width={128} />
              }
              <GridColumn title={TXT("label.flightDate")} field="flightDate" sortable={false} width={192} />
              <GridColumn title={TXT("label.passengers")} field="passenger" sortable={false} width={256} fill={true} />
              <GridColumn title={TXT("label.passengerCount")} field="passengerCount" sortable={false} width={128} />
              <GridColumn title={TXT("label.cost")} field="value" sortable={false} width={192} locked={true} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}
