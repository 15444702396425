import { Flight } from "./Flight";
import { Order } from "./Order";
import { PassengerReference } from "./Passenger";
import { ResourceReference } from "./Resource";
import { ServiceReference } from "./Service";

export class Seat {
  public id: number;
  public flight: Flight;
  public order: Order;
  public resource: ResourceReference;
  public passenger: PassengerReference | null;
  public service: ServiceReference | null;
  public expectedFrom: string;
  public expectedTill: string;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.flight = new Flight();
    this.order = new Order();
    this.resource = new ResourceReference();
    this.passenger = null;
    this.service = null;
    this.expectedFrom = "";
    this.expectedTill = "";
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}

export class SeatUpdateModel {
  public id: number;
  public familyName: string | null;
  public givenName: string | null;
  public middleName: string | null;
  public dateOfBirth: string | null;
  public ticket: string | null;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.familyName = null;
    this.givenName = null;
    this.middleName = null;
    this.dateOfBirth = null;
    this.ticket = null;
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}
