import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Measure, { ContentRect } from 'react-measure'
import { Icons } from '../../assets'
import { AuthContext } from '../../auth'
import { Chunk, Lock, Pair } from '../../data'
import { FieldSet, Form, FormContext, UploadedFile, Uploader } from '../../gears'
import { ComboBox, RichBox, SwitchBox, TagsBox, TextArea, TextBox } from '../../gears/inputs'
import { email, length, phone, required } from '../../gears/validators'
import { AccessProgram, AccessProgramSettings, Airport, BusinessPassengerSettings, CityBase, CityReference, CurrencySettings, EMPTY_ID, EVoucherSettings, FreeAccessSettings, FreeAccessType, LegalRequisites, LoyaltyProgram, LoyaltyProgramSettings, Organization, OrganizationCreateModel, OrganizationType, PassengerKlass, PaymentRequisites } from '../data/models'
import { Button, Div, Grid, GridColumn, ItemCreateHandler, ItemSearchHandler, TXT } from '../gears'
import { PairData, useAccessPrograms, useAirports, useCities, useCity, useDelay, useField, useFileUrl, useFormatBoolean, useFormatMessage, useFreeAccessTypes, useLoyaltyPrograms, useOrganizationAirports, useOrganizationCurrency, useOrganizationLegalRequisites, useOrganizationPaymentRequisites, useOrganizationTypes, usePassengerKlasses, useWindowSize } from '../hooks'
import { GridViewProps, ItemViewProps } from './types'

interface OrganizationRequisitesViewProps {
  readonly: boolean;
  name: string;
  item: LegalRequisites;
  onChange: (value: LegalRequisites) => void;
}

function OrganizationRequisitesView(props: OrganizationRequisitesViewProps): ReactElement {
  const { readonly, name, item, onChange } = props

  const context = useContext(AuthContext)
  const profile = context.profile

  const handleChange = useCallback((fields: {
    legalAddress?: string | null,
    postalAddress?: string | null,
    taxIdentificationNumber?: string | null,
    taxRegistrationReasonCode?: string | null,
    primaryStateRegistrationNumber?: string | null,
    generalPhone?: string | null,
    supportPhone?: string | null,
    fax?: string | null,
    email?: string | null,
    chiefExecutiveOfficer?: string | null,
    chiefAccountant?: string | null,
  }): void => {
    const value = { ...item, ...fields }
    onChange(value)
  }, [ item, onChange ])

  return (
    <Div layout="grid 12">
      <Div>
        <FieldSet label={TXT('label.requisites')}>
          <Div layout="grid 12 4@lg">
            <Div>
              <TextBox label={TXT('label.taxIdentificationNumber')}
                       fill={true}
                       disabled={!profile.isSys && !readonly}
                       mask="000000000000"
                       name={`${name}-taxIdentificationNumber`}
                       value={item.taxIdentificationNumber}
                       validators={[ required, value => length(value, 10, 12) ]}
                       onChange={taxIdentificationNumber => handleChange({ taxIdentificationNumber })} />
            </Div>
            <Div>
              <TextBox label={TXT('label.taxRegistrationReasonCode')}
                       fill={true}
                       mask="000000000"
                       name={`${name}-taxRegistrationReasonCode`}
                       value={item.taxRegistrationReasonCode}
                       validators={[ value => length(value, 9, 9) ]}
                       onChange={taxRegistrationReasonCode => handleChange({ taxRegistrationReasonCode })} />
            </Div>
            <Div>
              <TextBox label={TXT('label.primaryStateRegistrationNumber')}
                       fill={true}
                       mask="0000000000000"
                       name={`${name}-primaryStateRegistrationNumber`}
                       value={item.primaryStateRegistrationNumber}
                       validators={[ value => length(value, 13, 13) ]}
                       onChange={primaryStateRegistrationNumber => handleChange({ primaryStateRegistrationNumber })} />
            </Div>
          </Div>
        </FieldSet>
      </Div>
      <Div>
        <FieldSet label={TXT('label.contactInformation')}>
          <Div layout="grid 12">
            <Div>
              <Div layout="grid 12 3@lg">
                <Div>
                  <TextBox label={TXT('label.generalPhone')}
                           fill={true}
                           mask="(999) 000-00-00"
                           name={`${name}-generalPhone`}
                           value={item.generalPhone}
                           validators={[ phone ]}
                           onChange={generalPhone => handleChange({ generalPhone })} />
                </Div>
                <Div>
                  <TextBox label={TXT('label.supportPhone')}
                           fill={true}
                           mask="(999) 000-00-00"
                           name={`${name}-supportPhone`}
                           value={item.supportPhone}
                           validators={[ phone ]}
                           onChange={supportPhone => handleChange({ supportPhone })} />
                </Div>
                <Div>
                  <TextBox label={TXT('label.fax')}
                           fill={true}
                           mask="(999) 000-00-00"
                           name={`${name}-fax`}
                           value={item.fax}
                           validators={[ phone ]}
                           onChange={fax => handleChange({ fax })} />
                </Div>
                <Div>
                  <TextBox label={TXT('label.email')}
                           fill={true}
                           name={`${name}-email`}
                           value={item.email}
                           validators={[ email, value => length(value, 1, 100) ]}
                           onChange={email => handleChange({ email })} />
                </Div>
              </Div>
            </Div>
            <Div>
              <Div layout="grid 12 6@lg">
                <Div>
                  <TextBox label={TXT('label.chiefExecutiveOfficer')}
                           fill={true}
                           name={`${name}-chiefExecutiveOfficer`}
                           value={item.chiefExecutiveOfficer}
                           onChange={chiefExecutiveOfficer => handleChange({ chiefExecutiveOfficer })} />
                </Div>
                <Div>
                  <TextBox label={TXT('label.chiefAccountant')}
                           fill={true}
                           name={`${name}-chiefAccountant`}
                           value={item.chiefAccountant}
                           onChange={chiefAccountant => handleChange({ chiefAccountant })} />
                </Div>
              </Div>
            </Div>
          </Div>
        </FieldSet>
      </Div>
      <Div>
        <FieldSet label={TXT('label.legalAddress')}>
          <TextArea fill={true}
                    name={`${name}-legalAddress`}
                    value={item.legalAddress}
                    validators={[ value => length(value, 0, 1024) ]}
                    onChange={legalAddress => handleChange({ legalAddress })} />
        </FieldSet>
      </Div>
      <Div>
        <FieldSet label={TXT('label.postalAddress')}>
          <TextArea fill={true}
                    name={`${name}-postalAddress`}
                    value={item.postalAddress}
                    validators={[ value => length(value, 0, 1024) ]}
                    onChange={postalAddress => handleChange({ postalAddress })} />
        </FieldSet>
      </Div>
    </Div>
  )
}

interface PaymentRequisitesItemProps {
  readonly: boolean;
  name: string;
  item: PaymentRequisites;
  index: number;
  onChange: (value: PaymentRequisites, index: number) => void;
  onRemove: (value: PaymentRequisites, index: number) => void;
}

function PaymentRequisitesItem(props: PaymentRequisitesItemProps): ReactElement {
  const { readonly, name, item, index, onChange, onRemove } = props

  const form = useContext(FormContext)

  const formatMessage = useFormatMessage()

  const handleChange = useCallback((fields: {
    id?: string,
    paymentAccount?: string | null,
    correspondentAccount?: string | null,
    bankName?: string | null,
    bankIdentificationCode?: string | null,
  }): void => {
    const value = { ...item, ...fields }
    onChange(value, index)
  }, [ index, item, onChange ])

  const handleRemove = useCallback((): void => {
    onRemove(item, index)
  }, [ index, item, onRemove ])

  return (
    <FieldSet label={item.id}>
      <Div layout="grid 12">
        <Div>
          <Div layout="grid 12 3@lg">
            <TextBox label={TXT('label.name')}
                     fill={true}
                     readonly={readonly}
                     name={`${name}-id`}
                     value={item.id}
                     validators={[ required, value => length(value, 1, 256) ]}
                     onChange={id => handleChange({ id: id || '' })} />
          </Div>
        </Div>
        <Div>
          <Div layout="grid 12 3@lg">
            <Div>
              <TextBox label={TXT('label.paymentAccount')}
                       fill={true}
                       readonly={readonly}
                       name={`${name}-paymentAccount`}
                       value={item.paymentAccount}
                       validators={[ required, value => length(value, 1, 256) ]}
                       onChange={paymentAccount => handleChange({ paymentAccount })} />
            </Div>
            <Div>
              <TextBox label={TXT('label.correspondentAccount')}
                       fill={true}
                       readonly={readonly}
                       name={`${name}-correspondentAccount`}
                       value={item.correspondentAccount}
                       validators={[ required, value => length(value, 1, 256) ]}
                       onChange={correspondentAccount => handleChange({ correspondentAccount })} />
            </Div>
            <Div>
              <TextBox label={TXT('label.bankName')}
                       fill={true}
                       readonly={readonly}
                       name={`${name}-bankName`}
                       value={item.bankName}
                       validators={[ required, value => length(value, 1, 256) ]}
                       onChange={bankName => handleChange({ bankName })} />
            </Div>
            <Div>
              <TextBox label={TXT('label.bankIdentificationCode')}
                       fill={true}
                       readonly={readonly}
                       name={`${name}-bankIdentificationCode`}
                       value={item.bankIdentificationCode}
                       validators={[ required, value => length(value, 1, 256) ]}
                       onChange={bankIdentificationCode => handleChange({ bankIdentificationCode })} />
            </Div>
          </Div>
        </Div>
        {!readonly &&
        <Div layout="text-right">
          <Button disabled={form.submitting}
                  look="bare"
                  intent="danger"
                  className="action"
                  onClick={handleRemove}>
            {formatMessage(TXT('action.delete'))}
          </Button>
        </Div>
        }
      </Div>
    </FieldSet>
  )
}

interface PaymentRequisitesListProps {
  readonly: boolean;
  name: string;
  data: PaymentRequisites[];
  onChange: (value: PaymentRequisites[]) => void;
}

function PaymentRequisitesList(props: PaymentRequisitesListProps): ReactElement {
  const { readonly, data, name, onChange } = props

  const form = useContext(FormContext)

  const formatMessage = useFormatMessage()

  const handleAdd = useCallback(() => {
    const change = [ ...data, new PaymentRequisites() ]
    onChange(change)
  }, [ data, onChange ])

  const handleChange = useCallback((value: PaymentRequisites, index: number): void => {
    const change = [ ...data ]
    change.splice(index, 1, value)
    onChange(change)
  }, [ data, onChange ])

  const handleRemove = useCallback((value: PaymentRequisites, index: number): void => {
    const change = [ ...data ]
    change.splice(index, 1)
    onChange(change)
    form.onFieldRemove(`${name}-${value.id}`, false)
  }, [ form, data, name, onChange ])

  return (
    <FieldSet label={TXT('label.paymentRequisites')}>
      <Div layout="grid 12">
        {data.map((item, index) =>
          <Div key={index}>
            <PaymentRequisitesItem readonly={readonly}
                                   name={`${name}-${item.id}`}
                                   item={item}
                                   index={index}
                                   onChange={handleChange}
                                   onRemove={handleRemove} />
          </Div>,
        )}
        {!readonly &&
        <Div layout="text-right">
          <Button disabled={form.submitting}
                  look="outline"
                  className="action"
                  onClick={handleAdd}>
            {formatMessage(TXT('action.append'))}
          </Button>
        </Div>
        }
      </Div>
    </FieldSet>
  )
}

export interface OrganizationItemProps extends ItemViewProps<Organization, OrganizationCreateModel> {
  showTypes?: boolean;
}

export function OrganizationItem(props: OrganizationItemProps): ReactElement {
  const { item, lock, readonly, showTypes, onSubmit, onCancel, onDelete, onEdit, onFree } = props

  const auth = useContext(AuthContext)

  const pageSize = 100
  const id = item.id
  const isNew = id === EMPTY_ID

  const [ itemAirports ] = useOrganizationAirports(id)
  const [ itemLegalRequisites ] = useOrganizationLegalRequisites(id)
  const [ itemPaymentRequisites ] = useOrganizationPaymentRequisites(id)
  const [ itemCurrency ] = useOrganizationCurrency(id)
  const [ itemCity ] = useCity(item.city.id)

  const [ types ] = useOrganizationTypes()
  const [ type, setType ] = useField<Pair<OrganizationType> | null>(types.filter(pair => pair.code === item.type && pair.code !== OrganizationType.System)[0] || null)

  const [ name, setName ] = useField<string | null>(item.name)

  const [ cities, getCities ] = useCities(pageSize, isNew && !readonly)
  const [ city, setCity ] = useField<CityBase | null>(itemCity)

  const [ allAirports, getAirports ] = useAirports(pageSize, !readonly)
  const [ selAirports, setAirports ] = useField<Airport[] | null>(itemAirports?.data || null)

  const [ legalRequisites, setLegalRequisites ] = useField(itemLegalRequisites)
  const [ paymentRequisites, setPaymentRequisites ] = useField(itemPaymentRequisites?.data || null)

  const [ allCurrencies ] = useState<Pair[]>([
    new Pair('RUB', 'Russian Ruble'),
    new Pair('USD', 'US Dollar'),
    new Pair('EUR', 'Euro'),
  ])

  const [ selCurrency, setCurrency ] = useField<Pair | null>(allCurrencies.filter((pair) => pair.code === (itemCurrency?.code || 'RUB'))[0] || null)

  const getItem = useCallback((): OrganizationCreateModel => {
    const item = new OrganizationCreateModel()
    item.name = name || ''
    item.type = type ? type.code : OrganizationType.System
    item.city = city || new CityReference()
    item.airports = type && type.code === OrganizationType.Provider ? selAirports : null
    item.legalRequisites = legalRequisites
    item.paymentRequisites = paymentRequisites
    item.currency = selCurrency ? selCurrency.code : 'RUB'

    return item
  }, [
    name, type, city, selAirports, legalRequisites, paymentRequisites, selCurrency,
  ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem())
    }
  }, [ getItem, onSubmit ])

  return (
    <Form lock={lock}
          loaded={true}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12 3@lg">
              {showTypes &&
              <Div>
                <ComboBox label={TXT('label.type')}
                          fill={true}
                          disabled={!isNew && !readonly}
                          name="type"
                          data={types.filter(pair => pair.code !== OrganizationType.System)}
                          value={type}
                          valueKey="code"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setType} />
              </Div>
              }
              <Div>
                <TextBox label={TXT('label.name')}
                         fill={true}
                         disabled={!readonly && !auth.profile.isSys}
                         name="name"
                         value={name}
                         validators={[ required, value => length(value, 3, 256) ]}
                         onChange={setName} />
              </Div>
              <Div>
                <ComboBox label={TXT('label.city')}
                          fill={true}
                          disabled={!isNew && !readonly}
                          searchable={true}
                          pageSize={pageSize}
                          name="city"
                          data={cities}
                          value={city}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onFetch={getCities}
                          onChange={setCity} />
              </Div>
              {type && type.code === OrganizationType.Provider &&
              <Div>
                <TagsBox label={TXT('label.airports')}
                         disabled={!readonly && !auth.profile.isSys}
                         fill={true}
                         pageSize={pageSize}
                         name="airports"
                         data={allAirports}
                         value={selAirports || []}
                         valueKey="id"
                         valueLabel="name"
                         validators={[ required ]}
                         onFetch={getAirports}
                         onChange={setAirports} />
              </Div>
              }
              <Div>
                <ComboBox label={TXT('label.currency')}
                          fill={true}
                          disabled={!isNew && !readonly}
                          name="currency"
                          data={allCurrencies}
                          value={selCurrency}
                          valueKey="code"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setCurrency} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <OrganizationRequisitesView readonly={readonly}
                                      name="organizationRequisites"
                                      item={legalRequisites || new LegalRequisites()}
                                      onChange={setLegalRequisites} />
        </Div>
        <Div>
          {paymentRequisites !== null &&
          <PaymentRequisitesList readonly={readonly}
                                 name="paymentRequisites"
                                 data={paymentRequisites}
                                 onChange={setPaymentRequisites} />
          }
        </Div>
      </Div>
    </Form>
  )
}

export interface OrganizationGridProps extends GridViewProps<Organization<CityBase>> {
  showTypes?: boolean;
  showActive?: boolean;
  onItemAppend?: ItemCreateHandler;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function OrganizationGrid(props: OrganizationGridProps): ReactElement {
  const { data, showTypes, onItemAppend, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props

  const formatBoolean = useFormatBoolean()
  const [ , formatType ] = useOrganizationTypes()

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null)
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk())

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total)
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        code: item.code,
        name: item.name,
        type: formatType(item.type),
        city: item.city.name,
      })
    }
    setProcessedData(processedData)
  }, [ data.data, data.skip, data.take, data.total, formatBoolean, formatType, setProcessedData ])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [ delayedPattern, onItemSearch ])

  const [ width, setWidth ] = useState<number>(1)
  const [ , innerWidth ] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [ innerWidth ])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [ setWidth ])

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT('label.search')}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemAppend &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemAppend}>
              <FormattedMessage {...TXT('action.append')} />
            </Button>
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              <FormattedMessage {...TXT('action.create')} />
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  onItemSelect={onItemSelect}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange}>
              <GridColumn title={TXT('label.code')} field="code" width={128} locked={true} />
              <GridColumn title={TXT('label.name')} field="name" width={256} fill={true} />
              {showTypes &&
              <GridColumn title={TXT('label.type')} field="type" width={128} />
              }
              <GridColumn title={TXT('label.city')} field="city" width={256} sortable={false} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  )
}

export interface OrganizationCurrencySettingsViewProps {
  item: CurrencySettings;
  lock: Lock;
  readonly: boolean;
  onSubmit?: (model: CurrencySettings) => Promise<void>;
  onCancel?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onFree?: () => Promise<void>;
}

export function OrganizationCurrencySettingsView(props: OrganizationCurrencySettingsViewProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onEdit, onFree } = props

  const [ allCurrencies ] = useState<Pair[]>([
    new Pair('RUB', 'Russian Ruble'),
    new Pair('USD', 'US Dollar'),
    new Pair('EUR', 'Euro'),
  ])

  const [ selCurrency, setCurrency ] = useField<Pair | null>(allCurrencies.filter((pair) => pair.code === item.code)[0] || null)

  const handleSubmit = useCallback(async (): Promise<void> => {
    const model = { ...item }
    if (selCurrency) {
      model.code = selCurrency.code
    }

    if (onSubmit) {
      await onSubmit(model)
    }
  }, [ item, selCurrency, onSubmit ])

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout={'grid 12'}>
        <Div>
          <FieldSet label={TXT('label.currency')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <ComboBox label={TXT('label.currency')}
                          fill={true}
                          name="currency"
                          data={allCurrencies}
                          value={selCurrency}
                          valueKey="code"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setCurrency} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
      </Div>
    </Form>
  )
}

export interface OrganizationVoucherSettingsViewProps {
  item: EVoucherSettings;
  lock: Lock;
  readonly: boolean;
  showInfo: boolean;
  showRate: boolean;
  onSubmit?: (model: EVoucherSettings) => Promise<void>;
  onCancel?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onFree?: () => Promise<void>;
}

export function OrganizationVoucherSettingsView(props: OrganizationVoucherSettingsViewProps): ReactElement {
  const { item, lock, readonly, showInfo, showRate, onSubmit, onCancel, onEdit, onFree } = props

  const [ logoId, setLogoId ] = useField<string | null>(item.logoId !== EMPTY_ID ? item.logoId : null)
  const [ description, setDescription ] = useField<string | null>(item.description)
  const [ sendVoucher, setSendVoucher ] = useField<boolean>(item.voucherSendRequired ?? true)
  const [ showPrices, setShowPrices ] = useField<boolean>(item.voucherRateRequired ?? true)

  const [ uploadUrl, formatFileUrl ] = useFileUrl()

  const [ isUploaderVisible, setIsUploaderVisible ] = useState(false)

  const toggleUpload = useCallback((): void => {
    setIsUploaderVisible(true)
  }, [ setIsUploaderVisible ])

  const handleTrash = useCallback((): void => {
    setLogoId(null)
  }, [ setLogoId ])

  const handleUpload = useCallback((files: UploadedFile[]): void => {
    setIsUploaderVisible(false)
    setLogoId(files && files.length > 0 ? files[0].id : null)
  }, [ setIsUploaderVisible, setLogoId ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    const model = new EVoucherSettings()
    if (showInfo) {
      model.logoId = logoId || EMPTY_ID
      model.description = description
    }
    if (showRate) {
      model.voucherSendRequired = sendVoucher
      model.voucherRateRequired = showPrices
    }

    if (onSubmit) {
      await onSubmit(model)
    }
  }, [showInfo, showRate, logoId, description, sendVoucher, showPrices, onSubmit])

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout={'grid 12'}>
        {showInfo &&
        <Div>
          <FieldSet label={TXT('label.footer')}>
            <Div layout={'flex'} className={'voucher'}>
              <Div layout={'fit'}>
                <Div layout={'grid 12'}>
                  <Div>
                    {logoId ? <img alt={'EVoucher Logo'} src={formatFileUrl(logoId)} /> :
                      <span>{Icons.Common.NoImage}</span>}
                  </Div>
                  {!readonly &&
                  <Div>
                    <Button look={'outline'}
                            primary={true}
                            className={'icon'}
                            icon={'edit'}
                            onClick={toggleUpload} />
                    <Button look={'outline'}
                            className={'icon'}
                            icon={'trash'} onClick={handleTrash} />

                    {isUploaderVisible &&
                    <Uploader title={TXT('label.upload')}
                              uploadUrl={uploadUrl}
                              single={true}
                              allowedFileExtensions={[ '.svg', '.png', '.jpg', '.jpeg' ]}
                              onConfirm={handleUpload} />
                    }
                  </Div>
                  }
                </Div>
              </Div>
              <Div layout={'fill'}>
                <Div layout={'grid 12'}>
                  <Div>
                    <RichBox name={'description'} fill={true} value={description}
                             onChange={setDescription} />
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        {showRate &&
        <Div>
          <FieldSet label={TXT('label.settings')}>
            <Div layout="grid 12">
              <Div>
                <SwitchBox mode="check" label={TXT('label.sendVoucher')} name="sendVoucher" value={sendVoucher}
                           onChange={setSendVoucher} />
              </Div>
              <Div>
                <SwitchBox mode="check" label={TXT('label.showPrices')} name="showPrices" value={showPrices}
                           onChange={setShowPrices} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
      </Div>
    </Form>
  )
}

export interface OrganizationFreeAccessSettingsViewProps {
  item: FreeAccessSettings;
  lock: Lock;
  readonly: boolean;
  onSubmit?: (model: FreeAccessSettings) => Promise<void>;
  onCancel?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onFree?: () => Promise<void>;
}

export function OrganizationFreeAccessSettingsView(props: OrganizationFreeAccessSettingsViewProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onEdit, onFree } = props

  const pageSize = 100
  const formatMessage = useFormatMessage()

  const [ allTypes ] = useFreeAccessTypes()
  const [ selTypes, setTypes ] = useState<PairData<FreeAccessType>>([])
  useEffect(() => {
    setTypes(allTypes.filter(pair => item.types.some(code => code === pair.code)))
  }, [ item.types, allTypes, setTypes ])

  const [ allKlasses ] = usePassengerKlasses()
  const [ selKlasses, setKlasses ] = useState<PairData<PassengerKlass>>([])
  useEffect(() => {
    setKlasses(allKlasses.filter(pair => item.businessPassengerSettings.klasses.some(code => code === pair.code)))
  }, [ item.businessPassengerSettings.klasses, allKlasses, setKlasses ])

  const [ allBusinessRequiredParams ] = useState<PairData>([
    new Pair('klass', formatMessage(TXT('label.passengerKlass'))),
    new Pair('ticket', formatMessage(TXT('label.ticket'))),
  ])
  const [ selBusinessRequiredParams, setBusinessRequiredParams ] = useState<PairData>([])
  useEffect(() => {
    setBusinessRequiredParams([])
    if (item.businessPassengerSettings.klassRequired) {
      setBusinessRequiredParams(prev => [ ...prev, ...allBusinessRequiredParams.filter(pair => pair.code === 'klass') ])
    }
    if (item.businessPassengerSettings.ticketRequired) {
      setBusinessRequiredParams(prev => [ ...prev, ...allBusinessRequiredParams.filter(pair => pair.code === 'ticket') ])
    }
  }, [ item.businessPassengerSettings.klassRequired, item.businessPassengerSettings.ticketRequired, allBusinessRequiredParams, setBusinessRequiredParams ])

  const [ allLoyaltyPrograms, getLoyaltyPrograms ] = useLoyaltyPrograms(pageSize, !readonly)
  const [ selLoyaltyPrograms, setLoyaltyPrograms ] = useField<LoyaltyProgram[]>(item.loyaltyProgramSettings.programs)

  const [ allLoyaltyRequiredParams ] = useState<PairData>([
    new Pair('airline', formatMessage(TXT('label.airline'))),
    new Pair('program', formatMessage(TXT('label.program'))),
    new Pair('level', formatMessage(TXT('label.loyaltyLevel'))),
    new Pair('number', formatMessage(TXT('label.cardNumber'))),
  ])
  const [ selLoyaltyRequiredParams, setLoyaltyRequiredParams ] = useState<PairData>([])
  useEffect(() => {
    setLoyaltyRequiredParams([])
    if (item.loyaltyProgramSettings.airlineRequired) {
      setLoyaltyRequiredParams(prev => [ ...prev, ...allLoyaltyRequiredParams.filter(pair => pair.code === 'airline') ])
    }
    if (item.loyaltyProgramSettings.programRequired) {
      setLoyaltyRequiredParams(prev => [ ...prev, ...allLoyaltyRequiredParams.filter(pair => pair.code === 'program') ])
    }
    if (item.loyaltyProgramSettings.levelRequired) {
      setLoyaltyRequiredParams(prev => [ ...prev, ...allLoyaltyRequiredParams.filter(pair => pair.code === 'level') ])
    }
    if (item.loyaltyProgramSettings.numberRequired) {
      setLoyaltyRequiredParams(prev => [ ...prev, ...allLoyaltyRequiredParams.filter(pair => pair.code === 'number') ])
    }
  }, [ item.loyaltyProgramSettings.airlineRequired, item.loyaltyProgramSettings.programRequired, item.loyaltyProgramSettings.levelRequired, item.loyaltyProgramSettings.numberRequired, allLoyaltyRequiredParams, setLoyaltyRequiredParams ])

  const [ allAccessPrograms, getAccessPrograms ] = useAccessPrograms(pageSize, !readonly)
  const [ selAccessPrograms, setAccessPrograms ] = useField<AccessProgram[]>(item.accessProgramSettings.programs)

  const [ allAccessRequiredParams ] = useState<PairData>([
    new Pair('program', formatMessage(TXT('label.program'))),
    new Pair('number', formatMessage(TXT('label.cardNumber'))),
  ])
  const [ selAccessRequiredParams, setAccessRequiredParams ] = useState<PairData>([])
  useEffect(() => {
    setAccessRequiredParams([])
    if (item.accessProgramSettings.programRequired) {
      setAccessRequiredParams(prev => [ ...prev, ...allAccessRequiredParams.filter(pair => pair.code === 'program') ])
    }
    if (item.accessProgramSettings.numberRequired) {
      setAccessRequiredParams(prev => [ ...prev, ...allAccessRequiredParams.filter(pair => pair.code === 'number') ])
    }
  }, [ item.accessProgramSettings.programRequired, item.accessProgramSettings.numberRequired, allAccessRequiredParams, setAccessRequiredParams ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    const model = new FreeAccessSettings()
    model.types = selTypes.map(pair => pair.code)
    model.businessPassengerSettings = new BusinessPassengerSettings()
    if (model.types.some(code => code === FreeAccessType.BusinessPassenger)) {
      model.businessPassengerSettings.klasses = selKlasses.map(pair => pair.code)
      model.businessPassengerSettings.klassRequired = selBusinessRequiredParams.some(pair => pair.code === 'klass')
      model.businessPassengerSettings.ticketRequired = selBusinessRequiredParams.some(pair => pair.code === 'ticket')
    }
    model.loyaltyProgramSettings = new LoyaltyProgramSettings()
    if (model.types.some(code => code === FreeAccessType.LoyaltyProgram)) {
      model.loyaltyProgramSettings.programs = selLoyaltyPrograms
      model.loyaltyProgramSettings.programRequired = selLoyaltyRequiredParams.some(pair => pair.code === 'program')
      model.loyaltyProgramSettings.airlineRequired = selLoyaltyRequiredParams.some(pair => pair.code === 'airline')
      model.loyaltyProgramSettings.levelRequired = selLoyaltyRequiredParams.some(pair => pair.code === 'level')
      model.loyaltyProgramSettings.numberRequired = selLoyaltyRequiredParams.some(pair => pair.code === 'number')
    }
    model.accessProgramSettings = new AccessProgramSettings()
    if (model.types.some(code => code === FreeAccessType.AccessProgram)) {
      model.accessProgramSettings.programs = selAccessPrograms
      model.accessProgramSettings.programRequired = selAccessRequiredParams.some(pair => pair.code === 'program')
      model.accessProgramSettings.numberRequired = selAccessRequiredParams.some(pair => pair.code === 'number')
    }

    if (onSubmit) {
      await onSubmit(model)
    }
  }, [
    selTypes,
    selKlasses, selBusinessRequiredParams,
    selLoyaltyPrograms, selLoyaltyRequiredParams,
    selAccessPrograms, selAccessRequiredParams,
    onSubmit,
  ])

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout={'grid 12'}>
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <TagsBox label={(TXT('label.freeAccess'))}
                     fill={true}
                     name={'freeAccessTypes'}
                     data={allTypes}
                     value={selTypes}
                     valueKey={'code'}
                     valueLabel={'name'}
                     onChange={setTypes} />
          </FieldSet>
        </Div>
        {selTypes.some(pair => pair.code === FreeAccessType.BusinessPassenger) &&
        <Div>
          <FieldSet label={TXT('enum.organizationFreeAccessType.business')}>
            <Div layout={'grid 12'}>
              <Div>
                <TagsBox label={TXT('label.passengerKlasses')}
                         fill={true}
                         name={'passengerKlasses'}
                         data={allKlasses}
                         value={selKlasses}
                         valueKey={'code'}
                         valueLabel={'name'}
                         onChange={setKlasses} />
              </Div>
              <Div>
                <TagsBox label={TXT('label.requiredParameters')}
                         fill={true}
                         name={'businessParams'}
                         data={allBusinessRequiredParams}
                         value={selBusinessRequiredParams}
                         valueKey={'code'}
                         valueLabel={'name'}
                         onChange={setBusinessRequiredParams} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        {selTypes.some(pair => pair.code === FreeAccessType.LoyaltyProgram) &&
        <Div>
          <FieldSet label={TXT('enum.organizationFreeAccessType.loyalty')}>
            <Div layout={'grid 12'}>
              <Div>
                <TagsBox label={TXT('label.loyaltyPrograms')}
                         fill={true}
                         name={'loyaltyPrograms'}
                         data={allLoyaltyPrograms}
                         value={selLoyaltyPrograms}
                         valueKey={'name'}
                         valueLabel={'name'}
                         onChange={setLoyaltyPrograms}
                         onFetch={getLoyaltyPrograms} />
              </Div>
              <Div>
                <TagsBox label={TXT('label.requiredParameters')}
                         fill={true}
                         name={'loyaltyParams'}
                         data={allLoyaltyRequiredParams}
                         value={selLoyaltyRequiredParams}
                         valueKey={'code'}
                         valueLabel={'name'}
                         onChange={setLoyaltyRequiredParams} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
        {selTypes.some(pair => pair.code === FreeAccessType.AccessProgram) &&
        <Div>
          <FieldSet label={TXT('enum.organizationFreeAccessType.program')}>
            <Div layout={'grid 12'}>
              <Div>
                <TagsBox label={TXT('label.accessPrograms')}
                         fill={true}
                         name={'accessPrograms'}
                         data={allAccessPrograms}
                         value={selAccessPrograms}
                         valueKey={'name'}
                         valueLabel={'name'}
                         onChange={setAccessPrograms}
                         onFetch={getAccessPrograms} />
              </Div>
              <Div>
                <TagsBox label={TXT('label.requiredParameters')}
                         fill={true}
                         name={'accessParams'}
                         data={allAccessRequiredParams}
                         value={selAccessRequiredParams}
                         valueKey={'code'}
                         valueLabel={'name'}
                         onChange={setAccessRequiredParams} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        }
      </Div>
    </Form>
  )
}
