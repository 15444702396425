import React, { PureComponent, ReactNode } from "react";

interface LoadingIndicatorProps {
}

interface LoadingIndicatorProps {
  children?: null;
}

interface LoadingIndicatorState {
}

export class LoadingIndicator extends PureComponent<LoadingIndicatorProps, LoadingIndicatorState> {
  public constructor(props: LoadingIndicatorProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    return (
      <div className="k-loading-mask">
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );
  }
}
