import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import { ContractV1, ContractBaseV1, ContractDepositOperationV1, ContractRecord } from "../../../data/models";
import { OrganizationBase, OrganizationContract } from "../models";

export class ContractAccess {
  public static async getOne(providerCode: string, agencyCode: string, contractNumber: string): Promise<OrganizationContract<OrganizationBase>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${providerCode}/${agencyCode}/${contractNumber}`);
  }
}

export class ContractAccessV1 {
  public static async fetchItem(id: number): Promise<ContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<ContractV1>("Contract", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                agencyId?: number, providerId?: number,
                                active?: boolean, withDefault?: boolean): Promise<Chunk<ContractBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ContractBaseV1>>("Contract", {}, {
        pattern,
        orderBy,
        skip,
        take,
        agencyId,
        providerId,
        active,
        withDefault,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number,
                                agencyId?: number, providerId?: number,
                                onlyActive?: boolean, withDefault?: boolean): Promise<Chunk<ContractRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ContractRecord>>("Contract/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        agencyId,
        providerId,
        onlyActive,
        withDefault,
      });
  }

  public static async fetchDepositOperations(id: number, pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<ContractDepositOperationV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ContractDepositOperationV1>>("Contract/deposit/operations", { id }, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }

  public static async fetchDepositBalance(id: number): Promise<number> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<number>("Contract/deposit/balance", { id });
  }

  public static async fetchDepositOverdraft(id: number): Promise<number> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<number>("Contract/deposit/overdraft", { id });
  }

  public static async create(item: ContractV1): Promise<ContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Contract", item);
  }

  public static async update(id: number, item: ContractV1): Promise<ContractV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update("Contract", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .delete("Contract", { id });
  }

  public static async check(providerId: number, agencyId: number, number: string): Promise<boolean> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<boolean>("Contract/check", { providerId, agencyId, number });
  }

  public static async updateBalance(id: number, value: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .modify("Contract/deposit/balance", { value }, { id });
  }

  public static async updateOverdraft(id: number, value: number): Promise<void> {
    await Client
      .instance(await Apis.getDataApi())
      .modify("Contract/deposit/overdraft", { value }, { id });
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Contract/lock", { id }, { action })
  }
}
