import React, { ReactElement, useContext } from 'react'
import { Redirect } from 'react-router'
import { AuthContext } from '../auth'
import { NavPathItem } from '../gears'
import { withPage } from './withPage'

function HomePage(): ReactElement {
  const auth = useContext(AuthContext)

  if (auth.profile.isInRole('owner.accountant', 'agent.accountant')) {
    return <Redirect to="/reports" />
  }

  if (auth.profile.isInRole('owner.manager.resource')) {
    return <Redirect to="/resources" />
  }

  if (auth.profile.isAgent) {
    return <Redirect to="/orders" />
  }

  if (auth.profile.isOwner) {
    return <Redirect to="/orders" />
  }

  return <div />
}

function pathBuilder(): NavPathItem[] {
  return []
}

export const Home = withPage(HomePage, pathBuilder)
Home.displayName = 'HomePage'
