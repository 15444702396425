import { IReference } from '../../../data/models'
import { EMPTY_ID } from './index'

export class Performer {
  public id: string | null
  public clientId: string | null
  public userName: string | null
  public fullName: string | null
  public role: string | null
  public belongsTo: string | null
  public channel: string | null

  public constructor() {
    this.id = null
    this.clientId = null
    this.userName = null
    this.fullName = null
    this.role = null
    this.belongsTo = null
    this.channel = null
  }
}

export class Change {
  public id: string
  public model: IReference<string>
  public name: string
  public action: string
  public input: string | null
  public output: string | null
  public performedAt: string
  public performedBy: Performer

  public constructor() {
    this.id = EMPTY_ID
    this.model = { id: EMPTY_ID }
    this.name = ''
    this.action = ''
    this.input = null
    this.output = null
    this.performedAt = ''
    this.performedBy = new Performer()
  }
}
