import React, { ReactElement, useCallback } from "react";
import { ButtonGroup } from "../../gears";
import { SwitchBox, ToggleBox } from "../../gears/inputs";
import { Order, OrderPaymentMode, OrderPaymentModel, OrderPaymentType } from "../data/models";
import { Div, Span, TXT } from "../gears";
import { useFormatCost, useFormatMessage, useOrganizationContract, useOrganizationVoucherSettings } from '../hooks'
import { ViewProps } from "./types";

export interface OrderPaymentViewProps extends ViewProps<null> {
  calc: Order;
  item: OrderPaymentModel;
  onChange: (item: OrderPaymentModel) => void;
}

export function OrderPaymentView(props: OrderPaymentViewProps): ReactElement {
  const { calc, item, onChange } = props;

  const formatCost = useFormatCost()
  const formatMessage = useFormatMessage()

  const [ contract ] = useOrganizationContract(calc.contract.id)
  const [ settings ] = useOrganizationVoucherSettings(contract ? contract.agency.id : null);

  const handleCardSelect = useCallback((selected: boolean): void => {
    onChange({ ...item, type: selected ? OrderPaymentType.Card : OrderPaymentType.Cash });
  }, [ item, onChange ]);

  const handleCashSelect = useCallback((selected: boolean): void => {
    onChange({ ...item, type: selected ? OrderPaymentType.Cash : OrderPaymentType.Card });
  }, [ item, onChange ]);

  const handleVoucherSendRequired = useCallback((selected: boolean): void => {
    onChange({ ...item, voucherSendRequired: selected });
  }, [ item, onChange ]);

  const handleVoucherRateRequired = useCallback((selected: boolean): void => {
    onChange({ ...item, voucherRateRequired: selected });
  }, [ item, onChange ]);

  return (
    <Div style={{ width: 384 }}>
      <Div layout="grid 12">
        {calc.unpaid > 0 &&
        <Div>
          <Span intent="success">
            {`${formatMessage(TXT('label.rest'))}: ${formatCost(calc.unpaid, calc.currency.code)}`}
          </Span>
        </Div>
        }
        {calc.refund - calc.penalty > 0 &&
        <Div>
          <Span intent="success">
            {`${formatMessage(TXT('label.refund'))}: ${formatCost(calc.refund - calc.penalty, calc.currency.code)}`}
          </Span>
        </Div>
        }
        {calc.penalty > 0 &&
        <Div>
          <Span intent="danger">
            {`${formatMessage(TXT('label.fine'))}: ${formatCost(calc.penalty, calc.currency.code)}`}
          </Span>
        </Div>
        }
        {item.mode === OrderPaymentMode.Offline &&
          <Div>
            <ButtonGroup>
              <ToggleBox label={TXT("enum.orderPaymentType.card")}
                         name="card"
                         style={{ width: 128 }}
                         value={item.type === OrderPaymentType.Card}
                         onChange={handleCardSelect} />
              <ToggleBox label={TXT("enum.orderPaymentType.cash")}
                         name="cash"
                         style={{ width: 128 }}
                         value={item.type === OrderPaymentType.Cash}
                         onChange={handleCashSelect} />
            </ButtonGroup>
          </Div>
        }
        {item.mode === OrderPaymentMode.Offline && item.info && settings &&
          <Div>
            <Div layout="grid 12">
              <Div>
                  <SwitchBox mode="check" label={TXT("label.sendVoucher")} name="sendVoucher" value={item.voucherSendRequired ?? settings.voucherSendRequired ?? true}
                             onChange={handleVoucherSendRequired} />
              </Div>
              <Div>
                  <SwitchBox mode="check" label={TXT("label.showPrices")} name="showPrices" value={item.voucherRateRequired ?? settings.voucherRateRequired ?? true}
                             onChange={handleVoucherRateRequired} />
              </Div>
            </Div>
          </Div>
        }
      </Div>
    </Div>
  );
}
