import * as KendoIntl from "@progress/kendo-react-intl";
import React, { ReactNode } from "react";
import * as ReactIntl from "react-intl";
import app_ru from "./translations/app_ru.json";
import kendo_ru from "./translations/kendo_ru.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers_ru from "cldr-numbers-full/main/ru/numbers.json";
import currency_ru from "cldr-numbers-full/main/ru/currencies.json";
import calendar_ru from "cldr-dates-full/main/ru/ca-gregorian.json";
import dateFields_ru from "cldr-dates-full/main/ru/dateFields.json";
import timeZoneNames_ru from "cldr-dates-full/main/ru/timeZoneNames.json";

let language = "ru";
let messages = app_ru;
KendoIntl.load(likelySubtags, currencyData, weekData, currency_ru, numbers_ru, calendar_ru, dateFields_ru, timeZoneNames_ru);
KendoIntl.loadMessages(kendo_ru, language);

export function IntlProvider(props: Readonly<{ children?: ReactNode }>) {
  const { children } = props;

  return (
    <ReactIntl.IntlProvider locale={language} messages={messages}>
      <KendoIntl.IntlProvider locale={language}>
        <KendoIntl.LocalizationProvider language={language}>
          {children}
        </KendoIntl.LocalizationProvider>
      </KendoIntl.IntlProvider>
    </ReactIntl.IntlProvider>
  );
}
