import { Apis, Chunk, Client } from "../../../data";
import { CityBaseV1 } from "../../../data/models";

export class CityAccessV1 {
  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<CityBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<CityBaseV1>>("City", {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }
}
