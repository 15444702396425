import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Measure, { ContentRect } from 'react-measure'
import { Icons } from '../../assets'
import { Chunk, Lock, Pair } from '../../data'
import { UserBase } from '../../data/models'
import { ButtonGroup, Carousel, Drawer, FieldSet, Form, FormContext, RelLink, Tabs } from '../../gears'
import { ComboBox, NumberBox, RichBox, SwitchBox, TagsBox, TextBox, TimeBox, ToggleBox } from '../../gears/inputs'
import { length, phone as phoneValidator, required } from '../../gears/validators'
import { AirportAccess, CityAccess } from '../data/access'
import { Airport, AirportReference, AirportTerminalName, City, EMPTY_ID, FlightDirection, GroupRateRule, isAssistance, isLounge, isTransfer, Media, MediaKind, Organization, OrganizationProgram, OrganizationProgramType, OrganizationReference, OrganizationType, PassengerCategory, RateFilter, ResolvedOrganizationContract, ResolvedRate, ResolvedResource, ResolvedService, ResourceCreateModel, ResourceDefaults, ResourceTranslation, Service, ServiceKind, ServiceMode, ServiceType } from '../data/models'
import { ActionHandler, Button, CardDeck, CardItem, CardItemBody, CardItemHeader, CardItemTitle, Div, formatOrderBy, Grid, GridColumn, IntlText, ItemCreateHandler, ItemFilterHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, Span, TXT } from '../gears'
import { useAirport, useAirportTerminals, useDelay, useField, useFileUrl, useFlightDirections, useFormatBoolean, useFormatCode, useFormatCost, useFormatCostRange, useFormatMessage, useFormatTime, useOrganization, useOrganizationAirports, useOrganizationDefaults, useOrganizationLegalRequisites, useOrganizationProgramSettings, useOrganizations, usePassengerCategories, usePrimaryServiceKinds, useResourceCompatibilities, useResourceFeatureIcons, useResourceFeatures, useResourceSettings, useServiceModes, useServiceTypes, useServiceUnits, useUsersV1, useWindowSize } from '../hooks'
import { MediaView } from './Medias'
import { RateGrid } from './Rates'
import { GridViewProps, ItemViewProps, ViewProps } from './types'

interface CorporateProgramViewProps {
  item: OrganizationProgram
}

function CorporateProgramView(props: CorporateProgramViewProps) {
  const { item } = props

  const formatMessage = useFormatMessage()

  const [settings] = useOrganizationProgramSettings(item.id)

  if (settings === null) return null

  return (
    <Div layout="grid 12">
      {item.type === OrganizationProgramType.Individual && settings.availablePrimaryPersonCount !== 0 &&
        <Div>
          <Div layout="grid 6">
            <Div>
              <Span>{formatMessage(TXT('label.availablePrimaryPersons'))}:</Span>
            </Div>
            <Div>
              {settings.availablePrimaryPersonCount !== null &&
                <Span>{formatMessage(TXT('label.availablePrimaryPersonsCount', { count: settings.availablePrimaryPersonCount }))}. </Span>
              }
              <Span>{formatMessage(TXT('label.availablePrimaryPersonsDescription'))}. </Span>
            </Div>
          </Div>
        </Div>
      }
      {item.type === OrganizationProgramType.Individual && settings.availableSecondaryPersonCount !== 0 &&
        <Div>
          <Div layout="grid 6">
            <Div>
              <Span>{formatMessage(TXT('label.availableSecondaryPersons'))}:</Span>
            </Div>
            <Div>
              <Span>{settings.availableSecondaryPersonCount ? formatMessage(TXT('label.availableSecondaryPersonsCount', { count: settings.availableSecondaryPersonCount })) : formatMessage(TXT('label.availableSecondaryPersonsUnlimited'))}. </Span>
            </Div>
          </Div>
        </Div>
      }
      <Div>
        <Div layout="grid 6">
          <Div>
            <Span>{formatMessage(TXT('label.availableFlights'))}:</Span>
          </Div>
          <Div>
            {(settings.availableFlightCount || settings.availableFlightCountForDomestic || settings.availableFlightCountForInternational) &&
              <React.Fragment>
                <Span>{formatMessage(TXT('label.availableFlightsCount', { count: settings.availableFlightCount !== null ? settings.availableFlightCount : (settings.availableFlightCountForDomestic || 0) + (settings.availableFlightCountForInternational || 0) }))}. </Span>
                {(settings.availableFlightCountForDomestic || settings.availableFlightCountForInternational) &&
                  <Span>{formatMessage(TXT('label.availableFlightsCountOf', {
                    description: [settings.availableFlightCountForDomestic ? formatMessage(TXT('label.availableFlightsCountOn', {
                      count: settings.availableFlightCountForDomestic,
                      type: formatMessage(TXT('enum.flightDirection.dom')),
                    })) : undefined, settings.availableFlightCountForInternational ? formatMessage(TXT('label.availableFlightsCountOn', {
                      count: settings.availableFlightCountForInternational,
                      type: formatMessage(TXT('enum.flightDirection.int')),
                    })) : undefined].filter(item => item !== undefined).join(', '),
                  }))}. </Span>
                }
              </React.Fragment>
            }
            {(settings.availableFlightCountPerMonth || settings.availableFlightCountPerMonthForDomestic || settings.availableFlightCountPerMonthForInternational) &&
              <React.Fragment>
                <Span>{formatMessage(TXT('label.availableFlightsCountPerMonth', { count: settings.availableFlightCountPerMonth !== null ? settings.availableFlightCountPerMonth : (settings.availableFlightCountPerMonthForDomestic || 0) + (settings.availableFlightCountPerMonthForInternational || 0) }))}. </Span>
                {(settings.availableFlightCountForDomestic || settings.availableFlightCountForInternational) &&
                  <Span>{formatMessage(TXT('label.availableFlightsCountOf', {
                    description: [settings.availableFlightCountPerMonthForDomestic ? formatMessage(TXT('label.availableFlightsCountOn', {
                      count: settings.availableFlightCountPerMonthForDomestic,
                      type: formatMessage(TXT('enum.flightDirection.dom')),
                    })) : undefined, settings.availableFlightCountPerMonthForInternational ? formatMessage(TXT('label.availableFlightsCountOn', {
                      count: settings.availableFlightCountPerMonthForInternational,
                      type: formatMessage(TXT('enum.flightDirection.int')),
                    })) : undefined].filter(item => item !== undefined).join(', '),
                  }))}. </Span>
                }
                {settings.needsSaveUnusedFlightCount &&
                  <Span>{formatMessage(TXT('label.savingUnusedFlights'))}. </Span>
                }
              </React.Fragment>
            }
            {settings.availableFlightCount === null && settings.availableFlightCountForDomestic === null && settings.availableFlightCountForInternational === null && settings.availableFlightCountPerMonth === null && settings.availableFlightCountPerMonthForDomestic === null && settings.availableFlightCountPerMonthForInternational === null &&
              <Span>{formatMessage(TXT('limit.unlimited'))}. </Span>
            }
          </Div>
        </Div>
      </Div>
      {settings.availableGuestCountPerFlight !== 0 && settings.availableGuestCount !== 0 &&
        <Div>
          <Div layout="grid 6">
            <Div>
              <Span>{formatMessage(TXT('label.availableGuestPersons'))}:</Span>
            </Div>
            <Div>
              {settings.availableGuestCountPerFlight &&
                <Span>{formatMessage(TXT('label.availableGuestPersonsCountPerFlight', { count: settings.availableGuestCountPerFlight }))}. </Span>
              }
              {settings.availableGuestCount &&
                <Span>{formatMessage(TXT('label.availableGuestPersonsCount', { count: settings.availableGuestCount }))}. </Span>
              }
              {(settings.availableGuestCountPerMonth || settings.availableGuestCountPerMonthForArrival || settings.availableGuestCountPerMonthForDeparture) &&
                <React.Fragment>
                  <Span>{formatMessage(TXT('label.availableGuestPersonsCountPerMonth', { count: settings.availableGuestCountPerMonth !== null ? settings.availableGuestCountPerMonth : (settings.availableGuestCountPerMonthForArrival || 0) + (settings.availableGuestCountPerMonthForDeparture || 0) }))}. </Span>
                  {(settings.availableGuestCountPerMonthForArrival || settings.availableGuestCountPerMonthForDeparture) &&
                    <Span>{formatMessage(TXT('label.availableGuestPersonsCountOf', {
                      description: [settings.availableGuestCountPerMonthForArrival ? formatMessage(TXT('label.availableGuestPersonsCountOn', {
                        count: settings.availableGuestCountPerMonthForArrival,
                        type: formatMessage(TXT('enum.serviceType.arrival')).toLowerCase(),
                      })) : undefined, settings.availableGuestCountPerMonthForDeparture ? formatMessage(TXT('label.availableGuestPersonsCountOn', {
                        count: settings.availableGuestCountPerMonthForDeparture,
                        type: formatMessage(TXT('enum.serviceType.departure')).toLowerCase(),
                      })) : undefined].filter(item => item !== undefined).join(', '),
                    }))}. </Span>
                  }
                </React.Fragment>
              }
              {settings.availableGuestCount === null && settings.availableGuestCountPerMonth === null && settings.availableGuestCountPerMonthForArrival === null && settings.availableGuestCountPerMonthForDeparture === null && settings.availableGuestCountPerFlight === null &&
                <Span>{formatMessage(TXT('limit.unlimited'))}. </Span>
              }
            </Div>
          </Div>
        </Div>
      }
      {item.description &&
        <Div>
          <Div dangerouslySetInnerHTML={{ __html: item.description }} />
        </Div>
      }
    </Div>
  )
}


interface ServicedCategoriesProps {
  label: IntlText;
  name: string;
  value: PassengerCategory[];
  onChange: (value: PassengerCategory[]) => void;
}

function ServicedCategories(props: ServicedCategoriesProps): ReactElement {
  const { label, name, value, onChange } = props

  const [passengerCategories] = usePassengerCategories()
  const [selectedPassengerCategories, setSelectedPassengerCategories] = useState(passengerCategories.filter(x => x.code !== PassengerCategory.Group).map((x) => ({
    code: x.code, name: x.name, selected: false,
  })))

  useEffect(() => {
    setSelectedPassengerCategories(prev => {
      const curr = [...prev]
      for (const sel of curr) {
        sel.selected = value.some(x => x === sel.code)
      }
      return curr
    })
  }, [value, setSelectedPassengerCategories])

  const handleChange = useCallback((index: number, value: boolean): void => {
    selectedPassengerCategories[index].selected = value
    onChange(selectedPassengerCategories.filter(x => x.selected).map(dayItem => dayItem.code))
  }, [selectedPassengerCategories, onChange])

  return (
    <ButtonGroup label={label} fill={true}>
      {selectedPassengerCategories.map((item, index) =>
        <ToggleBox key={index}
          primary={true}
          label={item.name}
          name={`${name}-${item.code}`}
          value={item.selected}
          onChange={value => handleChange(index, value)} />)}
    </ButtonGroup>
  )
}

interface ResourceTranslationItemProps {
  readonly: boolean
  name: string
  index: number
  value: ResourceTranslation
  languages: string[]
  onChange: (index: number, value: ResourceTranslation) => void
  onRemove: (index: number) => void
}

function ResourceTranslationItem(props: ResourceTranslationItemProps) {
  const { readonly, name, index, value, languages, onChange, onRemove } = props

  const formatMessage = useFormatMessage()

  const handleChange = useCallback((change: Partial<ResourceTranslation>) => {
    onChange(index, { ...value, ...change })
  }, [index, value, onChange])

  const handleRemove = useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  return (
    <Div>
      <FieldSet label={value.language}>
        <Div layout="grid 12">
          <Div>
            <ComboBox label={TXT('label.language')}
              fill={true}
              readonly={readonly}
              name={`${name}-language`}
              data={languages}
              value={value.language}
              validators={[required]}
              onChange={language => handleChange({ language: language || '' })} />
          </Div>
          <Div>
            <TextBox label={TXT('label.name')}
              fill={true}
              readonly={readonly}
              name={`${name}-name`}
              value={value.name}
              validators={[value => length(value, 1, 256)]}
              onChange={name => handleChange({ name: name ? name.substring(0, 256) : null })} />
          </Div>
          <Div>
            <RichBox fill={true}
              readonly={readonly}
              name={`${name}-description` }
              value={value.description}
              onChange={description => handleChange({ description })} />
          </Div>
          <Div>
            <TextBox label={TXT('label.location')}
              readonly={readonly}
              fill={true}
              name={`${name}-location`}
              value={value.location}
              validators={[value => length(value, 1, 256)]}
              onChange={location => handleChange({ location: location ? location.substring(0, 256) : null })} />
          </Div>
          <div>
            <TextBox label={TXT('label.schedule')}
              readonly={readonly}
              fill={true}
              name={`${name}-schedule`}
              value={value.schedule}
              validators={[value => length(value, 1, 256)]}
              onChange={schedule => handleChange({ schedule: schedule ? schedule.substring(0, 256) : null })} />
          </div>
          {!readonly &&
            <Div layout="flex">
              <Div layout="fill" />
              <Div layout="fit">
                <Button
                  look="outline"
                  className="action"
                  onClick={handleRemove}>
                  {formatMessage(TXT('action.delete'))}
                </Button>
              </Div>
            </Div>
          }
        </Div>
      </FieldSet>
    </Div>
  )
}

interface ResourceTranslationListProps {
  readonly: boolean;
  value: ResourceTranslation[];
  onChange: (value: ResourceTranslation[]) => void;
}

function ResourceTranslationList(props: ResourceTranslationListProps) {
  const { readonly, value, onChange } = props

  const form = useContext(FormContext)

  const formatMessage = useFormatMessage()

  const [items, setItems] = useState(value)

  const [allLanguages] = useState(['en', 'ru'])

  const [selLanguages, setSelLanguages] = useState<string[]>([])

  const [newLanguages, setNewLanguages] = useState<string[]>([])

  useEffect(() => {
    setSelLanguages(items.map(x => x.language).filter(x => x.length > 0))
  }, [items])

  useEffect(() => {
    setNewLanguages(allLanguages.filter(x => !selLanguages.some(y => x === y)))
  }, [allLanguages, selLanguages])

  const handleChange = useCallback((index: number, value: ResourceTranslation) => {
    const change = [...items]
    change.splice(index, 1, value)
    setItems(change)
  }, [items, setItems])

  const handleRemove = useCallback((index: number) => {
    const change = [...items]
    change.splice(index, 1)
    form.onFieldRemove(`translations`, false)
    setItems(change)
  }, [items, setItems, form])

  const handleAppend = useCallback(() => {
    const change = [...items, new ResourceTranslation({ language: newLanguages[0] ?? '' })]
    console.log({ change })
    setItems(change)
  }, [items, newLanguages, setItems])

  return (
    <Div style={{ height: '95vh', overflowX: 'hidden', overflowY: 'auto' }}>
      <Div layout="grid 12">
        <Div>
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button look="bare" icon="close" onClick={() => onChange(items)} />
            </Div>
          </Div>
        </Div>
        <Div>
          <span className="header">{formatMessage(TXT('label.translations'))}</span>
        </Div>
        <Div>
          <Div layout="grid 12">
            {items.map((value, index) => (
              <ResourceTranslationItem key={index} readonly={readonly} name="translations" index={index} value={value} languages={newLanguages} onChange={handleChange} onRemove={handleRemove} />
            ))}
          </Div>
        </Div>
        {!readonly &&
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button disabled={form.submitting || newLanguages.length === 0}
                look="outline"
                className="action"
                onClick={handleAppend}>
                {formatMessage(TXT('action.append'))}
              </Button>
            </Div>
          </Div>
        }
      </Div>
    </Div>
  )
}

export interface ResourceItemProps extends ItemViewProps<
  ResolvedResource
  & { images: Media[], maps: Media[], docs: Media[], translations: ResourceTranslation[], users: string[] | null },
  ResourceCreateModel
  & { images: Media[], maps: Media[], docs: Media[], translations: ResourceTranslation[] }> {
  showProviders?: boolean;
}

export function ResourceItem(props: ResourceItemProps): ReactElement {
  const { item, lock, readonly, showProviders, onSubmit, onCancel, onDelete, onEdit, onFree } = props

  const pageSize = 100
  const id = item.id
  const isNew = id === EMPTY_ID

  const [tabItems] = useState([
    { label: TXT('label.images'), data: 'images' },
    { label: TXT('label.maps'), data: 'maps' },
    { label: TXT('label.docs'), data: 'docs' },
  ])
  const [tabIndex, setTabIndex] = useState(0)

  const [providers, getProviders] = useOrganizations(pageSize, isNew && !readonly && showProviders, useMemo(() => ({ byType: OrganizationType.Provider }), []))
  const [provider, setProvider] = useOrganization(item.organization.id !== EMPTY_ID ? item.organization.id : null)

  const [defaults] = useOrganizationDefaults(provider !== null ? provider.id : null)
  const [settings] = useResourceSettings(item.id !== EMPTY_ID ? item.id : null)

  const [kinds, formatServiceKind] = usePrimaryServiceKinds()
  const [kind, setKind] = useField<Pair<ServiceKind> | null>(kinds.filter(pair => !isNew && pair.code === item.kind)[0] || null)

  const [types] = useServiceTypes()
  const [type, setType] = useField<Pair<ServiceType> | null>(types.filter(pair => !isNew && pair.code === item.type)[0] || null)

  const [modes] = useServiceModes()
  const [mode, setMode] = useField<Pair<ServiceMode> | null>(modes.filter(pair => !isNew && pair.code === item.mode)[0] || null)

  const [flightDirections] = useFlightDirections()
  const [flightDirection, setFlightDirection] = useField<Pair<FlightDirection> | null>(flightDirections.filter(pair => !isNew && pair.code === item.flightDirection)[0] || null)

  const [airports] = useOrganizationAirports(provider !== null ? provider.id : null)
  const [airport, setAirport] = useAirport(item.airport.id !== EMPTY_ID ? item.airport.id : null)

  const [terminals] = useAirportTerminals(airport !== null ? airport.id : null, airport !== null)
  const [terminal, setTerminal] = useField<AirportTerminalName | null>(item.terminal && terminals ? terminals.data.filter(x => x.code === item.terminal)[0] || null : null)

  const [allFeatures] = useResourceFeatures()
  const [selFeatures, setFeatures] = useState(allFeatures.filter(pair => item.features && item.features.some(code => pair.code === code)))
  useEffect(() => {
    setFeatures(allFeatures.filter(pair => item.features && item.features.some(code => pair.code === code)))
  }, [item.features, allFeatures, setFeatures])

  const [allAmenities] = useResourceFeatures()
  const [selAmenities, setAmenities] = useState(allAmenities.filter(pair => item.amenities && item.amenities.some(code => pair.code === code)))
  useEffect(() => {
    setAmenities(allAmenities.filter(pair => item.amenities && item.amenities.some(code => pair.code === code)))
  }, [item.amenities, allAmenities, setAmenities])

  const [itemCompatibilities] = useResourceCompatibilities(item.id !== EMPTY_ID ? item.id : null)
  const [selCompatibilities, setCompatibilities] = useState<Pair<ServiceKind>[]>([])
  useEffect(() => {
    if (itemCompatibilities) {
      setCompatibilities(itemCompatibilities.map(kind => new Pair<ServiceKind>(kind, formatServiceKind(kind))))
    } else {
      setCompatibilities([])
    }
  }, [itemCompatibilities, formatServiceKind, setCompatibilities])

  const [allUsers, getUsers] = useUsersV1(pageSize, provider !== null, provider ? provider.code : undefined)
  const [selUsers, setUsers] = useField<UserBase[] | null>(useMemo(
    () => {
      if (!item.users) return []

      return item.users.filter((id) => allUsers.data.some(user => user.id === id)).map((id) => allUsers.data.filter((user) => user.id === id)[0]) || []
    },
    [allUsers, item.users],
  ))

  const [name, setName] = useField<string | null>(item.name)
  const [capacity, setCapacity] = useField<number | null>(settings?.capacity || null)
  const [quota, setQuota] = useField<number | null>(settings?.quota || null)
  const [arrivalEnterDelta, setArrivalEnterDelta] = useField<string | null>(settings?.arrivalEnterDelta || defaults?.arrivalEnterDelta || null)
  const [arrivalLeaveDelta, setArrivalLeaveDelta] = useField<string | null>(settings?.arrivalLeaveDelta || defaults?.arrivalLeaveDelta || null)
  const [departureEnterDelta, setDepartureEnterDelta] = useField<string | null>(settings?.departureEnterDelta || defaults?.departureEnterDelta || null)
  const [departureLeaveDelta, setDepartureLeaveDelta] = useField<string | null>(settings?.departureLeaveDelta || defaults?.departureLeaveDelta || null)
  const [maxStayDuration, setMaxStayDuration] = useField<string | null>(settings?.maxStayDuration || defaults?.maxStayDuration || null)
  const [description, setDescription] = useField<string | null>(item.description)
  const [location, setLocation] = useField<string | null>(item.location)
  const [schedule, setSchedule] = useField<string | null>(item.schedule)
  const [phone, setPhone] = useField<string | null>(item.phone)

  const [images, seImages] = useField(item.images)
  const [maps, setMaps] = useField(item.maps)
  const [docs, setDocs] = useField(item.docs)
  const [translations, setTranslations] = useField(item.translations)

  const lounge = kind && isLounge(kind.code)
  const assistance = kind && isAssistance(kind.code)
  const transfer = kind && isTransfer(kind.code)
  const transit = kind && kind.code === ServiceKind.Transit
  const arrival = type && (type.code === ServiceType.Any || type.code === ServiceType.Arrival)
  const departure = type && (type.code === ServiceType.Any || type.code === ServiceType.Departure)

  const [servicedCategories, setServicedCategories] = useField(settings?.servicedCategories || null)
  const [minChildAge, setMinChildAge] = useField(settings?.minChildAge || null)
  const [minAdultAge, setMinAdultAge] = useField(settings?.minAdultAge || null)

  const [isTransit, setIsTransit] = useField(item.isTransit)
  const [maxFlightConnection, setMaxFlightConnection] = useField<string | null>(settings?.maxFlightConnection || defaults?.maxComboDelta || null)

  const [active, setActive] = useField(!item.deactivated)

  useEffect(() => {
    if (isNew) {
      setTerminal(null)
    }
  }, [isNew, airport, setTerminal])

  useEffect(() => {
    if (isNew) {
      setMode(null)
    }
  }, [isNew, kind, setMode])

  useEffect(() => {
    if (isNew && transit) {
      setType(types.filter((pair) => pair.code === ServiceType.Arrival)[0])
    }
  }, [isNew, transit, types, setType])

  const getItem = useCallback((): ResourceCreateModel & { images: Media[], maps: Media[], docs: Media[], translations: ResourceTranslation[] } => {
    return {
      name: name || '',
      kind: kind !== null ? kind.code : ServiceKind.VipLounge,
      type: transfer ? ServiceType.Any : type !== null ? type.code : ServiceType.Any,
      flightDirection: transfer ? FlightDirection.Any : flightDirection !== null ? flightDirection.code : FlightDirection.Any,
      organization: provider || new OrganizationReference(),
      airport: airport || new AirportReference(),
      terminal: terminal?.code || null,
      description: description,
      mode: transfer ? ServiceMode.Automatic : mode !== null ? mode.code : ServiceMode.Automatic,
      capacity: lounge ? capacity : null,
      quota: lounge ? quota : null,
      groupAge: null,
      groupSize: null,
      maxStayDuration: (lounge || transit) ? maxStayDuration : null,
      arrivalEnterDelta: (lounge || assistance) && !transit && arrival ? arrivalEnterDelta : null,
      arrivalLeaveDelta: (lounge || assistance) && !transit && arrival ? arrivalLeaveDelta : null,
      departureEnterDelta: (lounge || assistance) && !transit && departure ? departureEnterDelta : null,
      departureLeaveDelta: (lounge || assistance) && !transit && departure ? departureLeaveDelta : null,
      minChildAge: minChildAge,
      minAdultAge: minAdultAge,
      servicedCategories: servicedCategories,
      location: (lounge || assistance) ? location : null,
      schedule: (lounge || assistance) ? schedule : null,
      phone: (lounge || assistance) ? phone : null,
      features: lounge ? selFeatures.map(pair => pair.code) : null,
      amenities: lounge ? selAmenities.map(pair => pair.code) : null,
      compatibilities: transfer ? selCompatibilities.map(pair => pair.code) : null,
      images: images,
      maps: maps,
      docs: docs,
      translations: translations,
      subscribers: selUsers?.map(x => x.id) || null,
      isTransit: (type?.code === ServiceType.Any || type?.code === ServiceType.Arrival) ? isTransit : null,
      maxFlightConnection: (type?.code === ServiceType.Any || type?.code === ServiceType.Arrival) ? maxFlightConnection : null,
      deactivated: !active,
    }
  }, [
    name, kind, type, flightDirection, provider, terminal, description, mode, capacity, quota,
    maxStayDuration, arrivalEnterDelta, arrivalLeaveDelta, departureEnterDelta, departureLeaveDelta,
    location, schedule, phone, selFeatures, selAmenities, selCompatibilities, images, maps, docs, translations, selUsers,
    arrival, departure, lounge, assistance, transfer, transit,
    minChildAge, minAdultAge, servicedCategories, airport, isTransit, maxFlightConnection, active,
  ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem())
    }
  }, [getItem, onSubmit])

  const formatMessage = useFormatMessage()

  const [translationsToggled, setTranslationsToggled] = useState(false)

  const toggleTranslations = useCallback(() => {
    setTranslationsToggled(prev => !prev)
  }, [setTranslationsToggled])

  const handleTranslations = useCallback((value: ResourceTranslation[]) => {
    setTranslations(value.filter(x => !!x.language))
    toggleTranslations()
  }, [toggleTranslations, setTranslations])

  return (
    <Form lock={lock}
      loaded={true}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onDelete={onDelete}
      onEdit={onEdit}
      onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12">
              <Div>
                <Div layout="grid 12 vertical-end 3@lg">
                  {showProviders &&
                    <Div>
                      <ComboBox label={TXT('label.provider')}
                        fill={true}
                        disabled={!isNew && !readonly}
                        name="provider"
                        pageSize={pageSize}
                        searchable={true}
                        data={providers}
                        value={provider}
                        valueKey="id"
                        valueLabel="name"
                        validators={[required]}
                        onFetch={getProviders}
                        onChange={setProvider} />
                    </Div>
                  }
                  <Div>
                    <TextBox label={TXT('label.name')}
                      fill={true}
                      name="name"
                      value={name}
                      validators={[required, value => length(value, 3, 256)]}
                      onChange={setName} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT('label.serviceKind')}
                      fill={true}
                      disabled={!isNew && !readonly}
                      name="kind"
                      data={kinds}
                      value={kind}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setKind} />
                  </Div>
                  {(lounge || assistance) &&
                    <Div>
                      <ComboBox label={TXT('label.serviceType')}
                        fill={true}
                        disabled={!isNew && !readonly}
                        name="type"
                        data={types.filter(pair => (!transit && (pair.code === ServiceType.Any || pair.code === ServiceType.Arrival || pair.code === ServiceType.Departure)) || (transit && pair.code === ServiceType.Arrival))}
                        value={type}
                        valueKey="code"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setType} />

                    </Div>
                  }
                  {(lounge || assistance) &&
                    <Div>
                      <ComboBox label={TXT('label.flightDirection')}
                        fill={true}
                        disabled={!isNew && !readonly}
                        name="flightDirection"
                        data={flightDirections}
                        value={flightDirection}
                        valueKey="code"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setFlightDirection} />

                    </Div>
                  }
                  {transfer &&
                    <Div>
                      <TagsBox label={TXT('label.compatibilities')}
                        fill={true} name="compatibilities"
                        data={kinds.filter(pair =>
                          pair.code === ServiceKind.VipLounge ||
                          pair.code === ServiceKind.BusinessLounge ||
                          pair.code === ServiceKind.ComfortLounge ||
                          pair.code === ServiceKind.OfficialPersonsAndDelegationsLounge)}
                        value={selCompatibilities}
                        valueKey="code"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setCompatibilities} />
                    </Div>
                  }
                  <Div>
                    <ComboBox label={TXT('label.airport')}
                      fill={true}
                      disabled={!isNew && !readonly}
                      pageSize={pageSize}
                      searchable={true}
                      name="airport"
                      data={airports != null ? airports || [] : []}
                      value={airport}
                      valueKey="id"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setAirport} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT('label.terminal')}
                      fill={true}
                      searchable={true}
                      editable={true}
                      name="terminal"
                      data={terminals}
                      value={terminal}
                      valueKey="id"
                      valueLabel="code"
                      onChange={setTerminal} />
                  </Div>
                  {lounge &&
                    <Div>
                      <NumberBox label={TXT('label.capacity')}
                        fill={true}
                        name="capacity"
                        value={capacity}
                        min={0}
                        validators={[required]}
                        onChange={setCapacity} />
                    </Div>
                  }
                  {lounge &&
                    <Div>
                      <NumberBox label={TXT('label.quota')}
                        fill={true}
                        name="quota"
                        value={quota}
                        min={0}
                        validators={[required]}
                        onChange={setQuota} />
                    </Div>
                  }
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12 vertical-end 3@lg">
                  {(lounge || assistance) && !transit && arrival &&
                    <Div>
                      <TimeBox label={lounge ? TXT('label.arrivalEnterDelta') : TXT('label.arrivalMeetingDelta')}
                        fill={true}
                        name="arrivalEnterDelta"
                        value={arrivalEnterDelta}
                        validators={[required]}
                        onChange={setArrivalEnterDelta} />
                    </Div>
                  }
                  {(lounge) && !transit && arrival &&
                    <Div>
                      <TimeBox label={TXT('label.arrivalLeaveDelta')}
                        fill={true}
                        name="arrivalLeaveDelta"
                        value={arrivalLeaveDelta}
                        validators={[required]}
                        onChange={setArrivalLeaveDelta} />
                    </Div>
                  }
                  {(lounge || assistance) && !transit && departure &&
                    <Div>
                      <TimeBox label={lounge ? TXT('label.departureEnterDelta') : TXT('label.departureMeetingDelta')}
                        fill={true}
                        name="departureEnterDelta"
                        value={departureEnterDelta}
                        validators={[required]}
                        onChange={setDepartureEnterDelta} />
                    </Div>
                  }
                  {(lounge) && !transit && departure &&
                    <Div>
                      <TimeBox label={TXT('label.departureLeaveDelta')}
                        fill={true}
                        name="departureLeaveDelta"
                        value={departureLeaveDelta}
                        validators={[required]}
                        onChange={setDepartureLeaveDelta} />
                    </Div>
                  }
                  {lounge &&
                    <Div>
                      <TimeBox label={TXT('label.maxStayDuration')}
                        fill={true}
                        name="maxStayDuration"
                        value={maxStayDuration}
                        validators={[required]}
                        onChange={setMaxStayDuration} />
                    </Div>
                  }
                  {transit &&
                    <Div>
                      <TimeBox label={TXT('label.maxFlightConnection')}
                        fill={true}
                        name="maxFlightConnection"
                        value={maxStayDuration}
                        validators={[required]}
                        onChange={setMaxStayDuration} />
                    </Div>
                  }
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12 3@lg">
                  {(lounge || assistance) && (type?.code === ServiceType.Any || type?.code === ServiceType.Arrival) &&
                    <Div style={{paddingTop: "15px"}}>
                      <ToggleBox label={TXT('label.transit')}
                        primary={true}
                        name="isTransit"
                        value={isTransit}
                        onChange={setIsTransit} />
                    </Div>
                  }
                  {(lounge || assistance) && (type?.code === ServiceType.Any || type?.code === ServiceType.Arrival) && isTransit &&
                    <Div>
                      <TimeBox label={TXT('label.maxFlightConnection')}
                        fill={true}
                        name="maxFlightConnection"
                        value={maxFlightConnection}
                        validators={[required]}
                        onChange={setMaxFlightConnection} />
                    </Div>
                  }
                </Div>
              </Div>
              {lounge &&
                <Div>
                  <TagsBox label={TXT('label.features')}
                    fill={true}
                    name="features"
                    data={allFeatures}
                    value={selFeatures}
                    valueKey="code"
                    valueLabel="name"
                    onChange={setFeatures} />
                </Div>
              }
              {lounge &&
                <Div>
                  <TagsBox label={TXT('label.amenities')}
                    fill={true}
                    name="amenities"
                    data={allAmenities}
                    value={selAmenities}
                    valueKey="code"
                    valueLabel="name"
                    onChange={setAmenities} />
                </Div>
              }
                <Div>
                  {formatMessage(TXT('label.bookable'))}{'? '}
                  <SwitchBox
                    name="active"
                    value={active}
                    onChange={setActive} />
                </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.description')}>
            <Div layout="grid 12">
              <Div>
                <Div layout="grid 12">
                  <Div>
                    <RichBox fill={true}
                      name="description"
                      value={description}
                      onChange={setDescription} />
                  </Div>
                  {(lounge || assistance) &&
                    <Div>
                      <Div layout="grid 12 3@lg vertical-end">
                        <Div>
                          <TextBox label={TXT('label.location')}
                            fill={true}
                            name="location"
                            value={location}
                            validators={[value => length(value, 1, 256)]}
                            onChange={setLocation} />
                        </Div>
                        <div>
                          <TextBox label={TXT('label.schedule')}
                            fill={true}
                            name="schedule"
                            value={schedule}
                            validators={[value => length(value, 1, 256)]}
                            onChange={setSchedule} />
                        </div>
                        <div>
                          <TextBox label={TXT('label.phone')}
                            fill={true}
                            mask="(999) 000-00-00"
                            name="phone"
                            value={phone}
                            validators={[phoneValidator]}
                            onChange={setPhone} />
                        </div>
                      </Div>
                    </Div>
                  }
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12">
                  <Div layout="text-right">
                    <Button look="bare" primary={true} onClick={toggleTranslations}>
                      {formatMessage(TXT("label.translations"))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {(lounge || assistance) &&
          <Div>
            <FieldSet label={TXT('label.settings')}>
              <Div layout="grid 12 3@lg">
                <Div>
                  <ComboBox label={TXT('label.confirmationMode')}
                    fill={true}
                    name="confirmationMode"
                    data={modes.filter(pair => kind && (isLounge(kind.code) || pair.code !== ServiceMode.SemiAutomatic))}
                    value={mode}
                    valueKey="code"
                    valueLabel="name"
                    validators={[required]}
                    onChange={setMode} />
                </Div>
                <Div>
                  <ServicedCategories label={TXT('label.servicedCategories')}
                    name="servicedCategories"
                    value={servicedCategories || [PassengerCategory.Adult, PassengerCategory.Child, PassengerCategory.Infant]}
                    onChange={setServicedCategories} />
                </Div>
                <Div>
                  <NumberBox label={TXT('label.adultAge')}
                    fill={true}
                    name={`adultAge`}
                    min={0}
                    placeholder={defaults ? defaults.childAge.toString() : undefined}
                    value={minAdultAge}
                    onChange={setMinAdultAge} />
                </Div>
                <Div>
                  <NumberBox label={TXT('label.childAge')}
                    fill={true}
                    name={`adultAge`}
                    min={0}
                    placeholder={defaults ? defaults.infantAge.toString() : undefined}
                    value={minChildAge}
                    onChange={setMinChildAge} />
                </Div>
              </Div>
            </FieldSet>
          </Div>
        }
        {selUsers !== null &&
          <Div>
            <FieldSet label={TXT('label.users')}>
              <Div>
                <TagsBox fill={true}
                  name="users"
                  data={allUsers}
                  value={selUsers}
                  valueKey="id"
                  valueLabel="name"
                  onChange={setUsers}
                  onFetch={getUsers} />
              </Div>
            </FieldSet>
          </Div>
        }
        <Div>
          <FieldSet label={TXT('label.media')}>
            <Tabs items={tabItems} render={tabItem => {
              if (tabItem.data === 'images') {
                return <MediaView data={images} kind={MediaKind.Image} onChange={!readonly ? seImages : undefined} />
              } else if (tabItem.data === 'maps') {
                return <MediaView data={maps} kind={MediaKind.Map} onChange={!readonly ? setMaps : undefined} />
              } else {
                return <MediaView data={docs} kind={MediaKind.Document} onChange={!readonly ? setDocs : undefined} />
              }
            }} selected={tabIndex} onSelect={setTabIndex} />
          </FieldSet>
        </Div>
      </Div>
      {translationsToggled &&
        <Drawer>
          <Div>
            <ResourceTranslationList readonly={readonly} value={translations} onChange={handleTranslations} />
          </Div>
        </Drawer>
      }
    </Form>
  )
}

export interface ResourceGridProps extends GridViewProps<ResolvedResource> {
  showProviders?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function ResourceGrid(props: ResourceGridProps): ReactElement {
  const { data, showProviders, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props

  const formatCode = useFormatCode()
  const [, formatFlightDirection] = useFlightDirections(true)
  const [, formatServiceKind] = usePrimaryServiceKinds()

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(null)
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk())

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total)
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        code: item.code,
        name: item.name,
        kind: formatServiceKind(item.kind),
        flightDirection: formatFlightDirection(item.flightDirection),
        organizationName: item.organization.name,
        airportName: item.airport.name,
      })
    }
    setProcessedData(processedData)
  }, [data.data, data.skip, data.take, data.total, formatCode, formatServiceKind, formatFlightDirection, setProcessedData])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [delayedPattern, onItemSearch])

  const [width, setWidth] = useState<number>(1)
  const [, innerWidth] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [innerWidth])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [setWidth])

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0]
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem)
    }
  }, [data.data, onItemSelect])

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy)
      replaceSortOrder(sortOrder, 'code', 'code')

      onSortChange(formatOrderBy(sortOrder))
    }
  }, [onSortChange])

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
        <Div>
          <Div layout="flex">
            {onItemSearch &&
              <Div layout="fill">
                <TextBox placeholder={TXT('label.search')}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern} />
              </Div>
            }
            {onItemCreate &&
              <Div layout="fit">
                <Button primary={true} className="action" onClick={onItemCreate}>
                  <FormattedMessage {...TXT('action.create')} />
                </Button>
              </Div>
            }
          </Div>
        </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT('label.code')} field="code" width={92} locked={true} />
              <GridColumn title={TXT('label.name')} field="name" width={256} fill={true} />
              <GridColumn title={TXT('label.serviceKind')} field="kind" width={128} />
              <GridColumn title={TXT('label.flightDirection')} field="flightDirection" width={128} />
              <GridColumn title={TXT('label.airport')} field="airportName" width={256} />
              {showProviders &&
                <GridColumn title={TXT('label.provider')} field="organizationName" width={256} />
              }
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  )
}

export interface ResourceDefaultsViewProps extends ViewProps<null> {
  item: ResourceDefaults;
  lock: Lock;
  readonly: boolean;
  onSubmit?: (model: ResourceDefaults) => Promise<void>;
  onCancel?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onFree?: () => Promise<void>;
}

export function ResourceDefaultsView(props: ResourceDefaultsViewProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onEdit, onFree } = props

  const [infantAge, setInfantAge] = useField<number | null>(item.infantAge)
  const [childAge, setChildAge] = useField<number | null>(item.childAge)
  const [groupAge, setGroupAge] = useField<number | null>(item.groupAge)
  const [groupSize, setGroupSize] = useField<number | null>(item.groupSize)
  const [maxStayDuration, setMaxStayDuration] = useField<string | null>(item.maxStayDuration)
  const [arrivalEnterDelta, setArrivalEnterDelta] = useField<string | null>(item.arrivalEnterDelta)
  const [arrivalLeaveDelta, setArrivalLeaveDelta] = useField<string | null>(item.arrivalLeaveDelta)
  const [departureEnterDelta, setDepartureEnterDelta] = useField<string | null>(item.departureEnterDelta)
  const [departureLeaveDelta, setDepartureLeaveDelta] = useField<string | null>(item.departureLeaveDelta)
  const [forbidArrivalDelta, setForbidArrivalDelta] = useField<string | null>(item.forbidArrivalDelta)
  const [forbidDepartureDelta, setForbidDepartureDelta] = useField<string | null>(item.forbidDepartureDelta)
  const [priorityDelta, setPriorityDelta] = useField<string | null>(item.priorityDelta)
  const [passengerEMailRequired, setPassengerEMailRequired] = useField<boolean>(item.passengerEMailRequired)
  const [passengerPhoneRequired, setPassengerPhoneRequired] = useField<boolean>(item.passengerPhoneRequired)
  const [maxComboDelta, setMaxComboDelta] = useField(item.maxComboDelta)

  const handleSubmit = useCallback(async (): Promise<void> => {
    const model = new ResourceDefaults()
    model.infantAge = infantAge ? infantAge : model.infantAge
    model.childAge = childAge ? childAge : model.childAge
    model.groupAge = groupAge ? groupAge : model.groupAge
    model.groupSize = groupSize ? groupSize : model.groupSize
    model.maxStayDuration = maxStayDuration ? maxStayDuration : model.maxStayDuration
    model.arrivalEnterDelta = arrivalEnterDelta ? arrivalEnterDelta : model.arrivalEnterDelta
    model.arrivalLeaveDelta = arrivalLeaveDelta ? arrivalLeaveDelta : model.arrivalEnterDelta
    model.departureEnterDelta = departureEnterDelta ? departureEnterDelta : model.departureEnterDelta
    model.departureLeaveDelta = departureLeaveDelta ? departureLeaveDelta : model.departureLeaveDelta
    model.forbidArrivalDelta = forbidArrivalDelta ? forbidArrivalDelta : model.forbidArrivalDelta
    model.forbidDepartureDelta = forbidDepartureDelta ? forbidDepartureDelta : model.forbidDepartureDelta
    model.priorityDelta = priorityDelta ? priorityDelta : model.priorityDelta
    model.passengerEMailRequired = passengerEMailRequired
    model.passengerPhoneRequired = passengerPhoneRequired
    model.maxComboDelta = maxComboDelta

    if (onSubmit) {
      await onSubmit(model)
    }
  }, [
    infantAge, childAge, groupAge, groupSize,
    maxStayDuration, arrivalEnterDelta, arrivalLeaveDelta, departureEnterDelta, departureLeaveDelta,
    forbidArrivalDelta, forbidDepartureDelta, priorityDelta,
    passengerEMailRequired, passengerPhoneRequired,
    maxComboDelta,
    onSubmit,
  ])

  return (
    <Form loaded={true}
      lock={lock}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onEdit={onEdit}
      onFree={onFree}>
      <Div layout={'grid 12'}>
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout={'grid 12'}>
              <Div>
                <Div layout={'grid 12 3@lg'}>
                  <Div>
                    <NumberBox label={TXT('label.infantAge')}
                      name={'infantAge'}
                      fill={true}
                      value={infantAge}
                      min={1}
                      validators={[required]}
                      onChange={setInfantAge} />
                  </Div>
                  <Div>
                    <NumberBox label={TXT('label.childAge')}
                      name={'childAge'}
                      fill={true}
                      value={childAge}
                      min={1}
                      validators={[required]}
                      onChange={setChildAge} />
                  </Div>
                  <Div>
                    <NumberBox label={TXT('label.groupAge')}
                      name={'groupAge'}
                      fill={true}
                      value={groupAge}
                      min={1}
                      validators={[required]}
                      onChange={setGroupAge} />
                  </Div>
                  <Div>
                    <NumberBox label={TXT('label.groupSize')}
                      name={'groupSize'}
                      fill={true}
                      value={groupSize}
                      min={1}
                      validators={[required]}
                      onChange={setGroupSize} />
                  </Div>
                </Div>
              </Div>
              <Div>
                <Div layout={'grid 12 3@lg'}>
                  <Div>
                    <TimeBox label={TXT('label.arrivalEnterDelta')}
                      name={'arrivalEnterDelta'}
                      fill={true}
                      value={arrivalEnterDelta}
                      validators={[required]}
                      onChange={setArrivalEnterDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.arrivalLeaveDelta')}
                      name={'arrivalLeaveDelta'}
                      fill={true}
                      value={arrivalLeaveDelta}
                      validators={[required]}
                      onChange={setArrivalLeaveDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.departureEnterDelta')}
                      name={'departureEnterDelta'}
                      fill={true}
                      value={departureEnterDelta}
                      validators={[required]}
                      onChange={setDepartureEnterDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.departureLeaveDelta')}
                      name={'departureLeaveDelta'}
                      fill={true}
                      value={departureLeaveDelta}
                      validators={[required]}
                      onChange={setDepartureLeaveDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.maxStayDuration')}
                      name={'maxStayDuration'}
                      fill={true}
                      value={maxStayDuration}
                      validators={[required]}
                      onChange={setMaxStayDuration} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.forbidArrivalDelta')}
                      hint={TXT('hint.forbidArrivalDelta')}
                      name={'forbidArrivalDelta'}
                      fill={true}
                      value={forbidArrivalDelta}
                      validators={[required]}
                      onChange={setForbidArrivalDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.forbidDepartureDelta')}
                      hint={TXT('hint.forbidDepartureDelta')}
                      name={'forbidDepartureDelta'}
                      fill={true}
                      value={forbidDepartureDelta}
                      validators={[required]}
                      onChange={setForbidDepartureDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.priorityDelta')}
                      hint={TXT('hint.priorityDelta')}
                      name={'priorityDelta'}
                      fill={true}
                      value={priorityDelta}
                      validators={[required]}
                      onChange={setPriorityDelta} />
                  </Div>
                  <Div>
                    <TimeBox label={TXT('label.maxComboDelta')}
                      hint={TXT('hint.maxComboDelta')}
                      name={'maxComboDelta'}
                      fill={true}
                      value={maxComboDelta}
                      onChange={setMaxComboDelta} />
                  </Div>
                </Div>
              </Div>
              <Div>
                <Div>
                  <SwitchBox mode={'check'}
                    name={'passengerEMailRequired'}
                    label={TXT('label.passengerEMailRequired')}
                    value={passengerEMailRequired}
                    onChange={setPassengerEMailRequired} />
                </Div>
                <Div>
                  <SwitchBox mode={'check'}
                    name={'passengerPhoneRequired'}
                    label={TXT('label.passengerPhoneRequired')}
                    value={passengerPhoneRequired}
                    onChange={setPassengerPhoneRequired} />
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
      </Div>
    </Form>
  )
}

export interface ResourceSearchViewProps extends ViewProps<null> {
  data: Chunk<ResolvedResource>;
  mode: 'letter' | 'name';
  logos: Map<string, Media>;
  medias: Map<string, Media[]>;
  onItemSearch?: ItemSearchHandler;
}

export function ResourceSearchView(props: ResourceSearchViewProps): ReactElement {
  const { data, mode, logos, medias, onItemSearch } = props

  const [, formatFileUrl] = useFileUrl()

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(null)
  const [processedData, setProcessedData] = useState<Map<string, ResolvedResource[]>>(new Map())
  const [airports, setAirports] = useState(new Map<string, Airport>())
  const [cities, setCities] = useState(new Map<string, City>())

  const [processed, setProcessed] = useState(false)

  useEffect(() => {
    (async (): Promise<void> => {
      setProcessed(false)
      const airportMap = new Map<string, Airport>()
      const cityMap = new Map<string, City>()
      if (mode === 'letter') {
        const map = new Map<string, ResolvedResource[]>()
        for (const item of data.data) {
          const airport = (airportMap.has(item.airport.id) ? airportMap : airportMap.set(item.airport.id, await AirportAccess.getOne(item.airport.id)))
            .get(item.airport.id)!
          const city = (cityMap.has(airport.city.id) ? cityMap : cityMap.set(airport.city.id, await CityAccess.getOne(airport.city.id)))
            .get(airport.city.id)!
          if (city.name.length === 0) continue

          const letter = city.name[0].toUpperCase()
          const resources = map.get(letter) || []
          resources.push(item)
          map.set(letter, resources)
        }

        setAirports(airportMap)
        setCities(cityMap)
        setProcessedData(map)
      } else {
        const map = new Map<string, ResolvedResource[]>()
        for (const item of data.data) {
          const airport = (airportMap.has(item.airport.id) ? airportMap : airportMap.set(item.airport.id, await AirportAccess.getOne(item.airport.id)))
            .get(item.airport.id)!
          const city = (cityMap.has(airport.city.id) ? cityMap : cityMap.set(airport.city.id, await CityAccess.getOne(airport.city.id)))
            .get(airport.city.id)!
          if (city.name.length === 0) continue

          const airportName = `${airport.name} (${airport.code})`
          const resources = map.get(airportName) || []
          resources.push(item)
          map.set(airportName, resources)
        }

        setAirports(airportMap)
        setCities(cityMap)
        setProcessedData(map)
      }

      setProcessed(true)
    })()
  }, [data, mode, delayedPattern, setProcessedData, setAirports, setCities])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [delayedPattern, onItemSearch])

  const [width, setWidth] = useState<number>(1)
  const [, innerWidth] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [innerWidth])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [setWidth])

  if (!processed) return <div />

  return (
    <Div layout="grid 12">
      {onItemSearch &&
        <Div>
          <Div layout="flex">
            <Div layout="fill">
              <TextBox placeholder={TXT('label.searchByCountryCityAirportName')}
                fill={true}
                name="search-pattern"
                value={currentPattern}
                onChange={setPattern} />
            </Div>
          </Div>
        </Div>
      }
      {mode === 'letter' &&
        <Div layout="grid 12 3@lg">
          {Array.from(processedData.keys()).map((letter, letterIndex) => {
            const resources = processedData.get(letter) || []
            const airportNames = new Map<string, string>()
            const airportCount = new Map<string, number>()
            for (const resource of resources) {
              const airport = airports.get(resource.airport.id)
              const city = airport ? cities.get(airport.city.id) : undefined
              if (airport && city) {
                airportNames.set(airport.id, city.name !== airport.name
                  ? `${city.name} — ${airport.name}`
                  : `${city.name}`)

                const count = airportCount.get(airport.id) || 0
                airportCount.set(airport.id, count + 1)
              }
            }

            return (
              <Div key={letterIndex} className="airport-index">
                <Div layout="flex">
                  <Div layout="fit">
                    <span className="letter">{letter}</span>
                  </Div>
                  <Div layout="fill">
                    <ul className="content">
                      {Array.from(airportNames.keys()).map((airportId, airportIndex) =>
                        <li key={airportIndex}>
                          <RelLink
                            to={`${airportId}`}>{`${airportNames.get(airportId)} (${airportCount.get(airportId)})`}</RelLink>
                        </li>,
                      )}
                    </ul>
                  </Div>
                </Div>
              </Div>
            )
          })}
        </Div>
      }
      {mode === 'name' &&
        <Div>
          <Div layout="grid 12 3@lg">
            {Array.from(processedData.keys()).map((airportName, airportIndex) => {
              const resources = processedData.get(airportName) || []
              if (resources.length > 0) {
                let logo: Media | null = null
                const mediaItems: Media[] = []
                for (const resource of resources) {
                  logo = logos.get(resource.airport.id) || null
                  mediaItems.push(...(medias.get(resource.id) || []))
                }

                let image: Media | null = logo

                if (logo === null && mediaItems.length > 0) {
                  image = mediaItems[0]
                }

                const resource = resources[0]
                return (
                  <Div key={airportIndex}>
                    <RelLink to={`${resource.airport.id}`}>
                      <Measure client={true} bounds={true} onResize={handleResize}>
                        {({ measureRef }) =>
                          <Div ref={measureRef} layout="text-center" className="resource-image">
                            <Div className="image">
                              <img alt={image ? image.name : ''}
                                src={image ? `${formatFileUrl(image.fileId)}/thumbnail` : 'no-image.jpg'} width={width}
                                height={width * 3 / 4} style={{ objectFit: 'cover' }} />
                            </Div>
                            <Div className="text">
                              {airportName}
                            </Div>
                          </Div>
                        }
                      </Measure>
                    </RelLink>
                  </Div>
                )
              } else {
                return null
              }
            })}
          </Div>
        </Div>
      }
    </Div>
  )
}

export interface ResourceAirportViewProps extends ViewProps<null> {
  data: Chunk<ResolvedResource>;
  medias: Map<string, Media[]>;
}

interface ResourceImageViewProps {
  item: ResolvedResource;
  image: Media | null;
}

function ResourceImageView(props: ResourceImageViewProps): ReactElement {
  const { item, image } = props

  const [, formatFileUrl] = useFileUrl()
  const [, formatFlightDirection] = useFlightDirections()
  const formatMessage = useFormatMessage()

  const [width, setWidth] = useState<number>(1)
  const [, innerWidth] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [innerWidth])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [setWidth])

  return (
    <RelLink to={`${item.id}`}>
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef} layout="text-center" className="resource-image">
            <Div className="image">
              <img alt={image ? image.name : ''} src={image ? `${formatFileUrl(image.fileId)}/thumbnail` : 'no-image.jpg'}
                width={width} height={width * 3 / 4} style={{ objectFit: 'cover' }} />
            </Div>
            <Div className="text">
              <span>{`${item.name} (${formatFlightDirection(item.flightDirection)})`}</span>
              {item.terminal &&
                <span>{` / ${formatMessage(TXT('label.terminal'))} ${item.terminal}`}</span>
              }
            </Div>
          </Div>
        }
      </Measure>
    </RelLink>
  )
}

export function ResourceAirportView(props: ResourceAirportViewProps): ReactElement {
  const { data, medias } = props

  const formatMessage = useFormatMessage()

  const vipLounges = data.data.filter(item => item.kind === ServiceKind.VipLounge)
  const bizLounges = data.data.filter(item => item.kind === ServiceKind.BusinessLounge)
  const cmfLounges = data.data.filter(item => item.kind === ServiceKind.ComfortLounge)
  const ondLounges = data.data.filter(item => item.kind === ServiceKind.OfficialPersonsAndDelegationsLounge)
  const fstServices = data.data.filter(item => item.kind === ServiceKind.FastTrack)
  const mnaServices = data.data.filter(item => item.kind === ServiceKind.MeetAndAssist || item.kind === ServiceKind.Transit)
  const mngServices = data.data.filter(item => item.kind === ServiceKind.MeetAndGreet)
  const prkServices = data.data.filter(item => item.kind === ServiceKind.Parking)

  const renderCount = useCallback((data: ResolvedResource[], text: string, icon: ReactElement): ReactElement => {
    return (
      <div className="resource-count">
        <div className="icon">{icon}</div>
        <div className="text">
          <small>
            {formatMessage(TXT(text, { count: data.length }))}
          </small>
        </div>
      </div>
    )
  }, [formatMessage])

  const renderImage = useCallback((item: ResolvedResource): ReactElement => {
    const mediaItem = (medias.get(item.id) || [])[0] || null

    return (
      <ResourceImageView item={item} image={mediaItem} />
    )
  }, [medias])

  return (
    <Div layout="grid 12">
      <Div>
        <Span className="header">{formatMessage(TXT('label.availableServices'))}</Span>
      </Div>
      <Div>
        <Div layout="grid 12 3@lg">
          {vipLounges.length > 0 &&
            <Div>
              {renderCount(vipLounges, 'label.vipCount', Icons.Resources.Lounges.Vip)}
            </Div>
          }
          {bizLounges.length > 0 &&
            <Div>
              {renderCount(bizLounges, 'label.businessCount', Icons.Resources.Lounges.Business)}
            </Div>
          }
          {cmfLounges.length > 0 &&
            <Div>
              {renderCount(cmfLounges, 'label.comfortCount', Icons.Resources.Lounges.Comfort)}
            </Div>
          }
          {ondLounges.length > 0 &&
            <Div>
              {renderCount(ondLounges, 'label.delegationCount', Icons.Resources.Lounges.Delegation)}
            </Div>
          }
          {fstServices.length > 0 &&
            <Div>
              {renderCount(fstServices, 'label.fastTrackCount', Icons.Resources.Services.FastTrack)}
            </Div>
          }
          {mnaServices.length > 0 &&
            <Div>
              {renderCount(mnaServices, 'label.meetAndAssistCount', Icons.Resources.Services.MeetAndAssist)}
            </Div>
          }
          {mngServices.length > 0 &&
            <Div>
              {renderCount(mngServices, 'label.meetAndGreetCount', Icons.Resources.Services.MeetAndGreet)}
            </Div>}
          {prkServices.length > 0 &&
            <Div>
              {renderCount(prkServices, 'label.parkingCount', Icons.Resources.Services.Parking)}
            </Div>
          }
        </Div>
      </Div>
      {vipLounges.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.vipLounges'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {vipLounges.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {bizLounges.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.businessLounges'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {bizLounges.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {cmfLounges.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.comfortLounges'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {cmfLounges.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {ondLounges.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.delegationLounges'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {ondLounges.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {fstServices.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.fastTrackServices'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {fstServices.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {mnaServices.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.meetAndAssistServices'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {mnaServices.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {mngServices.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.meetAndGreetServices'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {mngServices.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
      {prkServices.length > 0 &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.parkingServices'))}</Span>
            </Div>
            <Div layout="grid 12 3@lg">
              {prkServices.map((item, index) => (
                <Div key={index}>
                  {renderImage(item)}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      }
    </Div>
  )
}

export interface ServicePriceProps {
  item: Service;
  currency: string;
}

export function ServicePrice(props: ServicePriceProps) {
  const { item, currency } = props

  const formatCostRange = useFormatCostRange()
  const values = item.costs.map(x => x.value).sort()

  if (values.length === 0) return null

  return (
    <span>{formatCostRange(values[0], values[values.length - 1], currency)}</span>
  )
}

export interface ResourceDetailsViewProps extends ViewProps<null> {
  data: { item: ResolvedResource, images: Media[], maps: Media[], provider: Organization, programs: Chunk<OrganizationProgram>, rates: Chunk<ResolvedRate>, services: Chunk<ResolvedService>, medias: Map<string, Media[]>, ratesByContract: Map<ResolvedOrganizationContract, Chunk<ResolvedRate>> };
  onRateFilter: ItemFilterHandler<RateFilter>;
  onBook?: ActionHandler;
}

export function ResourceDetailsView(props: ResourceDetailsViewProps): ReactElement {
  const { data, onBook } = props
  const { item, images, maps, provider, programs, rates, services, medias, ratesByContract } = data

  const formatMessage = useFormatMessage()
  const formatCost = useFormatCost()
  const [, formatFileUrl] = useFileUrl()
  const [, formatDirection] = useFlightDirections()
  const [, formatFeatureText] = useResourceFeatures()
  const [, formatFeatureIcon] = useResourceFeatureIcons()
  const [, formatMode] = useServiceModes()
  const [, formatUnit] = useServiceUnits()

  const [width, setWidth] = useState<number>(1)
  const [, innerWidth] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [innerWidth])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [setWidth])

  const location = item.location ? item.location : item.terminal ? item.terminal : maps.length > 0 ? formatMessage(TXT('label.scheme')) : undefined

  const [schemeToggled, setSchemeToggled] = useState(false)

  const toggleScheme = useCallback((): void => {
    setSchemeToggled(prev => !prev)
  }, [setSchemeToggled])

  const [programsToggled, setProgramsToggled] = useState(false)

  const togglePrograms = useCallback((): void => {
    setProgramsToggled(prev => !prev)
  }, [setProgramsToggled])

  const [legalRequisites] = useOrganizationLegalRequisites(provider.id)

  const phone = item.phone || (legalRequisites ? legalRequisites.supportPhone || legalRequisites.generalPhone : undefined)
  const email = legalRequisites ? legalRequisites.email : undefined

  const [minimalCostValue, setMinimalCostValue] = useState<number | null | undefined>(undefined)
  const [currency, setCurrency] = useState<string | null>(null)
  const [baseCostsByContract, setBaseCostsByContract] = useState<{ contract: ResolvedOrganizationContract; adult: string; child: string; infant: string }[] | undefined>(undefined)
  useEffect(
    () => {
      setBaseCostsByContract((prev) => {
        if (prev !== undefined) return prev

        const curr: { contract: ResolvedOrganizationContract; adult: string; child: string; infant: string }[] = []
        for (const contract of Array.from(ratesByContract.keys())) {
          const rates = ratesByContract.get(contract)!
          const individualRates = rates.data.filter((rate) => !rate.rules.some((rule) => rule.name === 'GroupRateRule' && (rule as GroupRateRule).minSize > 0))
          const baseRate =
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Departure)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Any)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Departure)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Any)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Arrival)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Any)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Arrival)[0] ??
            individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Any)[0] ??
            individualRates.filter((rate) => rate.type !== ServiceType.ArrivalAndDeparture && rate.type !== ServiceType.DepartureAndArrival)[0] ?? null
          if (baseRate) {
            curr.push({
              contract,
              adult: `${baseRate.costs.filter((x) => x.category === PassengerCategory.Adult)[0]?.value ?? '??'} ${baseRate.currency.code}`,
              child: `${baseRate.costs.filter((x) => x.category === PassengerCategory.Child)[0]?.value ?? baseRate.costs.filter((x) => x.category === PassengerCategory.Adult)[0]?.value ?? '??'}  ${baseRate.currency.code}`,
              infant: `${baseRate.costs.filter((x) => x.category === PassengerCategory.Infant)[0]?.value ?? baseRate.costs.filter((x) => x.category === PassengerCategory.Adult)[0]?.value ?? '??'}  ${baseRate.currency.code}`,
            })
          }
        }

        return curr
      })

      setMinimalCostValue((prev) => {
        if (prev !== undefined) return prev

        if (rates.data.length > 0) {
          setCurrency(rates.data[0].currency.code)
        }

        const individualRates = rates.data.filter((rate) => !rate.rules.some((rule) => rule.name === 'GroupRateRule' && (rule as GroupRateRule).minSize > 0))
        const costs = individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Departure)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Any)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Departure)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Any)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Arrival)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.Domestic && rate.type === ServiceType.Any)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Arrival)[0]?.costs ??
          individualRates.filter((rate) => rate.flightDirection === FlightDirection.International && rate.type === ServiceType.Any)[0]?.costs ??
          individualRates.filter((rate) => rate.type !== ServiceType.ArrivalAndDeparture && rate.type !== ServiceType.DepartureAndArrival).flatMap((rate) => rate.costs)
        return costs
          .filter((cost) => cost.category === PassengerCategory.Adult)
          .reduce<number | null>((value, cost) => value === null ? cost.value : Math.min(value, cost.value), null)
      })
    },
    [rates.data, ratesByContract],
  )

  const [ratesToggled, setRatesToggled] = useState<ResolvedOrganizationContract | null>(null)
  const [baseRatesToggled, setBaseRatesToggled] = useState(false)

  const [defaults] = useOrganizationDefaults(provider.id)
  const [settings] = useResourceSettings(item.id)

  const minAdultAge = settings?.minAdultAge ?? defaults?.childAge
  const minChildAge = settings?.minChildAge ?? defaults?.infantAge

  const renderContractNumber = useCallback(
    (item: { contract: ResolvedOrganizationContract }): ReactElement => {
      return <Span intent={'primary'} style={{ cursor: 'pointer' }} onClick={() => setRatesToggled(item.contract)}>{item.contract.number}</Span>
    },
    [],
  )

  const [, formatRateType] = useServiceTypes()
  const [, formatFlightDirection] = useFlightDirections()
  const formatBoolean = useFormatBoolean()
  const formatTime = useFormatTime()

  const processedRates = useMemo(
    () => {
      const processedData = new Chunk<any>()
      if (ratesToggled === null) return processedData

      for (const item of ratesByContract.get(ratesToggled)?.data ?? []) {
        const costs = item.costs
        const fines = item.fines

        processedData.data.push({
          id: item.id,
          code: item.code,
          name: item.name,
          type: `${formatRateType(item.type)}`,
          flightDirection: `${formatFlightDirection(item.flightDirection)}`,
          group: formatBoolean(item.rules.some((rule) => rule.name === "GroupRateRule" && (rule as GroupRateRule).minSize > 1)),
          adultCost: formatCost(costs.filter(x => x.category === PassengerCategory.Adult)[0]?.value ?? 0, item.currency.code),
          childCost: formatCost(costs.filter(x => x.category === PassengerCategory.Child)[0]?.value ?? costs.filter(x => x.category === PassengerCategory.Adult)[0]?.value ?? 0, item.currency.code),
          infantCost: formatCost(costs.filter(x => x.category === PassengerCategory.Infant)[0]?.value ?? costs.filter(x => x.category === PassengerCategory.Adult)[0]?.value ?? 0, item.currency.code),
          fines: fines.map(x => `За ${formatTime(x.flightDelta)}ч — ${x.valueAfter}%`).join(' / '),
        })
      }

      return processedData
    },
    [ratesToggled, ratesByContract, formatRateType, formatFlightDirection, formatCost, formatBoolean, formatTime],
  )

  const [ratesWidth, setRatesWidth] = useState<number>(1)
  const [, innerRatesWidth] = useWindowSize()

  useEffect(() => {
    setRatesWidth(1)
  }, [innerWidth])

  const handleRatesResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setRatesWidth(rect.bounds.width)
    }
  }, [setRatesWidth])

  return (
    <Div layout="grid 12">
      <Div>
        <Span className="header">{formatMessage(TXT('label.information'))}</Span>
      </Div>
      {item.description &&
        <Div>
          <Div dangerouslySetInnerHTML={{ __html: item.description }} />
        </Div>
      }
      <Div>
        <Div layout={images.length > 0 ? 'grid 12 6@lg' : 'grid 12'}>
          {images.length > 0 &&
            <Measure client={true} bounds={true} onResize={handleResize}>
              {({ measureRef }) =>
                <Div ref={measureRef}>
                  <Carousel width={`${width}px`} data={images.map(item => ({
                    original: `${formatFileUrl(item.fileId)}/thumbnail`,
                    thumbnail: `${formatFileUrl(item.fileId)}/thumbnail`,
                  }))} />
                </Div>
              }
            </Measure>
          }
          <Div>
            <Div layout="grid 12">
              {location &&
                <Div className="description">
                  <Span className="icon">{Icons.Description.Location}</Span>
                  <Span className="text">
                    {maps.length > 0 ? <RelLink to="#scheme" onClick={toggleScheme}>{location}</RelLink> : location}
                  </Span>
                </Div>
              }
              {item.schedule &&
                <Div className="description">
                  <Span className="icon">{Icons.Description.Schedule}</Span>
                  <Span className="text">{item.schedule}</Span>
                </Div>
              }
              <Div className="description">
                <Span className="icon">{Icons.Description.Direction}</Span>
                <Span className="text">{formatDirection(item.flightDirection)}</Span>
              </Div>
              {phone &&
                <Div className="description">
                  <Span className="icon">{Icons.Description.Phone}</Span>
                  <Span className="text"><a href={`tel:+7 ${phone}`}>+7 {phone}</a></Span>
                </Div>
              }
              {email &&
                <Div className="description">
                  <Span className="icon">{Icons.Description.Email}</Span>
                  <Span className="text"><a href={`mailto:${email}`}>{email}</a></Span>
                </Div>
              }
              {item.kind === ServiceKind.VipLounge && programs.data.length > 0 &&
                <Div className="description">
                  <Span className="icon">{Icons.Navigation.Items.Membership}</Span>
                  <Span className="text"><RelLink to="#programs"
                    onClick={togglePrograms}>{formatMessage(TXT('hint.thereArePrograms'))}</RelLink></Span>
                </Div>
              }
              {item.features && item.features.length > 0 &&
                <Div className="description">
                  <Div layout="grid 12">
                    <Div className="text">
                      <Span><b>{formatMessage(TXT('label.features'))}</b></Span>
                    </Div>
                    <Div className="features">
                      {item.features.map((item, index) => (
                        <Span key={index} className="icon"
                          title={formatFeatureText(item)}>{formatFeatureIcon(item)}</Span>
                      ))}
                    </Div>
                  </Div>
                </Div>
              }
              {minAdultAge &&
                <Div className="description">
                  <Div className="text">
                    <Span><b>{formatMessage(TXT('label.adultAge'))}:</b> {minAdultAge}–∞</Span>
                  </Div>
                </Div>
              }
              {minAdultAge && minChildAge &&
                <Div className="description">
                  <Div className="text">
                    <Span><b>{formatMessage(TXT('label.childAge'))}:</b> {minChildAge}–{minAdultAge}</Span>
                  </Div>
                </Div>
              }
              {minChildAge &&
                <Div className="description">
                  <Div className="text">
                    <Span><b>{formatMessage(TXT('label.infantAge'))}:</b> {0}–{minChildAge}</Span>
                  </Div>
                </Div>
              }
              {minimalCostValue !== null && minimalCostValue !== undefined && currency !== null &&
                <Div className="description" style={{ display: 'none' }}>
                  <Div className="text">
                    <Span><b>{formatMessage(TXT('label.baseRate'))}</b> {formatCost(minimalCostValue, currency)} <RelLink
                      to="#rates"
                      onClick={() => setBaseRatesToggled(true)}>{formatMessage(TXT('label.moreRates'))}</RelLink></Span>
                  </Div>
                </Div>
              }
              {baseCostsByContract &&
                <Div className={'description'}>
                  <Span><b>{formatMessage(TXT('label.baseRate'))}</b></Span>
                  <Grid data={baseCostsByContract} onItemSelect={(value) => setRatesToggled(value.contract)}>
                    <GridColumn title={TXT('label.contract')} field="contract.number" sortable={false} itemRenderer={renderContractNumber} locked={true} />
                    <GridColumn title={TXT('label.adult')} field="adult" sortable={false} />
                    <GridColumn title={TXT('label.child')} field="child" sortable={false} />
                    <GridColumn title={TXT('label.infant')} field="infant" sortable={false} />
                  </Grid>
                </Div>
              }
              <Div>
                <Div layout="flex">
                  <Div layout="fill">
                    {(minimalCostValue === null || minimalCostValue === undefined) &&
                      <small>{formatMessage(TXT('hint.bookingUnavailable'))}</small>}
                  </Div>
                  {onBook &&
                    <Div layout="fit">
                      <Button disabled={ratesByContract.size === 0} primary={true}
                        className="action" onClick={onBook}>
                        {formatMessage(TXT('action.book'))}
                      </Button>
                    </Div>
                  }
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div>
        {schemeToggled &&
          <Drawer>
            <Div layout="grid 12">
              <Div>
                <Span className="header">{formatMessage(TXT('label.maps'))}</Span>
              </Div>
              <Div>
                <Carousel width={`${width}px`} data={maps.map(item => ({
                  original: `${formatFileUrl(item.fileId)}`,
                  thumbnail: `${formatFileUrl(item.fileId)}`,
                }))} />
              </Div>
              <Div>
                <Div layout="flex">
                  <Div layout="fill" />
                  <Div layout="fit">
                    <Button className="action" primary={true} look="outline" onClick={toggleScheme}>
                      {formatMessage(TXT('action.close'))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Drawer>
        }
        {programsToggled &&
          <Drawer onClose={togglePrograms}>
            <Div layout="grid 12">
              <Div>
                <Span className="header">{formatMessage(TXT('label.programs'))}</Span>
              </Div>
              <Div>
                <CardDeck>
                  <Div layout="grid 12">
                    {programs.data.map((item, index) =>
                      <Div key={index}>
                        <CardItem style={{ width: '100%' }}>
                          <CardItemHeader>
                            <CardItemTitle>{item.name}</CardItemTitle>
                          </CardItemHeader>
                          <CardItemBody>
                            <CorporateProgramView item={item} />
                          </CardItemBody>
                        </CardItem>
                      </Div>,
                    )}
                  </Div>
                </CardDeck>
              </Div>
              <Div>
                <Div layout="flex">
                  <Div layout="fill" />
                  <Div layout="fit">
                    <Button className="action" primary={true} look="outline" onClick={togglePrograms}>
                      {formatMessage(TXT('action.close'))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Drawer>
        }
        {baseRatesToggled &&
          <Drawer>
            <Div layout="grid 12">
              <Div>
                <Span className="header">{formatMessage(TXT('label.rates'))}</Span>
              </Div>
              <Div>
                <RateGrid
                  data={rates}
                  showTimes={true}
                  showTypes={true}
                  showGroup={true}
                />
              </Div>
              <Div>
                <Div layout="flex">
                  <Div layout="fill" />
                  <Div layout="fit">
                    <Button className="action" primary={true} look="outline" onClick={() => setBaseRatesToggled(false)}>
                      {formatMessage(TXT('action.close'))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Drawer>
        }
        {ratesToggled &&
          <Drawer>
            <Div layout="grid 12">
              <Div>
                <Span className="header">{formatMessage(TXT('label.rates'))} ({ratesToggled.number})</Span>
              </Div>
              <Measure client={true} bounds={true} onResize={handleRatesResize}>
                {({ measureRef }) =>
                  <Div ref={measureRef}>
                    <Grid data={processedRates} width={ratesWidth}>
                      <GridColumn title={TXT('label.code')} field="code" width={92} sortable={false} locked={true} />
                      <GridColumn title={TXT('label.serviceType')} field="type" width={128} sortable={false} />
                      <GridColumn title={TXT('label.flightDirection')} field="flightDirection" width={128} sortable={false} />
                      <GridColumn title={TXT('label.groupRate')} field="group" width={128} sortable={false} />
                      <GridColumn title={TXT('label.name')} field="name" width={256} />
                      <GridColumn title={TXT('label.fines')} field="fines" width={256} sortable={false} />
                      <GridColumn title={TXT('label.adultRange', { min: minAdultAge, max: '∞' })} field="adultCost" width={160} sortable={false} locked={true} />
                      <GridColumn title={TXT('label.childRange', { min: minChildAge, max: minAdultAge })} field="childCost" width={160} sortable={false} locked={true} />
                      <GridColumn title={TXT('label.infantRange', { min: 0, max: minChildAge })} field="infantCost" width={160} sortable={false} locked={true} />
                    </Grid>
                  </Div>
                }
              </Measure>
              <Div>
                <Div layout="flex">
                  <Div layout="fill" />
                  <Div layout="fit">
                    <Button className="action" primary={true} look="outline" onClick={() => setRatesToggled(null)}>
                      {formatMessage(TXT('action.close'))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Drawer>
        }
      </Div>
      {services.data.length > 0 &&
        <Div>
          <Span className="header">{formatMessage(TXT('label.services'))}</Span>
        </Div>
      }
      {currency && services.data.map((serviceItem, serviceIndex) =>
        <Div key={serviceIndex}>
          <Div layout="grid">
            <Div layout="9" className="description">
              <Div>
                <Span>
                  <b>{serviceItem.name}:</b> <ServicePrice item={serviceItem}
                    currency={currency} /> {formatUnit(serviceItem.unit).toLowerCase()}
                </Span>
              </Div>
              {serviceItem.description &&
                <Div>
                  <Div dangerouslySetInnerHTML={{ __html: serviceItem.description }} />
                </Div>
              }
              <Div>
                <Span><i>{formatMessage(TXT('label.serviceMode'))}: {formatMode(serviceItem.mode)}</i></Span>
              </Div>
            </Div>
            <Div layout="3">
              {(medias.get(serviceItem.id) || []).length > 0 &&
                <Carousel data={(medias.get(serviceItem.id) || []).map(item => ({
                  original: `${formatFileUrl(item.fileId)}`,
                  thumbnail: `${formatFileUrl(item.fileId)}`,
                }))} showFullscreen={false} showThumbnails={false} />
              }
            </Div>
          </Div>
        </Div>,
      )}
    </Div>
  )
}
