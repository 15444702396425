import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext, IdentityProfile } from '../auth'
import { Chunk, Lock, LockAction } from '../data'
import { User } from '../data/models'
import { Drawer, NavPathItem } from '../gears'
import { TextBox } from '../gears/inputs'
import { LockAccess, OrganizationAccess, OrganizationContractAccess, UserAccess } from '../parts/data/access'
import { CityBase, CityReference, CurrencyBase, DepositOperation, EMPTY_ID, Organization, OrganizationBase, OrganizationContract, OrganizationContractCreateModel, OrganizationContractType, OrganizationCreateModel, OrganizationType, PoolBase } from '../parts/data/models'
import { Button, Div, Loader, Menu, MenuItem, Span, TXT } from '../parts/gears'
import { useFormatMessage } from '../parts/hooks'
import { ContractGrid, ContractItem, DepositGrid, DepositView, OrganizationGrid, OrganizationItem, UserGrid } from '../parts/views'
import { PageProps, withPage } from './withPage'

function OrganizationsPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  return (
    <Switch>
      <Route exact={false} path={`${match.path}/:organizationId/contracts/:contractId`} render={props => (
        <Div layout="grid 12">
          {props.match.params.contractId !== 'new' &&
            <Div>
              <Menu>
                <MenuItem title={TXT('label.contract')}
                  path={`${match.path}/${props.match.params.organizationId}/contracts/${props.match.params.contractId}`} />
                <MenuItem title={TXT('label.deposit')}
                  path={`${match.path}/${props.match.params.organizationId}/contracts/${props.match.params.contractId}/deposit`} />
              </Menu>
            </Div>
          }
          <Div>
            <Switch>
              <Route exact={true}
                path={`${match.path}/:organizationId/contracts/:contractId`}
                render={props => <ContractItemPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                  onError={onError} />} />
              <Route exact={true}
                path={`${match.path}/:organizationId/contracts/:contractId/deposit`}
                render={props => <DepositPage {...props} onLoaded={onLoaded} onError={onError} />} />
            </Switch>
          </Div>
        </Div>
      )} />
      <Route exact={false} path={`${match.path}/:organizationId`} render={props => (
        <Div layout="grid 12">
          {props.match.params.organizationId !== 'new' &&
            <Div>
              <Menu>
                <MenuItem title={TXT('label.organization')}
                  path={`${match.path}/${props.match.params.organizationId}`} />
                <MenuItem title={TXT('label.contracts')}
                  path={`${match.path}/${props.match.params.organizationId}/contracts`} />
                {!auth.profile.isAgent &&
                  <MenuItem title={TXT('label.users')}
                    path={`${match.path}/${props.match.params.organizationId}/users`} />
                }
              </Menu>
            </Div>
          }
          <Div>
            <Switch>
              <Route exact={true}
                path={`${match.path}/:organizationId`}
                render={props => <OrganizationItemPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                  onError={onError} />} />
              <Route exact={true}
                path={`${match.path}/:organizationId/contracts`}
                render={props => <ContractListPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                  onError={onError} />} />
              {!auth.profile.isAgent &&
                <Route exact={true}
                  path={`${match.path}/:organizationId/users`}
                  render={props => <UserPage {...props} onLoaded={onLoaded} onError={onError} />} />
              }
            </Switch>
          </Div>
        </Div>
      )} />
      <Route exact={true}
        path={`${match.path}`}
        render={props => <OrganizationListPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
          onError={onError} />} />
    </Switch>
  )
}

interface OrganizationItemPageProps extends RouteComponentProps<{ organizationId: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function OrganizationItemPage(props: OrganizationItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const itemId = match.params.organizationId || 'new'
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState(new Organization(CityReference))
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const item = await OrganizationAccess.getOne(itemId)
            setItem(item)
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId,
    setLoaded, setLoading, setItem, setLock, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([item])
    }
  }, [loaded, item, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (model: OrganizationCreateModel): Promise<void> => {
    try {
      if (itemId !== 'new') {
        const updated = await OrganizationAccess.update(itemId, model).then(() => OrganizationAccess.getOne(itemId))
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await OrganizationAccess.create(model)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <OrganizationItem item={item}
        lock={lock}
        readonly={readonly}
        showTypes={auth.profile.isSys}
        onSubmit={auth.profile.isSys ? handleSubmit : undefined}
        onCancel={auth.profile.isSys ? handleCancel : undefined}
        onEdit={auth.profile.isSys ? handleEdit : undefined}
        onFree={auth.profile.isSys ? handleFree : undefined} />
    </Loader>
  )
}

interface OrganizationListPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function OrganizationListPage(props: OrganizationListPageProps): ReactElement {
  const { onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const formatMessage = useFormatMessage()

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState<Chunk<Organization<CityBase>>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const [authorizedData, setAuthorizedData] = useState<Chunk<Organization<CityBase>>>(new Chunk())
  const [authorizedSort, setAuthorizedSort] = useState('')
  const [authorizedSkip, setAuthorizedSkip] = useState(0)
  const [authorizedTake, setAuthorizedTake] = useState(15)
  const [authorizedPattern, setAuthorizedPattern] = useState('')

  const [unauthorizedData, setUnauthorizedData] = useState<Chunk<Organization<CityBase>>>(new Chunk())
  const [unauthorizedSort, setUnauthorizedSort] = useState('')
  const [unauthorizedSkip, setUnauthorizedSkip] = useState(0)
  const [unauthorizedTake, setUnauthorizedTake] = useState(15)
  const [unauthorizedPattern, setUnauthorizedPattern] = useState('')

  const [appendToggled, setAppendToggled] = useState(false)
  const [generatedCode, setGeneratedCode] = useState<string | null>(null)
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null)

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  const reloadAuthorized = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setAuthorizedPattern(pattern)
    if (sort !== undefined) setAuthorizedSort(sort)
    if (skip !== undefined) setAuthorizedSkip(skip)
    if (take !== undefined) setAuthorizedTake(take)
    setLoading(true)
  }, [setLoading, setAuthorizedPattern, setAuthorizedSort, setAuthorizedSkip, setAuthorizedTake])

  const reloadUnauthorized = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setUnauthorizedPattern(pattern)
    if (sort !== undefined) setUnauthorizedSort(sort)
    if (skip !== undefined) setUnauthorizedSkip(skip)
    if (take !== undefined) setUnauthorizedTake(take)
    setLoading(true)
  }, [setLoading, setUnauthorizedPattern, setUnauthorizedSort, setUnauthorizedSkip, setUnauthorizedTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (auth.profile.isSys) {
            const data = await OrganizationAccess.getAll(pattern, sort, skip, take)
            setData(data)
          } else {
            const authorizedData = await OrganizationAccess.getAll(authorizedPattern, authorizedSort, authorizedSkip, authorizedTake, {
              isConcluded: true,
            })
            setAuthorizedData(authorizedData)
            const unauthorizedData = await OrganizationAccess.getAll(unauthorizedPattern, unauthorizedSort, unauthorizedSkip, unauthorizedTake, {
              isConcluded: false,
            })
            setUnauthorizedData(unauthorizedData)
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading,
    auth.profile.isSys,
    pattern, sort, skip, take, setData,
    authorizedPattern, authorizedSort, authorizedSkip, authorizedTake, setAuthorizedData,
    unauthorizedPattern, unauthorizedSort, unauthorizedSkip, unauthorizedTake, setUnauthorizedData,
    setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [loaded, onLoaded])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`)
  }, [onNavigate])

  const handleItemSelect = useCallback((record: Organization): void => {
    onNavigate(`${record.id}`)
  }, [onNavigate])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handleAuthorizedItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(authorizedPattern, newPattern)) {
      reloadAuthorized(newPattern)
    }
  }, [authorizedPattern, reloadAuthorized])

  const handleUnauthorizedItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(unauthorizedPattern, newPattern)) {
      reloadUnauthorized(newPattern)
    }
  }, [unauthorizedPattern, reloadUnauthorized])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleAuthorizedPageChange = useCallback((skip: number, take: number): void => {
    reloadAuthorized(undefined, undefined, skip, take)
  }, [reloadAuthorized])

  const handleUnauthorizedPageChange = useCallback((skip: number, take: number): void => {
    reloadUnauthorized(undefined, undefined, skip, take)
  }, [reloadUnauthorized])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  const handleAuthorizedSortChange = useCallback((sort: string): void => {
    reloadAuthorized(undefined, sort, undefined, undefined)
  }, [reloadAuthorized])

  const handleUnauthorizedSortChange = useCallback((sort: string): void => {
    reloadUnauthorized(undefined, sort, undefined, undefined)
  }, [reloadUnauthorized])

  const toggleItemAppend = useCallback((): void => {
    setAppendToggled((prev) => !prev)
  }, [setAppendToggled])

  const handlePrepareCode = useCallback(async (): Promise<void> => {
    try {
      const organization = await OrganizationAccess.getOne(auth.profile.belongsTo)
      const token = await OrganizationAccess.prepareToken(organization.id)
      setGeneratedCode(token.value)
    } catch (e) {
      onError(e)
    }
  }, [auth.profile.belongsTo, onError, setGeneratedCode])

  const handleConfirmCode = useCallback(async (): Promise<void> => {
    try {
      const organization = await OrganizationAccess.getOne(auth.profile.belongsTo)
      await OrganizationAccess.confirmToken(organization.id, { value: confirmationCode || '' })
      reloadUnauthorized()
      toggleItemAppend()
    } catch (e) {
      onError(e)
    }
  }, [auth.profile.belongsTo, onError, confirmationCode, toggleItemAppend, reloadUnauthorized])

  return (
    <Loader loading={!loaded && loading}>
      {auth.profile.isSys &&
        <section>
          <OrganizationGrid data={data}
            showTypes={true}
            onItemCreate={handleItemCreate}
            onItemSelect={handleItemSelect}
            onItemSearch={handleItemSearch}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange} />
        </section>
      }
      {!auth.profile.isSys &&
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.authorized'))}</Span>
            </Div>
            <Div>
              <OrganizationGrid data={authorizedData}
                showActive={true}
                onItemSelect={handleItemSelect}
                onItemSearch={handleAuthorizedItemSearch}
                onPageChange={handleAuthorizedPageChange}
                onSortChange={handleAuthorizedSortChange} />
            </Div>
          </Div>
        </section>
      }
      {!auth.profile.isSys &&
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.unauthorized'))}</Span>
            </Div>
            <Div>
              <OrganizationGrid data={unauthorizedData}
                onItemAppend={toggleItemAppend}
                onItemSelect={handleItemSelect}
                onItemSearch={handleUnauthorizedItemSearch}
                onPageChange={handleUnauthorizedPageChange}
                onSortChange={handleUnauthorizedSortChange} />
            </Div>
          </Div>
        </section>
      }
      {appendToggled &&
        <Drawer>
          <Div layout="grid 12">
            <Div>
              <Div layout="flex">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" icon="close" onClick={toggleItemAppend} />
                </Div>
              </Div>
            </Div>
            <Div>
              <Div>
                <FormattedMessage {...TXT('message.organizationPrepareCode')} />
              </Div>
              <Div layout="flex">
                <Div layout="fill">
                  <TextBox fill={true} name="generatedCode" value={generatedCode} />
                </Div>
                <Div layout="fit">
                  <Button primary={true} className="action" onClick={handlePrepareCode}>
                    <FormattedMessage {...TXT("action.prepareCode")} />
                  </Button>
                </Div>
              </Div>
            </Div>
            <Div>
              <Div>
                <FormattedMessage {...TXT('message.organizationConfirmCode')} />
              </Div>
              <Div layout="flex">
                <Div layout="fill">
                  <TextBox fill={true} name="confirmationCode" value={confirmationCode} onChange={setConfirmationCode} />
                </Div>
                <Div layout="fit">
                  <Button primary={true} className="action" onClick={handleConfirmCode}>
                    <FormattedMessage {...TXT("action.confirmCode")} />
                  </Button>
                </Div>
              </Div>
            </Div>
          </Div>
        </Drawer>
      }
    </Loader>
  )
}

interface ContractItemPageProps extends RouteComponentProps<{ organizationId: string, contractId: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ContractItemPage(props: ContractItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const organizationId = match.params.organizationId || ''
  const itemId = match.params.contractId || 'new'
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [organizationItem, setOrganizationItem] = useState(new Organization(CityReference))
  const [item, setItem] = useState(new OrganizationContract(OrganizationBase, PoolBase, CurrencyBase))
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const organizationItem = await OrganizationAccess.getOne(organizationId)
          setOrganizationItem(organizationItem)
          if (!isNew) {
            const contractItem = await OrganizationContractAccess.getOne(itemId)
            setItem(contractItem)
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            if (!auth.profile.isSys) {
              const provider = await OrganizationAccess.getOne(auth.profile.belongsTo)
              const agency = await OrganizationAccess.getOne(organizationItem.id)
              setItem(prev => ({
                ...prev,
                provider,
                agency,
              }))
            } else {
              const provider = await OrganizationAccess.getOne(organizationItem.id)
              setItem(prev => ({
                ...prev,
                provider,
              }))
            }
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, auth.profile.isSys, auth.profile.belongsTo, organizationId, itemId,
    setLoaded, setLoading, setOrganizationItem, setItem, setLock, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([organizationItem, item])
    }
  }, [loaded, organizationItem, item, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${organizationId}/contracts/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(`${organizationId}/contracts`)
    }
  }, [organizationId, setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: OrganizationContractCreateModel): Promise<void> => {
    try {
      if (itemId !== 'new') {
        const updated = await OrganizationContractAccess.update(itemId, item)
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await OrganizationContractAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await OrganizationContractAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <ContractItem item={item}
        lock={lock}
        readonly={readonly}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDelete={(item.provider.code === auth.profile.belongsTo.toUpperCase() || auth.profile.isSys) && itemId !== 'new' ? handleDelete : undefined}
        onEdit={handleEdit}
        onFree={handleFree} />
    </Loader>
  )
}

interface ContractListPageProps extends RouteComponentProps<{ organizationId: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ContractListPage(props: ContractListPageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const formatMessage = useFormatMessage()

  const organizationId = match.params.organizationId || ''

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [organizationItem, setOrganizationItem] = useState(new Organization(CityReference))
  const [outgoingData, setOutgoingData] = useState<Chunk<OrganizationContract<OrganizationBase>>>(new Chunk())
  const [outgoingSort, setOutgoingSort] = useState('')
  const [outgoingSkip, setOutgoingSkip] = useState(0)
  const [outgoingTake, setOutgoingTake] = useState(15)
  const [outgoingPattern, setOutgoingPattern] = useState('')
  const [incomingData, setIncomingData] = useState<Chunk<OrganizationContract<OrganizationBase>>>(new Chunk())
  const [incomingSort, setIncomingSort] = useState('')
  const [incomingSkip, setIncomingSkip] = useState(0)
  const [incomingTake, setIncomingTake] = useState(15)
  const [incomingPattern, setIncomingPattern] = useState('')

  const reloadOutgoing = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setOutgoingPattern(pattern)
    if (sort !== undefined) setOutgoingSort(sort)
    if (skip !== undefined) setOutgoingSkip(skip)
    if (take !== undefined) setOutgoingTake(take)
    setLoading(true)
  }, [setLoading, setOutgoingPattern, setOutgoingSort, setOutgoingSkip, setOutgoingTake])

  const reloadIncoming = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setIncomingPattern(pattern)
    if (sort !== undefined) setIncomingSort(sort)
    if (skip !== undefined) setIncomingSkip(skip)
    if (take !== undefined) setIncomingTake(take)
    setLoading(true)
  }, [setLoading, setIncomingPattern, setIncomingSort, setIncomingSkip, setIncomingTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const organizationItem = await OrganizationAccess.getOne(organizationId)
          const organization = await OrganizationAccess.getOne(organizationItem.code)
          const belongsTo = await OrganizationAccess.getOne(auth.profile.belongsTo)
          setOrganizationItem(organizationItem)
          if (auth.profile.isSys) {
            const outgoingData = await OrganizationAccess.getOneContracts(organizationItem.id, outgoingPattern, outgoingSort, outgoingSkip, outgoingTake, OrganizationContractType.Deposit, organization.id, undefined)
            setOutgoingData(outgoingData)
            const incomingData = await OrganizationAccess.getOneContracts(organizationItem.id, incomingPattern, incomingSort, incomingSkip, incomingTake, OrganizationContractType.Deposit, undefined, organization.id)
            setIncomingData(incomingData)
          }
          if (auth.profile.isOwner && organization.type === OrganizationType.Agency) {
            const outgoingData = await OrganizationAccess.getOneContracts(organizationItem.id, outgoingPattern, outgoingSort, outgoingSkip, outgoingTake, OrganizationContractType.Deposit, belongsTo.id, organization.id)
            setOutgoingData(outgoingData)
          }
          if (auth.profile.isAgent) {
            if (belongsTo.type === OrganizationType.Provider) {
              const incomingData = await OrganizationAccess.getOneContracts(organizationItem.id, incomingPattern, incomingSort, incomingSkip, incomingTake, OrganizationContractType.Deposit, organization.id, belongsTo.id)
              setIncomingData(incomingData)
            }
            if (belongsTo.type === OrganizationType.Agency) {
              const outgoingData = await OrganizationAccess.getOneContracts(organizationItem.id, outgoingPattern, outgoingSort, outgoingSkip, outgoingTake, OrganizationContractType.Deposit, belongsTo.id, organization.id)
              setOutgoingData(outgoingData)
              const incomingData = await OrganizationAccess.getOneContracts(organizationItem.id, incomingPattern, incomingSort, incomingSkip, incomingTake, OrganizationContractType.Deposit, organization.id, belongsTo.id)
              setIncomingData(incomingData)
            }
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    auth, loading,
    organizationId, setOrganizationItem,
    outgoingPattern, outgoingSort, outgoingSkip, outgoingTake, setOutgoingData,
    incomingPattern, incomingSort, incomingSkip, incomingTake, setIncomingData,
    setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([organizationItem])
    }
  }, [loaded, organizationItem, onLoaded])

  const handleOutgoingItemCreate = useCallback((): void => {
    onNavigate(`${organizationId}/contracts/new`)
  }, [organizationId, onNavigate])

  const handleOutgoingItemSelect = useCallback((record: OrganizationContract): void => {
    onNavigate(`${organizationId}/contracts/${record.id}`)
  }, [organizationId, onNavigate])

  const handleOutgoingItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(outgoingPattern, newPattern)) {
      reloadOutgoing(newPattern)
    }
  }, [outgoingPattern, reloadOutgoing])

  const handleOutgoingPageChange = useCallback((skip: number, take: number): void => {
    reloadOutgoing(undefined, undefined, skip, take)
  }, [reloadOutgoing])

  const handleOutgoingSortChange = useCallback((sort: string): void => {
    reloadOutgoing(undefined, sort, undefined, undefined)
  }, [reloadOutgoing])

  // const handleIncomingItemCreate = useCallback((): void => {
  //   onNavigate(`${organizationId}/contracts/new`)
  // }, [ organizationId, onNavigate ])

  const handleIncomingItemSelect = useCallback((record: OrganizationContract): void => {
    onNavigate(`${organizationId}/contracts/${record.id}`)
  }, [organizationId, onNavigate])

  const handleIncomingItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(incomingPattern, newPattern)) {
      reloadIncoming(newPattern)
    }
  }, [incomingPattern, reloadIncoming])

  const handleIncomingPageChange = useCallback((skip: number, take: number): void => {
    reloadIncoming(undefined, undefined, skip, take)
  }, [reloadIncoming])

  const handleIncomingSortChange = useCallback((sort: string): void => {
    reloadIncoming(undefined, sort, undefined, undefined)
  }, [reloadIncoming])

  return (
    <Loader loading={!loaded && loading}>
      {(auth.profile.isSys || (auth.profile.isOwner && organizationItem.type === OrganizationType.Agency) || (auth.profile.isAgent && organizationItem.type === OrganizationType.Agency)) &&
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.outgoingContracts'))}</Span>
            </Div>
            <Div>
              <ContractGrid data={outgoingData}
                showProviders={false}
                showAgencies={true}
                onItemCreate={handleOutgoingItemCreate}
                onItemSelect={handleOutgoingItemSelect}
                onItemSearch={handleOutgoingItemSearch}
                onPageChange={handleOutgoingPageChange}
                onSortChange={handleOutgoingSortChange} />
            </Div>
          </Div>
        </section>
      }
      {((auth.profile.isSys && organizationItem.type === OrganizationType.Agency) || (auth.profile.isAgent)) &&
        <section>
          <Div layout="grid 12">
            <Div>
              <Span className="header">{formatMessage(TXT('label.incomingContracts'))}</Span>
            </Div>
            <Div>
              <ContractGrid data={incomingData}
                showProviders={true}
                showAgencies={false}
                onItemCreate={undefined}
                onItemSelect={handleIncomingItemSelect}
                onItemSearch={handleIncomingItemSearch}
                onPageChange={handleIncomingPageChange}
                onSortChange={handleIncomingSortChange} />
            </Div>
          </Div>
        </section>
      }
    </Loader>
  )
}

interface DepositPageProps extends RouteComponentProps<{ organizationId: string, contractId: string }> {
  onLoaded: (items: any[]) => void;
  onError: (error: any) => void;
}

function DepositPage(props: DepositPageProps): ReactElement {
  const { match, onLoaded, onError } = props

  const auth = useContext(AuthContext)

  const organizationId = match.params.organizationId || ''
  const contractId = match.params.contractId || ''

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [organizationItem, setOrganizationItem] = useState(new Organization(CityReference))
  const [contractItem, setContractItem] = useState(new OrganizationContract<OrganizationBase, PoolBase, CurrencyBase>(OrganizationBase, PoolBase, CurrencyBase))
  const [data, setData] = useState<Chunk<DepositOperation>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')
  const [balance, setBalance] = useState(0)
  const [overdraft, setOverdraft] = useState(0)
  const [autoreplenish, setAutoreplenish] = useState(false)

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const [organizationItem, contractItem] = await Promise.all([
            await OrganizationAccess.getOne(organizationId),
            await OrganizationContractAccess.getOne(contractId),
          ])
          setOrganizationItem(organizationItem)
          setContractItem(contractItem)

          const [data, balance, overdraft, autoreplenish] = await Promise.all([
            await OrganizationContractAccess.getOneOperations(`${contractItem.provider.id}/${contractItem.agency.id}/${encodeURIComponent(contractItem.number)}`, pattern, sort, skip, take),
            await OrganizationContractAccess.getOneBalance(`${contractItem.provider.id}/${contractItem.agency.id}/${encodeURIComponent(contractItem.number)}`),
            await OrganizationContractAccess.getOneOverdraft(`${contractItem.provider.id}/${contractItem.agency.id}/${encodeURIComponent(contractItem.number)}`),
            await OrganizationContractAccess.getOneAutoreplenish(`${contractItem.provider.id}/${contractItem.agency.id}/${encodeURIComponent(contractItem.number)}`),
          ])
          setData(data)
          setBalance(balance)
          setOverdraft(overdraft)
          setAutoreplenish(autoreplenish)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, organizationId, contractId, pattern, sort, skip, take, setData,
    setLoaded, setLoading, setOrganizationItem, setContractItem, setBalance, setOverdraft, setAutoreplenish, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([organizationItem, contractItem])
    }
  }, [loaded, organizationItem, contractItem, onLoaded])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  const handleBalanceChange = useCallback((value: number): void => {
    OrganizationContractAccess.updateBalance(`${contractItem.provider.code}/${contractItem.agency.code}/${encodeURIComponent(contractItem.number)}`, value)
      .catch(error => onError(error))
      .finally(() => setLoading(true))
  }, [contractItem, setLoading, onError])

  const handleOverdraftChange = useCallback((value: number): void => {
    OrganizationContractAccess.updateOverdraft(`${contractItem.provider.code}/${contractItem.agency.code}/${encodeURIComponent(contractItem.number)}`, value)
      .catch(error => onError(error))
      .finally(() => setLoading(true))
  }, [contractItem, setLoading, onError])

  const handleAutoreplenishChange = useCallback((value: boolean): void => {
    OrganizationContractAccess.updateAutoreplenish(`${contractItem.provider.code}/${contractItem.agency.code}/${encodeURIComponent(contractItem.number)}`, value)
      .catch(error => onError(error))
      .finally(() => setLoading(true))
  }, [contractItem, setLoading, onError])

  return (
    <Loader loading={!loaded && loading}>
      <Div layout="grid 12">
        <Div>
          <DepositView currency={contractItem.currency.code}
            balance={balance}
            overdraft={overdraft}
            autoreplenish={autoreplenish}
            onBalanceChange={auth.profile.isSys || auth.profile.belongsTo.toUpperCase() === contractItem.provider.code ? handleBalanceChange : undefined}
            onOverdraftChange={auth.profile.isSys || auth.profile.belongsTo.toUpperCase() === contractItem.provider.code ? handleOverdraftChange : undefined}
            onAutoreplenishChange={auth.profile.isSys || auth.profile.belongsTo.toUpperCase() === contractItem.provider.code ? handleAutoreplenishChange : undefined} />
        </Div>
        <Div>
          <DepositGrid currency={contractItem.currency.code}
            data={data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange} />
        </Div>
      </Div>
    </Loader>
  )
}

interface UserPageProps extends RouteComponentProps<{ organizationId: string }> {
  onLoaded: (items: any[]) => void;
  onError: (error: any) => void;
}

function UserPage(props: UserPageProps) {
  const { match, onLoaded, onError } = props

  const auth = useContext(AuthContext)

  const organizationId = match.params.organizationId

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [organizationItem, setOrganizationItem] = useState(new Organization(CityReference))
  const [data, setData] = useState<Chunk<User>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const organizationItem = await OrganizationAccess.getOne(organizationId)
          setOrganizationItem(organizationItem)
          const data = await UserAccess.getAll(pattern, sort, skip, take, organizationItem.code)
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, organizationId, pattern, sort, skip, take, setOrganizationItem, setData, setLoaded, setLoading, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([organizationItem])
    }
  }, [loaded, organizationItem, onLoaded])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <UserGrid data={data}
        showOrganizations={auth.profile.isSys}
        onItemSearch={handleItemSearch}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange} />
    </Loader>
  )
}

function isOrganization(item: any): item is Organization {
  return (item as Organization).city !== undefined
}

function isContract(item: any): item is OrganizationContract<OrganizationBase, PoolBase, CurrencyBase> {
  return (item as OrganizationContract).number !== undefined
}

function pathBuilder(items: (Organization | OrganizationContract<OrganizationBase, PoolBase, CurrencyBase>)[], profile: IdentityProfile): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: profile.isSys ? TXT('page.organizations') : profile.isOwner ? TXT('page.agencies') : TXT('page.organizations'),
  })
  let id = ''
  for (const item of items) {
    if (isOrganization(item)) {
      id = item.code
      pathItems.push({
        path: item.id ? `${item.id}` : 'new',
        text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : TXT('page.organizations.new'),
      })
    }
    if (isContract(item)) {
      pathItems.push({
        path: item.id ? `${id}/contracts/${item.id}` : `${id}/contracts/new`,
        text: item.id !== EMPTY_ID ? `${item.agency.code} — ${item.agency.name} — ${item.number}` : TXT('page.contracts.new'),
      })
    }
  }

  return pathItems
}

export const Organizations = withPage(OrganizationsPage, pathBuilder)
Organizations.displayName = 'OrganizationsPage'
