import * as KendoDateTimes from "@progress/kendo-react-dateinputs";
import * as KendoInputs from "@progress/kendo-react-inputs";
import * as KendoIntl from "@telerik/kendo-intl";
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface TimeBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  name: string;
  value: string | null;
  error?: IntlMessage;
  validators?: ((value: string | null) => IntlMessage | undefined)[],
  onChange?: (value: string | null) => void;
}

interface TimeBoxProps {
  children?: null;
}

interface TimeBoxState {
}

export class TimeBox extends PureComponent<TimeBoxProps, TimeBoxState> {
  private readonly targetRef = React.createRef<KendoDateTimes.DateInput>();

  public constructor(props: TimeBoxProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount(): void {
    if (this.targetRef.current && this.targetRef.current.element) {
      this.targetRef.current.element.addEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public componentWillUnmount(): void {
    if (this.targetRef.current && this.targetRef.current.element) {
      this.targetRef.current.element.removeEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public render(): ReactNode {
    const { hint, label, disabled, readonly, fill, name, value, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;
    const formatPlaceholder = {
      year: 'ЧЧ',
      month: 'ММ',
      day: 'ДД',
      hour: 'ЧЧ',
      minute: 'мм',
      second: 'сс',
    };

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={true}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) => {
                 if (readonly || fieldReadonly) {
                   const parsedValue = this.parse(value);
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          value={parsedValue ? KendoIntl.formatDate(parsedValue, "yy:mm", intl.locale) : ""} />
                     </span>
                   );
                 } else {
                   return (
                     <span className={className}>
                       <KendoDateTimes.DateInput ref={this.targetRef}
                                                 label={label && getIntlMessage(intl, label)}
                                                 format="yy:mm"
                                                 formatPlaceholder={formatPlaceholder}
                                                 disabled={disabled || fieldDisabled || !onFieldChange}
                                                 valid={true}
                                                 value={this.parse(value)}
                                                 onChange={e => onFieldChange(e.target.value ? this.format(KendoIntl.formatDate(e.target.value, "yy:mm", intl.locale)) : null)} />
                     </span>
                   );
                 }
               }}
               onChange={onChange} />
      } />
    );
  }

  private readonly parse = (value: string | null): Date | null => {
    if (value) {
      let dd = 0;
      let hh = 0;
      let mm = 0;

      const daysSplit = value.split(".")
      if (daysSplit.length > 1) {
        dd = Number(daysSplit[0])
      }

      if (daysSplit.length > 0) {
        const restSplit = daysSplit[daysSplit.length - 1].split(":")
        if (restSplit.length > 0) {
          hh = Number(restSplit[0])
        }
        if (restSplit.length > 1) {
          mm = Number(restSplit[1])
        }
      }

      return new Date(2000 + dd * 24 + hh, 0, 1, 0, mm, 0);
    }

    return null;
  };

  private readonly format = (value: string | null): string => {
    let hh = 0;
    let mm = 0;
    if (value) {
      const parts = value.split(":");
      if (parts.length > 0) {
        hh = Number(parts[0]);
      }
      if (parts.length > 1) {
        mm = Number(parts[1]);
      }
    }

    const dd = Math.floor(hh / 24)
    hh = hh - dd * 24

    return `${dd}.${hh}:${mm}`
  }

  private readonly cancelWheelEventBubbling = (event: WheelEvent): void => {
    event.cancelBubble = true;
  };
}
