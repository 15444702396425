import { Apis, Chunk, Client } from "../../../data";
import { Airline } from "../models";

export class AirlineAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<Airline>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`airlines`, {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }

  public static async getOne(id: string): Promise<Airline> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`airlines/${id}`);
  }
}
