export interface RateRule {
  name: string
}

export class GroupRateRule implements RateRule {
  public name: string
  public minSize: number
  public maxSize: number | null
  public minAdultAge: number | null
  public minAdultCount: number
  public maxAdultCount: number | null
  public minChildAge: number | null
  public minChildCount: number
  public maxChildCount: number | null

  public constructor(init?: Partial<GroupRateRule>) {
    this.name = 'GroupRateRule'
    this.minSize = init?.minSize ?? 1
    this.maxSize = init?.maxSize ?? null
    this.minAdultAge = init?.minAdultAge ?? null
    this.minAdultCount = init?.minAdultCount ?? 0
    this.maxAdultCount = init?.maxAdultCount ?? null
    this.minChildAge = init?.minChildAge ?? null
    this.minChildCount = init?.minChildCount ?? 0
    this.maxChildCount = init?.maxChildCount ?? null

  }
}

export class DiscountRateRule implements RateRule {
  public name: string
  public from: number
  public value: number

  public constructor(init?: Partial<DiscountRateRule>) {
    this.name = 'DiscountRateRule'
    this.from = init?.from ?? 2
    this.value = init?.value ?? 50
  }
}

export class UrgencyRateRule implements RateRule {
  public name: string
  public timeDeltaBeforeFlight: string | null
  public dayCountBeforeFlight: number | null
  public dayTimeThreshold: string | null

  public constructor(init?: Partial<UrgencyRateRule>) {
    this.name = 'UrgencyRateRule'
    this.timeDeltaBeforeFlight = init?.timeDeltaBeforeFlight ?? null
    this.dayCountBeforeFlight = init?.dayCountBeforeFlight ?? null
    this.dayTimeThreshold = init?.dayTimeThreshold ?? null
  }
}
