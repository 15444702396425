import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Chunk } from '../data'
import { ID, NavPathItem } from '../gears'
import { OrganizationAccess, OrganizationContractAccess, OrganizationProgramAccess, ResourceAccess, ServiceAccess } from '../parts/data/access'
import { EMPTY_ID, Media, MediaKind, OrganizationContractType, OrganizationProgram, OrganizationProgramType, RateFilter, ResolvedOrganization, ResolvedOrganizationContract, ResolvedRate, ResolvedResource, ResolvedService } from '../parts/data/models'
import { Dialog, Div, Loader, TXT } from '../parts/gears'
import { useFormatMessageEx, useOrderStatuses } from '../parts/hooks'
import { ResourceAirportView, ResourceDetailsView, ResourceSearchView } from '../parts/views'
import { PageProps, withPage } from './withPage'

function SearchPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props

  return (
    <Div className="search">
      <Switch>
        <Route exact={true}
               path={`${match.path}/:airportId/:resourceId`}
               render={props => <SearchResourcePage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                                                    onError={onError} />} />
        <Route exact={true}
               path={`${match.path}/:airportId`}
               render={props => <SearchAirportPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                                                   onError={onError} />} />
        <Route exact={true}
               path={`${match.path}`}
               render={props => <SearchQueryPage {...props} onLoaded={onLoaded} onNavigate={onNavigate}
                                                 onError={onError} />} />
      </Switch>
    </Div>
  )
}

interface SearchQueryPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function SearchQueryPage(props: SearchQueryPageProps): ReactElement {
  const { onLoaded, onError } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState<Chunk<ResolvedResource>>(new Chunk())
  const [ medias, setMedias ] = useState<Map<string, Media[]>>(new Map())
  const [ logos, setLogos ] = useState<Map<string, Media>>(new Map())
  const [ mode, setMode ] = useState<'letter' | 'name'>('letter')
  const [ sort, setSort ] = useState('')
  const [ skip, setSkip ] = useState(0)
  const [ take, setTake ] = useState(100)
  const [ pattern, setPattern ] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [ setLoading, setPattern, setSort, setSkip, setTake ])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          let data = pattern
            ? await ResourceAccess.getAll(undefined, sort, skip, take, { byCode: pattern })
              .then(chunk => chunk.data.length > 0 ? chunk : ResourceAccess.getAll(pattern, sort, skip, take))
            : await ResourceAccess.getAll(undefined, undefined, undefined, undefined)

          //const filteredResources = []
          //for (const item of data.data) {
          //  try {
          //    const contracts = await OrganizationContractAccess.getAll(undefined, undefined, 0, 1, {
          //      byResourceId: item.id,
          //    })
          //    if (contracts.data.length === 0) continue

          //    filteredResources.push(item)
          //  } catch {
          //  }
          //}
          //data = new Chunk(filteredResources)

          setData(data)

          
          const airportCount = new Map<string, number>()
          for (const item of data.data) {
            airportCount.set(item.airport.id, 1)
          }

          const mode = Array.from(airportCount.values()).reduce((p, c) => p + c, 0) > 12
            ? 'letter'
            : 'name'
          setMode(mode)

          const medias = new Map<string, Media[]>()
          const airportLogos = new Map<string, Media>()
          if (mode === 'name') {
            for (const item of data.data) {
              medias.set(item.id, await ResourceAccess.getOneMedias(item.id, MediaKind.Image).then(chunk => chunk.data))
              if (!airportLogos.has(item.airport.id)) {
                const voucherSettings = await OrganizationAccess.getOneVoucherSettings(item.organization.id)
                if (voucherSettings.logoId && voucherSettings.logoId !== EMPTY_ID) {
                  const logoMedia = new Media()
                  logoMedia.fileId = voucherSettings.logoId
                  logoMedia.kind = MediaKind.Image
                  airportLogos.set(item.airport.id, logoMedia)
                }
              }
            }
          }
          setMedias(medias)
          setLogos(airportLogos)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    profile, loading, pattern, sort, skip, take, setData, setMode, setLogos, setMedias, setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [ loaded, onLoaded ])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [ pattern, reload ])

  return (
    <Loader loading={!loaded && loading}>
      <ResourceSearchView data={data} mode={mode} logos={logos} medias={medias} onItemSearch={handleItemSearch} />
    </Loader>
  )
}

interface SearchAirportPageProps extends RouteComponentProps<{ airportId: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function SearchAirportPage(props: SearchAirportPageProps): ReactElement {
  const { match, onLoaded, onError } = props

  const airportId = match.params.airportId

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState<Chunk<ResolvedResource>>(new Chunk())
  const [ medias, setMedias ] = useState<Map<string, Media[]>>(new Map())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          let data = await ResourceAccess.getAll(undefined, undefined, undefined, undefined, { byCode: airportId })

          const filteredResources = []
          for (const item of data.data) {
            try {
              const contracts = await OrganizationContractAccess.getAll(undefined, undefined, 0, 1, {
                byResourceId: item.id,
              })
              if (contracts.data.length === 0) continue

              filteredResources.push(item)
            } catch {
            }
          }
          data = new Chunk(filteredResources)

          setData(data)
          const media = new Map<string, Media[]>()
          for (const item of data.data) {
            media.set(item.id, await ResourceAccess.getOneMedias(item.id, MediaKind.Image).then(chunk => chunk.data))
          }
          setMedias(media)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, airportId, setData, setMedias, setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded && data.data.length > 0) {
      onLoaded([ { path: `${data.data[0].airport.id}`, text: data.data[0].airport.name } ])
    }
  }, [ loaded, data, onLoaded ])

  return (
    <Loader loading={!loaded && loading}>
      <ResourceAirportView data={data} medias={medias} />
    </Loader>
  )
}

interface SearchResourcePageProps extends RouteComponentProps<{ airportId: string, resourceId: string }, {}, any> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function SearchResourcePage(props: SearchResourcePageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId
  const returnedWithSuccess = !!(location.state && location.state.success)
  const orderId = location.state ? location.state.id : undefined
  const orderStatus = location.state ? location.state.status : undefined

  const auth = useContext(AuthContext)
  const formatMessage = useFormatMessageEx()
  const [ , formatOrderStatus ] = useOrderStatuses()

  const [ loaded, setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState<ResolvedResource>(new ResolvedResource())
  const [ images, setImages ] = useState<Media[]>([])
  const [ maps, setMaps ] = useState<Media[]>([])
  const [ provider, setProvider ] = useState<ResolvedOrganization>(new ResolvedOrganization())
  const [ programs, setPrograms ] = useState<Chunk<OrganizationProgram>>(new Chunk())
  const [ rates, setRates ] = useState<Chunk<ResolvedRate>>(new Chunk())
  const [ ratesByContract, setRatesByContract ] = useState<Map<ResolvedOrganizationContract, Chunk<ResolvedRate>>>(new Map())
  const [ rateFilter, setRateFilter ] = useState<RateFilter>({})
  const [ services, setServices ] = useState<Chunk<ResolvedService>>(new Chunk())
  const [ medias, setMedias ] = useState<Map<string, Media[]>>(new Map())
  const canBook = auth.profile.isSys ||
    (auth.profile.isOwner && auth.profile.isInRole('owner.super', 'owner.manager.order')) ||
    (auth.profile.isAgent && auth.profile.isInRole('agent.super', 'agent.manager'))

  const [ confirmationToggled, setConfirmationToggled ] = useState(returnedWithSuccess)

  const toggleConfirmation = useCallback((): void => {
    setConfirmationToggled(prev => !prev)
  }, [ setConfirmationToggled ])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await ResourceAccess.getOne(resourceId)
          setItem(item)

          const images = await ResourceAccess.getOneMedias(item.id, MediaKind.Image).then(chunk => chunk.data)
          setImages(images)

          const maps = await ResourceAccess.getOneMedias(item.id, MediaKind.Map).then(chunk => chunk.data)
          setMaps(maps)

          const provider = await OrganizationAccess.getOne(item.organization.id)
          setProvider(provider)

          const programs = await OrganizationProgramAccess.getAll(undefined, undefined, undefined, undefined, { byType: OrganizationProgramType.Corporate, byOrganizationId: item.organization.id })
          setPrograms(programs)
          
          const contracts = await OrganizationContractAccess.getAll(undefined, undefined, undefined, 1, {
            byType: auth.profile.isAgent ? OrganizationContractType.Deposit : OrganizationContractType.Direct,
            byResourceId: item.id,
            isActive: true,
            isAuthorized: true,
          })
          const rates = await ResourceAccess.getOneBaseRates(item.id, rateFilter, 'Resolved')
          const ratesByContract = new Map<ResolvedOrganizationContract, Chunk<ResolvedRate>>()
          for (const contract of contracts.data) {
            const contractRates = await ResourceAccess.getOneRates(item.id, {
              ...rateFilter,
              byContractId: contract.id,
            }, 'Resolved')
            ratesByContract.set(contract, contractRates as Chunk<ResolvedRate>)
          }
          setRates(rates as Chunk<ResolvedRate>)
          setRatesByContract(ratesByContract)

          const services = await ServiceAccess.getAll(undefined, undefined, undefined, undefined, { byResourceId: item.id })
          setServices(services)

          const medias = new Map<string, Media[]>()
          for (const service of services.data) {
            medias.set(service.id, await ServiceAccess.getOneMedias(service.id, MediaKind.Image).then(chunk => chunk.data))
          }
          setMedias(medias)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    auth, loading, resourceId, rateFilter, setItem, setImages, setMaps, setProvider, setPrograms, setRates, setServices, setMedias, setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        { path: `${item.airport.id}`, text: item.airport.name },
        { path: `${item.airport.id}/${item.id}`, text: item.name },
      ])
    }
  }, [ loaded, item, onLoaded ])

  const handleItemFilter = useCallback((newFilter: RateFilter): void => {
    if (!R.equals(rateFilter, newFilter)) {
      setRateFilter(newFilter)
      setLoading(true)
    }
  }, [ rateFilter, setRateFilter, setLoading ])

  const handleBook = useCallback((): void => {
    onNavigate('/orders/new', { resource: item, returnUrl: `${match.url}` })
  }, [ item, match.url, onNavigate ])

  return (
    <Loader loading={!loaded && loading}>
      <ResourceDetailsView data={{ item, images, maps, provider, programs, rates, services, medias, ratesByContract }}
                           onRateFilter={handleItemFilter}
                           onBook={canBook ? handleBook : undefined} />
      {confirmationToggled &&
      <Dialog title={TXT('prompt.orderSuccess.title')} labelAccept={TXT('label.ok')} onAccept={toggleConfirmation}>
        <p>{formatMessage(TXT('prompt.orderSuccess.message', {
          a: (...chunks) => (<a href={`/orders/${orderId}`}>{chunks}</a>),
          id: orderId,
          status: formatOrderStatus(orderStatus),
        }))}</p>
      </Dialog>
      }
    </Loader>
  )
}

function pathBuilder(items: NavPathItem[]): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: ID('page.search'),
  })
  pathItems.push(...items)

  return pathItems
}

export const Search = withPage(SearchPage, pathBuilder)
Search.displayName = 'SearchPage'
