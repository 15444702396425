import * as KendoButtons from "@progress/kendo-react-buttons";
import { classNames } from "@progress/kendo-react-common";
import React, { CSSProperties, PureComponent, ReactNode } from "react";
import { Intent, intentToClassName } from "../../parts/gears";
import { Field } from "../Field";
import { IntlFormat, IntlMessage } from "../Intl";

interface ToggleBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  style?: CSSProperties;
  primary?: boolean;
  intent?: Intent;
  name: string;
  value: boolean;
  onChange?: (value: boolean) => void;
}

interface ToggleBoxProps {
  children?: null;
}

interface ToggleBoxState {
}

export class ToggleBox extends PureComponent<ToggleBoxProps, ToggleBoxState> {
  public constructor(props: ToggleBoxProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { hint, label, disabled, readonly, name, value, className, style, primary, intent, onChange } = this.props;

    return (
      <Field hint={hint}
             name={name}
             value={value}
             validateOnChange={false}
             render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) =>
               <KendoButtons.Button disabled={disabled || fieldDisabled || !onFieldChange}
                                    togglable={true}
                                    primary={primary}
                                    selected={value}
                                    look="flat"
                                    type="button"
                                    className={classNames("g-button", intentToClassName(intent, className))}
                                    style={style}
                                    onClick={!readonly && !fieldReadonly ? () => onFieldChange(!value) : undefined}>
                 {label && <IntlFormat text={label} />}
               </KendoButtons.Button>
             }
             onChange={onChange} />
    );
  }
}
