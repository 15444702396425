import { AirportTerminalReference, EMPTY_ID, TransferPointType } from './index'

export class TransferReference {
  public id: string;

  public constructor(id?: string) {
    this.id = id || EMPTY_ID;
  }
}

export class Transfer extends TransferReference {
  public departurePoint: AirportTerminalReference;
  public departurePointType: TransferPointType;
  public destinationPoint: AirportTerminalReference;
  public destinationPointType: TransferPointType;

  public constructor() {
    super();
    this.departurePoint = new AirportTerminalReference();
    this.departurePointType = TransferPointType.Common;
    this.destinationPoint = new AirportTerminalReference();
    this.destinationPointType = TransferPointType.Common;
  }
}
