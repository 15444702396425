import { useCallback, useEffect, useState } from "react";
import { Chunk } from "../../data";
import { LoyaltyProgram } from "../data/models";
import { FetchList } from "./types";
import { LoyaltyProgramAccess } from "../data/access";

export function useLoyaltyPrograms(pageSize?: number, prefetch?: boolean, belongsTo?: string | null): [ Chunk<LoyaltyProgram>, FetchList ] {
  const [ data, setData ] = useState<Chunk<LoyaltyProgram>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await LoyaltyProgramAccess.getAll(pattern, undefined, skip || 0, take || pageSize, belongsTo || undefined);
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }, [ pageSize, belongsTo, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ]
}
