import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { AuthContext } from '../../auth'
import { Chunk } from '../../data'
import { FieldSet, Form } from '../../gears'
import { ComboBox, DateBox, TextBox } from '../../gears/inputs'
import { email, length, phone, required } from '../../gears/validators'
import { EMPTY_ID, OrganizationContractName, OrganizationContractType, OrganizationProgramCard, OrganizationProgramCardCreateModel, OrganizationProgramCardStatistics, OrganizationProgramName, OrganizationProgramReference, OrganizationProgramType, OrganizationType, Passenger } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, Span, TXT } from '../gears'
import { useDelay, useField, useFormatDateRange, useFormatMessage, useOrganization, useOrganizationContract, useOrganizationContracts, useOrganizationProgram, useOrganizationProgramCard, useOrganizationPrograms, useOrganizationProgramSettings, useOrganizations, useWindowSize } from '../hooks'
import { CorporateContractStatisticsView, PassengerList } from '../spare'
import { GridViewProps, ItemViewProps } from './types'

export interface CorporateContractItemProps extends ItemViewProps<OrganizationProgramCard, OrganizationProgramCardCreateModel> {
  showProviders?: boolean;
  showComment?: boolean;
  statistics?: OrganizationProgramCardStatistics;
}

export function CorporateContractItem(props: CorporateContractItemProps): ReactElement {
  const {
    item,
    lock,
    readonly,
    showProviders,
    showComment,
    statistics,
    onSubmit,
    onCancel,
    onDelete,
    onEdit,
    onFree,
  } = props

  const pageSize = 100
  const id = item.id
  const isNew = id === EMPTY_ID

  const formatMessage = useFormatMessage()

  const auth = useContext(AuthContext)
  const code = auth.profile.belongsTo
  const [belongsTo] = useOrganization(code)

  const [ program, setProgram ] = useOrganizationProgram(item.program.id !== EMPTY_ID ? item.program.id : null)
  const [ settings ] = useOrganizationProgramSettings(program !== null ? program.id : null)
  const [ provider, setProvider ] = useOrganization(program != null ? program.organization.id : belongsTo !== null && belongsTo.type === OrganizationType.Provider ? belongsTo.code : null)
  const [ contract, setContract ] = useOrganizationContract(item.contract !== null ? item.contract.id : null)
  const [ agency, setAgency ] = useOrganization(contract !== null ? contract.agency.id : null)

  const providersFilter = useMemo(() => ({ byType: OrganizationType.Provider }), []);
  const [ providers, getProviders ] = useOrganizations(pageSize, !readonly && isNew && showProviders, providersFilter)

  const agenciesFilter = useMemo(() => ({ byType: OrganizationType.Agency }), [])
  const [ agencies, getAgencies ] = useOrganizations(pageSize, !readonly && isNew, agenciesFilter)

  const programsFilter = useMemo(() => ({ byOrganizationId: provider !== null ? provider.id : undefined }), [ provider ])
  const [ programs, getPrograms ] = useOrganizationPrograms(pageSize, !readonly && isNew && provider !== null, programsFilter)

  const contractsFilter = useMemo(() => ({ byProviderId: provider !== null ? provider.id : undefined, byAgencyId: agency !== null ? agency.id : undefined, isAuthorized: true, byType: OrganizationContractType.Deposit }), [ provider, agency ])
  const [ contracts, getContracts ] = useOrganizationContracts(pageSize, !readonly && isNew && agency !== null && provider !== null, contractsFilter)

  const [ card, setCard ] = useField<string | null>(item.number)
  const [ contactName, setContactName ] = useField<string | null>(item.contactName)
  const [ contactEmail, setContactEmail ] = useField<string | null>(item.contactEmail)
  const [ contactPhone, setContactPhone ] = useField<string | null>(item.contactPhone)
  const [ validFrom, setValidFrom ] = useField<string | null>(item.validFrom)
  const [ validTill, setValidTill ] = useField<string | null>(item.validTill)
  const [ comment, setComment ] = useField<string | null>(item.description)
  const [ people, setPeople ] = useField<Passenger[] | null>(item.people)

  const [ existed ] = useOrganizationProgramCard(program && card ? `${program.code}/${card}` : null);

  const isCardExist = program && card && existed !== null

  useEffect(() => {
    if (isNew) {
      setAgency(null)
      setContract(null)
    }
  }, [ isNew, program, setAgency, setContract ])

  const getItem = useCallback((): OrganizationProgramCardCreateModel => {
    const model = new OrganizationProgramCardCreateModel()
    model.number = card || ''
    model.program = program || new OrganizationProgramReference()
    model.contract = contract
    model.contactName = contactName || ''
    model.contactEmail = contactEmail || ''
    model.contactPhone = contactPhone || ''
    model.validFrom = validFrom || ''
    model.validTill = validTill || ''
    model.description = comment
    model.people = people

    return model
  }, [
    card, comment, program, contract,
    contactName, contactEmail, contactPhone,
    validFrom, validTill,
    people,
  ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem())
    }
  }, [ getItem, onSubmit ])

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12 3@lg">
              {showProviders &&
              <Div>
                <ComboBox label={TXT('label.provider')}
                          disabled={!readonly && (!isNew || showComment)}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="provider"
                          data={providers}
                          value={provider}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setProvider}
                          onFetch={getProviders} />
              </Div>
              }
              <Div>
                <ComboBox label={TXT('label.program')}
                          disabled={!readonly && (!isNew || showComment)}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="program"
                          data={programs}
                          value={program}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setProgram}
                          onFetch={getPrograms} />
              </Div>
              {program && program.type === OrganizationProgramType.Corporate &&
              <Div>
                <ComboBox label={TXT('label.agency')}
                          disabled={!readonly && (!isNew || showComment)}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="agency"
                          data={agencies}
                          value={agency}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setAgency}
                          onFetch={getAgencies} />
              </Div>
              }
              {program && program.type === OrganizationProgramType.Corporate &&
              <Div>
                <ComboBox label={TXT('label.contract')}
                          disabled={!readonly && (!isNew || showComment)}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="contract"
                          data={contracts}
                          value={contract}
                          valueKey="id"
                          valueLabel="number"
                          validators={[ required ]}
                          onChange={setContract}
                          onFetch={getContracts} />
              </Div>
              }
              <Div>
                <TextBox label={TXT('label.cardNumber')}
                         disabled={!readonly && (!isNew || showComment)}
                         fill={true}
                         name="card"
                         value={card}
                         error={isNew && isCardExist ? TXT('error.cardAlreadyExists') : undefined}
                         validators={[ required, value => length(value, 3, 100) ]}
                         onChange={setCard} />
              </Div>
              {showComment &&
              <Div>
                <TextBox label={TXT('label.comment')}
                         fill={true}
                         name="comment"
                         value={comment}
                         validators={[ value => length(value, 1, 256) ]}
                         onChange={setComment} />
              </Div>
              }
            </Div>
          </FieldSet>
        </Div>
        {!isNew && statistics &&
        <Div>
          <FieldSet label={TXT('label.limits')}>
            <CorporateContractStatisticsView name="statistics" item={statistics} />
          </FieldSet>
        </Div>
        }
        <Div>
          <FieldSet label={TXT('label.active')}>
            <Div layout="grid 12 3@lg">
              <Div>
                <DateBox label={TXT('label.from')}
                         disabled={!readonly && ((program !== null && !program.activated) || showComment)}
                         fill={true}
                         name="validFrom"
                         zone="UTC"
                         value={validFrom}
                         validators={[ required ]}
                         onChange={setValidFrom} />
              </Div>
              <Div>
                <DateBox label={TXT('label.till')}
                         disabled={!readonly && ((program !== null && !program.activated) || showComment)}
                         fill={true}
                         name="validTill"
                         zone="UTC"
                         value={validTill}
                         validators={[ required ]}
                         onChange={setValidTill} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.contactInformation')}>
            <Div layout="grid 12 4@lg">
              <Div>
                <TextBox label={TXT('label.fullName')}
                         disabled={!readonly && ((program !== null && !program.activated) || showComment)}
                         fill={true}
                         name="contactName"
                         value={contactName}
                         validators={[ required, value => length(value, 1, 256) ]}
                         onChange={setContactName} />
              </Div>
              <Div>
                <TextBox label={TXT('label.email')}
                         disabled={!readonly && ((program !== null && !program.activated) || showComment)}
                         fill={true}
                         name="contactEmail"
                         value={contactEmail}
                         validators={[ required, email, value => length(value, 1, 100) ]}
                         onChange={setContactEmail} />
              </Div>
              <Div>
                <TextBox label={TXT('label.phone')}
                         disabled={!readonly && ((program !== null && !program.activated) || showComment)}
                         fill={true}
                         mask="(999) 000-00-00"
                         name="contactPhone"
                         value={contactPhone}
                         validators={[ required, phone ]}
                         onChange={setContactPhone} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {program && settings && program.type === OrganizationProgramType.Individual && (settings.availablePrimaryPersonCount === null || settings.availablePrimaryPersonCount > 0) &&
        <Div>
          <FieldSet label={TXT('label.cardPersons')}>
            <PassengerList disabled={!readonly && (!program.activated || showComment)}
                           name="persons"
                           value={people}
                           limit={settings.availablePrimaryPersonCount}
                           emailRequired={true}
                           phoneRequired={true}
                           onChange={setPeople} />
          </FieldSet>
        </Div>
        }
        {!isNew && program && !program.activated &&
        <Div>
          <Span intent="warning">{formatMessage(TXT('hint.programIsNoActive'))}</Span>
        </Div>
        }
      </Div>
    </Form>
  )
}

export interface CorporateContractGridProps extends GridViewProps<OrganizationProgramCard<OrganizationProgramName, OrganizationContractName>> {
  showProviders?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function CorporateContractGrid(props: CorporateContractGridProps): ReactElement {
  const { data, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props

  const formatMessage = useFormatMessage()
  const formatDateRange = useFormatDateRange()

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null)
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk())

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total)
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        number: item.number,
        program: item.program.name,
        contract: item.contract !== null ? item.contract.number : '',
        valid: formatDateRange(item.validFrom, item.validTill),
      })
      setProcessedData(processedData)
    }
  }, [
    data.data, data.skip, data.take, data.total,
    formatMessage, formatDateRange,
    setProcessedData,
  ])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [ delayedPattern, onItemSearch ])

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0]
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem)
    }
  }, [ data.data, onItemSelect ])

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy)
      replaceSortOrder(sortOrder, 'contract', 'agency', 'contract')
      replaceSortOrder(sortOrder, 'valid', 'validFrom', 'validTill')

      onSortChange(formatOrderBy(sortOrder))
    }
  }, [ onSortChange ])

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT('label.search')}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              {formatMessage(TXT('action.create'))}
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT('label.number')} field="number" width={256} locked={true} />
              <GridColumn title={TXT('label.program')} field="program" width={256} fill={true} />
              <GridColumn title={TXT('label.contract')} field="contract" width={256} sortable={false} />
              <GridColumn title={TXT('label.active')} field="valid" width={256} locked={true} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  )
}
