import React, { DetailedHTMLProps, HTMLAttributes, Ref } from "react";

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { layout?: string };

type DivRef = Ref<HTMLDivElement>;

const Div = React.forwardRef((props: DivProps, ref?: DivRef) => {
  const { layout, ...rest } = props;

  const bp = layout ? { bp: layout } : undefined;

  return (
    <div ref={ref} {...rest} { ...bp } />
  );
});

Div.displayName = "Div";

export { Div };
