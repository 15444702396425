import * as KendoSortable from "@progress/kendo-react-sortable";
import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import Measure from "react-measure";
import { Button } from "./Button";
import { Div } from "./Div";
import { ComboBox } from "./inputs";
import { getIntlMessage, ID, Intl, IntlMessage } from "./Intl";

interface CollectionProps<TItem extends object> {
  label?: IntlMessage;
  data: TItem[];
  item: TItem | null;
  itemKey: string;
  itemRender: (item: TItem) => ReactElement;
  viewRender: () => ReactElement;
  categories?: any[];
  category?: any | null;
  categoryKey?: string;
  categoryLabel?: string;
  onAppend?: () => void;
  onChange?: (data: TItem[]) => void;
  onSelectItem?: (item: TItem | null) => void;
  onSelectCategory?: (category: any | null) => void;
}

export function Collection<TItem extends object>(props: Readonly<CollectionProps<TItem>>): ReactElement {
  const { label, item, itemKey, itemRender, viewRender, categories, category, categoryKey, categoryLabel, onAppend, onChange, onSelectItem, onSelectCategory } = props;

  const [ data, setData ] = useState(props.data);
  const [ viewSize, setViewSize ] = useState<{ width: number; height: number; }>({ width: 0, height: 0 });

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  const handleNavigate = (event: KendoSortable.SortableOnNavigateEvent): void => {
    if (onChange) onChange(event.newState as TItem[]);
  };

  const handleDragOver = (event: KendoSortable.SortableOnDragOverEvent): void => {
    setData(event.newState as TItem[]);
  };

  const handleDragEnd = (event: KendoSortable.SortableOnDragEndEvent): void => {
    if (onChange) onChange(event.newState as TItem[]);
  };

  const handleSelectCategory = (value: any | null): void => {
    if (onSelectCategory) onSelectCategory(value);
    if (onSelectItem) onSelectItem(null);
  };

  const getItemStyle = (isActive: boolean): CSSProperties => ({
    textAlign: "center",
    outline: "none",
    border: "1px solid",
    cursor: "move",
    borderColor: isActive ? "#ff9411" : "#ffffff",
  });

  const itemUI = (props: Readonly<KendoSortable.SortableItemUIProps>): ReactElement => {
    const { attributes, forwardRef, style, isActive, dataItem } = props;
    const currentId = dataItem[itemKey];

    if (isActive && onSelectItem) {
      onSelectItem(dataItem);
    }

    return (
      <div {...attributes}
           ref={div => (div && forwardRef) ? forwardRef(div) : undefined}
           style={{ ...getItemStyle(item ? (item as any)[itemKey] === currentId : false), ...style }}>
        {itemRender(dataItem)}
      </div>
    );
  };

  return (
    <Intl render={intl =>
      <Div layout="grid 12 gap-none" className="g-collection">
        {label &&
        <Div>
          <Div layout="grid 12" className="g-collection-header">
            <Div className="g-collection-label">
              <span>{getIntlMessage(intl, label)}</span>
            </Div>
          </Div>
        </Div>
        }

        {onAppend &&
        <Div>
          <Div layout="flex" className="g-collection-header">
            <Div layout="fill" className="g-collection-categories">
              {categories &&
              <ComboBox fill={true}
                        name="category"
                        data={categories}
                        value={category || null}
                        valueKey={categoryKey}
                        valueLabel={categoryLabel}
                        onChange={handleSelectCategory} />
              }
            </Div>
            <Div layout="fit" className="g-collection-actions">
              <Button primary={true}
                      text={ID("action.append")}
                      type="button"
                      onClick={onAppend} />
            </Div>
          </Div>
        </Div>
        }

        {data.length > 0 &&
        <Div>
          <Div layout="flex">
            <Div layout="fit" className="g-collection-list">
              <Measure>
                {({ measureRef }) =>
                  <div ref={measureRef} style={{ maxHeight: viewSize.height, overflowY: "auto" }}>
                    <KendoSortable.Sortable data={data}
                                            idField={itemKey}
                                            itemUI={itemUI}
                                            onNavigate={handleNavigate}
                                            onDragOver={onChange ? handleDragOver : undefined}
                                            onDragEnd={onChange ? handleDragEnd : undefined} />
                  </div>
                }
              </Measure>
            </Div>
            <Div layout="fill" className="g-collection-view">
              <Measure bounds={true} onResize={rect => rect.bounds && setViewSize(rect.bounds)}>
                {({ measureRef }) =>
                  <div ref={measureRef}>
                    {viewRender()}
                  </div>
                }
              </Measure>
            </Div>
          </Div>
        </Div>
        }
      </Div>
    } />
  );
}
