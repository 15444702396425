import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Chunk, Pair } from "../../data";
import { UserBase } from "../../data/models";
import { UserAccess } from "../data/access";
import { FetchList, PairConverter, PairData } from "./types";

export function useUserRolesV1(): [ PairData, PairConverter ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData>([
    new Pair("system.admin", intl.formatMessage({ id: "role.system.admin" })),
    new Pair("owner.super", intl.formatMessage({ id: "role.owner.super" })),
    new Pair("owner.manager.resource", intl.formatMessage({ id: "role.owner.manager.resource" })),
    new Pair("owner.manager.order", intl.formatMessage({ id: "role.owner.manager.order" })),
    new Pair("owner.accountant", intl.formatMessage({ id: "role.owner.accountant" })),
    new Pair("agent.super", intl.formatMessage({ id: "role.agent.super" })),
    new Pair("agent.manager", intl.formatMessage({ id: "role.agent.manager" })),
    new Pair("agent.accountant", intl.formatMessage({ id: "role.agent.accountant" })),
  ]);

  const converter = useCallback((code: string): string => {
    switch (code) {
      case "system.admin":
        return intl.formatMessage({ id: "role.system.admin" });
      case "owner.super":
        return intl.formatMessage({ id: "role.owner.super" });
      case "owner.manager.resource":
        return intl.formatMessage({ id: "role.owner.manager.resource" });
      case "owner.manager.order":
        return intl.formatMessage({ id: "role.owner.manager.order" });
      case "owner.accountant":
        return intl.formatMessage({ id: "role.owner.accountant"});
      case "agent.super":
        return intl.formatMessage({ id: "role.agent.super" });
      case "agent.manager":
        return intl.formatMessage({ id: "role.agent.manager" });
      case "agent.accountant":
        return intl.formatMessage({ id: "role.agent.accountant" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useUserExists(belongsTo: string | null, name: string | null): boolean {
  const [ isExist, setIsExist ] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (belongsTo && name) {
          setIsExist(await UserAccess.check(belongsTo, name))
        } else {
          setIsExist(false);
        }
      } catch {
        setIsExist(false);
      }
    })();
  }, [ belongsTo, name, setIsExist ]);

  return isExist;
}

export function useUsersV1(pageSize?: number, prefetch?: boolean, belongsTo?: string): [ Chunk<UserBase>, FetchList ] {
  const [ data, setData ] = useState<Chunk<UserBase>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await UserAccess.getAll(pattern, undefined, skip || 0, take || pageSize, belongsTo);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, belongsTo, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}
