import { classNames } from '@progress/kendo-react-common'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Route, Switch, useHistory } from 'react-router'
import './App.scss'
import { Icons, Logos } from './assets'
import { AuthContext } from './auth'
import { ID, NavMenu, NavMenuItem } from './gears'
import { Auth, Control, History, Home, Membership, Orders, Organizations, Queues, Rates, Reports, Resources, Search, Settings, Users } from './pages'
import { Button, Div, Popup, TXT } from './parts/gears'
import { useEventCount, useFormatMessage } from './parts/hooks'
import { OrderSearch, TimeQueueNotifier } from './parts/views'

function Collapse(props: Readonly<{ collapsed: boolean; onChange: (value: boolean) => void; }>): ReactElement {
  const { collapsed, onChange } = props

  const handleClick = (): void => {
    onChange(!collapsed)
  }

  if (collapsed) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172" onClick={handleClick}>
        <g>
          <path
            d="M40.14453,11.00196l-7.72656,7.55859l60.84668,62.06445l-60.84668,62.10645l7.72656,7.4746l68.15332,-69.58105zM83.14453,11.00196l-7.72656,7.55859l60.84668,62.06445l-60.84668,62.10645l7.72656,7.4746l68.15332,-69.58105z" />
        </g>
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172" onClick={handleClick}>
        <g>
          <path
            d="M88.85547,11.00196l-68.15332,69.62304l68.15332,69.58105l7.72656,-7.4746l-60.84668,-62.10645l60.84668,-62.06445zM131.85547,11.00196l-68.15332,69.62304l68.15332,69.58105l7.72656,-7.4746l-60.84668,-62.10645l60.84668,-62.06445z" />
        </g>
      </svg>
    )
  }
}

function Profile(): ReactElement {
  const auth = useContext(AuthContext)

  const history = useHistory()

  const formatMessage = useFormatMessage()

  const [ toggled, setToggled ] = useState(false)

  const toggle = useCallback((): void => {
    setToggled(prev => !prev)
  }, [ setToggled ])

  const logout = useCallback((): void => {
    history.push('/auth')
  }, [ history ])

  return (
    <Popup anchor={<Button look="bare" onClick={toggle}>{auth.profile.name}</Button>} toggled={toggled}
           onClose={toggle}>
      <Div>
        <Button look="bare" onClick={logout}>
          {formatMessage(TXT('action.logout'))}
        </Button>
      </Div>
    </Popup>
  )
}

function App(): ReactElement {
  const context = useContext(AuthContext)
  const [ cookies, setCookie ] = useCookies([ 'navigation-collapsed' ])
  const [ collapsed, setCollapsed ] = useState(false)
  const queueCount = useEventCount(100)

  const profile = context.profile

  useEffect(() => {
    const collapsed = cookies['navigation-collapsed']
    setCollapsed(collapsed ? collapsed === 'true' : false)
  }, [ cookies ])

  const handleCollapsed = (value: boolean): void => {
    setCookie('navigation-collapsed', value)
  }

  const canSeeMembership = profile.isInRole('system.admin', 'owner.super', 'agent.super')
  const canSeeUsers = profile.isInRole('system.admin', 'owner.super', 'agent.super')
  const canSeeOrganizations = profile.isInRole('system.admin', 'owner.super', 'agent.super')
  const canSeeResources = profile.isInRole('system.admin', 'owner.super', 'owner.manager.resource')
  const canSeeOrders = profile.isInRole('system.admin', 'owner.super', 'owner.manager.order', 'agent.super', 'agent.manager')
  const canSeeControl = profile.isInRole('system.admin', 'owner.super', 'owner.manager.order')
  const canSeeSearch = profile.isInRole('system.admin', 'owner.super', 'owner.manager.order', 'owner.manager.resource', 'agent.super', 'agent.manager')
  const canSeeSettings = profile.isInRole('system.admin', 'owner.super', 'agent.super')
  const canSeeQueues = profile.isInRole('system.admin', 'owner.super', 'owner.manager.order', 'agent.super', 'agent.manager')
  const canSeeQueueNotifications = profile.isInRole('system.admin', 'owner.super', 'owner.manager.order')
  const canSeeRates = profile.isInRole('system.admin', 'owner.super', 'owner.manager.resource', 'agent.super')
  const canSeeReports = profile.isInRole('system.admin', 'owner.super', 'owner.accountant', 'owner.manager.order', 'agent.super', 'agent.manager', 'agent.accountant')
  const canSeeHistory = profile.isInRole('system.admin')

  const version = `v${process.env.REACT_APP_VERSION}`

  return (
    <div className={classNames('layout', collapsed && 'collapsed')}>
      <nav>
        <div className="logo">
          <img alt="All Airports" src={collapsed ? Logos.Sign : Logos.Full} />
        </div>
        <div className="menu">
          <NavMenu>
            {canSeeSearch &&
            <NavMenuItem path="/search"
                         icon={Icons.Navigation.Items.Search}
                         text={ID('page.search')} />
            }
            {canSeeControl &&
            <NavMenuItem path="/control"
                         icon={Icons.Navigation.Items.Control}
                         text={ID('page.control')} />
            }
            {canSeeOrders &&
            <NavMenuItem path="/orders"
                         icon={Icons.Navigation.Items.Orders}
                         text={ID('page.orders')} />
            }
            {canSeeQueues &&
            <NavMenuItem path="/queues"
                         icon={Icons.Navigation.Items.Queues}
                         text={ID('page.queuesWithCount', { count: queueCount })} />
            }
            {canSeeResources &&
            <NavMenuItem path="/resources"
                         icon={Icons.Navigation.Items.Resources}
                         text={ID('page.resources')} />
            }
            {canSeeRates &&
            <NavMenuItem path="/rates"
                         icon={Icons.Navigation.Items.Rates}
                         text={ID('page.rates')} />
            }
            {canSeeMembership &&
            <NavMenuItem path="/membership"
                         icon={Icons.Navigation.Items.Membership}
                         text={ID('page.membership')} />
            }
            {canSeeReports &&
            <NavMenuItem path="/reports"
                         icon={Icons.Navigation.Items.Reports}
                         text={ID('page.reports')} />
            }
            {canSeeUsers &&
            <NavMenuItem path="/users"
                         icon={Icons.Navigation.Items.Users}
                         text={ID('page.users')} />
            }
            {canSeeOrganizations &&
            <NavMenuItem path="/organizations"
                         icon={Icons.Navigation.Items.Organizations}
                         text={profile.isAgent ? ID('page.organizations') : profile.isOwner ? ID('page.agencies') : ID('page.organizations')} />
            }
            {canSeeSettings &&
            <NavMenuItem path="/settings"
                         icon={Icons.Navigation.Items.Settings}
                         text={ID('page.settings')} />
            }
            {canSeeHistory &&
            <NavMenuItem path="/history"
                         icon={Icons.Navigation.Items.History}
                         text={ID('page.history')} />
            }
          </NavMenu>
        </div>
        <div className="foot">
          <span className="version">
            <small>{!collapsed ? version : ''}</small>
          </span>
          <span className="collapse">
            <Collapse collapsed={collapsed} onChange={handleCollapsed} />
          </span>
        </div>
      </nav>
      <main>
        <div className="auth">
          <span>
            <Profile />
          </span>
          {canSeeQueueNotifications &&
          <span>
            <TimeQueueNotifier />
          </span>
          }
          {canSeeOrders &&
          <span>
            <OrderSearch />
          </span>
          }
        </div>
        <Switch>
          <Route path="/auth" component={Auth} />
          {canSeeSearch &&
          <Route path="/search" component={Search} />
          }
          {canSeeControl &&
          <Route path="/control" component={Control} />
          }
          {canSeeOrders &&
          <Route path="/orders" component={Orders} />
          }
          {canSeeQueues &&
          <Route path="/queues" component={Queues} />
          }
          {canSeeResources &&
          <Route path="/resources" component={Resources} />
          }
          {canSeeRates &&
          <Route path="/rates" component={Rates} />
          }
          {canSeeMembership &&
          <Route path="/membership" component={Membership} />
          }
          {canSeeReports &&
          <Route path="/reports" component={Reports} />
          }
          {canSeeUsers &&
          <Route path="/users" component={Users} />
          }
          {canSeeOrganizations &&
          <Route path="/organizations" component={Organizations} />
          }
          {canSeeSettings &&
          <Route path="/settings" component={Settings} />
          }
          {canSeeHistory &&
          <Route path="/history" component={History} />
          }
          <Route exact={true} path="/" component={Home} />
        </Switch>
      </main>
    </div>
  )
}

export default App
