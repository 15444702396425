import { FlightDirection } from './FlightSpan'
import { EMPTY_ID, OrganizationContractReference, OrganizationProgramReference, PoolReference, Rate, RateReference, RateUpdateModel, ResourceReference, ServiceReference } from './index'
import { PassengerCategory } from './Passenger'
import { ServiceKind, ServiceType } from './Service'

export interface RateSpanFilter {
  byPoolId?: string | string[];
  byResourceId?: string;
  byAirportId?: string
  byKind?: ServiceKind;
  byType?: ServiceType;
  byFlightDirection?: FlightDirection;
  byCategory?: PassengerCategory;
  byFlightDate?: string;
  isShared?: boolean;
  isPrimary?: boolean;
}

export class RateSpanReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class RateSpanBase extends RateSpanReference {
  public code: string
  public name: string

  public constructor() {
    super()
    this.code = ''
    this.name = ''
  }
}

export class RateSpan<TRate extends RateReference = Rate> extends RateSpanBase {
  public rates: TRate[]

  public constructor() {
    super()
    this.rates = []
  }
}

export class RateSpanUpdateModel {
  public name: string
  public bookableFrom: string
  public bookableTill: string
  public validFrom: string
  public validTill: string
  public validFromTime: string
  public validTillTime: string
  public validDays: string[]
  public validRenewal: boolean
  public rates: RateUpdateModel[]

  public constructor() {
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0')
    this.name = ''
    this.bookableFrom = `${year}-${month}-01T00:00:00.000Z`
    this.bookableTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFrom = `${year}-${month}-01T00:00:00.000Z`
    this.validTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFromTime = '00:00'
    this.validTillTime = '23:59'
    this.validDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    this.validRenewal = false
    this.rates = []
  }
}

export class RateSpanCreateModel extends RateSpanUpdateModel {
  public pool: PoolReference
  public resource: ResourceReference | null
  public service: ServiceReference | null
  public kind: ServiceKind
  public type: ServiceType
  public flightDirection: FlightDirection
  public program: OrganizationProgramReference | null
  public contract: OrganizationContractReference | null

  public constructor() {
    super()
    this.pool = new PoolReference()
    this.resource = null
    this.service = null
    this.kind = ServiceKind.Any
    this.type = ServiceType.Any
    this.flightDirection = FlightDirection.Any
    this.program = null
    this.contract = null
  }
}
