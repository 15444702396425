import { EMPTY_ID } from './index'

export class PaymentRequisites {
  public id: string;
  public paymentAccount: string | null;
  public correspondentAccount: string | null;
  public bankName: string | null;
  public bankIdentificationCode: string | null;

  public constructor() {
    this.id = EMPTY_ID;
    this.paymentAccount = null;
    this.correspondentAccount = null;
    this.bankName = null;
    this.bankIdentificationCode = null;
  }
}
