import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { AuthContext } from '../../auth'
import { Chunk, Pair } from '../../data'
import { FieldSet, Form, ID, IntlMessage } from '../../gears'
import { ComboBox, TextBox } from '../../gears/inputs'
import { length, required } from '../../gears/validators'
import { EMPTY_ID, OrganizationContractType, OrganizationReference, OrganizationType, Pool, PoolCopyModel, PoolCreateModel } from '../data/models'
import { Div, IntlText, TXT } from '../gears'
import { useCurrencies, useCurrency, useField, useFormatMessage, useOrganization, useOrganizationContract, useOrganizationContracts, useOrganizationCurrency, usePool, usePools } from '../hooks'
import { firstOrNull } from '../utils'
import { ItemViewProps } from './types'

export interface PoolItemProps extends ItemViewProps<Pool, PoolCreateModel> {
  label?: IntlText | string;
  error?: IntlText | null;
  provider?: OrganizationReference;
}

export function PoolItem(props: PoolItemProps): ReactElement {
  const {
    item,
    lock,
    label,
    error,
    readonly,
    onSubmit,
    onCancel,
    onDelete,
    onEdit,
    onFree,
    provider,
  } = props

  const intl = useIntl()
  const formatMessage = useFormatMessage()

  const isNew = item.id === EMPTY_ID

  const [ name, setName ] = useField<string | null>(item.name)
  const [ currencyPairs ] = useCurrencies()
  const [ currencyPair, setCurrencyPair ] = useState<Pair | null>(null)
  const [ organization ] = useOrganization(item.organization.id)
  const [ organizationCurrency ] = useOrganizationCurrency(item.organization.id !== EMPTY_ID ? item.organization.id : null)
  const [ currency ] = useCurrency(item.currency.id !== EMPTY_ID ? item.currency.id : null)
  const [ selectedCurrency ] = useCurrency(currencyPair ? currencyPair.code : null)
  const [ contract, setContract ] = useOrganizationContract(item.rules[0]?.contract?.id ?? null)
  const [ contracts, getContracts ] = useOrganizationContracts(100, isNew && organization !== null && organization.type === OrganizationType.Agency, useMemo(() => ({
    byType: OrganizationContractType.Deposit,
    byProviderId: provider?.id,
    byAgencyId: item.organization.id,
  }), [ item.organization.id, provider ]))

  useEffect(
    () => {
      const defaultCurrency = currency ?? organizationCurrency
      if (defaultCurrency !== null) {
        setCurrencyPair(firstOrNull(currencyPairs.filter(x => x.code === defaultCurrency.code)))
      }
    },
    [ currencyPairs, currency, organizationCurrency ],
  )

  const handleSubmit = useCallback(
    async (): Promise<void> => {
      if (onSubmit && organization && selectedCurrency && name) {
        let model = new PoolCreateModel()
        model.organization = organization
        model.currency = selectedCurrency
        model.name = name
        model.contract = contract
        await onSubmit(model)
      }
    },
    [ name, organization, selectedCurrency, contract, onSubmit ],
  )

  const validateName = useCallback(
    (value: string | null): IntlMessage | undefined => {
      if (value === '(DEFAULT)' && item.name !== '(DEFAULT)')
        return ID("valid.name");

      return undefined;
    },
    [ item.name ],
  )

  return (
    <Form loaded={true}
          lock={lock}
          label={label}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={item.name !== '(DEFAULT)' ? onDelete : undefined}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12 6@md">
              <Div>
                <TextBox label={TXT('label.name')}
                         fill={true}
                         disabled={item.name === '(DEFAULT)'}
                         name="name"
                         value={item.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : name}
                         validators={[ required, value => length(value, 3, 256), validateName ]}
                         onChange={setName} />
              </Div>
              <Div>
                <ComboBox label={TXT('label.currency')}
                          fill={true}
                          disabled={!isNew}
                          name="currency"
                          data={currencyPairs}
                          value={currencyPair}
                          valueKey="code"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setCurrencyPair} />
              </Div>
              {organization && organization.type === OrganizationType.Agency &&
              <Div>
                <ComboBox label={TXT('label.contract')}
                          fill={true}
                          disabled={!isNew}
                          searchable={true}
                          name="contract"
                          data={contracts}
                          value={contract}
                          valueKey="id"
                          valueLabel="number"
                          validators={[ required ]}
                          onChange={setContract}
                          onFetch={getContracts} />
              </Div>
              }
            </Div>
          </FieldSet>
          {error &&
          <Div intent={'danger'}>
            {formatMessage(error)}
          </Div>
          }
        </Div>
      </Div>
    </Form>
  )
}

export interface PoolCopyProps extends ItemViewProps<Pool, PoolCopyModel> {
  label?: IntlText | string;
  provider?: OrganizationReference;
}

export function PoolCopy(props: PoolCopyProps): ReactElement {
  const {
    item,
    lock,
    label,
    readonly,
    onSubmit,
    onCancel,
    onDelete,
    onEdit,
    onFree,
    provider,
  } = props

  const intl = useIntl()

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const pageSize = 100

  const [ organization ] = useField(item.organization)

  const [ sourcePools, getSourcePools ] = usePools(pageSize, organization !== null, useMemo(() => ({
    byOrganizationId: profile.isSys ? organization?.id : undefined,
    byProviderId: profile.isOwner ? provider?.id : undefined,
    byResellerId: profile.isAgent ? provider?.id : undefined,
  }), [ organization, profile, provider ]))
  const [ sourcePool, setSourcePool ] = usePool(item.id)

  const [ destinationPools, getDestinationPools ] = usePools(pageSize, organization !== null, useMemo(() => ({
    byOrganizationId: profile.isSys ? organization?.id : undefined,
    byProviderId: profile.isOwner ? provider?.id : undefined,
    byResellerId: profile.isAgent ? provider?.id : undefined,
    byCurrencyId: sourcePool?.currency.id,
    byExcludedId: sourcePool?.id,
  }), [ organization, profile, provider, sourcePool ]))
  const [ destinationPool, setDestinationPool ] = usePool(firstOrNull(destinationPools.data)?.id ?? null)

  useEffect(
    () => {
      setDestinationPool(null)
    },
    [ sourcePool, setDestinationPool ],
  )

  const handleSubmit = useCallback(
    async (): Promise<void> => {
      if (onSubmit && sourcePool && destinationPool) {
        let model = new PoolCopyModel()
        model.source = sourcePool
        model.destination = destinationPool
        await onSubmit(model)
      }
    },
    [ sourcePool, destinationPool, onSubmit ],
  )

  const validateCurrency = useCallback(
    (value: Pool | null): IntlMessage | undefined => {
      if (sourcePool && value && value.currency.id !== sourcePool.currency.id) {
        return ID('valid.currencyMatch')
      }

      return undefined
    },
    [ sourcePool ],
  )

  const renamedSourcePools = useMemo(
    () => {
      return new Chunk(sourcePools.data.map((pool) => ({
        ...pool,
        name: pool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : pool.name,
      })), sourcePools.skip, sourcePools.take, sourcePools.total)
    },
    [ sourcePools, intl ],
  )

  const renamedDestinationPools = useMemo(
    () => {
      return new Chunk(destinationPools.data.map((pool) => ({
        ...pool,
        name: pool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : pool.name,
      })), destinationPools.skip, destinationPools.take, destinationPools.total)
    },
    [ destinationPools, intl ],
  )

  return (
    <Form loaded={true}
          lock={lock}
          label={label}
          readonly={readonly}
          labelSubmit={ID('action.copy')}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12 6@md">
              <Div>
                <ComboBox label={TXT('label.source')}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="source"
                          data={renamedSourcePools}
                          value={sourcePool
                            ? {
                              ...sourcePool,
                              name: sourcePool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : sourcePool.name,
                            }
                            : null}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required ]}
                          onChange={setSourcePool}
                          onFetch={getSourcePools} />
              </Div>
              <Div>
                <ComboBox label={TXT('label.destination')}
                          fill={true}
                          searchable={true}
                          pageSize={pageSize}
                          name="destination"
                          data={renamedDestinationPools}
                          value={destinationPool
                            ? {
                              ...destinationPool,
                              name: destinationPool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : destinationPool.name,
                            }
                            : null}
                          valueKey="id"
                          valueLabel="name"
                          validators={[ required, validateCurrency ]}
                          onChange={setDestinationPool}
                          onFetch={getDestinationPools} />
              </Div>
            </Div>
          </FieldSet>
        </Div>
      </Div>
    </Form>
  )
}
