import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Chunk } from "../../data";
import { AirportBaseV1, TerminalBaseV1 } from "../../data/models";
import { AirportAccess, AirportAccessV1 } from "../data/access";
import { Airport, AirportTerminalName } from '../data/models'
import { FetchList } from "./types";

export function useAirports(pageSize?: number, prefetch?: boolean, byContractId?: string, byPoolId?: string | string[]): [ Chunk<Airport>, FetchList ] {
  const [ data, setData ] = useState<Chunk<Airport>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await AirportAccess.getAll(pattern, undefined, skip || 0, take || pageSize, byContractId, byPoolId);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, setData, byContractId, byPoolId ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useAirport(airportId: string | null): [ Airport | null, Dispatch<SetStateAction<Airport | null>> ] {
  const [ item, setItem ] = useState<Airport | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (airportId) {
          setItem(await AirportAccess.getOne(airportId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ airportId, setItem ]);

  return [ item, setItem ];
}

export function useAirportTerminals(airportId: string | null, prefetch?: boolean): [ Chunk<AirportTerminalName>, FetchList ] {
  const [ data, setData ] = useState<Chunk<AirportTerminalName>>(new Chunk());

  const fetch = useCallback(async (): Promise<void> => {
    try {
      const data = airportId ? await AirportAccess.getOneTerminals(airportId) : new Chunk<AirportTerminalName>();
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ airportId, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useAirportsV1(pageSize?: number, prefetch?: boolean): [ Chunk<AirportBaseV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<AirportBaseV1>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await AirportAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useAirportTerminalsV1(code: number | string | null, prefetch?: boolean): [ Chunk<TerminalBaseV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<TerminalBaseV1>>(new Chunk());

  const fetch = useCallback(async (): Promise<void> => {
    try {
      const data = code ? await AirportAccessV1.fetchTerminals(code) : [];
      setData(new Chunk(data));
    } catch {
      setData(new Chunk());
    }
  }, [ code, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}
