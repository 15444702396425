import React from "react";

export type FormContextValue = {
  loaded: boolean;
  readonly: boolean;
  submitting: boolean;
  cancelling: boolean;
  deleting: boolean;
  onFieldChange: (name: string, isValid?: boolean, changed?: boolean) => void;
  onFieldRemove: (name: string, exact: boolean) => void;
};

export const FormContext = React.createContext<FormContextValue>({
  loaded: false,
  readonly: false,
  submitting: false,
  cancelling: false,
  deleting: false,
  onFieldChange: () => undefined,
  onFieldRemove: () => undefined,
});
