import { EMPTY_ID } from './index'

export class AirportTerminalReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class AirportTerminalName extends AirportTerminalReference {
  public code: string
  public name: string

  public constructor() {
    super();
    this.code = ''
    this.name = ''
  }
}

export enum TransferPointType {
  Common = 'Common',
  Lounge = 'Lounge',
}
