export class Chunk<TItem> {
  public data: TItem[];
  public skip: number;
  public take: number;
  public total: number;

  public constructor(data?: TItem[], skip?: number, take?: number, total?: number) {
    this.data = data || [];
    this.skip = skip || 0;
    this.take = take || 0;
    this.total = total || 0;
  }
}

export function isChunk<TValue>(data: Chunk<TValue> | TValue[]): data is Chunk<TValue> {
  return (data as Chunk<TValue>).data !== undefined;
}
