import { CityBase } from './City'
import { FlightDirection } from './FlightSpan'
import { ServiceType } from './Service'

export class RatedFlight {
  public city: CityBase | null
  public type: ServiceType
  public direction: FlightDirection
  public number: string
  public date: string
  public zone: string

  public constructor() {
    this.city = null
    this.type = ServiceType.Any
    this.direction = FlightDirection.Any
    this.number = ''
    this.date = ''
    this.zone = 'UTC'
  }
}
