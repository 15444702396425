import { Apis, Chunk, Client, Lock, LockAction } from "../../../data";
import { ScheduleV1, ScheduleBaseV1, ScheduleRecord } from "../../../data/models";

export class ScheduleAccessV1 {
  public static async fetchItem(id: number): Promise<ScheduleV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<ScheduleV1>("Schedule", { id });
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number, resourceId?: number): Promise<Chunk<ScheduleBaseV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ScheduleBaseV1>>("Schedule", {}, {
        pattern,
        orderBy,
        skip,
        take,
        resourceId,
      });
  }

  public static async fetchGrid(pattern?: string, orderBy?: string, skip?: number, take?: number, resourceId?: number): Promise<Chunk<ScheduleRecord>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<ScheduleRecord>>("Schedule/grid", {}, {
        pattern,
        orderBy,
        skip,
        take,
        resourceId,
      });
  }

  public static async create(item: ScheduleV1): Promise<ScheduleV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Schedule", item);
  }

  public static async update(id: number, item: ScheduleV1): Promise<ScheduleV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .update("Schedule", item, { id });
  }

  public static async delete(id: number): Promise<void> {
    return await Client
      .instance(await Apis.getDataApi())
      .delete("Schedule", { id });
  }

  public static async check(item: ScheduleV1): Promise<ScheduleV1[]> {
    return await Client
      .instance(await Apis.getDataApi())
      .create("Schedule/check", item);
  }

  public static async lock(id: number | string, action: LockAction): Promise<Lock> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Lock>("Schedule/lock", { id }, { action })
  }
}
