import * as KendoLayouts from "@progress/kendo-react-layout";
import React, { PureComponent, ReactElement, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface TabsProps<TData> {
  items: TabItem<TData>[];
  render: (item: TabItem<TData>, index: number) => ReactElement;
  selected: number;
  onSelect: (value: number) => void;
}

interface TabsProps<TData> {
  children?: null;
}

interface TabsState {
}

interface TabItem<TData> {
  label?: IntlMessage;
  data: TData;
}

export class Tabs<TData> extends PureComponent<TabsProps<TData>, TabsState> {
  public constructor(props: TabsProps<TData>) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { items, render, selected, onSelect } = this.props;

    return (
      <Intl render={intl =>
        <KendoLayouts.TabStrip animation={false} keepTabsMounted={true} selected={selected} onSelect={e => onSelect(e.selected)}>
          {items.map((item, index) =>
            <KendoLayouts.TabStripTab key={index} title={item.label && getIntlMessage(intl, item.label)}>
              {render(item, index)}
            </KendoLayouts.TabStripTab>
          )}
        </KendoLayouts.TabStrip>
      } />
    );
  }
}
