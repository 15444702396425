import { EMPTY_ID } from './index'

export class CurrencyReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class CurrencyBase extends CurrencyReference {
  public code: string
  public symbol: string
  public rounding: number

  public constructor() {
    super()
    this.code = ''
    this.symbol = ''
    this.rounding = 0
  }
}

export class Currency extends CurrencyBase {
  public constructor() {
    super();
  }
}
