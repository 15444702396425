import moment from 'moment'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { useHistory } from 'react-router'
import { Chunk } from '../../data'
import { Event, EventKind, EventPriority, EventType, Order, PassengerCategory, Seat } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, parseOrderBy, Popup, replaceSortOrder, TXT } from '../gears'
import { useEventKind, useEventPriorities, useEvents, useFlightTypes, useFormatCode, useFormatDate, useFormatMessage, useFormatPassenger, useOrderStatuses, useWindowSize } from '../hooks'
import { firstOrNull } from '../utils'
import { GridViewProps } from './types'

export interface OrderQueueGridProps extends GridViewProps<Event<Order>> {}

export function OrderQueueGrid(props: OrderQueueGridProps): ReactElement {
  const { data, onItemSelect, onPageChange, onSortChange } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const [ , formatEvent ] = useEventKind();
  const [ , formatPriority ] = useEventPriorities();
  const [ , formatOrderStatus ] = useOrderStatuses();
  const [ , formatFlightTypes ] = useFlightTypes();

  const [ processedData, setProcessedData ] = useState(new Chunk<any>());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      let event = formatEvent(item.kind);
      let message = "";
      switch (item.kind) {
        case EventKind.OrderCreated:
          event = `${event}${item.priority === EventPriority.High ? ` (${formatMessage(TXT("label.urgent")).toLowerCase()})` : ""}`;
          message = formatMessage(TXT("message.orderPaymentRequired", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderConfirmed:
          message = formatMessage(TXT("message.orderConfirmed", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderRejected:
          message = formatMessage(TXT("message.orderRejected", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderPending:
          message = formatMessage(TXT("message.orderPending", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderPaid:
          message = formatMessage(TXT("message.orderPaid", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderCancelled:
          message = formatMessage(TXT("message.orderCanceled", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderCompleted:
          message = formatMessage(TXT("message.orderCompleted", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderChanged:
          message = formatMessage(TXT("message.orderChanged", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderForced:
          message = formatMessage(TXT("message.orderForced", { id: formatCode(item.details.id, 6) }));
          break;
        case EventKind.OrderRefund:
          message = formatMessage(TXT("message.orderRefund", { id: formatCode(item.details.id, 6) }));
          if (item.description) {
            message += ` (${item.description})`
          }
          break;
      }

      const span = firstOrNull(item.details.spans)
      if (span === null) continue;
      const flight = firstOrNull(span.resources);
      if (flight === null) continue;
      const infantCount = flight.passengers.filter(seat => seat.category === PassengerCategory.Infant).length;
      const passengerCount = flight.passengers.length - infantCount;
      processedData.data.push({
        id: item.id,
        event: event,
        message: message,
        orderNo: formatCode(item.details.id, 6),
        orderStatus: formatOrderStatus(item.details.status),
        flightType: formatFlightTypes(flight.flights[0].type),
        flightNumber: `${flight.flights[0].number}`,
        flightDate: formatDate(flight.flights[0].date, true, flight.flights[0].zone),
        passengerCount: `${passengerCount}${infantCount > 0 ? ` + ${infantCount}` : ""}`,
        flight: `${flight.flights[0].number} / ${formatDate(flight.flights[0].date)}`,
        createdAt: formatDate(item.occuredAt, true),
        priority: formatPriority(item.priority),
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatCode, formatDate, formatMessage, formatEvent, formatPriority, formatOrderStatus, formatFlightTypes ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: any, selected?: boolean): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem, selected);
    }
  }, [ data.data, onItemSelect ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);

      replaceSortOrder(sortOrder, "flight", "flightNumber", "flightDate");
      replaceSortOrder(sortOrder, "event", "priority", "event");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [ onSortChange ]);

  return (
    <Div layout="grid 12">
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  showSelector={true}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.id")} field="orderNo" width={92}  locked={true} sortable={false} />
              <GridColumn title={TXT("label.message")} field="message" width={384} fill={true} sortable={false} />
              <GridColumn title={TXT("label.status")} field="orderStatus" sortable={false} width={128} />
              <GridColumn title={TXT("label.flightType")} field="flightType" sortable={false} width={128} />
              <GridColumn title={TXT("label.flightNumber")} field="flightNumber" sortable={false} width={128} />
              <GridColumn title={TXT("label.flightDate")} field="flightDate" sortable={false} width={192} />
              <GridColumn title={TXT("label.passengerCount")} field="passengerCount" sortable={false} width={128} />
              <GridColumn title={TXT("label.dateTime")} field="createdAt" width={192} sortable={false} />
              <GridColumn title={TXT("label.priority")} field="priority" width={192} locked={true} sortable={false} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}

export interface SeatQueueGridProps extends GridViewProps<Event<Seat>> {}

export function SeatQueueGrid(props: SeatQueueGridProps): ReactElement {
  const { data, onItemSelect, onPageChange, onSortChange } = props;

  const formatCode = useFormatCode();
  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();
  const [ , formatEvent ] = useEventKind();
  const [ , formatPriority ] = useEventPriorities();

  const [ processedData, setProcessedData ] = useState(new Chunk<any>());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      let event = formatEvent(item.kind);
      let message = "";
      switch (item.kind) {
        case EventKind.TimeExpired:
          message = formatMessage(TXT("message.seatOvertime", { id: formatCode(item.details.order.id, 6), name: formatPassenger(item.details.order.passengers.filter(x => item.details.passenger && x.id === item.details.passenger.id)[0] || {}) }));
          break;
      }
      const flight = item.details.flight;
      processedData.data.push({
        id: item.id,
        event: event,
        message: message,
        flight: `${flight.flightNumber} / ${formatDate(flight.flightDate)}`,
        createdAt: formatDate(item.occuredAt, true),
        priority: formatPriority(item.priority),
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatCode, formatDate, formatMessage, formatPassenger, formatEvent, formatPriority ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: any): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [ data.data, onItemSelect ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);

      replaceSortOrder(sortOrder, "flight", "flightNumber", "flightDate");
      replaceSortOrder(sortOrder, "event", "priority", "event");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [ onSortChange ]);

  return (
    <Div layout="grid 12">
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.event")} field="event" width={256} locked={true} sortable={false} />
              <GridColumn title={TXT("label.message")} field="message" width={384} fill={true} sortable={false} />
              <GridColumn title={TXT("label.flight")} field="flight" width={192} sortable={false} />
              <GridColumn title={TXT("label.dateTime")} field="createdAt" width={192} sortable={false} />
              <GridColumn title={TXT("label.priority")} field="priority" width={128} locked={true} sortable={false} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}

export function TimeQueueNotifier(): ReactElement {
  const history = useHistory();
  const formatCode = useFormatCode();
  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();

  const [ data, resolve ] = useEvents(undefined, EventType.Time);

  const [ toggled, setToggled ] = useState(false);

  const toggle = useCallback((): void => {
    setToggled(prev => !prev);
  }, [ setToggled ]);

  const formatUrl = useCallback((item: Event<Seat>): string => {
    return `/control?resource=${item.details.flight.resource.id}&flightDate=${moment(new Date(item.details.flight.flightDate)).startOf('day').toISOString()}&order=${item.details.order.id}&flight=${item.details.flight.id}&seat=${item.details.id}`;
  }, []);

  const handleClick = useCallback((e: React.MouseEvent, item: Event<Seat>): void => {
    history.push(`${formatUrl(item)}`);
    resolve(item.id).finally();
    toggle();
    e.preventDefault();
  }, [ history, resolve, toggle, formatUrl ]);

  return (
    <Popup anchor={<Button disabled={data.data.length === 0} icon="bell" intent={data.data.length > 0 ? "danger" : undefined} look="bare" onClick={toggle} />} toggled={toggled} onClose={toggle}>
      {data.data.length > 0 &&
      <Div layout="grid 12" style={{ width: "480px" }}>
        {data.data.map(item => item as Event<Seat>).map((item, index) =>
          <Div key={index} style={{ padding: "0 8px" }}>
            <a href={`${formatUrl(item)}`} onClick={e => handleClick(e, item)}>
              {formatMessage(TXT("message.seatOvertime"))}
              <br />
              {formatMessage(TXT("message.seatOvertimeInfo", { id: formatCode(item.details.order.id, 6), name: formatPassenger(item.details.order.passengers.filter(x => item.details.passenger && x.id === item.details.passenger.id)[0] || {}) }))}
            </a>
          </Div>
        )}
      </Div>
      }
    </Popup>
  );
}
