import { OrderedFlight } from './OrderedFlight'
import { ResourceReference } from './Resource'
import { UpdatedPassenger } from './UpdatedPassenger'
import { UpdatedService } from './UpdatedService'

export class UpdatedResource {
  public id: number
  public passengers: UpdatedPassenger[]
  public resource: ResourceReference
  public flights: OrderedFlight[]
  public services: UpdatedService[] | null

  public constructor() {
    this.id = 0
    this.passengers = []
    this.resource = new ResourceReference()
    this.flights = []
    this.services = null
  }
}
