import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { Chunk, Pair } from '../../data'
import { Drawer, FieldSet, Form, FormContext, guid } from '../../gears'
import { ComboBox, RichBox, TextBox, TimeBox } from '../../gears/inputs'
import { length, required } from '../../gears/validators'
import { EMPTY_ID, Media, MediaKind, ResolvedService, ResourceReference, Service, ServiceCreateModel, ServiceFieldDescription, ServiceFieldType, ServiceKind, ServiceMode, ServiceSettings, ServiceTranslation, ServiceType, ServiceUnit } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, TXT } from '../gears'
import { useDelay, useField, useFormatMessage, useRequired, useResource, useSecondaryServiceKinds, useServiceFieldTypes, useServiceModes, useServiceTypes, useWindowSize } from '../hooks'
import { MediaView } from './Medias'
import { GridViewProps, ItemViewProps } from './types'

interface ServiceFieldItemProps {
  name: string;
  item: ServiceFieldDescription;
  index: number;
  onChange: (index: number, item: ServiceFieldDescription) => void;
  onRemove: (index: number) => void;
}

function ServiceFieldItem(props: ServiceFieldItemProps): ReactElement {
  const { name, item, index, onChange, onRemove } = props

  const form = useContext(FormContext)

  const [itemName, setItemName] = useField<string | null>(item.name)
  const [itemTypes] = useServiceFieldTypes()
  const [itemType, setItemType] = useField<Pair<ServiceFieldType> | null>(itemTypes.filter(x => x.code === item.type)[0] || null)
  const [itemKinds] = useRequired()
  const [itemKind, setItemKind] = useField<Pair<boolean> | null>(itemKinds.filter(x => x.code === item.required)[0] || null)

  useEffect(() => {
    const changedItem = { ...item }
    changedItem.name = itemName || ''
    changedItem.type = itemType ? itemType.code : ServiceFieldType.Text
    changedItem.required = itemKind !== null && itemKind.code

    if (!R.equals(item, changedItem)) {
      onChange(index, changedItem)
    }
  }, [item, index, itemName, itemType, itemKind, onChange])

  const handleRemove = useCallback((): void => {
    onRemove(index)
  }, [index, onRemove])

  return (
    <Div layout="flex">
      {!form.readonly &&
        <Div layout="fit">
          <Button disabled={form.submitting}
            look="bare"
            icon="minus-outline"
            onClick={handleRemove} />
        </Div>
      }
      <Div layout="fit">
        <ComboBox name={`${name}-type`}
          data={itemTypes}
          value={itemType}
          valueKey="code"
          valueLabel="name"
          validators={[required]}
          onChange={setItemType} />
      </Div>
      <Div layout="fit">
        <TextBox placeholder={TXT('label.name')}
          name={`${name}-name`}
          value={itemName}
          validators={[required, value => length(value, 1, 256)]}
          onChange={setItemName} />
      </Div>
      <Div layout="fit">
        <ComboBox name={`${name}-required`}
          data={itemKinds}
          value={itemKind}
          valueKey="code"
          valueLabel="name"
          validators={[required]}
          onChange={setItemKind} />
      </Div>
    </Div>
  )
}

interface ServiceFieldListProps {
  name: string;
  data: ServiceFieldDescription[] | null;
  onChange: (items: ServiceFieldDescription[]) => void;
}

function ServiceFieldList(props: ServiceFieldListProps): ReactElement {
  const { name, data, onChange } = props
  const fields = data ? [...data] : []

  const form = useContext(FormContext)

  const handleAdd = useCallback((): void => {
    const id = guid()
    const fieldDescription = new ServiceFieldDescription()
    fieldDescription.id = id
    fields.push(fieldDescription)
    onChange(fields)
  }, [fields, onChange])

  const handleRemove = useCallback((index: number): void => {
    fields.splice(index, 1)
    onChange(fields)
  }, [fields, onChange])

  const handleChange = useCallback((index: number, field: ServiceFieldDescription): void => {
    fields.splice(index, 1, field)
    onChange(fields)
  }, [fields, onChange])

  return (
    <Div>
      {data && data.map((item, index) =>
        <ServiceFieldItem key={index} name={`${name}-${item.id}`} item={item} index={index} onChange={handleChange}
          onRemove={handleRemove} />,
      )}
      {!form.readonly &&
        <Div layout="flex vertical-center">
          <Div layout="fit">
            <Button disabled={form.submitting}
              look="bare"
              icon="plus-outline"
              onClick={handleAdd} />
          </Div>
        </Div>
      }
    </Div>
  )
}

interface ServiceTranslationItemProps {
  readonly: boolean
  name: string
  index: number
  value: ServiceTranslation
  languages: string[]
  onChange: (index: number, value: ServiceTranslation) => void
  onRemove: (index: number) => void
}

function ServiceTranslationItem(props: ServiceTranslationItemProps) {
  const { readonly, name, index, value, languages, onChange, onRemove } = props

  const formatMessage = useFormatMessage()

  const handleChange = useCallback((change: Partial<ServiceTranslation>) => {
    onChange(index, { ...value, ...change })
  }, [index, value, onChange])

  const handleRemove = useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  return (
    <Div>
      <FieldSet label={value.language}>
        <Div layout="grid 12">
          <Div>
            <ComboBox label={TXT('label.language')}
              fill={true}
              readonly={readonly}
              name={`${name}-language`}
              data={languages}
              value={value.language}
              validators={[required]}
              onChange={language => handleChange({ language: language || '' })} />
          </Div>
          <Div>
            <TextBox label={TXT('label.name')}
              fill={true}
              readonly={readonly}
              name={`${name}-name`}
              value={value.name}
              validators={[value => length(value, 1, 256)]}
              onChange={name => handleChange({ name: name ? name.substring(0, 256) : null })} />
          </Div>
          <Div>
            <RichBox fill={true}
              readonly={readonly}
              name={`${name}-description`}
              value={value.description}
              onChange={description => handleChange({ description })} />
          </Div>
          {!readonly &&
            <Div layout="flex">
              <Div layout="fill" />
              <Div layout="fit">
                <Button
                  look="outline"
                  className="action"
                  onClick={handleRemove}>
                  {formatMessage(TXT('action.delete'))}
                </Button>
              </Div>
            </Div>
          }
        </Div>
      </FieldSet>
    </Div>
  )
}

interface ServiceTranslationListProps {
  readonly: boolean;
  value: ServiceTranslation[];
  onChange: (value: ServiceTranslation[]) => void;
}

function ServiceTranslationList(props: ServiceTranslationListProps) {
  const { readonly, value, onChange } = props

  const form = useContext(FormContext)

  const formatMessage = useFormatMessage()

  const [items, setItems] = useState(value)

  const [allLanguages] = useState(['en', 'ru'])

  const [selLanguages, setSelLanguages] = useState<string[]>([])

  const [newLanguages, setNewLanguages] = useState<string[]>([])

  useEffect(() => {
    setSelLanguages(items.map(x => x.language).filter(x => x.length > 0))
  }, [items])

  useEffect(() => {
    setNewLanguages(allLanguages.filter(x => !selLanguages.some(y => x === y)))
  }, [allLanguages, selLanguages])

  const handleChange = useCallback((index: number, value: ServiceTranslation) => {
    const change = [...items]
    change.splice(index, 1, value)
    setItems(change)
  }, [items, setItems])

  const handleRemove = useCallback((index: number) => {
    const change = [...items]
    change.splice(index, 1)
    form.onFieldRemove(`translations`, false)
    setItems(change)
  }, [items, setItems, form])

  const handleAppend = useCallback(() => {
    const change = [...items, new ServiceTranslation({ language: newLanguages[0] ?? '' })]
    setItems(change)
  }, [items, newLanguages, setItems])

  return (
    <Div style={{ height: '95vh', overflowX: 'hidden', overflowY: 'auto' }}>
      <Div layout="grid 12">
        <Div>
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button look="bare" icon="close" onClick={() => onChange(items)} />
            </Div>
          </Div>
        </Div>
        <Div>
          <span className="header">{formatMessage(TXT('label.translations'))}</span>
        </Div>
        <Div>
          <Div layout="grid 12">
            {items.map((value, index) => (
              <ServiceTranslationItem key={index} readonly={readonly} name="translations" index={index} value={value} languages={newLanguages} onChange={handleChange} onRemove={handleRemove} />
            ))}
          </Div>
        </Div>
        {!readonly &&
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button disabled={form.submitting || newLanguages.length === 0}
                look="outline"
                className="action"
                onClick={handleAppend}>
                {formatMessage(TXT('action.append'))}
              </Button>
            </Div>
          </Div>
        }
      </Div>
    </Div>
  )
}

export interface ServiceItemProps extends ItemViewProps<Service & { images: Media[], translations: ServiceTranslation[] }, ServiceCreateModel & { images: Media[], translations: ServiceTranslation[] }> {
}

export function ServiceItem(props: ServiceItemProps): ReactElement {
  const { item, lock, readonly, onSubmit, onCancel, onDelete, onEdit, onFree } = props

  const id = item.id
  const isNew = id === EMPTY_ID

  const [resource] = useResource(item.resource.id)

  const [name, setName] = useField<string | null>(item.name)

  const [kinds] = useSecondaryServiceKinds()
  const [kind, setKind] = useField<Pair<ServiceKind> | null>(kinds.filter(x => !isNew && x.code === item.kind)[0] || null)

  const [types] = useServiceTypes()
  const [type, setType] = useField<Pair<ServiceType> | null>(types.filter(x => !isNew && x.code === item.type)[0] || null)

  const [modes] = useServiceModes()
  const [mode, setMode] = useField<Pair<ServiceMode> | null>(modes.filter(x => !isNew && x.code === item.mode)[0] || null)

  const [unitCode, setUnitCode] = useField(item.unit)

  const [flightDelta, setFlightDelta] = useField<string | null>(item.kind === ServiceKind.Urgency && item.settings && item.settings.priorityDelta ? item.settings.priorityDelta : null)

  const [fields, setFields] = useField<ServiceFieldDescription[] | null>(item.fields)

  const [description, setDescription] = useField(item.description)

  const [images, setImages] = useField(item.images)
  const [translations, setTranslations] = useField(item.translations)

  useEffect(() => {
    if (isNew && kind) {
      switch (kind.code) {
        case ServiceKind.LoungeTime:
        case ServiceKind.ConferenceRoom:
        case ServiceKind.Apartments:
          setUnitCode(ServiceUnit.Hour)
          break
        case ServiceKind.Luggage:
          setUnitCode(ServiceUnit.Item)
          break
        case ServiceKind.Photography:
          setUnitCode(ServiceUnit.ItemPerHour)
          break
        default:
          setUnitCode(ServiceUnit.Whole)
          break
      }
      setMode(null)
    }
  }, [isNew, kind, setUnitCode, setMode])

  const getItem = useCallback((): ServiceCreateModel & { images: Media[], translations: ServiceTranslation[] } => {
    const model: ServiceCreateModel & { images: Media[], translations: ServiceTranslation[] } = { ...new ServiceCreateModel(), images: [], translations: [] }
    model.kind = kind ? kind.code : ServiceKind.Other
    model.name = name || ''
    model.type = type ? type.code : ServiceType.Any
    model.resource = resource || new ResourceReference()
    model.mode = mode ? mode.code : ServiceMode.Automatic
    model.unit = unitCode
    model.fields = fields
    model.description = description
    model.images = images
    if (flightDelta) {
      model.settings = new ServiceSettings()
      model.settings.priorityDelta = flightDelta
    }
    model.translations = translations

    return model
  }, [
    name, type, resource, mode, unitCode, kind,
    fields, description, images, translations, flightDelta,
  ])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem())
    }
  }, [getItem, onSubmit])

  const formatMessage = useFormatMessage()

  const [translationsToggled, setTranslationsToggled] = useState(false)

  const toggleTranslations = useCallback(() => {
    setTranslationsToggled(prev => !prev)
  }, [setTranslationsToggled])

  const handleTranslations = useCallback((value: ServiceTranslation[]) => {
    setTranslations(value.filter(x => !!x.language))
    toggleTranslations()
  }, [toggleTranslations, setTranslations])

  return (
    <Form loaded={true}
      lock={lock}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onDelete={onDelete}
      onEdit={onEdit}
      onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT('label.parameters')}>
            <Div layout="grid 12">
              <Div>
                <Div layout="grid 12 3@lg">
                  <Div>
                    <TextBox label={TXT('label.name')}
                      fill={true}
                      name="name"
                      value={name}
                      validators={[required, value => length(value, 3, 256)]}
                      onChange={setName} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT('label.serviceType')}
                      fill={true}
                      name="type"
                      data={types.filter(pair => resource && (pair.code === ServiceType.Any || pair.code === ServiceType.Arrival || pair.code === ServiceType.Departure) && (resource.type === ServiceType.Any || resource.type === pair.code))}
                      value={type}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setType} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT('label.serviceCategory')}
                      disabled={!readonly && !isNew}
                      fill={true}
                      name="category"
                      data={kinds}
                      value={kind}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setKind} />
                  </Div>
                  <Div>
                    <ComboBox label={TXT('label.serviceMode')}
                      fill={true}
                      name="mode"
                      data={modes.filter(pair => kind && (kind.code === ServiceKind.ConferenceRoom || pair.code !== ServiceMode.SemiAutomatic))}
                      value={mode}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setMode} />
                  </Div>
                  {kind && kind.code === ServiceKind.Urgency &&
                    <Div>
                      <TimeBox label={TXT('label.flightDelta')}
                        fill={true}
                        name="time"
                        value={flightDelta}
                        validators={[required]}
                        onChange={setFlightDelta} />
                    </Div>
                  }
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12">
                  <Div layout="text-right">
                    <Button look="bare" primary={true} onClick={toggleTranslations}>
                      {formatMessage(TXT("label.translations"))}
                    </Button>
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {kind && kind.code === ServiceKind.Other &&
          <Div>
            <FieldSet label={TXT('label.orderFields')}>
              <ServiceFieldList name="orderFields"
                data={fields}
                onChange={setFields} />
            </FieldSet>
          </Div>
        }
        <Div>
          <FieldSet label={TXT('label.description')}>
            <RichBox fill={true}
              name="description"
              value={description}
              onChange={setDescription} />
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT('label.media')}>
            <MediaView data={images} kind={MediaKind.Image} onChange={!readonly ? setImages : undefined} />
          </FieldSet>
        </Div>
      </Div>
      {translationsToggled &&
        <Drawer>
          <Div>
            <ServiceTranslationList readonly={readonly} value={translations} onChange={handleTranslations} />
          </Div>
        </Drawer>
      }
    </Form>
  )
}

export interface ServiceGridProps extends GridViewProps<ResolvedService> {
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function ServiceGrid(props: ServiceGridProps): ReactElement {
  const { data, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props

  const formatMessage = useFormatMessage()

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(null)
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk())

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total)
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        code: item.code,
        name: item.name,
      })
      setProcessedData(processedData)
    }
  }, [
    data.data, data.skip, data.take, data.total,
    setProcessedData,
  ])

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || '')
    }
  }, [delayedPattern, onItemSearch])

  const [width, setWidth] = useState<number>(1)
  const [, innerWidth] = useWindowSize()

  useEffect(() => {
    setWidth(1)
  }, [innerWidth])

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width)
    }
  }, [setWidth])

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0]
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem)
    }
  }, [data.data, onItemSelect])

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy)
      replaceSortOrder(sortOrder, 'code', 'code')

      onSortChange(formatOrderBy(sortOrder))
    }
  }, [onSortChange])

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
        <Div>
          <Div layout="flex">
            {onItemSearch &&
              <Div layout="fill">
                <TextBox placeholder={TXT('label.search')}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern} />
              </Div>
            }
            {onItemCreate &&
              <Div layout="fit">
                <Button primary={true} className="action" onClick={onItemCreate}>
                  {formatMessage(TXT('action.create'))}
                </Button>
              </Div>
            }
          </Div>
        </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
              width={`${width}px`}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT('label.code')} field="code" width={92} locked={true} />
              <GridColumn title={TXT('label.name')} field="name" fill={true} />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  )
}
