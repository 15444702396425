import { Apis, Chunk, Client } from '../../../data'
import { OrganizationProgram, OrganizationProgramCreateModel, OrganizationProgramFilter, OrganizationProgramSettings, OrganizationProgramUpdateModel, ResolvedOrganizationProgram } from '../models'

export class OrganizationProgramAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: OrganizationProgramFilter): Promise<Chunk<ResolvedOrganizationProgram>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`programs`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<OrganizationProgram> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`programs/${id}`);
  }

  public static async getOneSettings(id: string): Promise<OrganizationProgramSettings> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`programs/${id}/settings`)
  }
  
  public static async create(model: OrganizationProgramCreateModel): Promise<OrganizationProgram> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`programs`, model)
  }
  
  public static async update(id: string, model: OrganizationProgramUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`programs/${id}`, model)
  }
  
  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`programs/${id}`)
  }
}
