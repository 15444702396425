import { classNames } from "@progress/kendo-react-common";
import * as KendoEditor from "@progress/kendo-react-editor"
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { Intl, IntlMessage } from "../Intl";

interface RichBoxProps {
  hint?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  name: string;
  value: string | null;
  error?: IntlMessage;
  validators?: ((value: string | null) => IntlMessage | undefined)[],
  onChange?: (value: string | null) => void;
}

interface RichBoxProps {
  children?: null;
}

interface RichBoxState {
}

export class RichBox extends PureComponent<RichBoxProps, RichBoxState> {
  private readonly editor = React.createRef<KendoEditor.Editor>();

  public constructor(props: RichBoxProps) {
    super(props);

    this.state = {};
  }

  public componentDidUpdate(prevProps: Readonly<RichBoxProps>, prevState: Readonly<RichBoxState>, snapshot?: any): void {
    const { value } = this.props;

    if (prevProps.value !== value && this.editor.current) {
      KendoEditor.EditorUtils.setHtml(this.editor.current.view, value || "");
    }
  }

  public render(): ReactNode {
    const { hint, disabled, readonly, fill, name, value, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;
    const {
      Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
      AlignLeft, AlignCenter, AlignRight, AlignJustify,
      Indent, Outdent, OrderedList, UnorderedList,
      Undo, Redo, FormatBlock, Link, Unlink,
    } = KendoEditor.EditorTools;

    return (
      <Intl render={() =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={false}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) =>
                 <span className={className} onBlur={() => this.handleChange(onFieldChange)}>
                   {(readonly || fieldReadonly || disabled || fieldDisabled || !onFieldChange)
                     ?
                     <div className={classNames("k-widget", "k-editor", (disabled || fieldDisabled || !onFieldChange) ? "k-state-disabled" : undefined)}>
                       <div className="k-content" style={{ height: 128 }}>
                         <div className="k-editable-area ProseMirror" style={{ boxSizing: "border-box", overflowY: "scroll", padding: "0 4px" }} dangerouslySetInnerHTML={{ __html: value || "" }} />
                       </div>
                     </div>
                     : <KendoEditor.Editor ref={this.editor}
                                           style={{ whiteSpace: "pre-wrap" }}
                                           contentStyle={{ height: 128 }}
                                           defaultEditMode="div"
                                           defaultContent={value || undefined}
                                           tools={[
                                             Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
                                             AlignLeft, AlignCenter, AlignRight, AlignJustify,
                                             Indent, Outdent, OrderedList, UnorderedList, Undo, Redo,
                                             FormatBlock, Link, Unlink,
                                           ]} onPasteHtml={this.handlePaste} />
                   }
                 </span>
               }
               onChange={onChange} />
      } />
    );
  }

  private readonly handleChange = (handler: (value: string | null) => void): void => {
    if (this.editor.current) {
      handler(KendoEditor.EditorUtils.getHtml(this.editor.current.view.state));
    }
  };

  private readonly handlePaste = (event: { pastedHtml: string, target: KendoEditor.Editor}): string | void => {
    const { pasteCleanup, sanitize, removeAttribute } = KendoEditor.EditorUtils;
    const pasteSettings = {
      convertMsLists: true,
      stripTags: 'span|font',
      attributes: {
        '*': removeAttribute
      }
    };

    return pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
  }
}
