import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Chunk, Lock, LockAction } from '../data'
import { ButtonGroup, Drawer, Form, ID, NavPathItem } from '../gears'
import { ComboBox, ToggleBox } from '../gears/inputs'
import { LockAccess, OrganizationAccess, PoolAccess, RateAccess, SpanAccess } from '../parts/data/access'
import { CityBase, CurrencyReference, EMPTY_ID, Organization, OrganizationReference, OrganizationType, Pool, PoolCopyModel, PoolCreateModel, PoolReference, Rate, RateCreateModel, RateFilter, RateSpan, RateSpanCreateModel, RateSpanFilter, ResolvedPool, ResolvedRate } from '../parts/data/models'
import { Button, Div, IntlText, Loader, TXT } from '../parts/gears'
import { useOrganization, useOrganizations, usePool, usePoolParent, usePools } from '../parts/hooks'
import { firstOrNull } from '../parts/utils'
import { OrganizationGrid, PoolCopy, PoolItem, RateGrid, RateItem, SpanGrid, SpanItem } from '../parts/views'
import { PageProps, withPage } from './withPage'

interface OrganizationsViewProps extends RouteComponentProps {
  pool: PoolReference;
}

function OrganizationsView(props: OrganizationsViewProps): ReactElement {
  const { pool } = props

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState<Chunk<Organization<CityBase>>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await OrganizationAccess.getAll(pattern, sort, skip, take, {
            byPoolId: pool.id,
            byType: OrganizationType.Agency,
          })
          setData(data)
        } catch {
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading,
    pattern, sort, skip, take, pool, setData,
    setLoaded, setLoading,
  ])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <section>
        <OrganizationGrid data={data}
          showTypes={true}
          onItemSearch={handleItemSearch}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange} />
      </section>
    </Loader>
  )
}

function RatesPage(props: PageProps): ReactElement {
  const { match, history, location, onLoaded, onNavigate, onError } = props

  const intl = useIntl()

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const pageSize = 100

  const [belongsTo] = useOrganization(profile.belongsTo)
  const [organizations, getOrganizations] = useOrganizations(pageSize, !profile.isOwner, useMemo(() => ({
    isConcluded: true,
    isAuthorized: true,
    byAgencyId: profile.isAgent ? belongsTo?.id : undefined
  }), [profile, belongsTo]))
  const [organization, setOrganization] = useOrganization(firstOrNull(organizations.data.map(x => x.id)) || profile.belongsTo)

  const [initPool, setInitPool] = useState<PoolReference | null>(null)

  const [pools, getPools] = usePools(pageSize, organization !== null, useMemo(() => ({
    byOrganizationId: profile.isSys ? organization?.id : undefined,
    // byProviderId: profile.isOwner ? organization?.id : undefined,
    byResellerId: profile.isAgent ? organization?.id : undefined,
  }), [profile.isAgent, profile.isOwner, profile.isSys, organization]))
  const [directPool, setDirectPool] = usePool(initPool?.id ?? firstOrNull(pools.data)?.id ?? null)
  const [parentPools, setParentPool] = usePoolParent(directPool && directPool.organization.type === OrganizationType.Agency ? directPool.id : null)

  const [showResources, setShowResources] = useState(true)
  const [showServices, setShowServices] = useState(false)
  const [showDirectRates, setShowDirectRates] = useState(true)
  const [showParentRates, setShowParentRates] = useState(false)

  const [poolToggled, setPoolToggled] = useState(false)
  const [selectedPool, setSelectedPool] = useState<ResolvedPool | null>(null)

  const [submitError, setSubmitError] = useState<IntlText | null>(null)

  const [filter, setFilter] = useState<RateFilter>({})

  const togglePool = useCallback(
    () => {
      setPoolToggled((prev) => !prev)
    },
    [setPoolToggled],
  )

  const selectedPools = useMemo(
    () => {
      if (showParentRates) return parentPools?.data ?? []

      return directPool ? [directPool] : []
    },
    [directPool, parentPools, showParentRates],
  )

  const [copyToggled, setCopyToggled] = useState(false)

  const toggleCopy = useCallback(
    () => {
      setCopyToggled((prev) => !prev)
    },
    [setCopyToggled],
  )

  const [infoToggled, setInfoToggled] = useState(false)

  const toggleInfo = useCallback(
    () => {
      setInfoToggled((prev) => !prev)
    },
    [setInfoToggled],
  )

  const [transferToggled, setTransferToggled] = useState(false)

  const toggleTransfer = useCallback(
    async (): Promise<void> => {
      setTransferToggled((prev) => !prev)
    },
    [setTransferToggled],
  )

  const handlePoolSubmit = useCallback(
    async (model: PoolCreateModel): Promise<void> => {
      try {
        if (!selectedPool) {
          const item = await PoolAccess.create(model)
          await getPools()
          setInitPool(item)
        } else {
          await PoolAccess.update(selectedPool.id, model)
          const item = await PoolAccess.getOne(selectedPool.id)
          await getPools()
          setDirectPool(item)
        }
        togglePool()
      } catch (e) {
        if ((e as any).status === 403) {
          setSubmitError(TXT('error.poolCantBeCreated'))
        } else {
          onError(e)
          togglePool()
        }
      }
    },
    [selectedPool, togglePool, getPools, setInitPool, setDirectPool, onError, setSubmitError],
  )

  const handlePoolCancel = useCallback(
    async (): Promise<void> => {
      togglePool()
    },
    [togglePool],
  )

  const handlePoolDelete = useCallback(
    async (): Promise<void> => {
      if (selectedPool) {
        try {
          await PoolAccess.delete(selectedPool.id);
          await getPools()
          setInitPool(null)
          togglePool()
        } catch (e) {
          if ((e as any).status === 409 || (e as any).status === 403) {
            setSubmitError(TXT("error.poolCantBeDeleted"))
          } else {
            onError(e)
            togglePool()
          }
        }
      }
    },
    [selectedPool, togglePool, getPools, setInitPool, onError, setSubmitError],
  )

  const handleCopySubmit = useCallback(
    async (model: PoolCopyModel): Promise<void> => {
      await PoolAccess.copy(model)
      toggleCopy()
    },
    [toggleCopy],
  )

  const handleCopyCancel = useCallback(
    async (): Promise<void> => {
      toggleCopy()
    },
    [toggleCopy],
  )

  const handlePoolSelect = useCallback(
    () => {
      setSelectedPool(directPool)
      togglePool()
    },
    [directPool, togglePool, setSelectedPool],
  )

  const handleTransfer = useCallback(
    async (): Promise<void> => {
      if (parentPools && directPool) {
        for (const source of parentPools?.data) {
          await PoolAccess.copy({ source, destination: directPool })
        }
      }
      toggleTransfer()
    },
    [ parentPools, directPool, toggleTransfer ],
  )

  useEffect(
    () => {
      setDirectPool(null)
      setFilter({})
    },
    [organization, setDirectPool],
  )

  useEffect(
    () => {
      setParentPool(null)
    },
    [directPool, setParentPool]
  )

  useEffect(
    () => {
      if (directPool && directPool.organization.type === OrganizationType.Provider) {
        setShowDirectRates(true)
        setShowParentRates(false)
      }
    },
    [directPool, setShowDirectRates, setShowParentRates],
  )

  useEffect(
    () => {
      if (showResources) {
        setShowServices(false)
      }
    },
    [showResources, setShowServices],
  )
  useEffect(
    () => {
      if (showServices) {
        setShowResources(false)
      }
    },
    [showServices, setShowResources],
  )

  useEffect(
    () => {
      if (showDirectRates) {
        setShowParentRates(false)
      }
    },
    [showDirectRates, setShowParentRates],
  )

  useEffect(
    () => {
      if (showParentRates) {
        setShowDirectRates(false)
      }
    },
    [showParentRates, setShowDirectRates],
  )

  useEffect(
    () => {
      if (!poolToggled) {
        setSelectedPool(null)
        setSubmitError(null)
      }
    },
    [poolToggled, setSelectedPool, setSubmitError],
  )

  const renamedPools = useMemo(
    () => {
      return new Chunk(pools.data.map((pool) => ({
        ...pool,
        name: pool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : pool.name,
      })), pools.skip, pools.take, pools.total)
    },
    [pools, intl],
  )

  return (
    <Div layout={'grid 12'}>
      {match.isExact &&
        <React.Fragment>
          <Div>
            <Div layout={'grid 12 3@lg vertical-end'}>
              {!auth.profile.isOwner &&
                <Div>
                  <ComboBox label={TXT('label.organization')}
                    name={'organization'}
                    fill={true}
                    searchable={true}
                    data={organizations}
                    value={organization}
                    valueKey={'id'}
                    valueLabel={'name'}
                    onChange={setOrganization}
                    onFetch={getOrganizations} />
                </Div>
              }
              <Div>
                <ComboBox label={TXT('label.pool')}
                  name={'pool'}
                  fill={true}
                  searchable={true}
                  data={renamedPools}
                  value={directPool
                    ? {
                      ...directPool,
                      name: directPool.name === '(DEFAULT)' ? intl.formatMessage({ id: 'label.defaultPool' }) : directPool.name,
                    }
                    : null}
                  valueKey={'id'}
                  valueLabel={'name'}
                  onChange={setDirectPool}
                  onFetch={getPools} />
              </Div>
              {organization &&
                <Div>
                  <Button icon="plus" title={TXT('action.append')} onClick={togglePool} />
                  <Button icon="edit" title={TXT('action.change')} onClick={handlePoolSelect} />
                  <Button icon="copy" title={TXT('action.copy')} onClick={toggleCopy} />
                  <Button icon="info" title={TXT('label.information')} onClick={toggleInfo} />
                </Div>
              }
            </Div>
          </Div>
          <Div>
            <Div layout={'grid 12 3@lg vertical-end'}>
              {directPool &&
                <Div>
                  <ButtonGroup label={' '}>
                    <ToggleBox primary={true}
                      label={TXT('label.resources')}
                      name={'resources'}
                      value={showResources}
                      onChange={setShowResources} />

                    <ToggleBox primary={true}
                      label={TXT('label.services')}
                      name={'services'}
                      value={showServices}
                      onChange={setShowServices} />
                  </ButtonGroup>
                </Div>
              }
              {directPool && directPool.organization.type === OrganizationType.Agency &&
                <Div>
                  <ButtonGroup label={' '}>
                    <ToggleBox primary={true}
                      label={TXT('label.directRates')}
                      name={'direct'}
                      value={showDirectRates}
                      onChange={setShowDirectRates} />

                    <ToggleBox primary={true}
                      label={TXT('label.parentRates')}
                      name={'parent'}
                      value={showParentRates}
                      onChange={setShowParentRates} />

                    <Button icon="copy" look={'flat'} primary={true} title={TXT('action.copy')} onClick={toggleTransfer} />
                  </ButtonGroup>
                </Div>
              }
            </Div>
          </Div>
        </React.Fragment>
      }
      <Div>
        <Switch>
          <Route exact={true}
            path={`${match.path}`}
            render={props => showServices
              ? <RateListPage {...props} pools={selectedPools} refresh={!transferToggled} filter={filter}
                onlyShared={false}
                showServices={showServices}
                onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} onFilter={setFilter} />
              : <SpanListPage {...props} pools={selectedPools} refresh={!transferToggled} filter={filter}
                onlyShared={false}
                showServices={showServices}
                onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} onFilter={setFilter} />
            } />
          {directPool &&
            <Route exact={true}
              path={`${match.path}/:id`}
              render={props => showServices
                ? <RateItemPage {...props} pool={directPool}
                  onlyShared={false}
                  showServices={showServices}
                  onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />
                : <SpanItemPage {...props} pool={directPool}
                  onlyShared={false}
                  showServices={showServices}
                  onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />
              } />
          }
        </Switch>
      </Div>
      {organization && belongsTo && poolToggled &&
        <Drawer>
          <Div layout="grid 12">
            <Div>
              <Div layout="flex">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" icon="close" onClick={togglePool} />
                </Div>
              </Div>
            </Div>
            <Div>
              <PoolItem
                item={selectedPool
                  ? selectedPool
                  : {
                    ...new Pool<OrganizationReference, CurrencyReference>(OrganizationReference, CurrencyReference),
                    organization: profile.isAgent ? belongsTo : organization,
                  }
                }
                label={selectedPool ? TXT('page.pools.edit') : TXT('page.pools.new')}
                readonly={false}
                error={submitError}
                onSubmit={handlePoolSubmit}
                onCancel={handlePoolCancel}
                onDelete={selectedPool ? handlePoolDelete : undefined}
                provider={!profile.isSys ? organization : undefined}
              />
            </Div>
          </Div>
        </Drawer>
      }
      {directPool && copyToggled && organization &&
        <Drawer>
          <Div layout="grid 12">
            <Div>
              <Div layout="flex vertical-center">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" icon="close" onClick={toggleCopy} />
                </Div>
              </Div>
            </Div>
            <Div>
              <PoolCopy
                item={directPool}
                label={TXT('page.pools.copy')}
                readonly={false}
                onSubmit={handleCopySubmit}
                onCancel={handleCopyCancel}
                provider={!profile.isSys ? organization : undefined}
              />
            </Div>
          </Div>
        </Drawer>
      }
      {directPool && infoToggled &&
        <Drawer>
          <Div layout="grid 12">
            <Div>
              <Div layout="flex vertical-center">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" icon="close" onClick={toggleInfo} />
                </Div>
              </Div>
            </Div>
            <Div>
              <OrganizationsView pool={directPool} history={history} location={location} match={match} />
            </Div>
          </Div>
        </Drawer>
      }
      {directPool && parentPools && transferToggled &&
        <Drawer>
          <Div layout="grid 12">
            <Div>
              <Div layout="flex vertical-center">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" icon="close" onClick={toggleTransfer} />
                </Div>
              </Div>
            </Div>
          <Div>
            <Form loaded={true} labelSubmit={ID('action.copy')}
              readonly={false}
              onSubmit={handleTransfer}
              onCancel={toggleTransfer}>
              <p><FormattedMessage id={'message.transferRates'} /></p>
              </Form>
            </Div>
          </Div>
        </Drawer>
}
    </Div>
  )
}

interface RateItemPageProps extends RouteComponentProps<{ id: string }> {
  pool: ResolvedPool;
  onlyShared: boolean;
  showServices: boolean;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function RateItemPage(props: RateItemPageProps): ReactElement {
  const { onlyShared, showServices, location, match, pool, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const rateId = match.params.id || ''
  const isNew = rateId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [lock, setLock] = useState(new Lock())
  const [rate, setRate] = useState(new ResolvedRate())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const rate = await RateAccess.getOne(rateId)
            setRate(rate)
            const lock = await LockAccess.lock(rateId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            setRate(prev => ({
              ...prev,
              pool,
            }))
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, isNew, readonly, pool, rateId, setLoaded, setLoading, setLock, setRate, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([rate])
    }
  }, [loaded, rate, onLoaded])

  const handleNavigate = useCallback(async (rateId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (rateId && rateId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(rateId, LockAction.free)
        }
        onNavigate(`${rateId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: RateCreateModel): Promise<void> => {
    try {
      if (rateId !== 'new') {
        await RateAccess.update(rateId, item)
        await handleNavigate(rateId, false, true)
      } else {
        const created = await RateAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [rateId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, false, true)
  }, [rateId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await RateAccess.delete(rateId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [rateId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, true, false)
  }, [rateId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, false, true)
  }, [rateId, handleNavigate])

  return (
    <Loader loading={loading}>
      <RateItem item={rate}
        lock={lock}
        readonly={readonly}
        showResources={!onlyShared}
        showServices={showServices || rate.service !== null}
        showKinds={true}
        showTypes={true}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDelete={!isNew ? handleDelete : undefined}
        onEdit={pool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase() || profile.isSys ? handleEdit : undefined}
        onFree={pool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase() || profile.isSys ? handleFree : undefined} />
    </Loader>
  )
}

interface RateListPageProps extends RouteComponentProps {
  pools: ResolvedPool[];
  refresh: boolean;
  filter: RateFilter;
  onlyShared: boolean;
  showServices: boolean;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
  onFilter: (filter: RateFilter) => void;
}

function RateListPage(props: RateListPageProps): ReactElement {
  const { pools, refresh, filter, onlyShared, showServices, onLoaded, onNavigate, onError, onFilter } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const showResources = !showServices
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Chunk<ResolvedRate>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number, filter?: RateFilter): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    if (filter !== undefined) onFilter(filter)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake, onFilter])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = pools.length > 0 ? await RateAccess.getAll(pattern, sort, skip, take, {
            byAirportId: filter.byAirportId,
            byPoolId: pools.map((pool) => pool.id),
            isShared: onlyShared ? true : undefined,
            isPrimary: showResources,
          }) : new Chunk<ResolvedRate>()
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, profile.belongsTo, pools, pattern, sort, skip, take, filter, onlyShared, showResources, setData, setLoaded, setLoading, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [loaded, onLoaded])

  useEffect(() => {
    reload()
  }, [pools, refresh, showResources, reload])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`)
  }, [onNavigate])

  const handleItemSelect = useCallback((record: Rate): void => {
    onNavigate(`${record.id}`)
  }, [onNavigate])

  const handleItemFilter = useCallback((newFilter: RateFilter): void => {
    if (!R.equals(filter, newFilter)) {
      reload(undefined, undefined, undefined, undefined, newFilter)
    }
  }, [filter, reload])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])


  const firstPool = useMemo(() => pools[0], [pools])

  return (
    <Loader loading={!loaded && loading}>
      <RateGrid data={data} pools={pools} filter={filter}
        showCategories={false}
        showKinds={false}
        showTypes={false}
        showTimes={false}
        onItemCreate={(firstPool && firstPool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase()) || profile.isSys ? handleItemCreate : undefined}
        onItemFilter={handleItemFilter}
        onItemSelect={handleItemSelect}
        onItemSearch={handleItemSearch}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange} />
    </Loader>
  )
}

interface SpanItemPageProps extends RouteComponentProps<{ id: string }> {
  pool: ResolvedPool;
  onlyShared: boolean;
  showServices: boolean;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function SpanItemPage(props: SpanItemPageProps): ReactElement {
  const { onlyShared, location, match, pool, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const rateId = match.params.id || ''
  const isNew = rateId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [lock, setLock] = useState(new Lock())
  const [rate, setRate] = useState(new RateSpan())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const rate = await SpanAccess.getOne(rateId)
            setRate(rate)
            const lock = await LockAccess.lock(rateId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            setRate(prev => ({
              ...prev,
              rates: [
                {
                  ...new Rate(PoolReference, CurrencyReference),
                  pool,
                }
              ],
            }))
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, isNew, readonly, pool, rateId, setLoaded, setLoading, setLock, setRate, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([rate])
    }
  }, [loaded, rate, onLoaded])

  const handleNavigate = useCallback(async (rateId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (rateId && rateId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(rateId, LockAction.free)
        }
        onNavigate(`${rateId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: RateSpanCreateModel): Promise<void> => {
    try {
      if (rateId !== 'new') {
        await SpanAccess.update(rateId, item)
        await handleNavigate(rateId, false, true)
      } else {
        const created = await SpanAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [rateId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, false, true)
  }, [rateId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await SpanAccess.delete(rateId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [rateId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, true, false)
  }, [rateId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(rateId, false, true)
  }, [rateId, handleNavigate])

  return (
    <Loader loading={loading}>
      <SpanItem item={rate}
        lock={lock}
        readonly={readonly}
        showResources={!onlyShared}
        showKinds={true}
        showTypes={true}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDelete={!isNew ? handleDelete : undefined}
        onEdit={pool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase() || profile.isSys ? handleEdit : undefined}
        onFree={pool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase() || profile.isSys ? handleFree : undefined} />
    </Loader>
  )
}

interface SpanListPageProps extends RouteComponentProps {
  pools: ResolvedPool[];
  refresh: boolean;
  filter: RateFilter;
  onlyShared: boolean;
  showServices: boolean;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
  onFilter: (filter: RateFilter) => void;
}

function SpanListPage(props: SpanListPageProps): ReactElement {
  const { pools, refresh, filter, onlyShared, showServices, onLoaded, onNavigate, onError, onFilter } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const showResources = !showServices
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Chunk<RateSpan>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number, filter?: RateFilter): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    if (filter !== undefined) onFilter(filter)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake, onFilter])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = pools.length > 0 ? await SpanAccess.getAll(pattern, sort, skip, take, {
            ...filter,
            byPoolId: pools.map((pool) => pool.id),
            isShared: onlyShared ? true : undefined,
            isPrimary: showResources,
          }) : new Chunk<RateSpan>()
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, profile.belongsTo, pools, pattern, sort, skip, take, filter, onlyShared, showResources, setData, setLoaded, setLoading, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [loaded, onLoaded])

  useEffect(() => {
    reload()
  }, [pools, refresh, showResources, reload])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`)
  }, [onNavigate])

  const handleItemSelect = useCallback((record: RateSpan): void => {
    onNavigate(`${record.id}`)
  }, [onNavigate])

  const handleItemFilter = useCallback((newFilter: RateSpanFilter): void => {
    if (!R.equals(filter, newFilter)) {
      reload(undefined, undefined, undefined, undefined, newFilter)
    }
  }, [filter, reload])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])


  const firstPool = useMemo(() => pools[0], [pools])

  return (
    <Loader loading={!loaded && loading}>
      <SpanGrid data={data} pools={pools} filter={filter}
        showCategories={true}
        showKinds={true}
        showTypes={true}
        onItemCreate={(firstPool && firstPool.organization.code.toUpperCase() === profile.belongsTo.toUpperCase()) || profile.isSys ? handleItemCreate : undefined}
        onItemFilter={handleItemFilter}
        onItemSelect={handleItemSelect}
        onItemSearch={handleItemSearch}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange} />
    </Loader>
  )
}

function pathBuilder(items: Rate[]): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: TXT('page.rates'),
  })
  for (const item of items) {
    pathItems.push({
      path: item.id !== EMPTY_ID ? `${item.id}` : `new`,
      text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : ID('page.rates.new'),
    })
  }
  return pathItems
}

export const Rates = withPage(RatesPage, pathBuilder)
Rates.displayName = 'RatesPage'
