import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Pair } from '../../data'
import { TransferPointType } from '../data/models'
import { PairConverter, PairData } from './types'

export function useTransfersPointTypes(): [ PairData<TransferPointType>, PairConverter<TransferPointType> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<TransferPointType>>([
    new Pair(TransferPointType.Common, intl.formatMessage({ id: 'enum.transferPointType.common' })),
    new Pair(TransferPointType.Lounge, intl.formatMessage({ id: 'enum.transferPointType.lounge' })),
  ])

  const converter = useCallback((value: TransferPointType): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}
