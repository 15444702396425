import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chunk, Pair } from '../../data'
import { ServiceBaseV1, ServiceCategoryV1, ServiceFieldTypeV1, ServiceKindV1, ServiceModeV1, ServiceTypeV1, ServiceUnitV1, ServiceV1 } from '../../data/models'
import { ServiceAccess, ServiceAccessV1 } from '../data/access'
import { Service, ServiceFieldType, ServiceKind, ServiceMode, ServiceType, ServiceUnit } from '../data/models'
import { FetchList, PairConverter, PairData } from './types'

export function useService(serviceId: string | null): [ Service | null, Dispatch<SetStateAction<Service | null>> ] {
  const [ item, setItem ] = useState<Service | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (serviceId) {
          setItem(await ServiceAccess.getOne(serviceId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ serviceId, setItem ]);

  return [ item, setItem ];
}

export function usePrimaryServiceKinds(): [ PairData<ServiceKind>, PairConverter<ServiceKind> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceKind>>([
    new Pair(ServiceKind.VipLounge, intl.formatMessage({ id: "enum.serviceKind.vipLounge" })),
    new Pair(ServiceKind.BusinessLounge, intl.formatMessage({ id: "enum.serviceKind.businessLounge" })),
    new Pair(ServiceKind.ComfortLounge, intl.formatMessage({ id: "enum.serviceKind.comfortLounge" })),
    new Pair(ServiceKind.OfficialPersonsAndDelegationsLounge, intl.formatMessage({ id: "enum.serviceKind.delegationLounge" })),
    new Pair(ServiceKind.VirtualLounge, intl.formatMessage({ id: "enum.serviceKind.virtualLounge" })),
    new Pair(ServiceKind.FastTrack, intl.formatMessage({ id: "enum.serviceKind.fastTrackService" })),
    new Pair(ServiceKind.MeetAndAssist, intl.formatMessage({ id: "enum.serviceKind.meetAndAssistService" })),
    new Pair(ServiceKind.MeetAndGreet, intl.formatMessage({ id: "enum.serviceKind.meetAndGreetService" })),
    new Pair(ServiceKind.Parking, intl.formatMessage({ id: "enum.serviceKind.parkingService" })),
    new Pair(ServiceKind.Transfer, intl.formatMessage({ id: "enum.serviceKind.transferService" })),
    new Pair(ServiceKind.Transit, intl.formatMessage({ id: "enum.serviceKind.transitService" })),
    new Pair(ServiceKind.Info, intl.formatMessage({ id: "enum.serviceKind.infoService" })),
    new Pair(ServiceKind.Meal, intl.formatMessage({ id: "enum.serviceKind.mealService" })),
  ]);

  const converter = useCallback((value: ServiceKind): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data.filter((item) => item.code !== ServiceKind.Transfer && item.code !== ServiceKind.Meal ), converter ];
}

export function useSecondaryServiceKinds(): [ PairData<ServiceKind>, PairConverter<ServiceKind> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceKind>>([
    new Pair(ServiceKind.LoungeTime, intl.formatMessage({ id: "enum.serviceKind.loungeTime" })),
    new Pair(ServiceKind.Urgency, intl.formatMessage({ id: "enum.serviceKind.urgency" })),
    new Pair(ServiceKind.ConferenceRoom, intl.formatMessage({ id: "enum.serviceKind.conferenceRoom" })),
    new Pair(ServiceKind.Apartments, intl.formatMessage({ id: "enum.serviceKind.apartments" })),
    new Pair(ServiceKind.Luggage, intl.formatMessage({ id: "enum.serviceKind.luggage" })),
    new Pair(ServiceKind.Photography, intl.formatMessage({ id: "enum.serviceKind.photography" })),
    new Pair(ServiceKind.Other, intl.formatMessage({ id: "enum.serviceKind.other" })),
  ]);

  const converter = useCallback((value: ServiceKind): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useServiceTypes(): [ PairData<ServiceType>, PairConverter<ServiceType> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceType>>([
    new Pair(ServiceType.Any, intl.formatMessage({ id: "enum.serviceType.all" })),
    new Pair(ServiceType.Arrival, intl.formatMessage({ id: "enum.serviceType.arrival" })),
    new Pair(ServiceType.Departure, intl.formatMessage({ id: "enum.serviceType.departure" })),
    new Pair(ServiceType.ArrivalAndDeparture, intl.formatMessage({ id: "enum.serviceType.arrivalAndDeparture" })),
    new Pair(ServiceType.DepartureAndArrival, intl.formatMessage({ id: "enum.serviceType.departureAndArrival" })),
    new Pair(ServiceType.Transit, intl.formatMessage({ id: "enum.serviceType.transit" })),
  ]);

  const converter = useCallback((value: ServiceType): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useServiceModes(): [ PairData<ServiceMode>, PairConverter<ServiceMode> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceMode>>([
    new Pair(ServiceMode.Automatic, intl.formatMessage({ id: "enum.confirmationMode.automatic" })),
    new Pair(ServiceMode.Manual, intl.formatMessage({ id: "enum.confirmationMode.manual" })),
    new Pair(ServiceMode.SemiAutomatic, intl.formatMessage({ id: "enum.confirmationMode.semiAutomatic" })),
  ]);

  const converter = useCallback((value: ServiceMode): string => {
    switch (value) {
      case ServiceMode.Automatic:
        return intl.formatMessage({ id: "enum.confirmationMode.automatic" });
      case ServiceMode.Manual:
        return intl.formatMessage({ id: "enum.confirmationMode.manual" });
      case ServiceMode.SemiAutomatic:
        return intl.formatMessage({ id: "enum.confirmationMode.semiAutomatic" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useServiceUnits(): [ PairData<ServiceUnit>, PairConverter<ServiceUnit> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceUnit>>([
    new Pair(ServiceUnit.Whole, intl.formatMessage({ id: "enum.serviceUnit.whole" })),
    new Pair(ServiceUnit.Hour, intl.formatMessage({ id: "enum.serviceUnit.hour" })),
    new Pair(ServiceUnit.Item, intl.formatMessage({ id: "enum.serviceUnit.item" })),
    new Pair(ServiceUnit.ItemPerHour, intl.formatMessage({ id: "enum.serviceUnit.itemPerHour" })),
  ]);

  const converter = useCallback((value: ServiceUnit): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useServiceFieldTypes(): [ PairData<ServiceFieldType>, PairConverter<ServiceFieldType> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceFieldType>>([
    new Pair(ServiceFieldType.Text, intl.formatMessage({ id: "enum.serviceFieldType.text" })),
    new Pair(ServiceFieldType.Date, intl.formatMessage({ id: "enum.serviceFieldType.date" })),
    new Pair(ServiceFieldType.Time, intl.formatMessage({ id: "enum.serviceFieldType.time" })),
  ]);

  const converter = useCallback((value: ServiceFieldType): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useServiceV1(id: number | null): ServiceV1 | null {
  const [ item, setItem ] = useState<ServiceV1 | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await ServiceAccessV1.fetchItem(id));
        } else {
          setItem(null);
        }
      } catch {
        setItem(null);
      }
    })();
  }, [ id, setItem ]);

  return item;
}

export function useServicesV1(pageSize?: number, prefetch?: boolean, resourceId?: number, includeDefault?: boolean, includeTransfers?: boolean): [ Chunk<ServiceBaseV1>, FetchList ] {
  const [ data, setData ] = useState(new Chunk<ServiceBaseV1>());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await ServiceAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize, resourceId, includeDefault, includeTransfers);
      setData(data);
    } catch {
      setData(new Chunk());
    }
  }, [ pageSize, resourceId, includeDefault, includeTransfers, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useServiceModesV1(): [ PairData<ServiceModeV1>, PairConverter<ServiceModeV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceModeV1>>([
    new Pair(ServiceModeV1.Automatic, intl.formatMessage({ id: "enum.confirmationMode.automatic" })),
    new Pair(ServiceModeV1.Manual, intl.formatMessage({ id: "enum.confirmationMode.manual" })),
    new Pair(ServiceModeV1.SemiAutomatic, intl.formatMessage({ id: "enum.confirmationMode.semiAutomatic" })),
  ]);

  const converter = useCallback((value: ServiceModeV1): string => {
    switch (value) {
      case ServiceModeV1.Automatic:
        return intl.formatMessage({ id: "enum.confirmationMode.automatic" });
      case ServiceModeV1.Manual:
        return intl.formatMessage({ id: "enum.confirmationMode.manual" });
      case ServiceModeV1.SemiAutomatic:
        return intl.formatMessage({ id: "enum.confirmationMode.semiAutomatic" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useServiceCategoriesV1(): [ PairData<ServiceCategoryV1>, PairConverter<ServiceCategoryV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceCategoryV1>>([
    new Pair(ServiceCategoryV1.LoungeTime, intl.formatMessage({ id: "enum.serviceCategory.loungeTime" })),
    new Pair(ServiceCategoryV1.Urgency, intl.formatMessage({ id: "enum.serviceCategory.urgency" })),
    new Pair(ServiceCategoryV1.Apartments, intl.formatMessage({ id: "enum.serviceCategory.apartments" })),
    new Pair(ServiceCategoryV1.ConferenceRoom, intl.formatMessage({ id: "enum.serviceCategory.conferenceRoom" })),
    new Pair(ServiceCategoryV1.Luggage, intl.formatMessage({ id: "enum.serviceCategory.luggage" })),
    new Pair(ServiceCategoryV1.Photography, intl.formatMessage({ id: "enum.serviceCategory.photography" })),
    new Pair(ServiceCategoryV1.Other, intl.formatMessage({ id: "enum.serviceCategory.other" })),
  ]);

  const converter = useCallback((value: ServiceCategoryV1): string => {
    switch (value) {
      case ServiceCategoryV1.Other:
        return intl.formatMessage({ id: "enum.serviceCategory.other" });
      case ServiceCategoryV1.Apartments:
        return intl.formatMessage({ id: "enum.serviceCategory.apartments" });
      case ServiceCategoryV1.ConferenceRoom:
        return intl.formatMessage({ id: "enum.serviceCategory.conferenceRoom" });
      case ServiceCategoryV1.Luggage:
        return intl.formatMessage({ id: "enum.serviceCategory.luggage" });
      case ServiceCategoryV1.Photography:
        return intl.formatMessage({ id: "enum.serviceCategory.photography" });
      case ServiceCategoryV1.LoungeTime:
        return intl.formatMessage({ id: "enum.serviceCategory.loungeTime" });
      case ServiceCategoryV1.Urgency:
        return intl.formatMessage({ id: "enum.serviceCategory.urgency" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useServiceKindsV1(): [ PairData<ServiceKindV1>, PairConverter<ServiceKindV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceKindV1>>([
    new Pair(ServiceKindV1.VipLounge, intl.formatMessage({ id: "enum.serviceKind.vipLounge" })),
    new Pair(ServiceKindV1.BusinessLounge, intl.formatMessage({ id: "enum.serviceKind.businessLounge" })),
    new Pair(ServiceKindV1.ComfortLounge, intl.formatMessage({ id: "enum.serviceKind.comfortLounge" })),
    new Pair(ServiceKindV1.OfficialPersonsAndDelegationsLounge, intl.formatMessage({ id: "enum.serviceKind.delegationLounge" })),
    new Pair(ServiceKindV1.FastTrackService, intl.formatMessage({ id: "enum.serviceKind.fastTrackService" })),
    new Pair(ServiceKindV1.MeetAndAssistService, intl.formatMessage({ id: "enum.serviceKind.meetAndAssistService" })),
    new Pair(ServiceKindV1.MeetAndGreetService, intl.formatMessage({ id: "enum.serviceKind.meetAndGreetService" })),
    new Pair(ServiceKindV1.ParkingService, intl.formatMessage({ id: "enum.serviceKind.parkingService" })),
    new Pair(ServiceKindV1.TransferService, intl.formatMessage({ id: "enum.serviceKind.transferService" })),
    new Pair(ServiceKindV1.TransitService, intl.formatMessage({ id: "enum.serviceKind.transitService" })),
    new Pair(ServiceKindV1.Info, intl.formatMessage({ id: "enum.serviceKind.infoService" })),
  ]);

  const converter = useCallback((value: ServiceKindV1): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useServiceTypesV1(): [ PairData<ServiceTypeV1>, PairConverter<ServiceTypeV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceTypeV1>>([
    new Pair(ServiceTypeV1.All, intl.formatMessage({ id: "enum.serviceType.all" })),
    new Pair(ServiceTypeV1.Arrival, intl.formatMessage({ id: "enum.serviceType.arrival" })),
    new Pair(ServiceTypeV1.Departure, intl.formatMessage({ id: "enum.serviceType.departure" })),
    new Pair(ServiceTypeV1.ArrivalAndDeparture, intl.formatMessage({ id: "enum.serviceType.arrivalAndDeparture" })),
    new Pair(ServiceTypeV1.DepartureAndArrival, intl.formatMessage({ id: "enum.serviceType.departureAndArrival" })),
  ]);

  const converter = useCallback((value: ServiceTypeV1): string => {
    switch (value) {
      case ServiceTypeV1.All:
        return intl.formatMessage({ id: "enum.serviceType.all" });
      case ServiceTypeV1.Arrival:
        return intl.formatMessage({ id: "enum.serviceType.arrival" });
      case ServiceTypeV1.Departure:
        return intl.formatMessage({ id: "enum.serviceType.departure" });
      case ServiceTypeV1.ArrivalAndDeparture:
        return intl.formatMessage({ id: "enum.serviceType.arrivalAndDeparture" });
      case ServiceTypeV1.DepartureAndArrival:
        return intl.formatMessage({ id: "enum.serviceType.departureAndArrival" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useServiceUnitsV1(): [ PairData<ServiceUnitV1>, PairConverter<ServiceUnitV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceUnitV1>>([
    new Pair(ServiceUnitV1.Whole, intl.formatMessage({ id: "enum.serviceUnit.whole" })),
    new Pair(ServiceUnitV1.Hour, intl.formatMessage({ id: "enum.serviceUnit.hour" })),
    new Pair(ServiceUnitV1.Item, intl.formatMessage({ id: "enum.serviceUnit.item" })),
    new Pair(ServiceUnitV1.ItemPerHour, intl.formatMessage({ id: "enum.serviceUnit.itemPerHour" })),
  ]);

  const converter = useCallback((value: ServiceUnitV1): string => {
    switch (value) {
      case ServiceUnitV1.Whole:
        return intl.formatMessage({ id: "enum.serviceUnit.whole" });
      case ServiceUnitV1.Hour:
        return intl.formatMessage({ id: "enum.serviceUnit.hour" });
      case ServiceUnitV1.Item:
        return intl.formatMessage({ id: "enum.serviceUnit.item" });
      case ServiceUnitV1.ItemPerHour:
        return intl.formatMessage({ id: "enum.serviceUnit.itemPerHour" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useServiceFieldTypesV1(): [ PairData<ServiceFieldTypeV1>, PairConverter<ServiceFieldTypeV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ServiceFieldTypeV1>>([
    new Pair(ServiceFieldTypeV1.Text, intl.formatMessage({ id: "enum.serviceFieldType.text" })),
    new Pair(ServiceFieldTypeV1.Date, intl.formatMessage({ id: "enum.serviceFieldType.date" })),
    new Pair(ServiceFieldTypeV1.Time, intl.formatMessage({ id: "enum.serviceFieldType.time" })),
  ]);

  const converter = useCallback((value: ServiceFieldTypeV1): string => {
    switch (value) {
      case ServiceFieldTypeV1.Text:
        return intl.formatMessage({ id: "enum.serviceFieldType.text" });
      case ServiceFieldTypeV1.Date:
        return intl.formatMessage({ id: "enum.serviceFieldType.date" });
      case ServiceFieldTypeV1.Time:
        return intl.formatMessage({ id: "enum.serviceFieldType.time" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}
