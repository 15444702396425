import * as KendoUpload from "@progress/kendo-react-upload"
import React, { ReactElement, useCallback, useState } from "react";
import { UploaderResponse } from "../../gears";

export interface UploaderProps {
  uploadUrl?: string;
  single?: boolean;
  allowedFileExtensions?: string[];
  allowedMaxFileSize?: number;
  allowedMinFileSize?: number;
  showFileList?: boolean;
  onUploaded?: (files: { id: string; uri: string; name: string; }[]) => void;
}

export function Uploader(props: UploaderProps): ReactElement {
  const { uploadUrl, single, allowedFileExtensions, allowedMaxFileSize, allowedMinFileSize, showFileList, onUploaded } = props;

  const multiple = single === undefined || !single;

  const [ files, setFiles ] = useState<KendoUpload.UploadFileInfo[]>([]);

  const handleAdd = useCallback((event: KendoUpload.UploadOnAddEvent): void => {
    setFiles(event.newState);
  }, [ setFiles ]);

  const handleProgress = useCallback((event: KendoUpload.UploadOnProgressEvent): void => {
    setFiles(event.newState);
  }, [ setFiles ]);

  const handleStatusChange = useCallback((event: KendoUpload.UploadOnStatusChangeEvent): void => {
    setFiles(event.newState);
    if (onUploaded && event.response && event.response.response && event.response.response.files) {
      onUploaded((event.response as UploaderResponse).response.files.map(file => file));
    }
  }, [ setFiles, onUploaded ]);

  return (
    <KendoUpload.Upload autoUpload={true}
                        batch={false}
                        multiple={multiple}
                        showFileList={showFileList}
                        showActionButtons={false}
                        disabled={!uploadUrl}
                        files={files}
                        saveUrl={uploadUrl}
                        restrictions={{
                          allowedExtensions: allowedFileExtensions,
                          maxFileSize: allowedMaxFileSize,
                          minFileSize: allowedMinFileSize,
                        }}
                        withCredentials={false}
                        onAdd={handleAdd}
                        onProgress={handleProgress}
                        onStatusChange={handleStatusChange} />
  );
}
