import React, { ReactElement, useCallback } from "react";
import { DateBox, TextBox } from "../../gears/inputs";
import { email, length, phone, required } from "../../gears/validators";
import { Passenger } from "../data/models";
import { Div, TXT } from "../gears";

export interface PassengerViewProps {
  name: string;
  item: Passenger;
  index: number;
  showName?: boolean;
  showPhone?: boolean;
  showEmail?: boolean;
  showTicket?: boolean;
  showBirthDate?: boolean;
  requireName?: boolean;
  requirePhone?: boolean;
  requireEmail?: boolean;
  requireTicket?: boolean;
  requireBirthDate?: boolean;
  onChange?: (item: Passenger, index: number) => void;
}

export function PassengerView(props: PassengerViewProps): ReactElement {
  const { name, item, index, showName, showPhone, showEmail, showTicket, showBirthDate, requireName, requirePhone, requireEmail, requireTicket, requireBirthDate, onChange } = props;

  const handleChange = useCallback((item: Passenger): void => {
    if (onChange) {
      onChange(item, index);
    }
  }, [ index, onChange ]);

  const handleFamilyNameChange = useCallback((value: string | null): void => {
    handleChange({ ...item, familyName: value || "" });
  }, [ item, handleChange ]);

  const handleGivenNameChange = useCallback((value: string | null): void => {
    handleChange({ ...item, givenName: value || "" });
  }, [ item, handleChange ]);

  const handleMiddleNameChange = useCallback((value: string | null): void => {
    handleChange({ ...item, middleName: value });
  }, [ item, handleChange ]);

  const handlePhoneChange = useCallback((value: string | null): void => {
    handleChange({ ...item, phone: value || "" });
  }, [ item, handleChange ]);

  const handleEmailChange = useCallback((value: string | null): void => {
    handleChange({ ...item, email: value || "" });
  }, [ item, handleChange ]);

  const handleTicketChange = useCallback((value: string | null): void => {
    handleChange({ ...item, ticket: value });
  }, [ item, handleChange ]);

  const handleBirthDateChange = useCallback((value: string | null): void => {
    handleChange({ ...item, dateOfBirth: value || "" });
  }, [ item, handleChange ]);

  return (
    <Div layout="flex">
      {showName &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.familyName")}
                 name={`${name}.familyName`}
                 fill={true}
                 value={item.familyName}
                 validators={[ ...(requireName ? [ required ] : []), value => length(value, 1, 100) ]}
                 onChange={handleFamilyNameChange} />
      </Div>
      }
      {showName &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.givenName")}
                 name={`${name}.givenName`}
                 fill={true}
                 value={item.givenName}
                 validators={[ ...(requireName ? [ required ] : []), value => length(value, 1, 100) ]}
                 onChange={handleGivenNameChange} />
      </Div>
      }
      {showName &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.middleName")}
                 name={`${name}.middleName`}
                 fill={true}
                 value={item.middleName}
                 validators={[ value => length(value, 1, 100) ]}
                 onChange={handleMiddleNameChange} />
      </Div>
      }
      {showEmail &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.email")}
                 name={`${name}.email`}
                 fill={true}
                 value={item.email}
                 validators={[ ...(requireEmail ? [ required ]: []), email, value => length(value, 1, 100) ]}
                 onChange={handleEmailChange} />
      </Div>
      }
      {showPhone &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.phone")}
                 name={`${name}.phone`}
                 fill={true}
                 mask="(999) 000-00-00"
                 value={item.phone}
                 validators={[ ...(requirePhone ? [ required ]: []), phone, value => length(value, 1, 100) ]}
                 onChange={handlePhoneChange} />
      </Div>
      }
      {showTicket &&
      <Div layout="fill">
        <TextBox placeholder={TXT("label.ticket")}
                 name={`${name}.ticket`}
                 fill={true}
                 value={item.ticket}
                 validators={[ ...(requireTicket ? [ required ] : []), value => length(value, 1, 100) ]}
                 onChange={handleTicketChange} />
      </Div>
      }
      {showBirthDate &&
      <Div layout="fill">
        <DateBox name={`${name}.birthDate`}
                 fill={true}
                 max={new Date().toISOString()}
                 value={item.dateOfBirth}
                 validators={[ ...(requireBirthDate ? [ required ] : []) ]}
                 onChange={handleBirthDateChange} />
      </Div>
      }
    </Div>
  );
}
