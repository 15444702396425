import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Pair } from '../../data'
import { Collection, Confirm, FieldSet, Uploader } from '../../gears'
import { ComboBox, TextArea, TextBox } from '../../gears/inputs'
import { length } from '../../gears/validators'
import { Media, MediaKind, ServiceType } from '../data/models'
import { Button, Div, Span, TXT } from '../gears'
import { useField, useFileUrl, useFormatMessage, useServiceTypes } from '../hooks'

interface MediaItemProps {
  item: Media;
  onChange?: (item: Media) => void;
  onRemove?: (item: Media) => void;
}

function MediaItem(props: MediaItemProps): ReactElement {
  const { item, onChange, onRemove } = props;

  const formatMessage = useFormatMessage();
  const [, formatUrl] = useFileUrl();
  const [confirmToggled, setConfirmToggled] = useState(false);

  const [types] = useServiceTypes()
  const [type, setType] = useField<Pair<ServiceType> | null>(types.filter(pair => pair.code === item.type)[0] || null)

  const handleNameChange = useCallback((value: string | null): void => {
    if (onChange) {
      onChange({ ...item, name: value || "" });
    }
  }, [item, onChange]);

  const handleDescriptionChange = useCallback((value: string | null): void => {
    if (onChange) {
      onChange({ ...item, description: value });
    }
  }, [item, onChange]);

  const handleLanguageChange = useCallback((value: string | null): void => {
    if (onChange) {
      onChange({ ...item, language: value });
    }
  }, [item, onChange])

  const handleTypeChange = useCallback((value: Pair<ServiceType, string> | null): void => {
    if (onChange) {
      onChange({ ...item, type: value?.code ?? ServiceType.Any });
    }
  }, [item, onChange])

  const handleRemove = useCallback((confirmed: boolean): void => {
    if (onRemove && confirmed) {
      onRemove(item);
    }
    setConfirmToggled(false);
  }, [item, setConfirmToggled, onRemove]);

  const toggleConfirm = useCallback((): void => {
    setConfirmToggled(prev => !prev);
  }, [setConfirmToggled]);

  return (
    <Div layout={"grid 12 6@lg"} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
      {item.kind !== MediaKind.Document &&
        <Div>
          <img style={{ maxWidth: "100%", display: "block", margin: "auto" }} alt={item.name} src={`${formatUrl(item.fileId)}/thumbnail`} />
        </Div>
      }
      {item.kind === MediaKind.Document &&
        <Div>
          <Div layout="grid 12">
            <Div>
              <FieldSet label={TXT('label.language')}>
                <ComboBox fill={true}
                  readonly={!onChange}
                  name="language"
                  data={["ru", "en"]}
                  value={item.language}
                  onChange={handleLanguageChange} />
              </FieldSet>
            </Div>
            <Div>
              <FieldSet label={TXT('label.serviceType')}>
                <ComboBox fill={true}
                  name="type"
                  data={types.filter(pair => ((pair.code === ServiceType.Any || pair.code === ServiceType.Arrival || pair.code === ServiceType.Departure)))}
                  value={type}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handleTypeChange} />
              </FieldSet>
            </Div>
            <Div>
              <a href={formatUrl(item.fileId)}>{item.name ?? 'Unknown'}</a>
            </Div>
          </Div>
        </Div>
      }
      <Div>
        <Div layout="grid 12">
          <Div>
            <FieldSet label={TXT("label.name")}>
              <TextBox fill={true}
                name="name"
                value={item.name}
                validators={[value => length(value, 0, 256)]}
                onChange={handleNameChange} />
            </FieldSet>
          </Div>
          {item.kind !== MediaKind.Document &&
            <Div>
              <FieldSet label={TXT("label.description")}>
                <TextArea fill={true}
                  name="description"
                  value={item.description}
                  onChange={handleDescriptionChange} />
              </FieldSet>
            </Div>
          }
          {onRemove &&
            <Div>
              <Div layout="flex">
                <Div layout="fill" />
                <Div layout="fit">
                  <Button look="bare" intent="danger" className="action" onClick={toggleConfirm}>
                    {formatMessage(TXT("action.delete"))}
                  </Button>
                </Div>
              </Div>
            </Div>
          }
        </Div>
        {confirmToggled &&
          <Confirm title={TXT("prompt.delete.title")}
            message={TXT("prompt.delete.message")}
            onConfirm={handleRemove} />
        }
      </Div>
    </Div>
  );
}

export interface MediaViewProps {
  data: Media[];
  kind: MediaKind;
  onChange?: (data: Media[]) => void;
}

export function MediaView(props: MediaViewProps): ReactElement {
  const { data, kind, onChange } = props;

  const formatMessage = useFormatMessage();
  const [fileUrl, formatUrl] = useFileUrl();

  const [item, setItem] = useState<Media | null>(null);
  const [uploaderToggled, setUploaderToggled] = useState(false);

  useEffect(() => {
    if (item === null && data.length > 0) {
      setItem(data[0]);
    }
  }, [item, data, setItem]);

  const handleAppend = useCallback((): void => {
    setUploaderToggled(true);
  }, [setUploaderToggled]);

  const handleChange = useCallback((data: Media[]): void => {
    if (onChange) {
      const newData: Media[] = [];
      let index = 0;
      for (const item of data) {
        newData.push({ ...item, index });
        index++;
      }
      onChange(newData);
    }
  }, [onChange]);

  const handleUpload = useCallback((files: { id: string; uri: string; name: string; }[]): void => {
    handleChange([...data, ...files.map(file => {
      const item = new Media();
      item.fileId = file.id;
      item.kind = kind;
      item.name = kind === MediaKind.Document ? file.name : '';
      return item;
    })]);
    setUploaderToggled(false);
  }, [data, kind, handleChange]);

  const handleItemChange = useCallback((item: Media): void => {
    const newData = [...data];
    newData.splice(item.index, 1, item);
    handleChange(newData);
    setItem(item);
  }, [data, handleChange, setItem]);

  const handleItemRemove = useCallback((item: Media): void => {
    const newData = [...data];
    newData.splice(item.index, 1);
    handleChange(newData);
    setItem(null);
  }, [data, handleChange, setItem]);

  const renderItem = useCallback((item: Media): ReactElement => {
    return kind !== MediaKind.Document
      ? <img style={{ height: "48px" }} alt={item.name} src={formatUrl(item.fileId)} />
      : <span className="k-icon k-i-file-pdf" style={{ fontSize: "48px" }} />;
  }, [formatUrl, kind]);

  const renderView = useCallback((): ReactElement => {
    return item
      ? <MediaItem item={item}
        onChange={onChange ? handleItemChange : undefined}
        onRemove={onChange ? handleItemRemove : undefined} />
      : <div />;
  }, [item, handleItemChange, handleItemRemove, onChange]);

  return (
    <Div layout="grid 12">
      <Div>
        <Collection data={data}
          item={item}
          itemKey="fileId"
          itemRender={renderItem}
          viewRender={renderView}
          onChange={onChange ? handleChange : undefined}
          onSelectItem={setItem} />
        {uploaderToggled &&
          <Uploader title={TXT("label.mediaUpload")}
            uploadUrl={fileUrl}
            allowedFileExtensions={kind !== MediaKind.Document ? [".png", ".jpg", ".jpeg"] : [".pdf"]}
            allowedMaxFileSize={52428800}
            onConfirm={handleUpload}>
            {kind !== MediaKind.Document ? <Span>{formatMessage(TXT("message.mediaImageDescription"))}</Span> : <Span>{formatMessage(TXT("message.mediaDocumentDescription"))}</Span>}
            
          </Uploader>
        }
      </Div>
      {onChange &&
        <Div>
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button look="bare" className="action" onClick={handleAppend}>
                {formatMessage(TXT("action.append"))}
              </Button>
            </Div>
          </Div>
        </Div>
      }
    </Div>
  );
}
