import { ConfirmedService } from "./ConfirmedService";

export class ConfirmedResource {
  public id: number;
  public services: ConfirmedService[];
  public confirmed: boolean;

  public constructor() {
    this.id = 0;
    this.services = [];
    this.confirmed = false;
  }
}
