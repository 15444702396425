import { DateTime } from "luxon";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Measure, { ContentRect } from "react-measure";
import { Icons } from "../../assets";
import { AuthContext } from "../../auth";
import { Pair } from "../../data";
import {
  Button as ButtonData,
  ButtonItem,
  Drawer,
  FieldSet,
  Form,
  FormContext,
  ID,
  IntlFormat,
  Tabs,
  Validator,
} from "../../gears";
import {
  ComboBox,
  DateBox,
  NumberBox,
  TagsBox,
  TextArea,
  TextBox,
  TimeBox,
  ToggleBox,
} from "../../gears/inputs";
import { email, flight, length, phone, required } from "../../gears/validators";
import {
  Airport,
  AirportTerminalName,
  CityBase,
  CurrencyReference,
  DiscountRateRule,
  EMPTY_ID,
  FlightDirection,
  GroupRateRule,
  isAssistance,
  isLounge,
  Order,
  OrderChangeModel,
  OrderCreateModel,
  OrderStatus,
  OrderType,
  OrderUpdateModel,
  OrganizationContract,
  OrganizationContractType,
  OrganizationProgramType,
  OrganizationReference,
  OrganizationType,
  Passenger,
  PassengerCategory,
  PassengerType,
  PoolReference,
  RateBase,
  RateCost,
  RatedFlight,
  RatedResource,
  RatedService,
  RateFine,
  Resource,
  SeatStatus,
  ServiceBase,
  ServiceField,
  ServiceFieldDescription,
  ServiceFieldType,
  ServiceKind,
  ServiceType,
  TransferPointType,
  UpdatedResource,
  UpdatedService,
  UploadedFile,
} from "../data/models";
import {
  Button,
  Div,
  Grid,
  GridColumn,
  IntlText,
  Span,
  TXT,
  Uploader,
} from "../gears";
import {
  useAirport,
  useAirports,
  useAirportTerminals,
  useCalcOrderStatus,
  useCities,
  useCity,
  useField,
  useFileUrl,
  useFlightDirections,
  useFlightTypes,
  useFormatCost,
  useFormatDate,
  useFormatMessage,
  useFormatPassenger,
  useFormatTime,
  useOrderPaymentTypes,
  useOrderTypes,
  useOrganization,
  useOrganizationContract,
  useOrganizationContractBalance,
  useOrganizationContractOverdraft,
  useOrganizationContractResources,
  useOrganizationContracts,
  useOrganizationDefaults,
  useOrganizationProgram,
  useOrganizationProgramCard,
  useOrganizationProgramCards,
  useOrganizationProgramSettings,
  useOrganizations,
  usePassengerCategories,
  usePrimaryServiceKinds,
  useRate,
  useResource,
  useResourceServices,
  useResourceSettings,
  useSeatStatuses,
  useService,
  useServiceTypes,
  useTransfersPointTypes,
  useWindowSize,
} from "../hooks";
import { firstOrNull, getEnterDateTime, getLeaverDateTime } from "../utils";
import { ItemViewProps } from "./types";

interface OrderedServiceItemProps {
  name: string;
  readonly: boolean;
  rated: boolean;
  visited: boolean;
  passengers: Passenger[];
  orderType: OrderType | null;
  calc: Order;
  item: RatedService;
  index: number;
  belongsTo?: Airport;
  onChange: (value: RatedService | null, index: number) => void;
}

function OrderedServiceItem(props: OrderedServiceItemProps): ReactElement {
  const {
    name,
    readonly,
    rated,
    visited,
    passengers,
    calc,
    item,
    index,
    belongsTo,
    onChange,
  } = props;

  const auth = useContext(AuthContext);
  const form = useContext(FormContext);
  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();
  const [, formatSeatStatus] = useSeatStatuses();
  const [transferPointTypes] = useTransfersPointTypes();

  const formattedPassengers = useMemo(
    () =>
      passengers
        .filter((passenger) => passenger.type !== PassengerType.Assistant)
        .map((passenger) => ({
          ...passenger,
          displayName: formatPassenger(passenger),
        })),
    [passengers, formatPassenger]
  );
  const formattedPassenger =
    formattedPassengers.filter(
      (passenger) => item.passenger && passenger.id === item.passenger.id
    )[0] || null;

  const [service] = useService(item.service.id);
  const fieldDescriptions = service ? service.fields : null;
  const cost = item.cost;
  const from = item.from;
  const till = item.till;
  const count = item.count;
  const hours = item.hours !== null ? item.hours : 1;
  const status = item.status;
  const canChange = !visited || auth.profile.isSys || auth.profile.isOwner;

  const [terminals, getTerminals] = useAirportTerminals(
    belongsTo ? belongsTo.id : null,
    !readonly
  );
  const destinationPoint = item.destinationPoint;
  const destinationPointType =
    item.destinationPointType !== null
      ? transferPointTypes.filter(
          (pair) => pair.code === item.destinationPointType
        )[0] || null
      : null;
  const departureTime = item.departureTime;

  const handleRemove = (): void => {
    form.onFieldRemove(name, false);
    onChange(null, index);
  };

  const handleFromChange = useCallback(
    (from: string | null): void => {
      onChange({ ...item, from }, index);
    },
    [item, index, onChange]
  );

  const handleTillChange = useCallback(
    (till: string | null): void => {
      onChange({ ...item, till }, index);
    },
    [item, index, onChange]
  );

  const handleCountChange = useCallback(
    (count: number | null): void => {
      onChange({ ...item, count }, index);
    },
    [item, index, onChange]
  );

  const handlePassengerChange = useCallback(
    (passenger: Passenger | null): void => {
      onChange({ ...item, passenger }, index);
    },
    [item, index, onChange]
  );

  const handleDestinationPointChange = useCallback(
    (terminal: AirportTerminalName | null): void => {
      onChange({ ...item, destinationPoint: terminal }, index);
    },
    [item, index, onChange]
  );

  const handleDestinationPointTypeChange = useCallback(
    (pair: Pair<TransferPointType> | null): void => {
      onChange(
        { ...item, destinationPointType: pair ? pair.code : null },
        index
      );
    },
    [item, index, onChange]
  );

  const handleDepartureTimeChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, departureTime: value }, index);
    },
    [item, index, onChange]
  );

  const handleFieldChange = useCallback(
    (value: string | null, fieldDescription: ServiceFieldDescription): void => {
      const changedFields = item.fields ? [...item.fields] : [];
      const changedFieldIndex = changedFields.findIndex(
        (x) => x.id === fieldDescription.id
      );
      if (changedFieldIndex === -1) {
        changedFields.push({
          ...new ServiceField(),
          id: fieldDescription.id,
          value: value || "",
        });
      } else {
        changedFields.splice(changedFieldIndex, 1, {
          ...changedFields[changedFieldIndex],
          value: value || "",
        });
      }

      const changedItem = { ...item, fields: changedFields };
      onChange(changedItem, index);
    },
    [item, index, onChange]
  );

  const renderField = useCallback(
    (fieldDescription: ServiceFieldDescription): ReactElement | null => {
      const field = item.fields
        ? item.fields.filter((x) => x.id === fieldDescription.id)[0] || null
        : null;
      const fieldId = fieldDescription.id;
      const fieldValue = field ? field.value : null;
      const fieldLabel = fieldDescription.name;
      const fieldName = `${name}-fields-${fieldId}`;
      const validators = fieldDescription.required ? [required] : undefined;
      switch (fieldDescription.type) {
        case ServiceFieldType.Text:
          return (
            <TextBox
              label={fieldLabel}
              disabled={!readonly && !canChange}
              fill={true}
              name={fieldName}
              value={fieldValue}
              validators={validators}
              onChange={(value) => handleFieldChange(value, fieldDescription)}
            />
          );
        case ServiceFieldType.Date:
          return (
            <DateBox
              label={fieldLabel}
              disabled={!readonly && !canChange}
              fill={true}
              zone={belongsTo ? belongsTo.zone : undefined}
              name={fieldName}
              value={fieldValue}
              validators={validators}
              onChange={(value) => handleFieldChange(value, fieldDescription)}
            />
          );
        case ServiceFieldType.Time:
          return (
            <TimeBox
              label={fieldLabel}
              disabled={!readonly && !canChange}
              fill={true}
              name={fieldName}
              value={fieldValue}
              validators={validators}
              onChange={(value) => handleFieldChange(value, fieldDescription)}
            />
          );
        default:
          return null;
      }
    },
    [belongsTo, item.fields, name, readonly, canChange, handleFieldChange]
  );

  if (status === SeatStatus.Cancelled || status === SeatStatus.Returned) {
    return <div />;
  }

  return (
    <FieldSet label={service ? service.name : undefined}>
      <Div layout="grid 12">
        {service && service.kind === ServiceKind.Transfer && (
          <Div>
            <Div layout="grid 12 3@lg">
              <Div>
                <NumberBox
                  label={TXT("label.vehicleCapacity")}
                  fill={true}
                  readonly={true}
                  name={`${name}-capacity`}
                  value={service.capacity}
                />
              </Div>
            </Div>
          </Div>
        )}
        {service &&
          (service.kind === ServiceKind.ConferenceRoom ||
            service.kind === ServiceKind.Apartments ||
            service.kind === ServiceKind.Luggage ||
            service.kind === ServiceKind.Photography ||
            service.kind === ServiceKind.LoungeTime ||
            service.kind === ServiceKind.Transfer) && (
            <Div>
              <Div layout="grid 12 3@lg">
                {service &&
                  (service.kind === ServiceKind.ConferenceRoom ||
                    service.kind === ServiceKind.Apartments ||
                    service.kind === ServiceKind.Photography) && (
                    <Div>
                      <DateBox
                        label={TXT("label.from")}
                        disabled={!readonly && !canChange}
                        fill={true}
                        time={true}
                        zone={belongsTo ? belongsTo.zone : undefined}
                        name={`${name}-from`}
                        value={from}
                        validators={[required]}
                        onChange={handleFromChange}
                      />
                    </Div>
                  )}
                {service &&
                  (service.kind === ServiceKind.ConferenceRoom ||
                    service.kind === ServiceKind.Apartments ||
                    service.kind === ServiceKind.Photography) && (
                    <Div>
                      <DateBox
                        label={TXT("label.till")}
                        disabled={!readonly && !canChange}
                        fill={true}
                        time={true}
                        zone={belongsTo ? belongsTo.zone : undefined}
                        name={`${name}-till`}
                        value={till}
                        validators={[required]}
                        onChange={handleTillChange}
                      />
                    </Div>
                  )}
                {service && service.kind === ServiceKind.LoungeTime && (
                  <Div>
                    <ComboBox
                      label={TXT("label.passenger")}
                      name={`${name}-passenger`}
                      data={formattedPassengers}
                      value={formattedPassenger}
                      valueKey="id"
                      valueLabel="displayName"
                      validators={[required]}
                      onChange={handlePassengerChange}
                    />
                  </Div>
                )}
                {service &&
                  (service.kind === ServiceKind.Luggage ||
                    service.kind === ServiceKind.Photography ||
                    service.kind === ServiceKind.LoungeTime) && (
                    <Div>
                      <NumberBox
                        label={TXT("label.count")}
                        disabled={!readonly && !canChange}
                        fill={true}
                        name={`${name}-count`}
                        value={count}
                        min={1}
                        validators={[required]}
                        onChange={handleCountChange}
                      />
                    </Div>
                  )}
                {service && service.kind === ServiceKind.Transfer && (
                  <Div>
                    <ComboBox
                      label={TXT("label.terminal")}
                      disabled={!readonly && !canChange}
                      fill={true}
                      name={`${name}-terminal`}
                      searchable={true}
                      data={terminals.data}
                      value={destinationPoint}
                      valueKey="id"
                      valueLabel="code"
                      validators={[required]}
                      onChange={handleDestinationPointChange}
                      onFetch={getTerminals}
                    />
                  </Div>
                )}
                {service && service.kind === ServiceKind.Transfer && (
                  <Div>
                    <ComboBox
                      label={TXT("label.lounge")}
                      disabled={!readonly && !canChange}
                      fill={true}
                      name={`${name}-lounge`}
                      data={transferPointTypes}
                      value={destinationPointType}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={handleDestinationPointTypeChange}
                    />
                  </Div>
                )}
                {service && service.kind === ServiceKind.Transfer && (
                  <Div>
                    <DateBox
                      label={TXT("label.dateTime")}
                      disabled={!readonly && !canChange}
                      fill={true}
                      time={true}
                      zone={belongsTo ? belongsTo.zone : "UTC"}
                      name={`${name}-departureTime`}
                      value={departureTime}
                      validators={[required]}
                      onChange={handleDepartureTimeChange}
                    />
                  </Div>
                )}
              </Div>
            </Div>
          )}
        {fieldDescriptions && (
          <Div>
            <Div layout="grid 12 3@lg">
              {fieldDescriptions.map((fieldDescription, fieldIndex) => (
                <Div key={fieldIndex}>{renderField(fieldDescription)}</Div>
              ))}
            </Div>
          </Div>
        )}
        <Div>
          <Div layout="flex">
            <Div layout="fill">
              {rated && cost && (
                <Div>
                  <Span>
                    {formatMessage(
                      TXT("label.rateValue", {
                        rate: cost.value * (count || 1) * hours,
                        currency: item.referenceCurrency.code,
                      })
                    )}
                  </Span>
                </Div>
              )}
              {rated &&
                status &&
                calc.status !== OrderStatus.Cancelled &&
                calc.status !== OrderStatus.Completed &&
                calc.status !== OrderStatus.Rejected && (
                  <Div
                    intent={
                      status === SeatStatus.Paid || status === SeatStatus.Rated
                        ? "success"
                        : status === SeatStatus.Requested ||
                          status === SeatStatus.Fined ||
                          status === SeatStatus.Forced
                        ? "warning"
                        : "danger"
                    }
                  >
                    <Span>
                      {formatSeatStatus(status)}
                      {item.confirmedAt === null
                        ? ` (${formatMessage(TXT("label.needsConfirmation"))})`
                        : ""}
                    </Span>
                  </Div>
                )}
            </Div>
            {!form.readonly &&
              canChange &&
              service &&
              service.kind !== ServiceKind.Urgency && (
                <Div layout="fit">
                  <Button
                    disabled={form.submitting}
                    primary={true}
                    look="bare"
                    className="action"
                    onClick={handleRemove}
                  >
                    {formatMessage(TXT("action.delete"))}
                  </Button>
                </Div>
              )}
          </Div>
        </Div>
      </Div>
    </FieldSet>
  );
}

interface OrderedServiceListProps {
  name: string;
  readonly: boolean;
  rated: boolean;
  visited: boolean;
  passengers: Passenger[];
  orderType: OrderType | null;
  calc: Order;
  data: RatedService[];
  flightType: ServiceType;
  airport?: Airport;
  onChange: (value: RatedService | null, index: number) => void;
}

function OrderedServiceList(props: OrderedServiceListProps): ReactElement {
  const {
    name,
    readonly,
    rated,
    visited,
    passengers,
    orderType,
    flightType,
    calc,
    data,
    airport,
    onChange,
  } = props;

  return (
    <React.Fragment>
      {data.map((orderedService, orderedServiceIndex) =>
        orderedService.status !== SeatStatus.Fined &&
        (orderedService.service.type === ServiceType.Any ||
          orderedService.service.type === flightType) &&
        !(
          orderedService.service.kind === ServiceKind.Urgency &&
          !orderedService.price
        ) ? (
          <OrderedServiceItem
            key={orderedServiceIndex}
            name={`${name}-${orderedServiceIndex}`}
            readonly={readonly}
            rated={rated}
            visited={visited}
            passengers={passengers}
            orderType={orderType}
            calc={calc}
            item={orderedService}
            index={orderedServiceIndex}
            belongsTo={airport}
            onChange={onChange}
          />
        ) : null
      )}
    </React.Fragment>
  );
}

interface OrderedPassengerRateProps {
  rate: RateBase;
  cost: RateCost;
  fine: RateFine;
}

function OrderedPassengerRate(props: OrderedPassengerRateProps) {
  const { rate, cost, fine } = props;

  const [full] = useRate(rate.id);

  const [group, setGroup] = useState<GroupRateRule | null>(null);
  const [discount, setDiscount] = useState<DiscountRateRule | null>(null);

  const [, formatCategory] = usePassengerCategories();

  useEffect(() => {
    if (!full || full.rules.length === 0) {
      setGroup(null);
      setDiscount(null);
      return;
    }

    const group =
      full.rules.filter((item) => item.name === "GroupRateRule")[0] ?? null;
    setGroup(group ? (group as GroupRateRule) : null);

    const discount =
      full.rules.filter((item) => item.name === "DiscountRateRule")[0] ?? null;
    setDiscount(discount ? (discount as DiscountRateRule) : null);
  }, [full, setGroup, setDiscount]);

  const renderGroup = useCallback(() => {
    if (group !== null && group.minSize > 1) {
      const totalPrefix = group.maxSize ? "" : "от ";
      const total = `всего: ${totalPrefix}${group.minSize}`;

      const adultPrefix =
        group.minAdultCount === group.maxAdultCount ? "" : "от ";
      const adult = `взрослых: ${adultPrefix}${group.minAdultCount}`;

      const childPrefix =
        group.minChildCount === group.maxChildCount ? "" : "от ";
      const child = `детей: ${childPrefix}${group.minChildCount}`;

      return (
        <div>
          <p>
            Групповой тариф ({full?.code} / {formatCategory(cost.category)}):{" "}
            {cost.value} {full?.currency.code}
          </p>
          <ul>
            <li>{total}</li>
            <li>{adult}</li>
            <li>{child}</li>
          </ul>
        </div>
      );
    }

    return (
      <p>
        Индивидуальный: {cost.value} {full?.currency.code}
      </p>
    );
  }, [full, group, formatCategory]);

  const renderDiscount = useCallback(() => {
    if (discount != null) {
      return <p>Скидка: {discount.value}%</p>;
    }

    return null;
  }, [discount]);

  if (!full) return null;

  return (
    <div>
      {renderGroup()}
      {renderDiscount()}
    </div>
  );
}

interface OrderedResourcePassengersProps {
  calc: Order;
  index: number;
  zone: string | undefined;
  onClose: () => void;
}

function OrderedResourcePassengers(props: OrderedResourcePassengersProps) {
  const { calc, index, zone, onClose } = props;

  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();
  const [, formatSeatStatus] = useSeatStatuses();

  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const data: any[] = [];
    const ratedPassengers = calc.spans
      .flatMap((x) => x.resources)
      [index].passengers.filter(
        (x) =>
          x.status === SeatStatus.Rated ||
          x.status === SeatStatus.Paid ||
          x.status === SeatStatus.Requested
      );
    for (const ratedPassenger of ratedPassengers) {
      const item = {
        name: formatPassenger(
          calc!.passengers.filter(
            (x) => x.id === ratedPassenger.passenger.id
          )[0]
        ),
        status: formatSeatStatus(ratedPassenger.status),
        from: ratedPassenger.visitedFrom
          ? formatDate(ratedPassenger.visitedFrom, true, zone)
          : "—",
        till: ratedPassenger.visitedTill
          ? formatDate(ratedPassenger.visitedTill, true, zone)
          : "—",
        rate: ratedPassenger.rate,
        cost: ratedPassenger.cost,
        fine: ratedPassenger.fine,
      };
      data.push(item);
    }
    setData(data);
  }, [
    calc,
    index,
    setData,
    formatDate,
    formatPassenger,
    formatSeatStatus,
    zone,
  ]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const renderItem = useCallback((item: any) => {
    return (
      <OrderedPassengerRate
        rate={item.rate}
        cost={item.cost}
        fine={item.fine}
      />
    );
  }, []);

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" icon="close" onClick={onClose} />
          </Div>
        </Div>
      </Div>
      <Div>
        <span className="header">{formatMessage(TXT("label.passengers"))}</span>
      </Div>
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid data={data} itemRenderer={renderItem} width={width}>
              <GridColumn
                title={TXT("label.fullName")}
                field="name"
                width={160}
                fill={true}
              />
              <GridColumn
                title={TXT("label.status")}
                field="status"
                width={128}
              />
              <GridColumn
                title={TXT("label.dateOfEnter")}
                field="from"
                width={160}
              />
              <GridColumn
                title={TXT("label.dateOfLeave")}
                field="till"
                width={160}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}

interface OrderedResourceItemProps {
  name: string;
  readonly: boolean;
  passengers: Passenger[];
  orderType: OrderType | null;
  contract: OrganizationContract | null;
  rated: boolean;
  calc: Order;
  item: RatedResource;
  index: number;
  onChange: (value: RatedResource | null, index: number) => void;
}

function OrderedResourceItem(props: OrderedResourceItemProps): ReactElement {
  const {
    name,
    readonly,
    passengers,
    orderType,
    contract,
    rated,
    calc,
    item,
    index,
    onChange,
  } = props;

  const pageSize = 100;

  const auth = useContext(AuthContext);
  const form = useContext(FormContext);

  const formatTime = useFormatTime();
  const formatMessage = useFormatMessage();
  const [, formatServiceKind] = usePrimaryServiceKinds();
  const [, formatServiceType] = useServiceTypes();
  const [, formatPassengerCategory] = usePassengerCategories();
  const [, formatSeatStatus] = useSeatStatuses();

  const [resource] = useResource(item.resource.id);
  const [airport] = useAirport(resource ? resource.airport.id : null);
  const [settings] = useResourceSettings(item.resource.id);

  const [isTransit, setIsTransit] = useState(
    item.flights.length === 2 &&
      item.flights[0].type === ServiceType.Arrival &&
      item.flights[1].type === ServiceType.Departure
  );
  const transit =
    resource && (resource.kind === ServiceKind.Transit || isTransit);

  let seatLimit =
    settings && settings.quota != null
      ? settings.quota
      : settings && settings.capacity != null
      ? settings.capacity
      : null;
  let noSeatCount = item.passengers
    .map((x) => x.seat)
    .filter((x) => x !== null && seatLimit !== null && x > seatLimit).length;

  const [flightTypes] = useFlightTypes();
  const [flight1Type] = useField<Pair<ServiceType> | null>(
    flightTypes.filter(
      (pair) =>
        resource !== null &&
        (resource.type === ServiceType.Any || resource.type === pair.code) &&
        pair.code === item.flights[0].type
    )[0] || null
  );
  const [flight2Type] = useField<Pair<ServiceType> | null>(
    flightTypes.filter(
      (pair) => item.flights.length > 1 && pair.code === item.flights[1].type
    )[0] || null
  );
  const cityQuery = useMemo(() => {
    if (
      airport &&
      resource &&
      resource.flightDirection !== FlightDirection.Any
    ) {
      return {
        byCountryId:
          resource.flightDirection === FlightDirection.Domestic
            ? airport.country.id
            : undefined,
        byNotCountryId:
          resource.flightDirection === FlightDirection.International
            ? airport.country.id
            : undefined,
      };
    }

    return {};
  }, [airport, resource]);
  const [flightCities, getFlightCities] = useCities(
    pageSize,
    !readonly,
    cityQuery
  );
  const [flight1City] = useCity(
    item.flights[0].city ? item.flights[0].city.id : null
  );
  const [flight1Number] = useField<string | null>(item.flights[0].number);
  const [flight1Date] = useField<string | null>(item.flights[0].date);
  const [flight2City] = useCity(
    item.flights.length > 1 && item.flights[1].city
      ? item.flights[1].city.id
      : null
  );
  const [flight2Number] = useField<string | null>(
    item.flights.length > 1 ? item.flights[1].number : null
  );
  const [flight2Date] = useField<string | null>(
    item.flights.length > 1 ? item.flights[1].date : null
  );
  const [detailsToggled, setDetailsToggled] = useState<boolean>(false);
  const flightType = item.flights[0].type;
  const [services, getServices] = useResourceServices(
    item.resource.id,
    pageSize,
    !readonly,
    useMemo(
      () => [
        ServiceKind.Transfer,
        ServiceKind.LoungeTime,
        ServiceKind.ConferenceRoom,
        ServiceKind.Apartments,
        ServiceKind.Luggage,
        ServiceKind.Photography,
        ServiceKind.Other,
      ],
      []
    ),
    useMemo(() => [ServiceType.Any, flightType], [flightType])
  );
  const [ratedServices] = useField(item.services);

  const activePassengers = item.passengers.filter(
    (x) => x.status === SeatStatus.Paid || x.status === SeatStatus.Rated
  );
  const confirmed =
    activePassengers.every((x) => x.confirmedAt !== null) &&
    activePassengers.length > 0;
  const statuses = item.passengers.map((x) => x.status);
  const status: Pair<SeatStatus, "danger" | "success" | "warning"> | undefined =
    statuses.length === 0
      ? undefined
      : statuses.every(
          (x) => x === SeatStatus.Cancelled || x === SeatStatus.Returned
        )
      ? new Pair(SeatStatus.Cancelled, "danger")
      : statuses.some((x) => x === SeatStatus.NoSchedule)
      ? new Pair(SeatStatus.NoSchedule, "danger")
      : statuses.some((x) => x === SeatStatus.NoRate)
      ? new Pair(SeatStatus.NoRate, "danger")
      : statuses.some((x) => x === SeatStatus.NoSeat)
      ? new Pair(SeatStatus.NoSeat, "danger")
      : statuses.some((x) => x === SeatStatus.NoLimit)
      ? new Pair(SeatStatus.NoLimit, "danger")
      : statuses.some((x) => x === SeatStatus.Rejected)
      ? new Pair(SeatStatus.Rejected, "danger")
      : statuses.some((x) => x === SeatStatus.Requested)
      ? new Pair(SeatStatus.Requested, "warning")
      : statuses
          .filter(
            (x) => x !== SeatStatus.Cancelled && x !== SeatStatus.Returned
          )
          .every((x) => x === SeatStatus.Paid)
      ? new Pair(SeatStatus.Paid, confirmed ? "success" : "warning")
      : new Pair(SeatStatus.Rated, "warning");
  const statusText = item.passengers
    .filter((x) => x.statusText !== null)
    .map((x) => x.statusText)
    .filter((value, index, self) => self.indexOf(value) === index);
  const visited =
    (item.passengers.some(
      (x) => x.visitedFrom !== null || x.visitedTill !== null
    ) ||
      item.passengers.length > 0) &&
    item.passengers.every(
      (x) =>
        x.status === SeatStatus.Rejected ||
        x.status === SeatStatus.Returned ||
        x.status === SeatStatus.Fined ||
        x.status === SeatStatus.Cancelled
    );
  const paid = statuses.some(
    (x) =>
      x === SeatStatus.Paid ||
      x === SeatStatus.Fined ||
      x === SeatStatus.Forced ||
      x === SeatStatus.Cancelled ||
      x === SeatStatus.Returned
  );

  const flightDateWarning =
    flight1Type !== null &&
    flight1Type.code === ServiceType.Departure &&
    flight1Date !== null
      ? auth.profile.isAgent
        ? DateTime.fromISO(flight1Date).diffNow("hours").hours < 4
          ? formatMessage(TXT("message.agentDepartureWarning"))
          : undefined
        : DateTime.fromISO(flight1Date).diffNow("hours").hours < 0
        ? formatMessage(TXT("message.ownerDepartureWarning"))
        : undefined
      : undefined;

  const handleRemove = useCallback((): void => {
    form.onFieldRemove(name, false);
    onChange(null, index);
  }, [form, name, index, onChange]);

  const handleFlightTypeChange = useCallback(
    (value: Pair<ServiceType> | null): void => {
      const flights = [...item.flights];
      flights[0].type = value ? value.code : ServiceType.Any;
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight1CityChange = useCallback(
    (value: CityBase | null): void => {
      const flights = [...item.flights];
      flights[0].city = value ? value : new CityBase();
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight2CityChange = useCallback(
    (value: CityBase | null): void => {
      const flights = [...item.flights];
      flights[1].city = value ? value : new CityBase();
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight1NumberChange = useCallback(
    (value: string | null): void => {
      const flights = [...item.flights];
      flights[0].number = value || "";
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight2NumberChange = useCallback(
    (value: string | null): void => {
      const flights = [...item.flights];
      flights[1].number = value || "";
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight1DateChange = useCallback(
    (value: string | null): void => {
      const flights = [...item.flights];
      flights[0].date = value || "";
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const handleFlight2DateChange = useCallback(
    (value: string | null): void => {
      const flights = [...item.flights];
      flights[1].date = value || "";
      onChange({ ...item, flights }, index);
    },
    [item, index, onChange]
  );

  const toggleDetails = useCallback((): void => {
    setDetailsToggled((prev) => !prev);
  }, [setDetailsToggled]);

  const handleServiceAdd = useCallback(
    (value: ServiceBase[]): void => {
      if (value.length === 0) return;

      const changedItem = { ...item };
      const ratedService = new RatedService();
      ratedService.service = value[0];
      if (
        ratedService.service.kind === ServiceKind.ConferenceRoom ||
        ratedService.service.kind === ServiceKind.Apartments ||
        ratedService.service.kind === ServiceKind.Photography
      ) {
        if (
          flight1Type &&
          flight1Date &&
          settings &&
          (flight1Type.code === ServiceType.Arrival ||
            flight1Type.code === ServiceType.Departure)
        ) {
          ratedService.from = getEnterDateTime(
            flight1Type.code,
            flight1Date,
            settings.arrivalEnterDelta || "",
            settings.departureEnterDelta || ""
          );
          ratedService.till = getLeaverDateTime(
            flight1Type.code,
            flight1Date,
            settings.arrivalLeaveDelta || "",
            settings.departureLeaveDelta || ""
          );
          if (ratedService.from === ratedService.till) {
            ratedService.till = DateTime.fromISO(ratedService.from)
              .plus({ hour: 1 })
              .toISO();
          }
        }
      }
      if (ratedService.service.kind === ServiceKind.Transfer) {
        if (
          flight1Type &&
          flight1Date &&
          settings &&
          (flight1Type.code === ServiceType.Arrival ||
            flight1Type.code === ServiceType.Departure)
        ) {
          ratedService.departureTime = getEnterDateTime(
            flight1Type.code,
            flight1Date,
            settings.arrivalEnterDelta || "",
            settings.departureEnterDelta || ""
          );
        }
      }

      changedItem.services = [...(changedItem.services || []), ratedService];
      onChange(changedItem, index);
    },
    [item, index, settings, flight1Type, flight1Date, onChange]
  );

  const handleServiceChange = useCallback(
    (value: RatedService | null, ratedServiceIndex: number): void => {
      const changedServices = [...(item.services || [])];
      if (value) {
        changedServices.splice(ratedServiceIndex, 1, value);
      } else {
        const deletedService = changedServices[ratedServiceIndex];
        if (!paid || deletedService.id === 0) {
          changedServices.splice(ratedServiceIndex, 1);
        } else {
          changedServices.splice(ratedServiceIndex, 1, {
            ...deletedService,
            status: SeatStatus.Fined,
          });
        }
      }

      const changedItem = { ...item, services: changedServices };
      onChange(changedItem, index);
    },
    [item, paid, index, onChange]
  );

  const renderRatesAndFines = useCallback(
    (calc: Order | null, index: number): ReactElement => {
      if (
        calc == null ||
        calc.spans.flatMap((x) => x.resources)[index] === undefined
      )
        return <span />;

      const passengers = calc.spans
        .flatMap((x) => x.resources)
        [index].passengers.filter(
          (x) =>
            x.status === SeatStatus.Rated ||
            x.status === SeatStatus.Paid ||
            x.status === SeatStatus.Requested
        );
      const rateGroups = new Map<
        string,
        {
          code: string;
          type: ServiceType;
          category: PassengerCategory;
          count: number;
          value: number;
          currency: string;
          discount: number;
        }
      >();
      const fineGroups = new Map<
        string,
        {
          code: string;
          type: ServiceType;
          category: PassengerCategory;
          delta: string;
          valueAfter: number;
          valueBefore: number;
        }
      >();
      for (const passenger of passengers) {
        if (passenger.rate && passenger.cost) {
          const rateKey = `${passenger.rate.id}-${passenger.rate.type}-${passenger.cost.category}-${passenger.discountRatio}`;
          const rateGroup = rateGroups.get(rateKey) || {
            code: passenger.rate.code,
            type: passenger.rate.type,
            category: passenger.cost.category,
            count: 0,
            value: passenger.cost.value * (passenger.discountRatio || 1),
            currency: passenger.referenceCurrency.code,
            discount: passenger.discountRatio || 1,
          };
          rateGroups.set(rateKey, { ...rateGroup, count: rateGroup.count + 1 });

          if (passenger.fine) {
            const fineKey = `${passenger.rate.id}-${passenger.fine.type}`;
            const fineGroup = fineGroups.get(fineKey) || {
              code: passenger.rate.code,
              type: passenger.fine.type,
              category: passenger.cost.category,
              delta: passenger.fine.flightDelta,
              valueAfter: passenger.fine.valueAfter,
              valueBefore: passenger.fine.valueBefore,
            };
            fineGroups.set(fineKey, fineGroup);
          }
        }
      }

      if (passengers.length === 0) {
        return <div />;
      }

      return (
        <Div>
          <Div layout="flex vertical-center">
            <Div layout="fill">
              <span>
                {formatMessage(TXT("label.rates"))}
                {": "}
              </span>
              <ul>
                {Array.from(rateGroups.values()).map(
                  (rateGroup, rateGroupIndex) => (
                    <li key={rateGroupIndex}>
                      <span>
                        {`${rateGroup.code} ${
                          rateGroup.type !== ServiceType.Any
                            ? `/ ${formatServiceType(rateGroup.type)} /`
                            : "/"
                        } ${formatPassengerCategory(rateGroup.category)} — ${
                          rateGroup.count
                        } × ${rateGroup.value} ${rateGroup.currency}`}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </Div>
            {true && (
              <Div layout="fit">
                <Button look="bare" primary={true} onClick={toggleDetails}>
                  {formatMessage(TXT("action.details"))}
                </Button>
              </Div>
            )}
            {true && detailsToggled && (
              <Drawer>
                <OrderedResourcePassengers
                  calc={calc}
                  index={index}
                  zone={airport ? airport.zone : undefined}
                  onClose={toggleDetails}
                />
              </Drawer>
            )}
          </Div>
          <Div>
            <span>
              {formatMessage(TXT("label.fines"))}
              {": "}
            </span>
            <ul>
              {Array.from(fineGroups.values()).map(
                (fineGroup, fineGroupIndex) => (
                  <li key={fineGroupIndex}>
                    <span>
                      {`${fineGroup.code} ${
                        fineGroup.type !== ServiceType.Any
                          ? `/ ${formatServiceType(fineGroup.type)}`
                          : ""
                      } — ${formatMessage(
                        TXT("message.fineRule", {
                          time: formatTime(fineGroup.delta),
                          valueBefore: fineGroup.valueBefore,
                          valueAfter: fineGroup.valueAfter,
                        })
                      )}`}
                    </span>
                  </li>
                )
              )}
            </ul>
          </Div>
        </Div>
      );
    },
    [
      readonly,
      airport,
      detailsToggled,
      toggleDetails,
      formatMessage,
      formatPassengerCategory,
      formatServiceType,
      formatTime,
    ]
  );

  const validateContractDate = useCallback(
    (value: string | null): IntlText | undefined => {
      if (contract) {
        const validateDate = new Date(value || "");
        const contractDate = new Date(contract.concludedAt);
        return validateDate < contractDate
          ? TXT("error.contractIsNotAuthorizedForCurrentDate")
          : undefined;
      } else {
        return undefined;
      }
    },
    [contract]
  );

  const validateFlight1City = useCallback((): IntlText | undefined => {
    if (
      airport &&
      flight1City &&
      resource &&
      resource.flightDirection !== FlightDirection.Any
    ) {
      if (
        resource.flightDirection === FlightDirection.Domestic &&
        flight1City.country.id !== airport.country.id
      ) {
        return TXT("error.onlyDomesticFlightsAreServed");
      }
      if (
        resource.flightDirection === FlightDirection.International &&
        flight1City.country.id === airport.country.id
      ) {
        return TXT("error.onlyInternationalFlightsAreServed");
      }
    }

    return undefined;
  }, [airport, flight1City, resource]);

  const validateFlight2City = useCallback((): IntlText | undefined => {
    if (
      airport &&
      flight2City &&
      resource &&
      resource.flightDirection !== FlightDirection.Any
    ) {
      if (
        resource.flightDirection === FlightDirection.Domestic &&
        flight2City.country.id !== airport.country.id
      ) {
        return TXT("error.onlyDomesticFlightsAreServed");
      }
      if (
        resource.flightDirection === FlightDirection.International &&
        flight2City.country.id === airport.country.id
      ) {
        return TXT("error.onlyInternationalFlightsAreServed");
      }
    }

    return undefined;
  }, [airport, flight2City, resource]);

  const handleTransit = useCallback(
    (value: boolean) => {
      if (value) {
        const flights = [new RatedFlight(), new RatedFlight()];
        flights[0].type = ServiceType.Arrival;
        flights[1].type = ServiceType.Departure;
        const arrival = item.flights[0];
        const departure = item.flights[1];
        if (arrival) {
          flights[0].city = arrival.city;
          flights[0].date = arrival.date;
          flights[0].number = arrival.number;
        }
        if (departure) {
          flights[1].city = departure.city;
          flights[1].date = departure.date;
          flights[1].number = departure.number;
        }

        onChange({ ...item, flights }, index);
      } else {
        const flights = [item.flights[0]];

        onChange({ ...item, flights }, index);
      }

      setIsTransit(value);
    },
    [item, index, onChange]
  );

  return (
    <React.Fragment>
      <FieldSet label={TXT("label.information")}>
        <Div layout="grid 12 3@lg vertical-end">
          <Div>
            <TextBox
              label={TXT("label.serviceKind")}
              fill={true}
              name={`${name}-serviceKind`}
              value={resource ? formatServiceKind(resource.kind) : ""}
            />
          </Div>
          <Div>
            <TextBox
              label={TXT("label.airport")}
              fill={true}
              name={`${name}-airport`}
              value={airport ? airport.name : ""}
            />
          </Div>
          <Div>
            <TextBox
              label={TXT("label.terminal")}
              fill={true}
              name={`${name}-terminal`}
              value={resource ? resource.terminal || "" : ""}
            />
          </Div>
          {resource && resource.kind === ServiceKind.VirtualLounge && (
            <Div layout="flex">
              <Div layout="fill" />
              {!form.readonly && !paid && (
                <Div layout="fit">
                  <Button
                    disabled={form.submitting}
                    primary={true}
                    look="bare"
                    className="action"
                    onClick={handleRemove}
                  >
                    {formatMessage(TXT("action.delete"))}
                  </Button>
                </Div>
              )}
              {paid &&
                status &&
                item.passengers.length > 0 &&
                item.passengers.every(
                  (x) =>
                    x.status === SeatStatus.Cancelled ||
                    x.status === SeatStatus.Rejected ||
                    x.status === SeatStatus.Returned
                ) && (
                  <Div layout="fill" intent={"danger"}>
                    {formatSeatStatus(item.passengers[0].status)}
                  </Div>
                )}
            </Div>
          )}
        </Div>
      </FieldSet>
      {resource && resource.kind !== ServiceKind.VirtualLounge && (
        <FieldSet label={TXT("label.flight")}>
          <Div layout="grid 12">
            {resource.isTransit && (
              <Div>
                <ToggleBox
                  label={TXT("label.transit")}
                  primary={true}
                  name="isTransit"
                  value={isTransit}
                  onChange={handleTransit}
                />
              </Div>
            )}
            <Div>
              <Div layout="grid 12 3@lg">
                <Div>
                  <ComboBox
                    label={TXT("label.flightType")}
                    disabled={!readonly && (visited || isTransit)}
                    fill={true}
                    name={`${name}-flightType`}
                    data={flightTypes.filter(
                      (pair) =>
                        resource !== null &&
                        (resource.type === ServiceType.Any ||
                          resource.type === pair.code)
                    )}
                    value={flight1Type}
                    valueKey="code"
                    valueLabel="name"
                    validators={[required]}
                    onChange={handleFlightTypeChange}
                  />
                </Div>
                <Div>
                  <ComboBox
                    label={TXT(
                      flight1Type && flight1Type.code === ServiceType.Arrival
                        ? "label.flightCity.departure"
                        : flight1Type &&
                          flight1Type.code === ServiceType.Departure
                        ? "label.flightCity.arrival"
                        : "label.flightCity"
                    )}
                    disabled={!readonly && visited}
                    fill={true}
                    searchable={true}
                    pageSize={pageSize}
                    name={`${name}-flightCity`}
                    data={flightCities}
                    value={flight1City}
                    valueKey="id"
                    valueLabel="name"
                    validators={[required, validateFlight1City]}
                    onChange={handleFlight1CityChange}
                    onFetch={getFlightCities}
                  />
                </Div>
                <Div>
                  <TextBox
                    label={TXT("label.flightNumber")}
                    disabled={!readonly && visited}
                    fill={true}
                    mask="AB00000"
                    placeholder="AB00000"
                    rules={{
                      A: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                      B: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                    }}
                    name={`${name}-flightNumber`}
                    value={flight1Number}
                    validators={[required, flight]}
                    onChange={handleFlight1NumberChange}
                  />
                </Div>
                <Div>
                  <DateBox
                    label={TXT(
                      flight1Type && flight1Type.code === ServiceType.Arrival
                        ? "label.flightDate.arrival"
                        : flight1Type &&
                          flight1Type.code === ServiceType.Departure
                        ? "label.flightDate.departure"
                        : "label.flightDate"
                    )}
                    disabled={!readonly && visited}
                    fill={true}
                    zone={airport ? airport.zone : undefined}
                    time={true}
                    name={`${name}-flightDate`}
                    value={flight1Date}
                    validators={[required, validateContractDate]}
                    onChange={handleFlight1DateChange}
                  />
                </Div>
              </Div>
              {transit && (
                <Div layout="grid 12 3@lg">
                  <Div>
                    <ComboBox
                      label={TXT("label.flightType")}
                      disabled={!readonly}
                      fill={true}
                      name={`${name}-flightType2`}
                      data={flightTypes}
                      value={flight2Type}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                    />
                  </Div>
                  <Div>
                    <ComboBox
                      label={TXT("label.flightCity.arrival")}
                      disabled={!readonly && visited}
                      fill={true}
                      searchable={true}
                      pageSize={pageSize}
                      name={`${name}-flightCity2`}
                      data={flightCities}
                      value={flight2City}
                      valueKey="id"
                      valueLabel="name"
                      validators={[required, validateFlight2City]}
                      onChange={handleFlight2CityChange}
                      onFetch={getFlightCities}
                    />
                  </Div>
                  <Div>
                    <TextBox
                      label={TXT("label.flightNumber")}
                      disabled={!readonly && visited}
                      fill={true}
                      mask="AB00000"
                      placeholder="AB00000"
                      rules={{
                        A: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                        B: /[0-9a-zA-Z\u0400-\u0481\u048A-\u04FF]/,
                      }}
                      name={`${name}-flightNumber2`}
                      value={flight2Number}
                      validators={[required, flight]}
                      onChange={handleFlight2NumberChange}
                    />
                  </Div>
                  <Div>
                    <DateBox
                      label={TXT("label.flightDate.departure")}
                      disabled={!readonly && visited}
                      fill={true}
                      zone={airport ? airport.zone : undefined}
                      time={true}
                      name={`${name}-flightDate2`}
                      value={flight2Date}
                      validators={[required, validateContractDate]}
                      onChange={handleFlight2DateChange}
                    />
                  </Div>
                </Div>
              )}
            </Div>
            <Div>
              <Div layout="flex">
                <Div layout="fill">
                  <Div layout="grid 12">
                    {rated &&
                      status &&
                      calc.status !== OrderStatus.Cancelled &&
                      calc.status !== OrderStatus.Completed &&
                      calc.status !== OrderStatus.Rejected && (
                        <Div>{renderRatesAndFines(calc, index)}</Div>
                      )}
                    {rated &&
                      status &&
                      calc.status !== OrderStatus.Cancelled &&
                      calc.status !== OrderStatus.Completed && (
                        <Div intent={status.name}>
                          <Div>
                            {formatSeatStatus(status.code)}
                            {!confirmed
                              ? ` (${formatMessage(
                                  TXT("label.needsConfirmation")
                                )})`
                              : ""}
                          </Div>
                          {rated &&
                            calc.status === OrderStatus.Created &&
                            seatLimit != null &&
                            noSeatCount > 0 &&
                            (auth.profile.isSys || auth.profile.isOwner) && (
                              <Div>
                                <Span>
                                  {formatMessage(
                                    TXT("hint.noSeatCount", {
                                      count: noSeatCount,
                                    })
                                  )}
                                </Span>
                              </Div>
                            )}
                          {statusText && (
                            <ul>
                              {statusText
                                .join("\n")
                                .split("\n")
                                .filter((error) => error && error.length > 0)
                                .map((error, errorIndex) => (
                                  <li key={errorIndex}>{error}</li>
                                ))}
                            </ul>
                          )}
                        </Div>
                      )}
                    {flightDateWarning && (
                      <Div>
                        <Span intent="warning">{flightDateWarning}</Span>
                      </Div>
                    )}
                  </Div>
                </Div>
                {!form.readonly && !paid && (
                  <Div layout="fit">
                    <Button
                      disabled={form.submitting}
                      primary={true}
                      look="bare"
                      className="action"
                      onClick={handleRemove}
                    >
                      {formatMessage(TXT("action.delete"))}
                    </Button>
                  </Div>
                )}
              </Div>
            </Div>
          </Div>
        </FieldSet>
      )}
      {resource &&
        (isLounge(resource.kind) ||
          isAssistance(resource.kind) ||
          resource.kind === ServiceKind.VirtualLounge) &&
        orderType !== OrderType.Business &&
        orderType !== OrderType.Block &&
        (!form.readonly || (item.services || []).length > 0) && (
          <FieldSet label={TXT("label.services")}>
            <Div layout="grid 12">
              {!readonly &&
                (!visited || auth.profile.isSys || auth.profile.isOwner) && (
                  <Div>
                    <Div layout="grid 12">
                      <Div>
                        <Validator
                          name={`${name}-services`}
                          value={(ratedServices ?? []).length}
                          validators={[
                            (value) =>
                              value < 1 &&
                              resource.kind === ServiceKind.VirtualLounge
                                ? TXT("error.minimumOneServiceRequired")
                                : undefined,
                          ]}
                        >
                          <TagsBox
                            name={`${name}-service`}
                            placeholder={TXT("label.search")}
                            fill={true}
                            autoClose={true}
                            pageSize={pageSize}
                            data={services}
                            value={[]}
                            valueKey="id"
                            valueLabel="name"
                            onChange={handleServiceAdd}
                            onFetch={getServices}
                          />
                        </Validator>
                      </Div>
                    </Div>
                  </Div>
                )}
              <Div>
                <OrderedServiceList
                  name={`${name}-ratedServices`}
                  readonly={readonly}
                  rated={rated}
                  visited={visited}
                  passengers={passengers}
                  orderType={orderType}
                  flightType={flight1Type ? flight1Type.code : ServiceType.Any}
                  calc={calc}
                  data={ratedServices || []}
                  airport={airport || undefined}
                  onChange={handleServiceChange}
                />
              </Div>
            </Div>
          </FieldSet>
        )}
    </React.Fragment>
  );
}

interface OrderedResourceListProps {
  name: string;
  readonly: boolean;
  passengers: Passenger[];
  orderType: OrderType | null;
  contract: OrganizationContract | null;
  rated: boolean;
  calc: Order;
  data: RatedResource[];
  selected: number;
  onChange: (value: RatedResource | null, index: number) => void;
  onSelect: (value: number) => void;
}

function OrderedResourceList(props: OrderedResourceListProps): ReactElement {
  const {
    name,
    readonly,
    passengers,
    orderType,
    contract,
    rated,
    calc,
    data,
    selected,
    onChange,
    onSelect,
  } = props;

  return (
    <Tabs
      items={data.map((item) => ({
        label: `${item.resource.name} ${
          item.flights[0].number &&
          item.resource.kind !== ServiceKind.VirtualLounge
            ? ` / ${item.flights[0].number}`
            : ""
        }`.trim(),
        data: item,
      }))}
      render={(tabItem, tabIndex) => (
        <OrderedResourceItem
          name={`${name}-${tabIndex}`}
          readonly={readonly}
          passengers={passengers}
          orderType={orderType}
          contract={contract}
          rated={rated}
          calc={calc}
          item={tabItem.data}
          index={tabIndex}
          onChange={onChange}
        />
      )}
      selected={selected}
      onSelect={onSelect}
    />
  );
}

interface OrderedPassengerItemProps {
  disabled?: boolean;
  readonly?: boolean;
  name: string;
  calc: Order | null;
  item: Passenger;
  index: number;
  type?: PassengerType;
  canRemove: boolean;
  canChange: (passenger: Passenger) => boolean;
  ageOfChild?: number;
  ageOfInfant?: number;
  hasTicket?: boolean;
  requireEmail?: boolean;
  requirePhone?: boolean;
  onChange: (item: Passenger | null, index: number) => void;
}

function OrderedPassengerItem(
  props: OrderedPassengerItemProps
): ReactElement | null {
  const {
    disabled,
    readonly,
    name,
    item,
    calc,
    index,
    type,
    canRemove,
    canChange,
    ageOfChild,
    ageOfInfant,
    hasTicket,
    requireEmail,
    requirePhone,
    onChange,
  } = props;

  const context = useContext(FormContext);

  const now = new Date();

  const getDayOfYear = useCallback((value: Date): number => {
    const start = new Date(value.getFullYear(), 0, 0);
    const diff = value.valueOf() - start.valueOf();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  }, []);

  const getAge = useCallback(
    (value: Date): number => {
      const now = new Date();

      let age = now.getFullYear() - value.getFullYear();
      if (getDayOfYear(now) < getDayOfYear(value)) {
        age -= 1;
      }

      return age;
    },
    [getDayOfYear]
  );

  const renderAge = useCallback(
    (passenger: Passenger): ReactElement | null => {
      const birthDate = passenger.dateOfBirth
        ? new Date(passenger.dateOfBirth)
        : null;
      if (!birthDate || isNaN(birthDate.valueOf())) {
        return <span className="icon">{Icons.Passengers.Unknown}</span>;
      } else {
        const age = getAge(birthDate);
        let icon = Icons.Passengers.Adult;
        if (age < (ageOfInfant || 2)) {
          icon = Icons.Passengers.Infant;
        } else if (age < (ageOfChild || 12)) {
          icon = Icons.Passengers.Child;
        }

        return <span className="icon">{icon}</span>;
      }
    },
    [ageOfChild, ageOfInfant, getAge]
  );

  const handleRemove = useCallback((): void => {
    onChange(null, index);
  }, [index, onChange]);

  const handleFamilyNameChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, familyName: value }, index);
    },
    [item, index, onChange]
  );

  const handleGivenNameChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, givenName: value }, index);
    },
    [item, index, onChange]
  );

  const handleMiddleNameChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, middleName: value }, index);
    },
    [item, index, onChange]
  );

  const handleDateOfBirthChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, dateOfBirth: value }, index);
    },
    [item, index, onChange]
  );

  const handleTicketChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, ticket: value }, index);
    },
    [item, index, onChange]
  );

  const handleEmailChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, email: value }, index);
    },
    [item, index, onChange]
  );

  const handlePhoneNameChange = useCallback(
    (value: string | null): void => {
      onChange({ ...item, phone: value }, index);
    },
    [item, index, onChange]
  );

  const activePassenger = useMemo(() => {
    if (!calc) return true;

    if (calc.status === OrderStatus.Cancelled) return true;

    if (item.type === PassengerType.Assistant) return true;

    if (!context.readonly) return true;

    const ratedPassengers = calc.spans
      .flatMap((span) => span.resources)
      .flatMap((ratedResource) => ratedResource.passengers)
      .filter((ratedPassenger) => ratedPassenger.passenger.id === item.id);

    if (ratedPassengers.length === 0) return true;

    return !ratedPassengers.every(
      (ratedPassenger) =>
        ratedPassenger.status === SeatStatus.Cancelled ||
        ratedPassenger.status === SeatStatus.Returned ||
        ratedPassenger.status === SeatStatus.Rejected
    );
  }, [calc, item, context.readonly]);

  if (!activePassenger) return null;

  return (
    <Div layout="flex">
      {!context.readonly && (
        <Div layout="fit">
          <Button
            disabled={disabled || context.submitting || !canRemove}
            look="bare"
            icon="minus-outline"
            onClick={handleRemove}
          />
        </Div>
      )}
      {(!type || type !== PassengerType.Assistant) && (
        <Div layout="fit">{renderAge(item)}</Div>
      )}
      <Div layout="fill">
        <Div layout="flex">
          <Div layout="fit" className="passenger-list-group">
            <Div layout="flex">
              <Div layout="fit" className="passenger-list-group-item">
                <TextBox
                  disabled={!context.readonly && (disabled || !canChange(item))}
                  readonly={readonly}
                  placeholder={ID("passengerList.familyName")}
                  name={`${name}-familyName`}
                  value={item.familyName}
                  validators={[
                    required,
                    (familyName) => length(familyName, 1, 256),
                  ]}
                  onChange={handleFamilyNameChange}
                />
              </Div>
              <Div layout="fit" className="passenger-list-group-item">
                <TextBox
                  disabled={!context.readonly && (disabled || !canChange(item))}
                  readonly={readonly}
                  placeholder={ID("passengerList.givenName")}
                  name={`${name}-givenName`}
                  value={item.givenName}
                  validators={[
                    required,
                    (givenName) => length(givenName, 1, 256),
                  ]}
                  onChange={handleGivenNameChange}
                />
              </Div>
              <Div layout="fit" className="passenger-list-group-item">
                <TextBox
                  disabled={!context.readonly && (disabled || !canChange(item))}
                  readonly={readonly}
                  placeholder={ID("passengerList.middleName")}
                  name={`${name}-middleName`}
                  value={item.middleName}
                  validators={[(middleName) => length(middleName, 1, 256)]}
                  onChange={handleMiddleNameChange}
                />
              </Div>
              {(!type || type !== PassengerType.Assistant) && (
                <Div layout="fit" className="passenger-list-group-item">
                  <DateBox
                    disabled={
                      !context.readonly && (disabled || !canChange(item))
                    }
                    readonly={readonly}
                    name={`${name}-birthDate`}
                    zone="UTC"
                    max={now.toISOString()}
                    value={item.dateOfBirth}
                    onChange={handleDateOfBirthChange}
                  />
                </Div>
              )}
              {hasTicket && (
                <Div layout="fit">
                  <TextBox
                    disabled={
                      !context.readonly && (disabled || !canChange(item))
                    }
                    readonly={readonly}
                    placeholder={ID("passengerList.ticket")}
                    name={`${name}-ticket`}
                    value={item.ticket}
                    validators={[required, (ticket) => length(ticket, 1, 50)]}
                    onChange={handleTicketChange}
                  />
                </Div>
              )}
            </Div>
          </Div>
          <Div layout="fit" className="passenger-list-group">
            <Div layout="flex">
              {(!type || type !== PassengerType.Assistant) && (
                <Div layout="fit" className="passenger-list-group-item">
                  <TextBox
                    disabled={
                      !context.readonly && (disabled || !canChange(item))
                    }
                    readonly={readonly}
                    placeholder={ID("passengerList.email")}
                    name={`${name}-email`}
                    value={item.email}
                    validators={[
                      ...(requireEmail ? [required] : []),
                      email,
                      (value) => length(value, 1, 100),
                    ]}
                    onChange={handleEmailChange}
                  />
                </Div>
              )}
              {(!type || type !== PassengerType.Assistant) && (
                <Div layout="fit" className="passenger-list-group-item">
                  <TextBox
                    disabled={
                      !context.readonly && (disabled || !canChange(item))
                    }
                    readonly={readonly}
                    placeholder={ID("passengerList.phone")}
                    mask="(999) 000-00-00"
                    name={`${name}-phone`}
                    value={item.phone}
                    validators={[...(requirePhone ? [required] : []), phone]}
                    onChange={handlePhoneNameChange}
                  />
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

interface OrderedPassengerListProps {
  disabled?: boolean;
  name: string;
  calc: Order | null;
  data?: Passenger[];
  value: Passenger[] | null;
  limit?: number | null;
  type?: PassengerType;
  canRemove: boolean;
  canChange: (passenger: Passenger) => boolean;
  hasTicket?: boolean;
  required?: boolean;
  ageOfInfant?: number;
  ageOfChild?: number;
  requireEmail?: boolean;
  requirePhone?: boolean;
  onChange: (items: Passenger[]) => void;
}

function OrderedPassengerList(props: OrderedPassengerListProps): ReactElement {
  const {
    disabled,
    name,
    calc,
    data,
    canRemove,
    canChange,
    value,
    limit,
    type,
    hasTicket,
    ageOfInfant,
    ageOfChild,
    requireEmail,
    requirePhone,
    onChange,
  } = props;
  const enteredPassengers = value ? [...value] : [];
  const count = enteredPassengers.filter(
    (passenger) => passenger.type === (type || PassengerType.Primary)
  ).length;

  const context = useContext(FormContext);
  const formatPassenger = useFormatPassenger();

  const predefinedPassengers = data
    ? data
        .filter(
          (x) =>
            !enteredPassengers.some(
              (y) =>
                x.type === y.type &&
                x.familyName === y.familyName &&
                x.middleName === y.middleName &&
                x.dateOfBirth === y.dateOfBirth
            )
        )
        .map((passenger, id) => ({
          key: id,
          text: formatPassenger(passenger),
          item: passenger,
        }))
    : [];

  const handleAdd = useCallback(
    (item?: ButtonItem, index?: number): void => {
      const changedPassengers = [...enteredPassengers];
      let lastId =
        changedPassengers.length > 0
          ? Math.max(...changedPassengers.map((x) => x.id))
          : 0;
      if (item && index !== undefined) {
        changedPassengers.push({
          ...predefinedPassengers[index].item,
          id: ++lastId,
        });
      } else {
        const passenger = new Passenger();
        passenger.id = ++lastId;
        passenger.type = type || PassengerType.Primary;
        changedPassengers.push(passenger);
      }
      onChange(changedPassengers);
    },
    [type, enteredPassengers, predefinedPassengers, onChange]
  );

  const handleChange = useCallback(
    (value: Passenger | null, index: number): void => {
      const changedPassengers = [...enteredPassengers];
      if (value) {
        changedPassengers.splice(index, 1, value);
      } else {
        context.onFieldRemove(`${name}-${index}`, false);
        changedPassengers.splice(index, 1);
      }
      onChange(changedPassengers);
    },
    [context, name, enteredPassengers, onChange]
  );

  return (
    <Div className="passenger-list">
      {value &&
        value.map((passenger, index) => {
          if (passenger.type === (type || PassengerType.Primary)) {
            return (
              <Div key={index}>
                <OrderedPassengerItem
                  disabled={disabled}
                  readonly={!!data}
                  name={`${name}-${index}`}
                  calc={calc}
                  item={passenger}
                  index={index}
                  canRemove={canRemove}
                  canChange={canChange}
                  ageOfChild={ageOfChild}
                  ageOfInfant={ageOfInfant}
                  hasTicket={hasTicket}
                  requireEmail={requireEmail}
                  requirePhone={requirePhone}
                  onChange={handleChange}
                />
              </Div>
            );
          } else {
            return null;
          }
        })}
      {!context.readonly && (
        <Validator
          name={name}
          value={count}
          validators={
            props.required
              ? [
                  (value) =>
                    value < 1 ? ID("passengerList.required") : undefined,
                ]
              : undefined
          }
        >
          <Div layout="flex vertical-center">
            <Div layout="fit">
              <ButtonData
                disabled={
                  disabled ||
                  context.submitting ||
                  (limit && count >= limit) ||
                  (data && predefinedPassengers.length === 0)
                }
                look="bare"
                icon="plus-outline"
                items={
                  predefinedPassengers.length > 0
                    ? predefinedPassengers
                    : undefined
                }
                onClick={handleAdd}
              />
            </Div>
            {limit && (
              <Div layout="fit">
                <small>
                  <IntlFormat
                    text={ID("passengerList.limit", { count, limit })}
                  />
                </small>
              </Div>
            )}
          </Div>
        </Validator>
      )}
    </Div>
  );
}

interface OrderTotalProps {
  readonly: boolean;
  calc: Order;
  onDiscard?: () => void;
  onReject?: () => void;
  onConfirm?: () => void;
  onWithdraw?: () => void;
  onDownload?: () => void;
  onRevert?: () => void;
  onReturn?: () => void;
  onVisit?: () => void;
}

function OrderTotal(props: OrderTotalProps): ReactElement {
  const {
    readonly,
    calc,
    onDiscard,
    onReject,
    onConfirm,
    onWithdraw,
    onDownload,
    onRevert,
    onReturn,
    onVisit,
  } = props;

  const auth = useContext(AuthContext);

  const formatCost = useFormatCost();
  const formatMessage = useFormatMessage();
  const formatStatus = useCalcOrderStatus();
  const [, formatPaymentType] = useOrderPaymentTypes();

  const ratedResources = calc.spans.flatMap((x) => x.resources);
  const badPassengers = ratedResources
    .flatMap((x) => x.passengers)
    .filter(
      (x) =>
        x.status !== SeatStatus.Paid &&
        x.status !== SeatStatus.Rated &&
        x.status !== SeatStatus.Requested &&
        x.status !== SeatStatus.Fined &&
        x.status !== SeatStatus.Cancelled &&
        x.status !== SeatStatus.Forced &&
        x.status !== SeatStatus.Returned &&
        x.status !== SeatStatus.Rejected
    );
  const badServices = ratedResources
    .flatMap((x) => x.services || [])
    .filter(
      (x) =>
        x.status !== SeatStatus.Paid &&
        x.status !== SeatStatus.Rated &&
        x.status !== SeatStatus.Requested &&
        x.status !== SeatStatus.Fined &&
        x.status !== SeatStatus.Cancelled &&
        x.status !== SeatStatus.Forced &&
        x.status !== SeatStatus.Returned &&
        x.status !== SeatStatus.Rejected
    );
  const statuses = badPassengers
    .map((x) => x.status)
    .concat(badServices.map((x) => x.status));
  const revertible =
    ratedResources
      .flatMap((x) => x.passengers)
      .some((x) => x.status === SeatStatus.Rejected) ||
    ratedResources
      .filter((x) => x.services !== null)
      .flatMap((x) => x.services)
      .some((x) => x!.status === SeatStatus.Rejected);

  return (
    <FieldSet label={TXT("label.total")}>
      <Div layout="grid 12">
        {!readonly && statuses.length > 0 && (
          <Div>
            <Span intent="danger">{formatMessage(TXT("hint.unrated"))}</Span>
          </Div>
        )}
        {calc.status !== OrderStatus.Rejected &&
          calc.status !== OrderStatus.Cancelled &&
          calc.status !== OrderStatus.Completed &&
          calc.total > 0 && (
            <Div>
              <Span>
                {`${formatMessage(TXT("label.rate"))}: ${formatCost(
                  calc.total,
                  calc.currency.code
                )}`}
              </Span>
            </Div>
          )}
        {calc.status !== OrderStatus.Rejected &&
          calc.status !== OrderStatus.Cancelled &&
          calc.status !== OrderStatus.Completed &&
          calc.unpaid > 0 && (
            <Div>
              <Span intent="success">
                {`${formatMessage(TXT("label.rest"))}: ${formatCost(
                  calc.unpaid,
                  calc.currency.code
                )}`}
              </Span>
            </Div>
          )}
        {calc.status !== OrderStatus.Rejected &&
          calc.status !== OrderStatus.Cancelled &&
          calc.status !== OrderStatus.Completed &&
          calc.refund - calc.penalty > 0 && (
            <Div>
              <Span intent="success">
                {`${formatMessage(TXT("label.refund"))}: ${formatCost(
                  calc.refund - calc.penalty,
                  calc.currency.code
                )}`}
              </Span>
            </Div>
          )}
        {calc.status !== OrderStatus.Rejected &&
          calc.status !== OrderStatus.Cancelled &&
          calc.status !== OrderStatus.Completed &&
          calc.penalty > 0 && (
            <Div>
              <Span intent="danger">
                {`${formatMessage(TXT("label.fine"))}: ${formatCost(
                  calc.penalty,
                  calc.currency.code
                )}`}
              </Span>
            </Div>
          )}
        <FieldSet label={TXT("label.status")}>
          <Div>
            <Div layout="flex vertical-center">
              <Div layout="fill">
                {(calc.status === OrderStatus.Rated ||
                  calc.status === OrderStatus.Created ||
                  calc.status === OrderStatus.Paid ||
                  calc.status === OrderStatus.Forced ||
                  calc.status === OrderStatus.Pending ||
                  calc.status === OrderStatus.Visited) && (
                  <Div>
                    <Span intent="warning">{`${formatStatus(calc)}`}</Span>
                  </Div>
                )}
                {(calc.status === OrderStatus.Confirmed ||
                  calc.status === OrderStatus.Completed) && (
                  <Div>
                    <Span intent="success">
                      {`${formatStatus(calc)} (${
                        calc.paymentType
                          ? formatPaymentType(calc.paymentType)
                          : formatMessage(TXT("label.deposit"))
                      })`}
                    </Span>
                  </Div>
                )}
                {(calc.status === OrderStatus.Rejected ||
                  calc.status === OrderStatus.Cancelled) && (
                  <Div>
                    <Span intent="danger">{`${formatStatus(calc)}`}</Span>
                  </Div>
                )}
              </Div>
              <Div layout="fit">
                {(calc.status === OrderStatus.Confirmed || calc.status === OrderStatus.Visited)&& onVisit && (
                  <Button
                    primary={false}
                    look="outline"
                    className="action"
                    onClick={onVisit}
                  >
                    {formatMessage(TXT("action.visit"))}
                  </Button>
                )}
                {(calc.status === OrderStatus.Rejected || revertible) &&
                  onRevert && (
                    <Button
                      primary={false}
                      look="outline"
                      className="action"
                      onClick={onRevert}
                    >
                      {formatMessage(TXT("action.revert"))}
                    </Button>
                  )}
                {calc.status === OrderStatus.Created &&
                  calc.paidAt === null &&
                  onDiscard &&
                  readonly && (
                    <Button
                      intent="danger"
                      look="outline"
                      className="action"
                      onClick={onDiscard}
                    >
                      {formatMessage(TXT("action.cancel"))}
                    </Button>
                  )}
                {calc.status === OrderStatus.Created && onWithdraw && (
                  <Button
                    primary={true}
                    look="outline"
                    className="action"
                    onClick={onWithdraw}
                  >
                    {formatMessage(TXT("action.withdraw"))}
                  </Button>
                )}
                {calc.status === OrderStatus.Paid &&
                  onReject &&
                  (auth.profile.belongsTo === "system" ||
                    auth.profile.isOwner) &&
                  readonly && (
                    <Button
                      intent="danger"
                      look="outline"
                      className="action"
                      onClick={onReject}
                    >
                      {formatMessage(TXT("action.reject"))}
                    </Button>
                  )}
                {calc.status === OrderStatus.Paid &&
                  onConfirm &&
                  (auth.profile.belongsTo === "system" ||
                    auth.profile.isOwner) &&
                  readonly && (
                    <Button
                      primary={true}
                      look="outline"
                      className="action"
                      onClick={onConfirm}
                    >
                      {formatMessage(TXT("action.confirm"))}
                    </Button>
                  )}
                {(calc.status === OrderStatus.Confirmed ||
                  (calc.status === OrderStatus.Forced &&
                    (auth.profile.belongsTo === "system" ||
                      auth.profile.isOwner))) &&
                  onReturn &&
                  readonly && (
                    <Button
                      intent="warning"
                      look="outline"
                      className="action"
                      onClick={onReturn}
                    >
                      {formatMessage(
                        calc.status === OrderStatus.Confirmed
                          ? TXT("action.return")
                          : TXT("action.force")
                      )}
                    </Button>
                  )}
                {(calc.status === OrderStatus.Confirmed ||
                  calc.status === OrderStatus.Cancelled ||
                  calc.status === OrderStatus.Completed) &&
                  onDownload && (
                    <Button
                      primary={true}
                      look="outline"
                      className="action"
                      onClick={onDownload}
                    >
                      {formatMessage(TXT("label.voucher"))}
                    </Button>
                  )}
              </Div>
            </Div>
          </Div>
        </FieldSet>
      </Div>
    </FieldSet>
  );
}

export interface OrderItemProps
  extends ItemViewProps<
    Order,
    OrderCreateModel | OrderChangeModel | OrderUpdateModel
  > {
  performer: string;
  showProviders?: boolean;
  onCalc?: (item: OrderCreateModel | OrderChangeModel) => Promise<Order | null>;
  onDiscard?: () => void;
  onReject?: () => void;
  onConfirm?: () => void;
  onWithdraw?: () => void;
  onDownload?: () => void;
  onRevert?: () => void;
  onReturn?: () => void;
  onVisit?: () => void;
}

export function OrderItem(props: OrderItemProps): ReactElement {
  const {
    item,
    lock,
    readonly,
    performer,
    showProviders,
    onSubmit,
    onCancel,
    onEdit,
    onFree,
    onCalc,
    onDiscard,
    onReject,
    onConfirm,
    onWithdraw,
    onDownload,
    onRevert,
    onReturn,
    onVisit,
  } = props;

  const pageSize = 100;
  const id = item.id;
  const isNew = id === 0;
  const isPaid = item.paidAt !== null;

  const [uploadUrl, formatFileUrl] = useFileUrl();

  const auth = useContext(AuthContext);

  const formatCost = useFormatCost();
  const formatMessage = useFormatMessage();
  const [, formatFlightDirection] = useFlightDirections(true);

  const [calc, setCalc] = useField(isNew ? null : item);
  const [calculated, setCalculated] = useState(!isNew);

  const [belongsTo] = useOrganization(auth.profile.belongsTo);

  const [defaultResourceId] = useState(
    isNew
      ? firstOrNull(
          item.spans
            .flatMap((span) => span.resources)
            .map((resource) => resource.resource.id)
        )
      : null
  );
  const [defaultResource] = useResource(defaultResourceId);

  const [contract, setContract] = useOrganizationContract(
    item.contract && item.contract.id !== EMPTY_ID ? item.contract.id : null
  );
  const contractId = contract ? contract.id : null;

  const [depositContract] = useOrganizationContract(
    contract
      ? contract.type === OrganizationContractType.Deposit
        ? contract.id
        : contract.parent
        ? contract.parent.id
        : null
      : null
  );

  const [provider, setProvider] = useOrganization(
    isNew && belongsTo && belongsTo.type === OrganizationType.Provider
      ? belongsTo.id
      : !isNew
      ? depositContract?.provider.id ?? contract?.provider.id ?? null
      : null
  );
  const providerId = provider
    ? provider.id
    : depositContract?.provider.id ?? contract?.provider.id;

  const [agency] = useOrganization(
    !isNew && contract ? contract.agency.id : null
  );

  const providerFilter = useMemo(
    () => ({
      byType: auth.profile.isSys ? OrganizationType.Provider : undefined,
      byProviderId:
        belongsTo && belongsTo.type === OrganizationType.Provider
          ? belongsTo.id
          : undefined,
      byAgencyId:
        belongsTo && belongsTo.type === OrganizationType.Agency
          ? belongsTo?.id
          : undefined,
      isConcluded: true,
      isAuthorized: true,
    }),
    [belongsTo, auth.profile]
  );
  const [providers, getProviders] = useOrganizations(
    pageSize,
    isNew && !readonly && showProviders && !!belongsTo,
    providerFilter
  );

  const contractFilter = useMemo(
    () => ({
      byProviderId: providerId || undefined,
      byResourceId: defaultResourceId || undefined,
      isAuthorized: true,
      isActive: true,
      byType: OrganizationContractType.Direct,
    }),
    [providerId, defaultResourceId]
  );
  const [contracts, getContracts] = useOrganizationContracts(
    pageSize,
    isNew && !readonly && providerId !== null,
    contractFilter
  );

  const [types] = useOrderTypes();
  const [type, setType] = useField<Pair<OrderType> | null>(
    firstOrNull(types.filter((pair) => !isNew && pair.code === item.type))
  );
  const typeCode = type ? type.code : null;

  const [allCards, getCards] = useOrganizationProgramCards(
    pageSize,
    isNew && contract !== null && typeCode === OrderType.Program,
    depositContract !== null ? depositContract.id : undefined,
    providerId || undefined,
    undefined,
    true,
    depositContract === null
  );
  const [selCard, setCard] = useOrganizationProgramCard(
    item.card ? item.card.id : null
  );
  const [program] = useOrganizationProgram(selCard ? selCard.program.id : null);
  const [programSettings] = useOrganizationProgramSettings(
    selCard ? selCard.program.id : null
  );

  const [contactName, setContactName] = useField<string | null>(
    item.contactName || (isNew ? auth.profile.fullName : "")
  );
  const [contactEmail, setContactEmail] = useField<string | null>(
    item.contactEmail || (isNew ? auth.profile.email : "")
  );
  const [contactPhone, setContactPhone] = useField<string | null>(
    item.contactPhone || (isNew ? auth.profile.phone : "")
  );

  const [description, setDescription] = useField<string | null>(
    item.description
  );

  const [depositBalance] = useOrganizationContractBalance(
    depositContract?.type === OrganizationContractType.Deposit
      ? depositContract.id
      : null
  );
  const [depositOverdraft] = useOrganizationContractOverdraft(
    depositContract?.type === OrganizationContractType.Deposit
      ? depositContract.id
      : null
  );

  const depositBalanceView = Math.max(depositBalance || 0, 0);
  const depositOverdraftView =
    (depositBalance || 0) > 0
      ? depositOverdraft || 0
      : (depositOverdraft || 0) + (depositBalance || 0);

  const [airports, getAirports] = useAirports(
    pageSize,
    !readonly && contract !== null,
    contract?.id
  );
  const onlyAirport = useMemo(() => {
    if (airports.total === 1 && airports.data[0]) {
      return airports.data[0];
    }

    return null;
  }, [airports]);
  const [airport, setAirport] = useAirport(onlyAirport?.id || null);

  const [passengersValidationMessage, setPassengersValidationMessage] =
    useState("");
  const [passengers, setPassengers] = useField(item.passengers);
  const [resources, getResources] = useOrganizationContractResources(
    contractId,
    pageSize,
    !readonly,
    useMemo(
      () => [
        ServiceKind.VipLounge,
        ServiceKind.BusinessLounge,
        ServiceKind.ComfortLounge,
        ServiceKind.OfficialPersonsAndDelegationsLounge,
        ServiceKind.FastTrack,
        ServiceKind.MeetAndAssist,
        ServiceKind.MeetAndGreet,
        ServiceKind.Parking,
        ServiceKind.Transit,
        ServiceKind.VirtualLounge,
      ],
      []
    ),
    airport?.id
  );
  const [orderedResources, setOrderedResources] = useState(
    item.spans.flatMap((item) => item.resources)
  );
  useEffect(() => {
    setOrderedResources(item.spans.flatMap((item) => item.resources));
  }, [item.spans, setOrderedResources]);
  const [orderedResourceIndex, setOrderedResourceIndex] = useState(0);

  const [defaultsProvider] = useOrganization(
    firstOrNull(
      resources
        ? resources.data.map((resource) => resource.organization.id)
        : []
    )
  );
  const [defaults] = useOrganizationDefaults(
    defaultsProvider ? defaultsProvider.id : null
  );

  const [attachments, setAttachments] = useState<UploadedFile[]>([]);
  useEffect(() => {
    setAttachments(item.attachments || []);
  }, [item.attachments]);

  useEffect(() => {
    if (isNew) {
      setContract(null);
    }
  }, [isNew, providerId, setContract]);

  useEffect(() => {
    if (isNew) {
      setCalc(null);
      setCalculated(false);

      setCard(null);

      if (typeCode === OrderType.Standard) {
        setPassengers((prev) => {
          const curr = [...prev];
          curr.forEach((item) => (item.type = PassengerType.Primary));

          return curr;
        });
      }
    }
  }, [isNew, typeCode, setPassengers, setCalc, setCalculated, setCard]);

  useEffect(() => {
    if (isNew && selCard && program && programSettings) {
      setCalc(null);
      setCalculated(false);

      setPassengers((prev) => {
        const curr: Passenger[] = [];
        for (const item of prev) {
          if (
            selCard.people &&
            !curr.some(
              (pass) =>
                pass.familyName === item.familyName &&
                pass.givenName === item.givenName &&
                pass.middleName === item.middleName &&
                pass.dateOfBirth === item.dateOfBirth
            ) &&
            selCard.people.some(
              (pers) =>
                pers.familyName === item.familyName &&
                pers.givenName === item.givenName &&
                pers.middleName === item.middleName &&
                pers.dateOfBirth === item.dateOfBirth
            )
          ) {
            curr.push({ ...item, type: PassengerType.Primary });
          } else if (
            programSettings.availableServicePersonCount === null ||
            programSettings.availableServicePersonCount >
              curr.filter((pass) => pass.type === PassengerType.Secondary)
                .length
          ) {
            curr.push({ ...item, type: PassengerType.Secondary });
          } else if (
            programSettings.availableGuestCount === null ||
            programSettings.availableGuestCount >
              curr.filter((pass) => pass.type === PassengerType.Guest).length
          ) {
            curr.push({ ...item, type: PassengerType.Guest });
          }
        }

        return curr;
      });
    }
  }, [
    isNew,
    selCard,
    setCalc,
    setCalculated,
    program,
    programSettings,
    setPassengers,
  ]);

  useEffect(() => {
    if (isNew && selCard !== null) {
      setContactName(selCard.contactName);
      setContactEmail(selCard.contactEmail);
      setContactPhone(selCard.contactPhone);
    }
  }, [isNew, selCard, setContactName, setContactEmail, setContactPhone]);

  const handlePassengersChange = useCallback(
    (value: Passenger[]): void => {
      setPassengers(value);

      setCalc(null);
      setCalculated(false);
      setPassengersValidationMessage("");
    },
    [setPassengers, setCalc, setCalculated, setPassengersValidationMessage]
  );

  const handleResourceAdd = useCallback(
    (value: Resource[]): void => {
      if (value.length === 0) return;

      const resource = value[0];

      const orderedFlight1 = new RatedFlight();
      orderedFlight1.type =
        resource.type !== ServiceType.Any ? resource.type : ServiceType.Any;
      if (resource.kind === ServiceKind.VirtualLounge) {
        orderedFlight1.type = ServiceType.Departure;
        orderedFlight1.date = DateTime.utc()
          .set({ minute: 0 })
          .plus({ hour: 1 })
          .toISO();
        orderedFlight1.number = "UNKNOWN";
      }

      const orderedFlight2 =
        resource.kind === ServiceKind.Transit ? new RatedFlight() : undefined;
      if (orderedFlight2) {
        orderedFlight2.type = ServiceType.Departure;
      }

      const orderedResource = new RatedResource();
      orderedResource.resource = value[0];
      orderedResource.flights = [orderedFlight1];
      if (orderedFlight2) {
        orderedResource.flights.push(orderedFlight2);
      }
      setOrderedResources((prev) => [...prev, orderedResource]);
      setOrderedResourceIndex(orderedResources.length);

      setCalc(null);
      setCalculated(false);
    },
    [
      orderedResources,
      setOrderedResources,
      setOrderedResourceIndex,
      setCalc,
      setCalculated,
    ]
  );

  const handleResourceChange = useCallback(
    (value: RatedResource | null, index: number): void => {
      setOrderedResources((prev) => {
        const existed = [...prev];
        if (value) {
          existed.splice(index, 1, value);
        } else {
          existed.splice(index, 1);
        }

        return existed;
      });

      setCalc(null);
      setCalculated(false);
    },
    [setOrderedResources, setCalc, setCalculated]
  );

  const validatePassengers = useCallback((): string | undefined => {
    // const haveAdult = passengers
    //   .filter(item => (item.type === PassengerType.Primary || item.type === PassengerType.Guest) &&
    //     isInAgeRange(item.dateOfBirth, defaults ? defaults.infantAge + 1 : null, null)).length > 0
    const haveSecondaryWithPrimary = passengers.some(
      (item) => item.type === PassengerType.Secondary
    )
      ? passengers.some((item) => item.type === PassengerType.Primary)
      : true;
    const notEmpty = passengers.length > 0;

    // if (!notEmpty || !haveAdult) return formatMessage(TXT('error.orderMustContainsAdult'))

    if (!notEmpty)
      return formatMessage(TXT("error.orderMustContainsPassenger"));

    if (!haveSecondaryWithPrimary)
      return formatMessage(TXT("error.orderMustContainsSecondaryWithPrimary"));

    return undefined;
  }, [passengers, formatMessage]);

  const isPassengerNotVisited = useCallback(
    (passenger: Passenger): boolean => {
      return item.spans
        .flatMap((x) => x.resources)
        .flatMap((x) => x.passengers)
        .filter((x) => x.passenger.id === passenger.id)
        .every((x) => x.visitedFrom === null && x.visitedTill === null);
    },
    [item]
  );

  const getCreateModel = useCallback((): OrderCreateModel => {
    const item = new OrderCreateModel();
    item.passengers = passengers;
    item.resources = orderedResources;
    item.contactName = contactName || "";
    item.contactEmail = contactEmail || "";
    item.contactPhone = contactPhone || "";
    item.description = description;
    item.contract =
      contract ||
      new OrganizationContract(
        OrganizationReference,
        PoolReference,
        CurrencyReference
      );
    item.card = selCard;
    item.type = type ? type.code : OrderType.Standard;
    item.attachments = attachments.length > 0 ? attachments : null;

    return item;
  }, [
    passengers,
    orderedResources,
    contactName,
    contactEmail,
    contactPhone,
    description,
    contract,
    selCard,
    type,
    attachments,
  ]);

  const getUpdateModel = useCallback((): OrderUpdateModel => {
    const item = new OrderUpdateModel();
    item.passengers = passengers;
    item.resources = orderedResources.map((ratedResource) => {
      const updatedResource = new UpdatedResource();
      updatedResource.id = ratedResource.id;
      updatedResource.resource = ratedResource.resource;
      updatedResource.flights = ratedResource.flights;
      updatedResource.services = (ratedResource.services || []).map(
        (ratedService) => {
          const updatedService = new UpdatedService();
          updatedService.id = ratedService.id;
          updatedService.passenger = ratedService.passenger;
          updatedService.service = ratedService.service;
          updatedService.fields = ratedService.fields;
          updatedService.count = ratedService.count;
          updatedService.from = ratedService.from;
          updatedService.till = ratedService.till;
          updatedService.departureTime = ratedService.departureTime;
          updatedService.destinationPoint = ratedService.destinationPoint;
          updatedService.destinationPointType =
            ratedService.destinationPointType;
          updatedService.return =
            ratedService.status === SeatStatus.Fined ||
            ratedService.status === SeatStatus.Forced ||
            (ratedService.service.type !== ServiceType.Any &&
              ratedService.service.type !== updatedResource.flights[0].type)
              ? true
              : null;

          return updatedService;
        }
      );

      return updatedResource;
    });
    item.contactName = contactName || "";
    item.contactEmail = contactEmail || "";
    item.contactPhone = contactPhone || "";
    item.description = description;
    item.attachments = attachments.length > 0 ? attachments : null;

    return item;
  }, [
    passengers,
    orderedResources,
    contactName,
    contactEmail,
    contactPhone,
    description,
    attachments,
  ]);

  const handleCalc = useCallback(async (): Promise<void> => {
    const v = validatePassengers();
    setPassengersValidationMessage(v || "");
    if (!v && onCalc) {
      const calc = await onCalc(!isPaid ? getCreateModel() : getUpdateModel());
      setCalc(calc);
      setCalculated(calc !== null);
    }
  }, [
    isPaid,
    validatePassengers,
    getCreateModel,
    getUpdateModel,
    onCalc,
    setCalc,
    setCalculated,
    setPassengersValidationMessage,
  ]);

  const handleRevert = useCallback(async (): Promise<void> => {
    if (onDiscard) {
      await onDiscard();
    }
  }, [onDiscard]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit && calculated) {
      await onSubmit(!isPaid ? getCreateModel() : getUpdateModel(), false);
    }
  }, [calculated, isPaid, getCreateModel, getUpdateModel, onSubmit]);

  const canSubmit =
    calculated &&
    calc &&
    (calc.status === OrderStatus.Created ||
      calc.status === OrderStatus.Confirmed ||
      calc.status === OrderStatus.Paid ||
      calc.status === OrderStatus.Forced);

  const handleUploadAttachment = useCallback(
    (files: UploadedFile[]): void => {
      setAttachments((prev) => [...prev, ...files]);
    },
    [setAttachments]
  );

  const handleRemoveAttachment = useCallback(
    (fileIndex: number): void => {
      setAttachments((prev) => {
        const next = [...prev];
        next.splice(fileIndex, 1);

        return next;
      });
    },
    [setAttachments]
  );

  return (
    <Form
      lock={lock}
      loaded={true}
      readonly={readonly}
      labelSubmit={!canSubmit ? TXT("action.calculate") : undefined}
      onSubmit={onSubmit ? (canSubmit ? handleSubmit : handleCalc) : undefined}
      onCancel={onCancel}
      onEdit={onEdit}
      onFree={onFree}
    >
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12">
              {!isNew && (
                <Div>
                  <Div layout="grid 12 3@lg">
                    <Div>
                      <DateBox
                        label={TXT("label.createdAt")}
                        readonly={true}
                        fill={true}
                        time={true}
                        name="createdAt"
                        value={item.createdAt}
                      />
                    </Div>
                    <Div>
                      <TextBox
                        label={TXT("label.createdBy")}
                        readonly={true}
                        fill={true}
                        name="createdBy"
                        value={performer}
                      />
                    </Div>
                    {contract &&
                      contract.agency.id !== contract.provider.id && (
                        <Div>
                          <TextBox
                            label={TXT("label.agency")}
                            readonly={true}
                            fill={true}
                            name="agency"
                            value={agency ? agency.name : null}
                          />
                        </Div>
                      )}
                  </Div>
                </Div>
              )}
              <Div>
                <Div layout="grid 12 3@lg">
                  {showProviders && (
                    <Div>
                      <ComboBox
                        label={TXT("label.provider")}
                        disabled={!isNew && !readonly}
                        fill={true}
                        searchable={true}
                        pageSize={pageSize}
                        name="provider"
                        data={providers}
                        value={provider}
                        valueKey="id"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setProvider}
                        onFetch={getProviders}
                      />
                    </Div>
                  )}
                  <Div>
                    <ComboBox
                      label={TXT("label.contract")}
                      disabled={!isNew && !readonly}
                      fill={true}
                      searchable={true}
                      pageSize={pageSize}
                      name="contract"
                      data={contracts}
                      value={contract}
                      valueKey="id"
                      valueLabel="number"
                      validators={[required]}
                      onChange={setContract}
                      onFetch={getContracts}
                    />
                  </Div>
                  <Div>
                    <ComboBox
                      label={TXT("label.orderType")}
                      disabled={!isNew && !readonly}
                      fill={true}
                      name="type"
                      data={types.filter(
                        (pair) =>
                          pair.code === OrderType.Standard ||
                          pair.code === OrderType.Program
                      )}
                      value={type}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setType}
                    />
                  </Div>
                  {type && type.code === OrderType.Program && (
                    <Div>
                      <ComboBox
                        label={TXT("label.cardNumber")}
                        disabled={!isNew && !readonly}
                        fill={true}
                        name="card"
                        data={allCards}
                        value={selCard}
                        valueKey="id"
                        valueLabel="number"
                        validators={[required]}
                        onChange={setCard}
                        onFetch={getCards}
                      />
                    </Div>
                  )}
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {!readonly &&
          depositContract &&
          depositBalance !== null &&
          depositOverdraft !== null && (
            <Div>
              <FieldSet label={TXT("label.deposit")}>
                <Div layout="grid 12 3@lg">
                  <Div>
                    <TextBox
                      label={TXT("label.balance")}
                      fill={true}
                      readonly={true}
                      name="depositBalance"
                      value={formatCost(
                        depositBalanceView,
                        depositContract.currency.code
                      )}
                    />
                  </Div>
                  <Div>
                    <TextBox
                      label={TXT("label.overdraft")}
                      fill={true}
                      readonly={true}
                      name="depositOverdraft"
                      value={formatCost(
                        depositOverdraftView,
                        depositContract.currency.code
                      )}
                    />
                  </Div>
                </Div>
              </FieldSet>
            </Div>
          )}
        {type &&
          type.code === OrderType.Program &&
          selCard &&
          selCard.people &&
          program &&
          programSettings &&
          program.type === OrganizationProgramType.Individual &&
          (programSettings.availablePrimaryPersonCount === null ||
            programSettings.availablePrimaryPersonCount > 0) &&
          (!readonly ||
            passengers.filter((item) => item.type === PassengerType.Primary)
              .length > 0) && (
            <Div>
              <FieldSet label={TXT("label.primaryPassengers")}>
                <Validator
                  name="passengers"
                  value={passengersValidationMessage}
                  validators={[(value) => value]}
                >
                  <OrderedPassengerList
                    name="primaryPassengers"
                    type={PassengerType.Primary}
                    data={
                      programSettings.availablePrimaryPersonCount !== 0
                        ? selCard.people || []
                        : undefined
                    }
                    calc={calc}
                    limit={programSettings.availablePrimaryPersonCount}
                    value={passengers}
                    canRemove={!isPaid}
                    canChange={isPassengerNotVisited}
                    ageOfInfant={defaults ? defaults.infantAge : undefined}
                    ageOfChild={defaults ? defaults.childAge : undefined}
                    requireEmail={
                      defaults ? defaults.passengerEMailRequired : undefined
                    }
                    requirePhone={
                      defaults ? defaults.passengerPhoneRequired : undefined
                    }
                    onChange={handlePassengersChange}
                  />
                </Validator>
              </FieldSet>
            </Div>
          )}
        {type &&
          type.code === OrderType.Program &&
          program &&
          programSettings &&
          program.type === OrganizationProgramType.Individual &&
          (programSettings.availablePrimaryPersonCount == null ||
            programSettings.availablePrimaryPersonCount > 0) &&
          (programSettings.availableSecondaryPersonCount === null ||
            programSettings.availableSecondaryPersonCount > 0) &&
          (!readonly ||
            passengers.filter((item) => item.type === PassengerType.Secondary)
              .length > 0) && (
            <Div>
              <FieldSet label={TXT("label.secondaryPassengers")}>
                <OrderedPassengerList
                  name="secondaryPassengers"
                  type={PassengerType.Secondary}
                  calc={calc}
                  limit={programSettings.availableSecondaryPersonCount}
                  value={passengers}
                  canRemove={!isPaid}
                  canChange={isPassengerNotVisited}
                  ageOfInfant={defaults ? defaults.infantAge : undefined}
                  ageOfChild={defaults ? defaults.childAge : undefined}
                  requireEmail={
                    defaults ? defaults.passengerEMailRequired : undefined
                  }
                  requirePhone={
                    defaults ? defaults.passengerPhoneRequired : undefined
                  }
                  onChange={handlePassengersChange}
                />
              </FieldSet>
            </Div>
          )}
        {type &&
          type.code === OrderType.Program &&
          program &&
          programSettings &&
          (programSettings.availableGuestCount === null ||
            programSettings.availableGuestCount > 0) &&
          (!readonly ||
            passengers.filter((item) => item.type === PassengerType.Guest)
              .length > 0) && (
            <Div>
              <FieldSet label={TXT("label.guestPassengers")}>
                <Validator
                  name="passengers"
                  value={passengersValidationMessage}
                  validators={[(value) => value]}
                >
                  <OrderedPassengerList
                    name="guestPassengers"
                    type={PassengerType.Guest}
                    calc={calc}
                    limit={programSettings.availableGuestCount}
                    value={passengers}
                    canRemove={!isPaid}
                    canChange={isPassengerNotVisited}
                    ageOfInfant={defaults ? defaults.infantAge : undefined}
                    ageOfChild={defaults ? defaults.childAge : undefined}
                    requireEmail={
                      defaults ? defaults.passengerEMailRequired : undefined
                    }
                    requirePhone={
                      defaults ? defaults.passengerPhoneRequired : undefined
                    }
                    onChange={handlePassengersChange}
                  />
                </Validator>
              </FieldSet>
            </Div>
          )}
        {type &&
          type.code === OrderType.Program &&
          program &&
          programSettings &&
          (programSettings.availableServicePersonCount === null ||
            programSettings.availableServicePersonCount > 0) &&
          (!readonly ||
            passengers.filter((item) => item.type === PassengerType.Assistant)
              .length > 0) && (
            <Div>
              <FieldSet label={TXT("label.assistantPassengers")}>
                <Validator
                  name="passengers"
                  value={passengersValidationMessage}
                  validators={[(value) => value]}
                >
                  <OrderedPassengerList
                    name="servicePassengers"
                    type={PassengerType.Assistant}
                    calc={calc}
                    limit={programSettings.availableServicePersonCount}
                    value={passengers}
                    canRemove={!isPaid}
                    canChange={isPassengerNotVisited}
                    onChange={handlePassengersChange}
                  />
                </Validator>
              </FieldSet>
            </Div>
          )}
        {type && typeCode !== OrderType.Program && (
          <Div>
            <FieldSet label={TXT("label.primaryPassengers")}>
              <Validator
                name="passengers"
                value={passengersValidationMessage}
                validators={[(value) => value]}
              >
                <OrderedPassengerList
                  name="primaryPassengers"
                  calc={calc}
                  value={passengers}
                  canRemove={!isPaid}
                  canChange={isPassengerNotVisited}
                  ageOfInfant={defaults ? defaults.infantAge : undefined}
                  ageOfChild={defaults ? defaults.childAge : undefined}
                  requireEmail={
                    defaults ? defaults.passengerEMailRequired : undefined
                  }
                  requirePhone={
                    defaults ? defaults.passengerPhoneRequired : undefined
                  }
                  onChange={handlePassengersChange}
                />
              </Validator>
            </FieldSet>
          </Div>
        )}
        <Div>
          <FieldSet label={TXT("label.resources")}>
            <Div layout="grid 12">
              {!readonly && (
                <Div>
                  <Div layout={airports.total > 1 ? "grid 12 6@lg" : "grid 12"}>
                    {airports.total > 1 && (
                      <Div>
                        <ComboBox
                          name="airport"
                          label={TXT("label.airport")}
                          disabled={
                            type !== null &&
                            type.code === OrderType.Business &&
                            orderedResources.length >= 1
                          }
                          fill={true}
                          pageSize={pageSize}
                          data={airports}
                          value={airport}
                          valueKey="id"
                          valueLabel={"name"}
                          onChange={setAirport}
                          onFetch={getAirports}
                        />
                      </Div>
                    )}
                    <Div>
                      <Validator
                        name="resource"
                        value={orderedResources.length}
                        validators={[
                          (value) =>
                            value < 1
                              ? TXT("error.minimumOneResourceRequired")
                              : undefined,
                        ]}
                      >
                        <TagsBox
                          name="resource"
                          label={" "}
                          placeholder={TXT("label.search")}
                          disabled={
                            type !== null &&
                            type.code === OrderType.Business &&
                            orderedResources.length >= 1
                          }
                          fill={true}
                          autoClose={true}
                          pageSize={pageSize}
                          data={resources}
                          value={[]}
                          valueKey="id"
                          valueLabel={(value) =>
                            `${value.name} (${formatFlightDirection(
                              value.flightDirection
                            )})`
                          }
                          onChange={handleResourceAdd}
                          onFetch={getResources}
                        />
                      </Validator>
                    </Div>
                  </Div>
                </Div>
              )}
              <Div>
                <OrderedResourceList
                  name="orderedResources"
                  readonly={readonly}
                  rated={calculated}
                  passengers={passengers}
                  orderType={type ? type.code : null}
                  contract={contract ? contract : null}
                  calc={item}
                  data={orderedResources}
                  selected={orderedResourceIndex}
                  onChange={handleResourceChange}
                  onSelect={setOrderedResourceIndex}
                />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT("label.contactInformation")}>
            <Div layout="grid 12 4@lg">
              <Div>
                <TextBox
                  label={TXT("label.fullName")}
                  fill={true}
                  name="contactName"
                  value={contactName}
                  validators={[(value) => length(value, 3, 256)]}
                  onChange={setContactName}
                />
              </Div>
              <Div>
                <TextBox
                  label={TXT("label.email")}
                  fill={true}
                  name="contactEmail"
                  value={contactEmail}
                  validators={[email, (value) => length(value, 3, 100)]}
                  onChange={setContactEmail}
                />
              </Div>
              <Div>
                <TextBox
                  label={TXT("label.phone")}
                  fill={true}
                  mask="(999) 000-00-00"
                  name="contactPhone"
                  value={contactPhone}
                  validators={[phone]}
                  onChange={setContactPhone}
                />
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT("label.comments")}>
            <TextArea
              fill={true}
              name="description"
              value={description}
              onChange={setDescription}
            />
            {!readonly && (
              <Div>
                <Uploader
                  uploadUrl={uploadUrl}
                  showFileList={false}
                  onUploaded={handleUploadAttachment}
                />
              </Div>
            )}
            <Div>
              {attachments.map((x, i) => (
                <Div key={i}>
                  <Div layout="flex vertical-center gap-row-none">
                    <Div layout="fill">
                      <a href={formatFileUrl(x.id)}>{x.name}</a>
                    </Div>
                    {!readonly && (
                      <Div layout="fit">
                        <Button
                          look="bare"
                          icon="trash"
                          onClick={() => handleRemoveAttachment(i)}
                        />
                      </Div>
                    )}
                  </Div>
                </Div>
              ))}
            </Div>
          </FieldSet>
        </Div>
        {calc && (
          <Div>
            <OrderTotal
              readonly={readonly}
              calc={calc}
              onDiscard={onDiscard ? handleRevert : undefined}
              onReject={onReject}
              onConfirm={onConfirm}
              onWithdraw={onWithdraw}
              onDownload={onDownload}
              onRevert={onRevert}
              onReturn={onReturn}
              onVisit={onVisit}
            />
          </Div>
        )}
      </Div>
    </Form>
  );
}
