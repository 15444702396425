import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Measure, { ContentRect } from 'react-measure'
import { Chunk, Pair } from '../../data'
import { User, UserCreateModel } from '../../data/models'
import { Confirm, FieldSet, Form } from '../../gears'
import { ComboBox, TextBox } from '../../gears/inputs'
import { email as emailValidator, length, phone as phoneValidator, required } from '../../gears/validators'
import { EMPTY_ID, OrganizationBase, OrganizationType } from '../data/models'
import { Button, Div, formatOrderBy, Grid, GridColumn, ItemActionHandler, ItemCreateHandler, ItemSearchHandler, parseOrderBy, replaceSortOrder, TXT } from '../gears'
import { useDelay, useField, useFormatMessage, useFormatPassenger, useOrganizations, useOrganizationTypes, useUserExists, useUserRolesV1, useWindowSize } from '../hooks'
import { GridViewProps, ItemViewProps } from './types'

export interface UserItemProps extends ItemViewProps<User, UserCreateModel> {
  showOrganizations?: boolean;
  onReset?: ItemActionHandler;
}

export function UserItem(props: UserItemProps): ReactElement {
  const { item, lock, readonly, showOrganizations, onSubmit, onCancel, onDelete, onReset, onEdit, onFree } = props;

  const pageSize = 100;
  const id = item.id;
  const isNew = id === "";

  const formatMessage = useFormatMessage();

  const [ types, ] = useOrganizationTypes();
  const [ type, setType ] = useField<Pair<OrganizationType> | null>(types.filter(pair => pair.code === item.belongsTo.type)[0] || null);

  const [ organizations, getOrganizations ] = useOrganizations(pageSize, isNew && type !== null && type.code !== OrganizationType.System, useMemo(() => ({ byType: type !== null ? type.code : undefined }), [ type ]));
  const [ organization, setOrganization ] = useField<OrganizationBase | null>(item.belongsTo.id !== EMPTY_ID ? item.belongsTo : null);

  const [ roles, ] = useUserRolesV1();
  const [ role, setRole ] = useField<Pair | null>(roles.filter(pair => pair.code === item.role)[0] || null);

  const [ name, setName ] = useField<string | null>(item.name);
  const [ email, setEmail ] = useField<string | null>(item.email);
  const [ phone, setPhone ] = useField<string | null>(item.phone);
  const [ familyName, setFamilyName ] = useField<string | null>(item.familyName);
  const [ givenName, setGivenName ] = useField<string | null>(item.givenName);
  const [ middleName, setMiddleName ] = useField<string | null>(item.middleName);

  const userExists = useUserExists(type !== null ? type.code === OrganizationType.System ? "system" : organization !== null ? organization.code : null : null, name);
  const [ passwordResetToggled, setPasswordResetToggled ] = useState(false);

  useEffect(() => {
    if (!readonly && isNew && showOrganizations) {
      setOrganization(null);
      setRole(null);
    }
  }, [ readonly, isNew, showOrganizations, type, setOrganization, setRole ]);

  const getItem = useCallback((): UserCreateModel => {
    const item = new UserCreateModel();
    item.name = name || "";
    item.role = role ? role.code : "";
    item.belongsTo = organization ? organization.code : "system";
    item.email = email || "";
    item.phone = phone || "";
    item.familyName = familyName || "";
    item.givenName = givenName || "";
    item.middleName = middleName;

    return item;
  }, [
    name, role, organization, email, phone, familyName, givenName, middleName,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      await onSubmit(getItem());
    }
  }, [ getItem, onSubmit ]);

  const handlePasswordReset = useCallback(async (confirmed: boolean): Promise<void> => {
    if (onReset && confirmed) {
      await onReset();
    }
    setPasswordResetToggled(false);
  }, [ setPasswordResetToggled, onReset ]);

  const togglePasswordReset = useCallback((): void => {
    setPasswordResetToggled(value => !value);
  }, [ setPasswordResetToggled ]);

  return (
    <Form loaded={true}
          lock={lock}
          readonly={readonly}
          onSubmit={onSubmit ? handleSubmit : undefined}
          onCancel={onCancel}
          onDelete={onDelete}
          onEdit={onEdit}
          onFree={onFree}>
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12">
              {showOrganizations &&
              <Div>
                <Div layout="grid 12 3@md">
                  <Div>
                    <ComboBox label={TXT("label.type")}
                              disabled={!readonly && !isNew}
                              fill={true}
                              name="type"
                              data={types}
                              value={type}
                              valueKey="code"
                              valueLabel="name"
                              validators={[ required ]}
                              onChange={setType} />
                  </Div>
                  {type && type.code !== OrganizationType.System &&
                  <Div>
                    <ComboBox label={TXT("label.organization")}
                              disabled={!readonly && !isNew}
                              fill={true}
                              searchable={true}
                              pageSize={pageSize}
                              name="organization"
                              data={organizations}
                              value={organization}
                              valueKey="id"
                              valueLabel="name"
                              validators={[ required ]}
                              onChange={setOrganization}
                              onFetch={getOrganizations} />
                  </Div>
                  }

                </Div>
              </Div>
              }
              <Div>
                <Div layout="grid 12 3@lg">
                  <Div>
                    <ComboBox label={TXT("label.role")}
                              fill={true}
                              name="role"
                              data={roles.filter(pair => type !== null && ((type.code === OrganizationType.System && pair.code.startsWith("system.")) || (type.code === OrganizationType.Provider && pair.code.startsWith("owner.")) || (type.code === OrganizationType.Agency && pair.code.startsWith("agent."))))}
                              value={role}
                              valueKey="code"
                              valueLabel="name"
                              validators={[ required ]}
                              onChange={setRole} />
                  </Div>
                  <Div>
                    <TextBox label={TXT("label.login")}
                             disabled={!readonly && !isNew}
                             hint={isNew ? TXT("hint.userName") : undefined}
                             fill={true}
                             name="name"
                             mask={"A".repeat(128)}
                             value={!isNew && organization ? `${organization.code}/${name}` : name}
                             error={isNew && userExists ? TXT("hint.userAlreadyExists") : undefined}
                             validators={[ required, value => length(value, 3, 128) ]}
                             onChange={setName} />
                  </Div>
                   <Div>
                     <TextBox label={TXT("label.email")}
                              hint={email && (!item.confirmed || email !== item.email) ? TXT("hint.needsConfirmation") : undefined}
                              fill={true}
                              name="email"
                              value={email}
                              validators={[ required, emailValidator, value => length(value, 3, 256) ]}
                              onChange={setEmail} />
                   </Div>
                   <Div>
                     <TextBox label={TXT("label.phone")}
                              fill={true}
                              mask="(999) 000-00-00"
                              name="phone"
                              value={phone}
                              validators={[ required, phoneValidator ]}
                              onChange={setPhone} />
                   </Div>
                  <Div>
                    <TextBox label={TXT("label.familyName")}
                             fill={true}
                             name="familyName"
                             value={familyName}
                             validators={[ required, value => length(value, 3, 256)]}
                             onChange={setFamilyName} />
                  </Div>
                  <Div>
                    <TextBox label={TXT("label.givenName")}
                             fill={true}
                             name="givenName"
                             value={givenName}
                             validators={[ required, value => length(value, 3, 256)]}
                             onChange={setGivenName} />
                  </Div>
                  <Div>
                    <TextBox label={TXT("label.middleName")}
                             fill={true}
                             name="middleName"
                             value={middleName}
                             validators={[ value => length(value, 3, 256)]}
                             onChange={setMiddleName} />
                  </Div>
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        {readonly &&
        <Div>
          <Div layout="flex">
            <Div layout="fill" />
            <Div layout="fit">
              <Button className={"action"} look="bare" primary={true} onClick={togglePasswordReset}>
                {formatMessage(TXT("action.resetPassword"))}
              </Button>
            </Div>
          </Div>
          {passwordResetToggled &&
          <Confirm title={TXT("prompt.resetPassword.title")}
                   message={TXT("prompt.resetPassword.message", { email })}
                   onConfirm={handlePasswordReset} />
          }
        </Div>
        }
      </Div>
    </Form>
  );
}

export interface UserGridProps extends GridViewProps<User> {
  showOrganizations?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemSearch?: ItemSearchHandler;
}

export function UserGrid(props: UserGridProps): ReactElement {
  const { data, showOrganizations, onItemCreate, onItemSearch, onItemSelect, onPageChange, onSortChange } = props;

  const formatName = useFormatPassenger()
  const [ , formatRole ] = useUserRolesV1();

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null);
  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        id: item.id,
        login: `${item.belongsTo.code}/${item.name}`,
        email: item.email,
        phone: item.phone,
        role: formatRole(item.role),
        belongsTo: { name: item.belongsTo.name },
        fullName: formatName(item)
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatRole, formatName, setProcessedData ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [ delayedPattern, onItemSearch ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  const handleItemSelect = useCallback((item: { id: string }): void => {
    const originalItem = data.data.filter(i => i.id === item.id)[0];
    if (onItemSelect && originalItem) {
      onItemSelect(originalItem);
    }
  }, [ data.data, onItemSelect ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    if (onSortChange) {
      const sortOrder = parseOrderBy(orderBy);
      replaceSortOrder(sortOrder, "login", "belongsTo", "name");

      onSortChange(formatOrderBy(sortOrder));
    }
  }, [ onSortChange ]);

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) &&
      <Div>
        <Div layout="flex">
          {onItemSearch &&
          <Div layout="fill">
            <TextBox placeholder={TXT("label.search")}
                     fill={true}
                     name="search-pattern"
                     value={currentPattern}
                     onChange={setPattern} />
          </Div>
          }
          {onItemCreate &&
          <Div layout="fit">
            <Button primary={true} className="action" onClick={onItemCreate}>
              <FormattedMessage {...TXT("action.create")} />
            </Button>
          </Div>
          }
        </Div>
      </Div>
      }
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={`${width}px`}
                  onItemSelect={onItemSelect ? handleItemSelect : undefined}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange ? handleSortChange : undefined}>
              <GridColumn title={TXT("label.login")} field="login" width="256px" locked={true} sortable={false} />
              <GridColumn title={TXT("label.fullName")} field="fullName" width={`${Math.max(width - 256 - 256 * 3 - (showOrganizations ? 256 : 0), 256)}px`} sortable={false} />
              <GridColumn title={TXT("label.role")} field="role" width="256px" sortable={false} />
              <GridColumn title={TXT("label.email")} field="email" width="256px" sortable={false} />
              <GridColumn title={TXT("label.phone")} field="phone" width="256px" sortable={false} />
              {showOrganizations &&
              <GridColumn title={TXT("label.organization")} field="belongsTo.name" width="256px" sortable={false} />
              }
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}
