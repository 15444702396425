import React, { ReactElement, useCallback, useEffect } from "react";
import { SwitchBox } from "../../gears/inputs";
import {
  FlightUpdateModel,
  Order,
  RatedPassenger,
  RatedResource,
  SeatUpdateModel,
} from "../data/models";
import { Button, Div, TXT } from "../gears";
import { useFormatDate, useFormatDateRange, useFormatMessage } from "../hooks";
import { ViewProps } from "./types";

export interface OrderVisitViewProps extends ViewProps<null> {
  calc: Order;
  data: FlightUpdateModel[];
  onChange: (data: FlightUpdateModel[]) => void;
}

export function OrderVisitView(props: OrderVisitViewProps): ReactElement {
  const { calc, data, onChange } = props;

  const formatDate = useFormatDate();
  const formatDateRange = useFormatDateRange();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    if (data.length > 0) return;
    onChange(
      calc.spans
        .flatMap((span) => span.resources)
        .map((resource) => {
          const flight = new FlightUpdateModel();
          flight.id = resource.id;
          flight.seats = resource.passengers.map((ratedPassenger) => {
            const seat = new SeatUpdateModel();
            const passenger = calc.passengers.filter((x) => x.id === ratedPassenger.passenger.id)[0]
            seat.id = ratedPassenger.id;
            seat.dateOfBirth = passenger.dateOfBirth
            seat.familyName = passenger.familyName
            seat.givenName = passenger.givenName
            seat.middleName = passenger.middleName
            seat.ticket = passenger.ticket
            seat.visitedFrom = ratedPassenger.visitedFrom;
            seat.visitedTill = ratedPassenger.visitedTill;

            return seat;
          });

          return flight;
        })
    );
  }, [calc, onChange]);

  const renderResource = useCallback(
    (
      value: FlightUpdateModel[],
      ratedResource: RatedResource,
      ratedResourceIndex: number
    ): ReactElement | null => {
      const handleChange = (confirmed: boolean): void => {
        const next = [...value];
        for (const seat of next[ratedResourceIndex].seats) {
          seat.visitedFrom = confirmed ? new Date().toISOString() : null;
        }
        onChange(next);
      };

      if (value.length === 0) return null;

      return (
        <SwitchBox
          label={`${ratedResource.resource.name} / ${
            ratedResource.flights[0].number
          } / ${formatDate(ratedResource.flights[0].date, true)}`}
          mode="check"
          name={`resource-${ratedResourceIndex}-visited`}
          value={value[ratedResourceIndex].seats.some((x) => x.visitedFrom)}
          onChange={handleChange}
        />
      );
    },
    [calc.spans, formatDate, onChange]
  );

  const renderPassenger = useCallback(
    (
      value: FlightUpdateModel[],
      ratedResource: RatedResource,
      ratedResourceIndex: number,
      ratedPassenger: RatedPassenger,
      ratedPassengerIndex: number
    ): ReactElement | null => {
      const handleChange = (confirmed: boolean): void => {
        const next = [...value];
        const seat = next[ratedResourceIndex].seats[ratedPassengerIndex];
        seat.visitedFrom = confirmed ? new Date().toISOString() : null;
        onChange(next);
      };

      const passenger = calc.passengers.filter(
        (x) => x.id === ratedPassenger.passenger.id
      )[0];
      const names: string[] = [];
      if (passenger.familyName) {
        names.push(passenger.familyName);
      }
      if (passenger.givenName) {
        names.push(passenger.givenName);
      }
      if (passenger.middleName) {
        names.push(passenger.middleName);
      }
      const label = names.length > 0 ? names.join(" ") : "";

      if (value.length === 0) return null;

      return (
        <SwitchBox
          label={label}
          mode="check"
          name={`resource-${ratedResourceIndex}-passenger-${ratedPassengerIndex}-visited`}
          value={
            !!value[ratedResourceIndex].seats[ratedPassengerIndex].visitedFrom
          }
          onChange={handleChange}
        />
      );
    },
    [calc.spans, formatDateRange]
  );

  const handleSelectAll = useCallback(() => {
    onChange(
      calc.spans
        .flatMap((span) => span.resources)
        .map((resource) => {
          const flight = new FlightUpdateModel();
          flight.id = resource.id;
          flight.seats = resource.passengers.map((ratedPassenger) => {
            const seat = new SeatUpdateModel();
            const passenger = calc.passengers.filter((x) => x.id === ratedPassenger.passenger.id)[0]
            seat.id = ratedPassenger.id;
            seat.dateOfBirth = passenger.dateOfBirth
            seat.familyName = passenger.familyName
            seat.givenName = passenger.givenName
            seat.middleName = passenger.middleName
            seat.ticket = passenger.ticket
            seat.visitedFrom = new Date().toISOString();
            seat.visitedTill = null;

            return seat;
          });

          return flight;
        })
    );
  }, [calc, onChange]);

  const handleDeselectAll = useCallback(() => {
    onChange(
      calc.spans
        .flatMap((span) => span.resources)
        .map((resource) => {
          const flight = new FlightUpdateModel();
          flight.id = resource.id;
          flight.seats = resource.passengers.map((ratedPassenger) => {
            const seat = new SeatUpdateModel();
            const passenger = calc.passengers.filter((x) => x.id === ratedPassenger.passenger.id)[0]
            seat.id = ratedPassenger.id;
            seat.dateOfBirth = passenger.dateOfBirth
            seat.familyName = passenger.familyName
            seat.givenName = passenger.givenName
            seat.middleName = passenger.middleName
            seat.ticket = passenger.ticket
            seat.visitedFrom = null;
            seat.visitedTill = null;

            return seat;
          });

          return flight;
        })
    );
  }, [calc, onChange]);

  return (
    <Div layout="grid 12">
      <Div>
        <ul>
          {calc.spans
            .flatMap((ratedSegment) => ratedSegment.resources)
            .map((ratedResource, ratedResourceIndex) => (
              <li key={ratedResourceIndex}>
                {renderResource(data, ratedResource, ratedResourceIndex)}
                {ratedResource.passengers && (
                  <ul>
                    {ratedResource.passengers.map(
                      (ratedPassenger, ratedPassengerIndex) => (
                        <li key={ratedPassengerIndex}>
                          {renderPassenger(
                            data,
                            ratedResource,
                            ratedResourceIndex,
                            ratedPassenger,
                            ratedPassengerIndex
                          )}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </Div>
      <Div>
        <Div layout="flex">
          <Div layout="fill" />
          <Div layout="fit">
            <Button look="bare" onClick={handleSelectAll}>
              {formatMessage(TXT("action.selectAll"))}
            </Button>
            <Button look="bare" onClick={handleDeselectAll}>
              {formatMessage(TXT("action.deselectAll"))}
            </Button>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
