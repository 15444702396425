import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Pair } from '../../data'
import { ReportType } from '../data/models'
import { PairConverter, PairData } from './types'

export function useReportTypes(): [ PairData<ReportType>, PairConverter<ReportType> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<ReportType>>([
    new Pair(ReportType.Sale, intl.formatMessage({ id: "enum.reportType.sale" })),
    new Pair(ReportType.Operations, intl.formatMessage({ id: "enum.reportType.operations" })),
    new Pair(ReportType.Load, intl.formatMessage({ id: "enum.reportType.load" })),
    new Pair(ReportType.Free, intl.formatMessage({ id: "enum.reportType.free" })),
  ]);

  const converter = useCallback((value: ReportType): string => {
    const item = data.filter(pair => pair.code === value)[0];
    return item ? item.name : "";
  }, [ data ]);

  return [ data, converter ];
}
