import { Apis, Chunk, Client } from "../../../data";
import { LoyaltyProgram } from "../models";

export class LoyaltyProgramAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, belongsTo?: string): Promise<Chunk<LoyaltyProgram>> {
    return await Client.instance(await Apis.getGatewayV0())
      .get<Chunk<LoyaltyProgram>>(`loyalty-programs`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        belongsTo,
      });
  }
}
