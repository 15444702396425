import { EMPTY_ID, RateCost, ResourceName, ResourceReference, ServiceFieldDescription, ServiceSettings } from './index'

export enum ServiceKind {
  Any = 'Any',
  VipLounge = 'VipLounge',
  BusinessLounge = 'BusinessLounge',
  ComfortLounge = 'ComfortLounge',
  OfficialPersonsAndDelegationsLounge = 'OfficialPersonsAndDelegationsLounge',
  FastTrack = 'FastTrack',
  MeetAndAssist = 'MeetAndAssist',
  MeetAndGreet = 'MeetAndGreet',
  Parking = 'Parking',
  Transfer = 'Transfer',
  Transit = 'Transit',
  Meal = 'Meal',
  Info = 'Info',
  LoungeTime = 'LoungeTime',
  Urgency = 'Urgency',
  ConferenceRoom = 'ConferenceRoom',
  Apartments = 'Apartments',
  Luggage = 'Luggage',
  Photography = 'Photography',
  Other = 'Other',
  VirtualLounge = 'VirtualLounge',
}

export enum ServiceType {
  Any = 'Any',
  Arrival = 'Arrival',
  Departure = 'Departure',
  ArrivalAndDeparture = 'ArrivalAndDeparture',
  DepartureAndArrival = 'DepartureAndArrival',
  Transit = 'Transit',
}

export enum ServiceMode {
  Automatic = 'Automatic',
  Manual = 'Manual',
  SemiAutomatic = 'SemiAutomatic',
}

export enum ServiceUnit {
  Whole = 'Whole',
  Hour = 'Hour',
  Item = 'Item',
  ItemPerHour = 'ItemPerHour',
}

export interface ServiceFilter {
  byResourceId?: string
  byKind?: ServiceKind[] | ServiceKind
  byType?: ServiceType[] | ServiceType
}

export class ServiceReference {
  public id: string

  public constructor(id?: string) {
    this.id = id || EMPTY_ID
  }
}

export class ServiceBase extends ServiceReference {
  public code: string
  public name: string
  public kind: ServiceKind
  public type: ServiceType
  public mode: ServiceMode
  public unit: ServiceUnit
  public capacity: number | null
  public settings: ServiceSettings | null
  public description: string | null

  public constructor() {
    super()
    this.code = ''
    this.name = ''
    this.kind = ServiceKind.Any
    this.type = ServiceType.Any
    this.mode = ServiceMode.Automatic
    this.unit = ServiceUnit.Whole
    this.capacity = null
    this.settings = null
    this.description = null
  }
}

export class Service<TResource extends ResourceReference = ResourceReference> extends ServiceBase {
  public resource: TResource
  public costs: RateCost[]
  public fields: ServiceFieldDescription[] | null

  public constructor(TResource: new() => TResource) {
    super()
    this.resource = new TResource()
    this.costs = []
    this.fields = null
  }
}

export class ServiceTranslation extends ServiceReference {
  public language: string
  public name: string | null
  public description: string | null

  public constructor(init: Partial<ServiceTranslation>) {
    super(init.id)
    this.language = init.language ?? ''
    this.name = init.name ?? null
    this.description = init.description ?? null
  }
}

export class ResolvedService extends Service<ResourceName> {
  public constructor() {
    super(ResourceName)
  }
}

export class ServiceUpdateModel {
  public name: string
  public type: ServiceType
  public mode: ServiceMode
  public capacity: number | null
  public quota: number | null
  public settings: ServiceSettings | null
  public description: string | null
  
  public constructor() {
    this.name = ''
    this.type = ServiceType.Any
    this.mode = ServiceMode.Automatic
    this.capacity = null
    this.quota = null
    this.settings = null
    this.description = null
  }
}

export class ServiceCreateModel extends ServiceUpdateModel {
  public kind: ServiceKind
  public unit: ServiceUnit
  public resource: ResourceReference
  public fields: ServiceFieldDescription[] | null
  
  public constructor() {
    super()
    this.kind = ServiceKind.Any
    this.unit = ServiceUnit.Whole
    this.resource = new ResourceReference()
    this.fields = null
  }
}

export class ServiceTranslateModel {
  public name: string | null
  public description: string | null

  public constructor() {
    this.name = null
    this.description = null
  }
}
