import React, { PureComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";
import { Icons } from "../assets";

interface NavPathProps {
  items: NavPathItem[];
}

interface NavPathProps {
  children?: null;
}

interface NavPathState {
}

export interface NavPathItem {
  path: string;
  text: IntlMessage;
  state?: any;
}

export class NavPath extends PureComponent<NavPathProps, NavPathState> {
  public constructor(props: NavPathProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { items } = this.props;

    return (
      <Intl render={intl =>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Link to={{ pathname: item.path, state: item.state }}>{getIntlMessage(intl, item.text)}</Link>
              {index < items.length - 1 && Icons.Navigation.Path.Separator}
            </li>
          ))}
        </ul>
      } />
    );
  }
}
