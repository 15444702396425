import { Apis, Client } from '../../../data'
import { Currency } from '../models'

export class CurrencyAccess {
  public static async getOne(id: string): Promise<Currency> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`currencies/${id}`);
  }
}
