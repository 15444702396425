import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Chunk } from '../../data'
import { OrganizationProgramAccess } from '../data/access'
import { OrganizationProgram, OrganizationProgramFilter, OrganizationProgramSettings, ResolvedOrganizationProgram } from '../data/models'
import { FetchList } from './types'

export function useOrganizationPrograms(pageSize?: number, prefetch?: boolean, filter?: OrganizationProgramFilter): [ Chunk<ResolvedOrganizationProgram>, FetchList ] {
  const [ data, setData ] = useState<Chunk<ResolvedOrganizationProgram>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await OrganizationProgramAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter)
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, filter, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganizationProgram(programId: string | null): [ OrganizationProgram | null, Dispatch<SetStateAction<OrganizationProgram | null>> ] {
  const [ item, setItem ] = useState<OrganizationProgram | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (programId) {
          setItem(await OrganizationProgramAccess.getOne(programId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ programId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationProgramSettings(programId: string | null): [ OrganizationProgramSettings | null, Dispatch<SetStateAction<OrganizationProgramSettings | null>> ] {
  const [ item, setItem ] = useState<OrganizationProgramSettings | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (programId) {
          setItem(await OrganizationProgramAccess.getOneSettings(programId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ programId, setItem ])

  return [ item, setItem ]
}
