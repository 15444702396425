import { IReference } from "./IReference";
import { ServiceReferenceV1 } from "./Service";

export enum MediaKindV1 {
  Document = "document",
  Image = "image",
  Video = "video",
  Map = "map",
}

export class MediaReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class MediaBaseV1 extends MediaReferenceV1 {
  public fileId: string;
  public index: number;
  public name: string;
  public kind: MediaKindV1;
  public description: string | null;

  public constructor(id?: number) {
    super(id);
    this.fileId = "";
    this.index = 0;
    this.name = "";
    this.kind = MediaKindV1.Document;
    this.description = null;
  }
}

export class MediaV1 extends MediaBaseV1 {
  public constructor() {
    super();
  }
}

export class MediaActionRequestV1 {
  public fileId: string;
  public index: number;
  public name: string;
  public kind: MediaKindV1;
  public description: string | null;

  public constructor(model?: MediaV1) {
    this.fileId = model ? model.fileId : "";
    this.index = model ? model.index : 0;
    this.name = model ? model.name : "";
    this.kind = model ? model.kind : MediaKindV1.Document;
    this.description = model ? model.description : null;
  }
}

export class MediaCreateRequestV1 extends MediaActionRequestV1 {
  public service: ServiceReferenceV1;

  public constructor(model?: MediaV1, service?: ServiceReferenceV1) {
    super(model);
    this.service = service || new ServiceReferenceV1();
  }
}

export class MediaUpdateRequestV1 extends MediaActionRequestV1 {
  public id: number;

  public constructor(model?: MediaV1) {
    super(model);
    this.id = model ? model.id : 0;
  }
}

export class MediaChangeOrderRequestV1 {
  public newOrder: MediaReferenceV1[];

  public constructor(newOrder?: MediaReferenceV1[]) {
    this.newOrder = newOrder || [];
  }
}
