import React from "react";
import { IdentityProfile } from "./IdentityProfile";

export type AuthContextValue = {
  authenticated: boolean;
  profile: IdentityProfile;
};

export const AuthContext = React.createContext<AuthContextValue>({
  authenticated: false,
  profile: new IdentityProfile(),
});
