import { ContractReferenceV1 } from "./Contract";
import { CorporateProgramReferenceV1 } from "./CorporateProgram";
import { IReference } from "./IReference";
import { OrganizationBaseV1 } from "./Organization";
import { PassengerCategoryV1 } from "./Passenger";
import { FlightDirectionV1, ResourceBaseV1, ServiceKindV1 } from "./Resource";
import { ServiceTypeV1 } from "./Service";
import { TransferBaseV1 } from "./Transfer";

export enum RateFamilyV1 {
  Standard = "standard",
  Program = "program",
  Contract = "contract",
}

export class RateCostV1 {
  public category: PassengerCategoryV1;
  public type: ServiceTypeV1;
  public appliedFrom: string | null;
  public appliedTill: string | null;
  public transfers: TransferBaseV1[] | null;
  public value: number;

  public constructor(category?: PassengerCategoryV1, type?: ServiceTypeV1, value?: number) {
    this.category = category || PassengerCategoryV1.Adult;
    this.type = type || ServiceTypeV1.All;
    this.appliedFrom = null;
    this.appliedTill = null;
    this.transfers = null;
    this.value = value || 0;
  }
}

export class RateFineV1 {
  public type: ServiceTypeV1;
  public flightDelta: string;
  public valueAfter: number;
  public valueBefore: number;

  public constructor(type?: ServiceTypeV1, flightDelta?: string, valueAfter?: number, valueBefore?: number) {
    this.type = type || ServiceTypeV1.All;
    this.flightDelta = flightDelta || "02:00";
    this.valueAfter = valueAfter || 0;
    this.valueBefore = valueBefore || 0;
  }
}

export class RateFilterV1 {
  public category?: PassengerCategoryV1;
  public kind?: ServiceKindV1;
  public type?: ServiceTypeV1;
  public isByProgram?: boolean;
  public isByContract?: boolean;
  public flightDate?: string;
}

export class RateReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class RateRecord extends RateReferenceV1 {
  public name: string;
  public categories: PassengerCategoryV1[];
  public kind: ServiceKindV1;
  public type: ServiceTypeV1;
  public direction: FlightDirectionV1;
  public provider: string;
  public resource: string | null;
  public program: string | null;
  public contract: string | null;
  public bookableFromDate: string;
  public bookableTillDate: string;
  public effectiveFromDate: string;
  public effectiveTillDate: string;
  public effectiveFromTime: string;
  public effectiveTillTime: string;
  public days: string[];
  public value: number;
  public valueFrom: number;
  public valueTill: number;

  public constructor() {
    super();
    this.name = "";
    this.categories = [];
    this.kind = ServiceKindV1.Additional;
    this.type = ServiceTypeV1.All;
    this.direction = FlightDirectionV1.Any;
    this.provider = "";
    this.resource = null;
    this.program = null;
    this.contract = null;
    this.bookableFromDate = "";
    this.bookableTillDate = "";
    this.effectiveFromDate = "";
    this.effectiveTillDate = "";
    this.effectiveFromTime = "";
    this.effectiveTillTime = "";
    this.days = [];
    this.value = 0;
    this.valueFrom = 0;
    this.valueTill = 0;
  }
}

export class RateBaseV1 extends RateReferenceV1 {
  public code: string;
  public name: string;
  public kind: ServiceKindV1;
  public type: ServiceTypeV1;
  public direction: FlightDirectionV1;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
    this.kind = ServiceKindV1.Additional;
    this.type = ServiceTypeV1.All;
    this.direction = FlightDirectionV1.Any;
  }
}

export class RateV1 extends RateBaseV1 {
  public provider: OrganizationBaseV1;
  public resource: ResourceBaseV1 | null;
  public program: CorporateProgramReferenceV1 | null;
  public contract: ContractReferenceV1 | null;
  public bookableFromDate: string;
  public bookableTillDate: string;
  public effectiveFromDate: string;
  public effectiveTillDate: string;
  public effectiveFromTime: string;
  public effectiveTillTime: string;
  public days: string[];
  public costs: RateCostV1[];
  public fines: RateFineV1[];

  public constructor() {
    super();
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
    this.provider = new OrganizationBaseV1();
    this.resource = null;
    this.program = null;
    this.contract = null;
    this.bookableFromDate = `${year}-${month}-01T00:00:00.000Z`;
    this.bookableTillDate = `${year + 1}-${month}-01T00:00:00.000Z`;
    this.effectiveFromDate = `${year}-${month}-01T00:00:00.000Z`;
    this.effectiveTillDate = `${year + 1}-${month}-01T00:00:00.000Z`;
    this.effectiveFromTime = "00:00";
    this.effectiveTillTime = "23:59";
    this.days = [ "mon", "tue", "wed", "thu", "fri", "sat", "sun" ];
    this.costs = [];
    this.fines = [];
  }
}
