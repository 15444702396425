import { guid } from "@progress/kendo-react-common";
import React, { CSSProperties, PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface TextAreaProps {
  hint?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  style?: CSSProperties;
  name: string;
  value: string | null;
  error?: IntlMessage;
  validators?: ((value: string | null) => IntlMessage | undefined)[],
  onChange?: (value: string | null) => void;
}

interface TextAreaProps {
  children?: null;
}

interface TextAreaState {
}

export class TextArea extends PureComponent<TextAreaProps, TextAreaState> {
  private readonly id: string;

  public constructor(props: TextAreaProps) {
    super(props);

    this.state = {};
    this.id = guid();
  }

  public render(): ReactNode {
    const { hint, placeholder, disabled, readonly, fill, style, name, value, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;


    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={false}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange, onFieldEnter, onFieldLeave) =>
                 <span className={className}>
                   <textarea id={this.id}
                             className="k-textarea"
                             style={{ height: 136, resize: "none", ...style }}
                             placeholder={placeholder && getIntlMessage(intl, placeholder)}
                             disabled={disabled || fieldDisabled || !onFieldChange}
                             readOnly={readonly || fieldReadonly}
                             value={value ? value : ""}
                             onChange={e => onFieldChange(e.target.value ? e.target.value : null)}
                             onFocus={onFieldEnter}
                             onBlur={onFieldLeave} />
                 </span>
               }
               onChange={onChange} />
      } />
    );
  }
}
