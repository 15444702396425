import { EMPTY_ID, ResourceName, ResourceReference } from './index'

export interface ScheduleFilter {
  byResourceId?: string
}

export class ScheduleReference {
  public id: string

  public constructor(id?: string) {
    this.id = id || EMPTY_ID
  }
}

export class ScheduleName extends ScheduleReference {
  public code: string
  public name: string

  public constructor() {
    super()
    this.code = ''
    this.name = ''
  }
}

export class ScheduleBase extends ScheduleName {
  public validFrom: string
  public validTill: string
  public validFromTime: string
  public validTillTime: string
  public validDays: string[]

  public constructor() {
    super()
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0')
    this.validFrom = `${year}-${month}-01T00:00:00.000Z`
    this.validTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFromTime = '00:00'
    this.validTillTime = '23:59'
    this.validDays = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ]
  }
}

export class Schedule<TResource extends ResourceReference = ResourceReference> extends ScheduleBase {
  public resource: TResource

  public constructor(TResource: new() => TResource) {
    super()
    this.resource = new TResource()
  }
}

export class ScheduleUpdateModel {
  public name: string
  public validFrom: string
  public validTill: string
  public validFromTime: string
  public validTillTime: string
  public validDays: string[]

  public constructor() {
    this.name = ''
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0')
    this.validFrom = `${year}-${month}-01T00:00:00.000Z`
    this.validTill = `${year + 1}-${month}-01T00:00:00.000Z`
    this.validFromTime = '00:00'
    this.validTillTime = '23:59'
    this.validDays = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ]
  }
}

export class ScheduleCreateModel extends ScheduleUpdateModel {
  public resource: ResourceReference
  
  public constructor() {
    super()
    this.resource = new ResourceReference()
  }
}

export class ResolvedSchedule extends Schedule<ResourceName> {
  public constructor() {
    super(ResourceName)
  }
}
