import { classNames } from "@progress/kendo-react-common";
import * as KendoPopup from "@progress/kendo-react-popup";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { IntlProvider } from "../../assets";
import { Div } from "./Div";
import { ActionHandler, GearProps } from "./types";

export interface PopupProps extends GearProps {
  anchor: ReactElement;
  toggled?: boolean;
  onClose?: ActionHandler;
}

export function Popup(props: PopupProps): ReactElement {
  const { anchor, children, className, toggled, onClose } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const popRef = useRef<HTMLDivElement>(null);

  const mouseupOutside = useCallback((event: MouseEvent): void => {
    if (divRef.current && popRef.current && !divRef.current.contains(event.target as Node) && !popRef.current.contains(event.target as Node) && onClose) {
      onClose();
    }
  }, [ onClose ]);

  useEffect(() => {
    document.addEventListener("mouseup", mouseupOutside);

    return () => {
      document.removeEventListener("mouseup", mouseupOutside);
    };
  }, [ mouseupOutside ]);

  return (
    <React.Fragment>
      <Div ref={divRef}>
        {anchor}
      </Div>
      <KendoPopup.Popup anchor={divRef.current || undefined} show={toggled}>
        <IntlProvider>
          <Div ref={popRef} className={classNames("g-popup", className)}>
            {children}
          </Div>
        </IntlProvider>
      </KendoPopup.Popup>
    </React.Fragment>
  );
}
