import { IReference } from "./IReference";

export enum PassengerCategoryV1 {
  Adult = "adult",
  Child = "child",
  Group = "group",
  Infant = "infant",
}

export enum PassengerTypeV1 {
  Primary = "primary",
  Secondary = "secondary",
  Guest = "guest",
  Service = "service",
}

export enum PassengerKlassV1 {
  Business = "business",
  First = "first",
}

export class PassengerReferenceV1 implements IReference {
  public id: number;

  public constructor() {
    this.id = 0;
  }
}

export class PassengerIdentityV1 extends PassengerReferenceV1 {
  public type: PassengerTypeV1;
  public givenName: string;
  public middleName: string | null;
  public familyName: string;
  public birthDate: string;
  public phone: string;
  public email: string;

  public constructor() {
    super();
    this.type = PassengerTypeV1.Primary;
    this.givenName = "";
    this.middleName = null;
    this.familyName = "";
    this.birthDate = "";
    this.email = "";
    this.phone = "";
  }
}

export class PassengerBaseV1 extends PassengerIdentityV1 {
  public ticket: string | null;

  public constructor() {
    super();
    this.ticket = null;
  }
}

export class PassengerV1 extends PassengerBaseV1 {
  public constructor() {
    super();
  }
}
