import * as KendoLayout from "@progress/kendo-react-layout";
import React, { ReactElement, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { useFormatMessage } from "../hooks";
import { GearProps, IntlText, ItemSelectHandler } from "./types";

export interface MenuItemProps {
  title: IntlText;
  path?: string;
  data?: any;
}

// noinspection JSUnusedLocalSymbols
export function MenuItem(props: MenuItemProps): null {
  return null;
}

export interface MenuProps extends GearProps<(ReactElement<MenuItemProps> | null | undefined | false)[] | ReactElement<MenuItemProps> | null | undefined | false> {
  onItemSelect?: ItemSelectHandler<any | undefined>;
}

export function Menu(props: MenuProps): ReactElement {
  const { children, onItemSelect } = props;

  const formatMessage = useFormatMessage();
  const location = useLocation();
  const history = useHistory();

  const handleSelect = useCallback((event: KendoLayout.MenuSelectEvent): void => {
    if (event.item.data && event.item.data.path) {
      history.push(event.item.data.path);
    }
    if (onItemSelect) {
      onItemSelect(event.item.data);
    }
  }, [ history, onItemSelect ]);

  return (
    <KendoLayout.Menu onSelect={handleSelect}>
      {children && React.Children.map(children, (child, index) => {
        if (!child) return;
        return (
          <KendoLayout.MenuItem key={index} cssClass={location.pathname === child.props.path ? "active" : undefined}
                                text={formatMessage(child.props.title)}
                                data={{ path: child.props.path, data: child.props.data }} />
        );
      })}
    </KendoLayout.Menu>
  );
}
