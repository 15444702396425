import React, { DetailedHTMLProps, HTMLAttributes, Ref } from "react";
import { Intent } from "./types";
import { intentToClassName } from "./utils";

export type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { layout?: string } & { intent?: Intent };
export type DivRef = Ref<HTMLDivElement>;

export const Div = React.forwardRef((props: DivProps, ref?: DivRef) => {
  const { layout, intent, className, ...rest } = props;

  const bp = layout ? { bp: layout } : undefined;

  return (
    <div ref={ref} className={intentToClassName(intent, className)} {...bp} {...rest} />
  );
});

Div.displayName = "Div";
