import { classNames } from "@progress/kendo-react-common";
import React, { CSSProperties, ReactElement } from "react";
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl";

export interface IntlDescriptor {
  id: string;
  values?: any;
}

export type IntlMessage = IntlDescriptor | string;

export const Intl = injectIntl((props: Readonly<WrappedComponentProps & { render: (intl: IntlShape) => ReactElement; }>): ReactElement =>
  props.render(props.intl));

export function IntlFormat(props: Readonly<{
  text: IntlMessage | Date,
  intent?: "info" | "success" | "warning" | "error",
  className?: string,
  style?: CSSProperties,
}>): ReactElement {
  return <Intl render={intl =>
    <span className={classNames(props.className, props.intent ? `k-text-${props.intent}` : undefined)}
          style={props.style}>{getIntlMessage(intl, props.text)}</span>} />
}

export function getIntlMessage(intl: IntlShape, message: IntlMessage | Date): string {
  function isIntlDescriptor (message: IntlMessage | Date): message is IntlDescriptor {
    return (message as IntlDescriptor).id !== undefined;
  }

  return isIntlDescriptor(message)
    ? intl.formatMessage({ id: message.id }, message.values)
    : message instanceof Date
      ? `${intl.formatDate(message)} ${intl.formatTime(message)}`
      : message;
}

export function ID(id: string, values?: any): IntlDescriptor {
  return ({ id, values });
}
