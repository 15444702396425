import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Icons } from '../../assets'
import { Chunk, Pair } from '../../data'
import { FlightDirectionV1, ResourceBaseV1, ResourceDefaultsV1, ResourceV1 } from '../../data/models'
import { ResourceAccess, ResourceAccessV1 } from '../data/access'
import { FlightDirection, Resource, ResourceFeature, ResourceFilter, ResourceSettings, Service, ServiceKind, ServiceType } from '../data/models'
import { FetchList, PairConverter, PairData } from './types'

export function useResource(resourceId: string | null): [ Resource | null, Dispatch<SetStateAction<Resource | null>> ] {
  const [ item, setItem ] = useState<Resource | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (resourceId) {
          setItem(await ResourceAccess.getOne(resourceId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ resourceId, setItem ]);

  return [ item, setItem ];
}

export function useResources(pageSize?: number, prefetch?: boolean, filter?: ResourceFilter): [ Chunk<Resource>, FetchList ] {
  const [ data, setData ] = useState<Chunk<Resource>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await ResourceAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter);
      setData(data);
    } catch (e) {
      setData(new Chunk());
    }
  }, [ pageSize, filter, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useResourceSettings(resourceId: string | null): [ ResourceSettings | null, Dispatch<SetStateAction<ResourceSettings | null>> ] {
  const [ item, setItem ] = useState<ResourceSettings | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (resourceId) {
          setItem(await ResourceAccess.getOneSettings(resourceId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ resourceId, setItem ]);

  return [ item, setItem ];
}

export function useResourceCompatibilities(resourceId: string | null): [ ServiceKind[] | null, Dispatch<SetStateAction<ServiceKind[] | null>> ] {
  const [ item, setItem ] = useState<ServiceKind[] | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (resourceId) {
          setItem(await ResourceAccess.getOneCompatibilities(resourceId));
        } else {
          setItem(null);
        }
      } catch (error) {
        setItem(null);
      }
    })();
  }, [ resourceId, setItem ]);

  return [ item, setItem ];
}

export function useResourceServices(resourceId: string | null, pageSize?: number, prefetch?: boolean, byKind?: ServiceKind | ServiceKind[], byType?: ServiceType | ServiceType[]): [
  Chunk<Service>,
  FetchList,
] {
  const [ data, setData ] = useState<Chunk<Service>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (resourceId) {
        const data = await ResourceAccess.getOneServices(resourceId, pattern, undefined, skip || 0, take || pageSize, byKind, byType);
        setData(data);
      } else {
        setData(new Chunk());
      }
    } catch (error) {
      console.error(error);
    }
  }, [ pageSize, resourceId, byKind, byType, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ]
}

export function useFlightDirections(short: boolean = false): [ PairData<FlightDirection>, PairConverter<FlightDirection> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<FlightDirection>>([
    new Pair(FlightDirection.Any, short ? intl.formatMessage({ id: "enum.flightDirection.any" }) : intl.formatMessage({ id: "enum.flightDirection.any" })),
    new Pair(FlightDirection.Domestic, short ? intl.formatMessage({ id: "enum.flightDirection.dom" }) : intl.formatMessage({ id: "enum.flightDirection.domestic" })),
    new Pair(FlightDirection.International, short ? intl.formatMessage({ id: "enum.flightDirection.int" }) : intl.formatMessage({ id: "enum.flightDirection.international" })),
  ]);

  const converter = useCallback((value: FlightDirection): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export const useResourceFeatures = (): [ PairData<ResourceFeature>, PairConverter<ResourceFeature> ] => {
  const [ data ] = useState<PairData<ResourceFeature>>([
    new Pair(ResourceFeature.AirCondition, 'Кондиционирование воздуха'),
    new Pair(ResourceFeature.AlcoholDrink, 'Алкогольные напитки'),
    new Pair(ResourceFeature.BizZone, 'Бизнес зона'),
    new Pair(ResourceFeature.ElectricalSocket, 'Электрические розетки'),
    new Pair(ResourceFeature.Internet, 'Интернет'),
    new Pair(ResourceFeature.LimitedPeople, 'Доступ для людей с ограниченными возможностями'),
    new Pair(ResourceFeature.LuggagePacking, 'Упаковка багажа'),
    new Pair(ResourceFeature.Meal, 'Шведский стол'),
    new Pair(ResourceFeature.Phone, 'Телефон'),
    new Pair(ResourceFeature.PlayRoom, 'Детская комната'),
    new Pair(ResourceFeature.Press, 'Пресса'),
    new Pair(ResourceFeature.Registration, 'Регистрация на рейс'),
    new Pair(ResourceFeature.Shower, 'Душ'),
    new Pair(ResourceFeature.TV, 'Телевидение'),
    new Pair(ResourceFeature.Transfer, 'Доставка на отдельном транспорте до ВС'),
    new Pair(ResourceFeature.Wardrobe, 'Гардероб'),
    new Pair(ResourceFeature.Wifi, 'Wi-Fi'),
    new Pair(ResourceFeature.Vegan, 'Вегетарианское меню'),
    new Pair(ResourceFeature.HotDishes, 'Горячие блюда'),
    new Pair(ResourceFeature.Snacks, 'Закуски'),
    new Pair(ResourceFeature.Cocktails, 'Коктейли'),
    new Pair(ResourceFeature.Menu, 'Меню на выбор'),
    new Pair(ResourceFeature.Fruits, 'Фрукты'),
    new Pair(ResourceFeature.Salad, 'Холодные блюда'),
    new Pair(ResourceFeature.Champagne, 'Шампанское'),
    new Pair(ResourceFeature.SPA, 'SPA'),
    new Pair(ResourceFeature.RunwayView, 'Вид на ВВП'),
    new Pair(ResourceFeature.Sofa, 'Гостиная'),
    new Pair(ResourceFeature.SmokingArea, 'Зона для курения'),
    new Pair(ResourceFeature.EntertainmentArea, 'Зона развлечений'),
    new Pair(ResourceFeature.LuggageStorage, 'Камеры хранения'),
    new Pair(ResourceFeature.BabyChanging, 'Комната матери и ребенка'),
    new Pair(ResourceFeature.NailPolish, 'Маникюрные услуги'),
    new Pair(ResourceFeature.MassageChair, 'Массажные кресла'),
    new Pair(ResourceFeature.PrayerRoom, 'Молитвенная комната'),
    new Pair(ResourceFeature.SleepingPlaces, 'Спальные места'),
    new Pair(ResourceFeature.WC, 'Туалетная комната'),
    new Pair(ResourceFeature.Laptop, 'Доступ к компьютеру'),
    new Pair(ResourceFeature.ConferenceHall, 'Конференц-зал'),
    new Pair(ResourceFeature.FlightTime, 'Монитор прилетов/вылетов'),
    new Pair(ResourceFeature.Announcements, 'Объявления по громкой связи'),
    new Pair(ResourceFeature.SecurityCheck, 'Отдельные предполетный досмотр'),
    new Pair(ResourceFeature.Printer, 'Принтер/копир'),
    new Pair(ResourceFeature.Fax, 'Факс'),
  ])

  const formatter = useCallback(
    (code: ResourceFeature) => {
      return data.filter((pair) => pair.code === code).map((pair) => pair.name)[0] ?? ''
    },
    [ data ],
  )

  return [ data, formatter ]
}

export const useResourceFeatureIcons = (): [ PairData<ResourceFeature, ReactElement | null>, PairConverter<ResourceFeature, ReactElement | null> ] => {
  const [data] = useState<PairData<ResourceFeature, ReactElement | null>>(() => {
    const data = []
    for (const key in ResourceFeature) {
      data.push(
        new Pair(
          ResourceFeature[key as keyof typeof ResourceFeature],
          <img src={`/icons/${ResourceFeature[key as keyof typeof ResourceFeature]}.png`} height='32px' width='32px' />,
        ),
      )
    }

    return data
  })

  const formatter = useCallback(
    (code: ResourceFeature) => {
      return data.filter((pair) => pair.code === code).map((pair) => pair.name)[0] || null
    },
    [ data ],
  )

  return [ data, formatter ]
}

export function useResourceV1(id: number | null): ResourceV1 | null {
  const [ item, setItem ] = useState<ResourceV1 | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await ResourceAccessV1.fetchItem(id));
        } else {
          setItem(null);
        }
      } catch {
        setItem(null);
      }
    })();
  }, [ id, setItem ]);

  return item;
}

export function useResourcesV1(pageSize?: number, prefetch?: boolean, provider?: string, airport?: string, withContracts?: boolean, lounges?: boolean): [ Chunk<ResourceBaseV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<ResourceBaseV1>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await ResourceAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize, provider, airport, withContracts, lounges);
      setData(data);
    } catch (e) {
      setData(new Chunk());
    }
  }, [ pageSize, provider, airport, withContracts, lounges, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useResourceDefaultsV1(provider: number | string | null): ResourceDefaultsV1 | null {
  const [ item, setItem ] = useState<ResourceDefaultsV1 | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const item = provider !== null ? await ResourceAccessV1.fetchDefaults(provider) : null;
        setItem(item);
      } catch {
        setItem(null);
      }
    })();
  }, [ provider, setItem ]);

  return item;
}

export function useFlightDirectionsV1(short: boolean = false): [ PairData<FlightDirectionV1>, PairConverter<FlightDirectionV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<FlightDirectionV1>>([
    new Pair(FlightDirectionV1.Any, short ? intl.formatMessage({ id: "enum.flightDirection.any" }) : intl.formatMessage({ id: "enum.flightDirection.any" })),
    new Pair(FlightDirectionV1.Domestic, short ? intl.formatMessage({ id: "enum.flightDirection.dom" }) : intl.formatMessage({ id: "enum.flightDirection.domestic" })),
    new Pair(FlightDirectionV1.International, short ? intl.formatMessage({ id: "enum.flightDirection.int" }) : intl.formatMessage({ id: "enum.flightDirection.international" })),
  ]);

  const converter = useCallback((value: FlightDirectionV1): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function useResourceFeaturesV1(): [ PairData, PairConverter, PairConverter<string, ReactElement | null> ] {
  const intl = useIntl();
  const items = [
    { code: "air-condition", icon: Icons.Features.AirCondition },
    { code: "alcohol-drink", icon: Icons.Features.AlcoholDrink },
    { code: "biz-zone", icon: Icons.Features.BizZone },
    { code: "electrical-socket", icon: Icons.Features.ElectricalSocket },
    { code: "internet", icon: Icons.Features.Internet },
    { code: "limited-people", icon: Icons.Features.LimitedPeople },
    { code: "luggage-packing", icon: Icons.Features.LuggagePacking },
    { code: "meal", icon: Icons.Features.Meal },
    { code: "phone", icon: Icons.Features.Phone },
    { code: "playroom", icon: Icons.Features.PlayRoom },
    { code: "press", icon: Icons.Features.Press },
    { code: "registration", icon: Icons.Features.Registration },
    { code: "shower", icon: Icons.Features.Shower },
    { code: "transfer", icon: Icons.Features.Transfer },
    { code: "tv", icon: Icons.Features.TV },
    { code: "wardrobe", icon: Icons.Features.Wardrobe },
    { code: "wifi", icon: Icons.Features.Wifi },
  ];

  const [ data, ] = useState<PairData>(items.map(item => new Pair(item.code, intl.formatMessage({ id: `enum.resourceFeature.${item.code}` }))));

  const textConverter = useCallback((value: string): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  const iconConverter = useCallback((value: string): ReactElement | null => {
    return items.filter(item => item.code === value).map(item => item.icon)[0] || null;
  }, [ items ]);

  return [ data, textConverter, iconConverter ];
}
