import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import { ButtonGroup, FieldSet, Form, Validator } from '../../gears'
import { SwitchBox, TextArea, ToggleBox } from '../../gears/inputs'
import { Order, OrderReturn, OrderStatus, OrderUpdateModel, Passenger, RatedPassenger, RatedResource, RatedService, SeatStatus, ServiceKind, UpdatedPassenger, UpdatedResource, UpdatedService, UploadedFile } from '../data/models'
import { Button, Div, Span, TXT, Uploader } from '../gears'
import { useFileUrl, useFormatCost, useFormatMessage, useFormatPassenger, useWindowSize } from '../hooks'
import { ItemViewProps } from './types'

function isVisitedPassenger(ratedPassenger: RatedPassenger): boolean {
  return ratedPassenger.visitedFrom !== null || ratedPassenger.visitedTill !== null;
}

function isCancelledPassenger(ratedPassenger: RatedPassenger): boolean {
  return ratedPassenger.status === SeatStatus.Cancelled ||
    ratedPassenger.status === SeatStatus.Returned;
}

function isEligiblePassenger(ratedPassenger: RatedPassenger): boolean {
  return !isVisitedPassenger(ratedPassenger) && !isCancelledPassenger(ratedPassenger);
}

interface OrderReturnGridServiceCellProps {
  item: Order;
  model: OrderUpdateModel;
  calculated: boolean;
  ratedResourceId: number;
  ratedServiceId: number;
  onChange?: (model: OrderUpdateModel) => void;
}

function OrderReturnGridServiceCell(props: OrderReturnGridServiceCellProps): ReactElement {
  const { item, model, calculated, ratedResourceId, ratedServiceId, onChange } = props;

  const [ratedResource, setRatedResource] = useState<RatedResource | null>(null);
  const [ratedService, setRatedService] = useState<RatedService | null>(null);
  useEffect(() => {
    const ratedResource = item.spans.flatMap(x => x.resources).filter(x => x.id === ratedResourceId)[0] || null;
    const ratedService = (ratedResource.services || []).filter(x => x.id === ratedServiceId)[0] || null;
    setRatedResource(ratedResource);
    setRatedService(ratedService);
  }, [item, ratedResourceId, ratedServiceId, setRatedService]);

  const [updatedResource, setUpdatedResource] = useState<UpdatedResource | null>(null);
  useEffect(() => {
    setUpdatedResource(model.resources.filter(updatedResource => ratedResource && updatedResource.id === ratedResource.id)[0] || null);
  }, [model, ratedResource, setUpdatedResource]);

  const [updatedService, setUpdatedService] = useState<UpdatedService | null>(null);
  useEffect(() => {
    if (ratedService && updatedResource) {
      setUpdatedService((updatedResource.services || []).filter(modifiedService => modifiedService.id === ratedService.id)[0] || null);
    } else {
      setUpdatedService(null);
    }
  }, [model, ratedResource, updatedResource, ratedService, setUpdatedService]);

  const [serviceDisabled, setServiceDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (ratedResource && updatedResource && ratedService) {
      setServiceDisabled(updatedResource.passengers.every(modifiedPassenger => {
        const ratedPassenger = ratedResource.passengers.filter(ratedPassenger => ratedPassenger.id === modifiedPassenger.id)[0] || null;
        return modifiedPassenger.return || !ratedPassenger || isCancelledPassenger(ratedPassenger);
      }) || ratedResource.passengers.some(ratedPassenger => isVisitedPassenger(ratedPassenger)) || ratedService.status !== SeatStatus.Paid);
    } else {
      setServiceDisabled(true);
    }
  }, [model, ratedResource, updatedResource, ratedService, setServiceDisabled]);

  const [serviceChecked, setServiceChecked] = useState<boolean>(false);
  useEffect(() => {
    setServiceChecked(updatedService !== null && updatedService.return === true);
  }, [updatedService, setServiceChecked]);

  const handleServiceChecked = useCallback((value: boolean): void => {
    if (ratedResource && updatedService && !serviceDisabled) {
      const modifiedModel = { ...model };
      const modifiedResource = modifiedModel.resources.filter(modifiedResource => modifiedResource.id === ratedResource.id)[0];
      const modifiedServices = [...(modifiedResource.services || [])];
      const modifiedServiceIndex = modifiedServices.findIndex(x => x.id === updatedService.id);
      modifiedServices.splice(modifiedServiceIndex, 1, { ...updatedService, return: value });
      modifiedResource.services = modifiedServices;

      if (modifiedServices.every(x => x.return) && ratedResource.resource.kind === ServiceKind.VirtualLounge) {
        modifiedResource.passengers = [...modifiedResource.passengers.map(x => ({ ...x, return: true }))];
      }

      if (onChange) {
        onChange(modifiedModel);
      }
    }
  }, [model, serviceDisabled, ratedResource, updatedService, onChange]);

  useEffect(() => {
    if (ratedResource && updatedResource) {
      const allChecked = ratedResource.passengers.every(ratedPassenger => {
        const modifiedPassenger = updatedResource.passengers.filter(modifiedPassenger => modifiedPassenger.id === ratedPassenger.id)[0] || null;
        return !isVisitedPassenger(ratedPassenger) && (isCancelledPassenger(ratedPassenger) || !modifiedPassenger || modifiedPassenger.return);
      });
      if (allChecked) {
        handleServiceChecked(true);
      }
    }
  }, [ratedResource, updatedResource, handleServiceChecked]);

  return (
    <Div layout="grid 12">
      {ratedService &&
        <Div>
          {onChange &&
            <SwitchBox label={ratedService.service.name}
              disabled={serviceDisabled}
              mode="check"
              name={`ratedService-${ratedServiceId}`}
              value={serviceChecked}
              onChange={handleServiceChecked} />
          }
          {!onChange &&
            <Span intent={updatedService && updatedService.return ? "primary" : undefined}>{ratedService.service.name}</Span>
          }
        </Div>
      }
      {calculated &&
        <React.Fragment />
      }
    </Div>
  );
}

interface OrderReturnGridResourceCellProps {
  item: Order;
  model: OrderUpdateModel;
  calculated: boolean;
  ratedResourceId: number;
  ratedPassengerId: number;
  onChange?: (model: OrderUpdateModel) => void;
}

function OrderReturnGridResourceCell(props: OrderReturnGridResourceCellProps): ReactElement {
  const { item, model, calculated, ratedResourceId, ratedPassengerId, onChange } = props;

  const formatMessage = useFormatMessage();
  const formatCost = useFormatCost();

  const [ratedResource, setRatedResource] = useState<RatedResource | null>(null);
  const [ratedPassenger, setRatedPassenger] = useState<RatedPassenger | null>(null);
  useEffect(() => {
    const { ratedResource, ratedPassenger } = item.spans
      .flatMap(span => span.resources)
      .filter(ratedResource => ratedResource.id === ratedResourceId)
      .map(ratedResource => ({ ratedResource, ratedPassenger: ratedResource.passengers.filter(ratedPassenger => ratedPassenger.id === ratedPassengerId)[0] || null }))
      .filter(({ ratedPassenger }) => ratedPassenger !== null)[0] || { ratedResource: null, ratedPassenger: null };
    setRatedResource(ratedResource);
    setRatedPassenger(ratedPassenger);
  }, [item, ratedResourceId, ratedPassengerId, setRatedResource, setRatedPassenger]);

  const [updatedPassenger, setUpdatedPassenger] = useState<UpdatedPassenger | null>(null);
  useEffect(() => {
    const modifiedResource = model.resources.filter(modifiedResource => ratedResource && modifiedResource.id === ratedResource.id)[0] || null;
    if (modifiedResource) {
      setUpdatedPassenger(modifiedResource.passengers.filter(modifiedPassenger => ratedPassenger && modifiedPassenger.id === ratedPassenger.id)[0] || null);
    } else {
      setUpdatedPassenger(null);
    }
  }, [model, ratedResource, ratedPassenger]);

  const [resourceDisabled, setResourceDisabled] = useState<boolean>(false);
  useEffect(() => {
    setResourceDisabled(ratedPassenger === null || !isEligiblePassenger(ratedPassenger));
  }, [ratedPassenger, setResourceDisabled]);

  const [resourceChecked, setResourceChecked] = useState<boolean>(false);
  useEffect(() => {
    setResourceChecked(updatedPassenger !== null && updatedPassenger.return === true);
  }, [updatedPassenger, setUpdatedPassenger]);

  const handleResourceChecked = useCallback((value: boolean): void => {
    if (ratedResource && updatedPassenger && !resourceDisabled) {
      const modifiedModel = { ...model }
      const modifiedResource = modifiedModel.resources.filter(modifiedResource => modifiedResource.id === ratedResource.id)[0];
      const modifiedPassengers = [...modifiedResource.passengers];
      const modifiedPassengerIndex = modifiedPassengers.findIndex(x => x.id === updatedPassenger.id);
      modifiedPassengers.splice(modifiedPassengerIndex, 1, { ...updatedPassenger, return: value });
      modifiedResource.passengers = modifiedPassengers;

      if (ratedResource.resource.kind === ServiceKind.VirtualLounge && modifiedResource.services && modifiedResource.services.length > 0 && !modifiedResource.passengers.every(x => x.return) && modifiedResource.services.every(x => x.return)) {
        modifiedResource.services = [
          ...modifiedResource.services.map(x => ({ ...x, return: false }))
        ]
      }

      if (onChange) {
        onChange(modifiedModel);
      }
    }
  }, [model, resourceDisabled, ratedResource, updatedPassenger, onChange]);

  const [penalty, setPenalty] = useState<number | null>(null);
  const [currency, setCurrency] = useState('')
  useEffect(() => {
    if (calculated && ratedResource && ratedPassenger && ratedPassenger.penalty) {
      setPenalty(ratedPassenger.penalty);
      setCurrency(ratedPassenger.effectiveCurrency?.code || '')
    } else {
      setPenalty(null);
    }
  }, [calculated, ratedResource, ratedPassenger, setPenalty]);

  return (
    <Div layout="grid 12">
      {ratedResource &&
        <Div>
          {onChange &&
            <SwitchBox label={`${ratedResource.resource.name} / ${ratedResource.flights[0].number}`}
              disabled={resourceDisabled}
              mode="check"
              name={`ratedPassenger-${ratedPassengerId}`}
              value={resourceChecked}
              onChange={handleResourceChecked} />
          }
          {!onChange &&
            <Span intent={updatedPassenger && updatedPassenger.return ? "primary" : undefined}>
              {`${ratedResource.resource.name} / ${ratedResource.flights[0].number}`}
            </Span>
          }
        </Div>
      }
      {calculated && penalty !== null && ratedPassenger && (ratedPassenger.status === SeatStatus.Fined || ratedPassenger.status === SeatStatus.Forced) &&
        <Div>
          <Span intent="danger">
            {formatMessage(TXT("label.fine"))}: {formatCost(penalty, currency)}
          </Span>
        </Div>
      }
      {calculated && ratedPassenger && ratedPassenger.status === SeatStatus.Rejected &&
        <Div>
          <Span intent="danger">
            {ratedPassenger.statusText}
          </Span>
        </Div>
      }
    </Div>
  );
}

interface OrderReturnGridPassengerCellProps {
  item: Order;
  model: OrderUpdateModel;
  passengerId: number;
  onChange?: (model: OrderUpdateModel) => void;
}

function OrderReturnGridPassengerCell(props: OrderReturnGridPassengerCellProps): ReactElement {
  const { item, model, passengerId, onChange } = props;

  const formatPassenger = useFormatPassenger();

  const [passenger, setPassenger] = useState<Passenger | null>(null);
  useEffect(() => {
    setPassenger(item.passengers.filter(x => x.id === passengerId)[0] || null);
  }, [item, passengerId, setPassenger]);

  const [passengerSeatIds, setPassengerSeatIds] = useState<number[]>([]);
  useEffect(() => {
    setPassengerSeatIds(item.spans
      .flatMap(span => span.resources)
      .flatMap(ratedResource => ratedResource.passengers)
      .filter(ratedPassenger => ratedPassenger.passenger.id === passengerId)
      .map(ratedPassenger => ratedPassenger.id));
  }, [item, passengerId, setPassengerSeatIds]);

  const [passengerChecked, setPassengerChecked] = useState<boolean>(false);
  useEffect(() => {
    setPassengerChecked(model.resources
      .flatMap(modifiedResource => modifiedResource.passengers)
      .some(modifiedPassenger =>
        passengerSeatIds.some(passengerSeatId => passengerSeatId === modifiedPassenger.id) &&
        modifiedPassenger.return));
  }, [model, passengerSeatIds, setPassengerChecked]);

  const handlePassengerChecked = useCallback((value: boolean): void => {
    const modifiedModel = { ...model };
    modifiedModel.resources = [...modifiedModel.resources];
    for (const modifiedResource of modifiedModel.resources) {
      const modifiedPassengerIndex = modifiedResource.passengers.findIndex(x => passengerSeatIds.some(id => id === x.id));
      const modifiedPassenger = { ...modifiedResource.passengers[modifiedPassengerIndex] };
      const ratedPassenger = item.spans.flatMap(span => span.resources).flatMap(ratedResource => ratedResource.passengers).filter(ratedPassenger => ratedPassenger.id === modifiedPassenger.id)[0];
      if (isEligiblePassenger(ratedPassenger)) {
        modifiedPassenger.return = value;
        modifiedResource.passengers = [...modifiedResource.passengers];
        modifiedResource.passengers.splice(modifiedPassengerIndex, 1, modifiedPassenger);
      }
    }

    if (onChange) {
      onChange(modifiedModel);
    }
  }, [item, model, passengerSeatIds, onChange]);

  return (
    <Div>
      {passenger &&
        <Div>
          {onChange &&
            <SwitchBox label={formatPassenger(passenger)}
              mode="check"
              name={`passenger-${passenger.id}`}
              value={passengerChecked}
              onChange={handlePassengerChecked} />
          }
          {!onChange && <Span>{formatPassenger(passenger)}</Span>}
        </Div>
      }
    </Div>
  );
}

interface OrderReturnGridProps {
  item: Order;
  model: OrderUpdateModel;
  calculated: boolean;
  onChange?: (model: OrderUpdateModel) => void;
}

function OrderReturnGrid(props: OrderReturnGridProps): ReactElement {
  const { item, model, calculated, onChange } = props;

  const formatMessage = useFormatMessage();

  const [eligiblePassengerIds, setEligiblePassengerIds] = useState<number[]>([]);
  useEffect(() => {
    setEligiblePassengerIds(item.spans
      .flatMap(span => span.resources)
      .flatMap(ratedResource => ratedResource.passengers)
      .map(ratedPassenger => ratedPassenger.passenger.id));
  }, [item, setEligiblePassengerIds]);

  const [passengers, setPassengers] = useState<Passenger[]>([]);
  useEffect(() => {
    setPassengers(item.passengers.filter(passenger => eligiblePassengerIds.some(id => id === passenger.id)));
  }, [item, eligiblePassengerIds, setPassengers]);

  const [ratedResources, setRatedResources] = useState<RatedResource[]>([]);
  useEffect(() => {
    setRatedResources(item.spans
      .flatMap(span => span.resources)
      .filter(ratedResource => ratedResource.passengers
        .map(ratedPassenger => ratedPassenger.passenger.id)
        .some(id => eligiblePassengerIds.some(eligibleId => eligibleId === id))));
  }, [item, eligiblePassengerIds, setRatedResources]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [setWidth]);

  return (
    <Measure client={true} bounds={true} onResize={handleResize}>
      {({ measureRef }) =>
        <Div ref={measureRef}>
          <Div>
            <Span className="header">{formatMessage(TXT("label.return"))}</Span>
          </Div>
          <FieldSet label={TXT("label.passengers")}>
            <Div style={{ width: width, overflowX: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {passengers.map((passenger, passengerIndex) =>
                      <th style={{ border: "1px solid rgba(0, 0, 0, 0.08)", padding: "8px 12px", textAlign: "left" }} key={passengerIndex}>
                        <OrderReturnGridPassengerCell item={item}
                          model={model}
                          passengerId={passenger.id}
                          onChange={onChange} />
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {ratedResources.map((ratedResource, ratedResourceIndex) =>
                    <React.Fragment key={ratedResourceIndex}>
                      <tr>
                        {passengers.map((passenger, passengerIndex) =>
                          <td style={{ border: "1px solid rgba(0, 0, 0, 0.08)", padding: "8px 12px" }} key={passengerIndex}>
                            <OrderReturnGridResourceCell item={item}
                              model={model}
                              calculated={calculated}
                              ratedResourceId={ratedResource.id}
                              ratedPassengerId={ratedResource.passengers.filter(ratedPassenger => ratedPassenger.passenger.id === passenger.id).map(ratedPassenger => ratedPassenger.id)[0] || 0}
                              onChange={onChange} />
                          </td>
                        )}
                      </tr>
                      {ratedResource.passengers.length === passengers.length && ratedResource.services && ratedResource.services.length > 0 &&
                        <tr>
                          <td style={{ border: "1px solid rgba(0, 0, 0, 0.08)", padding: "8px 12px" }} colSpan={passengers.length}>
                            <Div layout="flex">
                              <Div layout="fit">
                                <Span><strong>{formatMessage(TXT("label.services"))}: </strong></Span>
                              </Div>
                              {ratedResource.services.filter((orderedService) => orderedService.service.kind !== ServiceKind.Urgency).map((ratedService, ratedServiceIndex) =>
                                <Div key={ratedServiceIndex} layout="fit" style={{ paddingLeft: 8 }}>
                                  <OrderReturnGridServiceCell item={item}
                                    model={model}
                                    calculated={calculated}
                                    ratedResourceId={ratedResource.id}
                                    ratedServiceId={ratedService.id}
                                    onChange={onChange} />
                                </Div>
                              )}
                            </Div>
                          </td>
                        </tr>
                      }
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </Div>
            {onChange &&
              <Div>
                <span><small>{formatMessage(TXT("message.return"))}</small></span>
              </Div>
            }
          </FieldSet>
        </Div>
      }
    </Measure>
  );
}

interface OrderReturnTypeProps {
  type: SeatStatus.Fined | SeatStatus.Forced | null;
  onChange: (value: SeatStatus.Fined | SeatStatus.Forced) => void;
}

function OrderReturnType(props: OrderReturnTypeProps): ReactElement {
  const { type, onChange } = props;

  const formatMessage = useFormatMessage();

  const handleFinedReturnChange = useCallback((value: boolean): void => {
    onChange(value ? SeatStatus.Fined : SeatStatus.Forced);
  }, [onChange]);

  const handleForcedReturnChange = useCallback((value: boolean): void => {
    onChange(value ? SeatStatus.Forced : SeatStatus.Fined);
  }, [onChange]);

  return (
    <FieldSet label={TXT("label.type")}>
      <Div>
        <ButtonGroup>
          <ToggleBox label={TXT("label.finedReturn")}
            name="type-fined"
            primary={true}
            value={type === SeatStatus.Fined}
            onChange={handleFinedReturnChange} />
          <ToggleBox label={TXT("label.forcedReturn")}
            name="type-forced"
            primary={true}
            value={type === SeatStatus.Forced}
            onChange={handleForcedReturnChange} />
        </ButtonGroup>
      </Div>
      <Div>
        <span><small>{formatMessage(TXT("message.returnType"))}</small></span>
      </Div>
    </FieldSet>
  );
}

interface OrderReturnReason {
  reasonText: string | null;
  reasonAttachments: UploadedFile[] | null;
}

interface OrderReturnReasonModel {
  passengerReasons: (OrderReturnReason & {
    passenger: Passenger;
  })[];
  commonReason: OrderReturnReason;
}

interface OrderReturnReasonItemProps {
  model: OrderReturnReason;
  index: number;
  validatorValue: boolean;
  validator: (value: boolean) => string | undefined;
  onChange?: (model: OrderReturnReason, index: number) => void;
}

function OrderReturnReasonItem(props: OrderReturnReasonItemProps): ReactElement {
  const { model, index, validatorValue, validator, onChange } = props;

  const formatMessage = useFormatMessage();
  const [uploadUrl, formatFileUrl] = useFileUrl();

  const handleReasonTextChange = useCallback((value: string | null): void => {
    if (onChange) {
      onChange({ ...model, reasonText: value }, index);
    }
  }, [model, index, onChange]);

  const handleUpload = useCallback((files: UploadedFile[]): void => {
    const reasonAttachments = [...(model.reasonAttachments || []), ...files];
    if (onChange) {
      onChange({ ...model, reasonAttachments }, index);
    }
  }, [model, index, onChange]);

  const handleRemove = useCallback((fileIndex: number): void => {
    const reasonAttachments = [...(model.reasonAttachments || [])];
    reasonAttachments.splice(fileIndex, 1);
    if (onChange) {
      onChange({ ...model, reasonAttachments }, index);
    }
  }, [model, index, onChange]);

  return (
    <Div layout="grid 12">
      <Div>
        <Div>
          <Validator name={`passenger-${index}-reason-validator`} value={validatorValue} validators={[validator]}>
            <TextArea fill={true}
              style={{ height: 92 }}
              name={`passenger-${index}-reason`}
              value={model.reasonText}
              onChange={handleReasonTextChange} />
          </Validator>
        </Div>
        {onChange &&
          <Div>
            <span><small>{formatMessage(TXT("message.returnReasonText"))}</small></span>
          </Div>
        }
      </Div>
      <Div>
        <Div layout="grid 12">
          {onChange &&
            <Div>
              <Div>
                <Uploader uploadUrl={uploadUrl} showFileList={false} onUploaded={handleUpload} />
              </Div>
              <Div>
                <span><small>{formatMessage(TXT("message.returnReasonAttachments"))}</small></span>
              </Div>
            </Div>
          }
          <Div>
            {(model.reasonAttachments || []).map((x, i) =>
              <Div key={i}>
                <Div layout="flex vertical-center gap-row-none">
                  <Div layout="fill">
                    <a href={formatFileUrl(x.id)}>{x.name}</a>
                  </Div>
                  {onChange &&
                    <Div layout="fit">
                      <Button look="bare" icon="trash" onClick={() => handleRemove(i)} />
                    </Div>
                  }
                </Div>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

interface OrderReturnReasonListProps {
  item: Order;
  model: OrderReturnReasonModel;
  onChange?: (model: OrderReturnReasonModel) => void;
}

function OrderReturnReasonList(props: OrderReturnReasonListProps): ReactElement {
  const { model, onChange } = props;

  const formatMessage = useFormatMessage();
  const formatPassenger = useFormatPassenger();

  const handlePassengerReasonChange = useCallback((value: OrderReturnReason, index: number): void => {
    const changedModel = { ...model };
    const passengerReasons = [...changedModel.passengerReasons];
    passengerReasons.splice(index, 1, { ...passengerReasons[index], ...value });
    changedModel.passengerReasons = passengerReasons;
    if (onChange) {
      onChange(changedModel);
    }
  }, [model, onChange]);

  const handleCommonReasonChange = useCallback((value: OrderReturnReason): void => {
    const changedModel = { ...model, commonReason: value };
    if (onChange) {
      onChange(changedModel);
    }
  }, [model, onChange]);

  const isValid = useCallback((passengerReason: OrderReturnReason | null): boolean => {
    const hasCommonReason = model.commonReason.reasonText !== null && model.commonReason.reasonText.length > 0;
    const hasPassengerReason = passengerReason === null || (passengerReason.reasonText !== null && passengerReason.reasonText.length > 0)
    return hasCommonReason || hasPassengerReason;
  }, [model]);

  const isValidValidator = useCallback((value: boolean): string | undefined => {
    return !value ? formatMessage(TXT("valid.reasonRequired")) : undefined;
  }, [formatMessage]);

  return (
    <Div layout="grid 12">
      <Div>
        <Span className="header">{formatMessage(TXT("label.reasons"))}</Span>
      </Div>
      {model.passengerReasons.map((passengerReason, passengerReasonIndex) =>
        <Div key={passengerReasonIndex}>
          <FieldSet label={`${formatMessage(TXT("label.passenger"))}: ${formatPassenger(passengerReason.passenger)}`}>
            <OrderReturnReasonItem model={passengerReason}
              index={passengerReasonIndex}
              validatorValue={isValid(passengerReason)}
              validator={isValidValidator}
              onChange={onChange ? handlePassengerReasonChange : undefined} />
          </FieldSet>
        </Div>
      )}
      {onChange &&
        <Div>
          <FieldSet label={formatMessage(TXT("label.commonReason"))}>
            <OrderReturnReasonItem model={model.commonReason}
              index={-1}
              validatorValue={isValid(null)}
              validator={isValidValidator}
              onChange={handleCommonReasonChange} />
          </FieldSet>
        </Div>
      }
    </Div>
  );
}

export interface OrderReturnRequestViewProps extends ItemViewProps<Order, OrderUpdateModel> {
  onCalc?: (model: OrderUpdateModel) => Promise<Order | null>;
}

export function OrderReturnRequestView(props: OrderReturnRequestViewProps): ReactElement {
  const { item, lock, readonly, onCalc, onSubmit, onCancel } = props;

  const formatMessage = useFormatMessage();
  const formatCost = useFormatCost();

  const [model, setModel] = useState<OrderUpdateModel>(new OrderUpdateModel());
  const [type, setType] = useState<SeatStatus.Fined | SeatStatus.Forced | null>(null);
  const [reason, setReason] = useState<OrderReturnReasonModel | null>(null);
  const [calculated, setCalculated] = useState<boolean>(false);
  useEffect(() => {
    const model = new OrderUpdateModel();
    model.resources = item.spans.flatMap(span => span.resources).map(ratedResource => {
      const updatedResource = new UpdatedResource();
      updatedResource.id = ratedResource.id;
      updatedResource.flights = ratedResource.flights
      updatedResource.resource = ratedResource.resource;
      updatedResource.passengers = ratedResource.passengers.map(ratedPassenger => {
        const updatedPassenger = new UpdatedPassenger();
        updatedPassenger.id = ratedPassenger.id;
        updatedPassenger.return = ratedPassenger.status === SeatStatus.Fined || ratedPassenger.status === SeatStatus.Forced;
        updatedPassenger.forced = ratedPassenger.status === SeatStatus.Forced;

        return updatedPassenger;
      });
      updatedResource.services = (ratedResource.services || []).filter(x =>x.status === SeatStatus.Paid).map(ratedService => {
        const updatedService = new UpdatedService();
        updatedService.id = ratedService.id;
        updatedService.service = ratedService.service;
        updatedService.count = ratedService.count;
        updatedService.from = ratedService.from;
        updatedService.till = ratedService.till;
        updatedService.fields = ratedService.fields;
        updatedService.return = ratedService.status === SeatStatus.Fined || ratedService.status === SeatStatus.Forced;

        return updatedService;
      });

      return updatedResource;
    });
    model.contactName = item.contactName;
    model.contactEmail = item.contactEmail;
    model.contactPhone = item.contactPhone;
    model.description = item.description;

    setModel(model);
  }, [item, setModel]);

  const [disabled, setDisabled] = useState<boolean>(true);
  useEffect(() => {
    setDisabled(
      (model.resources.flatMap(r => r.passengers).every(p => !p.return) &&
        model.resources.flatMap(r => r.services || []).every(s => !s.return)) ||
      type === null);
  }, [model, type, setDisabled]);

  const handleModelChange = useCallback((model: OrderUpdateModel): void => {
    setModel(model);
    setCalculated(false);
  }, [setCalculated, setModel]);

  const handleTypeChange = useCallback((value: SeatStatus.Fined | SeatStatus.Forced): void => {
    setType(value);
    setModel(prev => {
      const curr = { ...prev };
      curr.resources.flatMap(r => r.passengers).forEach(p => {
        p.forced = value === SeatStatus.Forced;
      });

      return curr;
    });
    setCalculated(false);
  }, [setModel, setType, setCalculated]);

  const handleCalc = useCallback(async (): Promise<void> => {
    if (onCalc) {
      await onCalc(model);
      setCalculated(true);
    }
  }, [model, onCalc]);

  const handleReason = useCallback(async (): Promise<void> => {
    if (item && type === SeatStatus.Forced) {
      const ratedPassengers = item.spans.flatMap(s => s.resources).flatMap(r => r.passengers);
      const modifiedPassengers = model.resources.flatMap(r => r.passengers).filter(p => p.return);
      const passengers: Passenger[] = [];
      item.passengers.forEach(passenger => {
        if (ratedPassengers.filter(p => p.passenger.id === passenger.id).map(p => p.id).some(id => modifiedPassengers.some(p => p.id === id))) {
          passengers.push(passenger);
        }
      });

      setReason({
        passengerReasons: passengers.map(passenger => ({ passenger, reasonText: null, reasonAttachments: null })),
        commonReason: { reasonText: null, reasonAttachments: null },
      });
    }
  }, [item, model, type, setReason]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      if (reason) {
        model.resources.forEach(modifiedResource => {
          const ratedResource = item.spans.flatMap(span => span.resources).filter(ratedResource => ratedResource.id === modifiedResource.id)[0] || null;
          if (ratedResource) {
            modifiedResource.passengers.forEach(modifiedPassenger => {
              const passengerId = ratedResource.passengers.filter(ratedPassenger => ratedPassenger.id === modifiedPassenger.id).map(ratedPassenger => ratedPassenger.passenger.id)[0] || null;
              const passengerReason = reason.passengerReasons.filter(passengerReason => passengerReason.passenger.id === passengerId)[0] || null;
              modifiedPassenger.reason = passengerReason && passengerReason.reasonText
                ? [passengerReason.reasonText, reason.commonReason.reasonText || ""].join("\n")
                : reason.commonReason.reasonText;
              modifiedPassenger.attachments = passengerReason && passengerReason.reasonAttachments
                ? [...passengerReason.reasonAttachments, ...(reason.commonReason.reasonAttachments || [])]
                : reason.commonReason.reasonAttachments;
            });
          }
        });
      }

      await onSubmit(model, type === SeatStatus.Forced);
    }
  }, [item, model, type, reason, onSubmit]);

  return (
    <Form lock={lock}
      loaded={true}
      readonly={readonly}
      allowSubmit={!disabled}
      labelSubmit={!calculated ? TXT("action.calculate") : type === SeatStatus.Fined ? TXT("action.apply") : reason === null ? TXT("action.next") : TXT("action.request")}
      onSubmit={!calculated ? handleCalc : type === SeatStatus.Fined ? handleSubmit : reason === null ? handleReason : handleSubmit}
      onCancel={onCancel}>
      <Div layout="grid 12">
        {(reason === null || type === null || type === SeatStatus.Fined) &&
          <Div>
            <OrderReturnGrid item={item}
              model={model}
              calculated={calculated}
              onChange={handleModelChange} />
          </Div>
        }
        {(reason === null || type === null || type === SeatStatus.Fined) &&
          <Div>
            <OrderReturnType type={type}
              onChange={handleTypeChange} />
          </Div>
        }
        {reason !== null && type === SeatStatus.Forced && reason &&
          <Div>
            <OrderReturnReasonList item={item}
              model={reason}
              onChange={setReason} />
          </Div>
        }
        {(calculated && item.status !== OrderStatus.Rejected && item.refund - item.penalty > 0) &&
          <Div>
            <Span intent="success">
              {`${formatMessage(TXT("label.refund"))}: ${formatCost(item.refund - item.penalty, item.currency.code)}`}
            </Span>
          </Div>
        }
        {(calculated && item.status !== OrderStatus.Rejected && item.unpaid > 0) &&
          <Div>
            <Span intent="warning">
              {`${formatMessage(TXT("label.rest"))}: ${formatCost(item.unpaid, item.currency.code)}`}
            </Span>
          </Div>
        }
        {(calculated && item.status !== OrderStatus.Rejected && type === SeatStatus.Fined) &&
          <Div>
            <Span intent="danger">
              {`${formatMessage(TXT("label.fine"))}: ${formatCost(item.penalty, item.currency.code)}`}
            </Span>
          </Div>
        }
      </Div>
    </Form>
  );
}

export interface OrderReturnConfirmViewProps extends ItemViewProps<Order> {
  reasons: OrderReturn;
  onReject: () => Promise<void>;
  onConfirm: () => Promise<void>;
}

export function OrderReturnConfirmView(props: OrderReturnConfirmViewProps): ReactElement {
  const { item, reasons, onCancel, onReject, onConfirm } = props;

  const formatMessage = useFormatMessage();
  const formatCost = useFormatCost();

  const [model, setModel] = useState<OrderUpdateModel>(new OrderUpdateModel());
  const [reason, setReason] = useState<OrderReturnReasonModel | null>(null);
  useEffect(() => {
    const model = new OrderUpdateModel();
    model.resources = item.spans.flatMap(span => span.resources).map(ratedResource => {
      const updatedResource = new UpdatedResource();
      updatedResource.id = ratedResource.id;
      updatedResource.flights = ratedResource.flights
      updatedResource.resource = ratedResource.resource;
      updatedResource.passengers = ratedResource.passengers.map(ratedPassenger => {
        const updatedPassenger = new UpdatedPassenger();
        updatedPassenger.id = ratedPassenger.id;
        updatedPassenger.return = ratedPassenger.status === SeatStatus.Fined || ratedPassenger.status === SeatStatus.Forced;
        updatedPassenger.forced = ratedPassenger.status === SeatStatus.Forced;

        return updatedPassenger;
      });
      updatedResource.services = (ratedResource.services || []).map(ratedService => {
        const updatedService = new UpdatedService();
        updatedService.id = ratedService.id;
        updatedService.service = ratedService.service;
        updatedService.count = ratedService.count;
        updatedService.from = ratedService.from;
        updatedService.till = ratedService.till;
        updatedService.fields = ratedService.fields;
        updatedService.return = ratedService.status === SeatStatus.Fined || ratedService.status === SeatStatus.Forced;

        return updatedService;
      });

      return updatedResource;
    });
    model.contactName = item.contactName;
    model.contactEmail = item.contactEmail;
    model.contactPhone = item.contactPhone;
    model.description = item.description;

    setModel(model);
    setReason({
      passengerReasons: reasons.reasons.map(reason => ({
        passenger: reason.passenger,
        reasonText: reason.description,
        reasonAttachments: reason.attachments,
      })),
      commonReason: {
        reasonText: null,
        reasonAttachments: null,
      }
    });
  }, [item, reasons, setModel, setReason]);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleReject = useCallback(async (): Promise<void> => {
    try {
      setSubmitting(true);
      await onReject();
    } catch {
      setSubmitting(false);
    }
  }, [setSubmitting, onReject]);

  const handleConfirm = useCallback(async (): Promise<void> => {
    try {
      setSubmitting(true);
      await onConfirm();
    } catch {
      setSubmitting(false);
    }
  }, [setSubmitting, onConfirm]);

  return (
    <Form loaded={true}
      readonly={true}
      onCancel={onCancel}>
      <Div layout="grid 12">
        <Div>
          <OrderReturnGrid item={item}
            model={model}
            calculated={false} />
        </Div>
        {reason &&
          <Div>
            <OrderReturnReasonList item={item}
              model={reason} />
          </Div>
        }
        <Div>
          <Div layout="flex">
            <Div layout="fill">
              {(item.refund > 0) &&
                <Div>
                  <Span intent="success">
                    {`${formatMessage(TXT("label.refund"))}: ${formatCost(item.refund, item.currency.code)}`}
                  </Span>
                </Div>
              }
              {(item.unpaid > 0) &&
                <Div>
                  <Span intent="warning">
                    {`${formatMessage(TXT("label.rest"))}: ${formatCost(item.unpaid, item.currency.code)}`}
                  </Span>
                </Div>
              }
              {(item.penalty > 0) &&
                <Div>
                  <Span intent="danger">
                    {`${formatMessage(TXT("label.fine"))}: ${formatCost(item.penalty, item.currency.code)}`}
                  </Span>
                </Div>
              }
            </Div>
            <Div layout="fit">
              <Button intent="danger"
                look="outline"
                className="action"
                disabled={submitting}
                onClick={handleReject}>
                {formatMessage(TXT("action.reject"))}
              </Button>
              <Button primary={true}
                look="outline"
                className="action"
                disabled={submitting}
                onClick={handleConfirm}>
                {formatMessage(TXT("action.confirm"))}
              </Button>
            </Div>
          </Div>
        </Div>
      </Div>
    </Form>
  );
}
