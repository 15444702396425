import { ID, IntlMessage } from "../Intl";

export function range(value: number | null, min?: number, max?: number): IntlMessage | undefined {
  if (value !== null) {
    if (min !== undefined && value < min) {
      return ID("valid.mustBeMoreThan", { min });
    }
    if (max !== undefined && value > max) {
      return ID("valid.mustBeLessThan", { max });
    }
  }

  return undefined;
}
