import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AuthContext } from '../../auth'
import { Chunk, Pair } from '../../data'
import { OrganizationBaseV1, OrganizationFreeAccessSettingsV1, OrganizationFreeAccessTypeV1, OrganizationTypeV1, OrganizationV1, PaymentRequisitesV1 } from '../../data/models'
import { OrganizationAccess, OrganizationAccessV1 } from '../data/access'
import { Airport, CurrencySettings, EVoucherSettings, FreeAccessSettings, LegalRequisites, Organization, OrganizationFilter, OrganizationType, PaymentRequisites, ResolvedOrganization, Resource, ResourceDefaults } from '../data/models'
import { FetchList, PairConverter, PairData } from './types'

export function useOrganizations(pageSize?: number, prefetch?: boolean, filter?: OrganizationFilter): [ Chunk<ResolvedOrganization>, FetchList ] {
  const [ data, setData ] = useState<Chunk<ResolvedOrganization>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await OrganizationAccess.getAll(pattern, undefined, skip || 0, take || pageSize, filter)
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, filter, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganization(organizationId: string | null): [ Organization | null, Dispatch<SetStateAction<Organization | null>> ] {
  const [ item, setItem ] = useState<Organization | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOne(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationResources(organizationId: string | null, pageSize?: number, prefetch?: boolean, isAuthorized?: boolean): [
  Chunk<Resource>,
  FetchList,
] {
  const [ data, setData ] = useState<Chunk<Resource>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      if (organizationId) {
        const data = await OrganizationAccess.getOneResources(organizationId, pattern, undefined, skip || 0, take || pageSize)
        setData(data)
      } else {
        setData(new Chunk())
      }
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, organizationId, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganizationDefaults(organizationId: string | null): [ ResourceDefaults | null, Dispatch<SetStateAction<ResourceDefaults | null>> ] {
  const [ item, setItem ] = useState<ResourceDefaults | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOneDefaults(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationFreeAccessSettings(organizationId: string | null): [ FreeAccessSettings | null, Dispatch<SetStateAction<FreeAccessSettings | null>> ] {
  const [ item, setItem ] = useState<FreeAccessSettings | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId !== null) {
          const item = await OrganizationAccess.getOneFreeAccessSettings(organizationId)
          setItem(item)
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationVoucherSettings(organizationId: string | null): [ EVoucherSettings | null, Dispatch<SetStateAction<EVoucherSettings | null>> ] {
  const [ item, setItem ] = useState<EVoucherSettings | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOneVoucherSettings(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationCurrency(organizationId: string | null): [ CurrencySettings | null, Dispatch<SetStateAction<CurrencySettings | null>> ] {
  const [ item, setItem ] = useState<CurrencySettings | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOneCurrencySettings(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationLegalRequisites(organizationId: string | null): [ LegalRequisites | null, Dispatch<SetStateAction<LegalRequisites | null>> ] {
  const [ item, setItem ] = useState<LegalRequisites | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOneLegalRequisites(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationPaymentRequisites(organizationId: string | null): [ Chunk<PaymentRequisites> | null, Dispatch<SetStateAction<Chunk<PaymentRequisites> | null>> ] {
  const [ item, setItem ] = useState<Chunk<PaymentRequisites> | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOnePaymentRequisites(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationAirports(organizationId: string | null): [ Chunk<Airport> | null, Dispatch<SetStateAction<Chunk<Airport> | null>> ] {
  const [ item, setItem ] = useState<Chunk<Airport> | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (organizationId) {
          setItem(await OrganizationAccess.getOneAirports(organizationId))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ organizationId, setItem ])

  return [ item, setItem ]
}

export function useOrganizationV1(id?: number | string | null): OrganizationV1 | null {
  const auth = useContext(AuthContext)
  const code = auth.profile.belongsTo

  const [ item, setItem ] = useState<OrganizationV1 | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id === undefined) {
          setItem(await OrganizationAccessV1.fetchItem(code))
        } else if (id !== null) {
          setItem(await OrganizationAccessV1.fetchItem(id))
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ id, code, setItem ])

  return item
}

export function useOrganizationsV1(pageSize?: number, prefetch?: boolean, type?: OrganizationTypeV1,
                                   withContract?: boolean, forProviderId?: number, forAgencyId?: number): [
  Chunk<OrganizationBaseV1>,
  FetchList,
] {
  const [ data, setData ] = useState<Chunk<OrganizationBaseV1>>(new Chunk())

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      const data = await OrganizationAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize,
        type, withContract, forProviderId, forAgencyId)
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }, [ pageSize, type, withContract, forProviderId, forAgencyId, setData ])

  useEffect(() => {
    if (prefetch) {
      fetch().finally()
    }
  }, [ prefetch, fetch ])

  return [ data, fetch ]
}

export function useOrganizationPaymentRequisitesV1(id?: number | string | null): PaymentRequisitesV1[] | null {
  const auth = useContext(AuthContext)
  const code = auth.profile.belongsTo

  const [ item, setItem ] = useState<PaymentRequisitesV1[] | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id !== null) {
          const item = await OrganizationAccessV1.fetchPaymentRequisites(id || code)
          setItem(item)
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ id, code, setItem ])

  return item
}

export function useOrganizationTypes(): [ PairData<OrganizationType>, PairConverter<OrganizationType> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<OrganizationType>>([
    new Pair<OrganizationType>(OrganizationType.System, intl.formatMessage({ id: 'enum.organizationType.system' })),
    new Pair<OrganizationType>(OrganizationType.Provider, intl.formatMessage({ id: 'enum.organizationType.provider' })),
    new Pair<OrganizationType>(OrganizationType.Agency, intl.formatMessage({ id: 'enum.organizationType.agency' })),
  ])

  const converter = useCallback((code: OrganizationType): string => {
    switch (code) {
      case OrganizationType.System:
        return intl.formatMessage({ id: 'enum.organizationType.system' })
      case OrganizationType.Provider:
        return intl.formatMessage({ id: 'enum.organizationType.provider' })
      case OrganizationType.Agency:
        return intl.formatMessage({ id: 'enum.organizationType.agency' })
      default:
        return ''
    }
  }, [ intl ])

  return [ data, converter ]
}

export function useOrganizationTypesV1(): [ PairData<OrganizationTypeV1>, PairConverter<OrganizationTypeV1> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<OrganizationTypeV1>>([
    new Pair<OrganizationTypeV1>(OrganizationTypeV1.System, intl.formatMessage({ id: 'enum.organizationType.system' })),
    new Pair<OrganizationTypeV1>(OrganizationTypeV1.Provider, intl.formatMessage({ id: 'enum.organizationType.provider' })),
    new Pair<OrganizationTypeV1>(OrganizationTypeV1.Agency, intl.formatMessage({ id: 'enum.organizationType.agency' })),
  ])

  const converter = useCallback((code: OrganizationTypeV1): string => {
    switch (code) {
      case OrganizationTypeV1.System:
        return intl.formatMessage({ id: 'enum.organizationType.system' })
      case OrganizationTypeV1.Provider:
        return intl.formatMessage({ id: 'enum.organizationType.provider' })
      case OrganizationTypeV1.Agency:
        return intl.formatMessage({ id: 'enum.organizationType.agency' })
      default:
        return ''
    }
  }, [ intl ])

  return [ data, converter ]
}

export function useOrganizationFreeAccessTypesV1(): [ PairData<OrganizationFreeAccessTypeV1>, PairConverter<OrganizationFreeAccessTypeV1> ] {
  const intl = useIntl()

  const [ data ] = useState<PairData<OrganizationFreeAccessTypeV1>>([
    new Pair<OrganizationFreeAccessTypeV1>(OrganizationFreeAccessTypeV1.BusinessPassenger, intl.formatMessage({ id: 'enum.organizationFreeAccessType.business' })),
    new Pair<OrganizationFreeAccessTypeV1>(OrganizationFreeAccessTypeV1.LoyaltyProgram, intl.formatMessage({ id: 'enum.organizationFreeAccessType.loyalty' })),
    new Pair<OrganizationFreeAccessTypeV1>(OrganizationFreeAccessTypeV1.AccessProgram, intl.formatMessage({ id: 'enum.organizationFreeAccessType.program' })),
  ])

  const converter = useCallback((code: OrganizationFreeAccessTypeV1): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || ''
  }, [ data ])

  return [ data, converter ]
}

export function useOrganizationFreeAccessSettingsV1(id?: string | number | null): OrganizationFreeAccessSettingsV1 | null {
  const auth = useContext(AuthContext)
  const code = auth.profile.belongsTo

  const [ item, setItem ] = useState<OrganizationFreeAccessSettingsV1 | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id !== null) {
          const item = await OrganizationAccessV1.fetchFreeAccessSettings(id || code)
          setItem(item)
        } else {
          setItem(null)
        }
      } catch (error) {
        setItem(null)
      }
    })()
  }, [ id, code, setItem ])

  return item
}
