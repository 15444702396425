import { EMPTY_ID, ResourceReference, ServiceReference, ServiceType } from './index'

export enum MediaKind {
  Document = 'Document',
  Image = 'Image',
  Video = 'Video',
  Map = 'Map',
}

export class MediaReference {
  public id: string

  public constructor(id?: string) {
    this.id = id || EMPTY_ID
  }
}

export class MediaBase extends MediaReference {
  public name: string
  public kind: MediaKind
  public type: ServiceType
  public language: string | null
  public index: number
  public fileId: string

  public constructor() {
    super()
    this.name = ''
    this.kind = MediaKind.Document
    this.type = ServiceType.Any
    this.language = null
    this.index = 0
    this.fileId = EMPTY_ID
  }
}

export class Media extends MediaBase {
  public description: string | null

  public constructor() {
    super()
    this.description = null
  }
}

export class MediaUpdateModel {
  public fileId: string
  public index: number
  public name: string
  public kind: MediaKind
  public type: ServiceType
  public language: string | null
  public description: string | null

  public constructor() {
    this.fileId = ''
    this.index = 0
    this.name = ''
    this.kind = MediaKind.Document
    this.type = ServiceType.Any
    this.language = null
    this.description = null
  }
}

export class ResourceMediaCreateModel extends MediaUpdateModel {
  public resource: ResourceReference

  public constructor() {
    super()
    this.resource = new ResourceReference()
  }
}

export class ServiceMediaCreateModel extends MediaUpdateModel {
  public service: ServiceReference

  public constructor() {
    super()
    this.service = new ServiceReference()
  }
}
