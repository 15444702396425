import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Chunk, Lock, LockAction } from '../data'
import { ID, NavPathItem } from '../gears'
import { LockAccess, OrganizationAccess, ResourceAccess, ScheduleAccess, ServiceAccess } from '../parts/data/access'
import { EMPTY_ID, isAssistance, isLounge, isTransfer, Media, MediaKind, ResolvedResource, ResolvedSchedule, ResolvedService, ResourceCreateModel, ResourceTranslation, ScheduleCreateModel, ServiceCreateModel, ServiceKind, ServiceTranslation } from '../parts/data/models'
import { Div, Loader, Menu, MenuItem, TXT } from '../parts/gears'
import { ResourceGrid, ResourceItem, ScheduleGrid, ScheduleItem, ServiceGrid, ServiceItem, VehicleGrid, VehicleItem } from '../parts/views'
import { PageProps, withPage } from './withPage'

function ResourcesPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props
  const basePath = match.url

  return (
    <Switch>
      <Route exact={true}
        path={`${basePath}/:resourceId/schedules/:scheduleId`}
        render={props => <ScheduleItemPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId/services/:serviceId`}
        render={props => <ServiceItemPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId/vehicles/:vehicleId`}
        render={props => <VehicleItemPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId/schedules`}
        render={props => <ScheduleListPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId/services`}
        render={props => <ServiceListPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId/vehicles`}
        render={props => <VehicleListPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}/:resourceId`}
        render={props => <ResourceItemPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
        path={`${basePath}`}
        render={props => <ResourceListPage {...props} basePath={basePath} onLoaded={onLoaded}
          onNavigate={onNavigate} onError={onError} />} />
    </Switch>
  )
}

interface ResourceItemPageProps extends RouteComponentProps<{ resourceId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ResourceItemPage(props: ResourceItemPageProps): ReactElement {
  const { location, match, basePath, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const itemId = match.params.resourceId || 'new'
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState(new ResolvedResource())
  const [images, setImages] = useState<Media[]>([])
  const [users, setUsers] = useState<string[] | null>(null)
  const [maps, setMaps] = useState<Media[]>([])
  const [docs, setDocs] = useState<Media[]>([])
  const [translations, setTranslations] = useState<ResourceTranslation[]>([])
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const item = await ResourceAccess.getOne(itemId)
            setItem(item)

            const images = await ResourceAccess.getOneMedias(itemId, MediaKind.Image).then((chunk) => chunk.data)
            setImages(images)

            const maps = await ResourceAccess.getOneMedias(itemId, MediaKind.Map).then((chunk) => chunk.data)
            setMaps(maps)

            const docs = await ResourceAccess.getOneMedias(itemId, MediaKind.Document).then((chunk) => chunk.data)
            setDocs(docs)

            const translations = await ResourceAccess.getOneTranslations(itemId)
            setTranslations(translations)

            if (auth.profile.isInRole('system.admin', 'owner.super')) {
              const users = await ResourceAccess.getOneSubscribers(itemId)
              setUsers(users)
            }

            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            if (!auth.profile.isSys) {
              const organization = await OrganizationAccess.getOne(auth.profile.belongsTo)
              setItem(prev => ({
                ...prev,
                organization,
              }))
            }
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId, auth.profile,
    setLoaded, setLoading, setItem, setImages, setMaps, setDocs, setTranslations, setLock, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: item.id !== EMPTY_ID ? `${item.id}` : 'new',
          text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : ID('page.resources.new'),
        },
      ])
    }
  }, [loaded, item, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(``)
    }
  }, [setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: ResourceCreateModel & { images: Media[], maps: Media[], docs: Media[], translations: ResourceTranslation[] }): Promise<void> => {
    try {
      const { images, maps, docs, translations, ...resource } = item
      if (itemId !== 'new') {
        const updated = await ResourceAccess.update(itemId, resource).then(() => ResourceAccess.getOne(itemId))
        await ResourceAccess.updateMedia(updated.id, images, MediaKind.Image)
        await ResourceAccess.updateMedia(updated.id, maps, MediaKind.Map)
        await ResourceAccess.updateMedia(updated.id, docs, MediaKind.Document)
        await ResourceAccess.updateTranslations(updated.id, translations)
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await ResourceAccess.create(resource)
        await ResourceAccess.updateMedia(created.id, images, MediaKind.Image)
        await ResourceAccess.updateMedia(created.id, maps, MediaKind.Map)
        await ResourceAccess.updateMedia(created.id, docs, MediaKind.Document)
        await ResourceAccess.updateTranslations(created.id, translations)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await ResourceAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <Div layout="grid 12">
        {props.match.params.resourceId !== 'new' &&
          <Div>
            <Menu>
              <MenuItem title={TXT('label.resource')}
                path={`${basePath}/${itemId}`} />
              <MenuItem title={TXT('label.schedules')}
                path={`${basePath}/${itemId}/schedules`} />
              {(isLounge(item.kind) || isAssistance(item.kind) || item.kind === ServiceKind.VirtualLounge) &&
                <MenuItem title={TXT('label.services')}
                  path={`${basePath}/${itemId}/services`} />
              }
              {isTransfer(item.kind) &&
                <MenuItem title={TXT('label.vehicles')} path={`${basePath}/${itemId}/vehicles`} />
              }
            </Menu>
          </Div>
        }
        <Div>
          <ResourceItem item={{ ...item, images, maps, docs, translations, users }}
            lock={lock}
            readonly={readonly}
            showProviders={auth.profile.isSys}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onDelete={itemId !== 'new' ? handleDelete : undefined}
            onEdit={handleEdit}
            onFree={handleFree} />
        </Div>
      </Div>
    </Loader>
  )
}

interface ResourceListPageProps extends RouteComponentProps {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ResourceListPage(props: ResourceListPageProps): ReactElement {
  const { onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Chunk<ResolvedResource>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (auth.profile.isSys) {
            const data = await ResourceAccess.getAll(pattern, sort, skip, take, { includeDeactivated: true })
            setData(data)
          } else {
            // const belongsTo = await OrganizationAccess.getOne(auth.profile.belongsTo)
            const data = await ResourceAccess.getAll(pattern, sort, skip, take, { /*byProviderId: belongsTo.id,*/ includeDeactivated: true })
            setData(data)
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading,
    auth.profile.belongsTo, auth.profile.isSys,
    pattern, sort, skip, take, setData,
    setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([])
    }
  }, [loaded, onLoaded])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`)
  }, [onNavigate])

  const handleItemSelect = useCallback((record: ResolvedResource): void => {
    onNavigate(`${record.id}`)
  }, [onNavigate])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <ResourceGrid data={data}
        showProviders={auth.profile.isSys}
        onItemCreate={handleItemCreate}
        onItemSelect={handleItemSelect}
        onItemSearch={handleItemSearch}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange} />
    </Loader>
  )
}

interface ScheduleItemPageProps extends RouteComponentProps<{ resourceId: string, scheduleId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ScheduleItemPage(props: ScheduleItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)

  const resourceId = match.params.resourceId
  const itemId = match.params.scheduleId
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState(new ResolvedResource())
  const [item, setItem] = useState(new ResolvedSchedule())
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          if (!isNew) {
            const item = await ScheduleAccess.getOne(itemId)
            setItem(item)
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          }
          setItem(prev => ({
            ...prev,
            resource,
          }))
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId, resourceId,
    auth.profile.isSys, auth.profile.belongsTo,
    setLoaded, setLoading, setItem, setResource, setLock, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/schedules`,
          text: `${resource.code} — ${resource.name}`,
        },
        {
          path: item.id !== EMPTY_ID ? `${item.resource.id}/schedules/${item.id}` : `${item.resource.id}/schedules/new`,
          text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : ID('page.schedules.new'),
        },
      ])
    }
  }, [loaded, item, resource, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${resourceId}/schedules/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(`${resourceId}/schedules`)
    }
  }, [resourceId, setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: ScheduleCreateModel): Promise<void> => {
    try {
      if (itemId !== 'new') {
        const updated = await ScheduleAccess.update(itemId, item).then(() => ScheduleAccess.getOne(itemId))
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await ScheduleAccess.create(item)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await ScheduleAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <ScheduleItem item={item}
        lock={lock}
        readonly={readonly}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDelete={itemId !== 'new' ? handleDelete : undefined}
        onEdit={handleEdit}
        onFree={handleFree} />
    </Loader>
  )
}

interface ScheduleListPageProps extends RouteComponentProps<{ resourceId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ScheduleListPage(props: ScheduleListPageProps): ReactElement {
  const { match, basePath, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState<ResolvedResource>(new ResolvedResource())
  const [data, setData] = useState<Chunk<ResolvedSchedule>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          const data = await ScheduleAccess.getAll(pattern, sort, skip, take, { byResourceId: resourceId })
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, resourceId,
    pattern, sort, skip, take, setData, setResource,
    setLoaded, setLoading, onError,
  ])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/schedules`,
          text: `${resource.code} — ${resource.name}`,
        },
      ])
    }
  }, [loaded, resource, onLoaded])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`${resourceId}/schedules/new`)
  }, [resourceId, onNavigate])

  const handleItemSelect = useCallback((record: ResolvedSchedule): void => {
    onNavigate(`${resourceId}/schedules/${record.id}`)
  }, [resourceId, onNavigate])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <Div layout="grid 12">
        <Div>
          <Menu>
            <MenuItem title={TXT('label.resource')}
              path={`${basePath}/${resourceId}`} />
            <MenuItem title={TXT('label.schedules')}
              path={`${basePath}/${resourceId}/schedules`} />
            {(isLounge(resource.kind) || isAssistance(resource.kind) || resource.kind === ServiceKind.VirtualLounge) &&
              <MenuItem title={TXT('label.services')}
                path={`${basePath}/${resourceId}/services`} />
            }
            {isTransfer(resource.kind) &&
              <MenuItem title={TXT('label.vehicles')} path={`${basePath}/${resourceId}/vehicles`} />
            }
          </Menu>
        </Div>
        <Div>
          <ScheduleGrid data={data}
            onItemCreate={handleItemCreate}
            onItemSelect={handleItemSelect}
            onItemSearch={handleItemSearch}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange} />
        </Div>
      </Div>
    </Loader>
  )
}

interface ServiceItemPageProps extends RouteComponentProps<{ resourceId: string, serviceId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ServiceItemPage(props: ServiceItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId
  const itemId = match.params.serviceId
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState(new ResolvedResource())
  const [item, setItem] = useState(new ResolvedService())
  const [images, setImages] = useState<Media[]>([])
  const [translations, setTranslations] = useState<ServiceTranslation[]>([])
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          if (!isNew) {
            const item = await ServiceAccess.getOne(itemId)
            setItem(item)

            const images = await ServiceAccess.getOneMedias(itemId, MediaKind.Image).then((chunk) => chunk.data)
            setImages(images)

            const translations = await ServiceAccess.getOneTranslations(itemId)
            setTranslations(translations)

            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            setItem(prev => ({
              ...prev,
              resource: resource,
            }))
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, isNew, readonly, itemId, resourceId, setLoaded, setLoading, setLock, setItem, setImages, setResource, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/services`,
          text: `${resource.code} — ${resource.name}`,
        },
        {
          path: item.id !== EMPTY_ID ? `${item.resource.id}/services/${item.id}` : `${item.resource.id}/service/new`,
          text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : ID('page.services.new'),
        },
      ])
    }
  }, [loaded, item, resource, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${resourceId}/services/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(`${resourceId}`)
    }
  }, [resourceId, setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: ServiceCreateModel & { images: Media[], translations: ServiceTranslation[] }): Promise<void> => {
    try {
      const { images, translations, ...model } = item
      if (itemId !== 'new') {
        const updated = await ServiceAccess.update(itemId, model).then(() => ServiceAccess.getOne(itemId))
        await ServiceAccess.updateMedia(updated.id, images, MediaKind.Image)
        await ServiceAccess.updateTranslations(updated.id, translations)
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await ServiceAccess.create(model)
        await ServiceAccess.updateMedia(created.id, images, MediaKind.Image)
        await ServiceAccess.updateTranslations(created.id, translations)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await ServiceAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <Div layout="grid 12">
        <Div>
          <ServiceItem item={{ ...item, images, translations }}
            lock={lock}
            readonly={readonly}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onDelete={itemId !== 'new' ? handleDelete : undefined}
            onEdit={handleEdit}
            onFree={handleFree} />
        </Div>
      </Div>
    </Loader>
  )
}

interface ServiceListPageProps extends RouteComponentProps<{ resourceId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function ServiceListPage(props: ServiceListPageProps): ReactElement {
  const { match, basePath, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState(new ResolvedResource())
  const [data, setData] = useState<Chunk<ResolvedService>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          const data = await ServiceAccess.getAll(pattern, sort, skip, take, { byResourceId: resourceId })
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, pattern, sort, skip, take, resourceId, setResource, setData, setLoaded, setLoading, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/services`,
          text: `${resource.code} — ${resource.name}`,
        },
      ])
    }
  }, [loaded, resource, onLoaded])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`${resourceId}/services/new`)
  }, [resourceId, onNavigate])

  const handleItemSelect = useCallback((record: ResolvedService): void => {
    onNavigate(`${resourceId}/services/${record.id}`)
  }, [resourceId, onNavigate])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <Div layout="grid 12">
        <Div>
          <Menu>
            <MenuItem title={TXT('label.resource')}
              path={`${basePath}/${resourceId}`} />
            <MenuItem title={TXT('label.schedules')}
              path={`${basePath}/${resourceId}/schedules`} />
            {(isLounge(resource.kind) || isAssistance(resource.kind) || resource.kind === ServiceKind.VirtualLounge) &&
              <MenuItem title={TXT('label.services')}
                path={`${basePath}/${resourceId}/services`} />
            }
          </Menu>
        </Div>
        <Div>
          <ServiceGrid data={data}
            onItemCreate={handleItemCreate}
            onItemSelect={handleItemSelect}
            onItemSearch={handleItemSearch}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange} />
        </Div>
      </Div>
    </Loader>
  )
}

interface VehicleItemPageProps extends RouteComponentProps<{ resourceId: string, vehicleId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function VehicleItemPage(props: VehicleItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId
  const itemId = match.params.vehicleId
  const isNew = itemId === 'new'
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState(new ResolvedResource())
  const [item, setItem] = useState(new ResolvedService())
  const [images, setImages] = useState<Media[]>([])
  const [lock, setLock] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          if (!isNew) {
            const item = await ServiceAccess.getOne(itemId)
            setItem(item)
            const images = await ServiceAccess.getOneMedias(itemId, MediaKind.Image).then((chunk) => chunk.data)
            setImages(images)
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
            setLock(lock)
          } else {
            setItem(prev => ({
              ...prev,
              resource: resource,
            }))
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, isNew, readonly, itemId, resourceId, setLoaded, setLoading, setLock, setItem, setImages, setResource, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/vehicles`,
          text: `${resource.code} — ${resource.name}`,
        },
        {
          path: item.id !== EMPTY_ID ? `${item.resource.id}/vehicles/${item.id}` : `${item.resource.id}/vehicles/new`,
          text: item.id !== EMPTY_ID ? `${item.code} — ${item.name}` : ID('page.vehicles.new'),
        },
      ])
    }
  }, [loaded, item, resource, onLoaded])

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId && itemId !== 'new') {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free)
        }
        onNavigate(`${resourceId}/vehicles/${itemId}${edit ? '?edit' : ''}`)
        setLoaded(false)
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      onNavigate(`${resourceId}`)
    }
  }, [resourceId, setLoaded, setLoading, onNavigate])

  const handleSubmit = useCallback(async (item: ServiceCreateModel & { images: Media[] }): Promise<void> => {
    try {
      const { images, ...model } = item
      if (itemId && itemId !== 'new') {
        const updated = await ServiceAccess.update(itemId, model).then(() => ServiceAccess.getOne(itemId))
        await ServiceAccess.updateMedia(updated.id, images, MediaKind.Image)
        await handleNavigate(updated.id, false, true)
      } else {
        const created = await ServiceAccess.create(model)
        await ServiceAccess.updateMedia(created.id, images, MediaKind.Image)
        await handleNavigate(created.id, false, false)
      }
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await ServiceAccess.delete(itemId)
      await handleNavigate()
    } catch (error) {
      onError(error)
    }
  }, [itemId, handleNavigate, onError])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false)
  }, [itemId, handleNavigate])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true)
  }, [itemId, handleNavigate])

  return (
    <Loader loading={loading}>
      <VehicleItem item={{ ...item, images }}
        lock={lock}
        readonly={readonly}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDelete={itemId !== 'new' ? handleDelete : undefined}
        onEdit={handleEdit}
        onFree={handleFree} />
    </Loader>
  )
}

interface VehicleListPageProps extends RouteComponentProps<{ resourceId: string }> {
  basePath: string;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function VehicleListPage(props: VehicleListPageProps): ReactElement {
  const { match, basePath, onLoaded, onNavigate, onError } = props

  const resourceId = match.params.resourceId

  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState(new ResolvedResource())
  const [data, setData] = useState<Chunk<ResolvedService>>(new Chunk())
  const [sort, setSort] = useState('')
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(15)
  const [pattern, setPattern] = useState('')

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern)
    if (sort !== undefined) setSort(sort)
    if (skip !== undefined) setSkip(skip)
    if (take !== undefined) setTake(take)
    setLoading(true)
  }, [setLoading, setPattern, setSort, setSkip, setTake])

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const resource = await ResourceAccess.getOne(resourceId)
          setResource(resource)
          const data = await ServiceAccess.getAll(pattern, sort, skip, take, { byResourceId: resourceId })
          setData(data)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [loading, pattern, sort, skip, take, resourceId, setResource, setData, setLoaded, setLoading, onError])

  useEffect(() => {
    if (loaded) {
      onLoaded([
        {
          path: `${resource.id}/vehicles`,
          text: `${resource.code} — ${resource.name}`,
        },
      ])
    }
  }, [loaded, resource, onLoaded])

  const handleItemCreate = useCallback((): void => {
    onNavigate(`${resourceId}/vehicles/new`)
  }, [resourceId, onNavigate])

  const handleItemSelect = useCallback((record: ResolvedService): void => {
    onNavigate(`${resourceId}/vehicles/${record.id}`)
  }, [resourceId, onNavigate])

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern)
    }
  }, [pattern, reload])

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take)
  }, [reload])

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined)
  }, [reload])

  return (
    <Loader loading={!loaded && loading}>
      <Div layout="grid 12">
        <Div>
          <Menu>
            <MenuItem title={TXT('label.resource')}
              path={`${basePath}/${resourceId}`} />
            <MenuItem title={TXT('label.schedules')}
              path={`${basePath}/${resourceId}/schedules`} />
            <MenuItem title={TXT('label.vehicles')}
              path={`${basePath}/${resourceId}/vehicles`} />
          </Menu>
        </Div>
        <Div>
          <VehicleGrid data={data}
            onItemCreate={handleItemCreate}
            onItemSelect={handleItemSelect}
            onItemSearch={handleItemSearch}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange} />
        </Div>
      </Div>
    </Loader>
  )
}

function pathBuilder(items: NavPathItem[]): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: ID('page.resources'),
  })
  pathItems.push(...items)

  return pathItems
}

export const Resources = withPage(ResourcesPage, pathBuilder)
Resources.displayName = 'ResourcesPage'
