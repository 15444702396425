import * as KendoDropdowns from "@progress/kendo-react-dropdowns";
import * as KendoInputs from "@progress/kendo-react-inputs";
import React, { PureComponent, ReactNode } from "react";
import { Chunk, isChunk } from "../../data";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface ComboBoxProps<TValue> {
  hint?: IntlMessage;
  label?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  editable?: boolean;
  fill?: boolean;
  icon?: string;
  searchable?: boolean;
  pageSize?: number;
  name: string;
  data: Chunk<TValue> | TValue[];
  value: TValue | null;
  valueKey?: string;
  valueLabel?: string;
  error?: IntlMessage;
  validators?: ((value: TValue | null) => IntlMessage | undefined)[],
  onChange?: (value: TValue | null) => void;
  onFetch?: (pattern?: string, skip?: number, take?: number) => void;
}

interface ComboBoxProps<TValue> {
  children?: null;
}

interface ComboBoxState {
  data: any[];
  skip: number;
  take: number;
  total: number;
  loading: boolean;
  pattern: string | undefined;
}

export class ComboBox<TValue extends {}> extends PureComponent<ComboBoxProps<TValue>, ComboBoxState> {
  public constructor(props: ComboBoxProps<TValue>) {
    super(props);

    const { pageSize, data } = this.props;
    const skip = isChunk(data) ? data.skip : 0;
    const take = isChunk(data) ? data.take : data.length;
    const total = isChunk(data) ? data.total : data.length;

    this.state = {
      data: (isChunk(data) ? data.data : data).slice(0, pageSize || take),
      skip,
      take,
      total,
      loading: false,
      pattern: undefined,
    };
    this.timeout = null
  }

  public async componentDidUpdate(prevProps: Readonly<ComboBoxProps<TValue>>, prevState: Readonly<ComboBoxState>, snapshot?: any) {
    const { pageSize, data } = this.props;

    const skip = isChunk(data) ? data.skip : 0;
    const take = isChunk(data) ? data.take : data.length;
    const total = isChunk(data) ? data.total : data.length;

    if (prevProps.pageSize !== pageSize || prevProps.data !== data) {
      this.setState({
        data: (isChunk(data) ? data.data : data).slice(0, pageSize || take),
        skip,
        take,
        total,
      });
    }

    if (prevState.pattern !== this.state.pattern) {
      if (this.timeout) clearTimeout(this.timeout)
      await this.fetch(this.state.pattern, 0, take)
    }
  }

  private timeout: ReturnType<typeof setTimeout> | null

  private fetch = async (pattern: string | undefined, skip: number, take: number): Promise<void> => {
    const { /*data,*/ onFetch } = this.props;

    if (this.state.loading) return;

    if (onFetch) {
      // const dataSkip = isChunk(data) ? data.skip : 0;
      // const dataTake = isChunk(data) ? data.take : data.length;
      // const total = isChunk(data) ? data.total : data.length;

      this.setState({ loading: true })
      await onFetch(pattern)
      this.setState({ loading: false })

      // if (!pattern && skip >= dataSkip && (skip + take) < dataSkip + dataTake) {
      //   this.setState({
      //     data: (isChunk(data) ? data.data : data).slice(skip - dataSkip),
      //     skip: skip,
      //     take: take,
      //     total: total,
      //   });
      // } else {
      //   this.setState({ loading: true })
      //   if (skip > dataSkip) {
      //     await onFetch(pattern, skip, dataTake * 2);
      //   } else {
      //     await onFetch(pattern, 0, dataTake * 2);
      //   }
      //   this.setState({ loading: false })
      // }
    }
  }

  public render(): ReactNode {
    const { hint, label, placeholder, disabled, readonly, editable, fill, searchable, /*pageSize,*/ icon, name, data, value, valueKey, valueLabel, error, validators, onChange, onFetch } = this.props;

    const className = fill ? "fill" : undefined;

    // const virtual = {
    //   pageSize: pageSize ? pageSize : this.state.take,
    //   skip: this.state.skip,
    //   total: this.state.total,
    // };

    const handleFilterChange = onFetch
      ? async (event: KendoDropdowns.ComboBoxFilterChangeEvent): Promise<void> => {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => { this.setState({ pattern: event.filter.value })}, 1000)
        // await this.fetch(event.filter.value, 0, virtual.pageSize);
      }
      : undefined;

    // const handlePageChange = onFetch
    //   ? async (event: KendoDropdowns.ComboBoxPageChangeEvent): Promise<void> => {
    //     await this.fetch(undefined, event.page.skip, event.page.take);
    //   }
    //   : undefined;

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={true}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange, onFieldEnter, onFieldLeave) => {
                 if (readonly || fieldReadonly) {
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          value={value ? (typeof value === "object" && valueLabel ? (value as any)[valueLabel] : value.toString()) : ""} />
                     </span>
                   );
                 } else {
                   if (searchable) {
                     return (
                       <span className={className}>
                         <KendoDropdowns.ComboBox label={label && getIntlMessage(intl, label)}
                                                  placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                                  disabled={disabled || fieldDisabled || !onFieldChange}
                                                  iconClassName={icon && `k-i-${icon}`}
                                                  valid={true}
                                                  data={this.state.data}
                                                  value={value}
                                                  loading={this.state.loading}
                                                  dataItemKey={valueKey}
                                                  textField={valueLabel}
                                                  filterable={!!handleFilterChange}
                                                  // virtual={handlePageChange !== undefined ? virtual : undefined}
                                                  onChange={!this.state.loading ? e => onFieldChange(e.target.value) : undefined}
                                                  onFocus={onFieldEnter}
                                                  onBlur={onFieldLeave}
                                                  onFilterChange={handleFilterChange}
                                                  // onPageChange={handlePageChange}
                                                  allowCustom={editable}
                                                  clearButton={true}
                                                  suggest={true} />
                       </span>
                     );
                   } else {
                     return (
                       <span className={className}>
                         <KendoDropdowns.DropDownList label={label && getIntlMessage(intl, label)}
                                                      disabled={disabled || fieldDisabled || !onFieldChange}
                                                      iconClassName={icon && `k-i-${icon}`}
                                                      valid={true}
                                                      data={isChunk(data) ? data.data : data}
                                                      value={value}
                                                      dataItemKey={valueKey}
                                                      textField={valueLabel}
                                                      onChange={e => onFieldChange(e.target.value)}
                                                      onFocus={onFieldEnter}
                                                      onBlur={onFieldLeave} />
                       </span>
                     );
                   }
                 }
               }}
               onChange={onChange} />
      } />
    );
  }
}
