import { ID, IntlMessage } from "../Intl";

export function required<T extends {}>(value: T | T[] | null): IntlMessage | undefined {
  if (value === null || value === undefined)
    return ID("valid.required");

  if (typeof value === "string" || value instanceof Array)
    return value.length === 0 ? ID("valid.required") : undefined;

  return undefined;
}
