import { AccessProgram } from "./AccessProgram";
import { LoyaltyProgram } from "./LoyaltyProgram";
import { PassengerKlass } from "./Passenger";


export enum FreeAccessType {
  BusinessPassenger = "BusinessPassenger",
  LoyaltyProgram = "LoyaltyProgram",
  AccessProgram = "AccessProgram",
}

export class BusinessPassengerSettings {
  public klasses: PassengerKlass[];
  public klassRequired: boolean;
  public ticketRequired: boolean;

  public constructor() {
    this.klasses = [];
    this.klassRequired = true;
    this.ticketRequired = true;
  }
}

export class LoyaltyProgramSettings {
  public programs: LoyaltyProgram[];
  public airlineRequired: boolean;
  public programRequired: boolean;
  public levelRequired: boolean;
  public numberRequired: boolean;


  public constructor() {
    this.programs = [];
    this.airlineRequired = true;
    this.programRequired = true;
    this.levelRequired = true;
    this.numberRequired = true;
  }
}

export class AccessProgramSettings {
  public programs: AccessProgram[];
  public programRequired: boolean;
  public numberRequired: boolean;

  public constructor() {
    this.programs = [];
    this.programRequired = true;
    this.numberRequired = true;
  }
}

export class FreeAccessSettings {
  public types: FreeAccessType[];
  public businessPassengerSettings: BusinessPassengerSettings;
  public loyaltyProgramSettings: LoyaltyProgramSettings;
  public accessProgramSettings: AccessProgramSettings;

  public constructor() {
    this.types = [];
    this.businessPassengerSettings = new BusinessPassengerSettings();
    this.loyaltyProgramSettings = new LoyaltyProgramSettings();
    this.accessProgramSettings = new AccessProgramSettings();
  }
}
