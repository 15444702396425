import { Apis, Chunk, Client } from "../../../data";

export class SearchAccessV1 {
  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<string>>  {
    const data = (await Client
      .instance(await Apis.getDataApi())
      .get<{ name: string }[]>("Search/corporateClient", {}, { pattern }))
      .map(item => item.name);

    const part: string[] = [];
    const start = skip || 0;
    const count = take || data.length;
    if (start < data.length) {
      part.push(...data.slice(start, count));
    }

    return new Chunk<string>(part, start, count, data.length);
  }
}
