import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Pair } from "../../data";
import { FreeAccessType } from "../data/models";
import { PairConverter, PairData } from "./types";

export function useFreeAccessTypes(): [ PairData<FreeAccessType>, PairConverter<FreeAccessType> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<FreeAccessType>>([
    new Pair(FreeAccessType.BusinessPassenger, intl.formatMessage({ id: "enum.organizationFreeAccessType.business" })),
    new Pair(FreeAccessType.LoyaltyProgram, intl.formatMessage({ id: "enum.organizationFreeAccessType.loyalty" })),
    new Pair(FreeAccessType.AccessProgram, intl.formatMessage({ id: "enum.organizationFreeAccessType.program" })),
  ]);

  const converter = useCallback((code: FreeAccessType): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}
