import { IReference } from "./IReference";

export class CountryReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class CountryBaseV1 extends CountryReferenceV1 {
  public code: string;
  public name: string;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
  }
}

export class CountryV1 extends CountryBaseV1 {
  public constructor() {
    super();
  }
}
