import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Lock, LockAction } from '../data'
import { Div, ID, NavPathItem } from '../gears'
import { ComboBox } from '../gears/inputs'
import { LockAccess, OrganizationAccess } from '../parts/data/access'
import { CityReference, EMPTY_ID, EVoucherSettings, FreeAccessSettings, Organization, OrganizationType, OrganizationUpdateModel, ResourceDefaults } from '../parts/data/models'
import { Loader, Menu, MenuItem, TXT } from '../parts/gears'
import { useField, useOrganization, useOrganizations } from '../parts/hooks'
import { OrganizationFreeAccessSettingsView, OrganizationItem, OrganizationVoucherSettingsView, ResourceDefaultsView } from '../parts/views'
import { PageProps, withPage } from './withPage'

function SettingsPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props

  const auth = useContext(AuthContext)
  const profile = auth.profile

  const pageSize = 100
  
  const [ organizations, getOrganizations ] = useOrganizations(pageSize, profile.isSys)
  const [ belongsTo ] = useOrganization(profile.belongsTo)
  const [ organization, setOrganization ] = useField<Organization | null>(null)
  
  useEffect(
    () => {
      if (!profile.isSys && !organization) {
        setOrganization(belongsTo)
      }
    },
    [ profile.isSys, organization, belongsTo, setOrganization ],
  )

  useEffect(() => {
    onLoaded([])
  }, [ onLoaded ])

  return (
    <Div layout="grid 12">
      {auth.profile.isSys &&
      <Div>
        <Div layout={'grid 12 3@lg'}>
          <Div>
            <ComboBox label={TXT('label.organization')}
                      name={'organization'}
                      fill={true}
                      searchable={true}
                      data={organizations}
                      value={organization}
                      valueKey={'id'}
                      valueLabel={'name'}
                      onChange={setOrganization}
                      onFetch={getOrganizations} />
          </Div>
        </Div>
      </Div>
      }
      {organization && organization.type === OrganizationType.Provider &&
      <Div>
        <Menu>
          <MenuItem title={TXT('label.organization')} path={`${match.path}`} />
          <MenuItem title={TXT('label.resources')} path={`${match.path}/defaults`} />
          <MenuItem title={TXT('label.voucher')} path={`${match.path}/eVoucher`} />
          <MenuItem title={TXT('label.freeAccess')} path={`${match.path}/passengers`} />
        </Menu>
      </Div>
      }
      {organization && organization.type === OrganizationType.Agency &&
      <Div>
        <Menu>
          <MenuItem title={TXT('label.organization')} path={`${match.path}`} />
          <MenuItem title={TXT('label.voucher')} path={`${match.path}/eVoucher`} />
        </Menu>
      </Div>
      }
      {organization &&
      <Div>
        <Switch>
          <Route exact={true} path={`${match.path}`}
                 render={props => <OrganizationPage {...props}
                                                    organization={organization}
                                                    onLoaded={onLoaded}
                                                    onNavigate={onNavigate}
                                                    onError={onError} />} />
          <Route exact={true}
                 path={`${match.path}/defaults`}
                 render={props => <DefaultsPage {...props}
                                                organization={organization}
                                                onLoaded={onLoaded}
                                                onNavigate={onNavigate}
                                                onError={onError} />} />
          <Route exact={true}
                 path={`${match.path}/eVoucher`}
                 render={props => <EVoucherPage {...props}
                                                organization={organization}
                                                onLoaded={onLoaded}
                                                onNavigate={onNavigate}
                                                onError={onError} />} />
          <Route exact={true}
                 path={`${match.path}/passengers`}
                 render={props => <PassengersPage {...props}
                                                  organization={organization}
                                                  onLoaded={onLoaded}
                                                  onNavigate={onNavigate}
                                                  onError={onError} />} />
        </Switch>
      </Div>
      }
    </Div>
  )
}

interface OrganizationPageProps extends RouteComponentProps {
  organization: Organization;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function OrganizationPage(props: OrganizationPageProps): ReactElement {
  const { organization, location, onLoaded, onNavigate, onError } = props

  const itemId = organization.id
  const isNew = itemId === EMPTY_ID
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [ , setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new Organization(CityReference))
  const [ lock, setLock ] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrganizationAccess.getOne(itemId)
          setItem(item)
          const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
          setLock(lock)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId,
    setLoaded, setLoading, setItem, setLock, onError,
  ])

  useEffect(() => {
    onLoaded([])
  }, [ onLoaded ])

  useEffect(() => {
    setLoading(true)
  }, [ itemId ])

  const handleNavigate = useCallback(async (edit?: boolean, free?: boolean): Promise<void> => {
    try {
      if (free) {
        await LockAccess.lock(itemId, LockAction.free)
      }
      onNavigate(`${edit ? '?edit' : ''}`)
      setLoaded(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }, [ itemId, setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: OrganizationUpdateModel): Promise<void> => {
    try {
      await OrganizationAccess.update(itemId, item)
      await handleNavigate(false, true)
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(true, false)
  }, [ handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  return (
    <Loader loading={loading}>
      <OrganizationItem item={item}
                        lock={lock}
                        readonly={readonly}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        onEdit={handleEdit}
                        onFree={handleFree} />
    </Loader>
  )
}

interface DefaultsPageProps extends RouteComponentProps {
  organization: Organization;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function DefaultsPage(props: DefaultsPageProps): ReactElement {
  const { organization, location, onLoaded, onNavigate, onError } = props

  const itemId = organization.id
  const isNew = itemId === EMPTY_ID
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [ , setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new ResourceDefaults())
  const [ lock, setLock ] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrganizationAccess.getOneDefaults(itemId)
          setItem(item)
          const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
          setLock(lock)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId,
    setLoaded, setLoading, setItem, setLock, onError,
  ])

  useEffect(() => {
    onLoaded([])
  }, [ onLoaded ])

  useEffect(() => {
    setLoading(true)
  }, [ itemId ])

  const handleNavigate = useCallback(async (edit?: boolean, free?: boolean): Promise<void> => {
    try {
      if (free) {
        await LockAccess.lock(itemId, LockAction.free)
      }
      onNavigate(`defaults${edit ? '?edit' : ''}`)
      setLoaded(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }, [ itemId, setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: ResourceDefaults): Promise<void> => {
    try {
      await OrganizationAccess.updateSettings(itemId, { resourceDefaults: item })
      await handleNavigate(false, true)
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(true, false)
  }, [ handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  return (
    <Loader loading={loading}>
      <ResourceDefaultsView item={item}
                            lock={lock}
                            readonly={readonly}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                            onEdit={handleEdit}
                            onFree={handleFree} />
    </Loader>
  )
}

interface EVoucherPageProps extends RouteComponentProps {
  organization: Organization;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function EVoucherPage(props: EVoucherPageProps): ReactElement {
  const { organization, location, onLoaded, onNavigate, onError } = props

  const itemId = organization.id
  const isNew = itemId === EMPTY_ID
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [ , setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new EVoucherSettings())
  const [ lock, setLock ] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrganizationAccess.getOneVoucherSettings(itemId)
          setItem(item)
          const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
          setLock(lock)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId,
    setLoaded, setLoading, setItem, setLock, onError,
  ])

  useEffect(() => {
    onLoaded([])
  }, [ onLoaded ])

  useEffect(() => {
    setLoading(true)
  }, [ itemId ])

  const handleNavigate = useCallback(async (edit?: boolean, free?: boolean): Promise<void> => {
    try {
      if (free) {
        await LockAccess.lock(itemId, LockAction.free)
      }
      onNavigate(`eVoucher${edit ? '?edit' : ''}`)
      setLoaded(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }, [ itemId, setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: EVoucherSettings): Promise<void> => {
    try {
      await OrganizationAccess.updateSettings(itemId, { eVoucherSettings: item })
      await handleNavigate(false, true)
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(true, false)
  }, [ handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  return (
    <Loader loading={loading}>
      <OrganizationVoucherSettingsView item={item}
                                       lock={lock}
                                       readonly={readonly}
                                       showInfo={true}
                                       showRate={organization.type === OrganizationType.Agency}
                                       onSubmit={handleSubmit}
                                       onCancel={handleCancel}
                                       onEdit={handleEdit}
                                       onFree={handleFree} />
    </Loader>
  )
}

interface PassengersPageProps extends RouteComponentProps {
  organization: Organization;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function PassengersPage(props: PassengersPageProps): ReactElement {
  const { organization, location, onLoaded, onNavigate, onError } = props

  const itemId = organization.id
  const isNew = itemId === EMPTY_ID
  const options = new URLSearchParams(location.search)
  const readonly = !isNew && !options.has('edit')

  const [ , setLoaded ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ item, setItem ] = useState(new FreeAccessSettings())
  const [ lock, setLock ] = useState(new Lock())

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const item = await OrganizationAccess.getOneFreeAccessSettings(itemId)
          setItem(item)
          const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock)
          setLock(lock)
        } catch (error) {
          onError(error)
        } finally {
          setLoading(false)
          setLoaded(true)
        }
      }
    })()
  }, [
    loading, isNew, readonly, itemId,
    setLoaded, setLoading, setItem, setLock, onError,
  ])

  useEffect(() => {
    onLoaded([])
  }, [ onLoaded ])

  useEffect(() => {
    setLoading(true)
  }, [ itemId ])

  const handleNavigate = useCallback(async (edit?: boolean, free?: boolean): Promise<void> => {
    try {
      if (free) {
        await LockAccess.lock(itemId, LockAction.free)
      }
      onNavigate(`passengers${edit ? '?edit' : ''}`)
      setLoaded(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }, [ itemId, setLoaded, setLoading, onNavigate ])

  const handleSubmit = useCallback(async (item: FreeAccessSettings): Promise<void> => {
    try {
      await OrganizationAccess.updateSettings(itemId, { freeAccessSettings: item })
      await handleNavigate(false, true)
    } catch (error) {
      onError(error)
    }
  }, [ itemId, handleNavigate, onError ])

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(true, false)
  }, [ handleNavigate ])

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(false, true)
  }, [ handleNavigate ])

  return (
    <Loader loading={loading}>
      <OrganizationFreeAccessSettingsView item={item}
                                          lock={lock}
                                          readonly={readonly}
                                          onSubmit={handleSubmit}
                                          onCancel={handleCancel}
                                          onEdit={handleEdit}
                                          onFree={handleFree} />
    </Loader>
  )
}

function pathBuilder(): NavPathItem[] {
  const pathItems: NavPathItem[] = []
  pathItems.push({
    path: '',
    text: ID('page.settings'),
  })

  return pathItems
}

export const Settings = withPage(SettingsPage, pathBuilder)
Settings.displayName = 'SettingsPage'
