import * as KendoDialogs from "@progress/kendo-react-dialogs";
import * as KendoUpload from "@progress/kendo-react-upload"
import React, { ReactElement, ReactNode, useState } from "react";
import { Button } from "./Button";
import { Div } from "./Div";
import { getIntlMessage, ID, Intl, IntlMessage } from "./Intl";

export interface UploaderProps {
  title: IntlMessage;
  uploadUrl?: string;
  single?: boolean;
  allowedFileExtensions?: string[];
  allowedMaxFileSize?: number;
  allowedMinFileSize?: number;
  onConfirm: (fileIds: { id: string; uri: string; name: string; }[]) => void;
}

export interface UploaderProps {
  children?: ReactNode;
}

export interface UploaderResponse {
  response: {
    files: UploadedFile[];
  };
  status: number;
  statusText: string;
}

export interface UploadedFile {
  id: string;
  uri: string;
  name: string;
}

export function Uploader(props: Readonly<UploaderProps>): ReactElement {
  const { title, uploadUrl, single, allowedFileExtensions, allowedMaxFileSize, allowedMinFileSize, children, onConfirm } = props;
  const [ files, setFiles ] = useState<KendoUpload.UploadFileInfo[]>([]);
  const [ ids, setIds ] = useState<{ id: string, uri: string; name: string; }[]>([]);

  const multiple = single === undefined || !single;

  const handleAdd = (event: KendoUpload.UploadOnAddEvent): void => {
    setFiles(event.newState);
  };

  const handleProgress = (event: KendoUpload.UploadOnProgressEvent): void => {
    setFiles(event.newState);
  };

  const handleStatusChange = (event: KendoUpload.UploadOnStatusChangeEvent): void => {
    setFiles(event.newState);
    if (event.response && event.response.response && event.response.response.files) {
      setIds(prev => [ ...prev, ...(event.response as UploaderResponse).response.files.map(file => file) ]);
    }
  };

  const handleConfirm = (confirmed: boolean): void => {
    if (onConfirm) {
      if (confirmed) {
        onConfirm(ids);
      } else {
        onConfirm([]);
      }
    }
    setFiles([]);
    setIds([]);
  };

  return (
    <Intl render={intl =>
      <KendoDialogs.Dialog title={getIntlMessage(intl, title)} onClose={() => handleConfirm(false)}>
        <Div layout="grid 12">
          <Div>
            {children}
          </Div>
          <Div style={{ overflowY: "scroll", maxHeight: "320px" }}>
            <KendoUpload.Upload autoUpload={true} batch={false} multiple={multiple}
                                showFileList={true} showActionButtons={false}
                                disabled={!uploadUrl}
                                files={files}
                                saveUrl={uploadUrl}
                                restrictions={{
                                  allowedExtensions: allowedFileExtensions,
                                  maxFileSize: allowedMaxFileSize,
                                  minFileSize: allowedMinFileSize,
                                }}
                                withCredentials={false}
                                onAdd={handleAdd}
                                onProgress={handleProgress}
                                onStatusChange={handleStatusChange} />
          </Div>
        </Div>
        <KendoDialogs.DialogActionsBar>
          <Button look="outline" text={ID("action.cancel")} type="button" onClick={() => handleConfirm(false)} />
          <Button primary={true} text={ID("action.confirm")} disabled={ids.length === 0} type="button" onClick={() => handleConfirm(true)} />
        </KendoDialogs.DialogActionsBar>
      </KendoDialogs.Dialog>
    } />
  );
}
