import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { Chunk } from "../../data";
import { Change } from "../data/models";
import { Div, Grid, GridColumn, TXT } from "../gears";
import { useFormatDate, useOrderChangeActions, useWindowSize } from "../hooks";
import { GridViewProps } from "./types";

export interface OrderChangesViewProps extends GridViewProps<Change> {
}

export function OrderChangesView(props: OrderChangesViewProps): ReactElement {
  const { data } = props;

  const formatDate = useFormatDate();
  const [ , formatAction ] = useOrderChangeActions();

  const [ processedData, setProcessedData ] = useState<Chunk<any>>(new Chunk());

  useEffect(() => {
    const processedData = new Chunk<any>([], data.skip, data.take, data.total);
    for (const item of data.data) {
      processedData.data.push({
        action: formatAction(item.action),
        performedAt: formatDate(item.performedAt, true),
        performedBy: item.performedBy.fullName || item.performedBy.userName || item.performedBy.id || item.performedBy.clientId,
      });
    }
    setProcessedData(processedData);
  }, [ data.data, data.skip, data.take, data.total, formatDate, formatAction, setProcessedData ]);

  const [ width, setWidth ] = useState<number>(1);
  const [ , innerWidth ] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [ innerWidth ]);

  const handleResize = useCallback((rect: ContentRect): void => {
    if (rect.bounds && rect.bounds) {
      setWidth(rect.bounds.width);
    }
  }, [ setWidth ]);

  return (
    <Div layout="grid 12">
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) =>
          <Div ref={measureRef}>
            <Grid data={processedData}
                  width={width}>
              <GridColumn title={TXT("label.action")} field="action" width={256} fill={true} sortable={false}  />
              <GridColumn title={TXT("label.dateTime")} field="performedAt" width={192} sortable={false}  />
              <GridColumn title={TXT("label.user")} field="performedBy" width={256} sortable={false}  />
            </Grid>
          </Div>
        }
      </Measure>
    </Div>
  );
}
