import { CurrencyBase, CurrencyReference, EMPTY_ID, OrganizationBase, OrganizationReference } from './index'

export interface PoolFilter {
  byOrganizationId?: string;
  byProviderId?: string;
  byResellerId?: string;
  byExcludedId?: string | string[];
  byIncludedId?: string | string[];
  byCurrencyId?: string;
}

export class PoolReference {
  public id: string

  public constructor(id?: string) {
    this.id = id ?? EMPTY_ID
  }
}

export class PoolBase extends PoolReference {
  public name: string

  public constructor() {
    super()
    this.name = ''
  }
}

export class PoolRule {
  public provider: OrganizationReference | null
  public contract: OrganizationReference | null

  public constructor() {
    this.provider = null
    this.contract = null
  }
}

export class Pool<TOrganization extends OrganizationReference = OrganizationReference, TCurrency extends CurrencyReference = CurrencyReference> extends PoolBase {
  public organization: TOrganization
  public currency: TCurrency
  public rules: PoolRule[]

  public constructor(TOrganization: new() => TOrganization, TCurrency: new() => TCurrency) {
    super()
    this.organization = new TOrganization()
    this.currency = new TCurrency()
    this.rules = []
  }
}

export class ResolvedPool extends Pool<OrganizationBase, CurrencyBase> {
  public constructor() {
    super(OrganizationBase, CurrencyBase);
  }
}

export class PoolUpdateModel {
  public name: string

  public constructor() {
    this.name = ''
  }
}

export class PoolCreateModel extends PoolUpdateModel {
  public organization: OrganizationReference
  public currency: CurrencyReference
  public contract: OrganizationReference | null

  public constructor() {
    super();
    this.organization = new OrganizationReference()
    this.currency = new CurrencyReference()
    this.contract = null
  }
}

export class PoolCopyModel {
  public source: PoolReference
  public destination: PoolReference

  public constructor() {
    this.source = new PoolReference()
    this.destination = new PoolReference()
  }
}
