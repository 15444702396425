import { Checkbox } from "@progress/kendo-react-inputs";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Measure, { ContentRect } from "react-measure";
import { Chunk, ClientError, Pair } from "../../data";
import { RateFamilyV1 } from "../../data/models";
import { FieldSet, Form, FormContext, guid, IntlFormat } from "../../gears";
import {
  ComboBox,
  DateBox,
  NumberBox,
  SwitchBox,
  TextBox,
  TimeBox,
  ToggleBox,
} from "../../gears/inputs";
import { length, range, required } from "../../gears/validators";
import {
  CurrencyAccess,
  OrganizationContractAccess,
  OrganizationProgramAccess,
  ResourceAccess,
  SpanAccess,
} from "../data/access";
import {
  Airport,
  Currency,
  CurrencyReference,
  DiscountRateRule,
  EMPTY_ID,
  FlightDirection,
  GroupRateRule,
  OrganizationContract,
  OrganizationContractType,
  OrganizationProgram,
  OrganizationType,
  PassengerCategory,
  PoolReference,
  Rate,
  RateCost,
  RateFilter,
  RateFine,
  RateSpan,
  RateSpanCreateModel,
  RateSpanFilter,
  Resource,
  Service,
  ServiceKind,
  ServiceType,
  UrgencyRateRule,
} from "../data/models";
import {
  Button,
  Dash,
  Div,
  Grid,
  GridColumn,
  ItemCreateHandler,
  ItemFilterHandler,
  ItemSearchHandler,
  Span,
  TXT,
} from "../gears";
import {
  useAirport,
  useAirports,
  useDelay,
  useField,
  useFlightDirections,
  useFormatCode,
  useFormatCost,
  useFormatDateRange,
  useFormatDays,
  useFormatMessage,
  useFormatNumber,
  useFormatTime,
  useFormatTimeRange,
  useOrganization,
  useOrganizationContract,
  useOrganizationContracts,
  useOrganizationDefaults,
  useOrganizationProgram,
  useOrganizationPrograms,
  useOrganizations,
  usePassengerCategories,
  usePool,
  usePrimaryServiceKinds,
  useRateFamiliesV1,
  useResource,
  useResources,
  useResourceSettings,
  useServiceTypes,
  useWindowSize,
} from "../hooks";
import { firstOrNull } from "../utils";
import { WeekDays } from "./Schedules";
import { GridViewProps, ItemViewProps } from "./types";

interface RateFineViewProps {
  name: string;
  delta: string | null;
  valueAfter: number | null;
  valueBefore: number | null;
  onDeltaChange: (value: string | null) => void;
  onValueAfterChange: (value: number | null) => void;
  onValueBeforeChange: (value: number | null) => void;
}

function RateFineView(props: RateFineViewProps): ReactElement {
  const {
    name,
    delta,
    valueAfter,
    valueBefore,
    onDeltaChange,
    onValueAfterChange,
    onValueBeforeChange,
  } = props;

  const formatMessage = useFormatMessage();
  const formatTime = useFormatTime();

  return (
    <Div layout="grid 12">
      <Div>
        <Div layout="grid 12 6@md 3@lg">
          <Div>
            <TimeBox
              label={TXT("label.fineDelta")}
              fill={true}
              name={`${name}-delta`}
              value={delta}
              validators={[required]}
              onChange={onDeltaChange}
            />
          </Div>
          <Div>
            <NumberBox
              label={TXT("label.fineValueBefore", {
                time: formatTime(delta || "00:00"),
              })}
              fill={true}
              name={`${name}-value-before`}
              value={valueBefore}
              validators={[required, (value) => range(value, 0, 100)]}
              onChange={onValueBeforeChange}
            />
          </Div>
          <Div>
            <NumberBox
              label={TXT("label.fineValueAfter", {
                time: formatTime(delta || "00:00"),
              })}
              fill={true}
              name={`${name}-value-after`}
              value={valueAfter}
              validators={[required, (value) => range(value, 0, 100)]}
              onChange={onValueAfterChange}
            />
          </Div>
        </Div>
      </Div>
      <Div>
        {formatMessage(
          TXT("message.fineRule", {
            time: formatTime(delta || "00:00"),
            valueBefore: valueBefore || 0,
            valueAfter: valueAfter || 0,
          })
        )}
      </Div>
    </Div>
  );
}

interface GroupRateRuleViewProps {
  name: string;
  item: GroupRateRule;
  onChange: (value: GroupRateRule) => void;
}

function GroupRateRuleView(props: GroupRateRuleViewProps): ReactElement {
  const { name, item, onChange } = props;

  const [minSize, setMinSize] = useField<number | null>(item.minSize);
  const [exactSize] = useField(item.minSize === item.maxSize);
  const [minAdultCount, setMinAdultCount] = useField<number | null>(
    item.minAdultCount
  );
  const [exactAdultCount] = useField(item.minAdultCount === item.maxAdultCount);
  const [minChildCount, setMinChildCount] = useField<number | null>(
    item.minChildCount
  );
  const [exactChildCount] = useField(item.minChildCount === item.maxChildCount);

  const handleMinSizeChange = useCallback(
    (value: number | null) => {
      if (value !== null)
        onChange(new GroupRateRule({ ...item, minSize: value }));
      else setMinSize(value);
    },
    [item, setMinSize, onChange]
  );

  const handleExactSizeChange = useCallback(
    (value: boolean) => {
      onChange(
        new GroupRateRule({ ...item, maxSize: value ? item.minSize : null })
      );
    },
    [item, onChange]
  );

  const handleMinAdultCount = useCallback(
    (value: number | null) => {
      if (value !== null)
        onChange(new GroupRateRule({ ...item, minAdultCount: value }));
      else setMinAdultCount(value);
    },
    [item, setMinAdultCount, onChange]
  );

  const handleExactAdultCountChange = useCallback(
    (value: boolean) => {
      onChange(
        new GroupRateRule({
          ...item,
          maxAdultCount: value ? item.minAdultCount : null,
        })
      );
    },
    [item, onChange]
  );

  const handleMinChildCount = useCallback(
    (value: number | null) => {
      if (value !== null)
        onChange(new GroupRateRule({ ...item, minChildCount: value }));
      else setMinChildCount(value);
    },
    [item, setMinChildCount, onChange]
  );

  const handleExactChildCountChange = useCallback(
    (value: boolean) => {
      onChange(
        new GroupRateRule({
          ...item,
          maxChildCount: value ? item.minChildCount : null,
        })
      );
    },
    [item, onChange]
  );

  useEffect(() => {
    if (minSize && minAdultCount && minChildCount) {
      if (minSize < minAdultCount) {
        handleMinAdultCount(minSize);
        return;
      }
      if (minSize < minChildCount) {
        handleMinChildCount(minSize);
        return;
      }
      if (minSize < minAdultCount + minChildCount) {
        handleMinChildCount(Math.max(minSize - minAdultCount, 0));
      }
    }
  }, [
    minSize,
    minAdultCount,
    minChildCount,
    handleMinAdultCount,
    handleMinChildCount,
  ]);

  return (
    <Div layout="grid 12 6@md 3@lg">
      <Div>
        <Div>
          <Div>
            <NumberBox
              label={TXT("label.groupSize")}
              name={`${name}-minSize`}
              fill={true}
              min={2}
              validators={[required, (value) => range(value, 2)]}
              value={minSize}
              onChange={handleMinSizeChange}
            />
          </Div>
          <Div>
            <SwitchBox
              mode="check"
              label={TXT("label.exactEquality")}
              name={`${name}-maxSize`}
              value={exactSize}
              onChange={handleExactSizeChange}
            />
          </Div>
        </Div>
      </Div>
      <Div>
        <Div>
          <Div>
            <NumberBox
              label={TXT("label.adultCount")}
              name={`${name}-minAdultCount`}
              fill={true}
              min={0}
              validators={[required, (value) => range(value, 0, minSize || 0)]}
              value={minAdultCount}
              onChange={handleMinAdultCount}
            />
          </Div>
          <Div>
            <SwitchBox
              mode="check"
              label={TXT("label.exactEquality")}
              name={`${name}-maxAdultCount`}
              value={exactAdultCount}
              onChange={handleExactAdultCountChange}
            />
          </Div>
        </Div>
      </Div>
      <Div>
        <Div>
          <Div>
            <NumberBox
              label={TXT("label.childCount")}
              name={`${name}-minChildCount`}
              fill={true}
              min={0}
              validators={[required, (value) => range(value, 0, minSize || 0)]}
              value={minChildCount}
              onChange={handleMinChildCount}
            />
          </Div>
          <Div>
            <SwitchBox
              mode="check"
              label={TXT("label.exactEquality")}
              name={`${name}-maxChildCount`}
              value={exactChildCount}
              onChange={handleExactChildCountChange}
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

interface DiscountRateRuleViewProps {
  name: string;
  item: DiscountRateRule;
  onChange: (value: DiscountRateRule) => void;
}

function DiscountRateRuleView(props: DiscountRateRuleViewProps): ReactElement {
  const { name, item, onChange } = props;

  const [from, setFrom] = useField<number | null>(item.from);
  const [amount, setAmount] = useField<number | null>(item.value);

  const handleFrom = useCallback(
    (value: number | null) => {
      if (value !== null)
        onChange(new DiscountRateRule({ ...item, from: value }));
      else setFrom(value);
    },
    [item, setFrom, onChange]
  );

  const handleAmount = useCallback(
    (value: number | null) => {
      if (value !== null)
        onChange(new DiscountRateRule({ ...item, value: value }));
      else setAmount(value);
    },
    [item, setAmount, onChange]
  );

  useEffect(() => {
    const value = new DiscountRateRule();
    value.from = from || 0;
    value.value = amount || 0;

    onChange(value);
  }, [from, amount, onChange]);

  return (
    <Div layout="grid 12 6@md 3@lg">
      <Div>
        <NumberBox
          label={TXT("label.fromPassenger")}
          fill={true}
          name={`${name}-from`}
          value={from}
          validators={[required, (value) => range(value, 2)]}
          onChange={handleFrom}
        />
      </Div>
      <Div>
        <NumberBox
          label={TXT("label.discountValue")}
          fill={true}
          name={`${name}-value`}
          value={amount}
          validators={[required, (value) => range(value, 0, 100)]}
          onChange={handleAmount}
        />
      </Div>
    </Div>
  );
}

interface UrgencyRateRuleViewProps {
  name: string;
  item: UrgencyRateRule;
  onChange: (value: UrgencyRateRule) => void;
}

function UrgencyRateRuleView(props: UrgencyRateRuleViewProps): ReactElement {
  const { name, item, onChange } = props;

  const [timeDeltaBeforeFlight] = useField<string | null>(
    item.timeDeltaBeforeFlight
  );
  const [dayCountBeforeFlight] = useField<number | null>(
    item.dayCountBeforeFlight
  );
  const [dayTimeThreshold] = useField<string | null>(item.dayTimeThreshold);

  const handleTimeDelta = useCallback(
    (value: string | null) => {
      onChange(new UrgencyRateRule({ ...item, timeDeltaBeforeFlight: value }));
    },
    [item, onChange]
  );

  const handleDayCount = useCallback(
    (value: number | null) => {
      onChange(new UrgencyRateRule({ ...item, dayCountBeforeFlight: value }));
    },
    [item, onChange]
  );

  const handleDayTime = useCallback(
    (value: string | null) => {
      onChange(new UrgencyRateRule({ ...item, dayTimeThreshold: value }));
    },
    [item, onChange]
  );

  useEffect(() => {
    if (dayCountBeforeFlight === null) {
      onChange(
        new UrgencyRateRule({
          timeDeltaBeforeFlight,
          dayCountBeforeFlight,
          dayTimeThreshold: null,
        })
      );
    }
  }, [timeDeltaBeforeFlight, dayCountBeforeFlight, onChange]);

  return (
    <Div layout="grid 12 6@md 3@lg">
      <Div>
        <TimeBox
          label={TXT("label.timeDeltaBeforeFlight")}
          fill={true}
          name={`${name}-timeDelta`}
          value={timeDeltaBeforeFlight}
          onChange={handleTimeDelta}
        />
      </Div>
      <Div>
        <NumberBox
          label={TXT("label.dayCountBeforeFlight")}
          fill={true}
          name={`${name}-dayCount`}
          value={dayCountBeforeFlight}
          min={1}
          onChange={handleDayCount}
        />
      </Div>
      <Div>
        <TimeBox
          label={TXT("label.dayTimeThreshold")}
          fill={true}
          disabled={dayCountBeforeFlight === null}
          name={`${name}-dayTime`}
          value={dayTimeThreshold}
          onChange={handleDayTime}
        />
      </Div>
    </Div>
  );
}

interface RateViewProps {
  name: string;
  item: Rate;
  type: ServiceType;
  index: number;
  defaults: { groupSize: number; minChildAge: number; minAdultAge: number };
  readonly: boolean;
  showDiscount: boolean;
  showGroup: boolean;
  showUrgency: boolean;
  onChange: (index: number, value: Rate) => void;
  onRemove: (index: number) => void;
}

function RateView(props: RateViewProps): ReactElement {
  const {
    name,
    item,
    type,
    index,
    defaults,
    readonly,
    showDiscount,
    showGroup,
    showUrgency,
    onChange,
    onRemove,
  } = props;

  const form = useContext(FormContext);

  const formatNumber = useFormatNumber();

  const id = item.id;
  const code = item.code;

  const [baseValue, setBaseValue] = useField<number | null>(
    firstOrNull(
      item.costs
        .filter(
          (cost) =>
            cost.type === ServiceType.Any &&
            cost.category === PassengerCategory.Adult
        )
        .map((cost) => cost.value)
    )
  );
  const [adultValue, setAdultValue] = useField<number | null>(
    firstOrNull(
      item.costs
        .filter(
          (cost) =>
            cost.type === type && cost.category === PassengerCategory.Adult
        )
        .map((cost) => cost.value)
    )
  );
  const [childValue, setChildValue] = useField<number | null>(
    firstOrNull(
      item.costs
        .filter(
          (cost) =>
            cost.type === type && cost.category === PassengerCategory.Child
        )
        .map((cost) => cost.value)
    )
  );
  const [infantValue, setInfantValue] = useField<number | null>(
    firstOrNull(
      item.costs
        .filter(
          (cost) =>
            cost.type === type && cost.category === PassengerCategory.Infant
        )
        .map((cost) => cost.value)
    )
  );

  const [fineDeltaForAll, setFineDeltaForAll] = useField<string | null>(
    item.fines
      .filter((fine) => fine.type === ServiceType.Any)
      .map((fine) => fine.flightDelta)[0] || "02:00"
  );
  const [fineValueForAllAfter, setFineValueForAllAfter] = useField<
    number | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Any)
      .map((fine) => fine.valueAfter)[0] || 0
  );
  const [fineValueForAllBefore, setFineValueForAllBefore] = useField<
    number | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Any)
      .map((fine) => fine.valueBefore)[0] || 0
  );

  const [fineDeltaForArrival, setFineDeltaForArrival] = useField<string | null>(
    item.fines
      .filter((fine) => fine.type === ServiceType.Arrival)
      .map((fine) => fine.flightDelta)[0] || "02:00"
  );
  const [fineValueForArrivalAfter, setFineValueForArrivalAfter] = useField<
    number | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Arrival)
      .map((fine) => fine.valueAfter)[0] || 0
  );
  const [fineValueForArrivalBefore, setFineValueForArrivalBefore] = useField<
    number | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Arrival)
      .map((fine) => fine.valueBefore)[0] || 0
  );

  const [fineDeltaForDeparture, setFineDeltaForDeparture] = useField<
    string | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Departure)
      .map((fine) => fine.flightDelta)[0] || "02:00"
  );
  const [fineValueForDepartureAfter, setFineValueForDepartureAfter] = useField<
    number | null
  >(
    item.fines
      .filter((fine) => fine.type === ServiceType.Departure)
      .map((fine) => fine.valueAfter)[0] || 0
  );
  const [fineValueForDepartureBefore, setFineValueForDepartureBefore] =
    useField<number | null>(
      item.fines
        .filter((fine) => fine.type === ServiceType.Departure)
        .map((fine) => fine.valueBefore)[0] || 0
    );

  const isCombo =
    type === ServiceType.ArrivalAndDeparture ||
    type === ServiceType.DepartureAndArrival;

  const [groupRule, setGroupRule] = useState(
    firstOrNull(item.rules.filter((rule) => rule.name === "GroupRateRule"))
  );
  const [isGroup, setIsGroup] = useState(
    groupRule !== null && (groupRule as GroupRateRule).minSize > 1
  );

  const [discountRule, setDiscountRule] = useState(
    firstOrNull(item.rules.filter((rule) => rule.name === "DiscountRateRule"))
  );
  const [isDiscount, setIsDiscount] = useState(discountRule !== null);

  const [urgencyRule, setUrgencyRule] = useState(
    firstOrNull(item.rules.filter((rule) => rule.name === "UrgencyRateRule"))
  );
  const [isUrgency, setIsUrgency] = useState(urgencyRule !== null);

  useEffect(() => {
    if (
      type !== ServiceType.ArrivalAndDeparture &&
      type !== ServiceType.DepartureAndArrival
    ) {
      setBaseValue(adultValue);
    }
  }, [adultValue, type, setBaseValue]);

  useEffect(() => {
    setGroupRule((prev) => {
      if (prev) return prev;

      const curr = new GroupRateRule();
      if (isGroup) {
        curr.minSize = Math.max(defaults.groupSize, 2);
        curr.minAdultAge = null;
        curr.minAdultCount = defaults.groupSize;
        curr.minChildAge = null;
        curr.minChildCount = 0;
      } else {
        curr.minSize = 0;
        curr.minAdultAge = null;
        curr.minAdultCount = 0;
        curr.minChildAge = null;
        curr.minChildCount = 0;
      }

      return curr;
    });
  }, [defaults, isGroup, setGroupRule]);

  useEffect(() => {
    if (isDiscount) {
      setDiscountRule((prev) => {
        if (prev) return prev;
        else return new DiscountRateRule();
      });
    }
  }, [defaults, isDiscount, setDiscountRule]);

  useEffect(() => {
    if (isUrgency) {
      setUrgencyRule((prev) => {
        if (prev) return prev;
        else return new UrgencyRateRule();
      });
    }
  }, [defaults, isUrgency, setDiscountRule]);

  useEffect(() => {
    const value = new Rate(PoolReference, CurrencyReference);

    value.id = id;
    value.code = code;

    if (adultValue !== null) {
      value.costs.push(new RateCost(PassengerCategory.Adult, type, adultValue));
      if (type !== ServiceType.Any) {
        value.costs.push(
          new RateCost(
            PassengerCategory.Adult,
            ServiceType.Any,
            baseValue || adultValue
          )
        );
      }
    }
    if (childValue !== null) {
      value.costs.push(new RateCost(PassengerCategory.Child, type, childValue));
    }
    if (infantValue !== null) {
      value.costs.push(
        new RateCost(PassengerCategory.Infant, type, infantValue)
      );
    }
    if (
      (type === ServiceType.Any || type === ServiceType.Transit) &&
      fineValueForAllAfter !== null &&
      fineValueForAllBefore !== null
    ) {
      value.fines.push(
        new RateFine(
          ServiceType.Any,
          fineDeltaForAll || "02:00",
          fineValueForAllAfter,
          fineValueForAllBefore
        )
      );
    }
    if (
      (type === ServiceType.Arrival ||
        type === ServiceType.ArrivalAndDeparture ||
        type === ServiceType.DepartureAndArrival) &&
      fineValueForArrivalAfter !== null &&
      fineValueForArrivalBefore !== null
    ) {
      value.fines.push(
        new RateFine(
          ServiceType.Arrival,
          fineDeltaForArrival || "02:00",
          fineValueForArrivalAfter,
          fineValueForArrivalBefore
        )
      );
    }
    if (
      (type === ServiceType.Departure ||
        type === ServiceType.ArrivalAndDeparture ||
        type === ServiceType.DepartureAndArrival) &&
      fineValueForDepartureAfter !== null &&
      fineValueForDepartureBefore !== null
    ) {
      value.fines.push(
        new RateFine(
          ServiceType.Departure,
          fineDeltaForDeparture || "02:00",
          fineValueForDepartureAfter,
          fineValueForDepartureBefore
        )
      );
    }

    if (groupRule) {
      value.rules.push(groupRule);
    }

    if (isDiscount && discountRule) {
      value.rules.push(discountRule);
    }

    if (isUrgency && urgencyRule) {
      value.rules.push(urgencyRule);
    }

    onChange(index, value);
  }, [
    id,
    code,
    type,
    index,
    onChange,
    baseValue,
    adultValue,
    childValue,
    infantValue,
    fineDeltaForAll,
    fineValueForAllAfter,
    fineValueForAllBefore,
    fineDeltaForArrival,
    fineValueForArrivalAfter,
    fineValueForArrivalBefore,
    fineDeltaForDeparture,
    fineValueForDepartureAfter,
    fineValueForDepartureBefore,
    groupRule,
    discountRule,
    urgencyRule,
    isDiscount,
    isUrgency,
  ]);

  return (
    <Div layout="padding-left">
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.rateRules")}>
            <Div layout="grid 12">
              {showDiscount && (
                <Div>
                  <Div>
                    <Div layout="grid 12">
                      <Div>
                        <SwitchBox
                          label={TXT("label.discounts")}
                          mode="check"
                          name={`${name}-isDiscount`}
                          value={isDiscount}
                          onChange={setIsDiscount}
                        />
                      </Div>
                      {isDiscount && discountRule && (
                        <Div>
                          <DiscountRateRuleView
                            name={`${name}-discount`}
                            item={discountRule as DiscountRateRule}
                            onChange={setDiscountRule}
                          />
                        </Div>
                      )}
                    </Div>
                  </Div>
                </Div>
              )}
              {showGroup && (
                <Div>
                  <Div>
                    <Div layout="grid 12">
                      <Div>
                        <SwitchBox
                          label={TXT("label.group")}
                          mode="check"
                          name={`${name}-isGroup`}
                          value={isGroup}
                          onChange={setIsGroup}
                        />
                      </Div>
                      {isGroup && groupRule && (
                        <Div>
                          <GroupRateRuleView
                            name={`${name}-group`}
                            item={groupRule as GroupRateRule}
                            onChange={setGroupRule}
                          />
                        </Div>
                      )}
                    </Div>
                  </Div>
                </Div>
              )}
              {showUrgency && (
                <Div>
                  <Div>
                    <Div layout="grid 12">
                      <Div>
                        <SwitchBox
                          label={TXT("label.urgency")}
                          mode="check"
                          name={`${name}-isUrgency`}
                          value={isUrgency}
                          onChange={setIsUrgency}
                        />
                      </Div>
                      {isUrgency && urgencyRule && (
                        <Div>
                          <UrgencyRateRuleView
                            name={`${name}-urgency`}
                            item={urgencyRule as UrgencyRateRule}
                            onChange={setUrgencyRule}
                          />
                        </Div>
                      )}
                    </Div>
                  </Div>
                </Div>
              )}
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <FieldSet label={TXT("label.cost")}>
            <Div layout="grid 12 6@md 3@lg">
              <Div>
                <NumberBox
                  label={TXT("label.adult")}
                  hint={!isCombo ? TXT("hint.baseCost") : undefined}
                  fill={true}
                  name={`${name}-adultValue`}
                  min={0}
                  value={adultValue}
                  validators={[required]}
                  onChange={setAdultValue}
                />
              </Div>
              <Div>
                <NumberBox
                  label={TXT("label.child")}
                  fill={true}
                  name={`${name}-childValue`}
                  min={0}
                  placeholder={
                    adultValue !== null ? formatNumber(adultValue) : undefined
                  }
                  value={childValue}
                  onChange={setChildValue}
                />
              </Div>
              <Div>
                <NumberBox
                  label={TXT("label.infant")}
                  fill={true}
                  name={`${name}-infantValue`}
                  min={0}
                  placeholder={
                    adultValue !== null ? formatNumber(adultValue) : undefined
                  }
                  value={infantValue}
                  onChange={setInfantValue}
                />
              </Div>
              {isCombo && (
                <Div>
                  <NumberBox
                    label={TXT("label.base")}
                    hint={TXT("hint.baseCost")}
                    fill={true}
                    name={`${name}-baseValue`}
                    min={0}
                    value={baseValue}
                    validators={[required]}
                    onChange={setBaseValue}
                  />
                </Div>
              )}
            </Div>
          </FieldSet>
        </Div>
        <Div>
          <Div>
            <Div layout="grid 12">
              {(type === ServiceType.Any || type === ServiceType.Transit) && (
                <Div>
                  <FieldSet label={TXT("label.fines")}>
                    <RateFineView
                      name={`${name}-fines`}
                      delta={fineDeltaForAll}
                      valueAfter={fineValueForAllAfter}
                      valueBefore={fineValueForAllBefore}
                      onDeltaChange={setFineDeltaForAll}
                      onValueAfterChange={setFineValueForAllAfter}
                      onValueBeforeChange={setFineValueForAllBefore}
                    />
                  </FieldSet>
                </Div>
              )}
              {type === ServiceType.Arrival && (
                <Div>
                  <FieldSet label={TXT("label.fines")}>
                    <RateFineView
                      name={`${name}-fines-arrival`}
                      delta={fineDeltaForArrival}
                      valueAfter={fineValueForArrivalAfter}
                      valueBefore={fineValueForArrivalBefore}
                      onDeltaChange={setFineDeltaForArrival}
                      onValueAfterChange={setFineValueForArrivalAfter}
                      onValueBeforeChange={setFineValueForArrivalBefore}
                    />
                  </FieldSet>
                </Div>
              )}
              {type === ServiceType.Departure && (
                <Div>
                  <FieldSet label={TXT("label.fines")}>
                    <RateFineView
                      name={`${name}-fines-departure`}
                      delta={fineDeltaForDeparture}
                      valueAfter={fineValueForDepartureAfter}
                      valueBefore={fineValueForDepartureBefore}
                      onDeltaChange={setFineDeltaForDeparture}
                      onValueAfterChange={setFineValueForDepartureAfter}
                      onValueBeforeChange={setFineValueForDepartureBefore}
                    />
                  </FieldSet>
                </Div>
              )}
              {(type === ServiceType.ArrivalAndDeparture ||
                type === ServiceType.DepartureAndArrival) && (
                <React.Fragment>
                  <Div>
                    <FieldSet label={TXT("label.finesForArrival")}>
                      <RateFineView
                        name={`${name}-fines-arrival`}
                        delta={fineDeltaForArrival}
                        valueAfter={fineValueForArrivalAfter}
                        valueBefore={fineValueForArrivalBefore}
                        onDeltaChange={setFineDeltaForArrival}
                        onValueAfterChange={setFineValueForArrivalAfter}
                        onValueBeforeChange={setFineValueForArrivalBefore}
                      />
                    </FieldSet>
                  </Div>
                  <Div>
                    <FieldSet label={TXT("label.finesForDeparture")}>
                      <RateFineView
                        name={`${name}-fines-departure`}
                        delta={fineDeltaForDeparture}
                        valueAfter={fineValueForDepartureAfter}
                        valueBefore={fineValueForDepartureBefore}
                        onDeltaChange={setFineDeltaForDeparture}
                        onValueAfterChange={setFineValueForDepartureAfter}
                        onValueBeforeChange={setFineValueForDepartureBefore}
                      />
                    </FieldSet>
                  </Div>
                </React.Fragment>
              )}
            </Div>
          </Div>
        </Div>
        {!readonly && isGroup && (
          <Div layout="flex vertical-center">
            <Div layout="fill" />
            <Div layout="fit">
              <Button
                disabled={form.submitting}
                look="outline"
                className="action"
                onClick={() => onRemove(index)}
              >
                <IntlFormat text={TXT("action.delete")} />
              </Button>
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
}

export interface SpanItemProps
  extends ItemViewProps<RateSpan, RateSpanCreateModel> {
  showResources?: boolean;
  showKinds?: boolean;
  showTypes?: boolean;
}

export function SpanItem(props: SpanItemProps): ReactElement {
  const {
    item,
    lock,
    readonly,
    showResources,
    showKinds,
    showTypes,
    onSubmit,
    onCancel,
    onDelete,
    onEdit,
    onFree,
  } = props;

  const pageSize = 100;
  const id = item.id;
  const isNew = id === EMPTY_ID;

  const form = useContext(FormContext);

  const formatMessage = useFormatMessage();

  const init = useMemo(
    () => firstOrNull(item.rates) || new Rate(PoolReference, CurrencyReference),
    [item.rates]
  );

  const [pool] = usePool(init.pool.id);

  const [name, setName] = useField<string | null>(item.name);

  const [rateFamilies] = useRateFamiliesV1();
  const [rateFamily, setRateFamily] = useField<Pair<RateFamilyV1> | null>(
    !isNew
      ? rateFamilies.filter(
          (pair) =>
            (pair.code === RateFamilyV1.Standard &&
              init.program == null &&
              init.contract === null) ||
            (pair.code === RateFamilyV1.Program && init.program !== null) ||
            (pair.code === RateFamilyV1.Contract && init.contract !== null)
        )[0] || null
      : rateFamilies.filter((pair) => pair.code === RateFamilyV1.Standard)[0]
  );

  const [rateKinds] = usePrimaryServiceKinds();
  const [rateKind, setRateKind] = useField<Pair<ServiceKind> | null>(
    rateKinds.filter((pair) => !isNew && pair.code === init.kind)[0] || null
  );

  const [rateTypes] = useServiceTypes();
  const [rateType, setRateType] = useField<Pair<ServiceType> | null>(
    rateTypes.filter((pair) => !isNew && pair.code === init.type)[0] || null
  );

  const [flightDirections] = useFlightDirections();
  const [flightDirection, setFlightDirection] =
    useField<Pair<FlightDirection> | null>(
      flightDirections.filter(
        (pair) => !isNew && pair.code === init.flightDirection
      )[0] || null
    );

  const [contract, setContract] = useOrganizationContract(
    init.contract ? init.contract.id : null
  );
  const [provider] = useOrganization(pool ? pool.organization.id : null);
  const [agency, setAgency] = useOrganization(
    contract ? contract.agency.id : null
  );
  const [program, setProgram] = useOrganizationProgram(
    init.program ? init.program.id : null
  );

  const organizationFilter = useMemo(
    () => ({
      byProviderId: pool ? pool.organization.id : EMPTY_ID,
      isConcluded: true,
      isAuthorized: true,
    }),
    [pool]
  );
  const [agencies, getAgencies] = useOrganizations(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Contract &&
      pool !== null,
    organizationFilter
  );

  const organizationContractFilter = useMemo(
    () => ({
      byProviderId: pool ? pool.organization.id : EMPTY_ID,
      byAgencyId: agency ? agency.id : EMPTY_ID,
      byType: OrganizationContractType.Deposit,
    }),
    [pool, agency]
  );
  const [contracts, getContracts] = useOrganizationContracts(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Contract &&
      agency !== null,
    organizationContractFilter
  );

  const organizationProgramFilter = useMemo(
    () => ({ byOrganizationId: provider ? provider.id : EMPTY_ID }),
    [provider]
  );
  const [programs, getPrograms] = useOrganizationPrograms(
    pageSize,
    !readonly &&
      isNew &&
      rateFamily !== null &&
      rateFamily.code === RateFamilyV1.Program,
    organizationProgramFilter
  );

  const [defaults] = useOrganizationDefaults(provider ? provider.id : null);

  const [bookableFromDate, setBookableFromDate] = useField<string | null>(
    init.bookableFrom
  );
  const [bookableTillDate, setBookableTillDate] = useField<string | null>(
    init.bookableTill
  );

  const [effectiveFromDate, setEffectiveFromDate] = useField<string | null>(
    init.validFrom
  );
  const [effectiveTillDate, setEffectiveTillDate] = useField<string | null>(
    init.validTill
  );

  const [effectiveFromTime, setEffectiveFromTime] = useField<string | null>(
    init.validFromTime
  );
  const [effectiveTillTime, setEffectiveTillTime] = useField<string | null>(
    init.validTillTime
  );

  const [days, setDays] = useField(init.validDays);

  const [renewal, setRenewal] = useField(init.validRenewal);

  const [resource, setResource] = useResource(
    init.resource !== null ? init.resource.id : null
  );
  const [airport, setAirport] = useAirport(resource?.airport.id ?? null);

  const resourceFilter = useMemo(
    () => ({
      byOwnerId:
        pool && pool.organization.type === OrganizationType.Provider
          ? pool.organization.id
          : undefined,
      byPoolId:
        pool && pool.organization.type !== OrganizationType.Provider
          ? pool.id
          : undefined,
      byAirportId: airport?.id,
    }),
    [pool, airport]
  );
  const [resources, getResources] = useResources(
    pageSize,
    !readonly && isNew && showResources && pool !== null,
    resourceFilter
  );
  const [airports, getAirports] = useAirports(
    pageSize,
    pool !== null,
    undefined,
    pool?.id
  );

  const [settings] = useResourceSettings(
    resource !== null ? resource.id : null
  );

  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  const groupDefaults = useMemo(() => {
    return {
      groupSize: settings?.groupSize ?? defaults?.groupSize ?? 2,
      minChildAge: settings?.minChildAge ?? defaults?.infantAge ?? 2,
      minAdultAge: settings?.minAdultAge ?? defaults?.childAge ?? 12,
    };
  }, [settings, defaults]);

  const orderedRates = useMemo(() => {
    const singleRates = item.rates.filter(
      (x) =>
        x.rules.filter((y) => y.name === "GroupRateRule").length === 0 ||
        (x.rules.filter((y) => y.name === "GroupRateRule")[0] as GroupRateRule)
          .minSize < 2
    );
    const groupRates = item.rates.filter(
      (x) =>
        x.rules.filter((y) => y.name === "GroupRateRule").length > 0 &&
        (x.rules.filter((y) => y.name === "GroupRateRule")[0] as GroupRateRule)
          .minSize > 1
    );
    return [...singleRates, ...groupRates];
  }, [item.rates]);
  const [rates, setRates] = useField(orderedRates);

  const handleAppend = useCallback(() => {
    setRates((prev) => {
      const curr = [...prev];
      const item = new Rate(PoolReference, CurrencyReference);
      item.id = guid();
      //item.rules.push(new GroupRateRule({ minSize: 2 }))
      curr.push(item);

      return curr;
    });
  }, [setRates]);

  const handleChange = useCallback(
    (index: number, value: Rate) => {
      setRates((prev) => {
        const curr = [...prev];
        curr.splice(index, 1, value);

        return curr;
      });
    },
    [setRates]
  );

  const handleRemove = useCallback(
    (index: number) => {
      setRates((prev) => {
        const curr = [...prev];
        curr.splice(index, 1);

        return curr;
      });
    },
    [setRates]
  );

  useEffect(() => {
    if (
      isNew &&
      (rateFamily === null || rateFamily.code !== RateFamilyV1.Program)
    ) {
      setProgram(null);
    }
    if (
      isNew &&
      (rateFamily === null || rateFamily.code !== RateFamilyV1.Contract)
    ) {
      setContract(null);
    }
  }, [isNew, rateFamily, setProgram, setContract]);

  useEffect(() => {
    if (
      isNew &&
      (agency === null || (contract && contract.agency.id !== agency.id))
    ) {
      setContract(null);
    }
  }, [isNew, agency, contract, setContract]);

  const transit =
    (resource && resource.kind === ServiceKind.Transit) ||
    (rateKind && rateKind.code === ServiceKind.Transit);
  useEffect(() => {
    if (isNew && transit) {
      setRateType(
        rateTypes.filter((pair) => pair.code === ServiceType.Arrival)[0]
      );
    }
  }, [isNew, transit, rateTypes, setRateType]);

  const getItem = useCallback((): RateSpanCreateModel => {
    const item = new RateSpanCreateModel();
    item.name = name || "";
    item.pool = pool || new PoolReference();
    item.kind = rateKind
      ? rateKind.code
      : resource !== null
      ? resource.kind
      : ServiceKind.Any;
    item.type = rateType ? rateType.code : ServiceType.Any;
    item.flightDirection = flightDirection
      ? flightDirection.code
      : FlightDirection.Any;
    item.resource = resource;
    if (rateFamily && rateFamily.code === RateFamilyV1.Contract) {
      item.contract = contract;
    }
    if (rateFamily && rateFamily.code === RateFamilyV1.Program) {
      item.program = program;
    }
    item.bookableFrom = bookableFromDate || "";
    item.bookableTill = bookableTillDate || "";
    item.validFrom = effectiveFromDate || "";
    item.validTill = effectiveTillDate || "";
    item.validFromTime = effectiveFromTime || "";
    item.validTillTime = effectiveTillTime || "";
    item.validDays = days;
    item.validRenewal = renewal;
    item.rates = rates;

    return item;
  }, [
    pool,
    name,
    flightDirection,
    rateFamily,
    rateKind,
    rateType,
    days,
    renewal,
    effectiveFromDate,
    effectiveTillDate,
    effectiveFromTime,
    effectiveTillTime,
    bookableFromDate,
    bookableTillDate,
    contract,
    program,
    resource,
    rates,
  ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (onSubmit) {
      const item = getItem();
      try {
        if (isNew) {
          await SpanAccess.create(item, true);
        } else {
          await SpanAccess.update(id, item, true);
        }
        await onSubmit(item);
      } catch (error) {
        if (error instanceof ClientError && error.data && error.data.errors) {
          const firstError =
            error.data.errors[Object.keys(error.data.errors)[0]];
          setValidationMessage(
            formatMessage(
              TXT("error.rateScheduleIntersectedWith", {
                code: firstError[0],
                name: firstError[1],
              })
            )
          );
        } else {
          console.error(error);
        }
      }
    }
  }, [formatMessage, getItem, id, isNew, setValidationMessage, onSubmit]);

  const renderRateLabel = useCallback(
    (index: number, rate: Rate): string => {
      const rules: string[] = [];
      if (
        rate.rules.some(
          (x) => x.name === "GroupRateRule" && (x as GroupRateRule).minSize > 1
        )
      ) {
        rules.push(formatMessage(TXT("label.rateWithGroupRule")));
      }
      if (rate.rules.some((x) => x.name === "UrgencyRateRule")) {
        rules.push(formatMessage(TXT("label.rateWithUrgencyRule")));
      }

      if (rules.length > 0) {
        return formatMessage(
          TXT("label.rateNo", {
            no: `${index + 1} ${
              rate.code ? `(${rate.code})` : ""
            } — ${rules.join(", ")}`,
          })
        );
      } else {
        return formatMessage(
          TXT("label.rateNo", {
            no: `${index + 1} ${rate.code ? `(${rate.code})` : ""}`,
          })
        );
      }
    },
    [formatMessage]
  );

  return (
    <Form
      loaded={true}
      lock={lock}
      readonly={readonly}
      onSubmit={onSubmit ? handleSubmit : undefined}
      onCancel={onCancel}
      onDelete={onDelete}
      onEdit={onEdit}
      onFree={onFree}
    >
      <Div layout="grid 12">
        <Div>
          <FieldSet label={TXT("label.parameters")}>
            <Div layout="grid 12 6@lg">
              <Div>
                <Div layout="grid 12 6@md">
                  <Div>
                    <TextBox
                      label={TXT("label.name")}
                      fill={true}
                      name="name"
                      value={name}
                      validators={[required, (value) => length(value, 3, 256)]}
                      onChange={setName}
                    />
                  </Div>
                  {showResources && airports.total > 1 && (
                    <Div>
                      <ComboBox
                        label={TXT("label.airports")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        searchable={true}
                        pageSize={pageSize}
                        name="airport"
                        data={airports}
                        value={airport}
                        valueKey="id"
                        valueLabel="name"
                        onChange={setAirport}
                        onFetch={getAirports}
                      />
                    </Div>
                  )}
                  {showResources && (
                    <Div>
                      <ComboBox
                        label={TXT("label.resource")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        searchable={true}
                        pageSize={pageSize}
                        name="resource"
                        data={resources}
                        value={resource}
                        valueKey="id"
                        valueLabel="name"
                        onChange={setResource}
                        onFetch={getResources}
                      />
                    </Div>
                  )}
                  {showKinds && resource === null && (
                    <Div>
                      <ComboBox
                        label={TXT("label.serviceKind")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        name="rateKind"
                        data={rateKinds.filter(
                          (x) => x.code !== ServiceKind.Transfer
                        )}
                        value={rateKind}
                        valueKey="code"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setRateKind}
                      />
                    </Div>
                  )}
                  {showTypes && (
                    <Div>
                      <ComboBox
                        label={TXT("label.serviceType")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        name="rateType"
                        data={rateTypes.filter(
                          (pair) =>
                            resource === null ||
                            (resource.type === ServiceType.Any &&
                              pair.code !== ServiceType.Transit) ||
                            resource.type === pair.code ||
                            (resource.isTransit &&
                              pair.code === ServiceType.Transit)
                        )}
                        value={rateType}
                        valueKey="code"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setRateType}
                      />
                    </Div>
                  )}
                  <Div>
                    <ComboBox
                      label={TXT("label.flightDirection")}
                      fill={true}
                      disabled={!isNew && !readonly}
                      name="flightDirection"
                      data={flightDirections.filter(
                        (pair) =>
                          resource === null ||
                          resource.flightDirection === FlightDirection.Any ||
                          resource.flightDirection === pair.code
                      )}
                      value={flightDirection}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setFlightDirection}
                    />
                  </Div>
                  <Div>
                    <ComboBox
                      label={TXT("label.rateFamily")}
                      fill={true}
                      disabled={!readonly && !isNew}
                      name="rateKind"
                      data={rateFamilies.filter(
                        (x) =>
                          provider &&
                          (provider.type === OrganizationType.Provider ||
                            x.code !== RateFamilyV1.Program)
                      )}
                      value={rateFamily}
                      valueKey="code"
                      valueLabel="name"
                      validators={[required]}
                      onChange={setRateFamily}
                    />
                  </Div>
                  {rateFamily && rateFamily.code === RateFamilyV1.Contract && (
                    <Div>
                      <ComboBox
                        label={TXT("label.agency")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        searchable={true}
                        pageSize={pageSize}
                        name="agency"
                        data={agencies}
                        value={agency}
                        valueKey="id"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setAgency}
                        onFetch={getAgencies}
                      />
                    </Div>
                  )}
                  {rateFamily && rateFamily.code === RateFamilyV1.Contract && (
                    <Div>
                      <ComboBox
                        label={TXT("label.contract")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        searchable={true}
                        pageSize={pageSize}
                        name="contract"
                        data={contracts}
                        value={contract}
                        valueKey="id"
                        valueLabel="number"
                        validators={[required]}
                        onChange={setContract}
                        onFetch={getContracts}
                      />
                    </Div>
                  )}
                  {rateFamily && rateFamily.code === RateFamilyV1.Program && (
                    <Div>
                      <ComboBox
                        label={TXT("label.corporateProgram")}
                        fill={true}
                        disabled={!readonly && !isNew}
                        searchable={true}
                        pageSize={pageSize}
                        name="corporateProgram"
                        data={programs}
                        value={program}
                        valueKey="id"
                        valueLabel="name"
                        validators={[required]}
                        onChange={setProgram}
                        onFetch={getPrograms}
                      />
                    </Div>
                  )}
                </Div>
              </Div>
              <Div>
                <Div layout="grid 12">
                  <Div>
                    <Div layout="flex">
                      <Div layout="fill">
                        <DateBox
                          label={TXT("label.salesPeriod")}
                          fill={true}
                          zone="UTC"
                          name="bookableFromDate"
                          value={bookableFromDate}
                          validators={[required]}
                          onChange={setBookableFromDate}
                        />
                      </Div>
                      <Div layout="fit">
                        <Dash />
                      </Div>
                      <Div layout="fill">
                        <DateBox
                          label=" "
                          fill={true}
                          zone="UTC"
                          name="bookableTillDate"
                          value={bookableTillDate}
                          validators={[required]}
                          onChange={setBookableTillDate}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div>
                    <Div layout="flex">
                      <Div layout="fill">
                        <DateBox
                          label={TXT("label.effectivePeriod")}
                          fill={true}
                          zone="UTC"
                          name="effectiveFromDate"
                          value={effectiveFromDate}
                          validators={[required]}
                          onChange={setEffectiveFromDate}
                        />
                      </Div>
                      <Div layout="fit">
                        <Dash />
                      </Div>
                      <Div layout="fill">
                        <DateBox
                          label=" "
                          fill={true}
                          zone="UTC"
                          name="effectiveTillDate"
                          value={effectiveTillDate}
                          validators={[required]}
                          onChange={setEffectiveTillDate}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div>
                    <Div layout="flex">
                      <Div layout="fill">
                        <TimeBox
                          label={TXT("label.effectiveTime")}
                          fill={true}
                          name="effectiveFromTime"
                          value={effectiveFromTime}
                          validators={[required]}
                          onChange={setEffectiveFromTime}
                        />
                      </Div>
                      <Div layout="fit">
                        <Dash />
                      </Div>
                      <Div layout="fill">
                        <TimeBox
                          label=" "
                          fill={true}
                          name="effectiveTillTime"
                          validators={[required]}
                          value={effectiveTillTime}
                          onChange={setEffectiveTillTime}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div>
                    <Div layout="grid 6 vertical-end">
                      <Div>
                        <WeekDays
                          label={TXT("label.effectiveDays")}
                          name="days"
                          days={days}
                          onChange={setDays}
                        />
                      </Div>
                      <Div>
                        <ToggleBox
                          label={TXT("label.autoRenewal")}
                          primary={true}
                          name="renewal"
                          value={renewal}
                          onChange={setRenewal}
                        />
                      </Div>
                    </Div>
                  </Div>
                  {validationMessage && (
                    <Div>
                      <Span intent="danger">{validationMessage}</Span>
                    </Div>
                  )}
                </Div>
              </Div>
            </Div>
          </FieldSet>
        </Div>
        <Div>{formatMessage(TXT("message.rateRules"))}</Div>
        {pool && (
          <Div>
            {formatMessage(TXT("label.currency"))}: {pool.currency.code}
          </Div>
        )}
        <Div>
          <Div layout="grid 12">
            {defaults &&
              rates.map((rate, rateIndex) => (
                <Div key={rate.id}>
                  <FieldSet label={renderRateLabel(rateIndex, rate)}>
                    <RateView
                      name={`rate-${rate.id}`}
                      item={rate}
                      type={rateType ? rateType.code : ServiceType.Any}
                      index={rateIndex}
                      defaults={groupDefaults}
                      readonly={readonly}
                      showDiscount={true}
                      showGroup={rateIndex > 0}
                      showUrgency={rateIndex > 0}
                      onChange={handleChange}
                      onRemove={handleRemove}
                    />
                  </FieldSet>
                </Div>
              ))}
          </Div>
        </Div>
        {!readonly && (
          <Div>
            <Div layout="flex vertical-center">
              <Div layout="fill" />
              <Div layout="fit">
                <Button
                  disabled={form.submitting}
                  className="action"
                  onClick={handleAppend}
                >
                  <IntlFormat text={TXT("action.append")} />
                </Button>
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </Form>
  );
}

export interface SpanGridProps extends GridViewProps<RateSpan> {
  pools?: PoolReference[];
  filter?: RateFilter;
  showCategories?: boolean;
  showKinds?: boolean;
  showTypes?: boolean;
  onItemCreate?: ItemCreateHandler;
  onItemFilter?: ItemFilterHandler<RateSpanFilter>;
  onItemSearch?: ItemSearchHandler;
}

export function SpanGrid(props: SpanGridProps): ReactElement {
  const {
    data,
    pools,
    filter,
    showCategories,
    showKinds,
    showTypes,
    onItemCreate,
    onItemFilter,
    onItemSearch,
    onItemSelect,
    onPageChange,
    onSortChange,
  } = props;

  const formatMessage = useFormatMessage();
  const formatCode = useFormatCode();
  const formatCost = useFormatCost();
  const formatDateRange = useFormatDateRange();
  const formatTimeRange = useFormatTimeRange();
  const formatDays = useFormatDays();
  const formatTime = useFormatTime();

  const [currentPattern, delayedPattern, setPattern] = useDelay<string | null>(
    null
  );
  const [processedData, setProcessedData] = useState<Chunk<any>>(new Chunk());
  const [passengerCategories] = usePassengerCategories();
  const [passengerCategory, setPassengerCategory] =
    useField<Pair<PassengerCategory> | null>(
      firstOrNull(
        passengerCategories.filter((x) => x.code === filter?.byCategory)
      )
    );
  const [, formatRateFamily] = useRateFamiliesV1();
  const [rateKinds, formatRateKind] = usePrimaryServiceKinds();
  const [rateKind, setRateKind] = useField<Pair<ServiceKind> | null>(
    firstOrNull(rateKinds.filter((x) => x.code === filter?.byKind))
  );
  const [rateTypes, formatRateType] = useServiceTypes();
  const [, formatFlightDirection] = useFlightDirections();
  const [rateType, setRateType] = useField<Pair<ServiceType> | null>(
    firstOrNull(rateTypes.filter((x) => x.code === filter?.byType))
  );
  const [flightDate, setFlightDate] = useField<string | null>(
    filter?.byFlightDate ?? null
  );
  const [airports, getAirports] = useAirports(
    100,
    pools && pools.length > 0,
    undefined,
    useMemo(() => pools?.map((pool) => pool.id), [pools])
  );
  const [airport, setAirport] = useAirport(filter?.byAirportId ?? null);

  const handlePassengerCategory = useCallback(
    (value: Pair<PassengerCategory> | null): void => {
      setPassengerCategory(value);
      if (onItemFilter) onItemFilter({ ...filter, byCategory: value?.code });
    },
    [filter, onItemFilter, setPassengerCategory]
  );

  const handleKind = useCallback(
    (value: Pair<ServiceKind> | null): void => {
      setRateKind(value);
      if (onItemFilter) onItemFilter({ ...filter, byKind: value?.code });
    },
    [filter, onItemFilter, setRateKind]
  );

  const handleType = useCallback(
    (value: Pair<ServiceType> | null): void => {
      setRateType(value);
      if (onItemFilter) onItemFilter({ ...filter, byType: value?.code });
    },
    [filter, onItemFilter, setRateType]
  );

  const handleAirport = useCallback(
    (value: Airport | null): void => {
      setAirport(value);
      if (onItemFilter) onItemFilter({ ...filter, byAirportId: value?.id });
    },
    [filter, onItemFilter, setAirport]
  );

  const handleDate = useCallback(
    (value: string | null): void => {
      setFlightDate(value);
      if (onItemFilter)
        onItemFilter({ ...filter, byFlightDate: value ? value : undefined });
    },
    [filter, onItemFilter, setFlightDate]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      const processedData = new Chunk<any>(
        [],
        data.skip,
        data.take,
        data.total
      );
      const programs = new Map<string, OrganizationProgram>();
      const contracts = new Map<string, OrganizationContract>();
      const currencies = new Map<string, Currency>();
      const resources = new Map<string, Resource>();
      for (const item of data.data) {
        const init =
          firstOrNull(item.rates) || new Rate(PoolReference, CurrencyReference);
        const rates = item.rates.filter(
          (rate) =>
            !rate.rules.some(
              (rule) =>
                rule.name === "GroupRateRule" &&
                (rule as GroupRateRule).minSize > 0
            )
        );
        const costs = rates.flatMap((x) => x.costs);
        const fines = rates.flatMap((x) => x.fines);
        const program =
          init.program !== null
            ? programs.get(init.program.id) ||
              programs
                .set(
                  init.program.id,
                  await OrganizationProgramAccess.getOne(init.program.id)
                )
                .get(init.program.id) ||
              null
            : null;
        const contract =
          init.contract !== null
            ? contracts.get(init.contract.id) ||
              contracts
                .set(
                  init.contract.id,
                  await OrganizationContractAccess.getOne(init.contract.id)
                )
                .get(init.contract.id) ||
              null
            : null;
        const currency =
          currencies.get(init.currency.id) ||
          currencies
            .set(
              init.currency.id,
              await CurrencyAccess.getOne(init.currency.id)
            )
            .get(init.currency.id);
        const resource =
          init.resource !== null
            ? resources.get(init.resource.id) ||
              resources
                .set(
                  init.resource.id,
                  await ResourceAccess.getOne(init.resource.id)
                )
                .get(init.resource.id) ||
              null
            : null;
        processedData.data.push({
          id: item.id,
          code: item.code,
          name: item.name,
          family:
            program !== null
              ? `${formatRateFamily(RateFamilyV1.Program)} / ${program.name}`
              : contract != null
              ? `${formatRateFamily(RateFamilyV1.Contract)} / ${
                  contract.number
                }`
              : formatRateFamily(RateFamilyV1.Standard),
          kind: `${formatRateKind(init.kind)}`,
          type: `${formatRateType(init.type)}`,
          flightDirection: `${formatFlightDirection(init.flightDirection)}`,
          resource: resource !== null ? resource.name : "",
          bookablePeriod: formatDateRange(init.bookableFrom, init.bookableTill),
          effectivePeriod: formatDateRange(init.validFrom, init.validTill),
          effectiveTime: formatTimeRange(
            init.validFromTime,
            init.validTillTime
          ),
          effectiveDays: formatDays(init.validDays),
          adultCost: formatCost(
            costs.filter((x) => x.category === PassengerCategory.Adult)[0]
              ?.value ?? 0,
            currency ? currency.code : undefined
          ),
          childCost: formatCost(
            costs.filter((x) => x.category === PassengerCategory.Child)[0]
              ?.value ??
              costs.filter((x) => x.category === PassengerCategory.Adult)[0]
                ?.value ??
              0,
            currency ? currency.code : undefined
          ),
          infantCost: formatCost(
            costs.filter((x) => x.category === PassengerCategory.Infant)[0]
              ?.value ??
              costs.filter((x) => x.category === PassengerCategory.Adult)[0]
                ?.value ??
              0,
            currency ? currency.code : undefined
          ),
          fines: fines
            .map((x) => `За ${formatTime(x.flightDelta)}ч — ${x.valueAfter}%`)
            .join(" / "),
        });
      }
      setProcessedData(processedData);
    })();
  }, [
    data.data,
    data.total,
    data.skip,
    data.take,
    setProcessedData,
    passengerCategories,
    formatCode,
    formatCost,
    formatRateKind,
    formatRateType,
    formatRateFamily,
    formatDateRange,
    formatTimeRange,
    formatDays,
    formatFlightDirection,
    formatTime,
  ]);

  useEffect(() => {
    if (onItemSearch) {
      onItemSearch(delayedPattern || "");
    }
  }, [delayedPattern, onItemSearch]);

  const [width, setWidth] = useState<number>(1);
  const [, innerWidth] = useWindowSize();

  useEffect(() => {
    setWidth(1);
  }, [innerWidth]);

  const handleResize = useCallback(
    (rect: ContentRect): void => {
      if (rect.bounds && rect.bounds) {
        setWidth(rect.bounds.width);
      }
    },
    [setWidth]
  );

  const handleItemSelect = useCallback(
    (item: { id: string }): void => {
      const originalItem = data.data.filter((i) => i.id === item.id)[0];
      if (onItemSelect && originalItem) {
        onItemSelect(originalItem);
      }
    },
    [data.data, onItemSelect]
  );

  return (
    <Div layout="grid 12">
      {(onItemCreate || onItemSearch) && (
        <Div>
          <Div layout="flex">
            {onItemSearch && (
              <Div layout="fill">
                <TextBox
                  placeholder={TXT("label.search")}
                  fill={true}
                  name="search-pattern"
                  value={currentPattern}
                  onChange={setPattern}
                />
              </Div>
            )}
            {onItemCreate && (
              <Div layout="fit">
                <Button
                  primary={true}
                  className="action"
                  onClick={onItemCreate}
                >
                  {formatMessage(TXT("action.create"))}
                </Button>
              </Div>
            )}
          </Div>
        </Div>
      )}
      {onItemFilter && (
        <Div>
          <Div layout="grid 12 6@md 3@lg">
            {showCategories && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.passengerCategory")}
                  searchable={true}
                  fill={true}
                  name="filter-passengerCategory"
                  data={passengerCategories}
                  value={passengerCategory}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handlePassengerCategory}
                />
              </Div>
            )}
            {showKinds && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.serviceKind")}
                  searchable={true}
                  fill={true}
                  name="filter-rateKind"
                  data={rateKinds}
                  value={rateKind}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handleKind}
                />
              </Div>
            )}
            {showTypes && (
              <Div>
                <ComboBox
                  placeholder={TXT("label.serviceType")}
                  searchable={true}
                  fill={true}
                  name="filter-rateType"
                  data={rateTypes}
                  value={rateType}
                  valueKey="code"
                  valueLabel="name"
                  onChange={handleType}
                />
              </Div>
            )}
            <Div>
              <ComboBox
                placeholder={TXT("label.airports")}
                searchable={true}
                fill={true}
                name={"filter-airport"}
                data={airports}
                value={airport}
                valueKey={"id"}
                valueLabel={"name"}
                onChange={handleAirport}
                onFetch={getAirports}
              />
            </Div>
            <Div>
              <DateBox
                fill={true}
                name="filter-flightDate"
                zone="UTC"
                value={flightDate}
                onChange={handleDate}
              />
            </Div>
          </Div>
        </Div>
      )}
      <Measure client={true} bounds={true} onResize={handleResize}>
        {({ measureRef }) => (
          <Div ref={measureRef}>
            <Grid
              data={processedData}
              width={width}
              onItemSelect={onItemSelect ? handleItemSelect : undefined}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
            >
              <GridColumn
                title={TXT("label.code")}
                field="code"
                width={92}
                sortable={false}
                locked={true}
              />
              <GridColumn title={TXT("label.name")} field="name" width={256} />
              <GridColumn
                title={TXT("label.rateFamily")}
                field="family"
                width={256}
                sortable={false}
              />
              {showKinds && (
                <GridColumn
                  title={TXT("label.serviceKind")}
                  field="kind"
                  width={128}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.resource")}
                field="resource"
                width={256}
                sortable={false}
              />
              {showTypes && (
                <GridColumn
                  title={TXT("label.serviceType")}
                  field="type"
                  width={128}
                  sortable={false}
                />
              )}
              <GridColumn
                title={TXT("label.flightDirection")}
                field="flightDirection"
                width={128}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.salesPeriod")}
                field="bookablePeriod"
                width={192}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.effectivePeriod")}
                field="effectivePeriod"
                width={192}
                sortable={false}
              />
              <GridColumn
                title={TXT("label.fines")}
                field="fines"
                width={256}
                sortable={false}
              />
              <GridColumn
                title={TXT("cost.adult")}
                field="adultCost"
                width={128}
                sortable={false}
                locked={true}
              />
              <GridColumn
                title={TXT("cost.child")}
                field="childCost"
                width={128}
                sortable={false}
                locked={true}
              />
              <GridColumn
                title={TXT("cost.infant")}
                field="infantCost"
                width={128}
                sortable={false}
                locked={true}
              />
            </Grid>
          </Div>
        )}
      </Measure>
    </Div>
  );
}
