import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { AuthContext } from "../auth";
import { Chunk, Pair } from "../data";
import { Button, Confirm, NavPathItem } from "../gears";
import { ComboBox } from "../gears/inputs";
import { EventAccess } from "../parts/data/access";
import { EventType, Event, Order, Seat } from "../parts/data/models";
import { Div, Loader, TXT } from "../parts/gears";
import { useCounter, useEventTypes, useTimer } from "../parts/hooks";
import { OrderQueueGrid, SeatQueueGrid } from "../parts/views";
import { OrderItemPage, OrderReturnConfirmPage, OrderReturnRequestPage } from './Orders'
import { PageProps, withPage } from "./withPage";

function QueuePage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props;

  const auth = useContext(AuthContext);

  const [ types ] = useEventTypes();
  const [ type, setType ] = useState<Pair<EventType> | null>(types[0] || null);
  const [ toggledClearConfirmation, setToggledClearConfirmation ] = useState(false);
  const [ processingClearing, setProcessingClearing ] = useState(false);
  const [ counter, increase ] = useCounter();
  const [ ids, setIds ] = useState<string[]>([]);

  const handleOrderNavigate = useCallback((path: string, state?: any) => {
    if (path) {
      onNavigate(`order/${path}`, state);
    } else {
      onNavigate("");
    }
  }, [ onNavigate ]);

  const toggleClearConfirmation = useCallback((): void => {
    setToggledClearConfirmation(prev => !prev)
  }, [ setToggledClearConfirmation ]);

  const handleClearConfirmation = useCallback((confirmed: boolean): void => {
    setProcessingClearing(confirmed);
    if (!confirmed) {
      toggleClearConfirmation();
    }
  }, [ setProcessingClearing, toggleClearConfirmation ]);

  const handleSelected = useCallback((id: string, selected: boolean): void => {
    setIds(prev => {
      const curr = prev.filter(x => x !== id);
      if (selected) {
        curr.push(id);
      }
      return curr;
    });
  }, [ setIds ]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (processingClearing && type) {
        await EventAccess.clear({ type: type.code, ids: ids.length > 0 ? ids : null });
        setProcessingClearing(false);
        toggleClearConfirmation();
        increase();
      }
    })();
  }, [ processingClearing, type, ids, toggleClearConfirmation, increase ]);

  useEffect(() => {
    setIds([]);
  }, [ type, setIds ]);

  return (
    <Switch>
      <Route exact={true}
             path={`${match.path}/order/:orderId`}
             render={props => <OrderItemPage {...props} onLoaded={onLoaded} onNavigate={handleOrderNavigate} onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/order/:orderId/return`}
             render={props => <OrderReturnRequestPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/order/:orderId/confirm`}
             render={props => <OrderReturnConfirmPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true} path={`${match.path}`} render={props =>
        <Div layout="grid 12">
          <Div>
            <Div layout="grid 12 3@lg">
              <Div>
                <ComboBox fill={true}
                          name="type"
                          data={types.filter(pair => !auth.profile.isAgent || pair.code === EventType.Order)}
                          value={type}
                          valueKey="code"
                          valueLabel="name"
                          onChange={setType} />
              </Div>
              {type !== null &&
              <Div>
                <Button look="bare"
                        icon="trash"
                        text={ids.length === 0 ? TXT("action.clearAll") : TXT("action.clearSel")}
                        onClick={toggleClearConfirmation} />
              </Div>
              }
            </Div>
          </Div>
          <Div>
            {type && type.code === EventType.Order &&
            <OrderQueuePage {...props} counter={counter} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} onSelected={handleSelected} />
            }
            {type && type.code === EventType.Time &&
            <SeatQueuePage {...props} counter={counter} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} onSelected={handleSelected} />
            }
          </Div>
          {toggledClearConfirmation &&
            <Confirm title={TXT("prompt.clearQueue.title")}
                     message={TXT("prompt.clearQueue.message")}
                     locked={processingClearing}
                     onConfirm={handleClearConfirmation} />
          }
        </Div>
      } />
    </Switch>
  );
}

interface OrderQueuePageProps extends RouteComponentProps {
  counter?: number;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
  onSelected: (id: string, select: boolean) => void;
}

function OrderQueuePage(props: OrderQueuePageProps): ReactElement {
  const { counter, onLoaded, onNavigate, onError, onSelected } = props;

  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState(new Chunk<Event<Order>>());
  const [ sort, setSort ] = useState("");
  const [ skip, setSkip ] = useState(0);
  const [ take, setTake ] = useState(15);

  const reload = useCallback((sort?: string, skip?: number, take?: number): void => {
    if (sort !== undefined) setSort(sort);
    if (skip !== undefined) setSkip(skip);
    if (take !== undefined) setTake(take);
    setLoading(true);
  }, [ setLoading, setSort, setSkip, setTake ]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await EventAccess.getAll(undefined, sort, skip, take, EventType.Order);
          setData(data as Chunk<Event<Order>>);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    sort, skip, take, setData,
    setLoaded, setLoading, onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [ loaded, onLoaded ]);

  useEffect(() => {
    reload();
  }, [ counter, reload ])

  useTimer(() => setLoading(true));

  const handleItemSelect = useCallback((record: Event<Order>, selected?: boolean): void => {
    if (selected === undefined) {
      onNavigate(`order/${(record.details.id)}`);
    } else {
      onSelected(record.id, selected);
    }
  }, [ onNavigate, onSelected ]);

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, skip, take);
  }, [ reload ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    reload(orderBy);
  }, [ reload ]);

  return (
    <Loader loading={!loaded && loading}>
      <OrderQueueGrid data={data}
                      onItemSelect={handleItemSelect}
                      onPageChange={handlePageChange}
                      onSortChange={handleSortChange} />
    </Loader>
  );
}

interface SeatQueuePageProps extends RouteComponentProps {
  counter?: number;
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
  onSelected: (id: string, select: boolean) => void;
}

function SeatQueuePage(props: SeatQueuePageProps): ReactElement {
  const { counter, onLoaded, onNavigate, onError, onSelected } = props;

  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState(new Chunk<Event<Seat>>());
  const [ sort, setSort ] = useState("");
  const [ skip, setSkip ] = useState(0);
  const [ take, setTake ] = useState(15);

  const reload = useCallback((sort?: string, skip?: number, take?: number): void => {
    if (sort !== undefined) setSort(sort);
    if (skip !== undefined) setSkip(skip);
    if (take !== undefined) setTake(take);
    setLoading(true);
  }, [ setLoading, setSort, setSkip, setTake ]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await EventAccess.getAll(undefined, sort, skip, take, EventType.Time);
          setData(data as Chunk<Event<Seat>>);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [
    loading,
    sort, skip, take, setData,
    setLoaded, setLoading, onError,
  ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [ loaded, onLoaded ]);

  useEffect(() => {
    reload();
  }, [ counter, reload ])

  useTimer(() => setLoading(true));

  const handleItemSelect = useCallback((record: Event<Seat>, selected?: boolean): void => {
    if (selected === undefined) {
      onNavigate(`order/${(record.details.order.id)}`);
    } else {
      onSelected(record.id, selected);
    }
  }, [ onNavigate, onSelected ]);

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, skip, take);
  }, [ reload ]);

  const handleSortChange = useCallback((orderBy: string): void => {
    reload(orderBy);
  }, [ reload ]);

  return (
    <Loader loading={!loaded && loading}>
      <SeatQueueGrid data={data}
                     onItemSelect={handleItemSelect}
                     onPageChange={handlePageChange}
                     onSortChange={handleSortChange} />
    </Loader>
  );
}

function pathBuilder(items: Order[]): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: TXT("page.queues"),
  });
  for (const item of items) {
    pathItems.push({
      path: item.id > 0 ? `order/${item.id}` : "new",
      text: item.id > 0 ? TXT("page.order", { id: item.id.toString().padStart(6, "0").toUpperCase() }) : TXT("page.orders.new"),
    });
  }

  return pathItems;
}

export const Queues = withPage(QueuePage, pathBuilder);
Queues.displayName = "QueuesPages";
