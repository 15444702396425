import { CityBaseV1, CityReferenceV1 } from "./City";
import { ContractBaseV1, ContractReferenceV1 } from "./Contract";
import { CorporateContractBaseV1 } from "./CorporateContract";
import { IReference } from "./IReference";
import { PassengerV1, PassengerCategoryV1, PassengerKlassV1, PassengerReferenceV1 } from "./Passenger";
import { RateBaseV1, RateCostV1, RateFineV1 } from "./Rate";
import { ResourceBaseV1, ResourceReferenceV1 } from "./Resource";
import { ServiceBaseV1, ServiceReferenceV1, ServiceTypeV1 } from "./Service";
import { TerminalBaseV1, TerminalReferenceV1 } from "./Terminal";
import { TransferPointTypeV1 } from "./Transfer";
import { OrganizationFreeAccessTypeV1 } from "./OrganizationFreeAccessSettings";

export enum SeatStatusV1 {
  Paid = "paid",
  Rated = "rated",
  Requested = "requested",
  Rejected = "rejected",
  NoSchedule = "noSchedule",
  NoRate = "noRate",
  NoLimit = "noLimit",
  NoSeat = "noSeat",
  Fined = "fined",
  Cancelled = "cancelled",
  Forced = "forced",
  Returned = "returned",
}

export enum OrderTypeV1 {
  Standard = "standard",
  Business = "business",
  Corporate = "corporate",
  Reservation = "reservation",
}

export enum OrderStatusV1 {
  Rated = "rated",
  Created = "created",
  Confirmed = "confirmed",
  Rejected = "rejected",
  Cancelled = "cancelled",
  Paid = "paid",
  Completed = "completed",
  Forced = "forced"
}

export class ServiceFieldV1 {
  public id: string;
  public value: string;

  public constructor() {
    this.id = "";
    this.value = "";
  }
}

export class RatedResourceV1 {
  public id: number;
  public resource: ResourceBaseV1;
  public flightCity: CityBaseV1;
  public flightType: ServiceTypeV1;
  public flightNumber: string;
  public flightDate: string;
  public passengers: RatedPassengerV1[];
  public services: RatedServiceV1[];

  public constructor() {
    this.id = 0;
    this.resource = new ResourceBaseV1();
    this.flightCity = new CityBaseV1();
    this.flightType = ServiceTypeV1.All;
    this.flightNumber = "";
    this.flightDate = "";
    this.passengers = [];
    this.services = [];
  }
}

export class RatedPassengerV1 {
  public id: number;
  public passenger: PassengerReferenceV1;
  public category: PassengerCategoryV1;
  public rate: RateBaseV1 | null;
  public base: RateCostV1 | null;
  public cost: RateCostV1 | null;
  public fine: RateFineV1 | null;
  public seat: number;
  public expectedFrom: string;
  public expectedTill: string;
  public visitedFrom: string | null;
  public visitedTill: string | null;
  public status: SeatStatusV1;
  public statusText: string | null;
  public reason: string | null;
  public attachments: ForcedAttachmentV1[] | null;

  public constructor() {
    this.id = 0;
    this.passenger = new PassengerReferenceV1();
    this.category = PassengerCategoryV1.Adult;
    this.rate = null;
    this.base = null;
    this.cost = null;
    this.fine = null;
    this.seat = 0;
    this.expectedFrom = "";
    this.expectedTill = "";
    this.visitedFrom = null;
    this.visitedTill = null;
    this.status = SeatStatusV1.NoRate;
    this.statusText = null;
    this.reason = null;
    this.attachments = null;
  }
}

export class RatedServiceV1 {
  public id: number;
  public service: ServiceBaseV1;
  public cost: RateCostV1 | null;
  public fields: ServiceFieldV1[] | null;
  public status: SeatStatusV1;
  public count: number | null;
  public hours: number | null;
  public from: string | null;
  public till: string | null;
  public departureTime: string | null;
  public destinationPoint: TerminalBaseV1 | null;
  public destinationPointType: TransferPointTypeV1 | null;

  public constructor() {
    this.id = 0;
    this.service = new ServiceBaseV1();
    this.cost = null;
    this.fields = null;
    this.status = SeatStatusV1.NoRate;
    this.count = null;
    this.hours = null;
    this.from = null;
    this.till = null;
    this.departureTime = null;
    this.destinationPoint = null;
    this.destinationPointType = null;
  }
}

export class OrderFilterV1 {
  public flightDate?: string;
  public resourceId?: number;
}

export class OrderReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export enum OrderChangeActionV1 {
  Create = "create",
  Update = "update",
  Delete = "delete",
  Confirm = "confirm",
  Reject = "reject",
  Withdraw = "withdraw",
  Block = "block",
  Patch = "patch",
  Finalize = "finalize",
}

export class OrderChangeV1 extends OrderReferenceV1 {
  public action: OrderChangeActionV1;
  public inputModel: string | null;
  public outputModel: string | null;
  public performedAt: string;
  public performedBy: string;

  public constructor() {
    super();
    this.action = OrderChangeActionV1.Create;
    this.inputModel = null;
    this.outputModel = null;
    this.performedAt = "";
    this.performedBy = "";
  }
}

export class OrderRecord extends OrderReferenceV1 {
  public type: OrderTypeV1;
  public status: OrderStatusV1;
  public flightDate: string;
  public flightZone: string;
  public passenger: string;
  public passengerCount: number;
  public provider: string;
  public agency: string;
  public value: number;
  public createdAt: string;
  public createdBy: string;

  public constructor() {
    super();
    this.type = OrderTypeV1.Standard;
    this.status = OrderStatusV1.Created;
    this.flightDate = "";
    this.flightZone = "UTC";
    this.passenger = "";
    this.passengerCount = 0;
    this.provider = "";
    this.agency = "";
    this.value = 0;
    this.createdAt = "";
    this.createdBy = "";
  }
}

export class OrderBaseV1 extends OrderReferenceV1 {
  public type: OrderTypeV1;
  public status: OrderStatusV1;
  public card: CorporateContractBaseV1 | null;
  public contract: ContractBaseV1;

  public constructor() {
    super();
    this.type = OrderTypeV1.Standard;
    this.status = OrderStatusV1.Created;
    this.card = null;
    this.contract = new ContractBaseV1();
  }
}

export class OrderV1 extends OrderBaseV1 {
  public passengers: PassengerV1[];
  public assistants: PassengerV1[] | null;
  public spans: FlightSpanV1[];
  public contactName: string;
  public contactEmail: string;
  public contactPhone: string;
  public corporateClient: string | null;
  public description: string | null;
  public paymentMode: OrderPaymentModeV1 | null;
  public paymentType: OrderPaymentTypeV1 | null;
  public createdAt: string;
  public createdBy: string;
  public modifiedAt: string;
  public modifiedBy: string;
  public confirmedAt: string | null;
  public confirmedBy: string | null;
  public paidAt: string | null;
  public paidBy: string | null;
  public cancelledAt: string | null;
  public cancelledBy: string | null;
  public completedAt: string | null;
  public completedBy: string | null;
  public total: number;
  public unpaid: number;
  public penalty: number;
  public refund: number;
  public forcedReason: string | null;
  public forcedAttachments: ForcedAttachmentV1[] | null;

  public constructor() {
    super();
    this.passengers = [];
    this.assistants = null;
    this.spans = [];
    this.contactName = "";
    this.contactEmail = "";
    this.contactPhone = "";
    this.corporateClient = null;
    this.description = null;
    this.paymentMode = null;
    this.paymentType = null;
    this.status = OrderStatusV1.Rated;
    this.createdAt = "";
    this.createdBy = "";
    this.modifiedAt = "";
    this.modifiedBy = "";
    this.confirmedAt = null;
    this.confirmedBy = null;
    this.paidAt = null;
    this.paidBy = null;
    this.cancelledAt = null;
    this.cancelledBy = null;
    this.completedAt = null;
    this.completedBy = null;
    this.total = 0;
    this.unpaid = 0;
    this.penalty = 0;
    this.refund = 0;
    this.forcedReason = null;
    this.forcedAttachments = null;
  }
}

export class FlightSpanV1 {
  public resources: RatedResourceV1[];

  public constructor() {
    this.resources = [];
  }
}

export class OrderedResourceV1 {
  public resource: ResourceReferenceV1;
  public flightCity: CityReferenceV1;
  public flightType: ServiceTypeV1;
  public flightNumber: string;
  public flightDate: string;
  public services: OrderedServiceV1[];

  public constructor() {
    this.resource = new ResourceReferenceV1();
    this.flightCity = new CityReferenceV1();
    this.flightType = ServiceTypeV1.All;
    this.flightNumber = "";
    this.flightDate = "";
    this.services = [];
  }
}

export class OrderedServiceV1 {
  public service: ServiceReferenceV1;
  public fields: ServiceFieldV1[] | null;

  public constructor() {
    this.service = new ServiceReferenceV1();
    this.fields = null;
  }
}

export class ModifiedResourceV1 {
  public id: number;
  public resource: ResourceReferenceV1;
  public flightCity: CityReferenceV1;
  public flightType: ServiceTypeV1;
  public flightNumber: string;
  public flightDate: string;
  public passengers: ModifiedPassengerV1[];
  public services: ModifiedServiceV1[];

  public constructor() {
    this.id = 0;
    this.resource = new ResourceReferenceV1();
    this.flightCity = new CityReferenceV1();
    this.flightType = ServiceTypeV1.All;
    this.flightNumber = "";
    this.flightDate = "";
    this.passengers = [];
    this.services = [];
  }
}

export class ModifiedServiceV1 {
  public id: number;
  public service: ServiceReferenceV1;
  public fields: ServiceFieldV1[] | null;
  public count: number | null;
  public hours: number | null;
  public from: string | null;
  public till: string | null;
  public departureTime: string | null;
  public destinationPoint: TerminalReferenceV1 | null;
  public destinationPointType: TransferPointTypeV1 | null;
  public return: boolean;

  public constructor() {
    this.id = 0;
    this.service = new ServiceReferenceV1();
    this.fields = null;
    this.count = null;
    this.hours = null;
    this.from = null;
    this.till = null;
    this.departureTime = null;
    this.destinationPoint = null;
    this.destinationPointType = null;
    this.return = false;
  }
}

export class ModifiedPassengerV1 {
  public id: number;
  public return: boolean;
  public forced: boolean;
  public reason: string | null;
  public attachments: ForcedAttachmentV1[] | null;

  public constructor() {
    this.id = 0;
    this.return = false;
    this.forced = false;
    this.reason = null;
    this.attachments = null;
  }
}

export class OrderUpdateModelV1 {
  public passengers: PassengerV1[];
  public assistants: PassengerV1[] | null;
  public resources: OrderedResourceV1[];
  public contactName: string;
  public contactEmail: string;
  public contactPhone: string;
  public corporateClient: string | null;
  public description: string | null;

  public constructor() {
    this.passengers = [];
    this.assistants = null;
    this.resources = [];
    this.contactName = "";
    this.contactEmail = "";
    this.contactPhone = "";
    this.corporateClient = null;
    this.description = null;
  }
}

export class OrderCreateModelV1 extends OrderUpdateModelV1 {
  public contract: ContractReferenceV1;
  public card: CorporateContractBaseV1 | null;
  public type: OrderTypeV1;

  public constructor() {
    super();
    this.contract = new ContractReferenceV1();
    this.card = null;
    this.type = OrderTypeV1.Standard;
  }
}

export class OrderModifyModelV1 {
  public resources: ModifiedResourceV1[];
  public contactName: string;
  public contactEmail: string;
  public contactPhone: string;
  public corporateClient: string | null;
  public description: string | null;
  public forcedReason: string | null;
  public forcedAttachments: ForcedAttachmentV1[] | null;

  public constructor() {
    this.resources = [];
    this.contactName = "";
    this.contactEmail = "";
    this.contactPhone = "";
    this.corporateClient = null;
    this.description = null;
    this.forcedReason = null;
    this.forcedAttachments = null;
  }
}

export class ConfirmedResourceV1 {
  public id: number;
  public services: ConfirmedServiceV1[];
  public confirmed: boolean;

  public constructor() {
    this.id = 0;
    this.services = [];
    this.confirmed = false;
  }
}

export class ConfirmedServiceV1 {
  public id: number;
  public confirmed: boolean;

  public constructor() {
    this.id = 0;
    this.confirmed = false;
  }
}

export class ForcedAttachmentV1 {
  public id: string;
  public name: string;

  public constructor() {
    this.id = "";
    this.name = "";
  }
}

export class OrderConfirmModelV1 {
  public resources: ConfirmedResourceV1[];
  public description: string | null;

  public constructor() {
    this.resources = [];
    this.description = null;
  }
}

export enum OrderPaymentModeV1 {
  Online = "online",
  Offline = "offline",
}

export enum OrderPaymentTypeV1 {
  Card = "card",
  Cash = "cash",
}

export class OrderPaymentModelV1 {
  public mode: OrderPaymentModeV1;
  public type: OrderPaymentTypeV1;

  public constructor() {
    this.mode = OrderPaymentModeV1.Offline;
    this.type = OrderPaymentTypeV1.Cash;
  }
}

export class OrderedFlightRecordV1 {
  public id: number;
  public name: string;
  public orderId: number;
  public orderStatus: OrderStatusV1;
  public orderType: OrderTypeV1;
  public orderPaymentInfo: OrderPaymentInfoV1 | null;
  public flightNumber: string;
  public flightType: ServiceTypeV1;
  public flightDate: string;
  public flightZone: string;
  public seats: OrderedSeatRecordV1[];

  public constructor() {
    this.id = 0;
    this.name = "";
    this.orderId = 0;
    this.orderStatus = OrderStatusV1.Created;
    this.orderType = OrderTypeV1.Standard;
    this.orderPaymentInfo = null;
    this.flightNumber = "";
    this.flightType = ServiceTypeV1.Arrival;
    this.flightDate = "";
    this.flightZone = "UTC";
    this.seats = [];
  }
}

export class OrderedSeatRecordV1 {
  public id: number;
  public category: PassengerCategoryV1;
  public familyName: string | null;
  public givenName: string | null;
  public middleName: string | null;
  public birthDate: string | null;
  public ticket: string | null;
  public number: number;
  public expectedFrom: string;
  public expectedTill: string;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.category = PassengerCategoryV1.Adult;
    this.familyName = null;
    this.givenName = null;
    this.middleName = null;
    this.birthDate = null;
    this.ticket = null;
    this.number = 0;
    this.expectedFrom = "";
    this.expectedTill = "";
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}

export class OrderedRoomRecordV1 {
  public id: number;
  public name: string;
  public roomId: number;
  public orderId: number;
  public orderStatus: OrderStatusV1;
  public orderType: OrderTypeV1;
  public flightNumber: string;
  public flightType: ServiceTypeV1;
  public flightDate: string;
  public flightZone: string;
  public expectedFrom: string;
  public expectedTill: string;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.name = "";
    this.roomId = 0;
    this.orderId = 0;
    this.orderStatus = OrderStatusV1.Created;
    this.orderType = OrderTypeV1.Standard;
    this.flightNumber = "";
    this.flightType = ServiceTypeV1.Arrival;
    this.flightDate = "";
    this.flightZone = "UTC";
    this.expectedFrom = "";
    this.expectedTill = "";
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}

export class OrderedRoomPatchModelV1 {
  public id: number;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}

export class OrderedFlightPatchModelV1 {
  public id: number;
  public seats: OrderedSeatPatchModelV1[];

  public constructor() {
    this.id = 0;
    this.seats = [];
  }
}

export class OrderedSeatPatchModelV1 {
  public id: number;
  public familyName: string | null;
  public givenName: string | null;
  public middleName: string | null;
  public birthDate: string | null;
  public ticket: string | null;
  public visitedFrom: string | null;
  public visitedTill: string | null;

  public constructor() {
    this.id = 0;
    this.familyName = null;
    this.givenName = null;
    this.middleName = null;
    this.birthDate = null;
    this.ticket = null;
    this.visitedFrom = null;
    this.visitedTill = null;
  }
}

export class OrderPaymentInfoV1 {
  public type: OrganizationFreeAccessTypeV1 | null;
  public passengerKlass: PassengerKlassV1 | null;
  public airline: string | null;
  public loyaltyProgram: string | null;
  public loyaltyLevel: string | null;
  public accessProgram: string | null;
  public cardNumber: string | null;

  public constructor() {
    this.type = null;
    this.passengerKlass = null;
    this.airline = null;
    this.loyaltyProgram = null;
    this.loyaltyLevel = null;
    this.accessProgram = null;
    this.cardNumber = null;
  }
}

export class OrderBlockModelV1 {
  public resource: ResourceReferenceV1;
  public orderType: OrderTypeV1;
  public flightType: ServiceTypeV1;
  public flightNumber: string;
  public flightDate: string;
  public paymentInfo: OrderPaymentInfoV1 | null;
  public passengerCount: number;
  public passengers: PassengerV1[] | null;
  public contactName: string;
  public contactEmail: string;
  public contactPhone: string;
  public description: string | null;

  public constructor() {
    this.resource = new ResourceReferenceV1();
    this.orderType = OrderTypeV1.Business;
    this.flightType = ServiceTypeV1.All;
    this.flightNumber = "";
    this.flightDate = "";
    this.paymentInfo = null;
    this.passengerCount = 0;
    this.passengers = null;
    this.contactName = "";
    this.contactEmail = "";
    this.contactPhone = "";
    this.description = null;
  }
}
