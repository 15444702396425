import * as R from 'ramda'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AuthContext } from '../auth'
import { Chunk, Lock, LockAction } from '../data'
import { User, UserCreateModel } from '../data/models'
import { NavPathItem } from '../gears'
import { LockAccess, OrganizationAccess, UserAccess } from '../parts/data/access'
import { OrganizationBase, OrganizationType } from '../parts/data/models'
import { Loader, TXT } from '../parts/gears'
import { UserGrid, UserItem } from '../parts/views'
import { PageProps, withPage } from './withPage'

function UsersPage(props: PageProps): ReactElement {
  const { match, onLoaded, onNavigate, onError } = props;

  return (
    <Switch>
      <Route exact={true}
             path={`${match.path}`}
             render={props => <UserListPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/new`}
             render={props => <UserItemPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
      <Route exact={true}
             path={`${match.path}/:id`}
             render={props => <UserItemPage {...props} onLoaded={onLoaded} onNavigate={onNavigate} onError={onError} />} />
    </Switch>
  );
}

interface UserItemPageProps extends RouteComponentProps<{ id: string }> {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function UserItemPage(props: UserItemPageProps): ReactElement {
  const { location, match, onLoaded, onNavigate, onError } = props;

  const auth = useContext(AuthContext);

  const itemId = match.params.id || "";
  const isNew = itemId === "";
  const options = new URLSearchParams(location.search);
  const readonly = !isNew && !options.has("edit");

  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ item, setItem ] = useState(new User());
  const [ lock, setLock ] = useState(new Lock());

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          if (!isNew) {
            const item = await UserAccess.getOne(itemId);
            setItem(item);
            const lock = await LockAccess.lock(itemId, readonly ? LockAction.info : LockAction.lock);
            setLock(lock);
          } else {
            const belongsTo = await OrganizationAccess.getOne(auth.profile.belongsTo);
            setItem(prev => ({
              ...prev,
              belongsTo: belongsTo.type !== OrganizationType.System ? belongsTo : new OrganizationBase(),
            }));
          }
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [ loading, isNew, readonly, auth.profile.belongsTo, itemId, setLoaded, setLoading, setItem, setLock, onError ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([ item ]);
    }
  }, [ loaded, item, onLoaded ]);

  const handleNavigate = useCallback(async (itemId?: string, edit?: boolean, free?: boolean): Promise<void> => {
    if (itemId) {
      try {
        if (free) {
          await LockAccess.lock(itemId, LockAction.free);
        }
        onNavigate(`${itemId}${edit ? "?edit" : ""}`);
        setLoaded(false);
        setLoading(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      onNavigate(``);
    }
  }, [ setLoaded, setLoading, onNavigate ]);

  const handleSubmit = useCallback(async (item: UserCreateModel): Promise<void> => {
    try {
      if (itemId) {
        const updated = await UserAccess.update(itemId, item);
        await handleNavigate(updated.id, false, true);
      } else {
        const created = await UserAccess.create(item);
        await handleNavigate(created.id, false, false);
      }
    } catch (error) {
      onError(error);
    }
  }, [ itemId, handleNavigate, onError ]);

  const handleCancel = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true);
  }, [ itemId, handleNavigate ]);

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await UserAccess.delete(itemId);
      await handleNavigate();
    } catch (error) {
      onError(error);
    }
  }, [ itemId, handleNavigate, onError ]);

  const handleReset = useCallback(async (): Promise<void> => {
    try {
      await UserAccess.reset(itemId);
    } catch (error) {
      onError(error);
    }
  }, [ itemId, onError ]);

  const handleEdit = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, true, false);
  }, [ itemId, handleNavigate ]);

  const handleFree = useCallback(async (): Promise<void> => {
    await handleNavigate(itemId, false, true);
  }, [ itemId, handleNavigate ]);

  return (
    <Loader loading={loading}>
      <UserItem item={item}
                lock={lock}
                readonly={readonly}
                showOrganizations={auth.profile.isSys}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onDelete={itemId !== "" ? handleDelete : undefined}
                onReset={itemId !== "" ? handleReset : undefined}
                onEdit={handleEdit}
                onFree={handleFree} />
    </Loader>
  );
}

interface UserListPageProps extends RouteComponentProps {
  onLoaded: (items: any[]) => void;
  onNavigate: (path: string, state?: any) => void;
  onError: (error: any) => void;
}

function UserListPage(props: UserListPageProps): ReactElement {
  const { onLoaded, onNavigate, onError } = props;

  const auth = useContext(AuthContext);

  const [ loaded, setLoaded ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState<Chunk<User>>(new Chunk());
  const [ sort, setSort ] = useState("");
  const [ skip, setSkip ] = useState(0);
  const [ take, setTake ] = useState(15);
  const [ pattern, setPattern ] = useState("");

  const reload = useCallback((pattern?: string, sort?: string, skip?: number, take?: number): void => {
    if (pattern !== undefined) setPattern(pattern);
    if (sort !== undefined) setSort(sort);
    if (skip !== undefined) setSkip(skip);
    if (take !== undefined) setTake(take);
    setLoading(true);
  }, [ setLoading, setPattern, setSort, setSkip, setTake ]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (loading) {
        try {
          const data = await UserAccess.getAll(pattern, sort, skip, take);
          setData(data);
        } catch (error) {
          onError(error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    })();
  }, [ loading, pattern, sort, skip, take, setData, setLoaded, setLoading, onError ]);

  useEffect(() => {
    if (loaded) {
      onLoaded([]);
    }
  }, [ loaded, onLoaded ]);

  const handleItemCreate = useCallback((): void => {
    onNavigate(`new`)
  }, [ onNavigate ]);

  const handleItemSelect = useCallback((record: User): void => {
    onNavigate(`${record.id}`);
  }, [ onNavigate ]);

  const handleItemSearch = useCallback((newPattern: string): void => {
    if (!R.equals(pattern, newPattern)) {
      reload(newPattern);
    }
  }, [ pattern, reload ]);

  const handlePageChange = useCallback((skip: number, take: number): void => {
    reload(undefined, undefined, skip, take);
  }, [ reload ]);

  const handleSortChange = useCallback((sort: string): void => {
    reload(undefined, sort, undefined, undefined);
  }, [ reload ]);

  return (
    <Loader loading={!loaded && loading}>
      <UserGrid data={data}
                showOrganizations={auth.profile.isSys}
                onItemCreate={handleItemCreate}
                onItemSelect={handleItemSelect}
                onItemSearch={handleItemSearch}
                onPageChange={handlePageChange}
                onSortChange={handleSortChange} />
    </Loader>
  );
}

function pathBuilder(items: User[]): NavPathItem[] {
  const pathItems: NavPathItem[] = [];
  pathItems.push({
    path: "",
    text: TXT("page.users"),
  });
  for (const item of items) {
    pathItems.push({
      path: item.id ? `${item.id}` : "new",
      text: item.id ? `${item.belongsTo.code}/${item.name}` : TXT("page.users.new"),
    });
  }

  return pathItems;
}

export const Users = withPage(UsersPage, pathBuilder);
Users.displayName = "UsersPage";
