import { AirportBaseV1 } from "./Airport";
import { IReference } from "./IReference";

export class TerminalReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class TerminalBaseV1 extends TerminalReferenceV1 {
  public code: string;
  public name: string;
  public airport: AirportBaseV1;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
    this.airport = new AirportBaseV1();
  }
}

export class TerminalV1 extends TerminalBaseV1 {
  public constructor() {
    super();
  }
}
