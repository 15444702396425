import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TextBox } from "../../gears/inputs";
import { Flight } from "../data/models";
import { Button, Div, Popup, TXT } from "../gears";
import { useDelay, useFlightSearch, useFormatCode, useFormatDate, useFormatMessage } from "../hooks";

export function OrderSearch(): ReactElement {
  const history = useHistory();

  const formatCode = useFormatCode();
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate();

  const [ currentPattern, delayedPattern, setPattern ] = useDelay<string | null>(null);
  const [ data, getData ] = useFlightSearch();
  const [ toggled, setToggled ] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      await getData(delayedPattern || undefined, 0, 10);
    })();
  }, [ delayedPattern, getData ]);

  const toggle = useCallback((): void => {
    setToggled(prev => !prev);
    setPattern(null);
  }, [ setToggled, setPattern ]);

  const click = useCallback((event: React.MouseEvent, item: Flight): void => {
    history.push(`/orders/${item.order.id}`);
    toggle();
    event.preventDefault();
  }, [ history, toggle ]);

  return (
    <Popup anchor={<Button icon="search" look="bare" onClick={toggle} />} toggled={toggled} onClose={toggle}>
      <Div layout="grid 12" style={{ width: "480px" }}>
        <Div>
          <TextBox placeholder={TXT("label.search")}
                   autoFocus={true}
                   fill={true}
                   name="search-pattern"
                   value={currentPattern}
                   onChange={setPattern} />
        </Div>
        {data.data.map((item, index) =>
          <Div key={index} style={{ padding: "0 8px"}}>
            <a href={`/orders/${item.order.id}`} onClick={e => click(e, item)}>
              {formatMessage(TXT("label.orderWithId", { id: formatCode(item.order.id, 6) }))}: {item.flightNumber} / {formatDate(item.flightDate, true)}
            </a>
          </Div>
        )}
      </Div>
    </Popup>
  );
}
