import { Apis, Chunk, Client } from '../../../data'
import { RateSpanFilter, RateSpan, RateSpanCreateModel, RateSpanUpdateModel } from '../models'

export class SpanAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: RateSpanFilter): Promise<Chunk<RateSpan>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`spans`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<RateSpan> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`spans/${id}`)
  }

  public static async create(model: RateSpanCreateModel, test?: boolean): Promise<RateSpan> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create<RateSpanCreateModel, RateSpan>(`spans?test=${test || false}`, model)
  }

  public static async update(id: string, model: RateSpanUpdateModel, test?: boolean): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`spans/${id}?test=${test || false}`, model)
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`spans/${id}`)
  }
}
