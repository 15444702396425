import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Pair } from "../../data";
import { PassengerCategoryV1, PassengerKlassV1 } from "../../data/models";
import { PassengerCategory, PassengerKlass } from "../data/models";
import { PairConverter, PairData } from "./types";

export function usePassengerCategories(): [ PairData<PassengerCategory>, PairConverter<PassengerCategory> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<PassengerCategory>>([
    new Pair(PassengerCategory.Adult, intl.formatMessage({ id: "enum.passengerCategory.adult" })),
    new Pair(PassengerCategory.Child, intl.formatMessage({ id: "enum.passengerCategory.child" })),
    new Pair(PassengerCategory.Group, intl.formatMessage({ id: "enum.passengerCategory.group" })),
    new Pair(PassengerCategory.Infant, intl.formatMessage({ id: "enum.passengerCategory.infant" })),
  ]);

  const converter = useCallback((value: PassengerCategory): string => {
    return data.filter(pair => pair.code === value).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function usePassengerKlasses(): [ PairData<PassengerKlass>, PairConverter<PassengerKlass> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<PassengerKlass>>([
    new Pair(PassengerKlass.Business, intl.formatMessage({ id: "enum.passengerKlass.business" })),
    new Pair(PassengerKlass.First, intl.formatMessage({ id: "enum.passengerKlass.first" })),
  ]);

  const converter = useCallback((code: PassengerKlass): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}

export function usePassengerCategoriesV1(): [ PairData<PassengerCategoryV1>, PairConverter<PassengerCategoryV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<PassengerCategoryV1>>([
    new Pair<PassengerCategoryV1>(PassengerCategoryV1.Adult, intl.formatMessage({ id: "enum.passengerCategory.adult" })),
    new Pair<PassengerCategoryV1>(PassengerCategoryV1.Child, intl.formatMessage({ id: "enum.passengerCategory.child" })),
    new Pair<PassengerCategoryV1>(PassengerCategoryV1.Group, intl.formatMessage({ id: "enum.passengerCategory.group" })),
    new Pair<PassengerCategoryV1>(PassengerCategoryV1.Infant, intl.formatMessage({ id: "enum.passengerCategory.infant" })),
  ]);

  const converter = useCallback((value: PassengerCategoryV1): string => {
    switch (value) {
      case PassengerCategoryV1.Adult:
        return intl.formatMessage({ id: "enum.passengerCategory.adult" });
      case PassengerCategoryV1.Child:
        return intl.formatMessage({ id: "enum.passengerCategory.child" });
      case PassengerCategoryV1.Group:
        return intl.formatMessage({ id: "enum.passengerCategory.group" });
      case PassengerCategoryV1.Infant:
        return intl.formatMessage({ id: "enum.passengerCategory.infant" });
      default:
        return "";
    }
  }, [ intl ]);

  return [ data, converter ];
}

export function useFormatPassenger(): PairConverter<{ familyName?: string | null, givenName?: string | null, middleName?: string | null }> {
  return useCallback((value: { familyName?: string | null, givenName?: string | null, middleName?: string | null }): string => {
    const names: string[] = [];
    if (value.familyName) {
      names.push(value.familyName);
    }
    if (value.givenName) {
      names.push(value.givenName);
    }
    if (value.middleName) {
      names.push(value.middleName);
    }

    return names.join(" ");
  }, []);
}

export function usePassengerKlassesV1(): [ PairData<PassengerKlassV1>, PairConverter<PassengerKlassV1> ] {
  const intl = useIntl();

  const [ data, ] = useState<PairData<PassengerKlassV1>>([
    new Pair<PassengerKlassV1>(PassengerKlassV1.Business, intl.formatMessage({ id: "enum.passengerKlass.business" })),
    new Pair<PassengerKlassV1>(PassengerKlassV1.First, intl.formatMessage({ id: "enum.passengerKlass.first" })),
  ]);

  const converter = useCallback((code: PassengerKlassV1): string => {
    return data.filter(pair => pair.code === code).map(pair => pair.name)[0] || "";
  }, [ data ]);

  return [ data, converter ];
}
