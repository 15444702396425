import { UserManager } from "oidc-client";
import { IdentityProfile } from "./IdentityProfile";

export class Identity {
  private static identity: Identity | null = null;

  public static async instance(): Promise<Identity> {
    if (Identity.identity === null) {
      const auth = await fetch("Configuration/auth")
        .then(response => response.json())
        .then(data => data as { authority: string; origin: string; scopes: string[]; });
      Identity.identity = new Identity(auth.authority, auth.origin, auth.scopes.concat([ "ResourceServerApi" ]));
    }

    return Identity.identity;
  }

  private userManager: UserManager;

  private constructor(authority: string, origin: string, scopes: string[]) {
    this.userManager = new UserManager({
      authority: `${authority}`,
      client_id: "ResourceClientApp",
      scope: `${scopes.join(" ")}`,
      response_type: "code",
      redirect_uri: `${window.location.origin ?? origin}/auth-redirect-callback.html`,
      silent_redirect_uri: `${window.location.origin ?? origin}/auth-silent-callback.html`,
      post_logout_redirect_uri: `${window.location.origin ?? origin}`,
      automaticSilentRenew: true,
    });
  }

  public async signIn(): Promise<void> {
    await this.userManager.signinRedirect();
  }

  public async signOut(): Promise<void> {
    await this.userManager.signoutRedirect();
  }

  public async isLoggedIn(): Promise<boolean> {
    const user = await this.userManager.getUser();
    return !!user && user.expired !== true;
  }

  public async getProfile(): Promise<IdentityProfile> {
    const user = await this.userManager.getUser();
    if (user && user.profile) {
      return new IdentityProfile(user.profile);
    } else {
      return new IdentityProfile();
    }
  }

  public async getToken(): Promise<string> {
    const user = await this.userManager.getUser();
    if (user) {
      return user.access_token;
    } else {
      return "";
    }
  }
}
