export function firstOrNull<T>(array: T[]): T | null {
  if (array.length > 0) {
    return array[0];
  } else {
    return null;
  }
}

export function fieldOrNull<T, K extends keyof T>(array: T[], index: number, field: K): T[K] | null {
  if (array.length > index) {
    return array[index][field];
  } else {
    return null;
  }
}

export function fieldOrValue<T, K extends keyof T>(array: T[], index: number, field: K, value: T[K]): T[K] {
  if (array.length > index) {
    return array[index][field];
  } else {
    return value;
  }
}
