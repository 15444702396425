import { EMPTY_ID, OrganizationBase, OrganizationReference } from './index'

export enum OrganizationProgramType {
  Individual = 'Individual',
  Corporate = 'Corporate',
}

export interface OrganizationProgramFilter {
  byOrganizationId?: string
  byProviderId?: string
  byAgencyId?: string
  byType?: OrganizationProgramType
}

export class OrganizationProgramReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class OrganizationProgramName extends OrganizationProgramReference {
  public code: string
  public name: string

  public constructor() {
    super()
    this.code = ''
    this.name = ''
  }
}

export class OrganizationProgramBase extends OrganizationProgramName {
  public type: OrganizationProgramType
  public activated: boolean
  public description: string | null

  public constructor() {
    super()
    this.type = OrganizationProgramType.Individual
    this.activated = true
    this.description = null
  }
}

export class OrganizationProgram<TOrganization extends OrganizationReference = OrganizationReference> extends OrganizationProgramBase {
  public organization: TOrganization

  public constructor(TOrganization: new() => TOrganization) {
    super()
    this.organization = new TOrganization()
  }
}

export class ResolvedOrganizationProgram extends OrganizationProgram<OrganizationBase> {
  public constructor() {
    super(OrganizationBase)
  }
}

export class OrganizationProgramSettings {
  public availablePrimaryPersonCount: number | null
  public availableSecondaryPersonCount: number | null
  public availableServicePersonCount: number | null
  public availableFlightCount: number | null
  public availableFlightCountForDomestic: number | null
  public availableFlightCountForInternational: number | null
  public availableFlightCountPerMonth: number | null
  public availableFlightCountPerMonthForDomestic: number | null
  public availableFlightCountPerMonthForInternational: number | null
  public availablePassengerCountPerFlight: number | null
  public availableGuestCount: number | null
  public availableGuestCountPerMonth: number | null
  public availableGuestCountPerMonthForArrival: number | null
  public availableGuestCountPerMonthForDeparture: number | null
  public availableGuestCountPerFlight: number | null
  public needsSaveUnusedFlightCount: boolean

  public constructor() {
    this.availablePrimaryPersonCount = null
    this.availableSecondaryPersonCount = null
    this.availableServicePersonCount = null
    this.availableFlightCount = null
    this.availableFlightCountForDomestic = null
    this.availableFlightCountForInternational = null
    this.availableFlightCountPerMonth = null
    this.availableFlightCountPerMonthForDomestic = null
    this.availableFlightCountPerMonthForInternational = null
    this.availablePassengerCountPerFlight = null
    this.availableGuestCount = null
    this.availableGuestCountPerMonth = null
    this.availableGuestCountPerMonthForArrival = null
    this.availableGuestCountPerMonthForDeparture = null
    this.availableGuestCountPerFlight = null
    this.needsSaveUnusedFlightCount = false
  }
}

export class OrganizationProgramUpdateModel {
  public name: string
  public activated: boolean
  public description: string | null
  
  public constructor() {
    this.name = ''
    this.activated = false
    this.description = null
  }
}

export class OrganizationProgramCreateModel extends OrganizationProgramUpdateModel {
  public type: OrganizationProgramType
  public organization: OrganizationReference
  public availablePrimaryPersonCount: number | null
  public availableSecondaryPersonCount: number | null
  public availableServicePersonCount: number | null
  public availableFlightCount: number | null
  public availableFlightCountForDomestic: number | null
  public availableFlightCountForInternational: number | null
  public availableFlightCountPerMonth: number | null
  public availableFlightCountPerMonthForDomestic: number | null
  public availableFlightCountPerMonthForInternational: number | null
  public availablePassengerCountPerFlight: number | null
  public availableGuestCount: number | null
  public availableGuestCountPerMonth: number | null
  public availableGuestCountPerMonthForArrival: number | null
  public availableGuestCountPerMonthForDeparture: number | null
  public availableGuestCountPerFlight: number | null
  public needsSaveUnusedFlightCount: boolean
  
  public constructor() {
    super()
    this.type = OrganizationProgramType.Individual
    this.organization = new OrganizationReference()
    this.availablePrimaryPersonCount = null
    this.availableSecondaryPersonCount = null
    this.availableServicePersonCount = null
    this.availableFlightCount = null
    this.availableFlightCountForDomestic = null
    this.availableFlightCountForInternational = null
    this.availableFlightCountPerMonth = null
    this.availableFlightCountPerMonthForDomestic = null
    this.availableFlightCountPerMonthForInternational = null
    this.availablePassengerCountPerFlight = null
    this.availableGuestCount = null
    this.availableGuestCountPerMonth = null
    this.availableGuestCountPerMonthForArrival = null
    this.availableGuestCountPerMonthForDeparture = null
    this.availableGuestCountPerFlight = null
    this.needsSaveUnusedFlightCount = false
  }
}
