import { IReference } from "./IReference";
import { RateCostV1 } from "./Rate";
import { ResourceBaseV1 } from "./Resource";

export enum ServiceFieldTypeV1 {
  Text = "text",
  Date = "date",
  Time = "time",
}

export class ServiceFieldDescriptionV1 {
  public id: string;
  public name: string;
  public type: ServiceFieldTypeV1;
  public isRequired: boolean;

  public constructor() {
    this.id = "";
    this.name = "";
    this.type = ServiceFieldTypeV1.Text;
    this.isRequired = true;
  }
}

export enum ServiceModeV1 {
  Automatic = "automatic",
  Manual = "manual",
  SemiAutomatic = "semiAutomatic",
}

export enum ServiceTypeV1 {
  All = "all",
  Arrival = "arrival",
  Departure = "departure",
  ArrivalAndDeparture = "arrivalAndDeparture",
  DepartureAndArrival = "departureAndArrival",
}

export enum ServiceUnitV1 {
  Whole = "whole",
  Hour = "hour",
  Item = "item",
  ItemPerHour = "itemPerHour",
}

export enum ServiceCategoryV1 {
  Apartments = "apartments",
  ConferenceRoom = "conferenceRoom",
  Luggage = "luggage",
  Photography = "photography",
  Other = "other",
  Transfer = "transfer",
  Resource = "resource",
  LoungeTime = "loungeTime",
  Urgency = "urgency"
}

export class ServiceSettingsV1 {
  public priorityDelta: string | null;

  public constructor() {
    this.priorityDelta = null
  }
}

export class ServiceReferenceV1 implements IReference {
  public id: number;

  public constructor(id?: number) {
    this.id = id || 0;
  }
}

export class ServiceRecord extends ServiceReferenceV1 {
  public name: string;
  public type: ServiceTypeV1;
  public mode: ServiceModeV1;
  public unit: ServiceUnitV1;
  public category: ServiceCategoryV1;
  public resource: string;
  public provider: string;
  public valueFrom: number;
  public valueTill: number;
  public description: string | null;

  public constructor() {
    super();
    this.name = "";
    this.type = ServiceTypeV1.All;
    this.mode = ServiceModeV1.Automatic;
    this.unit = ServiceUnitV1.Whole;
    this.category = ServiceCategoryV1.Other;
    this.resource = "";
    this.provider = "";
    this.valueFrom = 0;
    this.valueTill = 0;
    this.description = null;
  }
}

export class ServiceBaseV1 extends ServiceReferenceV1 {
  public name: string;
  public type: ServiceTypeV1;
  public mode: ServiceModeV1;
  public unit: ServiceUnitV1;
  public category: ServiceCategoryV1;
  public value: number;
  public resource: ResourceBaseV1;

  public constructor() {
    super();
    this.name = "";
    this.type = ServiceTypeV1.All;
    this.mode = ServiceModeV1.Automatic;
    this.unit = ServiceUnitV1.Whole;
    this.category = ServiceCategoryV1.Other;
    this.value = 0;
    this.resource = new ResourceBaseV1();
  }
}

export class ServiceV1 extends ServiceBaseV1 {
  public capacity: number | null;
  public costs: RateCostV1[];
  public fields: ServiceFieldDescriptionV1[] | null;
  public description: string | null;
  public settings: ServiceSettingsV1 | null;

  public constructor() {
    super();
    this.capacity = null;
    this.costs = [];
    this.fields = null;
    this.description = null;
    this.settings = null;
  }
}
