import { Apis, Chunk, Client } from '../../../data'
import { EMPTY_ID, Media, MediaKind, ResolvedService, Service, ServiceCreateModel, ServiceFilter, ServiceReference, ServiceTranslation, ServiceUpdateModel } from '../models'

export class ServiceAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: ServiceFilter): Promise<Chunk<ResolvedService>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`services`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        as: 'Resolved',
        language: 'default',
        ...filter,
      })
  }
  
  public static async getOne(id: string): Promise<ResolvedService> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`services/${id}`, {}, { as: 'Resolved', language: 'default' });
  }

  public static async getOneTranslations(id: string): Promise<ServiceTranslation[]> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`services/${id}/translations`)
  }

  public static async getOneMedias(id: string, byKind?: MediaKind): Promise<Chunk<Media>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`services/${id}/medias`, {}, { byKind })
  }

  public static async create(model: ServiceCreateModel): Promise<Service> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`services`, model)
  }

  public static async update(id: string, model: ServiceUpdateModel): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .update(`services/${id}`, model)
  }

  public static async updateTranslations(id: string, updated: ServiceTranslation[]): Promise<void> {
    const existed = await this.getOneTranslations(id)
    const removed = existed.filter(item => !updated.map(translation => translation.language).some(language => item.language === language))

    for (const translation of updated) {
      await Client
        .instance(await Apis.getGatewayV0())
        .modify(`services/${id}/translations?language=${translation.language}`, translation)
    }

    for (const translation of removed) {
      await Client
        .instance(await Apis.getGatewayV0())
        .delete(`services/${id}/translations?language=${translation.language}`)
    }
  }

  public static async updateMedia(id: string, updated: Media[], kind: MediaKind): Promise<void> {
    const existed = await this.getOneMedias(id, kind).then((chunk) => chunk.data);
    const removed = existed.filter(item => !updated.map(image => image.id).some(id => item.id === id));

    for (const image of updated) {
      if (image.id !== EMPTY_ID) {
        await Client.instance(await Apis.getGatewayV0()).update(`medias/${image.id}`, image);
      } else {
        await Client.instance(await Apis.getGatewayV0()).create("medias/service", { ...image, service: new ServiceReference(id) });
      }
    }

    for (const image of removed) {
      if (image.id !== EMPTY_ID) {
        await Client.instance(await Apis.getGatewayV0()).delete(`medias/${image.id}`);
      }
    }
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`services/${id}`)
  }
}
