import { ConfirmedResource } from './ConfirmedResource'
import { CurrencyBase } from './Currency'
import { FlightSpan } from './FlightSpan'
import { FreeAccessType } from './FreeAccessSettings'
import { OrderedFlight } from './OrderedFlight'
import { OrderedResource } from './OrderedResource'
import { OrganizationContractReference } from './OrganizationContract'
import { OrganizationProgramCardReference } from './OrganizationProgramCard'
import { Passenger, PassengerKlass } from './Passenger'
import { ResourceReference } from './Resource'
import { ServiceType } from './Service'
import { UpdatedResource } from './UpdatedResource'
import { UploadedFile } from './UploadedFile'

export class OrderFilter {
  public flightDate: string | null
  public resourceId: string | null

  public constructor() {
    this.flightDate = null
    this.resourceId = null
  }
}

export enum OrderType {
  Standard = 'Standard',
  Business = 'Business',
  Program = 'Program',
  Block = 'Block',
}

export enum OrderStatus {
  Rated = 'Rated',
  Created = 'Created',
  Confirmed = 'Confirmed',
  Paid = 'Paid',
  Pending = 'Pending',
  Forced = 'Forced',
  Rejected = 'Rejected',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Visited = 'Visited',
}

export enum OrderPaymentMode {
  Online = 'Online',
  Offline = 'Offline',
}

export enum OrderPaymentType {
  Card = 'Card',
  Cash = 'Cash',
  Deposit = 'Deposit',
  Free = 'Free',
}

export class FreeOrderPaymentInfo {
  public type: FreeAccessType | null
  public passengerKlass: PassengerKlass | null
  public airline: string | null
  public loyaltyProgram: string | null
  public loyaltyLevel: string | null
  public accessProgram: string | null
  public cardNumber: string | null

  public constructor() {
    this.type = null
    this.passengerKlass = null
    this.airline = null
    this.loyaltyProgram = null
    this.loyaltyLevel = null
    this.accessProgram = null
    this.cardNumber = null
  }
}


export class OrderReference {
  public id: number

  public constructor() {
    this.id = 0
  }
}

export class OrderBase extends OrderReference {
  public type: OrderType
  public status: OrderStatus
  public paymentMode: OrderPaymentMode | null
  public paymentType: OrderPaymentType | null
  public paymentInfo: FreeOrderPaymentInfo | null
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public description: string | null
  public total: number
  public unpaid: number
  public refund: number
  public penalty: number
  public createdAt: string
  public updatedAt: string
  public confirmedAt: string | null
  public paidAt: string | null
  public cancelledAt: string | null
  public completedAt: string | null

  public constructor() {
    super()
    this.type = OrderType.Standard
    this.status = OrderStatus.Rated
    this.paymentMode = null
    this.paymentType = null
    this.paymentInfo = null
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.description = null
    this.total = 0
    this.unpaid = 0
    this.refund = 0
    this.penalty = 0
    this.createdAt = ''
    this.updatedAt = ''
    this.confirmedAt = null
    this.paidAt = null
    this.cancelledAt = null
    this.completedAt = null
  }
}

export class Order extends OrderBase {
  public contract: OrganizationContractReference
  public card: OrganizationProgramCardReference | null
  public passengers: Passenger[]
  public spans: FlightSpan[]
  public currency: CurrencyBase
  public attachments: UploadedFile[] | null

  public constructor() {
    super()
    this.contract = new OrganizationContractReference()
    this.card = null
    this.passengers = []
    this.spans = []
    this.currency = new CurrencyBase()
    this.attachments = null
  }
}

export class OrderReturnReason {
  public passenger: Passenger
  public description: string
  public attachments: UploadedFile[] | null

  public constructor() {
    this.passenger = new Passenger()
    this.description = ''
    this.attachments = null
  }
}

export class OrderReturn {
  public id: number
  public reasons: OrderReturnReason[]

  public constructor() {
    this.id = 0
    this.reasons = []
  }
}

export class OrderBlockModel {
  public contract: OrganizationContractReference
  public resource: ResourceReference
  public type: OrderType
  public flightType: ServiceType
  public flightNumber: string
  public flightDate: string
  public paymentInfo: FreeOrderPaymentInfo | null
  public passengerCount: number
  public passengers: Passenger[] | null
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public description: string | null

  public constructor() {
    this.contract = new OrganizationContractReference()
    this.type = OrderType.Business
    this.resource = new ResourceReference()
    this.flightType = ServiceType.Any
    this.flightNumber = ''
    this.flightDate = ''
    this.paymentInfo = null
    this.passengerCount = 0
    this.passengers = null
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.description = null
  }
}

export class OrderCreateModel {
  public contract: OrganizationContractReference
  public card: OrganizationProgramCardReference | null
  public type: OrderType
  public passengers: Passenger[]
  public resources: OrderedResource[]
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public description: string | null
  public attachments: UploadedFile[] | null

  public constructor(block?: OrderBlockModel) {
    if (block) {
      this.contract = block.contract
      this.card = null
      this.type = block.type
      this.passengers = block.passengers ? block.passengers : new Array<Passenger>(block.passengerCount)
      const flight = new OrderedFlight()
      flight.city = null
      flight.type = block.flightType
      flight.number = block.flightNumber
      flight.date = block.flightDate
      const resource = new OrderedResource()
      resource.resource = block.resource
      resource.flights = [ flight ]
      this.resources = [ resource ]
      this.contactName = block.contactName
      this.contactEmail = block.contactEmail
      this.contactPhone = block.contactPhone
      this.description = block.description
    } else {
      this.contract = new OrganizationContractReference()
      this.card = null
      this.type = OrderType.Standard
      this.passengers = []
      this.resources = []
      this.contactName = ''
      this.contactEmail = ''
      this.contactPhone = ''
      this.description = null
    }
    this.attachments = null
  }
}

export class OrderChangeModel {
  public passengers: Passenger[]
  public resources: OrderedResource[]
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public description: string | null
  public attachments: UploadedFile[] | null

  public constructor() {
    this.passengers = []
    this.resources = []
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.description = null
    this.attachments = null
  }
}

export class OrderUpdateModel {
  public passengers: Passenger[]
  public resources: UpdatedResource[]
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public description: string | null
  public attachments: UploadedFile[] | null

  public constructor() {
    this.passengers = []
    this.resources = []
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.description = null
    this.attachments = null
  }
}

export class OrderConfirmModel {
  public resources: ConfirmedResource[]

  public constructor() {
    this.resources = []
  }
}

export class OrderPaymentModel {
  public mode: OrderPaymentMode
  public type: OrderPaymentType
  public info: FreeOrderPaymentInfo | null
  public voucherSendRequired: boolean | null
  public voucherRateRequired: boolean | null

  public constructor() {
    this.mode = OrderPaymentMode.Offline
    this.type = OrderPaymentType.Card
    this.info = null
    this.voucherSendRequired = null
    this.voucherRateRequired = null
  }
}
