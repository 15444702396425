import * as KendoAnimation from "@progress/kendo-react-animation";
import * as KendoNotification from "@progress/kendo-react-notification";
import React, { PureComponent, ReactNode } from "react";
import { getIntlMessage, Intl, IntlMessage } from "./Intl";

interface ToasterProps {
  items: ToasterItem[];
  onClose?: (index: number) => void;
}

interface ToasterProps {
  children?: null;
}

interface ToasterState {
}

export interface ToasterItem {
  text: IntlMessage;
  type?: "none" | "success" | "error" | "warning" | "info";
  icon?: boolean;
}

export class Toaster extends PureComponent<ToasterProps, ToasterState> {
  public constructor(props: ToasterProps) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { items, onClose } = this.props;

    return (
      <Intl render={intl =>
        <KendoNotification.NotificationGroup style={{ top: 0, left: "50%", transform: "translateX(-50%)", zIndex: 2 }}>
          <KendoAnimation.Fade enter={true} exit={true}>
            {items.map((item, index) => (
              <KendoNotification.Notification key={index}
                                              type={{ style: item.type, icon: item.icon }}
                                              closable={!!onClose}
                                              onClose={onClose ? () => onClose(index) : undefined}>
                <span>{getIntlMessage(intl, item.text)}</span>

              </KendoNotification.Notification>
            ))}
          </KendoAnimation.Fade>
        </KendoNotification.NotificationGroup>
      } />
    );
  }
}
