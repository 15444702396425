import { EMPTY_ID, OrganizationContractReference, Passenger } from './index'
import { OrganizationProgramReference } from './OrganizationProgram'

export class OrganizationProgramCardReference {
  public id: string

  public constructor() {
    this.id = EMPTY_ID
  }
}

export class OrganizationProgramCardBase extends OrganizationProgramCardReference {
  public number: string
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public validFrom: string
  public validTill: string
  public description: string | null
  public people: Passenger[] | null

  public constructor() {
    super()
    this.number = ''
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.validFrom = ''
    this.validTill = ''
    this.description = null
    this.people = null
  }
}

export class OrganizationProgramCard<TProgram extends OrganizationProgramReference = OrganizationProgramReference, TContract extends OrganizationContractReference = OrganizationContractReference> extends OrganizationProgramCardBase {
  public program: TProgram
  public contract: TContract | null

  public constructor(TProgram: new() => TProgram) {
    super()
    this.program = new TProgram()
    this.contract = null
  }
}

export class OrganizationProgramCardStatistics {
  public availableFlightCount: number | null;
  public committedFlightCount: number;
  public availableFlightCountForDomestic: number | null;
  public committedFlightCountForDomestic: number;
  public availableFlightCountForInternational: number | null;
  public committedFlightCountForInternational: number;
  public availableFlightCountInCurrentMonth: number | null;
  public committedFlightCountInCurrentMonth: number;
  public availableFlightCountInCurrentMonthForDomestic: number | null;
  public committedFlightCountInCurrentMonthForDomestic: number;
  public availableFlightCountInCurrentMonthForInternational: number | null;
  public committedFlightCountInCurrentMonthForInternational: number;
  public availableGuestCount: number | null;
  public committedGuestCount: number;
  public availableGuestCountInCurrentMonth: number | null;
  public committedGuestCountInCurrentMonth: number;
  public availableGuestCountInCurrentMonthForArrival: number | null;
  public committedGuestCountInCurrentMonthForArrival: number;
  public availableGuestCountInCurrentMonthForDeparture: number | null;
  public committedGuestCountInCurrentMonthForDeparture: number;

  constructor() {
    this.availableFlightCount = null;
    this.committedFlightCount = 0;
    this.availableFlightCountForDomestic = null;
    this.committedFlightCountForDomestic = 0;
    this.availableFlightCountForInternational = null;
    this.committedFlightCountForInternational = 0;
    this.availableFlightCountInCurrentMonth = null;
    this.committedFlightCountInCurrentMonth = 0;
    this.availableFlightCountInCurrentMonthForDomestic = null;
    this.committedFlightCountInCurrentMonthForDomestic = 0;
    this.availableFlightCountInCurrentMonthForInternational = null;
    this.committedFlightCountInCurrentMonthForInternational = 0;
    this.availableGuestCount = null;
    this.committedGuestCount = 0;
    this.availableGuestCountInCurrentMonth = null;
    this.committedGuestCountInCurrentMonth = 0;
    this.availableGuestCountInCurrentMonthForArrival = null;
    this.committedGuestCountInCurrentMonthForArrival = 0;
    this.availableGuestCountInCurrentMonthForDeparture = null;
    this.committedGuestCountInCurrentMonthForDeparture = 0;
  }
}

export class OrganizationProgramCardUpdateModel {
  public contactName: string
  public contactEmail: string
  public contactPhone: string
  public validFrom: string
  public validTill: string
  public description: string | null
  public people: Passenger[] | null

  public constructor() {
    this.contactName = ''
    this.contactEmail = ''
    this.contactPhone = ''
    this.validFrom = ''
    this.validTill = ''
    this.description = null
    this.people = null
  }
}

export class OrganizationProgramCardCreateModel extends OrganizationProgramCardUpdateModel {
  public number: string
  public program: OrganizationProgramReference
  public contract: OrganizationContractReference | null

  public constructor() {
    super()
    this.number = ''
    this.program = new OrganizationProgramReference()
    this.contract = null
  }
}
