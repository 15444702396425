import { EMPTY_ID } from "./index";

export enum EventType {
  Order = "Order",
  Time = "Time",
}

export enum EventKind {
  OrderCreated = "OrderCreated",
  OrderConfirmed = "OrderConfirmed",
  OrderRejected = "OrderRejected",
  OrderPending = "OrderPending",
  OrderPaid = "OrderPaid",
  OrderCancelled = "OrderCancelled",
  OrderCompleted = "OrderCompleted",
  OrderChanged = "OrderChanged",
  OrderForced = "OrderForced",
  OrderRefund = "OrderRefund",
  TimeExpired = "TimeExpired",
}

export enum EventPriority {
  Low = "Low",
  Normal = "Normal",
  High = "High",
}

export class EventReference {
  public id: string;

  public constructor(id?: string) {
    this.id = id || EMPTY_ID;
  }
}

export class EventBase extends EventReference {
  public type: EventType;
  public kind: EventKind;
  public priority: EventPriority;
  public occuredAt: string;

  public constructor() {
    super();
    this.type = EventType.Order;
    this.kind = EventKind.OrderCreated;
    this.priority = EventPriority.Normal;
    this.occuredAt = "";
  }
}

export class Event<TDetails extends object = object> extends EventBase {
  public details: TDetails;
  public description: string | null;

  public constructor(TDetails: new() => TDetails) {
    super();
    this.details = new TDetails();
    this.description = null;
  }
}

export class EventDeleteModel {
  public type: EventType;
  public ids: string[] | null;

  public constructor() {
    this.type = EventType.Order;
    this.ids = null;
  }
}
