import { classNames } from "@progress/kendo-react-common";
import * as KendoTooltip from "@progress/kendo-react-tooltip";
import React, { FormEvent, PureComponent, ReactNode, useCallback, useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { Prompt } from "react-router";
import { Apis, Client, Lock, LockAction } from "../data";
import { IReference } from "../data/models";
import { Button } from "./Button";
import { Confirm } from "./Confirm";
import { Div } from "./Div";
import { getIntlMessage, ID, Intl, IntlMessage } from "./Intl";
import { FormContextValue, FormContext } from "./FormContext";
import { LoadingIndicator } from "./LoadingIndicator";

interface FormProps {
  label?: IntlMessage;
  labelSubmit?: IntlMessage;
  labelCancel?: IntlMessage;
  labelDelete?: IntlMessage;
  labelEdit?: IntlMessage;
  labelFree?: IntlMessage;
  confirm?: string;
  prompt?: string;
  lock?: Lock;
  loaded: boolean;
  readonly: boolean;
  noPadding?: boolean;
  markUnchangedOnSubmit?: boolean;
  markUnchangedOnCancel?: boolean;
  allowSubmit?: boolean;
  allowCancel?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  allowFree?: boolean;
  onSubmit?: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onEdit?: () => Promise<void>;
  onFree?: () => Promise<void>;
}

interface FormProps {
  children?: ReactNode;
}

interface FormState {
  context: FormContextValue;
  isValid: boolean;
  changed: boolean;
  isDeleteConfirmationVisible: boolean;
}

export class Form extends PureComponent<FormProps, FormState> {
  private changes: Map<string, { isValid: boolean; changed: boolean; }>;

  public constructor(props: FormProps) {
    super(props);

    this.state = {
      context: {
        loaded: false,
        readonly: props.readonly || (props.lock ? !props.lock.canEdit : false),
        submitting: false,
        cancelling: false,
        deleting: false,
        onFieldChange: this.handleFieldChange,
        onFieldRemove: this.handleFieldRemove,
      },
      isValid: true,
      changed: false,
      isDeleteConfirmationVisible: false,
    };

    this.changes = new Map<string, { isValid: boolean; changed: boolean; }>();
  }

  public async componentDidUpdate(prevProps: Readonly<FormProps>, prevState: Readonly<FormState>, snapshot?: any): Promise<void> {
    if (this.props.loaded !== prevProps.loaded) {
      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          loaded: this.props.loaded,
        },
      }));
    }

    if (this.props.readonly !== prevProps.readonly || this.props.lock !== prevProps.lock) {
      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          readonly: this.props.readonly || (this.props.lock ? !this.props.lock.canEdit : false),
        },
      }));
    }

    if (this.state.context.submitting && !prevState.context.submitting) {
      if (this.props.onSubmit && this.check()) {
        await this.props.onSubmit();
        if (this.props.markUnchangedOnSubmit === undefined || this.props.markUnchangedOnSubmit) {
          this.setState({ changed: false });
        }
      }

      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          submitting: false,
        },
      }));
    }

    if (this.state.context.cancelling && !prevState.context.cancelling) {
      if (this.props.onCancel) {
        await this.props.onCancel();
        if (this.props.markUnchangedOnCancel === undefined || this.props.markUnchangedOnCancel) {
          this.setState({ changed: false });
        }
      }

      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          cancelling: false,
        },
      }));
    }

    if (this.state.context.deleting && !prevState.context.deleting) {
      if (this.props.onDelete) {
        await this.props.onDelete();
      }

      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          deleting: false,
        },
      }));
    }
  }

  public render(): ReactNode {
    const { label, labelSubmit, labelCancel, labelDelete, labelEdit, labelFree, confirm, lock, loaded, noPadding, children, allowSubmit, allowCancel, allowDelete, allowEdit, allowFree, onSubmit, onCancel, onDelete, onEdit, onFree } = this.props;
    const { context, isDeleteConfirmationVisible } = this.state;

    if (!loaded) {
      return (
        <LoadingIndicator />
      );
    }

    return (
      <Intl render={intl =>
        <KendoTooltip.Tooltip position="bottom" filter={this.filterTooltip}>
          <Div className={classNames("g-form", noPadding ? "no-padding" : undefined )}>
            {label &&
            <Div className="g-form-header">
              <Div className="g-form-label">
                <span>{getIntlMessage(intl, label)}</span>
              </Div>
            </Div>
            }

            <form className="k-form" onSubmit={this.handleSubmit} onReset={this.handleCancel}>
              <Div className="g-form-fields">
                <FormContext.Provider value={context}>
                  {children}
                </FormContext.Provider>
              </Div>
              {(onFree || onEdit || onDelete || onCancel || onSubmit) &&
              <Div layout="flex vertical-center" className="g-form-actions">
                <Div layout="fill">
                  {lock && lock.lockedBy && lock.lockedAt &&
                  <small>
                    <span>{`${lock.lockedBy} — ${intl.formatDate(lock.lockedAt)} ${intl.formatTime(lock.lockedAt)}`}</span>
                  </small>
                  }
                </Div>
                {context.readonly
                  ? <Div layout="fit">
                    {onFree && (!lock || lock.canFree) &&
                    <Button className="g-form-action-free"
                            disabled={!(allowFree !== undefined ? allowFree : true)}
                            primary={true}
                            look="outline"
                            text={labelFree || ID("action.free")}
                            type="button"
                            onClick={onFree} />
                    }
                    {onEdit && (!lock || lock.canEdit) &&
                    <Button className="g-from-action-edit"
                            disabled={!(allowEdit !== undefined ? allowEdit : true)}
                            primary={true}
                            text={labelEdit || ID("action.edit")}
                            type="button"
                            onClick={onEdit} />
                    }
                  </Div>
                  : <Div layout="fit">
                    {onDelete && <Button className="g-form-action-delete"
                                         disabled={context.submitting || !(allowDelete !== undefined ? allowDelete : true)}
                                         primary={true}
                                         look="bare"
                                         type="button"
                                         text={labelDelete || ID("action.delete")}
                                         onClick={this.toggleDeleteConfirmationVisibility} />
                    }
                    {onCancel && (!lock || lock.canEdit) &&
                    <Button className="g-form-action-cancel"
                            disabled={context.submitting || !(allowCancel !== undefined ? allowCancel : true)}
                            primary={true}
                            look="outline"
                            text={labelCancel || ID("action.cancel")}
                            type="reset" />
                    }
                    {onSubmit && (!lock || lock.canEdit) &&
                    <Button className="g-form-action-submit"
                            disabled={context.submitting || !(allowSubmit !== undefined ? allowSubmit : true)}
                            primary={true}
                            text={labelSubmit || ID("action.submit")}
                            type="submit" />
                    }
                  </Div>
                }
              </Div>
              }
              {!context.readonly && lock &&
              <Prompt when={!context.readonly && !context.submitting && !context.cancelling && !context.deleting}
                      message={() => this.handlePrompt(intl)} />
              }
              {isDeleteConfirmationVisible &&
              <Confirm title={ID("prompt.delete.title")}
                       message={confirm ? confirm : ID("prompt.delete.message")}
                       onConfirm={this.handleDelete} />
              }
            </form>
          </Div>
        </KendoTooltip.Tooltip>
      } />
    );
  }

  private check(): boolean {
    let isValid = true;
    let changed = false;
    this.changes.forEach(value => {
      isValid = isValid && value.isValid;
      changed = changed || value.changed;
    });

    this.setState({ isValid, changed });

    return isValid;
  }

  private readonly filterTooltip = (target: HTMLElement): boolean => {
    return target.tagName === "SPAN" && target.classList.contains("g-tooltip");
  };

  private readonly handleFieldChange = (name: string, isValid?: boolean, changed?: boolean): void => {
    const change = this.changes.get(name) || { isValid: true, changed: false };
    this.changes.set(name, {
      isValid: isValid !== undefined ? isValid : change.isValid,
      changed: changed !== undefined ? changed : change.changed,
    });
    this.check();
  };

  private readonly handleFieldRemove = (name: string, exact: boolean): void => {
    if (exact) {
      if (this.changes.has(name)) {
        this.changes.delete(name);
      }
    } else {
      this.changes.forEach((value, key) => {
        if (key.startsWith(name)) {
          this.changes.delete(key);
        }
      });
    }
    this.check();
  };

  private readonly handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    if (!(this.props.allowSubmit !== undefined ? this.props.allowSubmit : true))
      return;
    this.setState(prev => ({
      ...prev,
      context: {
        ...prev.context,
        submitting: true,
      },
    }));

    event.preventDefault();
  };

  private readonly handleCancel = (event: FormEvent<HTMLFormElement>): void => {
    if (!(this.props.allowCancel !== undefined ? this.props.allowCancel : true))
      return;
    this.setState(prev => ({
      ...prev,
      context: {
        ...prev.context,
        cancelling: true,
      },
    }));

    event.preventDefault();
  };

  private readonly handleDelete = (confirmed: boolean): void => {
    if (!(this.props.allowDelete !== undefined ? this.props.allowDelete : true))
      return;
    this.toggleDeleteConfirmationVisibility();
    if (confirmed && this.props.onDelete) {
      this.setState(prev => ({
        ...prev,
        context: {
          ...prev.context,
          deleting: true,
        },
      }));
    }
  };

  private readonly toggleDeleteConfirmationVisibility = (): void => {
    this.setState(prev => ({
      ...prev,
      isDeleteConfirmationVisible: !prev.isDeleteConfirmationVisible,
    }));
  };

  private readonly handlePrompt = (intl: IntlShape): string => {
    const { lock, prompt } = this.props;

    if (lock) {
      Apis.getDataApi()
        .then(url => Client.instance(url))
        .then(client => client.get(lock.uri, {}, { action: LockAction.free }))
        .catch(() => undefined);
    }

    return prompt ? prompt : getIntlMessage(intl, ID("prompt.leave"));
  };
}

export function useForm<TItem extends IReference>(
  item: TItem,
  readonly: boolean,
  load: () => Promise<void>,
  lock: (value: TItem, action: LockAction) => Promise<boolean>,
  commit?: (value: TItem) => Promise<boolean>,
  remove?: (value: TItem) => Promise<boolean>,
  onLoaded?: () => void,
  onCommit?: (item: TItem | null) => void,
  onEdit?: (item: TItem) => void,
  onFree?: (item: TItem) => void
): [
  boolean,
  () => Promise<void>,
  () => Promise<void>,
  () => Promise<void>,
  () => Promise<void>,
  () => Promise<void>,
] {
  const [ loaded, setLoaded ] = useState(false);
  const [ committed, setCommitted ] = useState(false);
  const [ cancelled, setCancelled ] = useState(false);

  useEffect(() => {
    setLoaded(false);
    load()
      .finally(() => {
        setLoaded(true);
      });
  }, [ load ]);

  useEffect(() => {
    if (loaded) {
      lock(item, !readonly ? LockAction.lock : LockAction.info)
        .finally(() => {
          if (onLoaded) onLoaded();
        });
    }
  }, [ item, loaded, onLoaded, readonly, lock ]);

  useEffect(() => {
    if (committed) {
      setCommitted(false);
      if (onCommit) onCommit(item);
    }
  }, [ committed, item, onCommit, setCommitted ]);

  useEffect(() => {
    if (cancelled) {
      setCancelled(false);
      if (onCommit) onCommit(item.id ? item : null);
    }
  }, [ cancelled, item, onCommit, setCancelled ]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoaded(false);
    if (commit && await commit(item)) {
      await lock(item, LockAction.free);
      setCommitted(true);
    }
    setLoaded(true);
  }, [ commit, item, setCommitted, setLoaded, lock ]);

  const handleCancel = useCallback(async (): Promise<void> => {
    setLoaded(false);
    await lock(item, LockAction.free);
    setCancelled(true);
    setLoaded(true);
  }, [ item, setCancelled, setLoaded, lock ]);

  const handleDelete = useCallback(async (): Promise<void> => {
    if (remove && await remove(item)) {
      await lock(item, LockAction.free);
      if (onCommit) onCommit(null);
    }
  }, [ item, onCommit, remove, lock ]);

  const handleEdit = useCallback(async (): Promise<void> => {
    if (await lock(item, LockAction.lock)) {
      if (onEdit) onEdit(item);
    }
  }, [ item, onEdit, lock ]);

  const handleFree = useCallback(async (): Promise<void> => {
    if (await lock(item, LockAction.free)) {
      if (onFree) onFree(item);
    }
  }, [ item, onFree, lock ]);

  return [ loaded, handleSubmit, handleCancel, handleDelete, handleEdit, handleFree ]
}
