import { CountryReference, EMPTY_ID } from "./index";

export interface CityQuery {
  byCountryId?: string
  byNotCountryId?: string
}

export class CityReference {
  public id: string;

  public constructor() {
    this.id = EMPTY_ID;
  }
}

export class CityBase extends CityReference {
  public code: string;
  public name: string;

  public constructor() {
    super();
    this.code = "";
    this.name = "";
  }
}

export class City<TCountry extends CountryReference = CountryReference> extends CityBase {
  public country: TCountry;

  public constructor(TCountry: new() => TCountry) {
    super();
    this.country = new TCountry();
  }
}
