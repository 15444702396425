import { LocationDescriptor, LocationDescriptorObject } from "history";
import React, { MouseEventHandler, PropsWithChildren, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

interface RelLinkProps {
  className?: string;
  to: LocationDescriptor;
  onClick?: MouseEventHandler | undefined;
}

function isLocationDescriptorObject(location: LocationDescriptor): location is LocationDescriptorObject {
  return typeof location !== "string";
}

export const RelLink = withRouter((props: Readonly<PropsWithChildren<RelLinkProps> & RouteComponentProps>) => {
  const { className, to, location, children, onClick } = props;

  const base = location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;

  let destination: LocationDescriptor = to;
  if (isLocationDescriptorObject(to)) {
    const pathname = to.pathname ? `${base}/${to.pathname}` : `${location.pathname}`;
    const state = { ...(location.state as any), ...(to.state as any) };
    destination = { ...to, pathname, state };
  } else {
    destination = `${base}/${to}`;
  }

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (onClick) {
      onClick(e);
    }

    e.preventDefault();
  }, [ onClick ]);

  return <Link to={destination} className={className} onClick={onClick ? handleClick : undefined}>{children}</Link>
});
