import * as KendoDateTimes from "@progress/kendo-react-dateinputs";
import * as KendoInputs from "@progress/kendo-react-inputs";
import * as KendoIntl from "@telerik/kendo-intl";
import { DateTime } from "luxon";
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface DateBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  time?: boolean;
  zone?: string;
  name: string;
  min?: string;
  max?: string;
  value: string | null;
  error?: IntlMessage;
  validators?: ((value: string | null) => IntlMessage | undefined)[],
  onChange?: (value: string | null) => void;
}

interface DateBoxProps {
  children?: null;
}

interface DateBoxState {
  date: Date | null;
}

export class DateBox extends PureComponent<DateBoxProps, DateBoxState> {
  private readonly targetRef = React.createRef<KendoDateTimes.DateInput>();

  public constructor(props: DateBoxProps) {
    super(props);

    this.state = {
      date: null,
    };
  }

  public componentDidMount(): void {
    if (this.targetRef.current && this.targetRef.current.element) {
      this.targetRef.current.element.addEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public componentWillUnmount(): void {
    if (this.targetRef.current && this.targetRef.current.element) {
      this.targetRef.current.element.removeEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public componentDidUpdate(prevProps: Readonly<DateBoxProps>, prevState: Readonly<DateBoxState>, snapshot?: any): void {
    const { value, zone } = this.props;
    if (prevProps.value !== value || prevProps.zone !== zone) {
      this.setState({ date: this.toString(value) });
    }
  }

  public render(): ReactNode {
    const { hint, label, disabled, readonly, fill, time, zone, name, min, max, value, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;
    const formatPlaceholder = {
      year: 'ГГГГ',
      month: 'ММ',
      day: 'ДД',
      hour: 'ЧЧ',
      minute: 'мм',
      second: 'сс',
    };

    const format = `dd.MM.yyyy${time ? "  /  HH:mm" : ""}`;
    const convertedDate = this.toString(value);
    const convertedMin = min ? this.toString(min) : null;
    const convertedMax = max ? this.toString(max) : null;

    return (
      <Intl render={intl =>
        <Field hint={hint ? hint : zone === "UTC" ? undefined : zone || "Local"}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={true}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) => {
                 if (readonly || fieldReadonly) {
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          value={convertedDate ? KendoIntl.formatDate(convertedDate, format, intl.locale) : ""} />
                     </span>
                   );
                 } else {
                   return (
                     <span className={className}>
                       <KendoDateTimes.DateInput ref={this.targetRef}
                                                 label={label && getIntlMessage(intl, label)}
                                                 format={format}
                                                 formatPlaceholder={formatPlaceholder}
                                                 disabled={disabled || fieldDisabled || !onFieldChange}
                                                 valid={true}
                                                 value={convertedDate}
                                                 min={convertedMin || undefined}
                                                 max={convertedMax || undefined}
                                                 onChange={e => onFieldChange(this.fromString(this.constrained(e.target.value, convertedMin, convertedMax)))} />
                     </span>
                   );
                 }
               }}
               onChange={onChange} />
      } />
    );
  }

  private readonly fromString = (value: Date | null): string | null => {
    if (value) {
      const YY = value.getFullYear().toString().padStart(4, "0");
      const MM = (value.getMonth() + 1).toString().padStart(2, "0");
      const DD = value.getDate().toString().padStart(2, "0");
      const hh = value.getHours().toString().padStart(2, "0");
      const mm = value.getMinutes().toString().padStart(2, "0");
      const ss = value.getSeconds().toString().padStart(2, "0");
      const ms = value.getMilliseconds().toString().padStart(3, "0");
      const dateTime = DateTime.fromISO(`${YY}-${MM}-${DD}T${hh}:${mm}:${ss}.${ms}`, { zone: this.props.zone });
      return dateTime.toUTC().toISO();
    } else {
      return null;
    }
  };

  private readonly toString = (value: string | null): Date | null => {
    if (value) {
      let dateTime = DateTime.fromISO(value);
      if (this.props.zone) {
        dateTime = dateTime.setZone(this.props.zone);
      }
      const YY = dateTime.year.toString().padStart(4, "0");
      const MM = dateTime.month.toString().padStart(2, "0");
      const DD = dateTime.day.toString().padStart(2, "0");
      const hh = dateTime.hour.toString().padStart(2, "0");
      const mm = dateTime.minute.toString().padStart(2, "0");
      const ss = dateTime.second.toString().padStart(2, "0");
      const ms = dateTime.millisecond.toString().padStart(3, "0");
      return new Date(`${YY}-${MM}-${DD}T${hh}:${mm}:${ss}.${ms}`);
    } else {
      return null;
    }
  };

  private readonly constrained = (value: Date | null, min: Date | null, max: Date | null): Date | null => {
    if (value === null) return null;

    if (min !== null && value < min) return min;

    if (max !== null && value > max) return max;

    return value;
  };

  private readonly cancelWheelEventBubbling = (event: WheelEvent): void => {
    event.cancelBubble = true;
  };
}
