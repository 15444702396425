import * as KendoDropdowns from "@progress/kendo-react-dropdowns";
import * as KendoInputs from "@progress/kendo-react-inputs";
import React, { PureComponent, ReactNode } from "react";
import { Chunk, isChunk } from "../../data";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface TagsBoxProps<TValue> {
  hint?: IntlMessage;
  label?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  pageSize?: number;
  name: string;
  data: Chunk<TValue> | TValue[];
  value: TValue[];
  valueKey?: string;
  valueLabel?: string | ((value: TValue) => string);
  allowCustom?: boolean;
  autoClose?: boolean;
  error?: IntlMessage;
  validators?: ((value: TValue[]) => IntlMessage | undefined)[],
  onChange?: (value: TValue[]) => void;
  onFetch?: (pattern?: string, skip?: number, take?: number) => void;
}

interface TagsBoxProps<TValue> {
  children?: null;
}

interface TagsBoxState {
}

export class TagsBox<TValue> extends PureComponent<TagsBoxProps<TValue>, TagsBoxState> {
  public constructor(props: TagsBoxProps<TValue>) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { hint, label, placeholder, disabled, readonly, fill, pageSize, name, data, value, valueKey, valueLabel, allowCustom, autoClose, error, validators, onChange, onFetch } = this.props;

    const className = fill ? "fill" : undefined;

    const handleFilterChange = onFetch
      ? (event: KendoDropdowns.MultiSelectFilterChangeEvent) => onFetch(event.filter.value, 0, pageSize)
      : undefined;

    const handlePageChange = onFetch
      ? (event: KendoDropdowns.MultiSelectPageChangeEvent) => onFetch(undefined, event.page.skip, event.page.take)
      : undefined;

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={false}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange, onFieldEnter, onFieldLeave) => {
                 if (readonly || fieldReadonly) {
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          value={value.map(item => (typeof item === "object" && valueLabel ? typeof valueLabel === "string" ? (item as any)[valueLabel] : valueLabel(item) : item)).join(", ")} />
                     </span>
                   );
                 } else {
                   return (
                     <span className={className}>
                       <KendoDropdowns.MultiSelect label={label && getIntlMessage(intl, label)}
                                                   placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                                   disabled={disabled || fieldDisabled || !onFieldChange}
                                                   valid={true}
                                                   data={isChunk(data) ? data.data : data}
                                                   value={value}
                                                   dataItemKey={valueKey}
                                                   textField={valueLabel && typeof valueLabel === "string" ? valueLabel : undefined}
                                                   itemRender={valueLabel && typeof valueLabel === "function" ? (li, props) => React.cloneElement(li, li.props, valueLabel(props.dataItem)) : undefined}
                                                   filterable={!!handleFilterChange}
                                                   virtual={handlePageChange && {
                                                     pageSize: pageSize ? pageSize : isChunk(data) ? data.total : data.length,
                                                     skip: isChunk(data) ? data.skip : 0,
                                                     total: isChunk(data) ? data.total : data.length,
                                                   }}
                                                   allowCustom={allowCustom}
                                                   autoClose={autoClose !== undefined ? autoClose : false}
                                                   onChange={e => onFieldChange(e.target.value)}
                                                   onFocus={onFieldEnter}
                                                   onBlur={onFieldLeave}
                                                   onFilterChange={handleFilterChange}
                                                   onPageChange={handlePageChange} />
                     </span>
                   );
                 }
               }}
               onChange={onChange} />
      } />
    );
  }
}
