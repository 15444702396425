import { ServiceMode } from './Service'

export class ResourceDefaults {
  public mode: ServiceMode
  public infantAge: number
  public childAge: number
  public groupAge: number
  public groupSize: number
  public maxStayDuration: string
  public arrivalEnterDelta: string
  public arrivalLeaveDelta: string
  public departureEnterDelta: string
  public departureLeaveDelta: string
  public forbidArrivalDelta: string
  public forbidDepartureDelta: string
  public priorityDelta: string
  public passengerEMailRequired: boolean
  public passengerPhoneRequired: boolean
  public confirmAndPayFlow: boolean
  public maxComboDelta: string | null
  
  public constructor() {
    this.mode = ServiceMode.Automatic
    this.infantAge = 2
    this.childAge = 12
    this.groupAge = 12
    this.groupSize = 5
    this.maxStayDuration = '06:00'
    this.arrivalEnterDelta = '00:30'
    this.arrivalLeaveDelta = '03:30'
    this.departureEnterDelta = '03:30'
    this.departureLeaveDelta = '00:30'
    this.forbidArrivalDelta = '02:00'
    this.forbidDepartureDelta = '05:00'
    this.priorityDelta = '01:00'
    this.passengerEMailRequired = false
    this.passengerPhoneRequired = false
    this.confirmAndPayFlow = false
    this.maxComboDelta = null
  }
}
