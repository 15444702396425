import { Apis, Chunk, Client } from '../../../data'
import { DepositOperation, OrganizationContract, OrganizationContractCreateModel, OrganizationContractFilter, OrganizationContractUpdateModel, OrganizationProgramCard, ResolvedOrganizationContract, Resource, ServiceKind } from '../models'

export class OrganizationContractAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: OrganizationContractFilter): Promise<Chunk<ResolvedOrganizationContract>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<ResolvedOrganizationContract> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}`)
  }

  public static async getOneParents(id: string): Promise<Chunk<ResolvedOrganizationContract>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/parents`)
  }

  public static async getOneBalance(id: string): Promise<number> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/balance`)
  }

  public static async getOneOverdraft(id: string): Promise<number> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/overdraft`)
  }

  public static async getOneAutoreplenish(id: string): Promise<boolean> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/autoreplenish`)
  }

  public static async getOneOperations(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<DepositOperation>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/operations`, {}, {
        pattern,
        orderBy,
        skip,
        take,
      })
  }

  public static async getOneCards(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number, isAuthorized?: boolean, isIndividual?: boolean): Promise<Chunk<OrganizationProgramCard>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/cards`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        isAuthorized,
        isIndividual,
      })
  }

  public static async getOneResources(id: string, pattern?: string, orderBy?: string, skip?: number, take?: number, byKind?: ServiceKind | ServiceKind[], byAirportId?: string): Promise<Chunk<Resource>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`contracts/${id}/resources`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        byKind,
        byAirportId,
      })
  }

  public static async create(item: OrganizationContractCreateModel): Promise<OrganizationContract> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .create(`contracts`, item)
  }

  public static async update(id: string, item: OrganizationContractUpdateModel): Promise<OrganizationContract> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .update(`contracts/${id}`, item)
  }

  public static async delete(id: string): Promise<void> {
    await Client
      .instance(await Apis.getGatewayV0())
      .delete(`contracts/${id}`)
  }

  public static async updateBalance(id: string, value: number): Promise<void> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`contracts/${id}/balance/replenish`, { value })
  }

  public static async updateOverdraft(id: string, value: number): Promise<void> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`contracts/${id}/overdraft`, { value })
  }

  public static async updateAutoreplenish(id: string, value: boolean): Promise<void> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .modify(`contracts/${id}/autoreplenish`, { value })
  }
}
