import { Apis, Chunk, Client } from '../../../data'
import { Pool, PoolCopyModel, PoolCreateModel, PoolFilter, PoolUpdateModel, RateSpanCreateModel, ResolvedPool } from '../models'
import { SpanAccess } from './SpanAccess'

export class PoolAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, filter?: PoolFilter): Promise<Chunk<ResolvedPool>> {
    return await Client.instance(await Apis.getGatewayV0())
      .get(`pools`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        ...filter,
      })
  }

  public static async getOne(id: string): Promise<ResolvedPool> {
    return await Client.instance(await Apis.getGatewayV0())
      .get(`pools/${id}`)
  }

  public static async getOneParent(id: string): Promise<Chunk<ResolvedPool>> {
    return await Client.instance(await Apis.getGatewayV0())
      .get(`pools/${id}/parent`)
  }

  public static async create(model: PoolCreateModel): Promise<Pool> {
    return await Client.instance(await Apis.getGatewayV0())
      .create(`pools`, model)
  }

  public static async update(id: string, model: PoolUpdateModel): Promise<void> {
    await Client.instance(await Apis.getGatewayV0())
      .update(`pools/${id}`, model)
  }

  public static async delete(id: string): Promise<void> {
    await Client.instance(await Apis.getGatewayV0())
      .delete(`pools/${id}`)
  }

  public static async copy(model: PoolCopyModel): Promise<void> {
    const chunk = await SpanAccess.getAll(undefined, undefined, undefined, undefined, {
      byPoolId: model.source.id,
    })
    for (const span of chunk.data.filter(x => x.rates.length > 0)) {
      try {
        const item = new RateSpanCreateModel()
        item.pool = model.destination
        item.resource = span.rates[0].resource
        item.service = span.rates[0].service
        item.kind = span.rates[0].kind
        item.type = span.rates[0].type
        item.flightDirection = span.rates[0].flightDirection
        item.program = span.rates[0].program
        item.contract = span.rates[0].contract
        item.name = span.name
        item.bookableFrom = span.rates[0].bookableFrom
        item.bookableTill = span.rates[0].bookableTill
        item.validFrom = span.rates[0].validFrom
        item.validTill = span.rates[0].validTill
        item.validDays = span.rates[0].validDays
        item.rates = span.rates

        await SpanAccess.create(item)
      } catch {}
    }
  }
}
