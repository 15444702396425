import { Apis, Chunk, Client } from "../../../data";
import { City, CityQuery } from '../models'

export class CityAccess {
  public static async getAll(pattern?: string, orderBy?: string, skip?: number, take?: number, query?: CityQuery): Promise<Chunk<City>> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`cities`, {}, {
        pattern,
        orderBy,
        skip,
        take,
        ...query,
      });
  }

  public static async getOne(id: string): Promise<City> {
    return await Client
      .instance(await Apis.getGatewayV0())
      .get(`cities/${id}`);
  }
}
