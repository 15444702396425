export class ContactInformation {
  public fullName: string | null
  public email: string | null
  public phone: string | null

  public constructor() {
    this.fullName = ''
    this.email = ''
    this.phone = ''
  }
}
