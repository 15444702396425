import React, { CSSProperties, ReactElement } from "react";
import ReactImageGallery from "react-image-gallery";

interface CarouselProps {
  data: { original: string, thumbnail: string; }[],
  width?: string;
  showFullscreen?: boolean;
  showThumbnails?: boolean;
}

export function Carousel(props: CarouselProps): ReactElement {
  const { data, width, showFullscreen, showThumbnails } = props;

  const style: CSSProperties | undefined = width !== undefined
    ? { width }
    : undefined;

  return (
    <div style={style}>
      <ReactImageGallery items={data} showPlayButton={false} showThumbnails={showThumbnails} showFullscreenButton={showFullscreen} />
    </div>
  );
}
