import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Chunk } from '../../data'
import { AirlineV1 } from '../../data/models'
import { AirlineAccess, AirlineAccessV1 } from '../data/access'
import { Airline } from '../data/models'
import { FetchList } from './types'

export function useAirline(id: string | null): [ Airline | null, Dispatch<SetStateAction<Airline | null>> ] {
  const [ item, setItem ] = useState<Airline | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await AirlineAccess.getOne(id));
        } else {
          setItem(null);
        }
      } catch (e) {
        setItem(null);
      }
    })();
  }, [ id, setItem ]);

  return [ item, setItem ];
}

export function useAirlines(pageSize?: number, prefetch?: boolean): [ Chunk<Airline>, FetchList ] {
  const [ data, setData ] = useState<Chunk<Airline>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      setData(await AirlineAccess.getAll(pattern, undefined, skip || 0, take || pageSize));
    } catch (e) {
      setData(new Chunk());
    }
  }, [ pageSize, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}

export function useAirlineV1(id?: string | null): AirlineV1 | null {
  const [ item, setItem ] = useState<AirlineV1 | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          setItem(await AirlineAccessV1.fetchItem(id));
        } else {
          setItem(null);
        }
      } catch (e) {
        setItem(null);
      }
    })();
  }, [ id, setItem ]);

  return item;
}

export function useAirlinesV1(pageSize?: number, prefetch?: boolean): [ Chunk<AirlineV1>, FetchList ] {
  const [ data, setData ] = useState<Chunk<AirlineV1>>(new Chunk());

  const fetch = useCallback(async (pattern?: string, skip?: number, take?: number): Promise<void> => {
    try {
      setData(await AirlineAccessV1.fetchList(pattern, undefined, skip || 0, take || pageSize));
    } catch (e) {
      console.error(e);
      setData(new Chunk());
    }
  }, [ pageSize, setData ]);

  useEffect(() => {
    if (prefetch) {
      fetch().finally();
    }
  }, [ prefetch, fetch ]);

  return [ data, fetch ];
}
