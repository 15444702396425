import React, { PureComponent, ReactNode } from "react";
import { Field } from "./Field";
import { IntlMessage } from "./Intl";

interface ValidatorProps<TValue> {
  name: string;
  value: TValue;
  validators?: ((value: TValue) => IntlMessage | undefined)[];
}

interface ValidatorProps<TValue> {
  children?: ReactNode;
}

interface ValidatorState {
}

export class Validator<TValue> extends PureComponent<ValidatorProps<TValue>, ValidatorState> {
  public constructor(props: ValidatorProps<TValue>) {
    super(props);

    this.state = {};
  }

  public render(): ReactNode {
    const { name, value, validators, children } = this.props;

    return (
      <Field name={name}
             value={value}
             validators={validators}
             validateOnChange={true}
             render={() => (
               <React.Fragment>
                 {children}
               </React.Fragment>
             )} />
    );
  }
}
