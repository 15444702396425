import { Apis, Chunk, Client } from "../../../data";
import { AirlineV1 } from "../../../data/models";

export class AirlineAccessV1 {
  public static async fetchItem(id: string): Promise<AirlineV1> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<AirlineV1>(`Airline/${id}`);
  }

  public static async fetchList(pattern?: string, orderBy?: string, skip?: number, take?: number): Promise<Chunk<AirlineV1>> {
    return await Client
      .instance(await Apis.getDataApi())
      .get<Chunk<AirlineV1>>(`Airline`, {}, {
        pattern,
        orderBy,
        skip,
        take,
      });
  }
}
