import * as KendoInputs from "@progress/kendo-react-inputs";
import { NumericTextBoxHandle } from "@progress/kendo-react-inputs/dist/es/numerictextbox/interfaces/NumericTextBoxHandle";
import React, { PureComponent, ReactNode } from "react";
import { Field } from "../Field";
import { getIntlMessage, Intl, IntlMessage } from "../Intl";

interface NumberBoxProps {
  hint?: IntlMessage;
  label?: IntlMessage;
  placeholder?: IntlMessage;
  disabled?: boolean;
  readonly?: boolean;
  fill?: boolean;
  name: string;
  value: number | null;
  min?: number;
  max?: number;
  error?: IntlMessage;
  validators?: ((value: number | null) => IntlMessage | undefined)[],
  onChange?: (value: number | null) => void;
}

interface NumberBoxProps {
  children?: null;
}

interface NumberBoxState {
}

export class NumberBox extends PureComponent<NumberBoxProps, NumberBoxState> {
  private readonly targetRef = React.createRef<NumericTextBoxHandle>();

  public constructor(props: NumberBoxProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount(): void {
    if (this.targetRef.current && this.targetRef.current.element && this.targetRef.current.element.current) {
      this.targetRef.current.element.current.addEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public componentWillUnmount(): void {
    if (this.targetRef.current && this.targetRef.current.element && this.targetRef.current.element.current) {
      this.targetRef.current.element.current.removeEventListener("wheel", this.cancelWheelEventBubbling);
    }
  }

  public render(): ReactNode {
    const { hint, label, placeholder, disabled, readonly, fill, name, value, min, max, error, validators, onChange } = this.props;

    const className = fill ? "fill" : undefined;

    return (
      <Intl render={intl =>
        <Field hint={hint}
               name={name}
               value={value}
               error={error}
               validators={validators}
               validateOnChange={true}
               render={(value, error, fieldDisabled, fieldReadonly, onFieldChange) => {
                 if (readonly || fieldReadonly) {
                   return (
                     <span className={className}>
                       <KendoInputs.Input label={label && getIntlMessage(intl, label)}
                                          placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                          disabled={disabled || fieldDisabled || !onFieldChange}
                                          readOnly={true}
                                          value={value !== null ? value : ""} />
                     </span>
                   );
                 } else {
                   return (
                     <span className={className}>
                       <KendoInputs.NumericTextBox ref={this.targetRef}
                                                   label={label && getIntlMessage(intl, label)}
                                                   placeholder={placeholder && getIntlMessage(intl, placeholder)}
                                                   disabled={disabled || fieldDisabled || !onFieldChange}
                                                   valid={true}
                                                   value={value}
                                                   min={min}
                                                   max={max}
                                                   onChange={e => onFieldChange(this.constrained(e.value, min, max))} />
                     </span>
                   );
                 }
               }}
               onChange={onChange} />
      } />
    );
  }

  private readonly constrained = (value: number | null, min?: number, max?: number): number | null => {
    if (value === null) return null;

    if (min !== undefined && value < min) return min;

    if (max !== undefined && value > max) return max;

    return value;
  };

  private readonly cancelWheelEventBubbling = (event: WheelEvent): void => {
    event.cancelBubble = true;
  };
}
